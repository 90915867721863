import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_BILLING_PORTAL } from 'src/queries/GET_BILLING_PORTAL'
import { GetBillingPortal, GetBillingPortalVariables } from 'src/queries/types/GetBillingPortal'
import { FormButton } from 'src/resources/elements/form/Button'
import { ContentHeader } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import { VendorChargebeeRequired } from 'src/vendor/VendorChargebeeContext'
import styled from 'styled-components'

const BillingWrapper = styled.div`
  padding: 0 ${Spacing.basePadding4x} ${Spacing.basePadding4x};
`

export const TeamBillingPanel = () => (
  <BillingWrapper>
    <ContentHeader header='Billing' />
    <VendorChargebeeRequired fallback={() => <p>Loading subscriptions&hellip;</p>}>
      {(chargebeeInstance) => <ManageSubscription chargebeeInstance={chargebeeInstance} />}
    </VendorChargebeeRequired>
  </BillingWrapper>
)

const ManageSubscription = ({ chargebeeInstance }: { chargebeeInstance: any }) => {
  const team = useContext(TeamContext)
  const { data, loading, error } = useQuery<GetBillingPortal, GetBillingPortalVariables>(
    GET_BILLING_PORTAL,
    {
      variables: { teamId: team.id, redirectUrl: location.href }
    }
  )

  if (error) {
    return <p>Subscription information is currently unavailable. Please check back later.</p>
  }

  if (loading) {
    return <p>Loading subscription information&hellip;</p>
  }

  if (!data || !data.getBillingPortalSession) {
    return <p>Unable to show subscription information</p>
  }

  chargebeeInstance.setPortalSession(async () => data.getBillingPortalSession.raw)

  return (
    <FormButton
      primary
      onClick={() => {
        const cbPortal = chargebeeInstance.createChargebeePortal()
        cbPortal.open()
      }}
    >
      Manage subscription
    </FormButton>
  )
}
