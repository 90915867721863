import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Span } from '@sentry/types'
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { GlobalFrontChatWindow } from 'src/types/frontchat'
import { GeographicRegionSelect } from 'src/applications/Oversight/controls/GeographicRegionSelect'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { useStoredState } from 'src/applications/Oversight/hooks/useStoredState'
import { PreAuthTemplate } from 'src/applications/Oversight/templates/PreAuthTemplate'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { getUserContext } from 'src/contexts/UserContext'
import { GET_CURRENT_USER } from 'src/queries/GET_CURRENT_USER'
import { CurrentUser } from 'src/queries/types/CurrentUser'
import { Colors } from 'src/resources/colors'
import { AuthSheet } from 'src/resources/elements/AuthSheet'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FormCheckBox } from 'src/resources/elements/form/CheckBox'
import {
  ErrorContainer,
  ErrorInfoIcon,
  ErrorMessage,
  FlexError
} from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { Icon } from 'src/resources/elements/Icon'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import chevronRight from 'src/resources/icons/chevron-right.svg'
import emailIcon from 'src/resources/icons/email.svg'
import vendorGitHubLogo from 'src/resources/icons/vendor-github.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_FEATURE_FLAGS_PUBLIC } from 'src/smart/queries/SQ_FEATURE_FLAGS_PUBLIC'
import {
  UserTransceiver,
  UserTransceiverAuthenticationFailure,
  UserTransceiverRequestFailure
} from 'src/transceivers/UserTransceiver'
import styled, { css } from 'styled-components'
import { SignInViaEmail } from '../controls/SignInViaEmail'
import { shutdownFrontChat } from 'src/resources/sidebars/PlatformSidebar'
import posthog from 'posthog-js'

declare let window: GlobalFrontChatWindow

const LoginWithEmailWrapper = styled.div<{ isClickable: boolean }>`
  background-color: ${Colors.pigeon100};
  color: ${Colors.pigeon800};
  padding: 16px 18px;
  width: 100%;
  font-size: 14px;
  display: flex;
  transition: background-color 0.1s linear;
  border-radius: 6px;
  overflow: hidden;
  img {
    height: 16px;
    margin-right: 14px;
  }
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${Colors.pigeon200};
      }
    `}
`

const LoginWithEmailButton = ({
  children,
  onClick
}: {
  children: ReactNode
  onClick?: () => void
}) => (
  <LoginWithEmailWrapper onClick={onClick} isClickable={typeof onClick === 'function'}>
    <img src={emailIcon} alt='' /> {children}
  </LoginWithEmailWrapper>
)

const GithubLoginButton = styled(FlatButton)`
  width: 100%;
  padding: 14px 18px !important;
  margin-bottom: 24px;
  text-align: center;
`

function GithubAuth({ children }: { children: ReactNode }) {
  return <>{children}</>
}

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${fontSizes.type10};
  margin: ${Spacing.basePadding2x} 0 20px 0;

  &:before,
  &:after {
    content: '';
    border-top: 1px solid ${Colors.border};
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  &:after {
    margin: 0 0 0 20px;
  }
`

const otherOptions = (
  <GithubAuth>
    <HorizontalLine>OR</HorizontalLine>
    <GithubLoginButton
      size='lg'
      renderAs='a'
      href={`${FLATFILE_REFINERY_URL}/auth/github`}
      aria-label='Login or Signup with GitHub'
      color='alternative'
      variant='grayscale'
    >
      <img
        style={{
          height: '24px',
          marginRight: '6px',
          verticalAlign: '-7px'
        }}
        src={vendorGitHubLogo}
        alt='GitHub logo (Octocat SVG icon)'
      />{' '}
      <span>Sign in with Github</span>
    </GithubLoginButton>
  </GithubAuth>
)

const checkFlatfileStatus = (
  <>
    If the problem persists, check{' '}
    <a href='https://status.flatfile.io/' target='_blank'>
      Flatfile Status <Icon name='external-link-square-alt' />
    </a>
  </>
)

export const LoginContentContainer = styled.section`
  max-width: 420px;
  width: 100%;
  padding: ${Spacing.basePadding2x} ${Spacing.contentPadding} ${Spacing.contentPadding};
  background: ${Colors.white};
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin: 0 auto ${Spacing.basePadding3x};
`

const SecondaryContentContainer = styled(LoginContentContainer)`
  box-shadow: none;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: none;
  border: 1px solid ${Colors.gray40};
  padding: ${Spacing.basePadding2x} ${Spacing.basePadding4x};

  p {
    text-align: inherit;
    font-family: ${fontFamily.fontPrimary};
    color: ${Colors.textAccent};
    font-weight: 600;
  }
`

const InputGroup = styled.div`
  margin-bottom: ${Spacing.basePadding3x};

  input {
    width: 100%;
    margin-bottom: 0;
    font-size: ${fontSizes.type14};
    padding: 12px;
  }

  button {
    width: 100%;
  }

  ${ErrorContainer} {
    margin-bottom: 0;
  }
`

const ChevronRight = () => (
  <img
    style={{
      height: '9px',
      marginLeft: '7px',
      marginTop: '6px'
    }}
    src={chevronRight}
    alt=''
  />
)

// Sentry performance tracking
const loginSpans: Partial<Span>[] = [
  {
    op: 'login-loaded'
  },
  {
    op: 'login-onsubmit'
  },
  {
    op: 'login-success'
  }
]

enum ELoginMethod {
  email = 'email',
  password = 'password'
}

interface ILoginForm {
  password: string
  rememberMe: boolean
  username: string
}

export const LoginScene = withRouter(({ location: { search } }) => {
  const emailInputRef = useRef<HTMLInputElement>()
  const rememberMeInputRef = useRef<HTMLInputElement>()
  const [formDisabled, setFormDisabled] = useState(false)
  const [loginMethod, setLoginMethod] = useStoredState<ELoginMethod>(
    'flatfile.loginMethod',
    ELoginMethod.password
  )
  const [setLoginPassword, setLoginEmail] = [ELoginMethod.password, ELoginMethod.email].map(
    (method) =>
      useCallback(() => {
        setLoginMethod(method)
        setFormDisabled(false)
        shutdownFrontChat()
      }, [setLoginMethod])
  )

  const { result: featureFlags } = useSmartQuery(SQ_FEATURE_FLAGS_PUBLIC, { variables: {} })

  const loginWithEmailEnabled = useMemo(() => featureFlags?.LOGIN_WITH_EMAIL, [featureFlags])

  // TODO: can be removed once email login feature is enabled for all users
  const loginMethodFinal = useMemo(
    () => (loginWithEmailEnabled ? loginMethod : ELoginMethod.password),
    [loginMethod, loginWithEmailEnabled]
  )

  const userContext = getUserContext()
  const LoginForm: TForm<{ username: string; password: string; rememberMe: boolean }> = Form
  const params = new URLSearchParams(search)
  const reason = params.get('reason')
  const redir = params.get('redir')
  const sentryTransaction = useSentryTransaction({
    name: '/login',
    spans: loginSpans
  })
  // check if a user is already logged in
  const { data, loading } = useQuery<CurrentUser>(GET_CURRENT_USER, {
    fetchPolicy: 'network-only'
  })

  submitSentrySpan(sentryTransaction, 'login-loaded')

  const submitLoginForm = useCallback(
    async (event: IFormSubmitEvent<ILoginForm>) => {
      const { username, password, rememberMe } = event.data

      if (!username) {
        event.formContext.setValue({
          errors: { username: 'Please enter an email address' }
        })
        return
      }

      const showAuthError = (message: JSX.Element) =>
        event.formContext.setValue({
          errors: {
            password: message
          }
        })

      submitSentrySpan(sentryTransaction, 'login-onsubmit')

      shutdownFrontChat()

      try {
        const loggedInUser = await UserTransceiver.login({
          password,
          rememberMe,
          username,
          type: 'password'
        })

        userContext.setValue({ user: loggedInUser })
        if (loggedInUser) {
          submitSentrySpan(sentryTransaction, 'login-success')
          sentryTransaction.transaction.finish()
        }
        // reload the page to get accurate info in VendorSplitManager
        window.location.replace(redir || '/')
      } catch (e) {
        Sentry.captureException(e)
        switch (e) {
          case UserTransceiverRequestFailure:
            showAuthError(
              <p>
                Unable to connect to server. Please check your internet connection and try again.{' '}
                {checkFlatfileStatus}
              </p>
            )
            break
          case UserTransceiverAuthenticationFailure:
            showAuthError(
              <p>We couldn't find an account matching those credentials. Please try again.</p>
            )
            break
          default:
            showAuthError(<p>Unknown error. Please try again. {checkFlatfileStatus}</p>)
        }
      }
    },
    [userContext]
  )

  // show spinner until query has loaded
  if (loading) {
    return QueryAlert({ loading })
  }

  // if a user is logged in then redirect to user's current team
  if (data?.me && data?.currentTeam) {
    const { currentTeam } = data
    const url = `/a/${currentTeam.id}`
    posthog.identify(` ${data.me.email} `)

    return <Redirect to={url} />
  }

  // user is not logged in, show login form
  return (
    <PreAuthTemplate onAuthenticatedUrl={redir}>
      <AuthSheet>
        <LoginContentContainer>
          <LoginForm onSubmit={submitLoginForm}>
            {reason === 'expired' && (
              <FlexError>
                <ErrorInfoIcon />
                <div>
                  You have been automatically logged out due to inactivity. Please log back in to
                  continue.
                </div>
              </FlexError>
            )}
            {reason === 'invalid' && (
              <FlexError>
                <ErrorInfoIcon />
                <div>
                  This Workspace invitation is no longer valid. Please contact your project manager
                  if you need a new invitation.
                </div>
              </FlexError>
            )}
            <GeographicRegionSelect path='/login' />

            <InputGroup>
              <Input
                autoComplete='email'
                disabled={formDisabled}
                inputRef={emailInputRef}
                label='Email address'
                name='username'
                placeholder='jane@doe.com'
              />
            </InputGroup>

            {loginMethodFinal === ELoginMethod.password && (
              <InputGroup>
                <Input
                  autoComplete='current-password'
                  label='Password'
                  name='password'
                  type='password'
                  placeholder='* * * * * * * * '
                  helpButton={
                    <FlatButton
                      renderAs='small-link'
                      variant='empty'
                      color='secondary'
                      to='/forgot-password'
                      aria-label='Forgot your password'
                    >
                      Forgot password?
                    </FlatButton>
                  }
                />
                <ErrorMessage name='username' />
              </InputGroup>
            )}
            <InputGroup>
              <FormCheckBox
                disabled={formDisabled}
                inputRef={rememberMeInputRef}
                type='primary'
                name='rememberMe'
                label='Remember me for 7 days'
              />
            </InputGroup>
            {loginMethodFinal === ELoginMethod.password && (
              <>
                <ErrorMessage name='password' />
                <InputGroup>
                  <FlatButton size='lg' type='submit' aria-label='Submit login form'>
                    Log in
                  </FlatButton>
                </InputGroup>
              </>
            )}
            {loginWithEmailEnabled ? (
              <>
                {loginMethodFinal === ELoginMethod.email && (
                  <>
                    <SignInViaEmail
                      emailInputRef={emailInputRef}
                      rememberMeInputRef={rememberMeInputRef}
                      onSubmit={() => setFormDisabled(true)}
                      onComplete={() => setFormDisabled(false)}
                    />
                    <LoginWithEmailButton>
                      <div>
                        We'll email you a link to log in with. Or you can{' '}
                        <span
                          onClick={setLoginPassword}
                          style={{
                            color: Colors.sky600,
                            cursor: 'pointer',
                            fontWeight: 600
                          }}
                        >
                          sign in manually
                        </span>
                        .
                      </div>
                    </LoginWithEmailButton>
                  </>
                )}

                {loginMethodFinal === ELoginMethod.password && (
                  <>
                    {otherOptions}
                    <LoginWithEmailButton onClick={setLoginEmail}>
                      Email me a link to log in
                      <ChevronRight />
                    </LoginWithEmailButton>
                  </>
                )}
              </>
            ) : (
              <>{otherOptions}</>
            )}
          </LoginForm>
          <br />
          To log into the latest version of Flatfile,{' '}
          <a href='https://platform.flatfile.com/account/login'>click here</a>
        </LoginContentContainer>

        <GithubAuth>
          <SecondaryContentContainer>
            <p>New to Flatfile?</p>
            <FlatButton
              color='white'
              renderAs='button'
              aria-label='Sign up for a new Flatfile account'
              onClick={() => {
                window.location.replace('https://platform.flatfile.com/account/sign-up')
              }}
            >
              Sign up
            </FlatButton>
          </SecondaryContentContainer>

          <FlatButton
            style={{ opacity: '0', width: '1px', overflow: 'hidden' }}
            renderAs='button'
            onClick={() => {
              window.location.replace('https://platform.flatfile.com/account/sign-up')
            }}
          >
            &nbsp;
          </FlatButton>
        </GithubAuth>
      </AuthSheet>
    </PreAuthTemplate>
  )
})
