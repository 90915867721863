import { MutationTuple, useMutation } from '@apollo/client'
import {
  UpdateSchemaFunction,
  UpdateSchemaFunctionVariables
} from 'src/queries/types/UpdateSchemaFunction'
import { UPDATE_SCHEMA_FUNCTION } from 'src/queries/UPDATE_SCHEMA_FUNCTION'

export const useUpdateSchemaFunctionMutation = (): MutationTuple<
  UpdateSchemaFunction,
  UpdateSchemaFunctionVariables
> => {
  const [updateSchemaFunction, results] = useMutation<
    UpdateSchemaFunction,
    UpdateSchemaFunctionVariables
  >(UPDATE_SCHEMA_FUNCTION)

  return [updateSchemaFunction, results]
}
