import { ChangeEvent, useState } from 'react'
import { storage } from 'src/resources/utils/storage'

type InputChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

export interface IStateInputProps {
  value: string
  onChange(event: InputChangeEvent): void
}

export function useStateInput<TResult = void>(
  Component: (props: IStateInputProps) => JSX.Element,
  defaultValue: string = '',
  transformResult?: (value: string) => TResult,
  persistKey?: string
) {
  const persistedValue = persistKey ? storage(persistKey).getRaw() : undefined
  const [value, setValue] = useState(persistedValue ?? defaultValue)

  function updateValue(newValue: string) {
    if (persistKey) {
      storage(persistKey).setRaw(newValue)
      setValue(newValue)
    }
  }

  function onChange(event: InputChangeEvent) {
    updateValue(event.target.value)
  }

  return {
    transformed: transformResult?.(value),
    render() {
      return <Component value={value} onChange={onChange} />
    },
    value
  }
}
