import {
  DataChangeEvent,
  OnDataLoadProps,
  OnSelectedRows,
  OnSortProps,
  RowData,
  ESortOptions,
  determineSort
} from '@turntable/core'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Loader } from 'src/applications/Embed/fragments/Loader'
import { useConfirmDismissModal } from 'src/applications/Oversight/hooks/useConfirmDismissModal'
import { useFetchWorkbookRows } from 'src/applications/Oversight/hooks/useFetchWorkbookRows'
import { TableValidationContext } from 'src/contexts/TableValidationContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { useFilterWithCounts } from 'src/resources/hooks/useFilterWithCounts'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { useTableFilters, Validation } from 'src/resources/hooks/useTableFilters'
import { Spacing } from 'src/resources/layout'
import { isLoading } from 'src/resources/utils/isLoading'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_QUEUE_UPDATE_RECORD_STATUS } from 'src/smart/mutations/SM_QUEUE_UPDATE_RECORD_STATUS'
import { SM_UPDATE_WORKBOOK_ROWS } from 'src/smart/mutations/SM_UPDATE_WORKBOOK_ROWS'
import { queueUpdateRecordStatusVariables } from 'src/smart/mutations/types/queueUpdateRecordStatus'
import { SQ_GET_LATEST_MERGE } from 'src/smart/queries/SQ_GET_LATEST_SHEET_MERGE'
import { EValidationState } from 'src/types/enums/EValidationState'
import { EWorkbookStatus } from 'src/types/enums/EWorkbookStatus'
import { IJsonSchemaSchema } from 'src/types/interfaces/ISchema'
import { ISheetFilter } from 'src/types/interfaces/ISheetFilter'
import { Schema } from 'src/utils/data/Schema'
import { DALTransport } from 'src/utils/data/transports/DALTransport'
import { Transport } from 'src/utils/data/transports/Transport'
import { useSheetAPIExplorer } from '../hooks/useSheetAPIExplorer'
import { DataTable } from './DataTable'
import { ValidationTableActions } from './ValidationTableActions'
import styled from 'styled-components'

export const BasicDataTableContainer = styled.div`
  height: calc(100vh - 296px);
  max-height: calc(100vh - 296px);
`

const SecondaryMenuControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${Spacing.basePadding2x} ${Spacing.basePadding4x};
  position: sticky;
  z-index: 5;
`

const ToolboxWrapper = styled.div<{ autoMargin?: boolean }>`
  min-width: 0;
  margin-left: ${({ autoMargin }) => (autoMargin ? 'auto' : Spacing.basePadding2x)};
`

const PAGE_SIZE = 500

export const WorkbookDataTable = ({
  validating,
  schemaId,
  schemaObj,
  sheetId,
  workbookId,
  workbookStatus
}: {
  validating: boolean
  schemaId: string
  schemaObj: IJsonSchemaSchema
  sheetId?: string // todo: make non-optional and update ImportDataPanel.tsx
  workbookId: string
  workbookStatus?: string
}) => {
  /*
    These properties persist loaded data in the component state in order get around the fact
    that useFetchWorkbookRows sets all values to empty while loading, regardless of whether
    data already exists.
  */
  const [newRows, setNewRows] = useState(undefined)
  const [newCounts, setNewCounts] = useState(undefined)
  /* ** */

  /* Other state behavior */
  const { setValidatingTable } = useContext(TableValidationContext)
  const [selectedRows, setSelectedRows] = useState<RowData[]>([])
  const [refreshingDataset, setRefreshingDataset] = useState(false)
  const [{ validationState, filter: filterChoice, errors: errorsChoice }] = useTableFilters()
  const [toolbox, toolboxButton] = useSheetAPIExplorer({ sheetId, schemaId })
  const [sortOverrides, setSortOverrides] = useState<{
    sortOrder: ESortOptions
    sortColumn: number | undefined
  }>({ sortOrder: ESortOptions.noOrder, sortColumn: undefined })
  const [isWorkbookSucceeded, setIsWorkbookSucceeded] = useState(false)
  /* ** */

  /* Constants and mutations */
  const errorsParameter = useSearchParam.string('errors', '')
  const filters = useMemo(
    () => (filterChoice === '' ? {} : { valid: filterChoice === 'valid' }),
    [filterChoice]
  )
  const filter: ISheetFilter = useMemo(
    () => ({ ...filters, state: validationState, error: errorsParameter }),
    [filters, validationState, errorsParameter]
  )

  const queueUpdateRecordStatus = useSmartMutation(SM_QUEUE_UPDATE_RECORD_STATUS)
  const updateWorkbookRows = useSmartMutation(SM_UPDATE_WORKBOOK_ROWS)

  const team = useContext(TeamContext)
  const canMergeDuplicates = team.featureFlags?.WORKSPACES_MERGE_DUPLICATES
  const hideExtraRows = team.featureFlags?.WORKSPACES_HIDE_MANUAL_ROWS

  const selectedRowIds = useMemo(() => {
    return selectedRows.reduce((memo, { id }) => {
      if (id) memo.push(parseInt(id, 10))
      return memo
    }, [])
  }, [selectedRows])
  const selectedRowPositions = useMemo(() => {
    if (!selectedRows.length) return []
    if (selectedRows.length === 1) {
      return [selectedRows[0].position]
    }
    return [selectedRows[0].position, selectedRows[selectedRows.length - 1].position]
  }, [selectedRows])

  const latestMergeQuery = useSmartQuery(SQ_GET_LATEST_MERGE, {
    variables: {
      sheetId: sheetId
    },
    skip: !sheetId,
    fetchPolicy: 'network-only'
  })
  const latestMergeId = useMemo(() => latestMergeQuery.result, [latestMergeQuery])

  const dismissConfirmAction = useRef<() => void>()
  const dismissalModal = useConfirmDismissModal(
    () => dismissConfirmAction.current?.(),
    sheetId,
    selectedRowIds,
    filter
  )
  /* ** */

  /* Effects */
  const {
    totalRows,
    accepted,
    dismissed,
    filtered,
    review,
    counts,
    errors,
    rows,
    labels,
    fetchMoreRows,
    refetchRows,
    alert: fetchRowsAlert
  } = useFetchWorkbookRows(
    workbookId,
    filterChoice,
    validationState,
    schemaObj,
    parseInt(schemaId, 10),
    PAGE_SIZE,
    errorsChoice,
    latestMergeId,
    { skip: latestMergeQuery.alert },
    sortOverrides.sortColumn,
    sortOverrides.sortOrder
  )

  const workbookLoading = isLoading(fetchRowsAlert)

  const refetchRowData = useCallback(async () => {
    setRefreshingDataset(true)
    await refetchRows()
    setRefreshingDataset(false)
  }, [setRefreshingDataset])

  useEffect(() => setSelectedRows([]), [filterChoice, errorsChoice, validationState])

  useEffect(() => {
    if (!refreshingDataset && !workbookLoading) {
      if (!newRows && !rows.length) return
      if (rows) setNewRows(rows)
    }
  }, [rows, workbookLoading, refreshingDataset])

  useEffect(() => {
    if (counts) {
      setNewCounts(counts)
      /*
        If the counts for the current filter are 0, then we want to set the
        rows to empty.
      */
      if (
        (counts.valid === 0 && filter.valid === true) ||
        (counts.invalid === 0 && filter.valid === false) ||
        (counts.invalid === 0 && counts.valid === 0 && filter.valid === undefined)
      ) {
        setNewRows([])
      }
    }
  }, [counts])

  useEffect(() => {
    /* Refetch rows if a workbook update was successful */
    if (!workbookLoading && !isWorkbookSucceeded) {
      refetchRowData()
      setIsWorkbookSucceeded(workbookStatus === EWorkbookStatus.SUCCEEDED)
    }
  }, [workbookStatus])

  useEffect(() => {
    /* Refetch rows if validations are complete and the rows are not already being refetched */
    if (!validating && !refreshingDataset) {
      refetchRowData()
    }
  }, [validating])
  /* ** */

  /* Action handlers */
  const handleOnDataLoad = useCallback(
    async ({ pageSize, page }: OnDataLoadProps) => {
      const skip = (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize

      return fetchMoreRows({
        skip,
        limit: pageSize
      })
    },
    [fetchMoreRows]
  )

  const updateRowSelection: OnSelectedRows = useCallback(
    (newSelectedRows: RowData[]) => setSelectedRows(newSelectedRows),
    [setSelectedRows]
  )

  const setRowStatus = useCallback(
    async (rowValidationState: Validation) => {
      const mutationVariables: queueUpdateRecordStatusVariables = {
        validationState: rowValidationState,
        workbookId,
        schemaId: parseInt(schemaId, 10),
        // if rows are selected then change status based on the selected row ids,
        // otherwise change status of all rows that match the current filter(s)
        filter: undefined,
        rowIds: undefined,
        ...(selectedRowIds.length ? { rowIds: selectedRowIds } : { filter })
      }

      async function doUpdateStatus() {
        setValidatingTable(true)
        dismissalModal.close()
        try {
          await queueUpdateRecordStatus.run(mutationVariables)
        } catch (error) {
          console.error(error)
        }
        setSelectedRows([])
      }

      if (rowValidationState === EValidationState.dismissed) {
        // confirm before dismissing in case of linked records
        dismissConfirmAction.current = doUpdateStatus
        dismissalModal.open()
      } else {
        doUpdateStatus()
      }
    },
    [
      dismissalModal,
      filter,
      queueUpdateRecordStatus,
      schemaId,
      selectedRowIds,
      dismissConfirmAction,
      setRefreshingDataset,
      setSelectedRows,
      workbookId
    ]
  )

  const handleCellsChange = async ({ changes }: DataChangeEvent): Promise<DataChangeEvent> => {
    setIsWorkbookSucceeded(true)
    // todo: do this at a higher level
    const schema = new Schema({ properties: schemaObj?.properties })

    // transform changes for mutation
    const edits = Transport.toPatch(changes, schema)

    // save changes
    const updatedRows = await updateWorkbookRows.run({
      workbookId,
      schemaId: parseInt(schemaId, 10),
      edits
    })

    // all changes associated with a new row
    let newRowChangeIndex = 0
    const newRowChanges = changes.filter((c) => c.id === undefined)

    const updatedChanges = updatedRows.rows
      .map((r, index) => {
        let change =
          changes.find((c) => parseInt(c.id, 10) === r._id) ??
          newRows.find((row: RowData) => parseInt(row.id, 10) === r._id)

        if (!change) {
          if (newRowChanges.length) {
            change = {
              ...newRowChanges[newRowChangeIndex],
              id: r._id.toString()
            }
            newRowChangeIndex++
          } else {
            change = changes[index]
          }
        }

        return DALTransport.fromDAL(schema, r, change.position).toTurntablePatch()
      })
      .filter((update) => update !== null)

    await refetchRows()

    return { changes: updatedChanges }
  }

  const handleOnSort = useCallback(
    ({ columnIndex, contextMenu, order }: OnSortProps) => {
      //turntable rerenders every-time fetchWorkbookRows is called. This is a duplication of turntable logic required
      //until the table does not rerender when data is fetched.
      if (!contextMenu) {
        const { newSortOrder, newSelectedColumn } = determineSort({
          selectedColumn: columnIndex,
          currentSortOrder: sortOverrides.sortOrder,
          previousColumn: sortOverrides.sortColumn
        })
        setSortOverrides({ sortOrder: newSortOrder, sortColumn: newSelectedColumn })
      } else {
        setSortOverrides({ sortOrder: order as ESortOptions, sortColumn: columnIndex })
      }
    },
    [sortOverrides, labels, setSortOverrides, refreshingDataset]
  )
  /* ** */

  const hasNoRowData = !newRows

  const additionalRowCount = useMemo(() => {
    if (validationState === EValidationState.review && !hideExtraRows) {
      return 100
    }
    return 0
  }, [validationState, hideExtraRows])

  const filterWithCounts = useFilterWithCounts(
    counts ?? newCounts,
    workbookLoading && !newCounts,
    errors,
    schemaObj?.properties,
    !!(latestMergeId && canMergeDuplicates),
    !newCounts || refreshingDataset
  )

  return (
    <>
      {dismissalModal.render()}
      {toolbox}
      <SecondaryMenuControls>
        {!hasNoRowData && filterWithCounts}
        <ValidationTableActions
          selectedRows={selectedRowIds}
          onMarkStatus={setRowStatus}
          disabled={workbookLoading || validating || !rows.length}
        />
        {toolboxButton && <ToolboxWrapper autoMargin={false}>{toolboxButton}</ToolboxWrapper>}
      </SecondaryMenuControls>
      <BasicDataTableContainer>
        {!hasNoRowData ? (
          <DataTable
            additionalRows={additionalRowCount}
            pageSize={PAGE_SIZE}
            tableHeaders={labels}
            tableRows={rows}
            totalCount={filtered ?? totalRows ?? rows?.length}
            rowCounts={{ totalRows, accepted, dismissed, filtered, review }}
            onCellsChange={handleCellsChange}
            onDataLoad={handleOnDataLoad}
            onSort={handleOnSort}
            sortOverride={sortOverrides}
            onSelectedRows={updateRowSelection}
            selectedRows={selectedRowPositions}
            isLoading={workbookLoading || refreshingDataset || validating}
            isLoadingFullPage={workbookLoading}
          />
        ) : (
          <Loader top={0} height='100%' message='Loading...' />
        )}
      </BasicDataTableContainer>
    </>
  )
}
