import { gql } from 'apollo-boost'
import { SmartQuery } from 'src/smart/SmartQuery'

import {
  GetRecordsBySearchTerm,
  GetRecordsBySearchTermVariables,
  GetRecordsBySearchTerm_getRecordsBySearchTerm_recordPreviews
} from './types/GetRecordsBySearchTerm'

export const SQ_GET_RECORDS_BY_SEARCH: SmartQuery<
  GetRecordsBySearchTermVariables,
  GetRecordsBySearchTerm,
  GetRecordsBySearchTerm_getRecordsBySearchTerm_recordPreviews[]
> = {
  query: gql`
    query GetRecordsBySearchTerm($term: String!, $workbookId: UUID!, $schemaId: ID!) {
      getRecordsBySearchTerm(term: $term, workbookId: $workbookId, schemaId: $schemaId) {
        recordPreviews {
          rowId
          label
          linkedUniqueValue
        }
      }
    }
  `,
  extract({ getRecordsBySearchTerm }) {
    return getRecordsBySearchTerm.recordPreviews
  }
}
