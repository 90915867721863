import { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { FileUploadContext } from 'src/contexts/FileUploadContext'
import { uploadUpdatedSubscription } from 'src/smart/queries/SQ_UPLOAD_UPDATES_SUBSCRIPTION'
import { GetBatches_getBatches_data_views } from 'src/queries/types/GetBatches'

export function useWorkspaceUpdateNotifications(workspaceId: string) {
  const { addToast } = useToasts()
  const { setBatchViews } = useContext(FileUploadContext)
  const [newUploads, setNewUploads] = useState<string | null>(null)
  const update = uploadUpdatedSubscription(workspaceId)

  useEffect(() => {
    if (update) {
      setBatchViews((prevState: { [key: string]: GetBatches_getBatches_data_views[] }) => {
        const prevValues = prevState[update?.batch.id] ?? []
        return {
          ...prevState,
          [update?.batch.id]: prevValues.concat(update?.views)
        }
      })
    }
  }, [update?.emitDateTime])

  useEffect(() => {
    if (update && update?.emitDateTime !== newUploads) {
      setNewUploads(update.emitDateTime)
      const emitTimeStamp = new Date(Number(update.emitDateTime))
      let msg: string
      const time = `at ${emitTimeStamp.toString()}`
      if (update.updateType && update.updateType === 'newUploadProcessed') {
        msg = update.uploadedBy
          ? `New file uploaded by ${update.uploadedBy} ${time}`
          : `New file uploaded ${time}`
      } else if (update.updateType && update.updateType === 'batchArchived') {
        msg = update.uploadedBy
          ? `File archived by ${update.uploadedBy} ${time}`
          : `File archived ${time}`
      } else if (update.updateType && update.updateType === 'batchRestored') {
        msg = update.uploadedBy
          ? `File restored by ${update.uploadedBy} ${time}`
          : `File restored ${time}`
      }

      if (msg) {
        addToast(msg, {
          appearance: 'success',
          autoDismiss: true
        })
      }
    }
  }, [update?.emitDateTime])

  return newUploads
}
