import { useCallback, useContext, useState } from 'react'

import { BatchTimelineEvent } from 'src/applications/Oversight/controls/BatchTimelineEvent'
import {
  BatchTimelineFormatSelector,
  EBatchTimelineFormat
} from 'src/applications/Oversight/controls/BatchTimelineFormatSelector'
import { Copyable } from 'src/applications/Oversight/controls/Copyable'
import { useIdArrayMap } from 'src/applications/Oversight/hooks/useIdArrayMap'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontMonospace } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_BATCH_EVENTS } from 'src/smart/queries/SQ_BATCH_EVENTS'
import { IBatchEvent } from 'src/types/interfaces/IBatchEvent'
import { getConfigValue, setConfigValue, storedConfigValue } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

const TimelineContainer = styled.div`
  & > h3 {
    text-align: center;
  }
  text-align: left;
  code,
  input[readonly] {
    background: #e0e0e0;
    border-radius: 4px;
    color: ${Colors.grayText};
    font-family: ${fontMonospace};
    font-size: 10px;
    padding: 4px;
    text-align: center;
    border: none;
  }
`

const Identifiers = styled.div`
  background: ${Colors.lightCodeBackground};
  border-radius: 4px;
  box-shadow: ${Shadows.highlightBright};
  padding: ${Spacing.basePadding};

  h5 {
    text-align: center;
    color: ${Colors.grayText};
  }

  h5,
  p {
    padding: 0;
    margin: 4px 0;
    font-size: 12px;
  }

  table {
    margin: 0 auto;
    width: 360px;

    td {
      vertical-align: middle;
    }

    td:first-child {
      text-align: right;
    }
  }

  input[readonly] {
    width: 240px;
  }
`

const batchTimelineFormat = storedConfigValue(
  'BATCH_TIMELINE_FORMAT',
  EBatchTimelineFormat.simple,
  {
    insignificant: true
  }
)

const batchTimelineDeveloperMode = storedConfigValue('BATCH_TIMELINE_DEVELOPER_MODE', 'off')

const isDeveloperMode = getConfigValue(batchTimelineDeveloperMode) === 'on'

export function BatchTimelinePanel({ batchId }: { batchId?: string }) {
  const [timelineFormat, setTimelineFormat] = useState(
    getConfigValue<EBatchTimelineFormat>(batchTimelineFormat)
  )

  const changeSelectedFormat = useCallback(
    (newFormat: EBatchTimelineFormat) => {
      setConfigValue<EBatchTimelineFormat>(batchTimelineFormat, newFormat)
      setTimelineFormat(newFormat)
    },
    [setTimelineFormat]
  )

  const team = useContext(TeamContext)

  const batchEvents = useSmartQuery(SQ_BATCH_EVENTS, {
    fetchPolicy: 'no-cache',
    skip: !batchId,
    variables: {
      batchId,
      teamId: team.id,
      skip: 0,
      take: 100
    }
  })

  const { workspaceId } = useParams<{ workspaceId: string }>()

  if (!batchId) {
    return <p>No import selected</p>
  }

  return (
    <TimelineContainer>
      <h3 style={{ marginTop: 0, paddingBottom: Spacing.basePadding2x }}>Import timeline</h3>
      <BatchTimelineFormatSelector
        selectedFormat={timelineFormat}
        onChangeSelectedFormat={changeSelectedFormat}
      />
      {timelineFormat === EBatchTimelineFormat.detailed ? (
        <Identifiers>
          <h5>API Identifiers</h5>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>batch id</p>
                </td>
                <td>
                  <Copyable value={batchId} />
                </td>
              </tr>
              <tr>
                <td>
                  <p>team id</p>
                </td>
                <td>
                  <Copyable value={team.id} />
                </td>
              </tr>
              {workspaceId ? (
                <tr>
                  <td>
                    <p>workspaceId</p>
                  </td>
                  <td>
                    <Copyable value={workspaceId} />
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Identifiers>
      ) : null}
      {batchEvents.alert ? (
        queryAlert(batchEvents)
      ) : (
        <BatchEventsTimeline
          batchEvents={batchEvents.result.data}
          developerMode={isDeveloperMode}
          timelineFormat={timelineFormat}
        />
      )}
    </TimelineContainer>
  )
}

const filterToEventNamesByFormat: {
  [EBatchTimelineFormat.detailed]: string[]
  [EBatchTimelineFormat.simple]: string[]
} = {
  [EBatchTimelineFormat.detailed]: [
    /*empty means all*/
  ],
  [EBatchTimelineFormat.simple]: [
    'upload.dataSources.extract',
    'upload.dataSources.extractFields',
    'upload.error',
    'upload.match.rules.generate',
    'upload.prepare',
    'upload.transfer.failed',
    'upload.transfer.uploaded'
  ]
}

const EmptyState = styled.p`
  text-align: center;
`

function BatchEventsTimeline({
  batchEvents,
  developerMode = false,
  timelineFormat
}: {
  batchEvents?: IBatchEvent[]
  developerMode?: boolean
  timelineFormat: EBatchTimelineFormat
}) {
  const patches = useIdArrayMap<Partial<IBatchEvent>>([])
  const filterToEventNames = filterToEventNamesByFormat[timelineFormat]
  const filteredBatchEvents =
    batchEvents?.filter(
      (event) =>
        filterToEventNames.length === 0 || filterToEventNames.indexOf(event.eventName) !== -1
    ) ?? []
  if (!filteredBatchEvents?.length) {
    return <EmptyState>No events to display</EmptyState>
  }
  return (
    <>
      {filteredBatchEvents.map((event, index) => {
        const previousEvent = batchEvents[index - 1]
        const delay = previousEvent ? event.timestamp - previousEvent.timestamp : 0
        const latestPatch = patches.map.get(event.id)
        return (
          <BatchTimelineEvent
            delay={delay}
            developerMode={developerMode}
            event={latestPatch ? { ...event, ...latestPatch } : event}
            key={event.id}
            timelineFormat={timelineFormat}
            updateEvent={patches.updateById}
          />
        )
      })}
    </>
  )
}
