import { gql } from '@apollo/client'

export const EDIT_USER = gql`
  mutation EditUser(
    $id: ID!
    $name: String
    $email: LowercaseString
    $type: String
    $company: String
    $company_domain: String
    $license_needed: String
  ) {
    editUser(
      id: $id
      name: $name
      email: $email
      type: $type
      company: $company
      company_domain: $company_domain
      license_needed: $license_needed
    ) {
      id
      name
      email
      type
    }
  }
`
