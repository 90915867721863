import { useMemo, useState } from 'react'

import { TabAndContents } from 'src/applications/Oversight/hooks/useTabs'
import { GraphQLTabDetail } from 'src/applications/Oversight/panels/Concierge/WorkspaceSettingsTabs/GraphQLTabDetail'
import { ResourceSidebarItem } from 'src/resources/elements/ResourceSidebarItem'
import { UpdatedResourceBrowser } from 'src/resources/elements/UpdatedResourceBrowser'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_KNOWN_GRAPHQL_URIS } from 'src/smart/queries/SQ_GET_KNOWN_GRAPHQL_URIS'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

export const GraphQLTab: TabAndContents<{
  teamId: string
  workspace: IWorkspace
  refetchWorkspace(): Promise<void>
}> = {
  id: 'graphql',
  label: 'GraphQL Mutation Hooks',
  contents({ teamId, workspace }) {
    const [selectedResourceId, setSelectedResourceId] = useState<string | number | undefined>()

    const knownGraphQLURIs = useSmartQuery(SQ_GET_KNOWN_GRAPHQL_URIS, {
      variables: {
        teamId,
        archived: false
      }
    })

    const selectedResource = useMemo(() => {
      const resource =
        selectedResourceId && knownGraphQLURIs?.result?.find((x) => x === selectedResourceId)
      if (!resource && knownGraphQLURIs?.result?.[0]) {
        setSelectedResourceId(knownGraphQLURIs?.result?.[0])
        return knownGraphQLURIs?.result?.[0]
      }
      return resource
    }, [selectedResourceId, knownGraphQLURIs?.result])

    return (
      <UpdatedResourceBrowser
        sidebarConfig={{
          title: 'Library',
          description: 'List of GraphQL endpoints.'
        }}
        docsLink='https://flatfile.com/versioned-docs/3.0/api-reference/'
        items={knownGraphQLURIs?.result?.map((uri) => (
          <ResourceSidebarItem
            key={uri}
            onClick={setSelectedResourceId}
            name={uri}
            id={uri}
            selected={selectedResourceId === uri}
          />
        ))}
        selectedResourceId={selectedResource}
      >
        {selectedResource && (
          <GraphQLTabDetail workspace={workspace} graphQLEndpoint={selectedResource} />
        )}
      </UpdatedResourceBrowser>
    )
  }
}
