import { DELETABLE_KEYS, ISchema } from 'src/types/interfaces/ISchema'

const DEFAULT_JSON_SCHEMA = {
  schema: {
    properties: {},
    type: 'object'
  }
}

const getDefaultJsonSchema = (): any => {
  return JSON.parse(JSON.stringify(DEFAULT_JSON_SCHEMA))
}

const deserializeSchemaProperty = (schemaProperty: any): any => {
  if (schemaProperty.items) {
    schemaProperty.type = schemaProperty.items.type
    schemaProperty.isMultiple = true
  }

  const enumArray =
    schemaProperty.enum || (schemaProperty.items && schemaProperty.items.enum) || []
  const enumLabelArray =
    schemaProperty.enumLabel || (schemaProperty.items && schemaProperty.items.enumLabel) || []

  if (enumArray.length) {
    schemaProperty.type = 'category'
    schemaProperty.enumArray = enumArray.map((_value: string, index: number) => ({
      label: enumLabelArray[index],
      value: enumArray[index]
    }))
  }

  if (schemaProperty.format === 'email') {
    schemaProperty.type = 'email'
  }

  if (schemaProperty.format === 'phone') {
    schemaProperty.type = 'phone'
  }
  return schemaProperty
}

export const deserialize = (schema: any, deserializeLinkedProperties = false) => {
  schema.jsonSchema = schema.jsonSchema || getDefaultJsonSchema()
  const schemaProperties = schema.jsonSchema.schema.properties
  schema.jsonSchemaPropArray = Object.keys(schemaProperties).reduce((acc, key) => {
    const schemaProperty = deserializeSchemaProperty({ ...schemaProperties[key] })
    if (typeof schemaProperty.$schemaId === 'string' && deserializeLinkedProperties) {
      const linkedSchema = schema.linkedSchemas?.find(
        (linked: any) => linked.id === schemaProperty.$schemaId
      )
      if (linkedSchema) {
        const linkedSchemaProperties = (linkedSchema.jsonSchema || getDefaultJsonSchema()).schema
          .properties
        return acc.concat(
          Object.keys(linkedSchemaProperties).map((linkedKey) => {
            const linkedSchemaProperty = deserializeSchemaProperty({
              ...linkedSchemaProperties[linkedKey]
            })
            return {
              ...linkedSchemaProperty,
              $schema: {
                id: linkedSchema.id,
                name: linkedSchema.name,
                matchKeyLabel: schemaProperty.label,
                matchKey: key,
                matchKeySub: linkedKey
              },
              field: `${key}::${linkedKey}`,
              required: (linkedSchema.jsonSchema?.schema.required || []).includes(linkedKey),
              unique: (linkedSchema.jsonSchema?.schema.unique || []).includes(linkedKey),
              type: schemaProperty.type
            }
          })
        )
      }
    }
    return acc.concat({
      field: key,
      required: (schema.jsonSchema.schema.required || []).includes(key),
      unique: (schema.jsonSchema.schema.unique || []).includes(key),
      primary: schema.jsonSchema.schema.primary === key,
      ...schemaProperty
    })
  }, [])
  return schema
}

export const serialize = (schema: ISchema) => {
  // setting it to default as we serialize jsonSchemaPropArray everytime
  const defaultSchema = getDefaultJsonSchema()

  defaultSchema.schema.required = schema.jsonSchemaPropArray
    ?.filter((j) => j.required)
    .map((p) => p.field)

  defaultSchema.schema.unique = schema.jsonSchemaPropArray
    ?.filter((j) => j.unique)
    .map((p) => p.field)

  const { allowCustomFields } = schema.jsonSchema?.schema

  if (allowCustomFields) {
    defaultSchema.schema.allowCustomFields = true
  }

  const primary = schema.jsonSchemaPropArray?.find((j) => j.primary)
  defaultSchema.schema.primary = primary?.field || undefined

  schema.jsonSchema = defaultSchema

  schema.jsonSchemaPropArray?.forEach((prop) => {
    const schemaProp = { ...prop }

    if (schemaProp.type !== 'string') {
      schemaProp.regexp = undefined
    }

    DELETABLE_KEYS.forEach((key) => {
      delete schemaProp[key]
    })

    if (schemaProp.type !== 'category') {
      delete schemaProp.enumArray
      delete schemaProp.enum
      delete schemaProp.enumLabel
      delete schemaProp.enumLabelArray
    }
    if (schemaProp.type === 'category') {
      let enumValue = (prop.enumArray || []).filter((e) => e.label && e.value)
      enumValue = [...new Set(enumValue)]

      if (enumValue.length) {
        schemaProp.enumLabel = enumValue.map((e: any) => e.label)
        schemaProp.enum = enumValue.map((e: any) => e.value)
      }

      schemaProp.type = 'string'
    } else {
      schemaProp.enumLabelArray = undefined
      schemaProp.enumArray = undefined
    }

    if (schemaProp.type === 'date') {
      schemaProp.type = 'string'
      // 'date-time' is the right format in json schema.
      // https://json-schema.org/understanding-json-schema/reference/string.html#dates-and-times
      schemaProp.format = 'date'
      schemaProp.exportDateFormat = prop.exportDateFormat
    } else {
      schemaProp.format = undefined
      schemaProp.exportDateFormat = undefined
    }

    if (schemaProp.type === 'email') {
      schemaProp.type = 'string'
      schemaProp.format = 'email'
    }

    if (schemaProp.type === 'phone') {
      schemaProp.type = 'string'
      schemaProp.format = 'phone'
    }

    if (schemaProp.type !== 'number') {
      schemaProp.minimum = undefined
      schemaProp.maximum = undefined
    }

    if (schemaProp.type !== 'schema_ref' && '$schemaId' in schemaProp) {
      delete schemaProp.$schemaId
    }

    if (prop.isMultiple) {
      schemaProp.items = {
        type: schemaProp.type,
        enum: schemaProp.enum,
        enumLabel: schemaProp.enumLabel
      }
      schemaProp.type = 'array'
      delete schemaProp.enum
      delete schemaProp.enumLabel
    } else {
      schemaProp.items = undefined
    }

    delete schemaProp.enumArray
    delete schemaProp.enumLabelArray

    if (typeof schemaProp.default !== 'undefined' && !schemaProp.default) {
      delete schemaProp.default
    }

    schema.jsonSchema.schema.properties[prop.field] = schemaProp
  })

  delete schema.jsonSchemaPropArray
  return schema
}

export const deserializeErrors = (jsonSchemaErrors: string[]) => {
  return jsonSchemaErrors
    .map((e: string) => e.replace('instance.properties.', ''))
    .reduce((acc: any, error: any) => {
      const [key, message] = error.split('.')
      acc[key] = acc[key] || []
      acc[key].push(message)
      return acc
    }, {})
}

export const deserializeErrorsWithValues = (jsonSchemaErrors: string[], jsonSchema: any) => {
  return jsonSchemaErrors
    .map((e: string) => e.replace('instance.properties.', ''))
    .reduce((acc: any, error: any) => {
      const [key, message] = error.split('.')
      const [field] = message.split(' ')
      acc[key] = acc[key] || {}
      acc[key][field] = `${jsonSchema.schema.properties[key][field]} (${message})`
      return acc
    }, {})
}
