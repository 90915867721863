import { gql } from '@apollo/client'

export const CREATE_TEAM = gql`
  mutation CreateTeam(
    $meta: CreateTeamMetaDto
    $name: String!
    $plan: String
    $noPlan: Boolean
    $freeTrial: Boolean
    $companySize: String
  ) {
    createTeam(
      meta: $meta
      name: $name
      plan: $plan
      noPlan: $noPlan
      freeTrial: $freeTrial
      companySize: $companySize
    ) {
      id
      name
    }
  }
`
