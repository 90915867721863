import { Logo as FlatfileLogo } from 'src/resources/elements/Logo'
import flatfile from 'src/resources/images/flatfile-full.svg'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

export const Logo = styled.div`
  display: inline-block;
  width: 100px;
  height: 30px;
  background-image: url(${flatfile});
  background-size: contain;
  background-repeat: no-repeat;
`

export const LogoContainer = styled.a`
  display: block;
  margin-bottom: ${Spacing.basePadding2x};
`
export const MyLogo = styled(FlatfileLogo)`
  background-image: url(${flatfile});
`
export const SignupLogo = styled(LogoContainer)`
  left: 40px;
  overflow: hidden;
  position: absolute;
  top: 40px;
  width: 32px;
  & > div {
    background-size: 116px;
  }
`
