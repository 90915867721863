import { useSentryTransaction } from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { useModal } from 'src/resources/elements/Modal'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_UPDATE_ORGANIZATION } from 'src/smart/mutations/SM_UPDATE_ORGANIZATION'
import { IEditOrganizationFormData, IOrganizationStub } from 'src/types/interfaces/IOrganization'

const EditOrganizationFormElement: TForm<IEditOrganizationFormData> = Form

export const useEditOrganizationModal = (
  organization: IOrganizationStub,
  onUpdate?: () => void
) => {
  const sentryTransaction = useSentryTransaction({})
  const updateOrganization = useSmartMutation(SM_UPDATE_ORGANIZATION)

  const onSubmitAction = async (event: IFormSubmitEvent<IEditOrganizationFormData>) => {
    sentryTransaction.setTag('organization-update', 'manual')
    await updateOrganization.run({
      id: organization.id,
      name: event.data.name
    })
    editOrganizationModal.close()
    onUpdate()
  }

  const editOrganizationModal = useModal({
    clickProtect: true,
    header: 'Edit organization',
    contents: () => (
      <EditOrganization
        organization={organization}
        onClose={editOrganizationModal.close}
        onSubmit={onSubmitAction}
      />
    )
  })

  return {
    open: editOrganizationModal.open,
    render: editOrganizationModal.render
  }
}

export const EditOrganization = ({
  organization,
  onClose,
  onSubmit
}: {
  organization: IOrganizationStub
  onSubmit?: (event: IFormSubmitEvent<IEditOrganizationFormData>) => void
  onClose?: () => void
}) => {
  return (
    <EditOrganizationFormElement onSubmit={onSubmit} initialValue={{ name: organization.name }}>
      <InputGroup>
        <Input
          label='Organization name'
          name='name'
          placeholder='Coconut Labs'
          helperText='The name for the group you are collaborating with.'
          data-testid='edit-organization-name-label'
        />
        <ErrorMessage name='name' />
      </InputGroup>
      <ButtonGroup right>
        <FormButton secondary onClick={onClose} data-testid='cancel-update-button'>
          Cancel
        </FormButton>
        <FormButton primary submit data-testid='update-organization-button'>
          Update
        </FormButton>
      </ButtonGroup>
    </EditOrganizationFormElement>
  )
}
