import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { GET_USERS_FOR_WORKSPACE } from 'src/queries/GET_USERS_FOR_WORKSPACE'
import { CurrentUser_me } from 'src/queries/types/CurrentUser'
import {
  GetUsersForWorkspace,
  GetUsersForWorkspaceVariables
} from 'src/queries/types/GetUsersForWorkspace'
import { Colors } from 'src/resources/colors'
import { GroupAvatar } from 'src/resources/elements/Avatar'

export const OrganizationAvatars = ({
  workspaceId,
  currentUser
}: {
  workspaceId: string
  currentUser: CurrentUser_me
}) => {
  const { data: userData } = useQuery<GetUsersForWorkspace, GetUsersForWorkspaceVariables>(
    GET_USERS_FOR_WORKSPACE,
    {
      variables: {
        workspaceId
      }
    }
  )

  const names = useMemo(
    () =>
      userData?.getUsersForWorkspace?.length
        ? [...userData.getUsersForWorkspace.filter(({ id }) => id !== currentUser.id), currentUser]
        : [currentUser],
    [currentUser, userData?.getUsersForWorkspace]
  )

  return (
    <GroupAvatar names={names.map((x, _y) => x?.name)} showTooltips borderColor={Colors.gray20} />
  )
}
