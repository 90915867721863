import { gql } from 'apollo-boost'
import {
  GetSchemaMutationLogs,
  GetSchemaMutationLogsVariables,
  GetSchemaMutationLogs_getSchemaMutationLogs
} from 'src/smart/queries/types/GetSchemaMutationLogs'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_SCHEMA_MUTATION_LOGS: SmartQuery<
  GetSchemaMutationLogsVariables,
  GetSchemaMutationLogs,
  GetSchemaMutationLogs_getSchemaMutationLogs
> = {
  query: gql`
    query GetSchemaMutationLogs($take: Int!, $skip: Int!, $mutationId: UUID!) {
      getSchemaMutationLogs(take: $take, skip: $skip, mutationId: $mutationId) {
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
        data {
          errorMessage
          externalId
          requestHeaders
          requestBody
          requestDuration
          requestMethod
          requestSize
          requestUrl
          responseBody
          responseHeaders
          responseSize
          responseStatusCode
          success
          timestampRequestStart
          workspace {
            id
            name
          }
        }
      }
    }
  `,
  extract({ getSchemaMutationLogs }) {
    return getSchemaMutationLogs
  }
}
