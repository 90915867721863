import { Form, Formik, FormikProps } from 'formik'
import Select from 'react-select'
import styled from 'styled-components'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FormButton } from 'src/resources/elements/form/Button'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { FormikInput, InputGroup, RequiredMark } from 'src/resources/elements/form/Input'
import { Header } from 'src/resources/elements/Header'
import { IOrganization } from 'src/types/interfaces/IOrganization'
import { IUserStub } from 'src/types/interfaces/IUser'
import { fontSizes } from 'src/resources/typography'

interface ICreateDataRequestFormData {
  currentOwnerUserId: number | string
  email: string
  name: string
  schemas: string[]
  organizationId?: string
}

const initialValues: ICreateDataRequestFormData = {
  currentOwnerUserId: undefined,
  organizationId: undefined,
  email: '',
  name: '',
  schemas: []
}

export const InviteRow = styled.div`
  margin-bottom: 24px;
`

const Subheading = styled.div`
  font-size: ${fontSizes.type13};
`

export const CreateDataRequestForm = ({
  organizations,
  organizationId,
  users,
  onCancel,
  onSubmit,
  title
}: {
  organizations: IOrganization[]
  organizationId: string | undefined
  users: IUserStub[]
  onCancel?: () => void
  onSubmit: (event: ICreateDataRequestFormData) => Promise<void>
  title: boolean
}) => {
  const selectedOrganization = organizations.find((o) => o.id === organizationId)
  const userOptions = users.map((user) => {
    return { value: user.id, label: user.name }
  })
  const organizationOptions = organizations.map((organization) => {
    return { value: organization.id, label: organization.name }
  })

  const sentryTransaction = useSentryTransaction({})

  submitSentrySpan(sentryTransaction, 'create-data-request-loaded')

  return (
    <>
      {title && (
        <Header>
          <h2>Invite collaborators</h2>
          {selectedOrganization && (
            <Subheading>
              Collaborators will only have access to this Workspace. To grant a user access to all
              of your workspaces, add them to your team.
            </Subheading>
          )}
        </Header>
      )}
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit, ...props }: FormikProps<any>) => (
          <Form>
            {!organizationId ? (
              <InputGroup>
                <label aria-required='true'>
                  Workspace Organization<RequiredMark>*</RequiredMark>
                  <Select
                    isDisabled={isSubmitting}
                    options={organizationOptions}
                    label='Workspace Organization'
                    name='organizationId'
                    value={userOptions.find(
                      (option) => option.value === props.values.organizationId
                    )}
                    onChange={({ value }: { value: string }) =>
                      props.setFieldValue('organizationId', value)
                    }
                  />
                </label>
              </InputGroup>
            ) : null}

            <InputGroup>
              <InviteRow>
                <FormikInput
                  disabled={isSubmitting}
                  label='Email address'
                  placeholder='you@example.com'
                  required
                  type='email'
                  name='email'
                  onKeys={{ Escape: onCancel }}
                />
                <ErrorMessage name='email' />
              </InviteRow>

              <FormButton
                primary
                onClick={() => handleSubmit()}
                disabled={
                  isSubmitting ||
                  (props.values.email || '').length <= 0 ||
                  (!organizationId &&
                    (!props.values.organizationId || props.values.organizationId.length <= 0))
                }
              >
                Send
              </FormButton>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </>
  )
}
