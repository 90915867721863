export const env = {
  CHARGEBEE_PUBLISHABLE_KEY: process.env.CHARGEBEE_PUBLISHABLE_KEY,
  CHARGEBEE_SITE: process.env.CHARGEBEE_SITE,
  COMMIT_REF: process.env.COMMIT_REF,
  DEFAULT_PAGE_SIZE: process.env.DEFAULT_PAGE_SIZE,
  FEATURE_FLAG_EXPORT: process.env.FEATURE_FLAG_EXPORT,
  FEATURE_FLAG_SEARCH_WORKSPACE: process.env.FEATURE_FLAG_SEARCH_WORKSPACE,
  FEATURE_FLAG_TASKS: process.env.FEATURE_FLAG_TASKS,
  FEATURE_FLAG_UPLOADS: process.env.FEATURE_FLAG_UPLOADS,
  FLATFILE_DEVELOPERS_BASE: process.env.FLATFILE_DEVELOPERS_BASE,
  FLATFILE_ENV: process.env.FLATFILE_ENV,
  FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS:
    process.env.FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS,
  FLATFILE_GEOGRAPHIC_REGION_LABEL: process.env.FLATFILE_GEOGRAPHIC_REGION_LABEL,
  FLATFILE_PRODUCT_HOMEPAGE: process.env.FLATFILE_PRODUCT_HOMEPAGE,
  FLATFILE_REFINERY_URL: process.env.FLATFILE_REFINERY_URL,
  FLATFILE_REFINERY_WS_URL: process.env.FLATFILE_REFINERY_WS_URL,
  FLATFILE_SFTP_ENDPOINT: process.env.FLATFILE_SFTP_ENDPOINT,
  NODE_ENV: process.env.NODE_ENV,
  PAGE_QUERY_PARAM: process.env.PAGE_QUERY_PARAM,
  SENTRY_DEBUG: process.env.SENTRY_DEBUG,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENABLED: process.env.SENTRY_ENABLED,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE: process.env.SENTRY_TRACES_SAMPLE_RATE,
  WAIT_TIME_BEFORE_POLLING: process.env.WAIT_TIME_BEFORE_POLLING,
  POST_HOG_API_KEY: process.env.POST_HOG_API_KEY,
  POST_HOG_HOST: process.env.POST_HOG_HOST
}
