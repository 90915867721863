import { gql } from '@apollo/client'
import {
  SmartRemoveTeamUser,
  SmartRemoveTeamUserVariables,
  SmartRemoveTeamUser_removeTeamUser
} from 'src/smart/mutations/types/SmartRemoveTeamUser'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_REMOVE_TEAM_USER: SmartMutation<
  SmartRemoveTeamUserVariables,
  SmartRemoveTeamUser,
  SmartRemoveTeamUser_removeTeamUser
> = {
  mutation: gql`
    mutation SmartRemoveTeamUser($teamId: ID!, $removeUserId: ID!) {
      removeTeamUser(teamId: $teamId, removeUserId: $removeUserId) {
        success
      }
    }
  `,
  extract: (response) => response.removeTeamUser
}
