import Skeleton from 'react-loading-skeleton'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

const WorkspaceSettingsFormWrapper = styled.div`
  margin-bottom: 12px;
  position: relative;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .right {
    margin-left: auto;
    order: 2;
  }

  .ml-15 {
    margin-left: 15px;
  }
`

const RightSide = styled.div``

const AvatarContainer = styled.div`
  display: inline;
  right: 35px;
  position: relative;

  .overlap {
    position: relative;
    right: -15px;
  }

  .pr-40 {
    padding-right: 40px;
  }
`

export const WorkspaceActionBarSkeleton = named('WorkspaceActionBarSkeleton', (hasAvatars) => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <WorkspaceSettingsFormWrapper>
        <Skeleton width={180} height={20} />
        <Skeleton width={50} height={20} className='ml-15' />

        <RightSide className='right'>
          {hasAvatars ? (
            <>
              <AvatarContainer>
                <Skeleton circle={true} width={40} height={40} className='mt-16 overlap' />
                <Skeleton circle={true} width={40} height={40} className='mt-16 pr-40' />
              </AvatarContainer>
            </>
          ) : null}
          <Skeleton width={78} height={40} className='mt-16' />
        </RightSide>
      </WorkspaceSettingsFormWrapper>
    </>
  )
})
