import { UpdateUploadDataSourceField_updateUploadDataSourceField_validation } from 'src/queries/types/UpdateUploadDataSourceField'
import { SmartViewFields_getViewFields_data } from 'src/smart/queries/types/SmartViewFields'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'

interface IDataSourceField extends Partial<SmartViewFields_getViewFields_data> {
  jsonProp?: JsonSchemaProperty
}

export interface IUpdateDataSourceField {
  matchKey?: string
  matchKeySub?: string
  confirmed?: boolean
  label?: string
  ignored?: boolean
  matchMeta?: Record<string, string>
  jsonProp?: JsonSchemaProperty
  customMatchKey?: boolean
  force?: boolean
}

export class DataSourceField {
  public id: number
  public label: string // source column label
  public matchKey?: string
  public matchKeySub?: string
  public originalMatchKey?: string
  public matchMeta?: Record<string, string>
  public order: number
  public previewData?: string[]
  public confirmed: boolean // user selected the match
  public ignored: boolean
  public suggestedMatchKey?: string[]
  public jsonProp?: JsonSchemaProperty
  public customMatchKey?: boolean
  public columnDensity?: number
  public totalRows?: number

  constructor(raw: IDataSourceField) {
    Object.assign(this, {
      ...raw,
      id: parseInt(raw.id, 10)
    })
  }

  public get isMatched(): boolean {
    if (this.ignored) {
      return false
    }

    // TODO: move to helper folder
    function allValuesPresent(obj?: {}): boolean {
      return obj && Object.values(obj).every((value: any) => (value ?? null) !== null)
    }

    if (this.matchKey) {
      if (this.isEnum) {
        return allValuesPresent(this.matchMeta)
      }
      return true
    }
    return false
  }

  public get isEnum(): boolean {
    return !!(this.jsonProp?.enum && this.jsonProp?.enumArray)
  }

  public async update(payload: IUpdateDataSourceField) {
    return Object.assign(this, payload)
  }
}

export type ISourceFieldValidation =
  UpdateUploadDataSourceField_updateUploadDataSourceField_validation
