import { gql } from '@apollo/client'
import { SmartWorkspace, SmartWorkspaceVariables } from 'src/smart/queries/types/SmartWorkspace'
import { SmartQuery } from 'src/smart/SmartQuery'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

export const SQ_WORKSPACE: SmartQuery<SmartWorkspaceVariables, SmartWorkspace, IWorkspace> = {
  query: gql`
    query SmartWorkspace($workspaceId: UUID!) {
      getWorkspace(workspaceId: $workspaceId) {
        id
        name
        createdAt
        updatedAt
        lastActivity
        status
        primaryWorkbookId
        dataEngine
        secureEnv {
          variables
          signedKeys
        }
        primaryWorkbook {
          status
        }
      }
    }
  `,
  extract({ getWorkspace }) {
    return getWorkspace
  }
}
