import { ReactNode, useCallback, useState } from 'react'

import ClickAwayListener from 'react-click-away-listener'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { useModal } from 'src/resources/elements/Modal'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const PopupMenuContainer = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 8px 24px 0 rgba(16, 22, 26, 0.2);
  box-sizing: border-box;
  min-width: 140px;
  padding: ${Spacing.basePadding};
  position: absolute;
  right: 0;
  top: 24px;
`

export interface MenuOption {
  confirmationModalContents?: JSX.Element
  label: string | JSX.Element
  action(): boolean | Promise<boolean>
}

const OptionContainer = styled.div`
  cursor: pointer;
  min-width: 180px;
  padding: ${Spacing.basePadding};

  &:hover {
    background: ${Colors.blue};
    color: ${Colors.white};
  }
`

const MenuTapArea = styled.div`
  cursor: pointer;
  padding: ${Spacing.basePadding};
`

function MenuOption({
  onSelect,
  option
}: {
  option: MenuOption
  onSelect: (option: MenuOption) => void
}) {
  const doSelect = useCallback(() => {
    onSelect(option)
  }, [onSelect, option])
  return <OptionContainer onClick={doSelect}>{option.label}</OptionContainer>
}

export function Menu({ children, options }: { options: MenuOption[]; children: ReactNode }) {
  const [confirmOption, setConfirmOption] = useState<MenuOption>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const confirmationModal = useModal({
    contents(): JSX.Element {
      return (
        <>
          {confirmOption.confirmationModalContents}
          <FlatButton
            color='secondary'
            disabled={isSubmitting}
            onClick={closeModal}
            style={{ marginRight: Spacing.basePadding }}
          >
            Cancel
          </FlatButton>
          <FlatButton disabled={isSubmitting} color='primary' onClick={confirm}>
            Confirm
          </FlatButton>
        </>
      )
    }
  })
  const closeModal = useCallback(() => {
    confirmationModal.close()
  }, [confirmationModal])
  const confirm = useCallback(async () => {
    setIsSubmitting(true)
    if (await confirmOption.action()) {
      closeModal()
    }
    setIsSubmitting(false)
  }, [confirmOption?.action, closeModal, setIsSubmitting])
  const [open, setOpen] = useState(false)
  const toggleMenu = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])
  const selectOption = useCallback(
    (option: MenuOption) => {
      if (option.confirmationModalContents) {
        setConfirmOption(option)
        confirmationModal.open()
      } else {
        option.action()
      }
      setOpen(false)
    },
    [setOpen]
  )
  const clickAway = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  return (
    <>
      {confirmationModal.render()}
      {open ? (
        <ClickAwayListener onClickAway={clickAway}>
          <PopupMenuContainer>
            {options.map((option, i) => (
              <MenuOption key={i} option={option} onSelect={selectOption} />
            ))}
          </PopupMenuContainer>
        </ClickAwayListener>
      ) : null}
      <MenuTapArea onClick={toggleMenu}>{children}</MenuTapArea>
    </>
  )
}
