import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AceInput } from 'src/resources/elements/form/AceInput'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputLabel } from 'src/resources/elements/form/Input'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { Spacing } from 'src/resources/layout'
import { fontMonospace } from 'src/resources/typography'
import { submitForm } from 'src/resources/utils/submitForm'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_SCHEMA_MUTATION_VARIABLES } from 'src/smart/queries/SQ_GET_SCHEMA_MUTATION_VARIABLES'
import { ISchemaMutation } from 'src/types/interfaces/ISchemaMutation'
import styled from 'styled-components'
// @todo, move this to src/types/globalTypes
import { UpdateSchemaMutationDTO } from '../../../../types/globalTypes'
import { Tooltip } from './Tooltip'

const InfoPanel = styled.div`
  min-width: 320px;
  width: 480px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  h3 {
    font-weight: normal;
    margin: 0;
    padding: ${Spacing.basePadding2x} ${Spacing.basePadding2x} 0;
  }
`

const InputContainer = styled.div`
  padding: ${Spacing.basePadding2x};

  label {
    margin-top: 0;
  }

  & + & {
    padding-top: 0;
  }

  code {
    font-family: ${fontMonospace};
  }
`
const VariablesTable = styled.table`
  font-size: 13px;
`

const TooltipWrapper = styled.div`
  max-width: max-content;
`

const VariableNameCell = styled.td`
  font-family: ${fontMonospace};
  font-weight: bold;
  padding: ${Spacing.basePadding} 0 0;
`

const DescriptionCell = styled.td`
  padding-left: ${Spacing.basePadding};
`

const MUTATION_TYPES = [
  { label: 'delete', value: 'delete' },
  { label: 'insert', value: 'insert' },
  { label: 'update', value: 'update' }
]

const insertResultKeyHelpText = `
  This value returned by the insert mutation in this field
  will be available to use in update or delete mutations
`

const EditSchemaMutationFormElement: TForm<ISchemaMutation> = Form

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 56px;
  width: 100%;
`

export function EditSchemaMutationForm({
  knownGraphQLURIs,
  mutation,
  onSubmit
}: {
  knownGraphQLURIs: string[]
  mutation: ISchemaMutation
  onSubmit(event: IFormSubmitEvent<UpdateSchemaMutationDTO>): void | Promise<void>
}) {
  const mutationVariablesQuery = useSmartQuery(SQ_GET_SCHEMA_MUTATION_VARIABLES, {
    fetchPolicy: 'network-only',
    variables: {
      mutationId: mutation.id
    }
  })
  const formElementRef = useRef<HTMLFormElement>()
  const graphQLURIOptions: SelectOption[] = useMemo(() => {
    const allKnown = [{ label: 'None', value: null }].concat(
      knownGraphQLURIs.map((uri) => ({ label: uri, value: uri }))
    )
    if (mutation.graphQLURI && !knownGraphQLURIs.includes(mutation.graphQLURI)) {
      allKnown.push({
        label: mutation.graphQLURI,
        value: mutation.graphQLURI
      })
    }
    return allKnown
  }, [knownGraphQLURIs])

  const save = useCallback(() => {
    setTimeout(() => {
      submitForm(formElementRef.current)
    })
  }, [formElementRef])

  const [inputHeight, setInputHeight] = useState('100px')
  const wrapRef = useRef()

  useEffect(() => {
    const {
      current: { clientHeight }
    } = wrapRef ?? { current: { clientHeight: '100px' } }
    setInputHeight(`${clientHeight}px`)
  }, [wrapRef])

  const disabled = mutation.archived

  return (
    <EditSchemaMutationFormElement
      formRef={formElementRef}
      editingId={mutation.id}
      initialValue={{
        archived: mutation.archived,
        enabled: mutation.enabled,
        includeUserHeaders: mutation.includeUserHeaders,
        graphQLURI: mutation.graphQLURI,
        id: mutation.id,
        mutationBody: mutation.mutationBody,
        mutationType: mutation.mutationType,
        resultPrimaryKey: mutation.resultPrimaryKey
      }}
      onSubmit={onSubmit}
    >
      <Wrap ref={wrapRef}>
        <InfoPanel>
          <h3>Mutation</h3>
          <InputContainer>
            <Select
              disabled={disabled}
              label='Type'
              name='mutationType'
              onBlur={save}
              options={MUTATION_TYPES}
              required={true}
              selectedValue={null}
            />
          </InputContainer>
          <InputContainer>
            <Select
              creatable
              disabled={disabled}
              label='GraphQL Endpoint'
              name='graphQLURI'
              onBlur={save}
              options={graphQLURIOptions}
              required={true}
              selectedValue={null}
            />
          </InputContainer>
          <InputContainer>
            <BooleanInput disabled={disabled} onChange={save} label='Enabled' name='enabled' />
          </InputContainer>
          {mutation.mutationType === 'insert' && (
            <InputContainer>
              <Input
                disabled={disabled}
                onBlur={save}
                label='Primary result field'
                helperText={insertResultKeyHelpText}
                name='resultPrimaryKey'
              />
            </InputContainer>
          )}
          <InputContainer>
            <InputLabel>Example Mutation</InputLabel>
            <code>
              <pre>
                {`mutation MutationName(
  $myFieldA: String!
) {
  yourMutation (
    varNameA: $myFieldA
  ) {
    id
  }
}
`}
              </pre>
            </code>
          </InputContainer>
          <InputContainer>
            <InputLabel>Variables</InputLabel>
            <VariablesTable>
              <tbody>
                {Object.entries(mutationVariablesQuery.result ?? {}).map(([key, description]) => (
                  <Fragment key={key}>
                    <tr>
                      <VariableNameCell>${key}</VariableNameCell>
                    </tr>
                    <tr>
                      <DescriptionCell>{description}</DescriptionCell>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </VariablesTable>
          </InputContainer>
          <InputContainer>
            <Input
              disabled={disabled}
              autoSelect
              copyButton
              fullWidth
              readOnly
              label='Mutation ID'
              name='id'
              initialValue={mutation.id}
            />
          </InputContainer>
          <InputContainer>
            <TooltipWrapper data-tip='include-user-headers' data-for='include-user-headers'>
              <BooleanInput
                onChange={save}
                label='Include identifying headers'
                name='includeUserHeaders'
              />
              <Tooltip
                offset={{ top: 0, left: 0 }}
                id='include-user-headers'
                content='X-Flatfile-User-Id, X-Flatfile-User-Email, X-Flatfile-EndUser-Id, X-Flatfile-EndUser-Email'
              />
            </TooltipWrapper>
          </InputContainer>
          <InputContainer>
            <BooleanInput onChange={save} label='Archived' name='archived' />
          </InputContainer>
        </InfoPanel>
        <AceInput
          height={inputHeight}
          disabled={mutation.archived}
          onChange={save}
          name='mutationBody'
        />
      </Wrap>
    </EditSchemaMutationFormElement>
  )
}
