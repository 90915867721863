import { ToastProvider } from 'react-toast-notifications'
import { getUserContext } from 'src/contexts/UserContext'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { useModal } from 'src/resources/elements/Modal'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'

interface ILoginAsFormData {
  email: string
}

const LoginAsFormElement: TForm<ILoginAsFormData> = Form

export const useLoginAsModal = () => {
  const userContext = getUserContext()

  const onSubmitAction = async (event: IFormSubmitEvent<ILoginAsFormData>) => {
    try {
      const loggedInUser = await UserTransceiver.login({
        superAdminId: userContext.value.user?.id,
        targetUserEmail: event.data.email,
        type: 'superAdmin'
      })
      userContext.setValue({ user: loggedInUser })
      window.location.assign('/')
    } catch (e) {
      event.formContext.setValue({
        errors: { email: 'Invalid email. Try again.' }
      })
    }
  }

  const loginAsModal = useModal({
    clickProtect: true,
    header: 'Login as',
    contents: () => <LoginAsComponent onClose={loginAsModal.close} onSubmit={onSubmitAction} />
  })

  return {
    open: loginAsModal.open,
    render: loginAsModal.render
  }
}

export const LoginAsComponent = ({
  onClose,
  onSubmit
}: {
  onSubmit?: (event: IFormSubmitEvent<ILoginAsFormData>) => void
  onClose?: () => void
}) => {
  return (
    <ToastProvider>
      <LoginAsFormElement onSubmit={onSubmit}>
        <InputGroup>
          <Input
            label='Email address'
            name='email'
            autoFocus
            onKeys={{ Escape: onClose }}
            data-testid='login-as-email-label'
          />
          <ErrorMessage name='email' />
        </InputGroup>
        <ButtonGroup left>
          <FormButton primary submit data-testid='switch-account-button'>
            Switch account
          </FormButton>
          <FormButton secondary onClick={onClose} data-testid='cancel-switch-button'>
            Cancel
          </FormButton>
        </ButtonGroup>
      </LoginAsFormElement>
    </ToastProvider>
  )
}
