import { useContext } from 'react'

import {
  ITestAccessKeyFormData,
  TestAccessKeyForm
} from 'src/applications/Oversight/forms/TestAccessKeyForm'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { TeamContext } from 'src/contexts/TeamContext'
import { IModal, useModal } from 'src/resources/elements/Modal'
import { ResponseInfo } from 'src/resources/elements/ResponseInfo'
import { useFetch } from 'src/resources/hooks/useFetch'
import { IAccessKey } from 'src/types/interfaces/IAccessKey'
import { grow } from 'src/utils/grow'
import { ref } from 'src/utils/ref'

const TestAccessKeyModalHead = () => {
  const team = useContext(TeamContext)
  const [testAccessKey, testAccessKeyStatus] = useFetch<ITestAccessKeyFormData, {}>(
    ({ accessKeyId, secretAccessKey }) => [
      `${FLATFILE_REFINERY_URL}/rest/batches?licenseKey=${team.licenses[0].key}`,
      {
        headers: { 'X-Api-Key': `${accessKeyId}+${secretAccessKey}` }
      }
    ]
  )

  return { testAccessKey, testAccessKeyStatus }
}

export const useTestAccessKeyModal = grow(
  TestAccessKeyModalHead,
  (
    { testAccessKey, testAccessKeyStatus },
    { accessKeyToTest }: { accessKeyToTest?: IAccessKey }
  ) =>
    ref<IModal>((self) =>
      useModal({
        header: 'Test access key',
        width: '500px',
        contents: () => (
          <>
            <TestAccessKeyForm
              accessKeyId={accessKeyToTest.accessKeyId}
              onCancel={() => self.current.close()}
              onSubmit={async ({ data: { accessKeyId, secretAccessKey } }) => {
                await testAccessKey({
                  accessKeyId,
                  secretAccessKey
                })
              }}
            >
              {testAccessKeyStatus ? <ResponseInfo response={testAccessKeyStatus} /> : null}
            </TestAccessKeyForm>
          </>
        )
      })
    )
)
