import { useState, useRef, useEffect } from 'react'
import { Spinner } from 'src/resources/elements/Spinner'
import { EDeploymentState } from 'src/types/enums/EDeploymentState'
import { EStatusType } from 'src/types/enums/EStatusType'
import styled from 'styled-components'
import { Status } from 'src/resources/elements/Status'

const StatusString = styled.span`
  white-space: nowrap;
`

export const StatusPill = ({
  status,
  hasChanges = false,
  archived = false
}: {
  status: EDeploymentState
  hasChanges?: boolean
  archived?: boolean
}) => {
  const type = () => {
    if (hasChanges) {
      return EStatusType.needsAttention
    }
    if (archived) {
      return EStatusType.inProgress
    }
    switch (status) {
      case EDeploymentState.DEPLOYING:
      case EDeploymentState.READONLY:
        return EStatusType.inProgress
      case EDeploymentState.DEPLOY_FAILURE:
        return EStatusType.failed
      case EDeploymentState.DEPLOY_SUCCESS:
      case EDeploymentState.SDK:
        return EStatusType.completed
      case EDeploymentState.DRAFT:
        return EStatusType.needsAttention
    }
  }
  const statusString = () => {
    if (hasChanges) {
      return 'Changes Not Deployed'
    }
    if (archived) {
      return 'Inactive'
    }
    switch (status) {
      case EDeploymentState.DEPLOYING:
        return 'Deploying'
      case EDeploymentState.DEPLOY_FAILURE:
        return 'Failure'
      case EDeploymentState.DEPLOY_SUCCESS:
        return 'Deployed'
      case EDeploymentState.DRAFT:
        return 'Draft'
      case EDeploymentState.SDK:
        return 'Deployed from SDK'
      case EDeploymentState.READONLY:
        return 'Read-Only'
    }
  }
  const [statusStringWidth, setstatusStringWidth] = useState(36)
  const statusStringRef = useRef(null)

  useEffect(() => {
    const deploying = status === EDeploymentState.DEPLOYING ? 32 : 22
    setstatusStringWidth(statusStringRef.current.offsetWidth + deploying)
  }, [status, hasChanges])

  return (
    <Status type={type()} width={statusStringWidth}>
      <StatusString ref={statusStringRef}>
        {status === EDeploymentState.DEPLOYING && <Spinner />} {statusString()}
      </StatusString>
    </Status>
  )
}
