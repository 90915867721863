import { gql } from '@apollo/client'
import { useSubscription } from '@apollo/client'

import {
  DataHookInSchemaUpdatedSubscription,
  DataHookInSchemaUpdatedSubscriptionVariables
} from './types/DataHookInSchemaUpdatedSubscription'

export const DATAHOOK_UPDATED_SUBSCRIPTION = gql`
  subscription DataHookInSchemaUpdatedSubscription($schemaId: Int!) {
    datahookUpdated(schemaId: $schemaId) {
      id
      name
      description
      archived
      deploymentState
      deploymentError
      packageJSON
      code
      ancestor {
        id
      }
      root {
        id
      }
      schemaId
    }
  }
`

export const useDataHookUpdatedSubscription = (schemaId?: string) => {
  const { data } = useSubscription<
    DataHookInSchemaUpdatedSubscription,
    DataHookInSchemaUpdatedSubscriptionVariables
  >(DATAHOOK_UPDATED_SUBSCRIPTION, {
    variables: {
      schemaId: Number(schemaId)
    }
  })
  return data?.datahookUpdated ? data.datahookUpdated : undefined
}
