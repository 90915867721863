import { useContext } from 'react'

import WelcomeBanner from 'src/applications/Oversight/components/WelcomeBanner'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import BuildIllustration from 'src/resources/images/BuildIllustration@2x.png'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { device } from 'src/resources/utils/mediaQuery'
import styled from 'styled-components'

const DemoBanner = styled.div`
  background-color: #f2f6fc;
  background-image: url(${BuildIllustration});
  background-position: right;
  background-repeat: no-repeat;
  background-size: 406px 204px;
  border-radius: ${Spacing.halfBasePadding};
  display: flex;
  margin: ${Spacing.basePadding3x};
  padding: ${Spacing.basePadding4x};

  @media screen and ${device.tablet} {
    background-size: 304px 153px;
  }
`

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  > h1 {
    color: #303346;
    opacity: 0.8;
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type24};
    margin: ${Spacing.basePadding2x} 0;
  }
  > p {
    font-family: ${fontFamily.fontSecondary};
    font-size: ${fontSizes.type18};
    color: ${Colors.text};
  }
`

const ButtonGroup = styled.div`
  display: flex;
  margin-top: ${Spacing.basePadding2x};

  & > a:not(:last-child) {
    margin-right: ${Spacing.basePadding2x};
  }
`

const Section = styled.div`
  border-radius: ${Spacing.halfBasePadding};
  border: 1px solid ${Colors.border};
  margin-top: ${Spacing.basePadding3x};
`

const Padding = styled.div`
  padding: ${Spacing.basePadding4x};
`

export const WaitlistPanel = () => {
  const team = useContext(TeamContext)
  const getDisplayName = team.users[0].name.split(' ')[0]

  return (
    <Card>
      <Padding>
        <WelcomeBanner isWaitlist name={getDisplayName} />
        <Section>
          <DemoBanner>
            <BannerText>
              <h1>Get Concierge faster, or book a demo while you wait</h1>
              <p>
                Open up your spot sooner by telling us about your data onboarding. Or, get in touch
                with our team to watch a demo and see how Concierge changes everything.
              </p>
              <ButtonGroup>
                <FlatButton
                  renderAs='a'
                  aria-label='Open discovery form'
                  href='https://flatfile.freshsales.io/web_forms/5d8c95dd4a301e400f40f3e49356b8734d8b1ece07193bc877ab799a057f2c8f/form.html'
                >
                  Discovery Form
                </FlatButton>
                <FlatButton
                  renderAs='a'
                  variant='outlined'
                  aria-label='Book a Concierge demo with our Success team'
                  href='https://calendly.com/wiafe/concierge-workshop'
                >
                  Book a demo
                </FlatButton>
              </ButtonGroup>
            </BannerText>
          </DemoBanner>
        </Section>
      </Padding>
    </Card>
  )
}
