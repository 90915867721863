import { Colors } from 'src/resources/colors'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const Badge = styled.div`
  vertical-align: middle;
  position: relative;
  display: inline-block;
  padding: 4px;
  font-size: ${fontSizes.type12};
  background: ${Colors.gray20};
  border: 1px solid ${Colors.border};
  border-radius: 4px;

  & + & {
    margin-left: 6px;
  }
`
