import { createContext, Dispatch, ReactNode, useState } from 'react'
import { GetBatches_getBatches_data_views } from 'src/queries/types/GetBatches'

export interface IFileUploadState {
  percent: number
  workspaceId?: string
  finished?: boolean
  view?: any
}

export interface IFileUploadContext {
  fileUploadState: { [key: string]: IFileUploadState }
  setFileUploadState: Dispatch<any>
  batchViews: { [key: string]: GetBatches_getBatches_data_views[] }
  setBatchViews: Dispatch<any>
}

export const FileUploadContext = createContext<IFileUploadContext>({
  fileUploadState: {},
  setFileUploadState: undefined,
  batchViews: {},
  setBatchViews: undefined
})

export const FileUploadProvider = ({ children }: { children: ReactNode }) => {
  const [fileUploadState, setFileUploadState] = useState<{ [key: string]: IFileUploadState }>(null)
  const [batchViews, setBatchViews] = useState<{
    [key: string]: GetBatches_getBatches_data_views[]
  }>({})
  return (
    <FileUploadContext.Provider
      value={{ fileUploadState, setFileUploadState, batchViews, setBatchViews }}
    >
      {children}
    </FileUploadContext.Provider>
  )
}
