import { useCallback } from 'react'

import { Route, Switch } from 'react-router'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { UploadRoutes } from 'src/applications/Oversight/routes/UploadRoutes'
import { useModal } from 'src/resources/elements/Modal'
import { useParams } from 'src/resources/hooks/useParams'
import useReactRouter from 'use-react-router'

import { WorkspaceUploadDataSourceScene } from './WorkspaceUploadDataSourceScene'

export const WorkspaceUploadScene = () => {
  const teamRoot = useTeamRootUrl()

  const { history, match } = useReactRouter()
  const { teamId, workspaceId } = useParams<{
    teamId: string
    uploadId: string
    workspaceId: string
  }>()

  const onClose = useCallback(() => {
    history.push(`${teamRoot}/workspaces/${workspaceId}`)
    return false
  }, [history, teamId, workspaceId])

  const sourceModal = useModal({
    contents: () => <WorkspaceUploadDataSourceScene />,
    onClose,
    initialIsOpenState: true,
    width: '440px',
    zIndex: 101
  })

  const uploadModal = useModal({
    contents: () => <UploadRoutes closeModal={uploadModal.close} />,
    onClose,
    initialIsOpenState: true,
    fullscreen: true,
    modalStyle: {
      height: '100vh',
      borderRadius: 0
    },
    width: '100%',
    zIndex: 101
  })

  const uploadPath = match.path

  return (
    <Switch>
      <Route component={sourceModal.render} exact path={`${uploadPath}/source`} />
      <Route component={uploadModal.render} />
    </Switch>
  )
}
