import { Spacing } from 'src/resources/layout'
import { IConfigValue } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

export type DispatchChangeOverride = (configValue: IConfigValue, isOverride: boolean) => void

export const IconWrap = styled.span`
  min-width: ${Spacing.basePadding4x};
  text-align: center;
`

export const TextWrap = styled.span`
  font-weight: bold;
  padding: 0 ${Spacing.basePadding};
`

export const Vertical = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  & > * {
    flex-direction: column;
    width: 100%;
  }
`

export const StyledBigEmoji = styled.span`
  filter: grayscale(1) brightness(0) blur(4px);
  font-size: 60px;
  mix-blend-mode: luminosity;
  opacity: 0.7;

  &:nth-of-type(2) {
    filter: grayscale(1) brightness(100);
    position: absolute;
  }
`

const StyledBigEmojiWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 92px;
  position: relative;
`

export const EmojiIcon = ({ children }: { children: string }) => (
  <StyledBigEmojiWrap>
    <StyledBigEmoji>{children}</StyledBigEmoji>
    <StyledBigEmoji>{children}</StyledBigEmoji>
  </StyledBigEmojiWrap>
)
