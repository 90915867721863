import { gql } from '@apollo/client'
import {
  CreateEmbed,
  CreateEmbedVariables,
  CreateEmbed_createEmbed
} from 'src/smart/mutations/types/CreateEmbed'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_CREATE_EMBED: SmartMutation<
  CreateEmbedVariables,
  CreateEmbed,
  CreateEmbed_createEmbed
> = {
  mutation: gql`
    mutation CreateEmbed($name: String!, $teamId: ID!, $schemaIds: [ID!]!, $environmentId: UUID) {
      createEmbed(
        name: $name
        teamId: $teamId
        schemaIds: $schemaIds
        environmentId: $environmentId
      ) {
        embed {
          id
          name
          team {
            id
            name
          }
          teamId
          privateKey {
            id
          }
          privateKeyId
        }
        privateKeyString
      }
    }
  `,
  extract: (response: any) => response.createEmbed
}
