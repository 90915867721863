import { MutationTuple, useMutation } from '@apollo/client'
import { MAP_DATA_SOURCE_AND_DATA_TEMPLATE } from 'src/queries/MAP_DATA_SOURCE_AND_DATA_TEMPLATE'
import {
  MapDataSourceAndDataTemplate,
  MapDataSourceAndDataTemplateVariables
} from 'src/queries/types/MapDataSourceAndDataTemplate'

export const useMapDataSourceAndDataTemplateMutation = (): MutationTuple<
  MapDataSourceAndDataTemplate,
  MapDataSourceAndDataTemplateVariables
> => {
  const [mapDataSourceAndDataTemplate, mapDataSourceAndDataTemplateVariables] = useMutation<
    MapDataSourceAndDataTemplate,
    MapDataSourceAndDataTemplateVariables
  >(MAP_DATA_SOURCE_AND_DATA_TEMPLATE)

  return [mapDataSourceAndDataTemplate, mapDataSourceAndDataTemplateVariables]
}
