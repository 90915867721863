import { gql } from 'apollo-boost'
import {
  SmartGetBatchEvents,
  SmartGetBatchEventsVariables,
  SmartGetBatchEvents_getBatchEvents
} from 'src/smart/queries/types/SmartGetBatchEvents'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_BATCH_EVENTS: SmartQuery<
  SmartGetBatchEventsVariables,
  SmartGetBatchEvents,
  SmartGetBatchEvents_getBatchEvents
> = {
  query: gql`
    query SmartGetBatchEvents(
      $batchId: UUID
      $deleted: Boolean
      $skip: Int
      $take: Int
      $teamId: ID!
      $workspaceId: UUID
    ) {
      getBatchEvents(
        batchId: $batchId
        deleted: $deleted
        skip: $skip
        take: $take
        teamId: $teamId
        workspaceId: $workspaceId
      ) {
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
        data {
          countColumns
          countRows
          error
          eventData
          eventDuration
          eventLabel
          eventName
          fileName
          fileSize
          id
          parentEventId
          startEventId
          systemComponent
          systemWorkerType
          timestamp
          user {
            email
            name
          }
        }
      }
    }
  `,
  extract({ getBatchEvents }) {
    return getBatchEvents
  }
}
