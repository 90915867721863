import { gql } from '@apollo/client'
import { GetFeatureFlagsPublic } from 'src/smart/queries/types/GetFeatureFlagsPublic'
import { SmartQuery } from 'src/smart/SmartQuery'
import { FeatureFlags } from 'src/types/interfaces/FeatureFlags'

export const SQ_FEATURE_FLAGS_PUBLIC: SmartQuery<
  undefined,
  GetFeatureFlagsPublic,
  Partial<FeatureFlags>
> = {
  query: gql`
    query GetFeatureFlagsPublic {
      getFeatureFlagsPublic
    }
  `,
  extract({ getFeatureFlagsPublic }) {
    const flags: Partial<FeatureFlags> = {}
    for (const key of getFeatureFlagsPublic) {
      flags[key as keyof FeatureFlags] = true
    }
    return flags
  }
}
