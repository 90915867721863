import { Field } from 'formik'
import { KeyboardEvent, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Colors } from 'src/resources/colors'
import { Icon } from 'src/resources/elements/Icon'
import { Eye, EyeWithSlash } from 'src/resources/elements/Icons'
import { inputStyles } from 'src/resources/inputs'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { getFormContext } from './Form'
import { HelperText } from 'src/resources/elements/form/FieldLabel'
import { Transitions } from 'src/resources/animations/transitions'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'

const flatInputStyles = css<{ fullWidth?: boolean; type?: string }>`
  background-color: ${Colors.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: ${Spacing.basePadding} ${Spacing.basePadding5x} ${Spacing.basePadding}
    ${Spacing.baseAndHalfPadding};
  border-radius: 5px;
  border: ${inputStyles.border};
  font-size: ${fontSizes.type15};
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? 'auto' : '260px')};
  min-height: ${Spacing.basePadding5x};
  box-sizing: border-box;
  transition: ${Transitions.baseEase};

  &::placeholder {
    color: ${Colors.pigeon600};
  }
  &::selection {
    background: ${Colors.brandSecondary};
    color: white;
  }

  ${({ type }) =>
    type === 'password'
      ? css`
          letter-spacing: 0.075em;
        `
      : undefined}

  &:disabled {
    background-color: ${Colors.pigeon100};
    border-color: transparent;
    color: ${Colors.pigeon800};

    &:hover {
      border-color: transparent;
    }
  }

  & + label {
    font-weight: 600;
    pointer-events: none;
    color: ${Colors.pigeon700};
  }

  &:hover {
    border-color: ${Colors.pigeon400};
    cursor: text;
  }

  &:focus {
    border-color: ${Colors.brandPrimary};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

export const FlatInputDiv = styled.div<{ fullWidth?: boolean }>`
  ${flatInputStyles};
  cursor: default;
`

export const FlatInput = styled.input<{ fullWidth?: boolean }>`
  ${flatInputStyles}
`

export const TransparentInput = styled.input<{ fullWidth?: boolean; italic?: boolean }>`
  ${flatInputStyles}
  border-color: transparent;
  padding: 6px 12px;
  box-shadow: none;
  font-size: ${fontSizes.type16};
  font-weight: 600;
  margin: 0;
  margin-left: -12px;
  width: calc(100% + 24px);
  ${({ italic }) => italic && 'font-style: italic;'}
  &:hover {
    border-color: ${Colors.border};
  }
  &:focus {
    border-color: ${Colors.brandPrimary};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

export const FlatTextArea = styled.textarea<{ fullWidth?: boolean; height?: string }>`
  ${flatInputStyles};
  height: ${({ height }) => height ?? '200px'};
`

export const InputGroup = styled.div`
  & + div {
    margin-top: ${Spacing.basePadding2_5x};
  }

  i {
    display: block;
    font-size: 0.75rem;
    color: ${Colors.grayText};
    margin-top: 0.5rem;
  }
`

export const InputLabel = styled.label`
  color: ${Colors.textAccent};
  display: block;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type14};
  font-weight: 600;
  margin: ${Spacing.basePadding2x} auto 0;
  width: 100%;
`

export const FlatInputContainer = styled.div`
  flex-grow: 1;
  font-weight: 400;
  max-width: 560px;
  position: relative;
  width: 100%;

  input {
    max-width: unset;
    width: 100%;
  }

  small {
    color: ${Colors.textAccent};
    display: block;
    font-size: ${fontSizes.type13};
    letter-spacing: 0;
    margin-bottom: ${Spacing.halfBasePadding};
  }

  &:hover {
    cursor: pointer;
  }
`

export const FieldInput = styled(Field)<{ maxWidth?: string }>`
  background-color: ${Colors.white};
  padding: ${Spacing.basePadding} ${Spacing.baseAndHalfPadding};
  margin-top: ${Spacing.basePadding};
  border-radius: 4px;
  border: 1px solid ${Colors.border};
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : null)};
  box-sizing: border-box;
  transition: ${Transitions.baseEase};
  outline: transparent dashed 1px;

  ${({ type }) =>
    type === 'password'
      ? css`
          letter-spacing: 0.075em;
        `
      : undefined}

  &:disabled {
    background-color: ${Colors.gray20};
    border-color: transparent;
    color: ${Colors.textAccent};

    &:hover {
      border-color: transparent;
    }
  }

  & + label {
    font-weight: 600;
    pointer-events: none;
    color: ${Colors.grayText};
  }

  &:hover {
    border: 1px solid ${Colors.brandSecondaryText};
  }

  &:focus {
    box-shadow: 0 2px 7px rgba(84, 115, 238, 0.2), inset 0 1px 5px rgba(80, 80, 80, 0.1);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

export const RequiredMark = styled.span`
  color: ${Colors.red};
  margin-left: ${Spacing.halfBasePadding};
`

const OptionalFieldText = styled.span`
  color: ${Colors.pigeon600};
  font-weight: normal;
  float: right;
`

const InputAndCopyButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.basePadding};
`

export interface ICommonInputProps {
  'aria-label'?: string
  autoComplete?: string
  autoFocus?: boolean
  autoSelect?: boolean
  disabled?: boolean
  fullWidth?: boolean
  helperText?: string
  id?: string
  initialValue?: string
  label?: string
  name?: string
  optional?: boolean
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  showRequired?: boolean
  tabIndex?: number
  transform?: (value: string) => string
  type?: string
}

export interface IInputProps extends ICommonInputProps {
  copyButton?: boolean
  formValue?: string | number | boolean
  helpButton?: JSX.Element
  inputRef?: MutableRefObject<HTMLInputElement>
  onBlur?: (option: any) => void
  onChange?: (option: any) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  onKeys?: Record<string, () => void>
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void
}

const InsideInputIconButton = styled(Icon)`
  background: transparent;
  color: ${Colors.gray60};
  position: absolute;
  right: 10px;

  &:hover {
    color: ${Colors.brandSecondary};
  }

  &:active {
    opacity: 0.5;
  }
`

const ShowHideIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 47px;
  right: 2%;
  width: 32px;
  height: 32px;

  &:hover {
    border-radius: 50%;
    background: ${Colors.pigeon100};
  }

  svg path {
    fill: ${Colors.pigeon800};
  }
`
const InputHeaderFlex = styled.div`
  cursor: default;
  display: flex;
  align-items: flex-end;
`

export const Input = ({
  autoFocus,
  autoSelect,
  copyButton,
  disabled,
  formValue,
  helpButton,
  helperText,
  id,
  initialValue,
  inputRef,
  label,
  name,
  optional,
  placeholder,
  readOnly,
  required,
  showRequired,
  tabIndex,
  transform,
  type,
  onBlur,
  onChange,
  onKeyDown,
  onKeys,
  onKeyUp,
  ...props
}: IInputProps) => {
  const { addToast } = useToasts()
  const formContext = getFormContext()
  const inputElRef = useRef(null)
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  if (required && name) {
    formContext.value.requiredFields[name] = true
  }

  const value =
    formValue !== undefined
      ? formValue
      : name && name in formContext.value.data
      ? formContext.value.data[name]
      : ''

  const update = (updateValue: string) => {
    if (transform) {
      updateValue = transform(updateValue)
    }
    if (onChange) {
      onChange(updateValue)
    } else if (name) {
      const { data } = formContext.value
      formContext.setValue({ data: { ...data, [name]: updateValue } })
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeys && event.key in onKeys) {
      onKeys[event.key]()
    } else if (event.key === 'Escape') {
      document.body.focus()
    }
    onKeyDown?.(event)
  }

  const copy = useCallback(() => {
    ;(inputRef ?? inputElRef).current.select()
    document.execCommand('copy')
    addToast(`Copied '${value}' to clipboard`, {
      appearance: 'success',
      autoDismiss: true
    })
  }, [value])

  const togglePasswordVisible = useCallback(() => {
    setPasswordVisible(!passwordVisible)
  }, [passwordVisible, setPasswordVisible])

  const labelId = useMemo(() => Math.random().toString(), [])
  const tooltipOffset = useMemo(() => ({ top: 2, left: 6 }), [])
  const InputElement: typeof FlatInput = (
    type === 'textarea' ? FlatTextArea : FlatInput
  ) as typeof FlatInput
  return (
    <FlatInputContainer>
      <InputHeaderFlex>
        <InputHeader
          labelId={labelId}
          id={id}
          label={label}
          optional={optional}
          required={required}
          showRequired={showRequired}
        />
        {helpButton}
      </InputHeaderFlex>
      <InputAndCopyButtonContainer>
        <InputElement
          aria-labelledby={labelId}
          autoFocus={autoFocus}
          disabled={disabled || formContext.value.submitting}
          id={id}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={inputRef ?? inputElRef}
          tabIndex={tabIndex || 0}
          type={passwordVisible ? 'text' : type}
          value={value ?? ''}
          onBlur={(e) => onBlur && onBlur(e.target.value)}
          onChange={(e) => update(e.target.value)}
          onFocus={(e) => autoSelect && e.target.select()}
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          {...props}
        />
        {copyButton && <InsideInputIconButton name='copy' onClick={copy} />}
      </InputAndCopyButtonContainer>
      {helperText ? <HelperText>{helperText}</HelperText> : null}
      {type === 'password' && !readOnly && (
        <ShowHideIconWrap
          onClick={togglePasswordVisible}
          data-testid='show-hide-icon-wrap'
          data-tip='showHidePassword'
          data-for='showHidePassword'
        >
          {passwordVisible ? <EyeWithSlash /> : <Eye />}
          <Tooltip
            id='showHidePassword'
            content={passwordVisible ? 'Hide password' : 'Show password'}
            place='right'
            offset={tooltipOffset}
          />
        </ShowHideIconWrap>
      )}
    </FlatInputContainer>
  )
}

export function InputHeader({
  labelId,
  id,
  helperText,
  label,
  optional,
  required,
  showRequired
}: Pick<IInputProps, 'id' | 'helperText' | 'label' | 'optional' | 'required' | 'showRequired'> & {
  labelId?: string
}) {
  return (
    <>
      {label || required ? (
        <InputLabel
          id={labelId}
          title={required ? `${label} is required` : undefined}
          htmlFor={id}
        >
          {label}
          {required || showRequired ? <RequiredMark>*</RequiredMark> : null}
          {optional ? <OptionalFieldText>Optional</OptionalFieldText> : null}
        </InputLabel>
      ) : null}
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </>
  )
}

export const FormikInput = ({
  autoFocus,
  disabled,
  helperText,
  label,
  name,
  onKeys,
  placeholder,
  required,
  type
}: {
  autoFocus?: boolean
  disabled?: boolean
  helperText?: string
  label?: string
  name?: string
  onKeys?: Record<string, () => void>
  placeholder?: string
  required?: boolean
  type?: string
}) => {
  const labelId = label ? label.toLowerCase().replace(/\s/g, '-') : undefined

  return (
    <FlatInputContainer>
      {label || required ? (
        <InputLabel id={`label-${labelId}`} title={required ? `${label} is required` : undefined}>
          {label}
          {required ? <RequiredMark>*</RequiredMark> : null}
        </InputLabel>
      ) : null}
      <FieldInput
        aria-labelledby={`label-${labelId} `}
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        onKeyDown={(e: KeyboardEvent) => onKeys?.[e.key]?.()}
        placeholder={placeholder}
        type={type}
      />
      {helperText ? <small>{helperText}</small> : null}
    </FlatInputContainer>
  )
}
