import { MutationTuple, useMutation } from '@apollo/client'
import { INITIALIZE_BATCH_AND_UPLOAD } from 'src/queries/INITIALIZE_BATCH_AND_UPLOAD'
import {
  InitializeBatchAndUpload,
  InitializeBatchAndUploadVariables
} from 'src/queries/types/InitializeBatchAndUpload'

export const useInitializeBatchAndUploadMutation = (
  batchId?: string
): MutationTuple<InitializeBatchAndUpload, InitializeBatchAndUploadVariables> => {
  const options = batchId ? {} : { refetchQueries: ['GetBatches'] }
  const [initializeFile, initializeFileStatus] = useMutation<
    InitializeBatchAndUpload,
    InitializeBatchAndUploadVariables
  >(INITIALIZE_BATCH_AND_UPLOAD, options)

  return [initializeFile, initializeFileStatus]
}
