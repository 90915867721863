import { useCallback, useState } from 'react'
import { Toolbar } from 'src/applications/Oversight/controls/Toolbar'
import { TokenExpiredForm } from 'src/applications/Oversight/forms/TokenExpiredForm'
import { LoginContentContainer } from 'src/applications/Oversight/scenes/LoginScene'
import { GetWorkspaceInvitation_getWorkspaceInvitation } from 'src/queries/types/GetWorkspaceInvitation'
import { ErrorInfoIcon, FlexError } from 'src/resources/elements/form/ErrorMessage'
import { useWorkspaceInvite } from 'src/applications/Oversight/hooks/useWorkspaceInvite'
import { useParams } from 'react-router-dom'

export const TokenExpiredScene = ({
  data
}: {
  data: GetWorkspaceInvitation_getWorkspaceInvitation
}) => {
  const [sent, setSent] = useState(false)
  const { token } = useParams<{ ref: string; token: string }>()
  const { refreshInvite } = useWorkspaceInvite()

  const reSendInvite = useCallback(async () => {
    const { success } = await refreshInvite(token)

    setSent(success)
  }, [])

  return (
    <>
      <Toolbar />
      <LoginContentContainer>
        <FlexError>
          <ErrorInfoIcon />
          <div>
            This activation link has expired. To request a new activation link, click the button
            below.
          </div>
        </FlexError>
        <TokenExpiredForm data={data} onSubmit={reSendInvite} sent={sent} />
      </LoginContentContainer>
    </>
  )
}
