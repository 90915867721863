import { gql } from '@apollo/client'
import {
  GetDefaultOrganization,
  GetDefaultOrganizationVariables
} from 'src/smart/queries/types/GetDefaultOrganization'
import { SmartQuery } from 'src/smart/SmartQuery'
import { IOrganization } from 'src/types/interfaces/IOrganization'

import { useFindOne } from '../hooks/useSmartQuery'

export const SQ_WORKSPACE_ORGANIZATION: SmartQuery<
  GetDefaultOrganizationVariables,
  GetDefaultOrganization,
  IOrganization
> = {
  query: gql`
    query GetDefaultOrganization($workspaceId: UUID!) {
      getDefaultOrganizationForWorkspace(workspaceId: $workspaceId) {
        id
        teamId
        name
        createdAt
        updatedAt
      }
    }
  `,
  extract({ getDefaultOrganizationForWorkspace }) {
    return getDefaultOrganizationForWorkspace
  }
}

export const useFindDefaultOrganizationForWorkspace = (workspaceId: string) => {
  return useFindOne<
    GetDefaultOrganization,
    GetDefaultOrganizationVariables,
    'getDefaultOrganizationForWorkspace'
  >(
    SQ_WORKSPACE_ORGANIZATION.query,
    {
      workspaceId
    },
    'getDefaultOrganizationForWorkspace'
  )
}
