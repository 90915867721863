import { MutationTuple, useMutation } from '@apollo/client'
import {
  UpdateUploadStatus,
  UpdateUploadStatusVariables
} from 'src/queries/types/UpdateUploadStatus'
import { UPDATE_UPLOAD_STATUS } from 'src/queries/UPDATE_UPLOAD_STATUS'

export const useUpdateUploadStatusMutation = (): MutationTuple<
  UpdateUploadStatus,
  UpdateUploadStatusVariables
> => {
  const [updateUploadStatus, updateUploadStatusStatus] = useMutation<
    UpdateUploadStatus,
    UpdateUploadStatusVariables
  >(UPDATE_UPLOAD_STATUS)

  return [updateUploadStatus, updateUploadStatusStatus]
}
