import Skeleton from 'react-loading-skeleton'
import { EmbedLayout } from 'src/applications/Embed/fragments/Layout'
import { Colors } from 'src/resources/colors'
import MatchingArrow from 'src/resources/icons/MatchingArrow'
import { named } from 'src/resources/utils/named'
import * as Layout from 'src/applications/Embed/elements/LayoutElements'

import { DataPreviewSkeleton } from '../components/DataPreview'
import { Header } from '../components/Header'
import { Table, TableRow, TableWrapper } from '../styles'

export const MatchingSkeleton = named('MatchingSkeleton', () => {
  return (
    <EmbedLayout>
      <Layout.Content>
        <Layout.FlexVertical>
          <Header />
          <TableWrapper style={{ overflow: 'hidden', pointerEvents: 'none' }}>
            <Table>
              <thead>
                <TableRow>
                  <th />
                  <th>Columns in your file</th>
                  <th>
                    <MatchingArrow fill={Colors.horizontalRuleGray} />
                  </th>
                  <th>Our fields</th>
                  <th />
                </TableRow>
              </thead>
              <tbody>
                {Array.from(new Array(6)).map((_, i) => (
                  <TableRow key={i}>
                    <td>
                      <Skeleton width={20} height={20} />
                    </td>
                    <td>
                      <Skeleton width={120} height={20} />
                    </td>
                    <td>
                      <MatchingArrow />
                    </td>
                    <td>
                      <Skeleton width={120} height={20} />
                    </td>
                    <td />
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </Layout.FlexVertical>
      </Layout.Content>
      <Layout.Sidebar>
        <DataPreviewSkeleton />
      </Layout.Sidebar>
    </EmbedLayout>
  )
})
