import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'

import {
  UpdateTeamFeatureFlag,
  UpdateTeamFeatureFlagVariables,
  UpdateTeamFeatureFlag_updateTeamFeatureFlag
} from './types/UpdateTeamFeatureFlag'

export const SM_UPDATE_TEAM_FEATURE_FLAG: SmartMutation<
  UpdateTeamFeatureFlagVariables,
  UpdateTeamFeatureFlag,
  UpdateTeamFeatureFlag_updateTeamFeatureFlag
> = {
  mutation: gql`
    mutation UpdateTeamFeatureFlag($teamId: Int!, $featureId: Int!, $enabled: Boolean!) {
      updateTeamFeatureFlag(teamId: $teamId, featureId: $featureId, enabled: $enabled) {
        message
        success
      }
    }
  `,
  extract: (response) => response.updateTeamFeatureFlag
}
