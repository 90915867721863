export const fontMonospace = 'Source Code Pro, monospace'
export const fontPrimary = 'Proxima Nova, sans-serif'
export const fontSecondary = 'Aktiv Grotesk, sans-serif'

export const fontFamily = {
  fontMonospace: [
    'Source Code Pro',
    'Consolas',
    'Andale Mono WT',
    'Andale Mono',
    'Lucida Console',
    'Lucida Sans Typewriter',
    'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono',
    'Liberation Mono',
    'Nimbus Mono L',
    'Monaco',
    'Courier New',
    'Courier',
    'monospace'
  ]
    .map((x) => `"${x}"`)
    .join(', '),
  fontPrimary: 'Proxima Nova, sans-serif',
  fontSecondary: 'Avenir Next, Avenir, Helvetica, Arial, sans-serif',
  fontSerif: 'PT Serif, serif'
}

export const fontSizes = {
  type10: '0.5rem',
  type11: '0.65rem',
  type12: '0.75rem',
  type13: '0.8125rem',
  type14: '0.875rem',
  type15: '0.9375rem',
  type16: '1rem',
  type17: '1.0625rem',
  type18: '1.125rem',
  type19: '1.18rem',
  type20: '1.25rem',
  type21: '1.3125rem',
  type24: '1.5rem',
  type28: '1.75rem',
  type32: '2rem',
  type48: '3rem'
}

export const kernGilroy = '0.125em'
