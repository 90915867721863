import { gql } from '@apollo/client'

export const GET_BATCH = gql`
  query GetBatch($batchId: UUID!) {
    getBatch(batchId: $batchId) {
      countColumns
      countColumnsMatched
      countRows
      countRowsAccepted
      countRowsInvalid
      createdAt
      endUser {
        companyId
        companyName
        email
        id
        name
        userId
      }
      failedAt
      failureReason
      filename
      handledAt
      headerHash
      headersMatched {
        index
        matched_key
        value
      }
      headersRaw {
        index
        value
      }
      id
      importedFromUrl
      managed
      manual
      matchedAt
      memo
      originalFile
      parsingConfig
      status
      synced
      submittedAt
      type
      updatedAt
      validatedIn
      embedId
      schemaId
      workspaceId
      schema {
        id
        name
        jsonSchema {
          schema
        }
      }
      template {
        id
        name
        jsonSchema {
          schema
        }
      }
      teamId
      uploads {
        id
        fileName
        fileSize
        fileType
        selectedDataSourceIndex
        signedUrl
      }
      views {
        id
        status
        type
      }
      workspace {
        dataEngine
        primaryWorkbook {
          sheets {
            id
          }
        }
      }
    }
  }
`
