import { Colors } from 'src/resources/colors'
import { css } from 'styled-components'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

import { IFlatButtonStyle } from '../IFlatButton'

export const DangerFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'empty':
        return css`
          background-color: transparent !important;
          color: ${Colors.dangerStrong} !important;

          &:hover {
            color: ${Colors.redDark} !important;
          }
        `
      case 'outlined':
        return css`
          background-color: transparent !important;
          border-color: ${({ theme }) =>
            theme.modalSecondaryDangerButtonStyle?.borderColor ?? Colors.dangerStrong} !important;
          color: ${({ theme }) =>
            theme.modalSecondaryDangerButtonStyle?.color ?? Colors.dangerStrong} !important;

          &:hover {
            background-color: ${({ theme }) =>
              theme.modalSecondaryDangerButtonStyle?.hover?.backgroundColor ??
              Colors.dangerStrong} !important;
            border-color: ${({ theme }) =>
              theme.modalSecondaryDangerButtonStyle?.hover?.borderColor ??
              Colors.dangerStrong} !important;
            color: white !important;
          }

          &:focus {
            outline: ${({ theme }) =>
              theme.modalSecondaryDangerButtonStyle?.focus?.outline ??
              `2px solid ${themed('primary')}`} !important;
            outline-offset: ${({ theme }) =>
              theme.modalSecondaryDangerButtonStyle?.focus?.outlineOffset ?? `2px`} !important;
          }
        `
      default:
        return css`
          background-color: ${Colors.dangerStrong} !important;
          color: ${Colors.white} !important;

          &:hover {
            background-color: ${Colors.redDark} !important;
          }
        `
    }
  }}
`
