import { ReactNode } from 'react'

import { FLATFILE_PRODUCT_HOMEPAGE } from 'src/config'
import { Colors } from 'src/resources/colors'
import { StyledButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Header } from 'src/resources/elements/Header'
import { MyLogo, SignupLogo } from 'src/resources/elements/Logo'
import Dots from 'src/resources/images/dots.svg'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { device } from 'src/resources/utils/mediaQuery'
import styled, { createGlobalStyle, css } from 'styled-components'

const Grid = styled.main`
  display: inline-grid;
  width: 100%;
  min-height: 100vh;

  @media screen and ${device.tabletAndUp} {
    grid-template-columns: 60% 40%;
  }
`

const RightSideContents = styled.div<{ bgColor?: string }>`
  display: none;
  background: ${({ bgColor = Colors.navy }) => bgColor};
  padding: ${Spacing.basePadding3x};
  overflow: hidden;

  @media screen and ${device.tabletAndUp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const MainContents = styled.div<{ showBG: boolean }>`
  ${({ showBG }) =>
    showBG
      ? css`
          background: url(${Dots});
          background-size: 100px 100px;
          background-position: left;
          background-repeat: no-repeat;
        `
      : null}
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 112px 0;

  a {
    color: ${Colors.brandPrimary};
  }

  ${StyledButtonGroup} {
    div,
    a,
    button {
      width: 100%;
    }
    a,
    button {
      font-size: 1rem;
      line-height: 1.8;
    }
  }

  ${Header} {
    text-align: center;
  }

  @media screen and ${device.tabletAndUp} {
    input,
    select {
      padding: 14px;
      font-size: ${fontSizes.type14};
      max-width: 100%;
      min-width: 320px;
    }

    select:invalid {
      color: #a1a1a1;
    }
  }
`

const FullHeight = createGlobalStyle`
  body, html { height: 100%; }
  
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 0.7;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    opacity: 0.7;
  }
  :-ms-input-placeholder { /* IE 10+ */
    opacity: 0.7;
  }
  :-moz-placeholder { /* Firefox 18- */
    opacity: 0.7;
  }

`

export const SplitScreenTemplate = ({
  children,
  rightSide,
  rightSideColor,
  fullHeight = true,
  showBG,
  showLogo = true
}: {
  children: ReactNode
  rightSide: ReactNode
  rightSideColor?: string
  fullHeight?: boolean
  showBG?: boolean
  showLogo?: boolean
}) => (
  <Grid>
    {fullHeight && <FullHeight />}
    <MainContents showBG={showBG}>
      {showLogo && (
        <SignupLogo href={FLATFILE_PRODUCT_HOMEPAGE} target='_blank'>
          <MyLogo />
        </SignupLogo>
      )}
      {children}
    </MainContents>
    <RightSideContents bgColor={rightSideColor}>{rightSide}</RightSideContents>
  </Grid>
)
