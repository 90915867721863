import { useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useIsMounted } from 'src/resources/hooks/useIsMounted'
import { SubscriptionWithPollFallback } from 'src/smart/SubscriptionWithPollFallback'
import { WAIT_TIME_BEFORE_POLLING } from 'src/config'

export const useSubscriptionWithPollFallback = <Variables, RawResult, Result, FallbackResult>({
  extract,
  subscription,
  options,
  pollFallback: {
    startPollingAfter = Number(WAIT_TIME_BEFORE_POLLING ?? 10 * 1000), // default to 10s before falling back
    fallback,
    pollInterval = 1000 // 1s polling interval
  }
}: SubscriptionWithPollFallback<RawResult, Result>) => {
  const [fallbackResult, setFallbackResult] = useState<FallbackResult>()
  const isMounted = useIsMounted()
  const { data: subscriptionData } = useSubscription<RawResult, Variables>(subscription, {
    ...options,
    shouldResubscribe: true
  })

  const subscriptionResult = subscriptionData ? extract(subscriptionData) : undefined

  useEffect(() => {
    let idInterval: NodeJS.Timeout
    let idTimeout: NodeJS.Timeout

    const startPoll = () => {
      idTimeout = setTimeout(() => {
        clearInterval(idInterval)
        idInterval = setInterval(tick, pollInterval)
      }, startPollingAfter)
    }

    startPoll()

    async function tick() {
      if (!isMounted.current) {
        return
      }

      if (subscriptionResult) {
        clearInterval(idInterval)
        startPoll()
      } else {
        const pollResult = await fallback()

        if (pollResult) {
          setFallbackResult(pollResult)
          clearInterval(idInterval)
          startPoll()
        }
      }
    }

    return () => {
      clearTimeout(idTimeout)
      clearInterval(idInterval)
    }
  }, [pollInterval, subscriptionResult, fallback])

  const loading = !subscriptionResult && !fallbackResult

  return { result: subscriptionResult, loading, fallbackResult }
}
