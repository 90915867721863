import './debug/wdyr'

import { render } from 'react-dom'

import { modalHost } from 'src/applications/Oversight/modalHost'

import { Oversight } from '.'

export const mount = (doc: Document) => {
  const container = doc.createElement('div')
  doc.body.appendChild(container)
  doc.body.appendChild(modalHost)
  render(<Oversight />, container)
}

mount(document)
