import { gql } from '@apollo/client'

export const EXPORT_UPDATED_SUBSCRIPTION = gql`
  subscription ExportUpdated($exportId: UUID!) {
    exportUpdated(exportId: $exportId) {
      id
      status
    }
  }
`
