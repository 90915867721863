import { useEffect } from 'react'

import { createContextState } from 'src/resources/hooks/createContextState'
import useReactRouter from 'use-react-router'

export enum EImportDetailView {
  final = 'final',
  original = 'original'
}

export interface IActiveImportDetailContext {
  activeImportDetailViewType: EImportDetailView
  setActiveImportDetailViewType: (type: EImportDetailView) => void
}

export const [ActiveImportDetailContext, getActiveImportDetailContext] =
  createContextState<IActiveImportDetailContext>('ActiveImportDetail', {
    activeImportDetailViewType: EImportDetailView.final,
    setActiveImportDetailViewType: (_type: EImportDetailView) => null
  })

export const ActiveImportDetail = ({
  children
}: {
  children(params: IActiveImportDetailContext): JSX.Element
}) => {
  const { history, location } = useReactRouter()
  const basicInfoContext = getActiveImportDetailContext()

  useEffect(() => {
    const currentViewType: any = location.search.split('viewType=')[1]
    if (currentViewType && Object.values(EImportDetailView).indexOf(currentViewType) > -1) {
      basicInfoContext.setValue({ activeImportDetailViewType: currentViewType })
    }
  }, [location.pathname, location.search])

  const { activeImportDetailViewType } = getActiveImportDetailContext().value

  const setViewTypeAndReRoute = (type: EImportDetailView) => {
    basicInfoContext.setValue({ activeImportDetailViewType: type })
    history.replace(`${location.pathname}?viewType=${type}`)
  }

  return children({
    activeImportDetailViewType,
    setActiveImportDetailViewType: setViewTypeAndReRoute
  })
}
