export const pluralize = (text: string, count: number, pluralText?: string) => {
  switch (text.toLowerCase()) {
    case 'are':
      return count === 1 ? 'is' : text
    case 'is':
      return count === 1 ? text : 'are'
    default:
      return count === 1 ? text : pluralText || `${text}s`
  }
}
