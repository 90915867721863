import { gql } from '@apollo/client'

import { SmartMutation } from '../SmartMutation'
import {
  InitializeWorkbook,
  InitializeWorkbookVariables,
  InitializeWorkbook_initializeWorkbook
} from './types/InitializeWorkbook'

export const SM_INIT_WORKBOOK: SmartMutation<
  InitializeWorkbookVariables,
  InitializeWorkbook,
  InitializeWorkbook_initializeWorkbook
> = {
  mutation: gql`
    mutation InitializeWorkbook($batchId: UUID!, $mergeField: String, $existingWorkbookId: UUID) {
      initializeWorkbook(
        batchId: $batchId
        mergeField: $mergeField
        existingWorkbookId: $existingWorkbookId
      ) {
        id
        type
        archived
      }
    }
  `,
  extract: (response: any) => response.initializeWorkbook
}
