import { gql } from '@apollo/client'
import {
  UpdateDataHook,
  UpdateDataHookVariables,
  UpdateDataHook_updateDataHook
} from 'src/smart/mutations/types/UpdateDataHook'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_DATAHOOK: SmartMutation<
  UpdateDataHookVariables,
  UpdateDataHook,
  UpdateDataHook_updateDataHook
> = {
  mutation: gql`
    mutation UpdateDataHook(
      $id: UUID!
      $name: String
      $description: String
      $code: String
      $packageJSON: String
      $archived: Boolean
    ) {
      updateDataHook(
        id: $id
        name: $name
        archived: $archived
        description: $description
        code: $code
        packageJSON: $packageJSON
      ) {
        schema {
          id
        }
        dataHook {
          id
          archived
          name
          description
          code
          packageJSON
          deploymentState
          lambdaARN
          createdAt
          updatedAt
          root {
            id
          }
          ancestor {
            id
          }
        }
      }
    }
  `,
  extract: (response) => response.updateDataHook
}
