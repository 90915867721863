import { SVGProps } from 'react'

export const MobileNotAllowedIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 66' {...props}>
    <path
      fillRule='evenodd'
      d='M38 59.006A6.992 6.992 0 0130.995 66H7.005C3.14 66 0 62.866 0 59.008V6.992A6.991 6.991 0 017.005 0h23.99C34.86 0 38 3.138 38 6.994v52.012zM36 14V6.994C36 4.243 33.756 2 30.995 2H7.005A4.991 4.991 0 002 6.992V14h34zM2 59.008C2 61.76 4.243 64 7.005 64h23.99A4.992 4.992 0 0036 59.006V52H2v7.008zM2 50h34V16H2v34zM16 9a1 1 0 110-2h6a1 1 0 110 2h-6zm3 51a2 2 0 110-4 2 2 0 010 4zm0-28.414l5.293-5.293a1 1 0 011.414 1.414L20.414 33l5.293 5.293a1 1 0 01-1.414 1.414L19 34.414l-5.293 5.293a1 1 0 01-1.414-1.414L17.586 33l-5.293-5.293a1 1 0 011.414-1.414L19 31.586z'
    />
  </svg>
)
