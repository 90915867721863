const temporaryStorageData: { [key: string]: string | null } = {}

export const temporaryStorage = {
  getItem(key: string): string | null {
    if (key in temporaryStorageData) {
      return temporaryStorageData[key]
    }
    return null
  },
  removeItem(key: string) {
    temporaryStorageData[key] = null
  },
  setItem(key: string, value: string) {
    temporaryStorageData[key] = value
  }
}
