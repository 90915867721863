import { RowChangeDetail, CellChangeDetail } from '@turntable/core'

import { Atom, IAtomMessage, IAtomMeta } from './atoms/Atom'
import { BooleanAtom } from './atoms/BooleanAtom'
import { DefaultAtom } from './atoms/DefaultAtom'
import { EnumAtom } from './atoms/EnumAtom'
import { SchemaRefAtom } from './atoms/SchemaRefAtom'
import { EPropertyType, Schema } from './Schema'
import { PrimitiveRecord } from './VirtualRecord'

export class VirtualRecordFragment {
  public state: PrimitiveRecord = {}

  constructor(public readonly schema: Schema) {}

  /**
   * Get a single cell/atom abstraction to work with.
   *
   * @param key
   * @returns
   */
  public getAtom(key: string): Atom {
    const property = this.schema.getProperty(key)
    const value = this.state[key] ?? null

    const meta: IAtomMeta = {
      key,
      column: this.schema.getVisibleIndexByProperty(key),
      messages: this.getMessages(key)
    }
    switch (property.type) {
      case EPropertyType.ENUM:
        return new EnumAtom(property, value, meta)
      case EPropertyType.SCHEMA_REF:
        return new SchemaRefAtom(property, value, meta)
      case EPropertyType.BOOLEAN:
        return new BooleanAtom(property, value, meta)
      default:
        return new DefaultAtom(property, value, meta)
    }
  }

  public patch({ patch }: RowChangeDetail): this {
    const newState = patch.reduce((state: PrimitiveRecord, change: CellChangeDetail<unknown>) => {
      const { key } = this.schema.getVisiblePropertyByIndex(change.column)
      const atom = this.getAtom(key).patch(change)
      return { ...state, [key]: atom.value }
    }, {} as PrimitiveRecord)

    // overrides the current state object
    this.state = newState

    return this
  }

  /**
   * Stub method
   * @returns nothing because this is just a patch fragment
   */
  protected getMessages(_key: string): IAtomMessage[] {
    return []
  }
}
