export const localeEn = {
  components: {
    DataPreview: {
      dataPreview: 'Data preview',
      dataPreviewFor: 'Data preview for {{fieldLabel}}',
      hoverLineBeginning: 'Hover over a line to the left ',
      hoverLineEnd: 'for more detail.',
      noData: 'No data',
      totalCount_one: '{{count}} total row',
      totalCount_other: '{{count}} total rows, ',
      valueCount_one: ' {{count}} has a value',
      valueCount_other: ' {{count}} have a value'
    },
    DataSourceTable: {
      sheetTitle: 'Sheet Title'
    },
    Dropzone: {
      dropFileAlt: 'Drop file here',
      dropFileBeginning: 'Drop your file ',
      dropFileEnd: 'anywhere to upload',
      uploadFailedHeader: 'Upload failed',
      uploadFailedContent_one:
        'The file {{fileName}} that you uploaded is larger than 1GB. Please reformat your file to be smaller than 1GB and reupload your file.',
      uploadFailedContent_other:
        'The files {{fileName}} that you uploaded are larger than 1GB. Please reformat your files to be smaller than 1GB and reupload your files.'
    },
    EmbedNextAction: {
      processingFile: 'Processing {{fileName}}...',
      csvConvert:
        'This might take longer than 3 min. Convert into a CSV file for faster processing.'
    },
    FileUploader: {
      orBlock: 'or'
    },
    FilterWithCount: {
      showAllErrors: 'Show all errors'
    },
    RecordStatusBanner: {
      rejectedDescription_one:
        'You have {{count}} record that was rejected by the server. You can either dismiss it and wrap up, or correct it to re-import.',
      rejectedDescription_other:
        'You have {{count}} record that was rejected by the server. You can either dismiss all of them and wrap up, or correct them to re-import.',
      titleSuccess_one: '{{count}} record imported successfully,',
      titleSuccess_other: '{{count}} records imported successfully,',
      titleRejected_one: ' {{count}} rejected record',
      titleRejected_other: ' {{count}} rejected records',
      discardAndFinish: 'Discard all and finish!'
    }
  },
  elements: {
    buttons: {
      cancel: 'Cancel',
      continue: 'Continue',
      selectFile: 'Select file',
      startOver: 'Start over',
      submit: 'Submit'
    },
    filters: {
      allRows: 'All rows',
      validRows: 'Valid',
      invalidRows: 'Error',
      mergedRows: 'Most recently merged',
      showAllErrors: 'Show all errors',
      showFieldErrors: '{{fieldName}} errors'
    },
    loaders: {
      initializing: 'Initializing your importer',
      loading: 'Loading',
      loadingData: 'Loading your data',
      normalizing: 'Normalizing data',
      preparing: 'Preparing your data for review',
      processing: 'Processing',
      saving: 'Saving',
      scanning: 'Scanning for inconsistencies'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: 'Unable to cancel processing'
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      dataHooksErr: {
        title: 'Auto reformatting error',
        message:
          'Unable to complete automated reformatting. Hover over any error for guidance on correcting a value manually. Reach out to us if you need further assistance.'
      }
    },
    Layout: {
      mobileDevice: 'Please use a device with a larger screen to continue this workflow.'
    },
    SkipHeaderRow: {
      skipHeaderRow: 'Skip and use alphabets as headers.',
      noHeaderRow: "Don't have a header row?"
    }
  },
  hooks: {
    useEmbedContinueFlow: {
      pickUp: 'Pick up where you left off?'
    },
    useConfirmDismissModal: {
      dismissHeader: 'Dismiss record(s)',
      dismissLabel: 'Dismiss',
      paragraphOne: 'Dismissing these records will remove linked values for {{message}}.',
      paragraphTwo: 'Do you wish to dismiss them?',
      linkRecords: '{{countRecords}} records in {{schemaName}} sheet'
    },
    useMappingWarningModal: {
      requiredFields:
        'The following fields are required in order to submit the data to the system:',
      proceed:
        'Click Continue to proceed without matching these fields or Cancel to go back and match.',
      manual:
        'You can also manually input this data or upload new data to populate these fields at a later time.'
    }
  },
  scenes: {
    '1_InitialScene': {
      uploadingFile: 'Uploading your file',
      nonWhiteLabel: "You're securely onboarding data with {{company}}",
      fileTooLargeError: 'File is larger than 1GB. Please upload a smaller file.'
    },
    '5_ReviewScene': {
      loadingData: 'Processing your beautiful data...',
      cancelLabel: 'Discard and continue',
      confirmLabel: 'Resolve errors',
      discardSelected_one: 'Discard {{count}} selected row',
      discardSelected_other: 'Discard {{count}} selected rows',
      generalEmptyState: 'Something went wrong',
      specificEmptyState:
        "We're having trouble loading your data. You may be trying to import a file with no data, or having a problem with a data hook. Please try refreshing the page, and contact us if the problem persists."
    },
    '6_ErrorScene': {
      importError: 'Something went wrong during the import.'
    },
    '7_FinalScene': {
      showReviewMessage:
        'Some of your data was not accepted by {{teamName}}. Preview rejected data in the table below and choose whether to review and correct this data or discard this data and finish your import.',
      aboutToSubmit_one: 'You are about to submit {{count}} record!',
      aboutToSubmit_other: 'You are about to submit {{count}} records!',
      recordCount: '{{count}} records',
      recordCount_one: '{{count}} record',
      rejectedStatusLabel: 'Rejected',
      rowsRemaining: 'Rows remaining'
    },
    MatchingV3: {
      header: 'Are you sure?',
      confirmLabel: 'Yes, proceed',
      cancelLabel: 'No, cancel',
      contentsBeforeBreak: 'Your changes will affect the data in the next step.',
      contentsAfterBreak: 'Would you like to proceed?',
      columnHeaders: 'Columns in your file',
      unmatchedRequiredFields: 'Unmatched required field(s):',
      numberUnmatched: ' unmatched',
      emptyState: "You don't have any active columns.",
      excludeColumnMatch: 'Exclude this column from matching'
    }
  },
  textOverrides: {
    backToMatching: 'Back to matching',
    dragAndDropHint: 'Drop your file here',
    fileTypeHint: 'And other similar file types',
    headerCheck: 'Change or confirm header selection',
    headerCheckAction: 'Skip and use alphabets as headers.',
    headerCheckDesc: '',
    headerInitial: 'Upload your file',
    headerInitialDesc: '',
    importRecords_one: 'Import {{count}} record',
    importRecords_other: 'Import {{count}} records',
    saveAndFinalize: 'Save and finalize',
    headerMatch: 'Change or confirm column matches',
    headerMatchDesc: '',
    headerReview: 'Review and finalize',
    headerReviewDesc: '',
    headerSource: 'Select the sheet you want to upload',
    headerSourceDesc: '',
    templateFieldsLabel: 'Template fields',
    ctaLabelFinal: '',
    rowFilter_allRows: 'All rows',
    rowFilter_valid: 'Valid',
    rowFilter_error: 'Error',
    discardModalHeader: 'Discard records with errors',
    discardModalDescriptionBeginning: 'To continue, discard ',
    discardModalDescription_one: '{{count}} record ',
    discardModalDescription_other: '{{count}} records ',
    discardModalDescriptionEnd: 'with unresolved errors.',
    allInvalidErrHeading: 'No valid records',
    allInvalidErrDescription: 'You need at least one valid record to be able to import data.',
    loaderMessageImporting: 'Importing',
    successfulImport_one: 'You have imported {{count}} record!',
    successfulImport_other: 'You have imported {{count}} records!',
    finalViewImportedTab: 'Imported',
    finalViewReviewRejectedButton: 'Review rejected records',
    invalidFileType:
      'File is not a supported file type. Upload a .csv, .xls, .xlsx, .txt or .gz file.',
    columnExcluded_one:
      'The {{count}} column listed below is excluded from matching. Click on a column name to add it back to the list above or',
    columnExcluded_other:
      'The {{count}} columns listed below are excluded from matching. Click on a column name to add it back to the list above or',
    addAllLink: ' add all.',
    numberSubmitted_one: '{{count}} record submitted!',
    numberSubmitted_other: '{{count}} records submitted!'
  },
  themes: {
    flexport: {
      backToMatching: 'Back to matching',
      dragAndDropHint: '',
      headerInitial: 'Upload your file',
      fileTypeHint: 'Drag and drop your file here',
      headerSource: 'Select the sheet you want to upload',
      headerSourceDesc: '',
      headerCheck: 'Select your header row',
      headerCheckDesc: '',
      headerCheckAction: '',
      headerMatch: 'Match file columns to our fields',
      headerMatchDesc: '',
      headerReview: 'Review and make final changes',
      headerReviewDesc: '',
      templateFieldsLabel: 'Our fields',
      ctaLabelFinal: 'Continue',
      rowFilter_allRows: 'All rows',
      rowFilter_valid: 'Valid rows',
      rowFilter_error: 'Rows with errors',
      headerInitialDesc: '',
      importRecords_one: 'Import {{count}} record',
      importRecords_other: 'Import {{count}} records',
      saveAndFinalize: 'Save and finalize',
      discardModalHeader: 'Discard records with errors',
      discardModalDescriptionBeginning: 'To continue, discard ',
      discardModalDescription_one: '{{count}} record ',
      discardModalDescription_other: '{{count}} records ',
      discardModalDescriptionEnd: 'with unresolved errors.',
      allInvalidErrHeading: 'No valid records',
      allInvalidErrDescription: 'You need at least one valid record to be able to import data.',
      loaderMessageImporting: 'Importing',
      successfulImport_one: 'You have imported {{count}} record!',
      successfulImport_other: 'You have imported {{count}} records!',
      finalViewImportedTab: 'Imported',
      finalViewReviewRejectedButton: 'Review rejected records',
      invalidFileType:
        'File is not a supported file type. Upload a .csv, .xls, .xlsx, .txt or .gz file.',
      columnExcluded_one:
        'The {{count}} column listed below is excluded from matching. Click on a column name to add it back to the list above or',
      columnExcluded_other:
        'The {{count}} columns listed below are excluded from matching. Click on a column name to add it back to the list above or',
      addAllLink: ' add all.',
      numberSubmitted_one: '{{count}} record submitted!',
      numberSubmitted_other: '{{count}} records submitted!'
    },
    vindicia: {
      backToMatching: 'Back to Mapping',
      dragAndDropHint: 'Drag and drop your file here',
      headerInitial: 'Upload Transaction file',
      fileTypeHint: 'and other similar file types',
      headerSource: 'Select the spreadsheet to upload',
      headerSourceDesc: 'You can only upload one spreadsheet at a time',
      headerCheck: 'Select header row by clicking row number',
      headerCheckDesc: "Don't have a header row?",
      headerCheckAction: '(Skip and use alphabet row instead)',
      headerMatch: 'Map your columns to our template fields',
      headerMatchDesc: "We've started mapping for you - please review",
      headerReview: 'Review and correct Transaction data',
      headerReviewDesc:
        'Correct red marked errors and yellow warnings. Underlined text was auto-corrected, please review.',
      templateFieldsLabel: 'Template fields',
      ctaLabelFinal: 'Continue',
      rowFilter_allRows: 'Total rows',
      rowFilter_valid: 'Valid',
      rowFilter_error: 'Errors',
      headerInitialDesc: 'See the Template Fields file for required fields',
      importRecords_one: 'Continue',
      importRecords_other: 'Continue',
      saveAndFinalize: 'Continue',
      discardModalHeader: 'Discard Transactions with errors?',
      discardModalDescriptionBeginning: 'Transactions with errors',
      discardModalDescription_one: ' will not be ',
      discardModalDescription_other: ' will not be ',
      discardModalDescriptionEnd: 'submitted to Retain for processing.',
      allInvalidErrHeading: 'No valid transactions to submit',
      allInvalidErrDescription:
        'Correct the data, see the red error and yellow warning messages for guidance',
      loaderMessageImporting: 'Thanks for reviewing',
      successfulImport_one: "Hooray! We've got your final count",
      successfulImport_other: "Hooray! We've got your final count",
      finalViewImportedTab: 'Accepted',
      finalViewReviewRejectedButton: 'Review rejected Transactions',
      invalidFileType:
        'We only support CSV and XSLX file types. Please upload or drop a new file.',
      columnExcluded_one: 'The following column was not mapped. Select them where relevant or ',
      columnExcluded_other: "The following columns weren't mapped. Select them where relevant or ",
      addAllLink: 'Add all.',
      numberSubmitted_one: '{{count}} Submitted',
      numberSubmitted_other: '{{count}} Submitted'
    },
    sage: {
      backToMatching: 'Back to matching',
      headerInitial: 'Upload your file',
      dragAndDropHint: 'Drop your file here',
      fileTypeHint: 'And other similar file types',
      headerSource: 'Select the sheet you want to upload',
      headerInitialDesc: '',
      headerSourceDesc:
        'We found multiple sheets in this file. Which sheet contains the client data to import?',
      headerCheck: 'Select your header row',
      headerCheckDesc: 'Select the header row in your data to make column labels, or',
      headerCheckAction: 'skip and use letters as headers.',
      headerMatch: 'Match file columns to our fields',
      headerMatchDesc:
        'To reduce the potential for errors, tell us which of our fields relate to which of your spreadsheet columns.',
      headerReview: 'Review and make final changes',
      headerReviewDesc:
        'If we’ve highlighted any errors, resolve these before completing the import.',
      importRecords_one: 'Import {{count}} record',
      importRecords_other: 'Import {{count}} records',
      saveAndFinalize: 'Save and finalize',
      templateFieldsLabel: 'Our fields',
      ctaLabelFinal: 'Continue to Sage',
      rowFilter_allRows: 'All rows',
      rowFilter_valid: 'Valid rows',
      rowFilter_error: 'Rows with errors',
      discardModalHeader: 'Discard records with errors',
      discardModalDescriptionBeginning: 'To continue, discard ',
      discardModalDescription_one: '{{count}} record ',
      discardModalDescription_other: '{{count}} records ',
      discardModalDescriptionEnd: 'with unresolved errors.',
      allInvalidErrHeading: 'No valid records',
      allInvalidErrDescription: 'You need at least one valid record to be able to import data.',
      loaderMessageImporting: 'Importing',
      successfulImport_one: 'You have imported {{count}} record!',
      successfulImport_other: 'You have imported {{count}} records!',
      finalViewImportedTab: 'Imported',
      finalViewReviewRejectedButton: 'Review rejected records',
      invalidFileType:
        'File is not a supported file type. Upload a .csv, .xls, .xlsx, .txt or .gz file.',
      columnExcluded_one:
        'The {{count}} column listed below is excluded from matching. Click on a column name to add it back to the list above or',
      columnExcluded_other:
        'The {{count}} columns listed below are excluded from matching. Click on a column name to add it back to the list above or',
      addAllLink: ' add all.',
      numberSubmitted_one: '{{count}} record submitted!',
      numberSubmitted_other: '{{count}} records submitted!'
    }
  },
  miscEmbeds: {
    devMode: 'Developer mode'
  },
  unmatched: 'unmatched',
  matched: 'matched',
  suggested: 'suggested',
  unique: 'Unique',
  custom: 'Custom',
  searchOrSelect: 'Search or select...',
  addAsCustom: 'Add as custom field'
}

export const localeFr = {
  components: {
    DataPreview: {
      dataPreview: 'Aperçu des données',
      dataPreviewFor: 'Aperçu des données pour {{fieldLabel}}',
      hoverLineBeginning: 'Survolez une ligne à gauche ',
      hoverLineEnd: 'pour plus de détails.',
      noData: 'Aucune donnée',
      totalCount_one: '{{count}}  ligne au total',
      totalCount_other: '{{count}} lignes au total, ',
      valueCount_one: ' {{count}} possède une valeur',
      valueCount_other: ' {{count}}  possèdent une valeur'
    },
    DataSourceTable: {
      sheetTitle: 'Titre de la feuille'
    },
    Dropzone: {
      dropFileAlt: 'Déposez un fichier ici',
      dropFileBeginning: 'Déposez votre fichier ',
      dropFileEnd: "n'importe où pour l'importer",
      uploadFailedContent_one:
        "Le fichier {{fileName}} que vous avez importé fait plus de 1 Go. Veuillez le reformater afin qu'il soit inférieur à 1 Go et l'importer de nouveau.",
      uploadFailedContent_other:
        "Les fichiers {{fileName}} que vous avez importés font plus de 1 Go. Veuillez les reformater afin qu'ils soient inférieurs à 1 Go et les importer de nouveau.",
      uploadFailedHeader: "Échec de l'importation"
    },
    EmbedNextAction: {
      csvConvert:
        'Il se peut que cela prenne plus de trois minutes. Convertissez le fichier en CSV pour que le traitement soit plus rapide.',
      processingFile: '{{fileName}} en cours de traitement...'
    },
    FileUploader: {
      orBlock: 'ou'
    },
    FilterWithCount: {
      showAllErrors: 'Afficher toutes les erreurs'
    },
    RecordStatusBanner: {
      discardAndFinish: 'Tout abandonner et terminer !',
      rejectedDescription_one:
        "{{count}} enregistrement a été rejeté par le serveur. Vous pouvez soit l'ignorer et poursuivre, soit le corriger pour l'importer de nouveau.",
      rejectedDescription_other:
        "{{count}} enregistrements ont été rejetés par le serveur. Vous pouvez soit les ignorer et poursuivre, soit les corriger pour l'importer de nouveau.",
      titleRejected_one: ' {{count}} enregistrement rejeté',
      titleRejected_other: ' {{count}} enregistrements rejetés',
      titleSuccess_one: '{{count}} enregistrement importé avec succès,',
      titleSuccess_other: '{{count}} enregistrements importés avec succès,'
    }
  },
  elements: {
    buttons: {
      cancel: 'Annuler',
      continue: 'Continuer',
      selectFile: 'Sélectionner un fichier',
      startOver: 'Recommencer',
      submit: 'Envoyer'
    },
    filters: {
      allRows: 'Toutes les lignes',
      invalidRows: 'Erreur',
      mergedRows: 'Les plus récemment fusionnées',
      showAllErrors: 'Afficher toutes les erreurs',
      showFieldErrors: 'erreurs de {{fieldName}}',
      validRows: 'Valides'
    },
    loaders: {
      importing: "En cours d'importation",
      initializing: 'Initialisation de votre importateur en cours',
      loading: 'Chargement',
      loadingData: 'Chargement de vos données',
      normalizing: 'Réduction de vos données',
      preparing: 'Préparation de vos données en cours pour vérification',
      processing: 'En cours de traitement',
      saving: 'Enregistrement',
      scanning: 'Recherche des incohérences'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: "Impossible d'annuler le traitement"
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      dataHooksErr: {
        message:
          "Impossible de terminer le reformatage automatisé. Faites passer la souris sur une erreur pour obtenir des indications permettant de corriger manuellement une valeur. N'hésitez pas à nous contacter si vous avez besoin d'une assistance supplémentaire.",
        title: 'Erreur de reformatage automatique'
      }
    },
    Layout: {
      mobileDevice:
        'Veuillez utiliser un appareil avec un écran plus grand pour continuer sur ce flux de travail.'
    },
    SkipHeaderRow: {
      noHeaderRow: "Vous n'avez pas de ligne d'en-tête ?",
      skipHeaderRow: 'Ignorez et utilisez des signes alphabétiques comme en-têtes.'
    }
  },
  hooks: {
    useConfirmDismissModal: {
      dismissHeader: 'Rejeter les enregistrements',
      dismissLabel: 'Rejeter',
      linkRecords: '{{countRecords}} enregistrements dans la feuille {{schemaName}}',
      paragraphOne: 'Rejeter ces enregistrements supprimera les valeurs liées pour {{message}}.',
      paragraphTwo: 'Souhaitez-vous les rejeter ?'
    },
    useEmbedContinueFlow: {
      pickUp: 'Reprendre où vous en étiez ?'
    },
    useMappingWarningModal: {
      requiredFields:
        'Les champs suivants sont requis et doivent avoir une correspondance avec votre fichier:',
      proceed:
        'Cliquez sur Continuer pour poursuivre sans faire correspondre ces champs ou sur Annuler pour revenir en arrière et faire correspondre les champs.',
      manual:
        'Vous pouvez également saisir ces données manuellement ou importer de nouvelles données pour remplir ces champs ultérieurement.'
    }
  },
  miscEmbeds: {
    devMode: 'Mode développeur'
  },
  unmatched: 'Pas associé',
  matched: 'Associé',
  suggested: 'Recommandé',
  unique: 'Unique',
  custom: 'Personnalisé',
  searchOrSelect: 'Cherchez ou sélectionnez...',
  addAsCustom: 'Champ personnalisé',
  scenes: {
    '1_InitialScene': {
      nonWhiteLabel:
        "Vous êtes en train d'intégrer des données en toute sécurité avec {{company}}",
      uploadingFile: 'Téléchargement de votre fichier'
    },
    '5_ReviewScene': {
      cancelLabel: 'Rejeter et continuer',
      confirmLabel: 'Résoudre les erreurs',
      discardSelected_one: 'Rejeter {{count}} ligne sélectionnée',
      discardSelected_other: 'Rejeter {{count}} lignes sélectionnées',
      generalEmptyState: "Une erreur s'est produite",
      header: 'Rejeter les enregistrements contenant des erreurs',
      loadingData: 'Traitement de vos belles données en cours...',
      specificEmptyState:
        "Nous avons des difficultés à charger vos données. Il se peut que vous essayiez d'importer un fichier sans données, ou que vous ayez un problème avec un crochet de données. Essayez de rafraîchir la page et contactez-nous si le problème persiste."
    },
    '6_ErrorScene': {
      importError: "Une erreur s'est produite au moment de l'importation"
    },
    '7_FinalScene': {
      numberSubmitted_one: '{{count}} ligne envoyé !',
      numberSubmitted_other: '{{count}} lignes envoyés !',
      aboutToSubmit_other: 'Vous allez envoyer {{count}} lignes !',
      aboutToSubmit_one: 'Vous allez envoyer {{count}} ligne !',
      recordCount_one: '{{count}} ligne !',
      recordCount: '{{count}} lignes !',
      rejectedStatusLabel: 'Rejeté(s)',
      rowsRemaining: 'Lignes restantes',
      showReviewMessage:
        "Certaines de vos données n'ont pas été acceptées par {{teamName}}. Prévisualisez les données rejetées dans le tableau ci-dessous et décidez si vous  souhaitez les examiner et les corriger ou les rejeter et mettre fin à votre importation."
    },
    MatchingV3: {
      cancelLabel: 'Non, annuler',
      columnHeaders: 'Nom de la colonne dans votre fichier',
      confirmLabel: 'Oui, continuer',
      contentsAfterBreak: 'Voulez-vous continuer ?',
      contentsBeforeBreak: 'Vos modifications affecteront les données lors de la prochaine étape.',
      emptyState: "Vous n'avez aucune colonne active.",
      excludeColumnMatch: 'Exclure cette colonne de la mise en correspondance',
      header: 'Êtes-vous sûr ?',
      numberUnmatched: ' sans correspondance',
      unmatchedRequiredFields: 'Champ(s) requis non apparié(s) :'
    }
  },
  textOverrides: {
    backToMatching: 'Revenir à la correspondance',
    dragAndDropHint: 'Déposez votre fichier ici',
    fileTypeHint: "Et d'autres types de fichiers similaires",
    headerCheck: "Confirmez ou modifiez la ligne d'en-tête de votre fichier",
    headerCheckDesc: '',
    headerInitial: 'Importez votre fichier',
    headerMatch: 'Modifiez ou confirmez les correspondances des colonnes',
    headerMatchDesc: '',
    headerReview: 'Vérifiez, corrigez et finalisez',
    headerReviewDesc: '',
    headerSource: 'Sélectionnez la feuille que vous souhaitez importer',
    headerSourceDesc: '',
    templateFieldsLabel: 'Champs du modèle',
    ctaLabelFinal: '',
    rowFilter_allRows: 'Toutes les lignes',
    rowFilter_valid: 'Valides',
    rowFilter_error: 'Erreur',
    headerInitialDesc: '',
    importRecords_one: 'Importer {{count}} ligne',
    importRecords_other: 'Importer {{count}} lignes',
    saveAndFinalize: 'Enregistrer et finaliser',
    discardModalHeader: 'Rejeter les enregistrements contenant des erreurs',
    discardModalDescriptionBeginning: 'Pour continuer, rejeter ',
    discardModalDescription_one: '{{count}} enregistrement ',
    discardModalDescription_other: '{{count}} enregistrements ',
    discardModalDescriptionEnd: 'avec des erreurs non résolues',
    allInvalidErrHeading: 'Aucun enregistrement valide',
    allInvalidErrDescription:
      'Vous devez avoir au moins un enregistrement valide pour pouvoir importer des données.',
    loaderMessageImporting: "En cours d'importation",
    successfulImport_one: 'Vous avez importé {{count}} ligne !',
    successfulImport_other: 'Vous avez importé {{count}} lignes !',
    finalViewImportedTab: 'Importé(s)',
    finalViewReviewRejectedButton: 'Examiner les enregistrements rejetés',
    invalidFileType:
      "Nous n'acceptons que les fichiers de type CSV et XSLX. Importez un nouveau fichier",
    addAllLink: ' ajoutez-les toutes.',
    columnExcluded_one:
      "La {{count}} colonne listée ci-dessous est exclue de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
    columnExcluded_other:
      "Les {{count}} colonnes listées ci-dessous sont exclues de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
    numberSubmitted_one: '{{count}} ligne soumise !',
    numberSubmitted_other: '{{count}} lignes soumises !'
  },
  themes: {
    flexport: {
      backToMatching: 'Revenir à la correspondance',
      dragAndDropHint: '',
      ctaLabelFinal: 'Continuer',
      fileTypeHint: 'Faites glisser et déposez votre fichier ici',
      headerCheck: 'Sélectionnez votre ligne de titres',
      headerCheckDesc: '',
      headerInitial: 'Téléversez votre fichier',
      headerMatch: 'Faites correspondre les colonnes du fichier à nos champs',
      headerMatchDesc: '',
      headerReview: 'Vérifiez et apportez les modifications finales',
      headerReviewDesc: '',
      headerSource: 'Sélectionnez la feuille que vous souhaitez téléverser',
      headerSourceDesc: '',
      templateFieldsLabel: 'Nos champs',
      rowFilter_allRows: 'Toutes les lignes',
      rowFilter_valid: 'Valides',
      rowFilter_error: 'Erreur',
      headerInitialDesc: '',
      importRecords_one: 'Importer {{count}} ligne',
      importRecords_other: 'Importer {{count}} lignes',
      saveAndFinalize: 'Enregistrer et finaliser',
      discardModalHeader: 'Rejeter les enregistrements contenant des erreurs',
      discardModalDescriptionBeginning: 'Pour continuer, rejeter ',
      discardModalDescription_one: '{{count}} enregistrement ',
      discardModalDescription_other: '{{count}} enregistrements ',
      discardModalDescriptionEnd: 'avec des erreurs non résolues',
      allInvalidErrHeading: 'Aucun enregistrement valide',
      allInvalidErrDescription:
        'Vous devez avoir au moins un enregistrement valide pour pouvoir importer des données.',
      loaderMessageImporting: "En cours d'importation",
      successfulImport_one: 'Vous avez importé {{count}} ligne !',
      successfulImport_other: 'Vous avez importé {{count}} lignes !',
      finalViewImportedTab: 'Importé(s)',
      finalViewReviewRejectedButton: 'Examiner les enregistrements rejetés',
      invalidFileType:
        "Nous n'acceptons que les fichiers de type CSV et XSLX. Importez un nouveau fichier",
      addAllLink: ' ajoutez-les toutes.',
      columnExcluded_one:
        "La {{count}} colonne listée ci-dessous est exclue de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
      columnExcluded_other:
        "Les {{count}} colonnes listées ci-dessous sont exclues de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
      numberSubmitted_one: '{{count}} ligne soumise !',
      numberSubmitted_other: '{{count}} lignes soumises !'
    },
    sage: {
      backToMatching: 'Revenir à la correspondance',
      ctaLabelFinal: 'Continuer sur Sage',
      dragAndDropHint: 'Déposez votre fichier ici',
      fileTypeHint: "Et d'autres types de fichiers similaires",
      headerCheck: 'Sélectionnez votre ligne de titres',
      headerCheckAction: 'skip and use letters as headers.',
      headerCheckDesc:
        "Une ligne de titres sert à intituler chaque colonne. Cliquez sur le numéro de la ligne contenant les intitulés de vos colonnes. Vous ne disposez pas d'une telle ligne ?",
      headerInitial: 'Téléversez votre fichier',
      headerMatch: 'Faites correspondre les colonnes du fichier à nos champs',
      headerMatchDesc:
        "Pour réduire le risque d'erreurs, indiquez-nous les correspondances entre nos champs et les colonnes de votre feuille de calcul.",
      headerReview: 'Vérifiez et apportez les modifications finales',
      headerReviewDesc:
        "Si nous avons mis en évidence des erreurs, corrigez-les avant de terminer l'importation.",
      headerSource: 'Sélectionnez la feuille que vous souhaitez téléverser',
      headerSourceDesc:
        'Nous avons trouvé plusieurs feuilles dans ce fichier. Laquelle contient les données client à importer ?',
      templateFieldsLabel: 'Nos champs',
      rowFilter_allRows: 'Toutes les lignes',
      rowFilter_valid: 'Valides',
      rowFilter_error: 'Erreur',
      headerInitialDesc: '',
      importRecords_one: 'Importer {{count}} ligne',
      importRecords_other: 'Importer {{count}} lignes',
      saveAndFinalize: 'Enregistrer et finaliser',
      discardModalHeader: 'Rejeter les enregistrements contenant des erreurs',
      discardModalDescriptionBeginning: 'Pour continuer, rejeter ',
      discardModalDescription_one: '{{count}} enregistrement ',
      discardModalDescription_other: '{{count}} enregistrements ',
      discardModalDescriptionEnd: 'avec des erreurs non résolues',
      allInvalidErrHeading: 'Aucun enregistrement valide',
      allInvalidErrDescription:
        'Vous devez avoir au moins un enregistrement valide pour pouvoir importer des données.',
      loaderMessageImporting: "En cours d'importation",
      successfulImport_one: 'Vous avez importé {{count}} ligne !',
      successfulImport_other: 'Vous avez importé {{count}} lignes !',
      finalViewImportedTab: 'Importé(s)',
      finalViewReviewRejectedButton: 'Examiner les enregistrements rejetés',
      invalidFileType:
        "Nous n'acceptons que les fichiers de type CSV et XSLX. Importez un nouveau fichier",
      addAllLink: ' ajoutez-les toutes.',
      columnExcluded_one:
        "La {{count}} colonne listée ci-dessous est exclue de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
      columnExcluded_other:
        "Les {{count}} colonnes listées ci-dessous sont exclues de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
      numberSubmitted_one: '{{count}} ligne soumise !',
      numberSubmitted_other: '{{count}} lignes soumises !'
    },
    vindicia: {
      backToMatching: 'Revenir à la Correspondance',
      dragAndDropHint: 'Glissez et déposez votre fichier ici',
      headerInitial: 'Importez un fichier de transaction',
      fileTypeHint: 'Glissez et déposez votre fichier ici',
      headerSource: 'Sélectionnez la feuille de calcul à importer',
      headerSourceDesc: "Vous ne pouvez pas importer plus d'une feuille de calcul à la fois",
      headerCheck: "Sélectionnez la ligne d'en-tête en cliquant sur le numéro de ligne",
      headerCheckDesc: "Vous n'avez pas de ligne d'en-tête ?",
      headerCheckAction: '(Ignorez et utilisez les alphabets comme en-têtes)',
      headerMatch: 'Faites correspondre vos colonnes aux champs de notre modèle',
      headerMatchDesc:
        'Nous avons commencé à établir des correspondances pour vous, veuillez les vérifier.',
      headerReview: 'Vérifiez et corrigez les données de la transaction',
      headerReviewDesc:
        'Corrigez les erreurs en rouge et les avertissements en jaune. Le texte souligné à été corrigé automatiquement, veuillez le vérifier.',
      templateFieldsLabel: 'Champs du modèle',
      ctaLabelFinal: 'Continuer',
      rowFilter_allRows: 'Toutes les lignes',
      rowFilter_valid: 'Valides',
      rowFilter_error: 'Erreur',
      headerInitialDesc:
        'Consultez le fichier « Champs du modèle » pour connaître les champs obligatoires',
      importRecords_one: 'Continuer',
      importRecords_other: 'Continuer',
      saveAndFinalize: 'Continuer',
      discardModalHeader: 'Rejetez les transactions contenant des erreurs?',
      discardModalDescriptionBeginning: 'Les transactions contenant des erreurs',
      discardModalDescription_one: ' ne seront pas ',
      discardModalDescription_other: ' ne seront pas ',
      discardModalDescriptionEnd: 'soumises à la retenue pour traitement.',
      allInvalidErrHeading: 'Aucune transaction valide à soumettre',
      allInvalidErrDescription:
        "Corrigez les données, observez les messages d'erreur en rouge et les messages d'avertissement en jaune pour vous aider",
      loaderMessageImporting: 'Merci de votre vérification',
      successfulImport_one: 'Hourra ! Nous avons obtenu votre décompte final',
      successfulImport_other: 'Hourra ! Nous avons obtenu votre décompte final',
      finalViewImportedTab: 'Accepté',
      finalViewReviewRejectedButton: 'Examinez les transactions rejetées',
      invalidFileType:
        "Nous n'acceptons que les fichiers de type CSV et XSLX. Importez un nouveau fichier",
      columnExcluded_one:
        "La colonne suivante n'a pas été mise en correspondance. Sélectionnez-la au besoin ou ",
      columnExcluded_other:
        "Les colonnes suivantes n'ont pas été mises en correspondance. Sélectionnez-les au besoin ou ",
      addAllLink: 'ajoutez-les toutes.',
      numberSubmitted_one: '{{count}} ligne Soumise',
      numberSubmitted_other: '{{count}} lignes Soumises'
    }
  }
}

export const localeEs = {
  components: {
    DataPreview: {
      dataPreview: 'Vista previa de los datos',
      dataPreviewFor: 'Vista previa de datos para {{fieldLabel}}',
      hoverLineBeginning: 'Pósese en una línea de la izquierda',
      hoverLineEnd: 'para más detalles.',
      noData: 'Sin datos',
      totalCount_one: '{{count}} fila en total',
      totalCount_other: '{{count}} filas en total, ',
      valueCount_one: ' {{count}} tiene un valor',
      valueCount_other: ' {{count}} tienen un valor'
    },
    DataSourceTable: {
      sheetTitle: 'Título de la hoja'
    },
    Dropzone: {
      dropFileAlt: 'Suelte el archivo aquí',
      dropFileBeginning: 'Suelte el archivo ',
      dropFileEnd: 'en cualquier lugar para cargarlo',
      uploadFailedContent_one:
        'El archivo {{fileName}} que ha cargado pesa más de 1 GB. Reformatéelo para que ocupe menos de 1 GB y vuelva a cargarlo.',
      uploadFailedContent_other:
        'Los archivos {{fileName}} que ha cargado pesan más de 1 GB. Reformatéelos para que ocupen menos de 1 GB y vuelva a cargarlos.',
      uploadFailedHeader: 'Error al cargar'
    },
    EmbedNextAction: {
      csvConvert:
        'Esto puede tardar más de 3 minutos. Convierta el archivo en CSV para un procesamiento más rápido.',
      processingFile: 'Procesando {{fileName}}...'
    },
    FileUploader: {
      orBlock: 'o'
    },
    FilterWithCount: {
      showAllErrors: 'Mostrar todos los errores'
    },
    RecordStatusBanner: {
      discardAndFinish: '¡Descartar todo y terminar!',
      rejectedDescription_one:
        'El servidor rechazó {{count}} registro. Puede descartarlo y terminar, o corregirlo para volver a importarlo.',
      rejectedDescription_other:
        'El servidor rechazó {{count}} registros. Puede descartarlos todos y terminar, o corregirlos para volver a importarlos.',
      titleRejected_one: ' {{count}} registro rechazado',
      titleRejected_other: ' {{count}} registros rechazados',
      titleSuccess_one: '{{count}} registro importado correctamente,',
      titleSuccess_other: '{{count}} registros importados con éxito,'
    }
  },
  elements: {
    buttons: {
      cancel: 'Cancelar',
      continue: 'Continuar',
      selectFile: 'Seleccionar archivo',
      startOver: 'Empezar de nuevo',
      submit: 'Enviar'
    },
    filters: {
      allRows: 'Todas las filas',
      invalidRows: 'Con errores',
      mergedRows: 'Combinados más recientemente',
      showAllErrors: 'Mostrar todos los errores',
      showFieldErrors: 'Errores de {{fieldName}}',
      validRows: 'Válidas'
    },
    loaders: {
      importing: 'Importando',
      initializing: 'Inicializando el importador',
      loading: 'Cargando',
      loadingData: 'Cargando los datos',
      normalizing: 'Normalizando los datos',
      preparing: 'Preparando los datos para su revisión',
      processing: 'Procesando',
      saving: 'Guardando',
      scanning: 'Escaneando en busca de inconsistencias'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: 'No se puede cancelar el procesado'
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      dataHooksErr: {
        messsage:
          'No se ha podido completar el reformateado automático. Ponga el cursor sobre cualquier error para recibir indicaciones sobre cómo corregir los valores automáticamente. Contacte con nosotros si necesita ayuda.',
        title: 'Error de autoreformateo'
      }
    },
    Layout: {
      mobileDevice:
        'Use un dispositivo con una pantalla más grande para seguir con este flujo de trabajo.'
    },
    SkipHeaderRow: {
      noHeaderRow: '¿No tiene una fila de encabezado?',
      skipHeaderRow: 'Omitir y usar letras como encabezados.'
    }
  },
  hooks: {
    useConfirmDismissModal: {
      dismissHeader: 'Descartar registro(s)',
      dismissLabel: 'Descartar',
      linkRecords: '{{countRecords}} registros en la hoja {{schemaName}}',
      paragraphOne: 'Descartar estos registros eliminará los valores enlazados en {{message}}.',
      paragraphTwo: '¿Quiere descartarlos?'
    },
    useEmbedContinueFlow: {
      pickUp: '¿Seguir dónde lo había dejado?'
    },
    useMappingWarningModal: {
      manual:
        'También puedes introducir estos datos manualmente o cargar nuevos datos para rellenar estos campos más adelante.',
      proceed:
        'Haz clic en "Continuar" para continuar sin cotejar estos campos o en "Cancelar" para volver atrás y cotejarlos.',
      requiredFields: 'Los siguientes campos son obligatorios para enviar los datos al sistema:'
    }
  },
  miscEmbeds: {
    devMode: 'Modo desarrolador.'
  },
  scenes: {
    '1_InitialScene': {
      nonWhiteLabel: 'Está integrando datos de forma segura con {{company}}',
      uploadingFile: 'Subiendo el archivo'
    },
    '5_ReviewScene': {
      cancelLabel: 'Descartar y continuar',
      confirmLabel: 'Resolver errores',
      // TODO - not in french
      discardBeginning: 'Descarta para continuar. ',
      // TODO - not in french
      discardEnd: 'con errores no resueltos.',
      // TODO - not in french
      discardMessage_one: '{{count}} registro ',
      // TODO - not in french
      discardMessage_other: '{{count}} registros ',
      discardSelected_one: 'Descartar {{count}} fila seleccionada',
      discardSelected_other: 'Descartar {{count}} filas seleccionadas',
      generalEmptyState: 'Algo ha fallado',
      header: 'Descartar registros con errores.',
      loadingData: 'Procesando sus preciosos datos...',
      saveAndFinalize: 'Guardar y finalizar',
      specificEmptyState:
        'Tenemos problemas para subir sus datos. Puede que esté intentando importar un archivo sin datos o teniendo un problema con un hook de datos. Intente volver a cargar la página y avísenos si el problema continúa.'
    },
    '6_ErrorScene': {
      importError: 'Algo ha fallado al importar.'
    },
    '7_FinalScene': {
      // TODO - not in french
      aboutToSubmit_one: '¡Estás a punto de enviar {{count}} registro!',
      // TODO - not in french
      aboutToSubmit_other: '¡Estás a punto de enviar {{count}} registros!',
      importedStatusLabel: 'Importado',
      numberSubmitted_one: '¡{{count}} registro enviado!',
      numberSubmitted_other: '¡{{count}} registros enviados!',
      // TODO - not in french
      rejectedStatusLabel: 'Rechazado',
      rowsRemaining: 'Filas restantes',
      showReviewMessage:
        '{{teamName}} no ha aceptado algunos de los datos. Previsualice los datos rechazados en la tabla a continuación, elija si revisar y corregir los datos o eliminarlos y finalice la importación.'
    },
    MatchingV3: {
      cancelLabel: 'No, cancelar',
      columnHeaders: 'Columnas en el archivo',
      confirmLabel: 'Sí, adelante',
      contentsAfterBreak: '¿Quiere continuar?',
      contentsBeforeBreak: 'Sus cambios afectarán a los datos del próximo paso.',
      emptyState: 'No tienes ninguna columna activa.',
      excludeColumnMatch: 'Excluir esta columna de la asignación.',
      header: '¿Seguro?',
      numberUnmatched: ' No cotejado.',
      unmatchedRequiredFields: 'Campo(s) sin asignar obligatorios:'
    }
  },
  textOverrides: {
    addAllLink: ' añádalas todas.',
    allInvalidErrDescription: 'Necesita al menos un registro válido para poder importar datos.',
    allInvalidErrHeading: 'No se encontraron registros válidos',
    backToMatching: 'Volver a las correspondencias',
    columnExcluded_one:
      'La {{count}} columna que aparece a continuación está excluida de las correspondencias. Haga clic en el nombre de la columna para volver a añadirla a la lista anterior o',
    columnExcluded_other:
      'Las {{count}} columnas que aparecen a continuación están excluidas de las correspondencias. Haga clic en el nombre de una columna para volver a añadirla a la lista anterior o',
    ctaLabelFinal: '',
    discardModalDescription_one: '{{count}} registro',
    discardModalDescription_other: '{{count}} registros',
    discardModalDescriptionBeginning: 'Descarta para continuar. ',
    discardModalDescriptionEnd: 'con errores no resueltos.',
    discardModalHeader: 'Descartar registros con errores.',
    dragAndDropHint: 'Suelta tu archivo aquí.',
    fileTypeHint: 'Y otros tipos de archivos similares.',
    finalViewImportedTab: 'Importado',
    finalViewReviewRejectedButton: 'Revisar registros rechazados',
    headerCheck: 'Modifica o confirma la selección de la cabecera.',
    headerCheckAction: 'Omitir y utilizar encabezados alfabéticos.',
    headerCheckDesc: '',
    headerInitial: 'Sube tu archivo.',
    headerInitialDesc: '',
    headerMatch: 'Modifica o confirma las coincidencias de columna.',
    headerMatchDesc: '',
    headerReview: 'Revisar y finalizar.',
    headerReviewDesc: '',
    headerSource: 'Selecciona la hoja que quieres subir.',
    headerSourceDesc: '',
    importRecords_one: 'Importar {{count}} registro',
    importRecords_other: 'Importar {{count}} registros',
    invalidFileType:
      'El archivo no es un tipo de archivo compatible. Sube un archivo .csv, .xls, .xlsx, .txt o .gz.',
    loaderMessageImporting: 'Importando',
    numberSubmitted_one: '¡{{count}} registro enviado!',
    numberSubmitted_other: '¡{{count}} registros enviados!',
    rowFilter_allRows: 'Todas las filas',
    rowFilter_error: 'Con errores',
    rowFilter_valid: 'Válidas',
    saveAndFinalize: 'Guardar y finalizar',
    successfulImport_one: '¡Ha importado {{count}} registro!',
    successfulImport_other: '¡Ha importado {{count}} registros!',
    templateFieldsLabel: 'Campos de plantilla'
  },
  themes: {
    flexport: {
      addAllLink: ' añádalas todas.',
      allInvalidErrDescription: 'Necesita al menos un registro válido para poder importar datos.',
      allInvalidErrHeading: 'No se encontraron registros válidos',
      backToMatching: 'Volver a las correspondencias',
      columnExcluded_one:
        'La {{count}} columna que aparece a continuación está excluida de las correspondencias. Haga clic en el nombre de la columna para volver a añadirla a la lista anterior o',
      columnExcluded_other:
        'Las {{count}} columnas que aparecen a continuación están excluidas de las correspondencias. Haga clic en el nombre de una columna para volver a añadirla a la lista anterior o',
      ctaLabelFinal: 'Continuar',
      discardModalDescription_one: '{{count}} registro',
      discardModalDescription_other: '{{count}} registros',
      discardModalDescriptionBeginning: 'Descarta para continuar. ',
      discardModalDescriptionEnd: 'con errores no resueltos.',
      discardModalHeader: 'Descartar registros con errores.',
      dragAndDropHint: '',
      fileTypeHint: 'Arrastra y suelta tu archivo aquí.',
      finalViewImportedTab: 'Importado',
      finalViewReviewRejectedButton: 'Revisar registros rechazados',
      headerCheck: 'Selecciona tu fila de cabecera.',
      headerCheckAction: '',
      headerCheckDesc: '',
      headerInitial: 'Sube tu archivo.',
      headerInitialDesc: '',
      headerMatch: 'Haz coincidir las columnas del archivo con nuestros campos.',
      headerMatchDesc: '',
      headerReview: 'Revisa y realiza los cambios finales.',
      headerReviewDesc: '',
      headerSource: 'Selecciona la hoja que quieres subir.',
      headerSourceDesc: '',
      importRecords_one: 'Importar {{count}} registro',
      importRecords_other: 'Importar {{count}} registros',
      invalidFileType:
        'El archivo no es un tipo de archivo compatible. Sube un archivo .csv, .xls, .xlsx, .txt o .gz.',
      loaderMessageImporting: 'Importando',
      numberSubmitted_one: '¡{{count}} registro enviado!',
      numberSubmitted_other: '¡{{count}} registros enviados!',
      rowFilter_allRows: 'Todas las filas',
      rowFilter_error: 'Con errores',
      rowFilter_valid: 'Válidas',
      saveAndFinalize: 'Guardar y finalizar',
      successfulImport_one: '¡Ha importado {{count}} registro!',
      successfulImport_other: '¡Ha importado {{count}} registros!',
      templateFieldsLabel: 'Nuestros campos.'
    },
    sage: {
      addAllLink: ' añádalas todas.',
      allInvalidErrDescription: 'Necesita al menos un registro válido para poder importar datos.',
      allInvalidErrHeading: 'No se encontraron registros válidos',
      backToMatching: 'Volver a las correspondencias',
      columnExcluded_one:
        'La {{count}} columna que aparece a continuación está excluida de las correspondencias. Haga clic en el nombre de la columna para volver a añadirla a la lista anterior o',
      columnExcluded_other:
        'Las {{count}} columnas que aparecen a continuación están excluidas de las correspondencias. Haga clic en el nombre de una columna para volver a añadirla a la lista anterior o',
      ctaLabelFinal: 'Continuar con Sage.',
      discardModalDescription_one: '{{count}} registro',
      discardModalDescription_other: '{{count}} registros',
      discardModalDescriptionBeginning: 'Descarta para continuar. ',
      discardModalDescriptionEnd: 'con errores no resueltos.',
      discardModalHeader: 'Descartar registros con errores.',
      dragAndDropHint: 'Suelta tu archivo aquí.',
      fileTypeHint: 'Y otros tipos de archivos similares.',
      finalViewImportedTab: 'Importado',
      finalViewReviewRejectedButton: 'Revisar registros rechazados',
      headerCheck: 'Selecciona tu fila de cabecera.',
      headerCheckAction: '',
      headerCheckDesc:
        'Se utiliza una fila de cabecera para etiquetar cada columna. Haz clic en el número de la fila que contiene las etiquetas de las columnas. ¿No tienes una fila con etiquetas de columna?',
      headerInitial: 'Sube tu archivo.',
      headerInitialDesc: '',
      headerMatch: 'Haz coincidir las columnas del archivo con nuestros campos.',
      headerMatchDesc:
        'Para reducir la posibilidad de errores, indícanos cuáles de nuestros campos están relacionados con cada una de las columnas de tu hoja de cálculo.',
      headerReview: 'Revisa y realiza los cambios finales.',
      headerReviewDesc:
        'Si hemos resaltado algún error, resuélvelo antes de completar la importación.',
      headerSource: 'Selecciona la hoja que quieres subir.',
      headerSourceDesc:
        'Hemos encontrado varias hojas en este archivo. ¿Qué hoja contiene los datos del cliente que hay que importar?',
      importRecords_one: 'Importar {{count}} registro',
      importRecords_other: 'Importar {{count}} registros',
      invalidFileType:
        'El archivo no es un tipo de archivo compatible. Sube un archivo .csv, .xls, .xlsx, .txt o .gz.',
      loaderMessageImporting: 'Importando',
      numberSubmitted_one: '¡{{count}} registro enviado!',
      numberSubmitted_other: '¡{{count}} registros enviados!',
      rowFilter_allRows: 'Todas las filas',
      rowFilter_error: 'Con errores',
      rowFilter_valid: 'Válidas',
      saveAndFinalize: 'Guardar y finalizar',
      successfulImport_one: '¡Ha importado {{count}} registro!',
      successfulImport_other: '¡Ha importado {{count}} registros!',
      templateFieldsLabel: 'Nuestros campos.'
    },
    vindicia: {
      addAllLink: ' añádalas todas.',
      allInvalidErrDescription:
        'Corrija los datos, observe los mensajes rojos de error y amarillo de advertencia para obtener orientación.',
      allInvalidErrHeading: 'Sin transacciones válidas que presentar',
      backToMatching: 'Volver a las correspondencias',
      columnExcluded_one:
        'No se ha asignado la siguiente columna. Selecciónelas cuando proceda o ',
      columnExcluded_other:
        'Las siguientes columnas no se han asignado. Selecciónelas cuando proceda o ',
      ctaLabelFinal: '',
      discardModalDescription_one: 'no se enviarán',
      discardModalDescriptionBeginning: 'Transacciones con errores',
      discardModalDescriptionEnd: 'a Retención para su tramitación.',
      discardModalHeader: 'Descartar las transacciones con errores',
      dragAndDropHint: 'Arrastra y suelta tu archivo aquí.',
      fileTypeHint: 'Y otros tipos de archivos similares.',
      finalViewImportedTab: 'Accepted',
      finalViewReviewRejectedButton: 'Revisar transacciones rechazadas',
      headerCheck: 'Seleccione la fila del encabezado haciendo clic en el número de fila',
      headerCheckAction: '(Omitir y utilizar alfabetos como encabezados)',
      headerCheckDesc: '¿No tiene una fila de encabezado?',
      headerInitial: 'Cargar archivo de transacción',
      headerInitialDesc: 'Véase el archivo Campos de plantilla para los campos obligatorios',
      headerMatch: 'Asigne sus columnas a los campos de nuestra plantilla',
      headerMatchDesc: 'Hemos empezado a trazar mapas para usted, no dude en revisarlos.',
      headerReview: 'Revisar y corregir datos de transacciones',
      headerReviewDesc:
        'Corrija los errores marcados en rojo y las advertencias en amarillo. Se ha corregido automáticamente el texto subrayado.',
      headerSource: 'Seleccione la hoja de cálculo a cargar',
      headerSourceDesc: 'Solo puede cargar una hoja de cálculo a la vez',
      importRecords_one: 'Importar {{count}} registro',
      importRecords_other: 'Importar {{count}} registros',
      invalidFileType:
        'Solo admitimos los tipos de archivo CSV, XSLX y TSV. Cargar un nuevo archivo',
      loaderMessageImporting: 'Gracias por su comentario',
      numberSubmitted_one: '¡{{count}} registro enviado!',
      numberSubmitted_other: '¡{{count}} registros enviados!',
      rowFilter_allRows: 'Todas las filas',
      rowFilter_error: 'Con errores',
      rowFilter_valid: 'Válidas',
      saveAndFinalize: 'Guardar y finalizar',
      successfulImport_one: '¡Hurra! Ya tenemos el recuento final',
      successfulImport_other: '¡Hurra! Ya tenemos el recuento final',
      templateFieldsLabel: 'Campos de plantilla'
    }
  },
  unmatched: 'no emparejados',
  matched: 'emparejados',
  suggested: 'suggested',
  unique: 'único',
  custom: 'a medida',
  searchOrSelect: 'buscar o seleccionar...',
  addAsCustom: 'Agregar como campo personalizado'
}

export const localeDe = {
  components: {
    DataPreview: {
      dataPreview: 'Datenvorschau',
      dataPreviewFor: 'Datenvorschau für {{fieldLabel}}',
      hoverLineBeginning: 'Fahren Sie über eine Zeile links ',
      hoverLineEnd: 'für mehr Details.',
      noData: 'Keine Daten',
      totalCount_one: '{{count}} Zeile insgesamt',
      totalCount_other: '{{count}} Zeilen insgesamt, ',
      valueCount_one: ' {{count}} hat einen Wert',
      valueCount_other: ' {{count}} haben einen Wert'
    },
    DataSourceTable: {
      sheetTitle: 'Blatttitel'
    },
    Dropzone: {
      dropFileAlt: 'Datei hier ablegen',
      dropFileBeginning: 'Datei irgendwo hinziehen, ',
      dropFileEnd: 'um sie hochzuladen',
      uploadFailedContent_one:
        'Die Datei {{fileName}}, die Sie hochgeladen haben, ist größer als 1 GB. Bitte formatieren Sie Ihre Datei so, dass sie kleiner als 1 GB ist und laden Sie sie erneut hoch.',
      uploadFailedContent_other:
        'Die Dateien {{fileName}}, die Sie hochgeladen haben, sind größer als 1 GB. Bitte formatieren Sie Ihre Dateien so, dass sie kleiner als 1 GB sind und laden Sie sie erneut hoch.',
      uploadFailedHeader: 'Hochladen fehlgeschlagen'
    },
    EmbedNextAction: {
      csvConvert:
        'Dies könnte länger als 3 Minuten dauern. Für eine schnellere Verarbeitung konvertieren Sie in eine CSV-Datei.',
      processingFile: 'Verarbeitung von {{fileName}} läuft ...'
    },
    FileUploader: {
      orBlock: 'oder'
    },
    FilterWithCount: {
      showAllErrors: 'Alle Fehler anzeigen'
    },
    RecordStatusBanner: {
      discardAndFinish: 'Alle verwerfen und fertigstellen!',
      rejectedDescription_one:
        'Sie haben {{count}} Datensatz, der vom Server abgelehnt wurde. Sie können ihn entweder ignorieren und fortfahren oder ihn korrigieren und erneut importieren.',
      rejectedDescription_other:
        'Sie haben {{count}} Datensätze, die vom Server abgelehnt wurden. Sie können sie alle ignorieren und fortfahren oder sie korrigieren und erneut importieren.',
      titleRejected_one: ' {{count}} abgelehnter Datensatz',
      titleRejected_other: ' {{count}} abgelehnte Datensätze',
      titleSuccess_one: '{{count}} Datensatz erfolgreich importiert,',
      titleSuccess_other: '{{count}} Datensätze erfolgreich importiert,'
    }
  },
  elements: {
    buttons: {
      cancel: 'Abbrechen',
      continue: 'Fortfahren',
      selectFile: 'Datei auswählen',
      startOver: 'Von vorn beginnen',
      submit: 'Senden'
    },
    filters: {
      allRows: 'Alle Zeilen',
      invalidRows: 'Fehler',
      mergedRows: 'Zuletzt zusammengeführt',
      showAllErrors: 'Alle Fehler anzeigen',
      showFieldErrors: '{{fieldName}}-Fehler',
      validRows: 'Gültig'
    },
    loaders: {
      initializing: 'Ihr Importprogramm wird initialisiert',
      loading: 'Laden',
      loadingData: 'Ihre Daten werden geladen',
      normalizing: 'Daten normalisieren',
      preparing: 'Ihre Daten werden zur Überprüfung vorbereitet',
      processing: 'Verarbeiten',
      saving: 'Wird gespeichert',
      scanning: 'Unstimmigkeiten werden gesucht'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: 'Verarbeitung kann nicht abgebrochen werden'
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      dataHooksErr: {
        messsage:
          'Automatische Umformatierung kann nicht durchgeführt werden. Halten Sie den Mauszeiger über einen beliebigen Fehler, um eine Anleitung zur manuellen Korrektur eines Wertes zu erhalten. Falls Sie weitere Hilfe benötigen, kontaktieren Sie uns.',
        title: 'Fehler bei automatischer Umformatierung'
      }
    },
    Layout: {
      mobileDevice:
        'Bitte nutzen Sie ein Gerät mit einem größeren Bildschirm, um diesen Workflow fortzusetzen.'
    },
    SkipHeaderRow: {
      noHeaderRow: 'Haben Sie keine Kopfzeile?',
      skipHeaderRow: 'Überspringen und Buchstaben als Überschriften verwenden.'
    }
  },
  hooks: {
    useEmbedContinueFlow: {
      pickUp: 'Weitermachen, wo Sie aufgehört haben?'
    },
    useConfirmDismissModal: {
      dismissHeader: 'Eintrag/Einträge verwerfen',
      dismissLabel: 'Verwerfen',
      linkRecords: '{{countRecords}} Einträge in {{schemaName}} Tabelle',
      paragraphOne:
        'Durch Verwerfen dieser Einträge werden verbundene Werte für {{message}} entfernt.',
      paragraphTwo: 'Möchten Sie sie verwerfen?'
    },
    useMappingWarningModal: {
      manual:
        'Sie können diese Daten auch manuell eingeben oder neue Daten hochladen, um diese Felder zu einem späteren Zeitpunkt zu füllen.',
      proceed:
        'Klicken Sie auf „Weiter“, um ohne Abgleich dieser Felder fortzufahren, oder auf „Abbrechen“, um zurückzugehen und abzugleichen.',
      requiredFields:
        'Die folgenden Felder sind erforderlich, um die Daten in das System einzugeben:'
    }
  },
  scenes: {
    '1_InitialScene': {
      nonWhiteLabel: 'Sie gliedern Daten sicher mit {{company}} ein',
      uploadingFile: 'Ihre Datei wird hochgeladen',
      fileTooLargeError:
        'Die Datei {{fileName}}, die Sie hochgeladen haben, ist größer als 1 GB. Bitte formatieren Sie Ihre Datei so, dass sie kleiner als 1 GB ist und laden Sie sie erneut hoch.'
    },
    '5_ReviewScene': {
      loadingData: 'Ihre wunderschönen Daten werden verarbeitet ...',
      cancelLabel: 'Verwerfen und weiter',
      confirmLabel: 'Fehler beheben',
      discardSelected_one: '{{count}} ausgewählte Zeile verwerfen',
      discardSelected_other: '{{count}} ausgewählte Zeilen verwerfen',
      generalEmptyState: 'Etwas ist schiefgelaufen',
      specificEmptyState:
        'Wir haben Probleme, Ihre Daten zu laden. Vielleicht versuchen Sie, eine Datei ohne Daten zu importieren, oder Sie haben ein Problem mit einem Daten-Hook. Bitte versuchen Sie, die Seite neu zu laden, und kontaktieren Sie uns, wenn das Problem weiter besteht.'
    },
    '6_ErrorScene': {
      importError: 'Beim Import ist etwas schiefgelaufen:'
    },
    '7_FinalScene': {
      showReviewMessage:
        'Einige Ihrer Daten wurde von {{teamName}} nicht akzeptiert. Sehen Sie eine Vorschau der zurückgewiesenen Daten in der untenstehenden Tabelle, wählen Sie, ob Sie diese Daten überprüfen und korrigieren oder verwerfen möchten, und schließen Sie Ihren Import ab.',
      aboutToSubmit_one: 'Sie sind dabei, {{count}} Datensatz einzugeben!',
      aboutToSubmit_other: 'Sie sind dabei, {{count}} Datensätze einzugeben!',
      recordCount: '{{count}} Datensätze',
      recordCount_one: '{{count}} Datensatz',
      rejectedStatusLabel: 'Zurückgewiesen',
      rowsRemaining: 'Übrige Zeilen'
    },
    MatchingV3: {
      cancelLabel: 'Nein, abbrechen',
      columnHeaders: 'Spalten in Ihrer Datei',
      confirmLabel: 'Ja, fortfahren',
      contentsAfterBreak: 'Möchten Sie fortfahren?',
      contentsBeforeBreak:
        'Ihre Änderungen werden sich auf die Daten im nächsten Schritt auswirken.',
      emptyState: 'Sie haben keine aktiven Spalten.',
      excludeColumnMatch: 'Diese Spalte vom Abgleich ausschließen',
      header: 'Sind Sie sicher?',
      numberUnmatched: ' keine Übereinstimmung',
      unmatchedRequiredFields: 'Nicht zugeordnete(s) vorgeschriebene(s) Feld(er):'
    }
  },
  textOverrides: {
    backToMatching: 'Zurück zur Zuordnung',
    dragAndDropHint: 'Legen Sie Ihre Datei hier ab',
    fileTypeHint: 'Und andere ähnliche Dateitypen',
    headerCheck: 'Ändern oder bestätigen Sie die Auswahl der Überschrift',
    headerCheckAction: 'Überspringen und Buchstaben als Überschriften verwenden.',
    headerCheckDesc: '',
    headerInitial: 'Ihre Datei hochladen',
    headerInitialDesc: '',
    importRecords_one: '{{count}} Eintrag importieren',
    importRecords_other: '{{count}} Einträge importieren',
    importRecords_zero: 'Keine gültigen Einträge',
    saveAndFinalize: 'Speichern und abschließen',
    headerMatch: 'Ändern oder bestätigen Sie die Übereinstimmung der Spalten',
    headerMatchDesc: '',
    headerReview: 'Prüfen und fertigstellen',
    headerReviewDesc: '',
    headerSource: 'Wählen Sie das Blatt aus, das Sie hochladen wollen',
    headerSourceDesc: '',
    templateFieldsLabel: 'Vorlagenfelder',
    ctaLabelFinal: '',
    rowFilter_allRows: 'Alle Zeilen',
    rowFilter_valid: 'Gültig',
    rowFilter_error: 'Fehler',
    discardModalHeader: 'Datensätze mit Fehlern verwerfen',
    discardModalDescriptionBeginning: 'Zum Fortfahren verwerfen ',
    discardModalDescription_one: '{{count}} Datensatz',
    discardModalDescription_other: '{{count}} Datensätze',
    discardModalDescriptionEnd: 'mit ungelösten Fehlern.',
    allInvalidErrHeading: 'Keine gültigen Einträge',
    allInvalidErrDescription:
      'Sie brauchen mindestens einen gültigen Eintrag, um Daten importieren zu können.',
    loaderMessageImporting: 'Importieren',
    successfulImport_one: 'Sie haben {{count}} Eintrag importiert!',
    successfulImport_other: 'Sie haben {{count}} Einträge importiert!',
    finalViewImportedTab: 'Importiert',
    finalViewReviewRejectedButton: 'Review rejected records',
    invalidFileType:
      'File is not a supported file type. Upload a .csv, .xls, .xlsx, .txt or .gz file.',
    columnExcluded_one:
      'The {{count}} column listed below is excluded from matching. Click on a column name to add it back to the list above or',
    columnExcluded_other:
      'The {{count}} columns listed below are excluded from matching. Click on a column name to add it back to the list above or',
    addAllLink: ' add all.',
    numberSubmitted_one: '{{count}} Eintrag eingereicht!',
    numberSubmitted_other: '{{count}} Einträge eingereicht!'
  },
  themes: {
    flexport: {
      dragAndDropHint: '',
      headerSourceDesc: '',
      headerCheckDesc: '',
      headerCheckAction: '',
      headerMatchDesc: '',
      headerReviewDesc: '',
      headerInitialDesc: ''
    },
    sage: {
      headerInitialDesc: ''
    }
  },
  miscEmbeds: {
    devMode: 'Entwicklermodus'
  },
  unmatched: 'keine Übereinstimmung',
  matched: 'abgestimmt',
  suggested: 'empfohlen',
  unique: 'Einzigartig',
  custom: 'Brauch',
  searchOrSelect: 'Suchen oder auswählen...',
  addAsCustom: 'Als benutzerdefiniertes Fieldlect hinzufügen'
}
