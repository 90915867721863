import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { getOnboardingContext } from 'src/contexts/OnboardingContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_BATCHES } from 'src/queries/GET_BATCHES'
import { GetBatches, GetBatchesVariables } from 'src/queries/types/GetBatches'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACES } from 'src/smart/queries/SQ_WORKSPACES'
import { EWorkspaceStatus } from 'src/types/enums/EWorkspaceStatus'
import { EBatchType } from 'src/types/interfaces/IBatch'
import { ETeamUserRole } from 'src/types/interfaces/ITeam'
import { stripEndSlash } from 'src/utils/stripEndSlash'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'

export const MainTeamScene = withRouter(
  ({ match: { url } }: RouteComponentProps<{ teamId: string }>) => {
    const environment = useContext(EnvironmentContext)
    const sessionContext = useContext(SessionContext)
    const team = useContext(TeamContext)
    const { isCollaborator } = useTeamRole()
    url = stripEndSlash(url)

    if (team.capabilities.concierge || team.role === ETeamUserRole.collaborator) {
      const progressContext = getOnboardingContext().value

      const workspaces = useSmartQuery(SQ_WORKSPACES, {
        variables: {
          environmentId: environment?.id,
          status: EWorkspaceStatus.ACTIVE,
          teamId: sessionContext.activeTeamId
        }
      })

      if (workspaces.data?.length > 0) progressContext.workspace = true

      if (workspaces.alert) {
        return queryAlert(workspaces)
      }

      return (
        <>
          {progressContext.workspace || isCollaborator ? (
            <Redirect to={`${url}/workspaces`} />
          ) : (
            <Redirect to={`${url}/setup`} />
          )}
        </>
      )
    } else if (team.capabilities.portal) {
      const { data, loading } = useQuery<GetBatches, GetBatchesVariables>(GET_BATCHES, {
        variables: {
          environmentId: environment?.id,
          licenseKey: team.licenses[0].key,
          type: [EBatchType.legacyUpload],
          ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE)
        },
        fetchPolicy: 'network-only'
      })

      if (loading) {
        return <Skeleton height={300} />
      } else {
        return (
          <>
            {data?.getBatches.data.length ? (
              <Redirect to={`${url}/imports`} />
            ) : (
              <Redirect to={`${url}/templates`} />
            )}
          </>
        )
      }
    } else {
      return <Redirect to={`${url}/templates`} />
    }
  }
)
