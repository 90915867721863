import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'

import {
  SmartGetNumericallyIndexedViewRows,
  SmartGetNumericallyIndexedViewRowsVariables,
  SmartGetNumericallyIndexedViewRows_getNumericallyIndexedViewRows
} from './types/SmartGetNumericallyIndexedViewRows'

export const SQ_GET_NUMERICALLY_INDEXED_VIEW: SmartQuery<
  SmartGetNumericallyIndexedViewRowsVariables,
  SmartGetNumericallyIndexedViewRows,
  SmartGetNumericallyIndexedViewRows_getNumericallyIndexedViewRows
> = {
  query: gql`
    query SmartGetNumericallyIndexedViewRows(
      $dataSourceIndex: Int!
      $limit: Int
      $skip: Int
      $uploadId: UUID!
    ) {
      getNumericallyIndexedViewRows(
        dataSourceIndex: $dataSourceIndex
        limit: $limit
        skip: $skip
        uploadId: $uploadId
      ) {
        autoDetectedHeaderRowIndex
        rawRowData
      }
    }
  `,
  extract({ getNumericallyIndexedViewRows }) {
    return getNumericallyIndexedViewRows
  }
}
