import { createContext } from 'react'

import { CurrentUser_myCapabilities } from 'src/queries/types/CurrentUser'
import { FeatureFlags } from 'src/types/interfaces/FeatureFlags'
import { ITeam } from 'src/types/interfaces/ITeam'

export const TeamContext = createContext<ITeamWithCapabilities>({} as ITeamWithCapabilities)

export interface ITeamWithCapabilities extends ITeam {
  capabilities: CurrentUser_myCapabilities
  featureFlags?: Partial<FeatureFlags>
}
