import { gql } from '@apollo/client'
import {
  SmartViewFields,
  SmartViewFields_getViewFields,
  SmartViewFieldsVariables
} from 'src/smart/queries/types/SmartViewFields'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_VIEW_FIELDS: SmartQuery<
  SmartViewFieldsVariables,
  SmartViewFields,
  SmartViewFields_getViewFields
> = {
  query: gql`
    query SmartViewFields($viewId: Int!) {
      getViewFields(viewId: $viewId) {
        data {
          id
          label
          matchKey
          matchKeySub
          matchMeta
          order
          previewData
          confirmed
          ignored
          suggestedMatchKey
          columnDensity
          totalRows
        }
        validation {
          message
          fields
          uniqueProps
          type
        }
      }
    }
  `,
  extract({ getViewFields }) {
    return getViewFields
  }
}
