import { useContext } from 'react'

import { BillingPlanContext } from 'src/contexts/BillingPlanContext'

export function BillingPlanStatusSwitch({
  onLoading,
  onActivePlan,
  onExpiredTrial
}: {
  onLoading(): JSX.Element
  onActivePlan(): JSX.Element
  onExpiredTrial(): JSX.Element
}): JSX.Element {
  const { deactivated, loaded } = useContext(BillingPlanContext)
  if (!loaded) {
    return onLoading()
  }
  return deactivated ? onExpiredTrial() : onActivePlan()
}
