import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import AceEditor from 'react-ace'

import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { Spacer } from 'src/applications/Oversight/components/ActionsContainer'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { TeamContext } from 'src/contexts/TeamContext'
import { accessTokenHeader } from 'src/resources/clients/graphClient'
import { Colors } from 'src/resources/colors'
import { ApiDetail, ApiMethod } from 'src/resources/elements/ApiDetail'
import { BlockTitle } from 'src/resources/elements/BlockTitle'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatBooleanInput } from 'src/resources/elements/form/BooleanInput'
import { Icon } from 'src/resources/elements/Icon'
import { ResponseInfo } from 'src/resources/elements/ResponseInfo'
import { useFetch } from 'src/resources/hooks/useFetch'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { storage } from 'src/resources/utils/storage'
import styled from 'styled-components'

const Label = styled.div`
  font-size: ${fontSizes.type14};
  margin-right: ${Spacing.basePadding2x};
  height: ${Spacing.basePadding4x};
  line-height: ${Spacing.basePadding4x};
  margin-bottom: ${Spacing.basePadding2x};
`

const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${Spacing.basePadding2x};

  ${Label} {
    margin-bottom: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
`

const Column = styled.div`
  background-color: ${Colors.white};
  border-left: 1px solid ${Colors.border};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
`

const ScrollArea = styled.div`
  inset: ${Spacing.basePadding2x};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 61px;

  ${ButtonBase} + ${ButtonBase} {
    margin-left: ${Spacing.basePadding};
  }
`

const StyledAceEditor = styled(AceEditor)`
  margin: 0;
  max-width: 460px;
  width: 100%;
`

function queryParams(uri: string, params: Record<string, any>) {
  return `${uri}?${queryString.stringify(params)}`
}

export function RestApiEndpointTool({
  initialBody = {},
  method,
  params,
  uri
}: {
  initialBody?: object
  method: ApiMethod
  params?: Record<string, string>
  uri: string
}) {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const storageKey = useMemo(() => `${team.id}:body:${method}:${uri}`, [method, uri])
  const [bodyString, setBody] = useState<string>('')
  const handleBlur = useCallback(
    (_event, editor) => {
      try {
        const value = JSON.parse(editor.getValue())
        if (method === 'post' && !('data' in value)) {
          value.data = []
        }
        const formattedBody = JSON.stringify(value, null, 2)
        if (bodyString !== formattedBody) {
          setBody(formattedBody)
          storage(storageKey).setRaw(formattedBody)
          resetSubmit()
        }
      } catch (e) {
        // no op
      }
    },
    [setBody, storageKey, bodyString]
  )
  const [showCodeExamples, setShowCodeExamples] = useState(false)
  const toggleShowApi = useCallback(
    () => setShowCodeExamples(!showCodeExamples),
    [setShowCodeExamples, showCodeExamples]
  )
  const parsedBody = useMemo(() => {
    try {
      return JSON.parse(bodyString)
    } catch (e) {
      return undefined
    }
  }, [bodyString])
  const dataRowCount = useMemo(() => {
    if (parsedBody && Array.isArray((parsedBody as any).data)) {
      return (parsedBody as any).data.length
    }
    return 0
  }, [parsedBody])
  useEffect(() => {
    resetSubmit()
    const stored = storage(storageKey).getRaw()

    if (stored) {
      setBody(stored)
    } else {
      setBody(JSON.stringify(initialBody, null, 2))
    }
  }, [setBody, storageKey, params])
  const [submit, submitStatus, resetSubmit] = useFetch<string, {}>((body?: string) => [
    method === 'post' ? uri : queryParams(uri, { ...parsedBody, ...params }),
    {
      method,
      body,
      headers: {
        ...accessTokenHeader(),
        ...(method === 'post' ? { 'Content-Type': 'application/json' } : {})
      }
    }
  ])
  const submitButtonTitle = useMemo(() => {
    switch (method) {
      case 'post':
        return `Submit ${dataRowCount} row${dataRowCount === 1 ? '' : 's'}`
      case 'get':
        return 'Submit'
    }
  }, [dataRowCount, method])
  const submitRequest = useCallback(() => {
    submit(method === 'post' ? bodyString : undefined)
  }, [bodyString, submit])
  return (
    <Container>
      <Column>
        <BlockTitle>
          <span>1</span> Enter {method === 'post' ? 'data' : 'query parameters'}
        </BlockTitle>
        <StyledAceEditor
          fontSize={12}
          height='600px'
          mode='json'
          onBlur={handleBlur}
          theme=''
          value={bodyString}
          wrapEnabled={true}
        />
      </Column>
      <Column>
        <BlockTitle>
          <span>2</span> Submit
        </BlockTitle>
        <ScrollArea>
          <FlatButton
            onClick={submitRequest}
            color='primary'
            disabled={(method === 'post' && dataRowCount === 0) || submitStatus.loading}
          >
            <Icon name='arrow-circle-right' />
            {submitButtonTitle}
          </FlatButton>
          <FlatButton onClick={resetSubmit} color='secondary' disabled={!submitStatus.ready}>
            <Icon name='undo' />
            Reset
          </FlatButton>
          {submitStatus ? <ResponseInfo response={submitStatus} /> : null}
          <InputWrapper>
            <FlatBooleanInput onClick={toggleShowApi} value={showCodeExamples}>
              <span />
            </FlatBooleanInput>
            <Label>Show example code</Label>
            <Spacer grow />
            <Link to={`${teamRoot}/account/access-keys`}>
              <Icon name='key' />
              &nbsp;Access keys
            </Link>
          </InputWrapper>
          {showCodeExamples ? (
            <ApiDetail
              body={method === 'post' ? bodyString : undefined}
              method={method}
              uri={method === 'post' ? uri : queryParams(uri, { ...parsedBody, ...params })}
            />
          ) : null}
        </ScrollArea>
      </Column>
    </Container>
  )
}
