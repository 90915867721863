import { gql } from '@apollo/client'
import {
  RestoreView,
  RestoreViewVariables,
  RestoreView_restoreView
} from 'src/smart/mutations/types/RestoreView'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_RESTORE_VIEW: SmartMutation<
  RestoreViewVariables,
  RestoreView,
  RestoreView_restoreView
> = {
  mutation: gql`
    mutation RestoreView($viewId: ID!) {
      restoreView(viewId: $viewId) {
        id
        status
      }
    }
  `,
  extract: (response: any) => response.restoreView
}
