import { useCallback } from 'react'

import { SchemaMutationDetail } from 'src/applications/Oversight/components/SchemaMutationDetail'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { AutoFit } from 'src/controls/AutoFit'
import { Colors } from 'src/resources/colors'
import { ResourceBrowser } from 'src/resources/elements/ResourceBrowser'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_CREATE_SCHEMA_MUTATION } from 'src/smart/mutations/SM_CREATE_SCHEMA_MUTATION'
import { SQ_GET_SCHEMA_MUTATIONS } from 'src/smart/queries/SQ_GET_SCHEMA_MUTATIONS'
import { ISchema } from 'src/types/interfaces/ISchema'
import { ISchemaMutation } from 'src/types/interfaces/ISchemaMutation'
import styled, { css } from 'styled-components'

const Container = styled.div`
  height: calc(100vh - 353px);
  max-height: 100vh;
  min-height: 500px;
`

const StatusCircle = styled.div`
  background: ${Colors.white};
  border-radius: ${Spacing.basePadding};
  height: ${Spacing.basePadding2x};
  width: ${Spacing.basePadding2x};
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px;
`

const [On, Off] = [Colors.brandSuccess, Colors.red].map(
  (color) => styled.div`
    background: ${color};
    border-radius: 6px;
    height: 10px;
    width: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  `
)

const ListItem = styled.div<{ selected: boolean }>`
  background: ${Colors.white};
  cursor: pointer;
  padding: ${Spacing.basePadding};
  position: relative;

  ${({ selected }) =>
    selected
      ? css`
          background: ${Colors.brandBlue};
          color: ${Colors.white};
        `
      : null}
`

export const SchemaMutationsLegacy = ({
  schema,
  onSchemaIdUpdate
}: {
  schema: ISchema
  onSchemaIdUpdate(id: number | string, tab: string): void
}) => {
  const activeArchived = useActiveArchivedTabs({ center: true, compact: true })
  const mutations = useSmartQuery(SQ_GET_SCHEMA_MUTATIONS, {
    variables: {
      schemaId: schema.id,
      archived: activeArchived.showArchived
    }
  })

  const renderListItem = useCallback(
    ({
      resource,
      selected,
      onSelect
    }: {
      resource: ISchemaMutation
      selected: boolean
      onSelect: (id: string) => void
    }) => (
      <ListItem key={resource.id} selected={selected} onClick={() => onSelect(resource.id)}>
        <div>
          <small>
            <b>TYPE</b>
          </small>{' '}
          {resource.mutationType}
        </div>
        <AutoFit width>{resource.graphQLURI ?? 'No uri specified'}</AutoFit>
        <StatusCircle>{resource.enabled ? <On /> : <Off />}</StatusCircle>
      </ListItem>
    ),
    []
  )

  const createSchemaMutation = useSmartMutation(SM_CREATE_SCHEMA_MUTATION)

  const createResource = useCallback(async () => {
    const resource = await createSchemaMutation.run({ schemaId: schema.id })
    onSchemaIdUpdate(resource.schemaId, 'graphql')
  }, [createSchemaMutation, schema])

  const renderDetail = useCallback(
    ({ resource }: { resource: ISchemaMutation }) => (
      <SchemaMutationDetail
        schema={schema}
        mutation={resource}
        onSchemaIdUpdate={onSchemaIdUpdate}
      />
    ),
    [schema, SchemaMutationDetail, onSchemaIdUpdate]
  )

  return (
    <Container>
      <ResourceBrowser
        resourceListWidth={320}
        onCreateResource={createResource}
        renderDetail={renderDetail}
        renderListHead={activeArchived.tabs}
        renderListItem={renderListItem}
        resourceList={mutations.result}
        resourceName='mutation'
        showAddResource={activeArchived.showActive}
      />
    </Container>
  )
}
