import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    me {
      email
      id
      intercomHash
      superAdmin
      name
      githubUsername
    }
    currentTeam {
      id
      name
      type
      role
      licenses {
        id
        key
      }
      users {
        id
        name
        email
      }
      schemas(archived: false) {
        name
        id
      }
      features
      testModeOnly
    }
    myCapabilities {
      concierge
      isOwner
      mayCreateWorkspace
      mayManageSchemas
      mayRemoveUsers
      portal
      teamId
    }
    myTeams {
      id
      name
      type
      role
      licenses {
        id
        key
      }
      users {
        id
        name
        email
      }
      schemas(archived: false) {
        name
        id
      }
      features
      testModeOnly
    }
  }
`
