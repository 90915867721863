import { gql } from '@apollo/client'
import {
  UpdateWorkbookRows,
  UpdateWorkbookRowsVariables,
  UpdateWorkbookRows_updateWorkbookRows
} from 'src/smart/mutations/types/UpdateWorkbookRows'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_WORKBOOK_ROWS: SmartMutation<
  UpdateWorkbookRowsVariables,
  UpdateWorkbookRows,
  UpdateWorkbookRows_updateWorkbookRows
> = {
  mutation: gql`
    mutation UpdateWorkbookRows(
      $edits: [JSON!]!
      $workbookId: UUID
      $batchId: UUID
      $schemaId: Int
    ) {
      updateWorkbookRows(
        edits: $edits
        workbookId: $workbookId
        batchId: $batchId
        schemaId: $schemaId
      ) {
        rows {
          _id
          cells
          validations {
            error
            key
            message
          }
        }
      }
    }
  `,
  extract: (response) => response.updateWorkbookRows
}
