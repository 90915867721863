import { Colors } from 'src/resources/colors'
import styled from 'styled-components'

const VerticalLineStyled = styled.div<{
  height?: string
  margin?: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
}>`
  border: 1px solid ${Colors.border};
  height: ${({ height }) => height ?? null};
  margin-top: ${({ margin: { top } }) => top ?? null};
  margin-right: ${({ margin: { right } }) => right ?? null};
  margin-bottom: ${({ margin: { bottom } }) => bottom ?? null};
  margin-left: ${({ margin: { left } }) => left ?? null};
  width: 1px;
`

const VerticalLine = (props: {
  height?: string
  margin?: { top?: string; right?: string; bottom?: string; left?: string }
}) => {
  const { height, margin } = props
  return <VerticalLineStyled height={height} margin={margin} />
}

export default VerticalLine
