import { Colors } from 'src/resources/colors'

const sleep = (ms: number) => new Promise((resolve) => window.setTimeout(resolve, ms))

export const recycle = async () => {
  const shade = document.createElement('div')
  Object.assign(shade.style, {
    backgroundColor: Colors.white,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    bottom: '0',
    left: '0',
    opacity: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    transition: '0.5s ease opacity, 1s ease transform',
    zIndex: '10000000'
  })
  document.body.appendChild(shade)
  await sleep(250)
  shade.style.opacity = '1'
  window.location.reload()
}
