import { CustomTheme, defaultTheme } from '@turntable/core'
import { DefaultTheme } from 'src/applications/Embed/fragments/ThemeWrapper'

export const VindiciaTheme: Partial<typeof DefaultTheme & { fontFamily: string }> = {
  // General
  hideImportedCounter: true,
  primary: '#5204BE',
  primaryAccent: '#E6EDFF',
  circleColor: 'rgb(120, 221, 54)',
  // Buttons
  buttonPadding: '12px 20px',
  footerButtonWidth: 'initial',
  // Modals
  modalSecondaryDangerButtonStyle: {
    borderColor: '#FC757C',
    color: '#FC757C',
    hover: {
      backgroundColor: '#DD2C46',
      borderColor: '#DD2C46'
    }
  },
  // Table
  filterBorder: '1px solid #CAD0DC',
  filterMarginRight: '0px',
  filterFirstBorderRadius: '5px 0px 0px 5px',
  filterSecondBorderRadius: '0px',
  filterThirdBorderRadius: '0px 5px 5px 0px',

  dataTable: {
    ...(defaultTheme as CustomTheme),
    cell: {
      selected: {
        backgroundColor: '#E6EDFF'
      },
      error: {
        backgroundColor: 'rgba(252,117,124,0.2)'
      }
    }
  },
  //markdown
  paragraphSpacing: '1em',
  // final scene
  submittedFontSize: '16px',
  textOverrides: {
    backToMatching: 'themes.vindicia.backToMatching',
    importRecords_one: 'themes.vindicia.importRecords_one',
    importRecords_other: 'themes.vindicia.importRecords_other',
    saveAndFinalize: 'themes.vindicia.saveAndFinalize',
    headerInitial: 'themes.vindicia.headerInitial',
    headerInitialDesc: 'themes.vindicia.headerInitialDesc',
    dragAndDropHint: 'themes.vindicia.dragAndDropHint',
    fileTypeHint: 'themes.vindicia.fileTypeHint',
    headerSource: 'themes.vindicia.headerSource',
    headerSourceDesc: 'themes.vindicia.headerSourceDesc',
    headerCheck: 'themes.vindicia.headerCheck',
    headerCheckDesc: 'themes.vindicia.headerCheckDesc',
    headerCheckAction: '(Skip and use alphabet row instead)',
    headerMatch: 'themes.vindicia.headerMatch',
    headerMatchDesc: 'themes.vindicia.headerMatchDesc',
    headerReview: 'themes.vindicia.headerReview',
    headerReviewDesc: 'themes.vindicia.headerReviewDesc',
    templateFieldsLabel: 'themes.vindicia.templateFieldsLabel',
    ctaLabelFinal: 'themes.vindicia.ctaLabelFinal',
    rowFilter_allRows: 'themes.vindicia.rowFilter_allRows',
    rowFilter_valid: 'themes.vindicia.rowFilter_valid',
    rowFilter_error: 'themes.vindicia.rowFilter_error',
    discardModalHeader: 'themes.vindicia.discardModalHeader',
    discardModalDescriptionBeginning: 'themes.vindicia.discardModalDescriptionBeginning',
    discardModalDescription_one: 'themes.vindicia.discardModalDescription_one',
    discardModalDescription_other: 'themes.vindicia.discardModalDescription_other',
    discardModalDescriptionEnd: 'themes.vindicia.discardModalDescriptionEnd',
    allInvalidErrHeading: 'themes.vindicia.allInvalidErrHeading',
    allInvalidErrDescription: 'themes.vindicia.allInvalidErrDescription',
    loaderMessageImporting: 'themes.vindicia.loaderMessageImporting',
    successfulImport_one: 'themes.vindicia.successfulImport_one',
    successfulImport_other: 'themes.vindicia.successfulImport_other',
    finalViewImportedTab: 'themes.vindicia.finalViewImportedTab',
    invalidFileType: 'themes.vindicia.invalidFileType',
    columnExcluded_one: 'themes.vindicia.columnExcluded_one',
    columnExcluded_other: 'themes.vindicia.columnExcluded_other',
    addAllLink: 'themes.vindicia.addAllLink',
    numberSubmitted_one: 'themes.vindicia.numberSubmitted_one',
    numberSubmitted_other: 'themes.vindicia.numberSubmitted_other',
    finalViewReviewRejectedButton: 'themes.vindicia.finalViewReviewRejectedButton'
  }
}
