import { Colors } from 'src/resources/colors'

export const Shadows = {
  cardBg: `box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.05), 0px 3px 5px rgba(0, 0, 0, 0.05)`,
  cardHover:
    `box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.05),` +
    `0 2px 16px 0 rgba(0, 0, 0, 0.05), 0 24px 16px -16px rgba(0, 0, 0, 0.08), cursor: pointer,`,
  dark: '0 1px 3px rgba(0, 0, 0, 0.2)',
  focus: '0 1px 1px rgba(0, 0, 0, 0.1)',
  highlightBright: '0 0 2px rgba(255, 255, 255, 0.5), inset 0 0 2px 1px rgba(255, 255, 255, 0.5)',
  hover: '0 1px 5px rgba(0, 0, 0, 0.3)',
  large: '0 2px 16px rgba(0, 0, 0, 0.15)',
  light: `0 1px 2px ${Colors.grayLight}`,
  popOver:
    `box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.05),` +
    `0 2px 16px 0 rgba(0, 0, 0, 0.05), 0 24px 16px -16px rgba(0, 0, 0, 0.08),`,
  xLarge: '0 2px 16px 0 rgba(0, 0, 0, 0.6)'
}
