import { gql } from '@apollo/client'

export const GET_BILLING_PORTAL = gql`
  query GetBillingPortal($teamId: ID!, $redirectUrl: String!) {
    getBillingPortalSession(teamId: $teamId, redirectUrl: $redirectUrl) {
      accessUrl
      expiresAt
      raw
    }
  }
`
