import { gql } from '@apollo/client'
import {
  SmartCreateDataRequests,
  SmartCreateDataRequestsVariables,
  SmartCreateDataRequests_createDataRequests
} from 'src/smart/mutations/types/SmartCreateDataRequests'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_CREATE_DATA_REQUESTS: SmartMutation<
  SmartCreateDataRequestsVariables,
  SmartCreateDataRequests,
  SmartCreateDataRequests_createDataRequests[]
> = {
  mutation: gql`
    mutation SmartCreateDataRequests($workspaceId: UUID!, $schemaIds: [ID!]!, $teamId: ID!) {
      createDataRequests(workspaceId: $workspaceId, schemaIds: $schemaIds, teamId: $teamId) {
        workspaceId
      }
    }
  `,
  extract: (response) => response.createDataRequests
}
