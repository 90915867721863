import { ReactElement } from 'react'

import Tippy, { TippyProps } from '@tippyjs/react'
import { Colors } from 'src/resources/colors'
import styled from 'styled-components'

interface IDropdownMenu extends TippyProps {
  children: ReactElement
  content: ReactElement
  placement?: 'top' | 'bottom' | 'right' | 'left' | 'auto'
  // if true, child components can have nested menus
  interactive?: boolean
  offset?: [number, number]
  arrow?: boolean
  trigger?: string
  visible?: boolean
}

const arrowPlacement: { [key: string]: string } = {
  right: `border-right-color: ${Colors.white};`,
  left: `border-left-color: ${Colors.white};`,
  top: `border-top-color: ${Colors.white};`,
  bottom: `border-bottom-color: ${Colors.white};`
}

const arrowBorder: { [key: string]: string } = {
  right: 'left: -20px;',
  left: 'right: -20px;',
  top: 'bottom: -20px;',
  bottom: 'top: -20px;'
}

const DropdownWrapper = styled(Tippy)<{ arrow?: boolean; placement?: string }>`
  outline: none;

  .tippy-arrow {
    border: 10px solid transparent;
    ${({ placement, arrow }) => placement && arrow && arrowPlacement[placement]};
    ${({ placement, arrow }) => placement && arrow && arrowBorder[placement]};
    height: 8px;
  }
`

export const DropdownMenu = ({
  children,
  content,
  placement = 'right',
  arrow = true,
  trigger = 'click',
  interactive,
  offset,
  visible
}: IDropdownMenu) => {
  return (
    <DropdownWrapper
      content={content}
      interactive={interactive}
      placement={placement}
      trigger={trigger}
      arrow={arrow}
      offset={offset}
      visible={visible}
    >
      {children}
    </DropdownWrapper>
  )
}
