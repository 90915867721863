import { useEffect } from 'react'
import { accessTokenStorage } from 'src/resources/clients/graphClient'

export const TemporaryIOCOMRedirect = (): null => {
  useEffect(() => {
    if (window.location.host === 'app.flatfile.io') {
      const token = accessTokenStorage.get()
      const session = token ? `#session=${token}` : ''
      window.location.replace(`https://app.flatfile.com${window.location.pathname}${session}`)
    }
  }, [])
  return null
}
