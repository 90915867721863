import { ReactNode } from 'react'

import styled from 'styled-components'

import { Spacing } from '../layout'

const SubContainer = styled.section`
  padding: 0 ${Spacing.contentPadding} ${Spacing.contentPadding};

  > ul:first-child {
    margin-top: ${Spacing.basePadding};
  }

  code {
    font-family: 'Source Code Pro', monospace;
  }
`

export const SubContentContainer = ({ children }: { children?: ReactNode | string }) => {
  return <SubContainer>{children}</SubContainer>
}
