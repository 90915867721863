import { ReactNode, useContext, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { FLATFILE_IS_CLOUD_ENV } from 'src/config'
import { BillingPlanContext, IBillingPlan } from 'src/contexts/BillingPlanContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { GET_ACCOUNT_STATUS } from 'src/queries/GET_ACCOUNT_STATUS'
import { GetAccountStatus, GetAccountStatusVariables } from 'src/queries/types/GetAccountStatus'

export function BillingPlanManager({ children }: { children: ReactNode }): JSX.Element {
  const sessionContext = useContext(SessionContext)
  const teamId = sessionContext.activeTeamId
  const skip = !FLATFILE_IS_CLOUD_ENV || !teamId
  const accountStatusQuery = useQuery<GetAccountStatus, GetAccountStatusVariables>(
    GET_ACCOUNT_STATUS,
    {
      fetchPolicy: 'no-cache',
      skip,
      variables: { teamId }
    }
  )
  const billingPlan = useMemo((): IBillingPlan => {
    const { data, error } = accountStatusQuery
    if (skip || error) {
      // don't lock users out if chargebee info isn't available
      return { loaded: true, deactivated: false, trialEndsAt: NaN }
    }
    const deactivated =
      (data?.getAccountStatus.plan !== null && data?.getAccountStatus.status === 'Cancelled') ??
      false
    return {
      deactivated,
      loaded: !!data?.getAccountStatus,
      trialEndsAt: data?.getAccountStatus?.trialEndsAt
    }
  }, [accountStatusQuery])
  return <BillingPlanContext.Provider value={billingPlan}>{children}</BillingPlanContext.Provider>
}
