import { gql } from 'apollo-boost'
import { EndUser, EndUserVariables, EndUser_endUser } from 'src/smart/queries/types/EndUser'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_END_USER: SmartQuery<EndUserVariables, EndUser, EndUser_endUser> = {
  query: gql`
    query EndUser($id: UUID!) {
      endUser(id: $id) {
        id
        userId
        name
        email
        companyId
        companyName
      }
    }
  `,
  extract({ endUser }) {
    return endUser
  }
}
