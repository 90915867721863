type TContentType = {
  extension: string
  contentType: string
}

// Use .xls? to ensure that it does not match .xlsx (the signed url will have params following the extension)
const contentTypes: TContentType[] = [
  { extension: '.csv', contentType: 'text/csv' },
  {
    extension: '.xlsx',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  { extension: '.txt', contentType: 'text/plain' },
  { extension: '.xls?', contentType: 'application/vnd.ms-excel' }
]

export const getContentTypeFromSignedUrl = (signedUrl: string): string => {
  return contentTypes.reduce((header: string, fileType: TContentType) => {
    if (signedUrl.includes(fileType.extension)) {
      return fileType.contentType
    }
    return header
  }, 'text/csv')
}
