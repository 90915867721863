import { useState, useMemo } from 'react'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { Duplicate, MagnifyingGlass } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { OverflowData } from './OverflowData'

const VariableItemCopyStatus = styled.div`
  color: ${Colors.brandPrimary};
  background: ${Colors.pigeon100};
  opacity: 0;
  transition: ${Transitions.baseEase};
  position: absolute;
  right: ${Spacing.basePadding};
  padding: 4px;
  top: 0;
  font-size: ${fontSizes.type14};
  display: flex;
  align-items: center;
`

const VariableItem = styled.div`
  padding: ${Spacing.halfBasePadding} ${Spacing.baseAndHalfPadding};
  transition: ${Transitions.baseEase};
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background: ${Colors.pigeon100};
    ${VariableItemCopyStatus} {
      opacity: 1;
    }
  }
  span {
    font-size: ${fontSizes.type14};
  }
`
const VariableItemKey = styled.span`
  color: ${Colors.pigeon700};
  overflow: hidden;
  text-overflow: ellipsis;
`
const VariableItemLabel = styled.span`
  color: #090b2b;
  margin-right: ${Spacing.basePadding};
  overflow: hidden;
  text-overflow: ellipsis;
`

const VariableDealie = ({ value, label }: { value: string; label: string }) => {
  const [copied, setCopied] = useState<Boolean>(false)

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => setCopied(true)}
      onMouseOut={() => setCopied(false)}
    >
      <VariableItem>
        <OverflowData
          dataFor={label}
          dataTip={label}
          offset={{ top: 0, left: 0 }}
          maxWidth={125}
          maxLines={1}
        >
          <VariableItemLabel>{String(label)}</VariableItemLabel>
        </OverflowData>
        <OverflowData
          dataFor={value}
          dataTip={value}
          offset={{ top: 0, left: 0 }}
          maxWidth={100}
          maxLines={1}
        >
          <VariableItemKey>{value}</VariableItemKey>
        </OverflowData>

        <VariableItemCopyStatus>
          {copied ? (
            'Copied!'
          ) : (
            <>
              <Duplicate fill={Colors.brandPrimary} />
              copy key
            </>
          )}
        </VariableItemCopyStatus>
      </VariableItem>
    </CopyToClipboard>
  )
}

const VariablesTrayWrapper = styled.div<{ visible: boolean }>`
    position: absolute;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 200px;
    max-width: 250px;
    transform: translateX(100%);
    pointer-events: none;
    transition: ${Transitions.baseEase};
    overflow: scroll;
    ${({ visible }) =>
      visible &&
      `
            transform: translateX(0);
            pointer-events: all;
        `}
    }
`

const VariablesTrayHeader = styled.div`
  position: sticky;
  top: 0;
  padding-top: ${Spacing.baseAndHalfPadding};
  background: #fff;
  z-index: 1;
`

const SearchWrapper = styled.div`
  position: relative;
  margin: 6px 10px;
  svg {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const VariableItems = styled.div`
  overflow: scroll;
  height: 100%;
`

const SearchResultsCount = styled.p`
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`

const FloatingInput = styled.input`
  font-weight: 500;
  max-width: unset;
  width: 100%;
  position: relative;
  line-height: 1;
  font-size: 14px;
  margin: 0;
  padding: 4px 20px;
  border: 1px solid ${Colors.pigeon400};
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  border-radius: 8px;
  padding-left: 36px;
  transition: ${Transitions.baseEase};

  &::-webkit-search-cancel-button {
    position: relative;
    right: 0;
    -webkit-appearance: none;
    height: 26px;
    width: 16px;
    border-radius: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6 6 2 2M4 8l2-2-2 2Zm2-2 2-2-2 2Zm0 0L4 4l2 2Z' stroke='%238b93a4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    opacity: 0.8;
    transition: ${Transitions.baseEase};
    &:hover {
      opacity: 1;
    }
  }
  &:focus {
    border-color: ${Colors.brandPrimary};
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(59 47 201 / 80%);
  }
`

export const VariablesTray = ({ properties, visible }: { properties: any; visible: boolean }) => {
  const [search, setSearch] = useState('')

  const searchResults = useMemo(() => {
    const filteredResultKeys = Object.entries(properties)
      .filter(
        ([key, value]: [key: string, value: { label: string }]) =>
          key.includes(search) || value.label.includes(search)
      )
      .map((i) => i[0])

    const filteredProperties: { [key: string]: any } = Object.keys(properties)
      .filter((key) => filteredResultKeys.includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: properties[key] })
      }, {})

    return filteredProperties
  }, [search])

  const VariablesCount = useMemo(() => {
    const totalCount = Object.keys(properties).length
    const searchCount = Object.keys(searchResults).length
    return (
      <SearchResultsCount>
        {totalCount !== searchCount ? `${searchCount} / ${totalCount}` : searchCount} variables
      </SearchResultsCount>
    )
  }, [search])

  return (
    <VariablesTrayWrapper visible={visible}>
      <VariablesTrayHeader>
        {VariablesCount}
        <SearchWrapper>
          <MagnifyingGlass />
          <FloatingInput
            type='search'
            placeholder='Search'
            onChange={(e) => setSearch(e.currentTarget.value)}
            value={search}
          />
        </SearchWrapper>
      </VariablesTrayHeader>
      <VariableItems>
        {searchResults &&
          Object.keys(searchResults).map((key) => {
            const property = searchResults[key]
            return <VariableDealie key={key} value={key} label={property.label} />
          })}
      </VariableItems>
    </VariablesTrayWrapper>
  )
}
