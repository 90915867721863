import { useCallback, useEffect, useMemo, useState } from 'react'

import { UpdatedEditDataHook } from 'src/applications/Oversight/components/UpdatedEditDataHook'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { Select } from 'src/resources/elements/form/Select'
import { ResourceSidebarItem } from 'src/resources/elements/ResourceSidebarItem'
import { UpdatedResourceBrowser } from 'src/resources/elements/UpdatedResourceBrowser'
import { useLocalStorageState } from 'src/resources/hooks/useLocalStorageState'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { inputStyles } from 'src/resources/inputs'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_CREATE_DATAHOOK } from 'src/smart/mutations/SM_CREATE_DATAHOOK'
import { SQ_DATAHOOKS } from 'src/smart/queries/SQ_DATAHOOKS'
import { useDataHookUpdatedSubscription } from 'src/smart/queries/SQ_DATAHOOK_UPDATED_SUBSCRIPTION'
import { SmartGetDataHooks_getDataHooks } from 'src/smart/queries/types/SmartGetDataHooks'
import { EDeploymentState } from 'src/types/enums/EDeploymentState'
import { ISchema } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'
import useRouter from 'use-react-router'

const HookStatusDescriptions = {
  [EDeploymentState.DRAFT]: 'Not Deployed',
  [EDeploymentState.DEPLOYING]: 'Deploying...',
  [EDeploymentState.DEPLOY_SUCCESS]: 'Deployment Successful',
  [EDeploymentState.DEPLOY_FAILURE]: 'Deployment Failed',
  [EDeploymentState.SDK]: 'Deployed from SDK',
  [EDeploymentState.READONLY]: 'Read-Only'
}

const ItemFilters = styled.div`
  padding: 0 ${Spacing.basePadding2x} ${Spacing.basePadding};
  display: flex;
  justify-content: space-between;
  > * {
    &:nth-child(1) {
      flex: 0;
    }

    &:nth-child(2) {
      flex: 0 0 115px;
    }
  }
`

export interface FilterOption {
  value: string
  label: string
}

export const availableFilters: FilterOption[] = [
  { value: 'recently-modified', label: 'Recently Modified' },
  { value: 'alphabetically', label: 'Alphabetical' }
]

const recentlyAdded = (a: SmartGetDataHooks_getDataHooks, b: SmartGetDataHooks_getDataHooks) => {
  return Number(b.createdAt) - Number(a.createdAt)
}
const alphabetically = (a: SmartGetDataHooks_getDataHooks, b: SmartGetDataHooks_getDataHooks) => {
  const c = a.name || 'Untitled'
  const d = b.name || 'Untitled'
  if (c < d) {
    return -1
  }
  if (c > d) {
    return 1
  }
  return 0
}

export function TemplateDataHooksPanel({
  schema,
  onSchemaIdUpdate
}: {
  schema: ISchema
  onSchemaIdUpdate(id: number | string, tab: string, dataHook?: string): void
}) {
  const [showInactive, setShowInactive] = useLocalStorageState('showInactiveDataHooks', false)
  const SCHEMA_DEPLOYED_FROM_SDK = !!schema.slug
  const toggleShowInactive = useCallback(() => {
    setShowInactive(!showInactive)
  }, [setShowInactive, showInactive])

  const [sortOrder, setSortOrder] = useState('recently-modified')
  const paramDataHookId = useSearchParam.string('dataHookId', null)
  const { history } = useRouter()

  const allDataHooks = useSmartQuery(SQ_DATAHOOKS, {
    variables: { schemaId: schema.id }
  })

  const updatedHook = useDataHookUpdatedSubscription(schema.id)

  const setSelectedResourceId = (id?: string) => {
    if (id) {
      history.replace({
        pathname: history.location.pathname,
        search: `?tab=data-hooks&dataHookId=${id}`
      })
    }
  }

  const dataHooks = useMemo(() => {
    const filteredDataHooks = !showInactive
      ? allDataHooks?.result?.filter((hook) => hook.archived === false)
      : allDataHooks?.result

    const sortedDataHooks =
      sortOrder === 'alphabetically'
        ? filteredDataHooks?.slice().sort(alphabetically)
        : filteredDataHooks?.slice().sort(recentlyAdded)

    return sortedDataHooks
  }, [allDataHooks.result, showInactive, sortOrder])

  const selectedResource = useMemo(() => {
    let resource
    if (paramDataHookId) {
      resource = allDataHooks?.result?.find((hook) => hook.id === paramDataHookId)
    }

    return resource
  }, [paramDataHookId, allDataHooks.result])

  const createDataHook = useSmartMutation(SM_CREATE_DATAHOOK)

  const handleAdd = useCallback(async () => {
    const newHook = await createDataHook.run({
      schemaId: schema.id
    })
    onSchemaIdUpdate(newHook.schemaId, 'data-hooks', newHook.id)
  }, [onSchemaIdUpdate, allDataHooks?.result])

  const refetchHooks = async (id?: string) => {
    await allDataHooks.state.refetch({ schemaId: schema.id })
    setSelectedResourceId(id)
  }

  useEffect(() => {
    if (updatedHook) {
      refetchHooks()
    }
  }, [updatedHook])

  return (
    <UpdatedResourceBrowser
      actionBar={
        <ItemFilters>
          <Select
            key={sortOrder}
            defaultValue={sortOrder}
            selectedValue={sortOrder}
            onChange={(option) => {
              setSortOrder(option.value)
            }}
            options={availableFilters}
            placeholder='All records'
            styles={{
              control: (provided: any, _state: any) => ({
                ...provided,
                fontSize: inputStyles.fontSize,
                fontWeight: inputStyles.fontWeight,
                height: '36px',
                minHeight: 'unset',
                border: 'none',
                width: '146px'
              }),
              placeholder: () => ({}),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              indicatorContainer: () => ({
                padding: `6px`
              }),
              input: () => ({
                opacity: '0'
              }),
              menu: (provided: any) => ({
                ...provided,
                width: '150px'
              }),
              singleValue: () => ({
                color: '#090b2b',
                fontSize: '12px'
              }),
              valueContainer: () => ({
                color: '#090b2b',
                padding: `6px 0px 6px 8px`,
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center'
              }),
              option: (styles: any) => ({
                ...styles,
                fontSize: '12px'
              })
            }}
          />
          <BooleanInput
            onChange={toggleShowInactive}
            label='Show inactive'
            checked={showInactive}
            small
          />
        </ItemFilters>
      }
      sidebarConfig={{
        title: 'Data Hook Library',
        activeCount: true
      }}
      items={dataHooks?.map((hook) => {
        return (
          <ResourceSidebarItem
            key={hook.id}
            onClick={setSelectedResourceId}
            name={hook.name}
            id={hook.id}
            selected={selectedResource?.id === hook.id}
            enabled={hook.deploymentState === EDeploymentState.DEPLOY_SUCCESS}
            status={
              hook.archived
                ? 'Inactive'
                : HookStatusDescriptions[hook.deploymentState as EDeploymentState]
            }
          />
        )
      })}
      active={allDataHooks?.result?.filter((hook) => hook.archived === false).length}
      inactive={allDataHooks?.result?.filter((hook) => hook.archived === true).length}
      selectedResourceId={selectedResource?.id}
      addItem={!SCHEMA_DEPLOYED_FROM_SDK && handleAdd}
      docsLink={'https://flatfile.com/versioned-docs/3.0/guides/data-hooks'}
    >
      <UpdatedEditDataHook
        key={selectedResource?.id}
        resource={selectedResource}
        refetchHooks={refetchHooks}
      />
    </UpdatedResourceBrowser>
  )
}
