import { useEffect } from 'react'

import { BatchTimelinePanel } from 'src/applications/Oversight/panels/Concierge/BatchTimelinePanel'
import { useModal } from 'src/resources/elements/Modal'
import { getConfigValue, setConfigValue, storedConfigValue } from 'src/utils/localStorageOverride'

const batchTimelineModalPinnedOpen = storedConfigValue('BATCH_TIMELINE_MODAL_PINNED_OPEN', 'off', {
  insignificant: true
})

export function useBatchTimelineModal({ batchId }: { batchId?: string }) {
  const modal = useModal({
    contents() {
      return <BatchTimelinePanel batchId={batchId} />
    },
    onClose() {
      setConfigValue(batchTimelineModalPinnedOpen, 'off')
      return true
    },
    width: '600px'
  })

  useEffect(() => {
    if (getConfigValue(batchTimelineModalPinnedOpen) === 'on' && !modal.isOpen && batchId) {
      modal.open()
    }
  }, [batchId])

  return {
    open: modal.open,
    render: modal.render
  }
}
