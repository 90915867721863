import { useEffect } from 'react'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { Header } from 'src/resources/elements/Header'
import RequestAccessImage from 'src/resources/images/404.svg'
import { Spacing } from 'src/resources/layout'
import { openFrontChat } from 'src/resources/sidebars/PlatformSidebar'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'
import styled from 'styled-components'
import { Toolbar } from 'src/applications/Oversight/controls/Toolbar'

const RequestAccessContainer = styled.div`
  text-align: -webkit-center;
  padding: ${Spacing.basePadding8x};

  small a {
    color: ${Colors.brandBlue};
    opacity: 1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  header {
    h2 {
      justify-content: center;
    }
  }

  p {
    margin-bottom: ${Spacing.basePadding3x};
  }

  img {
    margin-top: ${Spacing.basePadding6x};
  }
`

export const RequestAccessScene = () => {
  useEffect(() => {
    UserTransceiver.logout()

    if (window.location.pathname !== '/request-access') {
      window.location.pathname = '/request-access' // reload the page to unload JS memory
    }
  }, [])

  return (
    <>
      <Toolbar />
      <Card>
        <RequestAccessContainer>
          <Header>
            <h2>You don't have access to view this page</h2>
            <p>
              <small>
                If you believe this was an error,
                <br /> please <a onClick={openFrontChat}>let us know</a>
              </small>
            </p>

            <FlatButton renderAs='link' to='/login'>
              Log in
            </FlatButton>
          </Header>
          <img src={RequestAccessImage} alt='404 Page not found' width='200px' />
        </RequestAccessContainer>
      </Card>
    </>
  )
}
