import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const Wrap = styled.div`
  min-width: 50px;
  max-width: 100px;
  height: 28px;
  background: rgb(215, 222, 231);
  border-radius: 14px;
  padding: 0 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: rgb(29, 40, 48);
  font-size: ${fontSizes.type14};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 600;
`

export const TypeBadge = ({ type, ...props }: { type: string; onClick?(): void }) => {
  return (
    <Wrap data-testid='wrap' {...props}>
      {type}
    </Wrap>
  )
}
