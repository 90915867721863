import { useContext } from 'react'
import { useTabs } from 'src/applications/Oversight/hooks/useTabs'
import { EditWebhookPanel } from 'src/applications/Oversight/panels/EditWebhookPanel'
import { TeamCustomizationPanel } from 'src/applications/Oversight/panels/Team/TeamCustomizationPanel'
import { TeamMembersPanel } from 'src/applications/Oversight/panels/Team/TeamMembersPanel'
import { ETeamType, getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { getUserContext } from 'src/contexts/UserContext'
import { Card } from 'src/resources/elements/Card'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { PageHeaderContainer } from 'src/resources/elements/Header'

export const TeamScene = () => {
  const team = useContext(TeamContext)
  const userContext = getUserContext()
  const {
    error,
    data: { currentTeam }
  } = getBasicInfoContext().value
  const showCustomizationTab = team.featureFlags?.V3_CUSTOMIZATION

  const showWebhookTab =
    team.featureFlags?.FEATURE_TEAM_WEBHOOK && currentTeam?.type === ETeamType.full

  const teamTabs = useTabs({
    tabs: [
      {
        id: 'members',
        label: 'Members',
        showBetaBadge: false,
        contents: TeamMembersPanel
      }
    ]
      .concat(
        showCustomizationTab
          ? [
              {
                id: 'customization',
                label: 'Customization',
                showBetaBadge: false,
                contents: TeamCustomizationPanel
              }
            ]
          : []
      )
      .concat(
        showWebhookTab
          ? [
              {
                id: 'webhook',
                label: 'Webhook',
                showBetaBadge: true,
                contents: EditWebhookPanel
              }
            ]
          : []
      )
  })

  // logout on query error
  if (error) {
    userContext.setValue({ user: null })
  }

  return (
    <Card>
      <PageHeaderContainer hasMargin header='Team' />
      <ContentContainer>{teamTabs.render({})}</ContentContainer>
    </Card>
  )
}
