import Skeleton from 'react-loading-skeleton'
import { SuccessCheck } from 'src/resources/elements/Icons'
import styled, { useTheme } from 'styled-components'
import { isValidNumber } from 'src/utils/isValidNumber'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'

import { DataSourceField } from '../classes/DataSourceField'
import { DataPreviewContent, DataPreviewHeader, DataSourceStatus } from '../styles'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { useTranslation } from 'react-i18next'

const EmptyState = styled.div`
  width: 100%;
  border: 1px dashed ${themed('borderColor')};
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-radius: ${themed('borderRadius')};

  p {
    margin-top: ${Spacing.basePadding};
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.figmaGray80};
  }
`

const EmptyStateTitle = styled.h2`
  font-size: 18px;
  line-height: 1.5;
  color: ${Colors.figmaGray80};
  margin: 0;
  font-weight: 500;
  color: ${themed('textColor')};
`

const List = styled.ol`
  margin-top: 0;
  padding-left: ${Spacing.basePadding3x};
  list-style: none;

  li {
    padding: ${Spacing.baseAndHalfPadding} 0;
    color: ${themed('textColor')};
    font-size: ${themed('contentSmallFontSize')};
    font-weight: 400;
    border-top: 1px solid ${themed('borderColor')};

    span {
      opacity: 0.8;
      margin-right: ${Spacing.basePadding3x};
    }

    i {
      color: ${Colors.text80};
      font-weight: 400;
      font-style: normal;
    }
  }
`
const DataSourceLabel = styled.div`
  display: flex;
  flex-direction: column;
`

const DataRowCounts = styled.div`
  font-size: ${themed('contentSmallFontSize')};
  span {
    font-weight: 400;
  }
`

interface IDataPreview {
  field?: DataSourceField
}

export function DataPreview({ field }: IDataPreview) {
  const { t } = useTranslation()
  const theme: any = useTheme()
  return (
    <>
      {!!field ? (
        <DataPreviewContent>
          <DataPreviewHeader>
            {theme.showDataSourceStatus && (
              <DataSourceStatus active={field.isMatched}>
                {field.isMatched && <SuccessCheck circle={'transparent'} />}
              </DataSourceStatus>
            )}
            <DataSourceLabel>
              {!!theme.sage ? (
                field.label
              ) : (
                <>
                  {t('components.DataPreview.dataPreviewFor', { fieldLabel: field.label })}
                  {isValidNumber(field.totalRows) && isValidNumber(field.columnDensity) && (
                    <DataRowCounts>
                      {t('components.DataPreview.totalCount', {
                        count: field.totalRows
                      }) + t('components.DataPreview.valueCount', { count: field.columnDensity })}
                    </DataRowCounts>
                  )}
                </>
              )}
            </DataSourceLabel>
          </DataPreviewHeader>
          <List>
            {field.previewData.map((item, index) => (
              <li key={index}>
                <span>{index + 1}</span>
                {item || <i>{t('components.DataPreview.noData')}</i>}
              </li>
            ))}
          </List>
        </DataPreviewContent>
      ) : (
        <EmptyState>
          <EmptyStateTitle>{t('components.DataPreview.dataPreview')}</EmptyStateTitle>
          <p>
            {t('components.DataPreview.hoverLineBeginning')}
            <br />
            {t('components.DataPreview.hoverLineEnd')}
          </p>
        </EmptyState>
      )}
    </>
  )
}

export function DataPreviewSkeleton() {
  const { t } = useTranslation()
  const theme: any = useTheme()
  return (
    <DataPreviewContent>
      <DataPreviewHeader>
        {theme.showDataSourceStatus && <DataSourceStatus />}
        {t('components.DataPreview.dataPreview')}
      </DataPreviewHeader>
      <List>
        {Array.from(new Array(6)).map((_, i) => (
          <li key={i}>
            <Skeleton width={150} height={20} />
          </li>
        ))}
      </List>
    </DataPreviewContent>
  )
}
