import { RenderedCellProps, CellChangeDetail } from '@turntable/core'

import { Atom } from './Atom'

export const getBooleanValue = (value?: string | boolean | number) => {
  if (value === 'false' || value === false) return false
  if (value === 'true' || value === true) return true
  return value
}

export class BooleanAtom extends Atom<RenderedCellProps['value']> {
  /**
   * @returns a value prepared for the boolean renderer
   * @todo make the DAL server give us what we need here
   */
  public toCellValue() {
    return { label: this.value?.toString(), booleanValue: getBooleanValue(this.value) }
  }

  /**
   * Get the primitive value from from a changeset
   * @param change
   */
  public valueFromCellChangeDetail(change: CellChangeDetail<RenderedCellProps['value']>) {
    return change.value.booleanValue === undefined ? '' : change.value.booleanValue
  }
}
