import { gql } from '@apollo/client'

export const INACTIVATE_ACCESS_KEY = gql`
  mutation InactivateAccessKey($accessKeyId: String!) {
    inactivateAccessKey(accessKeyId: $accessKeyId) {
      accessKeyId
      inactivatedAt
      inactivatedByUser {
        id
        name
      }
    }
  }
`
