import { gql } from '@apollo/client'
import {
  SmartGetSheet,
  SmartGetSheetVariables,
  SmartGetSheet_getSheet
} from 'src/smart/queries/types/SmartGetSheet'
import { SmartQuery } from 'src/smart/SmartQuery'

type SchemaSheetSmartQueryType = SmartQuery<
  SmartGetSheetVariables,
  SmartGetSheet,
  SmartGetSheet_getSheet
>

export const SQ_SHEET: SchemaSheetSmartQueryType = {
  query: gql`
    query SmartGetSheet($sheetId: UUID!) {
      getSheet(sheetId: $sheetId) {
        id
        template {
          id
          name
          jsonSchema {
            schema
          }
        }
        schema {
          id
          name
          jsonSchema {
            schema
          }
        }
        schemaVariant {
          id
          name
          jsonSchema {
            schema
          }
        }
        percentComplete
      }
    }
  `,
  extract({ getSheet }) {
    return getSheet
  }
}
