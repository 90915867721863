import { ReactElement } from 'react'
import { Transitions } from 'src/resources/animations/transitions'

import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

import { DropdownMenu } from './Dropdown'
import { DropdownOptionStyles } from './DropdownChildNav'

export const DropdownMenuContainer = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: default;
  padding: ${Spacing.basePadding};
  transition: ${Transitions.baseEase};
  width: 210px;
`

export const FileDropdownOption = styled.span`
  ${DropdownOptionStyles}
`

export const FileDownloadDropdown = ({
  onDownloadFile,
  showOriginalFileOption,
  offset,
  children
}: {
  children: ReactElement
  onDownloadFile: (type: 'original' | 'processed', format?: 'csv' | 'xlsx') => void
  showOriginalFileOption?: boolean
  offset?: [number, number]
}) => {
  if (!showOriginalFileOption) {
    return (
      <DropdownMenu
        interactive={true}
        offset={offset}
        placement='bottom'
        content={
          <DropdownMenuContainer>
            <FileDropdownOption key='1' onClick={() => onDownloadFile('processed', 'csv')}>
              As CSV
            </FileDropdownOption>
            <FileDropdownOption key='2' onClick={() => onDownloadFile('processed', 'xlsx')}>
              As XLSX
            </FileDropdownOption>
          </DropdownMenuContainer>
        }
      >
        {children}
      </DropdownMenu>
    )
  }

  return (
    <DropdownMenu
      interactive={true}
      offset={offset}
      placement='bottom'
      content={
        <DropdownMenuContainer>
          <DropdownMenu
            interactive={true}
            offset={[18, 18]}
            placement='left'
            content={
              <DropdownMenuContainer>
                <FileDropdownOption key='1' onClick={() => onDownloadFile('processed', 'csv')}>
                  As CSV
                </FileDropdownOption>
                <FileDropdownOption key='2' onClick={() => onDownloadFile('processed', 'xlsx')}>
                  As XLSX
                </FileDropdownOption>
              </DropdownMenuContainer>
            }
          >
            <FileDropdownOption>Download final</FileDropdownOption>
          </DropdownMenu>
          <FileDropdownOption onClick={() => onDownloadFile('original')}>
            Download original
          </FileDropdownOption>
        </DropdownMenuContainer>
      }
    >
      {children}
    </DropdownMenu>
  )
}
