import { useEffect, useMemo, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { Dropzone } from 'src/applications/Oversight/components/Dropzone'
import { useUploadFile } from 'src/applications/Oversight/hooks/useUploadFile'
import { useUploads } from 'src/applications/Oversight/hooks/useUploads'
import { DataChecklistPanel } from 'src/applications/Oversight/panels/Concierge/DataChecklistPanel'
import { WorkspaceKnowledgeBasePanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceKnowledgeBasePanel'
import { WorkspaceSettingsPanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceSettingsPanel'
import { WorkspaceWelcomePanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceWelcomePanel'
import { FloatingContentPanel } from 'src/applications/Oversight/panels/FloatingContentPanel'
import { WorkspaceDebugScene } from 'src/applications/Oversight/scenes/Common/WorkspaceDebugScene'
import { WorkspaceDetailScene } from 'src/applications/Oversight/scenes/Common/WorkspaceDetailScene'
import { PreviewFileScene } from 'src/applications/Oversight/scenes/PreviewFileScene'
import { WorkbookViewSceneWrap } from 'src/applications/Oversight/scenes/WorkbookViewScene'
import { IFileUploadState } from 'src/contexts/FileUploadContext'
import { getUserContext } from 'src/contexts/UserContext'
import { useWorkspaceChatContext } from 'src/contexts/WorkspaceChatContext'
import { WorkspaceContext } from 'src/contexts/WorkspaceContext'
import { ProgressBar } from 'src/resources/elements/ProgressBar'
import { useParams } from 'src/resources/hooks/useParams'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import styled from 'styled-components'

const Content = styled.div`
  padding-right: 0;
  transition: 0.3s padding-right ease;
`

export const WorkspaceRoutes = ({
  match: { path: workspacePath }
}: RouteComponentProps<{ teamId: string; workspaceId: string }>) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const { uploads } = useUploads(workspaceId)
  const { onUpload } = useUploadFile(workspaceId)
  const [showUploads, setShowUploads] = useState(false)

  const getAvgUpload = (fileUploadState: { [key: string]: IFileUploadState }) => {
    const total = Object.values(fileUploadState).reduce((acc, curr) => acc + curr.percent, 0)
    return Math.round(total / Object.values(fileUploadState).length)
  }
  const progressMemoized = useMemo(() => {
    if (uploads) {
      return getAvgUpload(uploads)
    }
  }, [uploads])

  useEffect(() => {
    const newShowUploads = Object.keys(uploads).length > 0
    if (newShowUploads !== showUploads) {
      setShowUploads(newShowUploads)
    }
  }, [uploads])

  const {
    value: { user }
  } = getUserContext()

  const { result: workspace } = useSmartQuery(SQ_WORKSPACE, { variables: { workspaceId } })

  const { setValue: setWorkspaceChat } = useWorkspaceChatContext()

  useEffect(() => {
    setWorkspaceChat({
      user,
      workspace
    })
  }, [user, workspace])

  const workspaceContext = useMemo(
    () => ({
      workspace
    }),
    [workspace]
  )

  return (
    <WorkspaceContext value={workspaceContext}>
      <Content>
        <Dropzone
          workspaceStatus={!workspaceContext?.workspace?.status}
          handleDrop={async (files: File[]) => {
            /* react-dropzone has built in file validation so utilizing that here */
            const [response] = await Promise.all(files.map((file) => onUpload({ file })))
            if (response instanceof Error) {
              throw new Error(response.message)
            }
          }}
        >
          <Switch>
            <Route
              component={WorkbookViewSceneWrap}
              exact
              path={`${workspacePath}/workbooks/:workbookId/sheets/:sheetId/:validationState?`}
            />
            <Route component={PreviewFileScene} exact path={`${workspacePath}/preview/:batchId`} />
            <Route
              component={WorkspaceKnowledgeBasePanel}
              exact
              path={`${workspacePath}/knowledge-base`}
            />
            <Route component={DataChecklistPanel} exact path={`${workspacePath}/data-checklist`} />
            <Route component={WorkspaceWelcomePanel} exact path={`${workspacePath}/welcome`} />
            <Route component={WorkspaceSettingsPanel} exact path={`${workspacePath}/settings`} />
            <Route component={WorkspaceDebugScene} path={`${workspacePath}/debug`} />
            <Route component={WorkspaceDetailScene} path={workspacePath} />
          </Switch>
          {showUploads && (
            <FloatingContentPanel onClose={() => setShowUploads(false)}>
              <ProgressBar uploads={uploads} percentage={progressMemoized} />
            </FloatingContentPanel>
          )}
        </Dropzone>
      </Content>
    </WorkspaceContext>
  )
}
