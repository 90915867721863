import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import WorkspaceAvatar from 'src/applications/Oversight/components/WorkspaceAvatar'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_INVITATION } from 'src/queries/GET_INVITATION'
import { GetInvitation, GetInvitationVariables } from 'src/queries/types/GetInvitation'
import { Colors } from 'src/resources/colors'
import { Divider } from 'src/resources/elements/Divider'
import { FormButton } from 'src/resources/elements/form/Button'
import { FormCheckBox } from 'src/resources/elements/form/CheckBox'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { TableHeader } from 'src/resources/elements/Header'
import { Icon } from 'src/resources/elements/Icon'
import { useModal } from 'src/resources/elements/Modal'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { getStringColor } from 'src/resources/utils/getStringColor'
import styled from 'styled-components'

const AvatarWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  max-width: 75%;
`

const Content = styled.div`
  p {
    color: ${Colors.text};
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type14};
  }
`

const InviteSection = styled.div`
  padding: 1rem 0;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const useAcceptWorkspaceInvitationModal = (
  invitationToken: string,
  onInviteMember: () => void
) => {
  const invitationQuery = useQuery<GetInvitation, GetInvitationVariables>(GET_INVITATION, {
    variables: {
      token: invitationToken
    },
    skip: !invitationToken?.length
  })

  const adminTeam = invitationQuery.data?.getInvitation?.team

  const { addToast } = useToasts()
  const GetStartedForm: TForm<{
    fullName: string
    email: string
    password: string
    accept: boolean
  }> = Form

  const team = useContext(TeamContext)

  const acceptWorkspaceInvitationModal = useModal({
    contents:
      invitationQuery.loading || invitationQuery.error
        ? () => QueryAlert(invitationQuery)
        : () => (
            <GetStartedForm
              onSubmit={async (event) => {
                const { accept } = event.data

                if (!accept) {
                  addToast('Please accept the terms of service', {
                    appearance: 'error',
                    autoDismiss: true
                  })
                  return
                }
                acceptWorkspaceInvitationModal.close()
              }}
            >
              <TableHeader>
                <h3>
                  {adminTeam.name} is inviting you to securely onboard your data using Flatfile
                </h3>
              </TableHeader>
              <Content>
                <AvatarWrap>
                  <WorkspaceAvatar
                    bgColor={getStringColor(adminTeam.id)}
                    workspaceName={adminTeam.name}
                    showFullName
                  />
                  <Icon name='arrow-right' />
                  <WorkspaceAvatar
                    bgColor={getStringColor(team.id)}
                    workspaceName={team.name}
                    showFullName
                  />
                </AvatarWrap>
                <Divider />
                {/*<p>Data {adminTeam.name} needs from you:</p>*/}
                {/*<PillContainer pills={pills} /> @todo resurrection pls kthx */}
                <InviteSection>
                  Don’t have access to the data?{' '}
                  <a
                    href='#'
                    onClick={() => {
                      acceptWorkspaceInvitationModal.close()
                      onInviteMember()
                    }}
                  >
                    Invite a team member!
                  </a>
                </InviteSection>
                <FormCheckBox type='primary' name='accept'>
                  Accept the platform{' '}
                  <a target='_blank' href='/terms'>
                    terms of service
                  </a>
                </FormCheckBox>
                <Footer>
                  <div>
                    <FormButton primary submit>
                      Get Started
                    </FormButton>
                  </div>
                </Footer>
              </Content>
            </GetStartedForm>
          )
  })

  return {
    open: acceptWorkspaceInvitationModal.open,
    render: acceptWorkspaceInvitationModal.render
  }
}
