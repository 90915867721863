import { useCallback, useState } from 'react'
import { useBatchTimelineModal } from 'src/applications/Oversight/hooks/useBatchTimelineModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_WORKBOOK_SHEETS } from 'src/smart/queries/SQ_GET_WORKBOOK_SHEETS'
import { IBatchData } from 'src/types/interfaces/IBatch'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import { BatchTableRow } from './BatchTableRow'

export const BatchTable = ({
  batches,
  workspace
}: {
  batches: IBatchData[]
  workspace: IWorkspace
}) => {
  const [viewHistoryBatchId, setViewHistoryBatchId] = useState<string | undefined>()

  const batchTimelineModal = useBatchTimelineModal({ batchId: viewHistoryBatchId })
  const workspaceSheets = useSmartQuery(SQ_GET_WORKBOOK_SHEETS, {
    variables: { workspaceId: workspace.id }
  })
  const sheets =
    workspaceSheets.result?.reduce((acc, sheet) => {
      acc[sheet.schemaId] = sheet.id
      return acc
    }, {} as { [schemaId: string]: string }) ?? {}

  const viewBatchHistory = useCallback(
    (batchId: string) => {
      setViewHistoryBatchId(batchId)
      batchTimelineModal.open()
    },
    [batches]
  )

  const teamRoot = useTeamRootUrl()
  const workspaceRootUrl = `${teamRoot}/workspaces/${workspace.id}`
  return (
    <>
      {batchTimelineModal.render()}
      {batches.map((batch: IBatchData, index: number) => {
        const sheetLink =
          batch?.template?.id && sheets[batch?.template.id]
            ? `${workspaceRootUrl}/workbooks/${workspace.primaryWorkbookId}/sheets/${
                sheets[batch?.template.id]
              }`
            : undefined

        return (
          <BatchTableRow
            batch={batch}
            key={index}
            onViewHistory={viewBatchHistory}
            schemaLabel={batch?.template?.name}
            sheetLink={sheetLink}
            workspaceRootUrl={workspaceRootUrl}
          />
        )
      })}
    </>
  )
}
