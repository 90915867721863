import { LowLayer3 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import styled, { css } from 'styled-components'

export const FullScreen = styled.div<{ opaque?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  ${({ opaque }) =>
    opaque
      ? css`
          background: ${Colors.white};
          z-index: ${LowLayer3};
        `
      : null}
`
