import TurnTable, {
  OnCellsChange,
  RowData,
  DataChangeEvent,
  ColumnConfigProps,
  TableScrollerProvider
} from '@turntable/core'
import { useTheme } from 'styled-components'

interface TableProps {
  count: number
  columnConfig?: ColumnConfigProps[]
  onCellClick?: (row: RowData) => void
  onCellsChange?: OnCellsChange
  initData?: RowData[]
  readOnly?: boolean
  isLoading?: boolean
  tableId?: string
  emptyState?: JSX.Element
}

const defaultOnCellsChange = (): Promise<DataChangeEvent> => null

export const Table = ({
  count,
  initData,
  columnConfig,
  readOnly,
  tableId,
  isLoading,
  emptyState,
  onCellClick,
  onCellsChange = defaultOnCellsChange
}: TableProps) => {
  const theme: any = useTheme()
  return (
    <TableScrollerProvider>
      <TurnTable
        readOnly={readOnly}
        theme={theme?.dataTable}
        tableId={tableId}
        initData={initData}
        totalRowCount={count}
        columnConfig={columnConfig}
        isLoading={isLoading}
        emptyState={emptyState}
        onCellClick={onCellClick}
        onCellsChange={onCellsChange}
      />
    </TableScrollerProvider>
  )
}
