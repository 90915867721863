import { gql } from '@apollo/client'
import {
  SmartGetFileAccessToken,
  SmartGetFileAccessTokenVariables,
  SmartGetFileAccessToken_getFileAccessToken
} from 'src/smart/queries/types/SmartGetFileAccessToken'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_FILE_ACCESS_TOKEN: SmartQuery<
  SmartGetFileAccessTokenVariables,
  SmartGetFileAccessToken,
  SmartGetFileAccessToken_getFileAccessToken
> = {
  query: gql`
    query SmartGetFileAccessToken($batchId: UUID!, $type: String!) {
      getFileAccessToken(batchId: $batchId, type: $type) {
        token
        active
        type
      }
    }
  `,
  extract({ getFileAccessToken }) {
    return getFileAccessToken
  }
}
