import { TeamBillingPanel } from 'src/applications/Oversight/panels/TeamBillingPanel'
import { FLATFILE_IS_CLOUD_ENV } from 'src/config'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { getUserContext } from 'src/contexts/UserContext'
import { Card } from 'src/resources/elements/Card'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { VendorChargebeeContext, VendorChargebeeInit } from 'src/vendor/VendorChargebeeContext'

export const BillingScene = () => {
  const userContext = getUserContext()
  const { error } = getBasicInfoContext().value

  // logout on query error
  if (error) {
    userContext.setValue({ user: null })
  }

  return (
    <Card>
      <PageHeaderContainer hasMargin header='Team' />
      <ContentContainer>
        <VendorChargebeeContext>
          <VendorChargebeeInit />
          {FLATFILE_IS_CLOUD_ENV ? <TeamBillingPanel /> : null}
        </VendorChargebeeContext>
      </ContentContainer>
    </Card>
  )
}
