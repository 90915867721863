import { StateColors } from 'src/resources/colors'
import { Fade } from 'src/resources/effects/Fade'
import { getFormContext, IFormContextValue } from 'src/resources/elements/form/Form'
import { Spacing } from 'src/resources/layout'
import { opacity } from 'src/resources/opacities'
import styled from 'styled-components'
import { Icon } from '../Icon'

interface IErrorMessageProps {
  name: string
}

export const ErrorContainer = styled.div`
  background-color: ${opacity(StateColors.error, 0.1)};
  display: inline-block;
  padding: 8px 12px;
  border-radius: 3px;
  border: 1px solid ${StateColors.error};
  color: ${StateColors.error};
  font-size: 0.875rem;
  margin: ${Spacing.basePadding2x} 0;

  p {
    margin: 0;
  }
`

export const FlexError = styled(ErrorContainer)`
  display: flex;
  flex-direction: row;
`

const ErrorIconContainer = styled.div`
  padding: 0 ${Spacing.basePadding} 0 0;
`

export const ErrorInfoIcon = () => (
  <ErrorIconContainer>
    <Icon name='info-circle' />
  </ErrorIconContainer>
)

export const ErrorMessage = ({ name }: IErrorMessageProps) => {
  const { errors } = getFormContext().value as IFormContextValue<{
    [key: string]: string
  }>

  if (name in errors) {
    return (
      <Fade entering>
        <ErrorContainer>{errors[name]}</ErrorContainer>
      </Fade>
    )
  }

  return null
}
