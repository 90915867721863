import { gql } from '@apollo/client'
import {
  UpdateTeamTheme,
  UpdateTeamThemeVariables,
  UpdateTeamTheme_updateTeamTheme
} from 'src/smart/mutations/types/UpdateTeamTheme'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_TEAM_THEME: SmartMutation<
  UpdateTeamThemeVariables,
  UpdateTeamTheme,
  UpdateTeamTheme_updateTeamTheme
> = {
  mutation: gql`
    mutation UpdateTeamTheme($teamId: ID!, $theme: JSON!) {
      updateTeamTheme(teamId: $teamId, theme: $theme) {
        name
        attributes {
          type
          name
          hex
        }
      }
    }
  `,
  extract: (response) => response.updateTeamTheme
}
