import { gql, SubscriptionHookOptions } from '@apollo/client'
import { useSubscription } from '@apollo/client'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { useSubscriptionWithPollFallback } from 'src/smart/hooks/useSubscriptionWithPollFallback'
import { SQ_WORKSPACE } from './SQ_WORKSPACE'
import { SmartWorkspace_getWorkspace } from './types/SmartWorkspace'

import {
  WorkbookUpdated,
  WorkbookUpdatedVariables,
  WorkbookUpdated_workbookUpdated
} from './types/WorkbookUpdated'

export const WORKBOOK_UPDATED_SUBSCRIPTION = gql`
  subscription WorkbookUpdated($workbookId: UUID!) {
    workbookUpdated(workbookId: $workbookId) {
      id
      workspaceId
      status
    }
  }
`

export const useWorkbookUpdatedSubscription = (
  workbookId?: string,
  options: SubscriptionHookOptions<WorkbookUpdated, WorkbookUpdatedVariables> = {}
) => {
  return useSubscription<WorkbookUpdated, WorkbookUpdatedVariables>(
    WORKBOOK_UPDATED_SUBSCRIPTION,
    {
      ...options,
      skip: !workbookId,
      variables: {
        workbookId
      }
    }
  )
}

export const useWorkbookUpdatedSubscriptionWithPollFallback = (
  workbookId: string,
  workspaceId: string
) => {
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    },
    skip: true
  })

  const subscriptionFallback = async () => {
    const { data } = await workspaceQuery.state.refetch({ workspaceId })

    return data.getWorkspace.primaryWorkbook.status === 'succeeded' ? data.getWorkspace : null
  }

  const { result, loading, fallbackResult } = useSubscriptionWithPollFallback<
    WorkbookUpdatedVariables,
    WorkbookUpdated,
    WorkbookUpdated_workbookUpdated,
    SmartWorkspace_getWorkspace
  >({
    subscription: WORKBOOK_UPDATED_SUBSCRIPTION,
    extract: ({ workbookUpdated }) => workbookUpdated,
    options: {
      skip: !workbookId,
      variables: {
        workbookId
      }
    },
    pollFallback: {
      fallback: subscriptionFallback
    }
  })

  return { result, loading, fallbackResult }
}
