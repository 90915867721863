import { DataChangeEvent } from '@turntable/core'

import { IMessageLevel } from '../atoms/Atom'
import { Schema } from '../Schema'
import { PrimitiveRecord, VirtualRecord } from '../VirtualRecord'
import { VirtualRecordFragment } from '../VirtualRecordFragment'

export class Transport {
  // @ts-ignore the unused record will be extended
  constructor(private record: VirtualRecord) {}

  /**
   * Take a change event and return the parameters needed for a DAL Patch event
   * @param changes
   * @param schema
   * @returns
   */
  public static toPatch(
    changes: DataChangeEvent['changes'],
    schema: Schema
  ): {
    _id: string
    data: PrimitiveRecord
  }[] {
    return changes.map((change) => {
      const data = new VirtualRecordFragment(schema).patch(change).state
      return {
        _id: change.id,
        data
      }
    })
  }

  /**
   * Convert the validation type from the server to something we can work with
   *
   * Note: Previously, validation.error was assigned the same value as validation.message e.g.
   * validation.error could be 'required', 'must be unique', etc. In the case where
   * validation.error is not one of 'info' or 'warn' assume it is an error
   *
   * @param errorType
   * @returns
   */
  protected static normalizeMessageType(errorType: string | null): IMessageLevel {
    switch (errorType) {
      case 'warn':
      case 'warning':
        return IMessageLevel.WARN
      case 'info':
        return IMessageLevel.INFO
      default:
        return IMessageLevel.ERROR
    }
  }
}
