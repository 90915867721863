import ReactTooltip, { TooltipProps } from 'react-tooltip'
import { Colors } from 'src/resources/colors'
import { fontPrimary } from 'src/resources/typography'
import styled from 'styled-components'

const StyledTooltip = styled(ReactTooltip)`
  font-family: ${fontPrimary};
`

interface ITooltip extends TooltipProps {
  id: string
  content?: string | JSX.Element
  offset?: { [key: string]: number }
  place?: 'top' | 'bottom' | 'left' | 'right'
}

export const Tooltip = ({
  id,
  content,
  offset = { top: 10, left: 10 },
  place = 'top',
  ...props
}: ITooltip) => {
  return (
    <StyledTooltip
      backgroundColor={Colors.text}
      id={id}
      place={place}
      type='dark'
      effect='solid'
      offset={{ top: offset.top, left: offset.left }}
      textColor={Colors.gray40}
      {...props}
    >
      {content ?? id}
    </StyledTooltip>
  )
}
