import { Colors, ColorUtils } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'

export const WhiteFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return css`
          background-color: transparent !important;
          border: 1px solid ${Colors.white} !important;
          color: ${Colors.white} !important;
          &:hover {
            border-color: ${Colors.white} !important;
          }
        `
      case 'primary-text':
        return css`
          background-color: transparent !important;
          border: 1px solid transparent !important;
          color: ${Colors.brandPrimary} !important;
          &:hover {
            border-color: transparent !important;
          }
          padding: ${Spacing.basePadding} 0 !important;
        `
      default:
        return css`
          background-color: ${Colors.white} !important;
          color: ${Colors.text} !important;

          &:hover {
            background-color: ${ColorUtils.hoverColor(Colors.white)} !important;
          }
        `
    }
  }}
`
