import { EmbedListPanel } from 'src/applications/Oversight/panels/EmbedListPanel'
import styled from 'styled-components'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { KeyIcon } from 'src/resources/elements/Icons'

export const PortalV3Panel = ({ toggleView }: { toggleView?: () => void }) => {
  return (
    <>
      <EmbedListPanel />
      {toggleView && <PortalV2Navigation toggleView={toggleView} />}
    </>
  )
}

const PortalV2NavigationContent = styled.div`
  border: 1px solid ${Colors.electric050};
  border-radius: ${Spacing.borderRadius};
  margin-top: ${Spacing.basePadding2x};
  display: flex;
  gap: ${Spacing.baseAndHalfPadding};
  padding: ${Spacing.baseAndHalfPadding} ${Spacing.basePadding2x};

  p {
    font-size: ${fontSizes.type14};
    color: ${Colors.pigeon700};
  }

  a {
    cursor: pointer;
    color: ${Colors.sky600};
    :hover {
      color: ${Colors.purpleHover};
    }
  }
`

const PortalV2Navigation = ({ toggleView }: { toggleView: () => void }) => {
  return (
    <PortalV2NavigationContent>
      <KeyIcon />
      <p>
        Looking for your Portal 2.0 license key? <a onClick={toggleView}>Go here →</a>
      </p>
    </PortalV2NavigationContent>
  )
}
