import { ApolloError } from '@apollo/client'
import { MutationResult, QueryResult } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { SmartResult } from 'src/smart/SmartQuery'

export const mergedQueryResult = (
  ...results: (MutationResult | QueryResult | SmartResult<any, any, any>)[]
) => {
  const loading = results.some((result) => ('alert' in result ? result.alert : result.loading))
  const errors: ApolloError[] = results
    .map((result) => ('alert' in result ? result.state.error : result.error))
    .filter((err) => err) as ApolloError[]

  const error =
    errors.length === 0
      ? undefined
      : errors.length === 1
      ? errors[0]
      : new ApolloError({
          graphQLErrors: errors.reduce(
            (acc, e) => acc.concat(e.graphQLErrors),
            [] as GraphQLError[]
          ),
          errorMessage: errors.map((e) => e.message).join('; '),
          extraInfo: errors
            .filter((e) => e.extraInfo)
            .map((e) => e.extraInfo)
            .pop(),
          networkError: errors
            .filter((e) => e.networkError)
            .map((e) => e.extraInfo)
            .pop()
        })

  return { alert: !!error || loading, error, errors, loading }
}
