import * as Sentry from '@sentry/react'
import { ITeamWithCapabilities, TeamContext } from 'src/contexts/TeamContext'
import { CurrentUser_myCapabilities } from 'src/queries/types/CurrentUser'
import { createContextState } from 'src/resources/hooks/createContextState'
import { named } from 'src/resources/utils/named'
import { FeatureFlags } from 'src/types/interfaces/FeatureFlags'
import { ITeam } from 'src/types/interfaces/ITeam'

export interface ITeamContext {
  team: ITeam
  capabilities: CurrentUser_myCapabilities
  featureFlags: Partial<FeatureFlags>
}

export const [ActiveTeamContext, getActiveTeamContext] = createContextState<ITeamContext>(
  'ActiveTeam',
  {
    team: null,
    capabilities: null,
    featureFlags: null
  }
)

export const ActiveTeamRequired = named(
  'ActiveTeamRequired',
  ({ children }: { children(activeTeam: ITeamWithCapabilities): JSX.Element }) => {
    const { team, capabilities, featureFlags } = getActiveTeamContext().value

    if (team && capabilities && featureFlags) {
      Sentry.setTags({ TeamName: team.name, TeamID: team.id })
      return (
        <TeamContext.Provider value={{ ...team, capabilities, featureFlags }}>
          {children({ ...team, capabilities, featureFlags })}
        </TeamContext.Provider>
      )
    }

    return null
  }
)
