import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ButtonBase } from 'src/resources/elements/buttons/FlatButton'
import Notification from 'src/resources/elements/Notification'
import { useInterval } from 'src/applications/Oversight/hooks/useInterval'

export const AppVersionUpdatedNotification = () => {
  const interval: number = 60 * 1000 * 10
  const hashedFilesToCheck = ['oversight', 'vendors', 'runtime']
  const [newestVersionArray, setNewestVersionArray] = useState([])
  const [currentVersionArray, setCurrentVersionArray] = useState([])

  const getFileNames = (htmlFile: string, fileNameArray: string[]) => {
    return fileNameArray.map((fileName) => {
      const re = `${fileName}.[a-z0-9]+.js`
      return htmlFile.match(re)[0]
    })
  }

  const getCurrentVersionArray = () => {
    const currentHtml = document.documentElement.outerHTML
    setCurrentVersionArray(getFileNames(currentHtml, hashedFilesToCheck))
  }

  const getNewestVersionArray = async () => {
    try {
      const response = await fetch('/index.html')
      const newestHtml = await response.text()
      setNewestVersionArray(getFileNames(newestHtml, hashedFilesToCheck))
    } catch (err) {
      return
    }
  }

  useEffect(() => {
    getCurrentVersionArray()
  }, [newestVersionArray])

  useInterval(() => {
    getNewestVersionArray()
  }, interval)

  const refresh = useCallback(() => window.location.reload(), [])

  const RefreshButton = (
    <ButtonBase onClick={refresh} size='md'>
      {' '}
      Refresh
    </ButtonBase>
  )

  const versionUpToDate =
    newestVersionArray.length > 0 && currentVersionArray.length > 0
      ? isEqual(currentVersionArray, newestVersionArray)
      : true

  return versionUpToDate ? null : (
    <Notification
      fullWidth
      type='info'
      title='A new version of Flatfile is available'
      button={RefreshButton}
    >
      Refresh your browser to use the latest version. Save any unsaved changes before refreshing.
    </Notification>
  )
}
