export const hasOverflow = (el: HTMLElement): boolean => {
  const scrollHeight = Math.max(
    el.scrollHeight,
    el.childElementCount > 0 ? el.children.item(0).scrollHeight : 0
  )
  const scrollWidth = Math.max(
    el.scrollWidth,
    el.childElementCount > 0 ? el.children.item(0).scrollWidth : 0
  )
  return el && (scrollWidth > el.offsetWidth || scrollHeight - el.offsetHeight > 2)
}
