import { createContextState } from 'src/resources/hooks/createContextState'
import { IUser } from 'src/types/interfaces/IUser'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import { storage } from 'src/resources/utils/storage'

export interface WorkspaceChat {
  chatOpen?: boolean
  online?: boolean
  user?: IUser
  workspace?: IWorkspace
}

export const [WorkspaceChatContext, useWorkspaceChatContext] = createContextState<WorkspaceChat>(
  'WorkspaceChat',
  { chatOpen: storage('chatOpen').getRaw() === '1' },
  (newChat) => storage('chatOpen').setRaw(newChat.chatOpen ? '1' : '0')
)
