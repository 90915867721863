import { CellChangeDetail, LookupOption } from '@turntable/core'

import { EnumSchemaProperty } from '../properties/EnumSchemaProperty'
import { Atom, IMessageLevel } from './Atom'

export class EnumAtom extends Atom<LookupOption[]> {
  /**
   * Property definition is different for an Enum atom
   */
  protected property: EnumSchemaProperty

  /**
   * @returns the current selected values as Turntable ready options
   * @todo support multiple options
   */
  public toCellValue() {
    if (typeof this.value === 'undefined') {
      return []
    }
    const error = this.meta.messages.some((message) => message.level === IMessageLevel.ERROR)
    const warning = this.meta.messages.some((message) => message.level === IMessageLevel.WARN)
    const found = this.property.options.filter((opt) => opt.value === this.value)
    const enumIdx = this.property.definition.enum.findIndex((opt) => opt === this.value)
    const enumLabel = this.property.definition.enumLabel[enumIdx]

    if (this.value && (!found.length || error)) {
      return [{ label: enumLabel, value: this.value, error: true }]
    }
    if (this.value && warning) {
      return [{ label: enumLabel, value: this.value, warning: true }]
    }
    return found
  }

  /**
   * Get the primitive value from from a changeset
   *
   * @todo support multiple options
   * @param change
   */
  public valueFromCellChangeDetail(change: CellChangeDetail<LookupOption[]>) {
    const options = change.value
    if (!options.length) {
      return null
    }
    return options[0].value
  }
}
