import { useMutation } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import { getActiveTeamContext } from 'src/contexts/ActiveTeamContext'
import { EDIT_USER } from 'src/queries/EDIT_USER'
import { EditUser, EditUserVariables } from 'src/queries/types/EditUser'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup, ButtonWrap } from 'src/resources/elements/form/ButtonGroup'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Select } from 'src/resources/elements/form/Select'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_EDIT_TEAM } from 'src/smart/mutations/SM_EDIT_TEAM'
import { IUser } from 'src/types/interfaces/IUser'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { useHistory } from 'react-router-dom'

interface IEnrichUserDetails {
  company?: string
  company_domain?: string
  license_needed?: 'unknown' | 'immediately' | 'within a month' | 'within 3 months'
}

const computeSignupFormDataErrors = (formData: IEnrichUserDetails) => {
  let errors = {}
  if (!formData.company || formData.company.trim().length === 0) {
    errors = { ...errors, company: 'Please input your company name.' }
  }
  if (!formData.company_domain || formData.company_domain.trim().length === 0) {
    errors = { ...errors, company_domain: 'Please input your company domain.' }
  }
  if (!formData.license_needed || formData.license_needed.length === 0) {
    errors = { ...errors, license_needed: 'Please select your implementation timeline.' }
  }
  if (Object.keys(errors).length > 0) {
    return errors
  }
  return null
}

export const EnrichUserDetailsForm = ({ user, teamId }: { user: IUser; teamId: string }) => {
  const [editUser] = useMutation<EditUser, EditUserVariables>(EDIT_USER)
  const editTeam = useSmartMutation(SM_EDIT_TEAM)
  const history = useHistory()
  const activeTeamContext = getActiveTeamContext()
  const {
    value: { team }
  } = activeTeamContext

  const { addToast } = useToasts()
  const onSubmit = async (event: IFormSubmitEvent<IEnrichUserDetails>) => {
    const { company, company_domain, license_needed } = event.data
    try {
      const formErrors = computeSignupFormDataErrors(event.data)
      if (formErrors) {
        event.formContext.setValue({
          ...event.formContext.value,
          errors: formErrors
        })
        return
      }
      const response = await editUser({
        variables: {
          id: user.id,
          company,
          company_domain,
          license_needed
        }
      })

      if (company) {
        await editTeam.run({
          input: { id: teamId, name: company }
        })
        activeTeamContext.setValue({
          ...activeTeamContext.value,
          team: { ...team, name: company }
        })
      }

      if (response) {
        window.sessionStorage.removeItem('dev')
        addToast('Successfully activated developer license', {
          appearance: 'success',
          autoDismiss: true
        })
        history.replace(`/a/${teamId}/env/test/templates?welcome=true`)
      }
    } catch (error) {
      throw error
    }
  }

  const licenseNeededOptions: { [key: string]: { [key: string]: string } } = {
    unknown: { id: 'unknown', label: 'Unknown' },
    immediately: { id: 'immediately', label: 'Immediately' },
    month: { id: 'month', label: 'Within a month' },
    threeMonth: { id: 'threeMonth', label: 'Within 3 months' }
  }

  const UserDetailsForm: TForm<IEnrichUserDetails> = Form
  return (
    <UserDetailsForm onSubmit={onSubmit}>
      <InputGroup>
        <Input placeholder='Acme Co.' label='Company name' name='company' />
        <ErrorMessage name='company' />
      </InputGroup>

      <InputGroup>
        <Input placeholder='acme.com' label='Company domain' name='company_domain' />
        <ErrorMessage name='company_domain' />
      </InputGroup>

      <InputGroup>
        <Select
          label='Activation timeline'
          name='license_needed'
          creatable
          options={Object.keys(licenseNeededOptions).map((option) => ({
            label: licenseNeededOptions[option].label,
            value: licenseNeededOptions[option].id
          }))}
        />
        <ErrorMessage name='license_needed' />
      </InputGroup>
      <ButtonWrap>
        <ButtonGroup stretch>
          <FormButton primary submit>
            Get started
          </FormButton>
        </ButtonGroup>
      </ButtonWrap>
    </UserDetailsForm>
  )
}
