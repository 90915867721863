import { FC } from 'react'

import ReactTooltip from 'react-tooltip'
import { LowLayer1 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import { fontFamily, fontSecondary, fontSizes } from 'src/resources/typography'
import { getInitials } from 'src/resources/utils/getInitials'
import styled, { css } from 'styled-components'

import { Spacing } from '../layout'

export const AvatarSquareInitials = styled.label`
  align-items: center;
  background: ${Colors.brandPrimary};
  border-radius: 4px;
  color: ${Colors.white};
  display: flex;
  height: 26px;
  font-size: ${fontSizes.type10};
  font-weight: 700;
  justify-content: center;
  pointer-events: none;
  transform: translateX(32px);
  width: 26px;
  z-index: ${LowLayer1};
`

export const AvatarCircle = styled.div<{
  small?: boolean
  color?: string
  borderColor?: string
  onClick?(): void
}>`
  align-items: center;
  background-color: ${({ color }) => (color ? color : Colors.brandPrimary)};
  border-radius: 50%;
  border: 2px solid ${({ borderColor }) => (borderColor ? borderColor : Colors.transparent)};
  box-sizing: content-box;
  color: ${Colors.white};
  cursor: default;
  display: flex;
  flex-shrink: 0;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type12};
  font-weight: 600;
  height: ${({ small }) => (small ? Spacing.basePadding3x : Spacing.basePadding5x)};
  width: ${({ small }) => (small ? Spacing.basePadding3x : Spacing.basePadding5x)};
  justify-content: center;
  line-height: normal;
  position: relative;
  user-select: none;

  ${({ onClick }) =>
    onClick
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : null}
`
const StyledTooltip = styled(ReactTooltip)`
  font-family: ${fontSecondary};
`

const AvatarGroup = styled.div`
  display: flex;
  & > div + div {
    margin-left: -8px;
  }
`

const StatusDot = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? Colors.green : Colors.textAccent)};
  border-radius: 10px;
  height: 12px;
  width: 12px;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border: 2px solid white;
`

export const AvatarCircleInitials = ({
  borderColor,
  color,
  name,
  onlineStatus,
  showTooltip,
  small
}: {
  borderColor?: string
  color?: string
  name: string
  onlineStatus?: boolean
  showTooltip?: boolean
  small?: boolean
}) => {
  return (
    <AvatarCircle
      small={small}
      color={color}
      data-testid='avatar-circle'
      data-for={name}
      data-tip={
        typeof onlineStatus === 'boolean'
          ? `${name} is ${onlineStatus ? 'online' : 'offline'}`
          : name
      }
      borderColor={borderColor}
    >
      {getInitials(name)}
      {showTooltip && <StyledTooltip id={name} place='top' type='dark' effect='float' />}
      {typeof onlineStatus === 'boolean' ? <StatusDot active={onlineStatus} /> : null}
    </AvatarCircle>
  )
}

export const GroupAvatar: FC<{
  names: string[]
  showTooltips?: boolean
  borderColor?: string
}> = ({ names, showTooltips, borderColor }) => {
  const hasRemainingLength = names?.length >= 4
  const remainingLength = names?.length - 3
  return (
    <AvatarGroup data-testid='avatar-wrap'>
      {hasRemainingLength
        ? names
            .slice(0, 3)
            .map((name, i) => (
              <AvatarCircleInitials
                key={i}
                name={name}
                showTooltip={showTooltips}
                borderColor={borderColor}
              />
            ))
        : names.map((name, i) => (
            <AvatarCircleInitials
              key={i}
              name={name}
              showTooltip={showTooltips}
              borderColor={borderColor}
            />
          ))}
      {hasRemainingLength && (
        <AvatarCircle borderColor={borderColor}>{`+${remainingLength}`}</AvatarCircle>
      )}
    </AvatarGroup>
  )
}
