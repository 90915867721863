import { gql } from '@apollo/client'

export const GET_ACTIVITIES = gql`
  query GetActivities($take: Int!, $skip: Int!, $workspaceId: UUID) {
    getActivities(take: $take, skip: $skip, workspaceId: $workspaceId) {
      pagination {
        currentPage
        limit
        nextOffset
        offset
        onPage
        pageCount
        previousOffset
        totalCount
      }
      data {
        id
        resource
        action
        message
        activityInfo
        createdAt
        user {
          name
        }
      }
    }
  }
`
