import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

export const SearchInput = styled.div<{ secondary?: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: ${({ secondary }) => (secondary ? '286px' : '100%')};
  margin: 0 ${Spacing.contentPadding};
  ${({ secondary }) =>
    secondary
      ? css`
          margin-top: ${Spacing.basePadding2x};
        `
      : null};
  z-index: 1;

  input {
    -webkit-appearance: none;
    align-items: center;
    background-color: ${({ secondary }) =>
      secondary ? `${Colors.brandPrimary}` : `${Colors.white}`};
    border-radius: 5px;
    border: 1px solid ${Colors.pigeon400};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: ${({ secondary }) => (secondary ? `${Colors.white}` : `${Colors.text}`)};
    display: inline-block;
    font-size: ${fontSizes.type15};
    font-weight: 400;
    height: ${({ secondary }) => (secondary ? '26px' : '38px')};
    min-width: 440px;
    overflow: hidden;
    padding: ${Spacing.basePadding};
    text-indent: 24px;
    transition: ${Transitions.baseEase};

    ::placeholder {
      color: ${({ secondary }) => (secondary ? `${Colors.white}` : `${Colors.pigeon600}`)};
      opacity: ${({ secondary }) => (secondary ? `1` : `1`)};
    }

    &:hover,
    &:focus {
      border: 1px solid ${Colors.pigeon600};
    }
  }
`

export const SearchIcon = styled.img`
  bottom: 1px;
  height: 14px;
  left: 12px;
  margin: auto;
  position: absolute;
  pointer-events: none;
  top: 0;
  vertical-align: middle;
  width: 14px;
`
