import { Colors, ColorUtils } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

export const PrimaryFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant, theme }) => {
    const primaryColor = themed('primary')({ theme })

    switch (variant) {
      case 'outlined':
        return theme.flexport
          ? css`
              background-color: transparent !important;
              border: ${themed('borderWidth')} solid #c5d2e7 !important;
              color: ${primaryColor} !important;
              &:hover {
                border-color: ${primaryColor} !important;
              }
            `
          : css`
              background-color: transparent !important;
              border: ${themed('borderWidth')} solid ${primaryColor} !important;
              color: ${primaryColor} !important;
              &:hover {
                border-color: ${primaryColor} !important;
              }
            `
      case 'hover-outlined':
        return css`
          background-color: transparent !important;
          border: ${themed('borderWidth')} solid ${primaryColor} !important;
          color: ${primaryColor} !important;
          &:hover {
            border-color: ${primaryColor} !important;
            background-color: ${primaryColor} !important;
            color: ${Colors.white} !important;
          }
        `
      default:
        return css`
          background-color: ${primaryColor} !important;
          color: ${Colors.white} !important;
          box-shadow: 0 1px 8px -2px ${ColorUtils.hexToRgba(primaryColor, 0.3)},
            0 2px 12px -3px ${ColorUtils.hexToRgba(primaryColor, 0.2)};

          &:hover {
            background-color: ${ColorUtils.hoverColor(primaryColor)} !important;
          }
        `
    }
  }}
`
