import {
  EditSchemaPropertyForm,
  LinkedSchema
} from 'src/applications/Oversight/forms/EditSchemaPropertyForm'
import { useModal } from 'src/resources/elements/Modal'
import { SmartGetSchemas_getSchemas_data } from 'src/smart/queries/types/SmartGetSchemas'
import { ISchemaProperty } from 'src/types/interfaces/ISchema'

export const useEditSchemaPropertyModal = ({
  isNew,
  schemaProperty,
  schemasArray,
  onCancel,
  onSubmit
}: {
  isNew: boolean
  schemaProperty: ISchemaProperty
  schemasArray: SmartGetSchemas_getSchemas_data[]
  onCancel?: () => void
  onSubmit?: (
    schemaProperty: Partial<ISchemaProperty>,
    updatedLinkedSchemas: LinkedSchema[]
  ) => void
}) => {
  const editSchemaPropertyModal = useModal({
    clickProtect: true,
    header: `${isNew ? 'New' : 'Edit'} field`,
    onClose: onCancel,
    contents: () => (
      <EditSchemaPropertyForm
        isNew={isNew}
        iSchemaProperty={schemaProperty}
        schemas={schemasArray}
        onCancel={editSchemaPropertyModal.close}
        onSubmit={async (property, updatedLinkedSchemas) => {
          onSubmit(property, updatedLinkedSchemas)
          editSchemaPropertyModal.close()
        }}
      />
    )
  })
  return editSchemaPropertyModal
}
