import { FunctionComponent, memo, ReactNode, useState } from 'react'

import { Colors } from 'src/resources/colors'
import { isNil } from 'lodash'

import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { CloseIcon, DangerIcon, ErrorIcon, InfoIcon } from 'src/resources/elements/Icons'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

const fullWidthMargin = `0 0 ${Spacing.basePadding2x} 0;`
const defaultMargin = `0 ${Spacing.basePadding4x} ${Spacing.basePadding2x};`

interface INotificationProps {
  fullWidth: boolean
  marginBottom: string | 0
  type: string
}

const NotificationBase = styled.div<INotificationProps>`
  padding: ${Spacing.basePadding2x};
  border-radius: ${themed('borderRadius')};
  font-family: ${({ theme }) => theme?.customFontStack ?? fontFamily.fontPrimary};
  margin: ${(props) => (props.fullWidth ? fullWidthMargin : defaultMargin)};
  display: flex;
  align-items: center;

  ${({ marginBottom }) =>
    !isNil(marginBottom) &&
    css`
      margin-bottom: ${marginBottom};
    `}

  svg {
    cursor: pointer;
    align-self: start;
  }

  svg:first-child {
    width: ${Spacing.basePadding2x};
    flex: 0 0 ${Spacing.basePadding2x};
    margin-top: 2px;
    cursor: default;
  }

  ${({ type }) => {
    switch (type) {
      case 'info':
        return css`
          background-color: ${Colors.brandAccent2};
        `
      case 'danger':
        return css`
          background-color: ${Colors.dangerBGColor};
        `
      case 'warning':
        return css`
          background-color: ${Colors.warningBGColor};
        `
      default:
        return css`
          border: 2px solid ${Colors.brandPrimary};
          color: ${Colors.text};
          height: 100px;
        `
    }
  }}
`

const Content = styled.div<{ inline: boolean }>`
  color: ${Colors.text};
  margin-bottom: 0;
  margin-left: ${Spacing.baseAndThreeQuarterPadding};
  margin-top: 0;
  font-size: ${fontSizes.type14};
  display: flex;

  > div {
    align-self: center;
  }

  ${({ inline }) => {
    if (!inline) {
      return css`
        flex-flow: column;

        > div {
          align-self: auto;
        }
      `
    }
  }}

  &, > div {
    flex: 1;
  }
`

const Strong = styled.strong`
  font-weight: 600;
  margin-right: ${Spacing.halfBasePadding};
  color: ${Colors.textLogo};
  line-height: 20px;
`

const ChildContainer = styled.div`
  padding-top: ${Spacing.basePadding};
`

const NotificationInfoIcon = () => (
  <InfoIcon
    fill={Colors.brandPrimary}
    role='img'
    aria-label='Blue circle with the letter i in the center'
  />
)

const NotificationDangerIcon = () => (
  <ErrorIcon role='img' aria-label='Red danger circle with the letter x in the center' />
)

const NotificationWarningIcon = () => (
  <DangerIcon
    fill={Colors.warningStrong}
    role='img'
    aria-label='Yellow warning triangle with exclamation point in the center'
  />
)

const Notification: FunctionComponent<{
  type?: 'info' | 'danger' | 'warning' | 'default'
  fullWidth?: boolean
  children?: ReactNode
  inline?: boolean
  title?: string
  closeable?: boolean
  marginBottom?: string | 0
  showIcon?: boolean
  button?: JSX.Element
}> = ({
  type = 'default',
  children,
  fullWidth,
  title,
  inline,
  closeable = false,
  marginBottom,
  showIcon = true,
  button
}) => {
  const [closed, setClosed] = useState(false)

  const Icon = {
    info: NotificationInfoIcon,
    danger: NotificationDangerIcon,
    warning: NotificationWarningIcon,
    default: null
  }[type]

  if (closed) {
    return null
  }

  return (
    <NotificationBase
      data-testid='notification-base'
      type={type}
      fullWidth={fullWidth}
      marginBottom={marginBottom}
    >
      {showIcon && Icon && <Icon />}

      <Content inline={inline}>
        {title && <Strong data-testid='title'>{title}</Strong>}
        {children && <ChildContainer>{children}</ChildContainer>}
      </Content>
      {button}
      {closeable && (
        <CloseIcon role='button' aria-label='Close icon' onClick={() => setClosed(true)} />
      )}
    </NotificationBase>
  )
}

export default memo(Notification)
