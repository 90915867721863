import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ColumnConfigProps, RowData } from '@turntable/core'
import { DataTable } from 'src/applications/Oversight/components/DataTable'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { LowLayer1 } from 'src/applications/Oversight/layers'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Message } from 'src/resources/elements/Message'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_BATCH } from 'src/smart/queries/SQ_BATCH'
import { SQ_GET_NUMERICALLY_INDEXED_VIEW } from 'src/smart/queries/SQ_GET_NUMERICALLY_INDEXED_VIEW'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { EViewType } from 'src/types/enums/EViewType'
import { NumericallyIndexedTransport } from 'src/utils/data/transports/NumericallyIndexedTransport'
import { Breadcrumbs } from 'src/applications/Oversight/components/Breadcrumbs'
import { Colors } from 'src/resources/colors'
import { fontFamily, fontSizes } from 'src/resources/typography'

const SchemaImportContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 144px);
  overflow: hidden;
  padding-top: ${Spacing.basePadding3x};
`

const SchemaImportChromelessContainer = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const DataTableContainer = styled.section`
  height: calc(100vh - 319px);
  flex: 1;
  flex-direction: column;
  display: flex;
  z-index: ${LowLayer1};
  overflow: hidden;
`

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em auto;
  width: 100%;

  h1 {
    color: ${Colors.pigeon800};
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type24};
    margin-top: ${Spacing.basePadding2x};
    margin-bottom: 0;
    font-weight: 600;
  }

  img {
    padding: 8px;
  }

  p {
    margin: 0;
  }
`

const ContentArea = styled.div`
  display: block;
`

export const ImportDataPanel = ({
  workspaceId,
  batchId,
  hideChrome,
  viewType
}: {
  workspaceId: string
  batchId: string
  hideChrome?: boolean
  viewType: EViewType
}) => {
  const [headerLabels, setHeaderLabels] = useState<ColumnConfigProps[]>([])
  const [tableRows, setTableRows] = useState<RowData[]>([])

  const teamRoot = useTeamRootUrl()

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const batchQuery = useSmartQuery(SQ_BATCH, {
    fetchPolicy: 'network-only',
    skip: !batchId,
    variables: {
      batchId
    }
  })
  const batch = batchQuery.result

  const rowsQuery = useSmartQuery(SQ_GET_NUMERICALLY_INDEXED_VIEW, {
    fetchPolicy: 'network-only',
    skip:
      ![EViewType.NUMERICALLY_INDEXED, EViewType.GLOBAL].includes(viewType) ||
      !batchQuery?.result?.uploads?.[0],
    variables: {
      uploadId: batchQuery.result?.uploads?.[0]?.id,
      dataSourceIndex: batchQuery.result?.uploads?.[0]?.selectedDataSourceIndex,
      limit: 1000
    }
  })
  const rawRowData = rowsQuery?.result?.rawRowData

  // todo: too much logic here
  useEffect(() => {
    if (rawRowData?.length) {
      const rows = NumericallyIndexedTransport.fromNumericallyIndexed(rawRowData)
      const firstRow = rows[0]
      setTableRows(rows.map((r) => r.toTurntableProps()))
      setHeaderLabels(firstRow.schema.propertyNames.map((value) => ({ value })))
    }
  }, [rawRowData])

  const conditionals = [!workspaceQuery?.result, !batchQuery?.result]

  // todo: if there are no rows, this will show as loading forever
  const dataTableContainer = useMemo(
    () => (
      <DataTableContainer>
        {tableRows?.length > 0 ? (
          <DataTable
            tableHeaders={headerLabels}
            tableRows={tableRows}
            totalCount={tableRows?.length}
            readOnly={true}
          />
        ) : (
          <Message center>
            <Spinner />
            Please wait, loading...
          </Message>
        )}
      </DataTableContainer>
    ),
    [tableRows, headerLabels]
  )

  if (conditionals.includes(true)) {
    return (
      <Message center>
        <Spinner />
        Please wait, loading...
      </Message>
    )
  }

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      to: `${teamRoot}/workspaces/${workspaceId}/files`,
      label: 'Files'
    },
    {
      current: true,
      label: batch?.originalFile
    }
  ]

  if (hideChrome) {
    return (
      <SchemaImportChromelessContainer>
        <ContentArea>{dataTableContainer}</ContentArea>
      </SchemaImportChromelessContainer>
    )
  }

  return (
    <>
      <WorkspaceActionBar
        inline={true}
        noMargin={false}
        noPadding={true}
        title={<Breadcrumbs links={links} />}
        viewType={EViewType.DERIVED}
        workspaceActive={!workspaceQuery?.result?.status}
        workspaceId={workspaceId}
      />
      <Card>
        <PageHeaderContainer header={batch?.originalFile} />
        <SchemaImportContainer>
          <ContentArea>{dataTableContainer}</ContentArea>
        </SchemaImportContainer>
      </Card>
    </>
  )
}
