interface NamedComponent {
  displayName?: string
  whyDidYouRender?: boolean
}

export const named = <T extends (...args: any[]) => any>(name: string, component: T): T => {
  const renamed: NamedComponent = component as NamedComponent
  renamed.displayName = name
  renamed.whyDidYouRender = true
  return renamed as T
}
