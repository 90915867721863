import { gql } from '@apollo/client'
import { useQuery } from '@apollo/client'
import {
  GetWorkspaceDataRequestSchemas,
  GetWorkspaceDataRequestSchemasVariables
} from 'src/queries/types/GetWorkspaceDataRequestSchemas'

export const GET_WORKSPACE_DATA_REQUEST_SCHEMAS = gql`
  query GetWorkspaceDataRequestSchemas($workspaceId: UUID!) {
    getWorkspaceDataRequestSchemas(workspaceId: $workspaceId) {
      id
      name
      jsonSchema {
        schema
      }
      linkedSchemas {
        name
        id
      }
      variants {
        id
        jsonSchema {
          schema
        }
      }
    }
  }
`

export const getWorkspaceDataRequestSchemas = (workspaceId: string) => {
  return useQuery<GetWorkspaceDataRequestSchemas, GetWorkspaceDataRequestSchemasVariables>(
    GET_WORKSPACE_DATA_REQUEST_SCHEMAS,
    {
      variables: {
        workspaceId
      }
    }
  )
}
