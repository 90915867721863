export const size = {
  large: '1440px',
  largeSecondary: '1280px',
  medium: '1024px',
  small: '320px'
}

export const device = {
  desktop: `(min-width: ${size.large})`,
  mobile: `(max-width: ${size.medium})`,
  tablet: `(max-width: ${size.large})`,
  tabletAndUp: `(min-width: ${size.medium})`,
  smallDesktopAndUp: `(min-width: ${size.largeSecondary})`
}
