import { groupBy } from 'lodash'
import queryString from 'query-string'
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import {
  DangerIcon,
  ExclamationCircleIcon,
  InfoIcon,
  TableIcon,
  EmptyBox
} from 'src/resources/elements/Icons'
import { PaginationWrapper } from 'src/resources/elements/Pagination'
import Pill, { PillStyle } from 'src/resources/elements/Pill'
import {
  ResourceBrowserComponents,
  UpdatedResourceBrowser
} from 'src/resources/elements/UpdatedResourceBrowser'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontPrimary, fontSizes } from 'src/resources/typography'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_DATAHOOK_EXECUTION_WITH_FILTERS } from 'src/smart/queries/SQ_DATAHOOK_EXECUTION_WITH_FILTERS'
import { DataHookExecutionEvent } from 'src/types/aliases/DataHookExecutions'
import { renderDate } from 'src/utils/date'
import { numberFormatter } from 'src/utils/numberFormatter'
import { pluralize } from 'src/utils/pluralize'
import styled from 'styled-components'
import useRouter from 'use-react-router'
import { OverflowData } from '../components/OverflowData'
import { ListPagination } from '../controls/ListPagination'
import { logEventName, LogsDetailPanel } from './LogsDetailPanel'

const LogDate = styled.span`
  padding: ${Spacing.basePadding} ${Spacing.baseAndHalfPadding} ${Spacing.halfBasePadding};
  font-weight: 700;
  font-size: ${fontSizes.type11};
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: ${Colors.pigeon700};
`

const XLogListItem = styled.div<{ idx: number; selected: boolean }>`
  cursor: pointer;
  padding: ${Spacing.baseAndThreeQuarterPadding} ${Spacing.basePadding2_5x};
  background: ${({ idx }) => (idx % 2 === 0 ? Colors.pigeon100 : Colors.white)};
  margin-top: 1px;
  border-right: ${({ selected }) =>
    selected ? `4px solid ${Colors.brandPrimary}` : '4px solid transparent'};
  transition: ${Transitions.baseEase};
  border-radius: 0;

  span {
    font-weight: 600;
    font-size: ${fontSizes.type12};
    line-height: 16px;
    color: ${Colors.pigeon800};
    display: block;
    white-space: nowrap;
  }

  &:hover {
    box-shadow: ${Shadows.hover};
  }

  &:last-child {
    margin-bottom: 40px;
  }
`

const Row = styled.div`
  justify-content: space-between;

  &,
  div {
    display: flex;
  }

  & + & {
    margin-top: 5px;
  }
`

const LogsListItems = styled.div`
  ${PaginationWrapper} {
    padding: 20px 30px;
  }

  ${PillStyle} {
    margin-left: 0;
  }
`

const XIconData = styled.div<{ fontSize: string }>`
  align-items: center;
  margin-left: 10px;
  display: flex;

  svg {
    height: 12px;
  }

  span {
    margin-left: 6px;
    font-weight: 400;
    font-size: ${({ fontSize }) => fontSize ?? fontSizes.type11};
    line-height: 15px;
    color: ${Colors.pigeon800};
  }
`

const NoLogsToList = styled.div`
  width: 190px;
  font-size: ${fontSizes.type12};
  line-height: 16px;
  text-align: center;
  color: ${Colors.pigeon800};
  margin: 0 auto;
  padding-bottom: 80px;

  p {
    font-weight: 600;
  }
`

const logsResourceBrowser = {
  ...ResourceBrowserComponents,
  Resources: styled(ResourceBrowserComponents.Resources)`
    min-width: 340px;
    flex: 0 1 360px;

    ${ResourceBrowserComponents.Inner} {
      position: unset;
    }
  `,
  SpinnerBlock: styled(ResourceBrowserComponents.SpinnerBlock)`
    margin-top: 10px;
  `
}

const XCard = styled(Card)`
  ${PillStyle} {
    font-size: ${fontSizes.type11};
    color: ${Colors.pigeon800};
  }

  * {
    font-family: ${fontPrimary};
  }
`

const DEFAULT_PAGE_SIZE = 10

export const LogsListPanel = () => {
  const team = useContext(TeamContext)
  const { history } = useRouter()
  const [dhExecutions, setDhExecutions] = useState<{
    [Key: string]: DataHookExecutionEvent[]
  }>({})
  const eventId = useSearchParam.string('eventId', null)
  const page = useSearchParam.string('page', '1')
  const environment = useContext(EnvironmentContext)

  const prevLogRef = useRef<DataHookExecutionEvent>(null)

  const { result, pagination, state } = useSmartQuery(SQ_DATAHOOK_EXECUTION_WITH_FILTERS, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: Number(team.id),
      environmentId: environment.id,
      ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE)
    }
  })

  useEffect(() => {
    if (result?.data) {
      setDhExecutions(
        groupBy(result.data || [], ({ createdAt }) =>
          renderDate(new Date(createdAt).toISOString(), false, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        )
      )
    }
  }, [result])

  const setSelectedEventId = (id?: string) => {
    if (id) {
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify({
          eventId: id,
          page
        })
      })
      window.scrollTo({ top: 0, left: 0 })
    }
  }

  const selectedExecution = useMemo(() => {
    if (!result) return null

    const resource = eventId
      ? result.data.find((event) => event.eventId === eventId)
      : result.data[0]

    if (resource) {
      prevLogRef.current = resource

      return resource
    } else {
      return prevLogRef.current
    }
  }, [eventId, result])

  const logsCount = Object.keys(dhExecutions).length

  return (
    <XCard>
      <PageHeaderContainer hasMargin header='Event Logs' />

      {(logsCount || state.loading) && (
        <UpdatedResourceBrowser
          components={logsResourceBrowser}
          items={
            logsCount &&
            pagination && [
              <LogsListItems key='log-list-items'>
                {Object.keys(dhExecutions).map((executionDate, idx) => (
                  <div key={idx}>
                    <LogDate>{executionDate}</LogDate>

                    {dhExecutions[executionDate].map((event, logidx: number) => (
                      <LogListItem
                        event={event}
                        idx={logidx}
                        onClick={setSelectedEventId}
                        key={event.eventId}
                        selected={selectedExecution?.eventId === event.eventId}
                      />
                    ))}
                  </div>
                ))}

                <ListPagination pagination={pagination} />
              </LogsListItems>
            ]
          }
          sidebarConfig={null}
          alwaysRenderChildren
        >
          {selectedExecution && <LogsDetailPanel dhExecutionEvent={selectedExecution} />}
        </UpdatedResourceBrowser>
      )}

      {!logsCount && !state.loading && <EmptyLogList />}
    </XCard>
  )
}

export const buildPillContent = (count: number, type: string) => {
  return `${numberFormatter(count, 0)} ${pluralize(type, count)}`
}

const LogListItem = memo(
  ({
    event,
    idx,
    onClick,
    selected
  }: {
    event: DataHookExecutionEvent
    idx: number
    onClick(id: string | number): void
    selected: boolean
  }) => {
    const handleSelect = useCallback(() => onClick(event.eventId), [onClick])

    const hooksCount = useMemo(
      () => buildPillContent(event.dataHooksCount, 'Hook'),
      [event.dataHooksCount]
    )
    const rowsCount = useMemo(
      () => buildPillContent(event.rowCount / event.dataHooksCount, 'Record'),
      [event.rowCount, event.dataHooksCount]
    )
    const formattedDate = useMemo(
      () => new Date(event.createdAt).toLocaleTimeString(),
      [event.createdAt]
    )

    return (
      <XLogListItem idx={idx} onClick={handleSelect} selected={selected}>
        <Row>
          <OverflowData maxWidth={167} dataTip={logEventName(event)}>
            <>{logEventName(event)}</>
          </OverflowData>
          <Pill customStyle={{ background: Colors.pigeon200 }}>
            <>
              {hooksCount}, {rowsCount}
            </>
          </Pill>
        </Row>
        <Row>
          <span>{formattedDate}</span>
          <IconData Icon={TableIcon}>
            <OverflowData maxWidth={102} dataTip={event.template.name}>
              <span>{event.template.name}</span>
            </OverflowData>
          </IconData>

          <IconData
            Icon={DangerIcon}
            iconColor={!event.errorCount ? Colors.pigeon600 : Colors.brandAccent}
          >
            {event.errorCount}
          </IconData>
          <IconData Icon={InfoIcon}>{event.infoCount}</IconData>
          <IconData Icon={ExclamationCircleIcon}>{event.warningCount}</IconData>
        </Row>
      </XLogListItem>
    )
  }
)

export const IconData = ({
  Icon,
  children,
  iconColor,
  fontSize
}: {
  Icon: ({ fill }: { fill?: string }) => JSX.Element
  children: string | JSX.Element | number
  iconColor?: string
  fontSize?: string
}) => (
  <XIconData fontSize={fontSize}>
    <Icon fill={iconColor} />
    <span>{children}</span>
  </XIconData>
)

export const EmptyLogList = () => (
  <NoLogsToList>
    <EmptyBox />
    <p>There are no logs to show</p>

    <span>If you believe this is an error, please contact support.</span>
  </NoLogsToList>
)
