const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

function renderWithTime(d: Date) {
  return d
    .toLocaleTimeString('en-US', dateOptions)
    .split(', ')
    .map((x) => x.replace(/\:\d\d\s/, ' '))
    .join(' ')
}

/**
 * @param {string} dateString '162786606100' or '2021-07-16T15:05:32.000Z'
 * @param {boolean} showTime set to true to show time when formatting
 * @returns {string} either formatted date or original dateString value
 */
export const renderDate = (
  dateString: string | number,
  showTime?: boolean,
  options = dateOptions
) => {
  if (!dateString) {
    return
  }

  let parsed = new Date(dateString)

  // if it's not invalid, return formatted
  if (!isNaN(parsed.valueOf())) {
    if (showTime) {
      return renderWithTime(parsed)
    }
    return parsed.toLocaleDateString('en-US', options)
  }

  // otherwise, we need to parse it as an int first
  const dateInt: number = typeof dateString === 'number' ? dateString : parseInt(dateString, 10)
  parsed = new Date(dateInt)

  // if it's not invalid, return formatted
  if (!isNaN(parsed.valueOf())) {
    if (showTime) {
      return renderWithTime(parsed)
    }
    return parsed.toLocaleDateString('en-US', options)
  }

  // if it's still not a real date, let's return the original value
  return String(dateString)
}

const MINUTE_S = 60
const HOUR_S = MINUTE_S * 60
const DAY_S = HOUR_S * 24

export function formatDuration(value: number): string[] {
  for (const [period, label] of [
    [DAY_S, 'd'],
    [HOUR_S, 'h'],
    [MINUTE_S, 'm']
  ] as [number, string][]) {
    if (value > period) {
      const units = Math.floor(value / period)
      return [`${units}${label}`, ...formatDuration(value - units * period)]
    }
  }
  return [`${value}s`]
}
