import { useEffect } from 'react'

import { withRouter } from 'react-router-dom'

export function lastNumericSegmentVaries(a: string, b: string): boolean {
  const [aSegments, bSegments] = [a, b].map((x) => x.split('/'))
  if (aSegments.length !== bSegments.length) {
    return false
  }
  return (
    aSegments.filter((_, index) => {
      const varies = aSegments[index] !== bSegments[index]
      const isLastSegment = index === aSegments.length - 1
      const isNumeric = [aSegments[index], bSegments[index]].every((x) => /^\d+$/.test(x))
      if (isLastSegment) {
        return varies && !isNumeric
      } else {
        return varies
      }
    }).length === 0
  )
}

export const ScrollToTop = withRouter(({ history }): null => {
  useEffect(() => {
    const prevPathname = history.location.pathname
    const unListen = history.listen(({ pathname }) => {
      const preserveScrollPosition = lastNumericSegmentVaries(pathname, prevPathname)
      if (!preserveScrollPosition) {
        window.scrollTo({ top: 0, left: 0 })
      }
    })
    return unListen
  }, [])

  return null
})
