import { useRef } from 'react'

import { onUnmount } from './onUnmount'

export const useIsMounted = () => {
  const isMounted = useRef(true)

  onUnmount(() => {
    isMounted.current = false
  })

  return isMounted
}
