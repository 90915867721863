import {
  GetBatches_getBatches_data_endUser,
  GetBatches_getBatches_data_schema
} from 'src/queries/types/GetBatches'

export type EndUserType = GetBatches_getBatches_data_endUser
export type SchemaType = GetBatches_getBatches_data_schema

export const renderEndUserLink = (endUser?: Partial<EndUserType>) =>
  `?u=${endUser ? endUser.id : null}`

export const renderEndUserId = (endUser?: Partial<EndUserType>) => endUser && endUser.userId

export const renderEndUserName = (endUser?: Partial<EndUserType>) => endUser && endUser.name

export const renderSchemaLink = (schema: Pick<SchemaType, 'id'>) => `?s=${schema.id}`
