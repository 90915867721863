import { useContext } from 'react'

import { NavLink, withRouter } from 'react-router-dom'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { LowLayer3 } from 'src/applications/Oversight/layers'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { AvatarSquareInitials } from 'src/resources/elements/Avatar'
import { CustomDropdownElement } from 'src/resources/elements/Dropdown'
import { Logo } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { getInitials } from 'src/resources/utils/getInitials'
import styled from 'styled-components'

export const ToolbarStyle = styled.nav`
  z-index: ${LowLayer3};
  padding-right: ${Spacing.basePadding2x};
  padding-left: ${Spacing.basePadding2x};
`

const ToolbarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto ${Spacing.basePadding};
  max-width: calc(${Spacing.container} - ${Spacing.basePadding2x});
  padding: ${Spacing.contentPadding} 0 ${Spacing.basePadding2x} 0;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${Spacing.basePadding2x} 0;
`

const ToolbarHomeLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.fontPrimary};
  color: ${Colors.textAccent};
  align-items: center;

  span {
    font-weight: 600;
    display: block;
    margin-top: 8px;
    font-size: ${fontSizes.type24};
  }
`

export const ToolbarDropdown = styled.div``

const ToolbarLeft = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: normal;
`

const CompanyInitials = styled(AvatarSquareInitials)`
  transform: translateX(0px);
`

const ToolbarRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

export const Toolbar = withRouter(({ location: { pathname } }) => {
  const teamRoot = useTeamRootUrl()
  const {
    data: { me, myTeams }
  } = getBasicInfoContext().value

  const team = useContext(TeamContext)

  if (!me || !team) {
    // toolbar to show when not logged in
    return (
      <ToolbarStyle>
        <ToolbarContainer>
          <LinkContainer>
            <ToolbarHomeLink exact to='/'>
              <Logo />
            </ToolbarHomeLink>
          </LinkContainer>
        </ToolbarContainer>
      </ToolbarStyle>
    )
  }

  const teamSelectionOptions = myTeams.map((iterTeam) => ({
    label: iterTeam.name,
    path: pathname.replace(/^\/a\/[^\/]+\//, `/a/${iterTeam.id}/`)
  }))

  const AccountDropdown = [
    {
      label: 'Account',
      path: `${teamRoot}/account`
    },
    {
      label: 'Invite team',
      path: `${teamRoot}/team`
    },
    {
      label: 'Billing',
      path: `${teamRoot}/team`
    },
    {
      label: 'Logout',
      path: '/logout'
    }
  ]

  return (
    <ToolbarStyle>
      <ToolbarContainer>
        <ToolbarLeft>
          <CompanyInitials>{getInitials(team.name)}</CompanyInitials>
          <ToolbarDropdown>
            <CustomDropdownElement
              placeholder={team.name}
              dropdownOptions={teamSelectionOptions}
            />
          </ToolbarDropdown>
        </ToolbarLeft>
        <ToolbarRight>
          <AvatarSquareInitials>{getInitials(me.name)}</AvatarSquareInitials>
          <CustomDropdownElement placeholder='' dropdownOptions={AccountDropdown} />
        </ToolbarRight>
      </ToolbarContainer>
    </ToolbarStyle>
  )
})
