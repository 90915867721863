import { useCallback, useEffect, useState } from 'react'

import { OVERSIGHT_IS_PRODUCTION_DOMAIN } from 'src/config'
import { TopLevelPortal } from 'src/resources/elements/TopLevelPortal'
import {
  IConfigValue,
  IConfigValuesManager,
  localStorageOverride,
  storedConfigValue,
  useConfigValues
} from 'src/utils/localStorageOverride'

import { DispatchChangeOverride } from './DeveloperMenuCommon'
import { Collapsed, Expanded, Panel, PanelContainer } from './DeveloperMenuElements'

localStorageOverride('VENDOR_SEGMENT_ENABLE', 'on')

const getNewOverrideValue = (configValue: IConfigValue) => {
  switch (configValue.defaultValue) {
    case 'on':
      return 'off'
    case 'off':
      return 'on'
    case 'true':
      return 'false'
    case 'false':
      return 'true'
    case '1':
      return '0'
    case '0':
      return '1'
    default:
      return configValue.defaultValue
  }
}

const createOnChangeIsOverride =
  (config: IConfigValuesManager): DispatchChangeOverride =>
  (configValue: IConfigValue, isOverride: boolean) => {
    if (isOverride) {
      config.set(configValue, getNewOverrideValue(configValue))
    } else {
      config.remove(configValue)
    }
  }

const isProduction =
  localStorageOverride(
    'OVERSIGHT_IS_PRODUCTION_DOMAIN',
    String(OVERSIGHT_IS_PRODUCTION_DOMAIN)
  ) === 'true'

const developerMenuPinnedOpen = storedConfigValue('DEVELOPER_MENU_PINNED_OPEN', 'off', {
  insignificant: true
})

const developerMenuFullWidth = storedConfigValue('DEVELOPER_MENU_FULL_WIDTH', 'off', {
  insignificant: true
})

const developerMenuRoute = storedConfigValue('DEVELOPER_MENU_ROUTE', '', {
  insignificant: true
})

export const DeveloperMenu = () => {
  const configValues = useConfigValues()
  const changeIsOverride = useCallback(createOnChangeIsOverride(configValues), [configValues])
  const setRoute = useCallback(
    (newRoute: string[]) => configValues.set(developerMenuRoute, newRoute.join('/')),
    [configValues]
  )

  const isPinned = configValues.get(developerMenuPinnedOpen, true) === 'on'
  const isFullWidth = configValues.get(developerMenuFullWidth, true) === 'on'
  const [isUserExpanded, setIsUserExpanded] = useState(isPinned)
  const isExpanded = isPinned || isUserExpanded

  const setIsExpanded = useCallback(
    // closing will also un-pin
    (expanded: boolean) => {
      if (isPinned && !expanded) {
        changeIsOverride(developerMenuPinnedOpen, false)
      }
      setIsUserExpanded(expanded)
    },
    [isPinned, isUserExpanded]
  )

  const route = (configValues.get(developerMenuRoute, true) ?? '').split('/').filter((x) => x)

  useEffect(() => {
    const keyboardConfigPanelExpander = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === '`') {
        setIsExpanded(!isExpanded)
      }
    }
    window.addEventListener('keydown', keyboardConfigPanelExpander, { capture: true })

    return () => {
      window.removeEventListener('keydown', keyboardConfigPanelExpander)
    }
  }, [isExpanded])

  useEffect(() => {
    document.body.style.overflow = isExpanded && isFullWidth ? 'hidden' : 'auto'
  }, [isFullWidth, isExpanded])

  if (isProduction && !isExpanded) {
    return null
  }

  return (
    <TopLevelPortal>
      <PanelContainer expanded={isExpanded} fullWidth={isFullWidth}>
        <Panel expanded={isExpanded} fullWidth={isFullWidth}>
          {isExpanded ? (
            <Expanded
              config={configValues}
              fullWidth={isFullWidth}
              onChangeIsOverride={changeIsOverride}
              pinned={isPinned}
              route={route}
              setRoute={setRoute}
              toggleIsExpanded={() => setIsExpanded(false)}
              toggleIsFullWidth={() => changeIsOverride(developerMenuFullWidth, !isFullWidth)}
              toggleIsPinned={() => changeIsOverride(developerMenuPinnedOpen, !isPinned)}
            />
          ) : (
            <Collapsed toggleIsExpanded={() => setIsUserExpanded(true)} />
          )}
        </Panel>
      </PanelContainer>
    </TopLevelPortal>
  )
}
