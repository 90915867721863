import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'
import { css } from 'styled-components'

export const Spacer = styled.div<{ grow?: boolean }>`
  width: ${Spacing.basePadding2x};

  ${({ grow }) =>
    grow
      ? css`
          flex-grow: 1;
        `
      : null}
`
