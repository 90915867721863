import styled from 'styled-components'
import { EmbedUploadIcon } from 'src/resources/elements/Icons'
import { Colors } from 'src/resources/colors'
import EmbedCSVIcon from 'src/resources/icons/EmbedCSV.svg'
import EmbedXLSXIcon from 'src/resources/icons/EmbedXLSX.svg'

export const StyledEmbedUploadIcon = styled(EmbedUploadIcon)`
  path:first-child {
    fill: ${(props) => props.theme.primary || Colors.brandPrimary};
  }
`

export const FileUploadIcon = (
  <>
    <img src={EmbedCSVIcon} />
    <img src={EmbedXLSXIcon} />
  </>
)
