import Skeleton from 'react-loading-skeleton'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

const Container = styled.div.attrs(() => ({ tabIndex: 0 }))`
  &.top {
    margin-bottom: 25px;
  }

  .mt-12 {
    margin-top: 12px;
    display: block;
  }

  .mt-24 {
    margin-top: 24px;
    display: block;
  }

  .mt-32 {
    margin-top: 32px;
    display: block;
  }

  .mb-16 {
    margin-bottom: 16px;
    display: block;
  }

  .mb-32 {
    margin-bottom: 32px;
    display: block;
  }

  .b5 {
    bottom: 5px;
  }

  .b15 {
    bottom: 15px;
  }

  .t5 {
    top: 5px;
  }

  .t12 {
    top: 12px;
  }

  .rightside {
    position: relative;
    margin-left: 8px;
  }

  .br-20 {
    border-radius: 20px;
  }
`

const Inline = styled.div.attrs(() => ({ tabIndex: 0 }))`
  display: flex;
`

const SkeletonContainer = styled.div.attrs(() => ({ tabIndex: 0 }))``

export const SideBarWorkspaceSkeleton = named('SideBarWorkspaceSkeleton', () => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <Container className='top'>
        <Skeleton width={145} height={40} className='br-20' />

        <SkeletonContainer className='mt-24 mb-16'>
          <Inline>
            <Skeleton circle={true} width={40} height={40} />
            <Skeleton width={90} height={12} className='rightside t12' />
          </Inline>
        </SkeletonContainer>

        <SkeletonContainer className='pl'>
          <Inline>
            <Skeleton circle={true} width={24} height={24} className='mt-12' />
            <Skeleton width={145} height={12} className='mt-24 rightside b5' />
          </Inline>
        </SkeletonContainer>

        <SkeletonContainer className='pl'>
          <Inline>
            <Skeleton circle={true} width={24} height={24} className='mt-12' />
            <Skeleton width={135} height={12} className='mt-24 rightside b5' />
          </Inline>
        </SkeletonContainer>

        <SkeletonContainer className='pl'>
          <Inline>
            <Skeleton circle={true} width={24} height={24} className='mt-12' />
            <Skeleton width={145} height={12} className='mt-24 rightside b5' />
          </Inline>
        </SkeletonContainer>

        <SkeletonContainer className='pl'>
          <Skeleton width={135} height={12} className='mt-32 mb-32' />
        </SkeletonContainer>

        <SkeletonContainer className='pl mt-24'>
          <Inline>
            <Skeleton circle={true} width={24} height={24} />
            <Skeleton width={130} height={12} className='mt-24 rightside b15' />
          </Inline>
        </SkeletonContainer>

        <SkeletonContainer className='pl'>
          <Inline>
            <Skeleton circle={true} width={24} height={24} />
            <Skeleton width={155} height={12} className='rightside t5' />
          </Inline>
        </SkeletonContainer>
      </Container>
    </>
  )
})
