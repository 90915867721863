import { gql } from '@apollo/client'

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $passwordConfirm: String!, $resetToken: String!) {
    resetPassword(
      password: $password
      passwordConfirm: $passwordConfirm
      resetToken: $resetToken
    ) {
      email
    }
  }
`
