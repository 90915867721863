import { FLATFILE_REFINERY_URL, FLATFILE_REFINERY_WS_URL, USER_IS_DEVELOPER } from 'src/config'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'
import { Icon } from 'src/resources/elements/Icon'

declare const GIT_BRANCH: string

const DeveloperAlertsContainer = styled.div`
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: opacity 0.2s ease;
  z-index: 1000;

  &:hover {
    opacity: 1;
  }
`

const DeveloperAlert = styled.div`
  color: ${Colors.blackLight};
  font-family: ${fontFamily.fontMonospace};
  font-size: ${fontSizes.type12};
  margin: 0 ${Spacing.basePadding};
  padding: 6px 8px;
  pointer-events: initial;
  text-shadow: 0 0 ${Spacing.halfBasePadding} ${Colors.white};
`

export const DeveloperAlerts = () =>
  USER_IS_DEVELOPER ? (
    <DeveloperAlertsContainer>
      <DeveloperAlert title='Git branch or commit'>
        <Icon name='code-branch' /> {GIT_BRANCH}
      </DeveloperAlert>
      <DeveloperAlert title='Flatfile API &amp; Websocket URLs'>
        <Icon name='globe' /> {FLATFILE_REFINERY_URL} {FLATFILE_REFINERY_WS_URL}
      </DeveloperAlert>
    </DeveloperAlertsContainer>
  ) : null
