import { useCallback, useEffect, useMemo, useState } from 'react'

import FlipMove from 'react-flip-move'
import { Loader } from 'src/applications/Embed/fragments/Loader'
import { Colors } from 'src/resources/colors'
import { SuccessCheck } from 'src/resources/elements/Icons'
import EmbedBackIcon from 'src/resources/icons/EmbedBack.svg'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'

import { DataSourceField } from '../classes/DataSourceField'
import { sortEnumFields } from '../helpers'
import { DataPreviewContent, DataPreviewHeader, DataSourceStatus } from '../styles'
import { MenuSelect } from './MenuSelect'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
`

const Backdrop = styled.div`
  width: 40vw;
  height: 100vh;
`

const Content = styled.div`
  width: 60vw;
  height: 100vh;
  background-color: ${Colors.grayDarkBackground};
  padding: 56px 80px 56px 56px;
  position: relative;

  ${DataPreviewContent} {
    height: 100%;
  }
`

const TableWrapper = styled.div`
  height: calc(100% - 60px);
  overflow-y: auto;
  flex: 1;
`

const TableRow = styled.tr``

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  padding-bottom: 24px;

  thead {
    position: sticky;
    top: 0;
    z-index: 15001;
    background-color: white;

    & > ${TableRow} > th {
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      color: #3f5c71;
      letter-spacing: 1.5px;
      padding: 30px 0 6px;

      &:nth-child(3) {
        padding-left: 0;
      }
    }
  }

  tbody > ${TableRow} {
    &:hover {
      background: #eaf0f6;

      td:last-child {
        div[class$='-control'] {
          background-color: white;
        }
      }
    }

    & > td {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #35414b;
      padding: 6px 0;
      border-bottom: 1px solid #dbe5f0;

      i {
        color: #afbed0;
        font-weight: 400;
      }

      &:nth-child(1) {
        padding-right: 10px;
        padding-left: 10px;

        & > * {
          margin-left: auto;
          margin-right: 4px;
        }
      }

      &:last-child {
        padding-right: 40px;
      }
    }
  }
`

const Back = styled.a`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #587a9d;
  margin-bottom: 16px;
  cursor: pointer;

  img {
    margin-right: 6px;
  }
`

export interface IEnumMatchPanel {
  visible: boolean
  field?: DataSourceField
  jsonSchemaProp?: JsonSchemaProperty
  onClose?: (result?: Record<string, string>) => void
}

const StyledSuccessCheck = styled(SuccessCheck)`
  circle {
    fill: ${(props) => props.theme.primary};
  }
`
StyledSuccessCheck.defaultProps = {
  theme: {
    primary: '#377D3D'
  }
}

export function EnumMatchPanel({ visible, field, jsonSchemaProp, onClose }: IEnumMatchPanel) {
  const [enums, setEnums] = useState<Record<string, string>>({})

  useEffect(() => {
    setEnums(field?.matchMeta || {})
  }, [field?.matchMeta])

  const handleClose = useCallback(() => {
    onClose(JSON.stringify(enums) !== JSON.stringify(field?.matchMeta) ? enums : void 0)
  }, [enums, field])

  const sortedEnums = useMemo(() => sortEnumFields(enums), [enums])
  const isDataSourceActive = useMemo(
    () =>
      Object.keys(enums ?? {}).length &&
      Object.values(enums ?? {}).every((v) => (v ?? null) !== null),
    [enums]
  )

  const hasEnums = useMemo(() => Object.keys(enums).length > 0, [enums])

  if (!visible) {
    return <></>
  }

  if (!jsonSchemaProp?.enumArray?.length) {
    return <></>
  }

  return (
    <Wrapper>
      <Backdrop onClick={() => handleClose()} />
      <Content>
        <Back
          onClick={(e) => {
            e.preventDefault()
            handleClose()
          }}
        >
          <img src={EmbedBackIcon} />
          <span>Return to Matching</span>
        </Back>
        {!!field && (
          <DataPreviewContent>
            <DataPreviewHeader>
              <DataSourceStatus active={isDataSourceActive}>
                {isDataSourceActive && <SuccessCheck circle={'transparent'} />}
              </DataSourceStatus>
              Category matching for {field.label}
            </DataPreviewHeader>
            {hasEnums ? (
              <TableWrapper>
                <Table>
                  <colgroup>
                    <col width='10%' />
                    <col width='50%' />
                    <col width='40%' />
                  </colgroup>
                  <thead>
                    <TableRow>
                      <th />
                      <th>Your values</th>
                      <th>Additional values</th>
                    </TableRow>
                  </thead>
                  <FlipMove typeName='tbody'>
                    {sortedEnums.map(({ sourceValue, enumValue }) => (
                      <TableRow key={sourceValue}>
                        <td>
                          <DataSourceStatus small active={typeof enumValue === 'string'}>
                            {typeof enumValue === 'string' && (
                              <SuccessCheck circle={'transparent'} />
                            )}
                          </DataSourceStatus>
                        </td>
                        <td>{sourceValue || <i>Empty</i>}</td>
                        <td>
                          <div style={{ maxWidth: 220 }}>
                            <MenuSelect
                              name={sourceValue}
                              selectedValue={
                                enumValue
                                  ? {
                                      label:
                                        jsonSchemaProp.enumArray.find((e) => e.value === enumValue)
                                          ?.label ?? enumValue,
                                      value: enumValue
                                    }
                                  : null
                              }
                              columnOptions={jsonSchemaProp.enumArray}
                              onUpdate={(values) => {
                                setEnums((_enums) => Object.assign({}, _enums, values))
                              }}
                            />
                          </div>
                        </td>
                      </TableRow>
                    ))}
                  </FlipMove>
                </Table>
              </TableWrapper>
            ) : (
              <Loader top={100} />
            )}
          </DataPreviewContent>
        )}
      </Content>
    </Wrapper>
  )
}
