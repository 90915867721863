import { availableFilters } from 'src/applications/Oversight/components/FilterSelect'
import { Filter, Validation } from 'src/resources/hooks/useTableFilters'
import { pluralize } from 'src/utils/pluralize'

export interface IRowCounts {
  totalRows: number
  accepted?: number
  dismissed?: number
  filtered?: number
  review?: number
  notSet?: boolean
}

export const getEmptyValidationMessage = (validationState: Validation) => {
  switch (validationState) {
    case 'review':
      return 'No records in review'
    case 'accepted':
      return 'No approved records'
    case 'dismissed':
      return 'No dismissed records'
    default:
      return 'No records in review'
  }
}

export const getEmptyValidationBody = (validationState: Validation, count: number) => {
  const pluralizedText = pluralize('record', count)
  switch (validationState) {
    case 'review':
      return `${count} ${pluralizedText} in review`
    case 'accepted':
      return `${count} approved ${pluralizedText}`
    case 'dismissed':
      return `${count} dismissed ${pluralizedText}`
    default:
      return `${count} ${pluralizedText} in review`
  }
}

export const getEmptyFilterMessageEnding = (validationState?: Validation) => {
  switch (validationState) {
    case 'review':
      return 'left to review'
    case 'accepted':
      return 'have been approved yet'
    case 'dismissed':
      return 'have been dismissed yet'
    default:
      return 'left to review'
  }
}

export const getEmptyFilterMessage = ({
  validationState,
  filterChoice
}: {
  validationState: Validation
  filterChoice: Filter
}) => {
  return `No ${availableFilters
    .find((aFilter) => aFilter.value === filterChoice)
    ?.label?.toLowerCase()} ${getEmptyFilterMessageEnding(validationState)}`
}

export const getEmptyStateTitle = ({
  filterChoice,
  validationState,
  rowCounts
}: {
  filterChoice: Filter
  validationState: Validation
  rowCounts?: IRowCounts
}) => {
  const isValidityFilterMatched = filterChoice === availableFilters[0].value

  if (rowCounts?.totalRows) {
    switch (validationState) {
      case 'accepted':
        return isValidityFilterMatched
          ? getEmptyValidationMessage('accepted')
          : getEmptyFilterMessage({ validationState, filterChoice })
      case 'dismissed':
        return isValidityFilterMatched
          ? getEmptyValidationMessage('dismissed')
          : getEmptyFilterMessage({ validationState, filterChoice })
      default:
        return isValidityFilterMatched
          ? getEmptyValidationMessage('review')
          : getEmptyFilterMessage({ validationState, filterChoice })
    }
  } else {
    return 'Nothing to see yet!'
  }
}
