import moment from 'moment'
import { useState, useEffect } from 'react'
import { Transitions } from 'src/resources/animations/transitions'
import styled from 'styled-components'
// TODO: enable if we want by the second updates
// moment.relativeTimeThreshold('ss', 0)

export const TimeStamp = styled.div<{ visible: boolean }>`
  transition: ${Transitions.baseEase};
  pointer-events: none;
  opacity: 0;
  transform: translateX(-2px);
  ${({ visible }) =>
    visible &&
    `
  pointer-events: all;
  opacity: 1;
  transform: translateX(0);
  `}
`
export const TimeAgo = ({ ago, message }: { ago: string; message: string }) => {
  const [timestamp, setTimestamp] = useState(moment.unix(Number(ago) / 1000).fromNow())
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimestamp(moment.unix(Number(ago) / 1000).fromNow())
    }, 60000)

    return () => clearInterval(timerInterval)
  }, [ago])
  return (
    <TimeStamp visible={!!timestamp}>
      {message} {timestamp}
    </TimeStamp>
  )
}
