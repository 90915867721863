import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'

import { SmartEmbeds, SmartEmbedsVariables, SmartEmbeds_getEmbeds } from './types/SmartEmbeds'

export const SQ_EMBEDS: SmartQuery<SmartEmbedsVariables, SmartEmbeds, SmartEmbeds_getEmbeds> = {
  query: gql`
    query SmartEmbeds(
      $teamId: ID!
      $environmentId: UUID
      $take: Int!
      $skip: Int!
      $search: String
      $archived: Boolean
    ) {
      getEmbeds(
        teamId: $teamId
        environmentId: $environmentId
        take: $take
        skip: $skip
        search: $search
        archived: $archived
      ) {
        data {
          id
          archived
          name
          team {
            id
            name
          }
          teamId
          privateKey {
            id
          }
          schemas {
            id
            name
          }
          createdAt
          updatedAt
        }
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
      }
    }
  `,
  extract({ getEmbeds }) {
    return getEmbeds
  }
}
