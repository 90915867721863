import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

import { CreateWorkspace, CreateWorkspaceVariables } from './types/CreateWorkspace'

export const SM_CREATE_WORKSPACE: SmartMutation<
  CreateWorkspaceVariables,
  CreateWorkspace,
  IWorkspace
> = {
  mutation: gql`
    mutation CreateWorkspace(
      $organizationId: UUID!
      $teamId: ID!
      $name: String!
      $environmentId: UUID
    ) {
      createWorkspace(
        teamId: $teamId
        organizationId: $organizationId
        name: $name
        environmentId: $environmentId
      ) {
        id
        name
        createdAt
        updatedAt
        lastActivity
        status
        primaryWorkbookId
        dataEngine
        secureEnv {
          variables
          signedKeys
        }
        primaryWorkbook {
          status
        }
      }
    }
  `,
  extract: (response) => response.createWorkspace
}
