import { CellChangeDetail, LookupOption } from '@turntable/core'

import { Atom, IMessageLevel } from './Atom'

export class SchemaRefAtom extends Atom<LookupOption[]> {
  /**
   * @returns the current selected values as Turntable ready options
   */
  public toCellValue() {
    if (typeof this.value === 'undefined' || this.value === null) {
      return []
    }

    return [
      {
        value: this.value,
        label: this.value?.toString(),
        ...(this.meta.messages.some(({ level }) => level === IMessageLevel.ERROR)
          ? { error: true }
          : {})
      }
    ]
  }

  /**
   * Get the primitive value from from a changeset
   *
   * @todo support multiple options
   * @param change
   */
  public valueFromCellChangeDetail(change: CellChangeDetail<LookupOption[]>) {
    const options = change.value
    if (!options.length) {
      return null
    }
    return options[0].value
  }
}
