import { useCallback } from 'react'

import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'

export enum EBatchTimelineFormat {
  simple = 'simple',
  detailed = 'detailed'
}

export function BatchTimelineFormatSelector({
  selectedFormat,
  onChangeSelectedFormat
}: {
  selectedFormat: EBatchTimelineFormat
  onChangeSelectedFormat: (newFormat: EBatchTimelineFormat) => void
}) {
  const [goSimple, goDetailed] = [EBatchTimelineFormat.simple, EBatchTimelineFormat.detailed].map(
    (x) =>
      useCallback(() => {
        onChangeSelectedFormat(x)
      }, [onChangeSelectedFormat])
  )
  return (
    <Tabstrip center marginBottom>
      <Tab active={selectedFormat === EBatchTimelineFormat.simple} onClick={goSimple}>
        Simple
      </Tab>
      <Tab active={selectedFormat === EBatchTimelineFormat.detailed} onClick={goDetailed}>
        Detailed
      </Tab>
    </Tabstrip>
  )
}
