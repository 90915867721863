import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_INIT_WORKBOOK } from 'src/smart/mutations/SM_INIT_WORKBOOK'
import { useWorkbookUpdatedSubscriptionWithPollFallback } from 'src/smart/queries/SQ_WORKBOOK_UPDATED_SUBSCRIPTION'
import { EWorkbookStatus } from 'src/types/enums/EWorkbookStatus'
import useReactRouter from 'use-react-router'
import {
  SubmittedLoader,
  SubmittedLoaderTryAgain
} from 'src/applications/Oversight/scenes/MatchingV3/styles'
import { useTranslation } from 'react-i18next'
interface IWorkbookLoader {
  batchId: string
  mergeField?: string
  onComplete(workbookId: string): void
  existingWorkbookId?: string
  matchesUpdated?: boolean
  workbookCancelled: boolean
  workspaceId: string
}

// WorkbookLoader is responsible for creating a workbook
// if the V3 flag is enabled as well as notifying when
// the workbook is ready
export function WorkbookLoader({
  batchId,
  onComplete,
  existingWorkbookId,
  mergeField,
  matchesUpdated,
  workbookCancelled,
  workspaceId
}: IWorkbookLoader) {
  const [workbookId, setWorkbookId] = useState<string>(null)
  const createWorkbook = useSmartMutation(SM_INIT_WORKBOOK)
  const { t } = useTranslation()

  const workbookUpdatedSubscription = useWorkbookUpdatedSubscriptionWithPollFallback(
    workbookId,
    workspaceId
  )
  const workbookStatus =
    workbookUpdatedSubscription.result?.status ||
    workbookUpdatedSubscription?.fallbackResult?.primaryWorkbook?.status

  const initializeWorkbook = useCallback(async () => {
    if (!workbookCancelled && existingWorkbookId && !matchesUpdated) {
      return onComplete(existingWorkbookId)
    }

    setWorkbookId(null)
    const workbook = await createWorkbook.run({
      batchId,
      mergeField,
      existingWorkbookId
    })
    setWorkbookId(workbook.id)
  }, [createWorkbook, setWorkbookId, workbookCancelled])

  useEffect(() => {
    // Worbook is ready to be viewed.
    if (
      [EWorkbookStatus.SUCCEEDED, EWorkbookStatus.IN_PROGRESS].includes(
        workbookStatus as EWorkbookStatus
      )
    ) {
      onComplete(workbookId)
    }
  }, [workbookStatus])

  const { location, history } = useReactRouter()
  const closeError = useCallback(() => {
    history.push(location.pathname)
  }, [])

  useEffect(() => {
    initializeWorkbook()
  }, [])

  const displayStatus = useMemo(() => {
    if (workbookStatus === EWorkbookStatus.QUEUED) {
      return t('processingData')
    }
    if (workbookUpdatedSubscription?.loading) {
      return t('preparingWorkbook')
    }

    return t('creatingWorkbook')
  }, [workbookUpdatedSubscription])

  if (workbookStatus === EWorkbookStatus.FAILED || createWorkbook.state?.error) {
    return (
      <SubmittedLoaderTryAgain
        message={t('failedToCreate')}
        onTryAgain={() => initializeWorkbook()}
        onClose={closeError}
      />
    )
  }

  return <SubmittedLoader status={displayStatus} />
}
