import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'
import {
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequest,
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequestVariables,
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequest_getDataHookExecutionsForTeamWithFiltersGroupedByRequest
} from './types/GetDataHookExecutionsForTeamWithFiltersGroupedByRequest'

export const SQ_DATAHOOK_EXECUTION_WITH_FILTERS: SmartQuery<
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequestVariables,
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequest,
  GetDataHookExecutionsForTeamWithFiltersGroupedByRequest_getDataHookExecutionsForTeamWithFiltersGroupedByRequest
> = {
  query: gql`
    query GetDataHookExecutionsForTeamWithFiltersGroupedByRequest(
      $teamId: Int!
      $take: Int
      $skip: Int
      $status: Int
      $fromDate: DateTime
      $toDate: DateTime
      $schemaId: Int
      $dataHookId: ID
      $environmentId: UUID!
    ) {
      getDataHookExecutionsForTeamWithFiltersGroupedByRequest(
        teamId: $teamId
        take: $take
        skip: $skip
        status: $status
        fromDate: $fromDate
        toDate: $toDate
        schemaId: $schemaId
        dataHookId: $dataHookId
        environmentId: $environmentId
      ) {
        data {
          createdAt
          infoCount
          warningCount
          rowCount
          executions {
            id
            eventId
            createdAt
            dataHookId
            errorCount
            infoCount
            warningCount
            partNumber
            executionOrder
            dataHook {
              id
              name
              schemaId
            }
            status
            requestId
            logGroupName
            logStreamName
            functionError
            errorMessage
            errorType
          }
          dataHooks {
            id
            name
            schemaId
            deploymentState
          }
          dataHooksCount
          eventId
          errorCount
          payload
          upload {
            id
            fileName
          }
          template {
            id
            name
          }
          workspace {
            id
            name
          }
        }
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
      }
    }
  `,
  extract({ getDataHookExecutionsForTeamWithFiltersGroupedByRequest }) {
    return getDataHookExecutionsForTeamWithFiltersGroupedByRequest
  }
}
