import { useCallback, useState } from 'react'

import { detach } from 'src/utils/promise'

export interface IResponse<T> {
  request?: {
    url: string
    init: RequestInit
  }
  loading: boolean
  ready: boolean
  ok?: boolean
  status?: number
  data?: T
}

const initialResponse = { ready: false, loading: false }

export const useFetch = <TInput, TResponse>(
  convertInput: (input: TInput) => [string, RequestInit],
  onResponse?: (response: IResponse<TResponse>) => void
): [(input: TInput) => void, IResponse<TResponse> | undefined, () => void] => {
  const [response, setResponse] = useState<IResponse<TResponse>>(initialResponse)

  const getJSON = async <T,>(res: Response): Promise<T | undefined> => {
    try {
      return await res.json()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const reset = useCallback(() => {
    setResponse(initialResponse)
  }, [setResponse])

  return [
    (input: TInput) =>
      detach(async () => {
        const [url, init] = convertInput(input)
        setResponse({ request: { url, init }, ready: false, loading: true })
        const res = await fetch(url, init)
        const data = await getJSON<TResponse>(res)
        const responseObject = {
          data,
          loading: true,
          ok: res.ok,
          ready: true,
          request: { url, init },
          status: res.status
        }
        setResponse(responseObject)
        if (onResponse) {
          onResponse(responseObject)
        }
      }),
    response,
    reset
  ]
}
