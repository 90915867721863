import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

interface Growable {
  grow?: boolean
}

export const TapArea = styled.div<Growable>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  height: 100%;
  justify-content: center;
  min-height: ${Spacing.basePadding4x};
  min-width: ${Spacing.basePadding4x};
  overflow: hidden;
  position: relative;

  i + span {
    padding: 0 0 0 4px;
  }

  &:after {
    background-size: 2000% 2000%;
    background: linear-gradient(45deg, ${Colors.brandPrimary}, ${Colors.brandSecondary});
    bottom: 0;
    content: '';
    display: none;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    &:after {
      animation: rainbow 5s ease infinite, fadeIn 0.2s ease-in forwards;
      display: block;
      mix-blend-mode: multiply;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes rainbow {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
`
