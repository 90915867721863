import { SmartResult } from 'src/smart/SmartQuery'
import { partition } from 'lodash'

//accepts Smart Results from Smart GQL queries and Boolean values

const isSmartResult = (
  arg: boolean | SmartResult<any, any, any>
): arg is SmartResult<any, any, any> => {
  return typeof arg === 'object'
}

export const isLoading = (...args: (boolean | SmartResult<any, any, any>)[]) => {
  const [resultArray, boolArray]: [SmartResult<any, any, any>[], boolean[]] = partition(
    args,
    isSmartResult
  )

  const loadingStateArray: boolean[] = resultArray.map((result) => result.state?.loading)

  return boolArray.concat(loadingStateArray).some(Boolean)
}
