import { gql } from '@apollo/client'

export const GET_WORKSPACES_WITH_FILTERING = gql`
  query GetWorkspacesWithFiltering(
    $teamId: ID!
    $environmentId: UUID
    $status: Int
    $take: Int
    $skip: Int
    $searchTerm: String
  ) {
    getWorkspacesWithFiltering(
      teamId: $teamId
      environmentId: $environmentId
      status: $status
      take: $take
      skip: $skip
      searchTerm: $searchTerm
    ) {
      pagination {
        currentPage
        limit
        nextOffset
        offset
        onPage
        pageCount
        previousOffset
        totalCount
      }
      data {
        id
        name
        status
        createdAt
        updatedAt
        lastActivity
      }
    }
  }
`
