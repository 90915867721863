import { useState } from 'react'

import { useModal } from 'src/resources/elements/Modal'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 16px;
  line-height: 24px;
`

export const useErrorModal = () => {
  const [message, setMessage] = useState('')

  const messageModal = useModal({
    header: 'Oops!',
    zIndex: 16000,
    contents: () => <Wrapper>{message || 'Something went wrong'}</Wrapper>
  })

  return [
    () => messageModal.render(),
    (_message: string) => {
      setMessage(_message)
      messageModal.open()
    }
  ] as [() => JSX.Element, (m: string) => void]
}
