import 'ace-builds'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-kuroir'
import 'ace-builds/src-noconflict/theme-twilight'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/ace'
import AceEditor from 'react-ace'
import { Header } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import { deserializeErrorsWithValues } from 'src/utils/schema-normalizer'
import styled from 'styled-components'
import { getFormContext } from 'src/resources/elements/form/Form'
import { BaseFormButtons } from 'src/resources/elements/form/SchemaModelInput'
import { useCallback, useRef, useState } from 'react'
import { ISchema } from 'src/types/interfaces/ISchema'

const StyledAceEditor = styled(AceEditor)`
  margin-left: -${Spacing.contentPadding};
  margin-right: -${Spacing.contentPadding};
  margin-top: ${Spacing.baseAndHalfPadding};
  width: calc(100% + 64px) !important;
  height: calc(100vh - 332px) !important;
`
const FlexyRow = styled.div`
  padding: ${Spacing.contentPadding} ${Spacing.contentPadding} 0;
  & > section {
    width: 100%;
  }
`
const InlineHeader = styled(Header)`
  display: inline-flex;
`

export const ButtonsWrapper = styled.div<{
  fullWidth?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}>`
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};
  margin-top: ${({ marginTop }) => (marginTop ? '16px' : '0')};

  button {
    height: 40px;
  }

  > * {
    margin-left: ${Spacing.basePadding2x};
  }
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const JsonSchemaCodeEditor = ({
  jsonSchema,
  jsonSchemaErrors,
  disabled
}: {
  jsonSchema: ISchema['jsonSchema']
  jsonSchemaErrors: string[]
  disabled: boolean
}) => {
  const formContext = getFormContext()
  const aceRef = useRef<AceEditor>()
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [jsonInvalid, setJsonInvalid] = useState(false)
  const [editorText, setEditorText] = useState(null)
  const jsonSchemaErrorsObj = deserializeErrorsWithValues(jsonSchemaErrors, jsonSchema)

  const updateValues = useCallback(
    (updatedValue) => {
      setEditorText(updatedValue)
      try {
        const updatedJsonSchema = JSON.parse(updatedValue)
        const hasContentChanged = JSON.stringify(updatedJsonSchema) !== JSON.stringify(jsonSchema)
        updateFormContext(updatedJsonSchema)
        setUnsavedChanges(hasContentChanged)
        formContext.setValue({ hasJsonChanges: hasContentChanged })
        setJsonInvalid(false)
      } catch {
        setUnsavedChanges(true)
        setJsonInvalid(true)
      }
    },
    [editorText]
  )

  const reload = useCallback(() => {
    aceRef?.current?.editor?.setValue(JSON.stringify(jsonSchema, null, '\t'))
  }, [jsonSchema])

  const getErrors = useCallback(() => {
    const annotations = aceRef?.current?.editor?.session?.getAnnotations()
    const hasSyntaxErrors = annotations?.some((annotation) => annotation.type === 'error')
    setJsonInvalid(hasSyntaxErrors)
  }, [editorText])

  const updateFormContext = (updatedJsonSchema: any) => {
    const { data } = formContext.value
    const { jsonSchema = { schema: {} } } = data
    formContext.setValue({
      data: {
        ...data,
        jsonSchema: { ...jsonSchema, schema: { ...updatedJsonSchema?.schema } }
      }
    })
  }

  return (
    <>
      <FlexyRow>
        <section>
          <HeaderRow>
            <InlineHeader>
              <h2>Schema JSON</h2>
            </InlineHeader>
            <ButtonsWrapper>
              <BaseFormButtons
                unsavedChanges={unsavedChanges}
                jsonInvalid={jsonInvalid}
                onClick={reload}
              />
            </ButtonsWrapper>
          </HeaderRow>
          <StyledAceEditor
            mode='json'
            theme='twilight'
            defaultValue={JSON.stringify(
              formContext?.value?.data?.jsonSchema ?? jsonSchema,
              null,
              '\t'
            )}
            onChange={(value) => {
              getErrors()
              updateValues(value)
            }}
            wrapEnabled={true}
            fontSize={14}
            ref={aceRef}
            showPrintMargin={false}
            readOnly={disabled}
          />
        </section>
        {jsonSchemaErrors.length ? (
          <section>
            <h2>Errors</h2>
            <StyledAceEditor
              mode='json'
              theme='kuroir'
              value={JSON.stringify(
                {
                  properties: jsonSchemaErrorsObj
                },
                null,
                '\t'
              )}
              wrapEnabled={true}
              fontSize={14}
              showGutter={false}
              readOnly={true}
            />
          </section>
        ) : null}
      </FlexyRow>
    </>
  )
}
