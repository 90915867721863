import { Colors } from 'src/resources/colors'

const SvgComponent = (props: any) => {
  const { fill = Colors.textAccent } = props
  const height = props.height ? props.height : 24
  const width = props.width ? props.width : 24
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' {...props}>
      <g fillRule='evenodd'>
        <path
          fill={fill}
          fillRule='nonzero'
          d='M6 8v12.5A1.5 1.5 0 007.5 22h9a1.5 1.5 0 001.5-1.5V8H6z'
        />
        <path
          fill={fill}
          d='M14 4.5V4a1 1 0 00-1-1h-2a1 1 0 00-1 1v.5H5.5A.5.5 0 005 5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5H14z'
        />
      </g>
    </svg>
  )
}

export default SvgComponent
