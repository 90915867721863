import { useEffect, useMemo, useState } from 'react'
import { Loader } from 'src/applications/Embed/fragments/Loader'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { Message } from 'src/resources/elements/Message'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { defaultProcessingStages } from 'src/resources/utils/loadingStages'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_UPLOAD } from 'src/smart/queries/SQ_GET_UPLOAD'
import { uploadUpdatedSubscription } from 'src/smart/queries/SQ_UPLOAD_UPDATES_SUBSCRIPTION'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

const ErrorHeader = styled.p`
  color: ${Colors.red};
  margin: 0 !important;
  font-size: ${fontSizes.type20};
  font-weight: 500;
`

const ErrorMessage = styled.p`
  max-width: 328px;
  font-size: ${fontSizes.type16};
  font-weight: 500;
  margin: ${Spacing.basePadding3x} 0;
`

const WideButtonContent = styled.div`
  padding: 0 ${Spacing.basePadding2x};
`

export const WorkspaceUploadRedirectorScene = ({ closeModal }: { closeModal: () => void }) => {
  /*
    Gets upload data and redirects to appropriate scene
    based on steps complete
    */

  const { workspaceId, uploadId } = useParams<{
    teamId: string
    workspaceId: string
    uploadId: string
  }>()
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()
  const [workspaceLoading, setWorkspaceLoading] = useState(false)

  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, {
    fetchPolicy: 'network-only',
    variables: { uploadId }
  })

  const updateUpload = uploadUpdatedSubscription(workspaceId)

  useEffect(() => {
    if (updateUpload?.updateType && updateUpload?.updateType === 'uploadInProgress') {
      uploadQuery.state.startPolling(1000)
    }
  }, [updateUpload])

  const nextAction = uploadQuery?.result?.nextAction?.name
  const percentComplete = !isNaN(uploadQuery?.result?.percentComplete)
    ? uploadQuery?.result?.percentComplete
    : 0
  const percentCompleteRounded = Math.round(percentComplete)

  useMemo(() => {
    if (percentCompleteRounded < 1) {
      setWorkspaceLoading(true)
    }
  }, [percentCompleteRounded])

  const baseURL = `${teamRoot}/workspaces/${workspaceId}`

  useEffect(() => {
    switch (nextAction) {
      case 'source':
        history.push(`${baseURL}/uploads/${uploadId}/source`)
        break
      case 'header':
        history.push(
          `${baseURL}/uploads/${uploadId}/sheet/${uploadQuery?.result?.selectedDataSourceIndex}/schema/${uploadQuery?.result?.nextAction?.schemaId}/header-row`
        )
        break
      case 'view':
        history.push(`${baseURL}/sheets/${uploadQuery?.result?.nextAction?.schemaId}`)
        break
      case 'match':
        history.push(
          `${baseURL}/uploads/${uploadId}/view/${uploadQuery?.result?.nextAction?.originalViewId}/match`
        )
        break
    }
  }, [nextAction])

  if (uploadQuery.alert) {
    return queryAlert(uploadQuery)
  }

  switch (nextAction) {
    case 'error':
      return (
        <Message center>
          <ErrorHeader>Upload error</ErrorHeader>
          <ErrorMessage>
            We were unable to process this file. You can try uploading it again or contact support
            for assistance.
          </ErrorMessage>
          <FormButton danger onClick={closeModal}>
            <WideButtonContent>Ok</WideButtonContent>
          </FormButton>
        </Message>
      )
    default:
      return workspaceLoading ? (
        <Loader
          displayLoadingCircle={percentCompleteRounded > 1}
          percentage={percentCompleteRounded}
          loadingStages={defaultProcessingStages}
        />
      ) : (
        <Loader message='Uploading your file...' />
      )
  }
}
