import ReactTooltip from 'react-tooltip'
import { Colors } from 'src/resources/colors'
import { NewBadge } from 'src/resources/sidebars/Menu'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

import { Spacing } from '../layout'

export const StyledTab = styled.div<{
  active?: boolean
  disabled?: boolean
  right?: boolean
  vertical?: boolean
}>`
  color: ${Colors.pigeon600};
  cursor: pointer;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type16};
  font-weight: 600;
  position: relative;
  text-align: center;
  transition: ${Transitions.baseEase};
  ${({ vertical }) =>
    vertical
      ? css`
          border-right: 0;
          margin: ${Spacing.basePadding};
          max-width: calc(100% - ${Spacing.basePadding2x});
          padding: ${Spacing.baseAndHalfPadding};
          text-align: left;
          width: 100%;
          p {
            margin-bottom: 0;
            margin-top: ${Spacing.halfBasePadding};
          }
        `
      : null}
  ${({ right }) =>
    right
      ? css`
          position: absolute;
          right: ${Spacing.basePadding4x};
        `
      : null}

  &::after {
    background: ${Colors.brandPrimary};
    bottom: 4px;
    content: '';
    display: ${({ vertical }) => (vertical ? 'none' : 'block')};
    height: 2px;
    position: relative;
    top: 10px;
    transition: ${Transitions.baseEase};
    z-index: 1;
    width: ${({ active, disabled, vertical }) =>
      active && !disabled && !vertical ? '100%' : '0'};
  }
  svg path {
    fill: ${Colors.textAccent};
    transition: ${Transitions.baseEase};
  }
  ${({ active, disabled, vertical }) =>
    active &&
    !disabled &&
    `
    ${vertical ? `border-right-color: ${Colors.brandPrimary};` : ``}
    color: ${Colors.brandPrimary};
    svg path {
      fill: ${Colors.brandPrimary}
    }
  `}

  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      color: ${Colors.brandPrimary};
  
      svg path {
        fill: ${Colors.brandPrimary};
      }
    }
`}
`

export const Tabstrip = styled.div<{
  bottomBorder?: boolean
  compact?: boolean
  center?: boolean
  justified?: boolean
  marginBottom?: boolean
  vertical?: boolean
}>`
  display: flex;
  user-select: none;
  position: relative;
  ${({ vertical }) =>
    vertical
      ? css`
          flex-direction: column;
          width: 140px;
        `
      : css`
          flex-direction: row;
          margin-top: 10px;
          padding: ${Spacing.basePadding} ${Spacing.contentPadding} 10px;

          ${StyledTab} + ${StyledTab} {
            margin-left: ${Spacing.basePadding4x};
          }

          ${StyledTab} p {
            margin: 0;
          }
        `};
  ${({ bottomBorder = false }) =>
    bottomBorder
      ? css`
          &::after {
            background-color: ${Colors.border};
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
            top: calc(100% - 1px);
            z-index: 0;
          }
        `
      : null}
  ${({ marginBottom }) =>
    marginBottom
      ? css`
          margin-bottom: ${Spacing.basePadding3x};
        `
      : null};
  ${({ justified }) =>
    justified
      ? css`
          justify-content: space-between;
        `
      : null}
  ${({ center }) =>
    center
      ? css`
          justify-content: center;
        `
      : null}
      ${NewBadge} {
    height: 50%;
  }
`

export const Tab = ({
  active,
  children,
  disabled,
  onClick,
  right,
  tooltip,
  tooltipType = 'info',
  uniqueId,
  vertical,
  ...props
}: {
  active?: boolean
  children?: any
  disabled?: boolean
  onClick?: (event: any) => void
  right?: boolean
  tooltip?: string
  tooltipType?: any
  uniqueId?: any
  vertical?: boolean
}) => {
  return (
    <StyledTab
      active={active}
      /* eslint-disable-next-line react/forbid-component-props */
      className={active ? 'tab--active' : ''}
      data-for={uniqueId}
      data-tip={tooltip}
      disabled={disabled}
      right={right}
      vertical={vertical}
      onClick={(event) => {
        if (disabled) {
          event.stopPropagation()
          event.preventDefault()
        } else {
          onClick?.(event)
        }
      }}
      {...props}
    >
      {children}
      {tooltip && (
        <ReactTooltip
          id={uniqueId}
          place='top'
          backgroundColor={Colors.brandPrimary}
          type={tooltipType}
          effect='float'
        />
      )}
    </StyledTab>
  )
}
