import { gql } from '@apollo/client'
import {
  DeleteOrganization,
  DeleteOrganizationVariables
} from 'src/smart/mutations/types/DeleteOrganization'
import { SmartMutation } from 'src/smart/SmartMutation'
import { IOrganization } from 'src/types/interfaces/IOrganization'

export const SM_DELETE_ORGANIZATION: SmartMutation<
  DeleteOrganizationVariables,
  DeleteOrganization,
  IOrganization
> = {
  mutation: gql`
    mutation DeleteOrganization($id: String!) {
      deleteOrganization(id: $id) {
        id
        name
      }
    }
  `,
  extract: (response) => response.deleteOrganization
}
