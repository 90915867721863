import { IJsonSchemaSchema, JsonSchemaProperty } from 'src/types/interfaces/ISchema'

import { BooleanSchemaProperty } from './properties/BooleanSchemaProperty'
import { EnumSchemaProperty } from './properties/EnumSchemaProperty'
import { SchemaProperty } from './properties/SchemaProperty'

/**
 * Abstraction of the JSON Schema class
 * should be used for all interaction with the schema definition
 */
export class Schema {
  constructor(private raw: IJsonSchemaSchema) {}

  /**
   * Return a virtualized property based on the key
   *
   * @param key
   * @returns
   */
  public getProperty(key: string): SchemaProperty | EnumSchemaProperty {
    const prop = this.raw.properties[key] as JsonSchemaProperty
    if (!prop) {
      throw new Error(`Property "${key}" not present in JSON Schema`)
    }

    switch (this.getType(prop)) {
      case EPropertyType.BOOLEAN:
        return new BooleanSchemaProperty(this, key, prop)
      case EPropertyType.ENUM:
        return new EnumSchemaProperty(this, key, prop)
      default:
        return new SchemaProperty(this, key, prop)
    }
  }

  /**
   * Using the index of the column in the table / schema, get the correlating key
   *
   * @param index
   * @returns
   */
  public getVisiblePropertyByIndex(index: number): SchemaProperty | EnumSchemaProperty {
    const visiblePropertiesAtReview = this.propertyNames.filter((property) =>
      this.propertyVisibleAtReview(property)
    )
    if (!visiblePropertiesAtReview[index]) {
      throw new Error(`No property found in schema at index "${index}"`)
    }
    return this.getProperty(visiblePropertiesAtReview[index])
  }

  /**
   * Using the index of the column in the table / schema, get the correlating key
   *
   * @param index
   * @returns
   */
  public getVisibleIndexByProperty(name: string): number {
    const visiblePropertiesAtReview = this.propertyNames.filter((property) =>
      this.propertyVisibleAtReview(property)
    )
    return visiblePropertiesAtReview.indexOf(name)
  }

  /**
   * @param propertyName
   */
  public propertyVisibleAtReview(propertyName: string) {
    if (!this.raw.properties[propertyName]) {
      throw new Error(`No property with name "${propertyName}"`)
    }
    return this.raw.properties[propertyName].visibility?.review === false ? false : true
  }

  /**
   * Return a list of the propertyNames as defined by the schema
   */
  get propertyNames() {
    return Object.keys(this.raw.properties)
  }

  /**
   * Based on our use of JSONSchema, return the field type
   */
  private getType(definition: JsonSchemaProperty): EPropertyType {
    if ('enum' in definition) {
      return EPropertyType.ENUM
    }

    if (definition?.type === 'boolean') {
      return EPropertyType.BOOLEAN
    }

    return EPropertyType.DEFAULT
  }
}

export enum EPropertyType {
  BOOLEAN = 'boolean',
  DEFAULT = 'default',
  ENUM = 'enum',
  NUMERIC = 'numeric',
  SCHEMA_REF = 'schema_ref'
}
