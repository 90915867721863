import { gql } from '@apollo/client'
import { useQuery } from '@apollo/client'
import {
  GetSchemaFunctions,
  GetSchemaFunctionsVariables
} from 'src/queries/types/GetSchemaFunctions'

// TODO: fetch all functions for use in sidebar
export const GET_SCHEMA_FUNCTIONS = gql`
  query GetSchemaFunctions($schemaId: ID!, $archived: Boolean!) {
    getSchema(schemaId: $schemaId) {
      getSchemaFunctions(archived: $archived) {
        archived
        createdAt
        functionMeta
        functionType
        id
        name
        order
        schemaId
        status
      }
    }
  }
`

export const getSchemaFunctions = (schemaId: string, archived: boolean) => {
  return useQuery<GetSchemaFunctions, GetSchemaFunctionsVariables>(GET_SCHEMA_FUNCTIONS, {
    variables: {
      archived,
      schemaId
    }
  })
}
