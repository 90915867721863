import { ReactNode } from 'react'
import { AppVersionUpdatedNotification } from 'src/applications/Oversight/components/AppVersionNotification'

import { HighLayer3 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import { IsParent } from 'src/types/interfaces/IsParent'
import styled from 'styled-components'

import { TopLevelPortal } from '../elements/TopLevelPortal'

export const MAX_RENDER_WIDTH_PX = 1920
export const SIDEBAR_WIDTH_PX = 320

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
`

export const Columns = styled(CenterFlex)`
  height: 100vh;
  width: 100%;
`

export const Container = styled(CenterFlex)`
  min-height: 100vh;
  min-width: 1200px;
  width: 100%;
`

export const Content = styled.div`
  flex-grow: 1;
  max-width: ${MAX_RENDER_WIDTH_PX - SIDEBAR_WIDTH_PX}px;
  width: 50%;
  &:empty {
    height: 0;
  }
`

const FixedArea = styled.div`
  display: flex;
  height: 0;
  justify-content: center;
  left: 0;
  overflow: visible;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${HighLayer3};
`

export const Sidebar = styled.div`
  height: 100%;
  pointer-events: all;
  position: relative;
  max-width: ${SIDEBAR_WIDTH_PX}px;
  min-width: ${SIDEBAR_WIDTH_PX}px;
`

interface HasSidebar {
  sidebar?: ReactNode
}

interface HasWrap {
  ContentWrap?: typeof Default.ContentWrap
  SidebarWrap?: typeof Default.SidebarWrap
}

interface HasBackgroundColor {
  backgroundColor?: string
}

const pad = { padding: '10px' }

const Default = {
  Content() {
    return (
      <div style={{ backgroundColor: 'green', height: '2000px', ...pad }}>
        <h1 style={{ margin: 0 }}>Default content</h1>
      </div>
    )
  },
  ContentWrap({ children }: IsParent) {
    return (
      <div
        style={{
          backgroundColor: 'blue',
          flexGrow: 1,
          maxWidth: `${MAX_RENDER_WIDTH_PX - SIDEBAR_WIDTH_PX}px`,
          minHeight: '100px',
          minWidth: '900px',
          ...pad
        }}
      >
        {children}
      </div>
    )
  },
  Sidebar() {
    return (
      <div
        style={{
          backgroundColor: 'white',
          bottom: '25px',
          left: '10px',
          position: 'absolute',
          right: '10px',
          top: '10px',
          ...pad
        }}
      >
        <h1 style={{ margin: 0 }}>Default sidebar</h1>
      </div>
    )
  },
  SidebarWrap({ children }: IsParent) {
    return (
      <div style={{ height: '100%', backgroundColor: 'yellow', padding: '10px' }}>{children}</div>
    )
  }
}

export const VariableWidthContentLayout = ({
  backgroundColor = Colors.dangerColor,
  children = <Default.Content />,
  ContentWrap = Default.ContentWrap,
  sidebar = <Default.Sidebar />,
  SidebarWrap = Default.SidebarWrap
}: IsParent & HasSidebar & HasWrap & HasBackgroundColor) => (
  <>
    <TopLevelPortal>
      <FixedArea>
        <Columns>
          <Sidebar>
            <SidebarWrap>{sidebar}</SidebarWrap>
          </Sidebar>
          <Content />
        </Columns>
      </FixedArea>
    </TopLevelPortal>
    <Container style={{ backgroundColor }} data-testid='main-content'>
      <Sidebar />
      <Content>
        <ContentWrap>
          <AppVersionUpdatedNotification />
          {children}
        </ContentWrap>
      </Content>
    </Container>
  </>
)
