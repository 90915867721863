import { gql } from '@apollo/client'
import {
  CreateSchemaFunction,
  CreateSchemaFunctionVariables
} from 'src/smart/mutations/types/CreateSchemaFunction'
import { SmartMutation } from 'src/smart/SmartMutation'
import { ISchemaFunction } from 'src/types/interfaces/ISchema'

export const SM_CREATE_SCHEMA_FUNCTION: SmartMutation<
  CreateSchemaFunctionVariables,
  CreateSchemaFunction,
  ISchemaFunction
> = {
  mutation: gql`
    mutation CreateSchemaFunction($schemaId: ID!, $order: Int!) {
      createSchemaFunction(schemaId: $schemaId, order: $order) {
        archived
        createdAt
        functionMeta
        functionType
        id
        name
        order
        schemaId
        status
      }
    }
  `,
  extract: (response) => response.createSchemaFunction
}
