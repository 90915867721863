import { useEffect } from 'react'

import { Breadcrumbs, IBreadcrumb } from 'src/applications/Oversight/components/Breadcrumbs'
import { UpgradeToDALBanner } from 'src/applications/Oversight/components/UpgradeToDALBanner'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { WorkspaceBatchTable } from 'src/applications/Oversight/components/WorkspaceBatchTable'
import { useAcceptWorkspaceInvitationModal } from 'src/applications/Oversight/hooks/useAcceptWorkspaceInvitationModal'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { TableOverflow } from 'src/applications/Oversight/panels/ImportListDataPanel'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { useParams } from 'src/resources/hooks/useParams'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'

export const WorkspaceOverviewPanel = ({ workspace }: { workspace?: IWorkspace }) => {
  const teamRoot = useTeamRootUrl()
  const activeArchivedTabs = useActiveArchivedTabs()
  const invitationToken = useSearchParam.string('invitationToken', '')
  const createDataRequestModal = useCreateDataRequestModal(workspace)
  const acceptWorkspaceInvitationModal = useAcceptWorkspaceInvitationModal(
    invitationToken,
    createDataRequestModal.open
  )
  const { workspaceId } = useParams<{ workspaceId: string }>()

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  useEffect(() => {
    if (invitationToken) {
      acceptWorkspaceInvitationModal.open()
    }
  }, [invitationToken])

  const links: IBreadcrumb[] = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}/`,
      label: workspace.name
    },
    {
      current: true,
      label: 'Files'
    }
  ]

  return (
    <>
      {createDataRequestModal.render()}
      {acceptWorkspaceInvitationModal.render()}

      <WorkspaceActionBar
        title={<Breadcrumbs links={links} />}
        workspaceId={workspace.id}
        inline
        noMargin={false}
        noPadding
        workspaceActive={!workspaceQuery?.result?.status}
      />
      <UpgradeToDALBanner workspace={workspace} />
      <Card>
        <PageHeaderContainer header='Files' />
        {activeArchivedTabs.tabs()}
        <TableOverflow fullHeight>
          <WorkspaceBatchTable workspace={workspace} deleted={activeArchivedTabs.showArchived} />
        </TableOverflow>
      </Card>
    </>
  )
}
