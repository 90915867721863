import { Colors } from 'src/resources/colors'
import styled from 'styled-components'

const Badge = styled.div<{ secondary: boolean }>`
  background-color: ${Colors.brandAccent};
  border-radius: 5px;
  border: 1px solid ${({ secondary }) => (secondary ? Colors.white : Colors.textAccent)};
  height: 7px;
  position: absolute;
  width: 7px;
`

const NotificationBadge = ({ secondaryBorder }: { secondaryBorder?: boolean }) => {
  return <Badge secondary={secondaryBorder} />
}

export default NotificationBadge
