import { useCallback } from 'react'

import { AddIcon } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import styled, { css } from 'styled-components'
import { DefaultTheme, themed } from 'src/applications/Embed/fragments/ThemeWrapper'

import { DataSourceField, IUpdateDataSourceField } from '../classes/DataSourceField'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  margin: 16px 0 0;
  border-top: 1px solid ${themed('borderColor')};
  box-sizing: border-box;
  font-weight: 400;
  font-size: ${themed('contentSmallFontSize')};
  line-height: 1.5;
  color: ${themed('textColor')};
  padding: 32px 20px 0;
  a {
    color: ${themed('primary')};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Fields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${Spacing.basePadding};
`

const Field = styled.button`
  background: ${themed('primaryAccent')};
  border-radius: 24px;
  border: none;
  color: ${themed('primary')};
  cursor: pointer;
  display: inline-block;
  font-size: ${themed('contentSmallFontSize')}
  font-weight: 500;
  margin: ${Spacing.baseAndHalfPadding} ${Spacing.baseAndHalfPadding} 0 0;
  padding: ${Spacing.halfBasePadding} ${Spacing.baseAndHalfPadding};
  ${({ theme }) =>
    theme.sage &&
    css`
      height: 40px;
      border-radius: 20px;
      padding: 8px 20px;
      color: white;
      background-color: #335c6d;
    `}
  ${({ theme }) =>
    theme.flexport &&
    css`
      height: 32px;
      border-radius: 32px;
      padding: 0 12px;
      color: #67768d;
      background-color: transparent;
      border: 1px solid #c5d2e7;
    `}

  span {
    margin-right: 8px;
  }

  svg {
    position: relative;
    top: -1px;
    path {
      stroke: currentColor;
      ${({ theme }) => (theme.sage ? 'stroke: white' : '')};
    }
  }
`

interface IExcludedBlock {
  dataSourceFields: DataSourceField[]
  onUpdate: (f: string | number, p: IUpdateDataSourceField) => Promise<void> | void
  onSelect: (f: number | null) => void
  theme: DefaultTheme
}

export function ExcludedBlock({ dataSourceFields, onUpdate, onSelect, theme }: IExcludedBlock) {
  const { t } = useTranslation()
  const handleOnSelect = useCallback<IExcludedBlock['onSelect']>(
    (fieldId) => {
      onSelect(fieldId)
    },
    [onSelect]
  )
  if (!dataSourceFields.length) {
    return null
  }
  return (
    <Wrapper>
      {dataSourceFields.length === 1
        ? t(theme.textOverrides.columnExcluded_one, {
            count: dataSourceFields.length
          })
        : t(theme.textOverrides.columnExcluded_other, {
            count: dataSourceFields.length
          })}
      <a
        href={'#'}
        onClick={async (e) => {
          e.preventDefault()
          await Promise.all(
            dataSourceFields.map((field) =>
              onUpdate(field.id, {
                ignored: false
              })
            )
          )
        }}
      >
        {t(theme.textOverrides.addAllLink)}
      </a>
      <Fields onMouseLeave={() => handleOnSelect(null)}>
        {dataSourceFields.map((field) => (
          <Field
            key={field.id}
            onMouseEnter={() => handleOnSelect(field.id)}
            onMouseOver={() => handleOnSelect(field.id)}
            onClick={async (e) => {
              e.preventDefault()

              await onUpdate(field.id, {
                ignored: false
              })
            }}
          >
            <span>{field.label}</span>
            <AddIcon />
          </Field>
        ))}
      </Fields>
    </Wrapper>
  )
}
