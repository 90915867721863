import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { WorkspaceUploadMatchingScene } from 'src/applications/Oversight/scenes/Concierge/WorkspaceUploadMatchingScene'
import { WorkspaceUploadRedirectorScene } from 'src/applications/Oversight/scenes/Concierge/WorkspaceUploadRedirectorScene'
import { WorkspaceUploadSourceHeaderRowScene } from 'src/applications/Oversight/scenes/Concierge/WorkspaceUploadSourceHeaderRowScene'

export const UploadSourceRoutes = withRouter(
  ({
    match: { path: uploadSourcePath },
    closeModal
  }: {
    closeModal: () => void
  } & RouteComponentProps) => {
    return (
      <Switch>
        <Route
          component={WorkspaceUploadSourceHeaderRowScene}
          exact
          path={`${uploadSourcePath}/header-row`}
        />
        <Route
          component={() => {
            return <WorkspaceUploadRedirectorScene closeModal={closeModal} />
          }}
          path={uploadSourcePath}
        />
      </Switch>
    )
  }
)

export const UploadRoutes = withRouter(
  ({
    match: { path: uploadPath },
    closeModal
  }: {
    closeModal: () => void
  } & RouteComponentProps) => {
    return (
      <Switch>
        <Route component={WorkspaceUploadMatchingScene} exact path={`${uploadPath}/match`} />
        <Route
          component={WorkspaceUploadMatchingScene}
          exact
          path={`${uploadPath}/view/:viewId/match`}
        />
        <Route
          component={() => <UploadSourceRoutes closeModal={closeModal} />}
          path={`${uploadPath}/sheet/:dataSourceIndex/schema/:schemaId`}
        />
        <Route
          component={() => <WorkspaceUploadRedirectorScene closeModal={closeModal} />}
          path={uploadPath}
        />
      </Switch>
    )
  }
)
