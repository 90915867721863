import { useContext, useState } from 'react'

import { Span } from '@sentry/types'
import {
  CreateWorkspaceForm,
  ICreateWorkspaceFormData
} from 'src/applications/Oversight/forms/CreateWorkspaceForm'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { IFormSubmitEvent } from 'src/resources/elements/form/Form'
import { useModal } from 'src/resources/elements/Modal'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { usePages } from 'src/resources/hooks/usePages'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_CREATE_DATA_REQUESTS } from 'src/smart/mutations/SM_CREATE_DATA_REQUESTS'
import { SM_CREATE_ORGANIZATION } from 'src/smart/mutations/SM_CREATE_ORGANIZATION'
import { SM_CREATE_WORKSPACE } from 'src/smart/mutations/SM_CREATE_WORKSPACE'
import { SQ_ORGANIZATIONS } from 'src/smart/queries/SQ_ORGANIZATIONS'
import { IOrganization } from 'src/types/interfaces/IOrganization'
import { mergedQueryResult } from 'src/utils/mergedQueryResult'
import styled, { CSSProperties, keyframes } from 'styled-components'
import useReactRouter from 'use-react-router'

import { useGetSchemas } from './useGetSchemas'
import { useTeamRootUrl } from './useTeamRootUrl'
import { LIVE_ENVIRONMENT_SLUG } from 'src/config'

const HeaderContainer = styled.div`
  background-color: ${Colors.gray20};
  border: 1px solid ${Colors.border};
  box-shadow: ${Shadows.light};
  border-radius: 0.5rem;
  display: flex;
  font-family: ${fontFamily.fontPrimary};
  font-weight: 500;
  margin-bottom: ${Spacing.basePadding4x};
  margin-left: -${Spacing.basePadding2x};
  margin-right: -${Spacing.basePadding2x};
  padding: ${Spacing.basePadding2x};

  svg {
    flex: 0 0 24px;
    margin-bottom: ${Spacing.basePadding};
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  p {
    margin: 0;
    & + p {
      font-size: ${fontSizes.type14};
      font-family: ${fontFamily.fontSecondary};
      font-weight: 400;
      margin-top: ${Spacing.halfBasePadding};
    }
  }
`

export const fadeIn = keyframes`
 0% { opacity: 0; transform: translateY(10px) }
 100% { opacity: 1; transform: translateY(0) }
`
const fadeRight = keyframes`
 0% { opacity: 0; transform: translateX(-10px) }
 100% { opacity: 1; transform: translateX(0) }
`

const FadeInDiv = styled.div`
  opacity: 0;
  transform: translateY(10px);
  animation: ${fadeIn} 0.3s forwards;
`

const FadeRightDiv = styled.div<{ delay?: string }>`
  opacity: 0;
  transform: translateX(10px);
  animation: ${fadeRight} 0.6s ${({ delay }) => (delay ? delay : '0.25s')} forwards;
`

const modalBackdropStyle = {
  backgroundColor: Colors.white,
  opacity: 1
}

const modalHeaderStyle: CSSProperties = {
  textAlign: 'center',
  fontSize: '32px',
  display: 'block'
}

const modalStyle = {
  paddingTop: '32px'
}

const CONTENT_WIDTH = '400px'
const MODAL_WIDTH = '100%'

export const useCreateWorkspaceModal = ({ onClose }: { onClose?(): void } = {}) => {
  const createWorkspaceModal = useModal({
    backdropStyle: modalBackdropStyle,
    contentAreaWidth: CONTENT_WIDTH,
    divider: true,
    fullscreen: true,
    header: 'Create workspace',
    headerStyle: modalHeaderStyle,
    modalStyle,
    width: MODAL_WIDTH,
    contents() {
      return <CreateWorkspace onCancel={createWorkspaceModal.close} />
    },
    onClose
  })

  return {
    open: createWorkspaceModal.open,
    render: createWorkspaceModal.render
  }
}

// Sentry performance tracking
const createWorkspaceSpans: Partial<Span>[] = [
  {
    op: 'create-workspace-loaded'
  },
  {
    op: 'create-workspace-onsubmit'
  },
  {
    op: 'create-workspace-success'
  }
]

const CreateWorkspace = ({ onCancel }: { onCancel?: () => void }) => {
  const { history } = useReactRouter()
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const teamRoot = useTeamRootUrl()
  const createWorkspace = useSmartMutation(SM_CREATE_WORKSPACE)
  const CreateOrganization = useSmartMutation(SM_CREATE_ORGANIZATION)
  const createDataRequests = useSmartMutation(SM_CREATE_DATA_REQUESTS)
  const organizations = useSmartQuery(SQ_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      environmentId: environment?.id,
      skip: 0,
      take: 1000,
      teamId: team.id
    }
  })
  const schemasQuery = useGetSchemas({}, { fetchPolicy: 'network-only' })

  const sentryTransaction = useSentryTransaction({
    name: '/workspaces',
    spans: createWorkspaceSpans
  })

  submitSentrySpan(sentryTransaction, 'create-workspace-loaded')

  const mergedQuery = mergedQueryResult(
    createWorkspace.state,
    CreateOrganization.state,
    organizations.state,
    schemasQuery.state
  )

  const [creating, setCreating] = useState(false)

  const onSubmit = async (event: IFormSubmitEvent<ICreateWorkspaceFormData>) => {
    submitSentrySpan(sentryTransaction, 'create-workspace-onsubmit')

    try {
      const formErrors = computeWorkspaceFormDataErrors(event.data)
      if (formErrors) {
        event.formContext.setValue({
          ...event.formContext.value,
          errors: formErrors
        })
        return
      }

      let organization: IOrganization = organizations.result.find(
        (item) => item.id === event.data.organizationId
      )

      const startTime = performance.now()
      setCreating(true)
      if (!organization) {
        organization = await CreateOrganization.run({
          name: event.data.organizationId,
          teamId: team.id,
          environmentId: environment?.id
        })
      }

      const createdWorkspace = await createWorkspace.run({
        organizationId: organization.id,
        name: event.data.name,
        teamId: team.id,
        environmentId: environment?.id
      })

      await createDataRequests.run({
        schemaIds: event.data.schemaIds,
        teamId: team.id,
        workspaceId: createdWorkspace.id
      })

      if (createdWorkspace && organization) {
        submitSentrySpan(sentryTransaction, 'create-workspace-success')
        sentryTransaction.transaction.finish()
      }

      const elapsedTime = performance.now() - startTime

      if (elapsedTime < 30000) {
        // TODO: Do we need this setTimeout?
        window.setTimeout(() => {
          history.push(`${teamRoot}/workspaces/${createdWorkspace.id}`)
        }, 3000 - elapsedTime)
      } else {
        history.push(`${teamRoot}/workspaces/${createdWorkspace.id}`)
      }
    } catch (error) {
      setCreating(false)
      throw error
    }
  }

  const pageContent = usePages([
    () => (
      <CreateWorkspaceForm
        schemas={schemasQuery.result?.data ?? []}
        initialValue={{ organizationId: '', name: '', schemaIds: [] }}
        organizations={organizations.result}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLiveEnvironment={environment?.slug === LIVE_ENVIRONMENT_SLUG}
      />
    )
  ])

  if (mergedQuery.alert || creating) {
    return QueryAlert({
      ...mergedQuery,
      loading: mergedQuery.loading || creating,
      carousel: true
    })
  }

  return (
    <>
      <HeaderContainer>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24' width='24'>
          <defs />
          <path
            fill={Colors.blue}
            d='M15.03,16.158a.249.249,0,0,1-.288-.022A2.724,2.724,0,0,0,13,15.5a2.75,2.75,0,1,0,2.054,4.559.251.251,0,0,1,.281-.068l2.272.909a.25.25,0,0,1,.157.246A2.744,2.744,0,1,0,20.5,18.5a2.723,2.723,0,0,0-2.055.941.252.252,0,0,1-.281.067L15.9,18.605a.251.251,0,0,1-.157-.253,2.058,2.058,0,0,0-.056-.638.25.25,0,0,1,.115-.268l2.665-1.6a.25.25,0,0,1,.289.022A2.724,2.724,0,0,0,20.5,16.5a2.75,2.75,0,1,0-2.687-2.211.251.251,0,0,1-.116.267Z'
          />
          <path
            fill={Colors.blue}
            d='M11.252,0C6.168,0,.765,1.751.753,4.993l0,.01v12.75c0,2.644,3.576,4.293,7.654,4.816a.984.984,0,0,0,1.11-.977v-.033a.986.986,0,0,0-.877-.967c-3.665-.461-5.887-1.835-5.887-2.839v-.789a.249.249,0,0,1,.373-.217A14.242,14.242,0,0,0,7.7,18.214a1,1,0,0,0,1.135-.789l.006-.025a.974.974,0,0,0-.812-1.148c-3.3-.53-5.278-1.8-5.278-2.749v-.788a.25.25,0,0,1,.374-.217c3.2,1.813,8.494,2.136,12.444,1.324a.952.952,0,0,0,.745-.775l0-.024a.976.976,0,0,0-1.153-1.144,19.669,19.669,0,0,1-3.914.374c-5.188,0-8.5-1.777-8.5-3V8.464a.25.25,0,0,1,.373-.218A17.574,17.574,0,0,0,11.252,10,17.587,17.587,0,0,0,19.38,8.247a.249.249,0,0,1,.372.216v.071a.986.986,0,0,0,.941.975h.021a.978.978,0,0,0,1.036-.983V5C21.752,1.755,16.343,0,11.252,0Zm8.5,5v.006C19.741,6.232,16.433,8,11.252,8s-8.5-1.777-8.5-3,3.311-3,8.5-3,8.495,1.773,8.5,3Z'
          />
        </svg>
        <FadeInDiv>
          <p>Shared workspace</p>
          <p>Collaborate on organization data by inviting your team or uploading data</p>
        </FadeInDiv>
      </HeaderContainer>
      <FadeRightDiv delay='0.1s'>{pageContent}</FadeRightDiv>
    </>
  )
}

export const computeWorkspaceFormDataErrors = (formData: ICreateWorkspaceFormData) => {
  let errors = {}
  if (!formData.organizationId || formData.organizationId.trim().length === 0) {
    errors = { ...errors, organizationId: 'Please select an organization' }
  }
  if (!formData.name || formData.name.trim().length === 0) {
    errors = { ...errors, name: 'Please enter a workspace name' }
  }
  if (!formData.schemaIds || formData.schemaIds.length === 0) {
    errors = { ...errors, schemaIds: 'Please select at least one data template' }
  }
  if (Object.keys(errors).length > 0) {
    return errors
  }
  return null
}
