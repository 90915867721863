import { ReactElement, useEffect } from 'react'
import { Colors } from 'src/resources/colors'
import { useModal } from 'src/resources/elements/Modal'
import styled from 'styled-components'

const InnerModalContainer = styled.div`
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto 0;
`

const InnerContentContainer = styled.div`
  display: flex;
  margin: 40px auto 0;
`

const CalendlyInlineWidget = styled.div`
  height: 600px;
  width: 400px;
`

const MeetingDescription = styled.div`
  font-size: 16px;
  width: 600px;
  padding-right: 20px;
  margin-top: 0;
  color: ${Colors.black};
  margin-bottom: 20px;
`

export const ScheduleCalendlyMeeting = ({
  calendlyUrl,
  children,
  linkText,
  immediatelyOpen
}: {
  calendlyUrl: string
  children?: ReactElement
  linkText?: string
  immediatelyOpen?: boolean
}) => {
  // add loading state
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head.appendChild(script)
    if (immediatelyOpen) {
      scheduleCallModal.open()
    }
  })
  const scheduleCallModal = useModal({
    contents: () => (
      <InnerModalContainer>
        <InnerContentContainer>
          <MeetingDescription>{children}</MeetingDescription>
          <CalendlyInlineWidget className='calendly-inline-widget' data-url={calendlyUrl} />
        </InnerContentContainer>
      </InnerModalContainer>
    ),
    fullscreen: true,
    minHeight: '100vh',
    width: '100vw',
    modalStyle: {
      backgroundColor: Colors.whiteDark
    },
    showCloseIcon: !immediatelyOpen
  })
  return (
    <div>
      <span onClick={scheduleCallModal.open}>{linkText ?? 'Schedule a call'}</span>
      {scheduleCallModal.render()}
    </div>
  )
}
