import { DefaultTheme } from 'src/applications/Embed/fragments/ThemeWrapper'

export const SageTheme: Partial<typeof DefaultTheme & { fontFamily: string }> = {
  showDataSourceStatus: false,
  showMatchIndicator: false,
  hideImportedCounter: false,
  placeholderColor: '#090B2B',
  rowHoverColor: '#E8EDF4',
  borderColor: '#CAD0DC',
  primary: '#007E45',
  primaryAccent: '#D6EAE4',
  secondary: '#007E45',
  textColor: '#090B2B',
  borderRadius: '32px',
  dropdownBorderRadius: 'unset',
  largeBorderRadius: 'unset',
  boxShadow: false,
  fontFamily: 'Sage UI, Proxima Nova, sans-serif !important',
  footerAlignment: 'flex-end',
  footerButtonHeight: 'initial',
  footerButtonWidth: 'initial',
  buttonHeight: '40px',
  buttonWeight: '500',
  buttonSize: '14px',
  buttonPadding: '9px 24px',
  buttonLineHeight: '20px',
  buttonLetterSpacing: '0.015em',
  buttonHover: '#006738',
  footerHeight: '56px',
  footerButtonPadding: '9px 24px',
  footerGutter: '32px',
  gutterSpacing: '32px',
  closeModalButtonStyles: { top: 30 },
  borderWidth: '2px',
  primaryBackgroundIcon: true,
  modalContentStyle: {
    background: '#FFF',
    boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Sage UI, Proxima Nova, sans-serif !important',
    display: 'flex',
    gap: '10px',
    marginBottom: '-24px',
    marginLeft: '-24px',
    marginRight: '-24px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingTop: '16px',
    justifyContent: 'flex-end',
    borderRadius: '8px'
  },
  modalHeaderStyles: {
    padding: '0 24px 24px 24px',
    fontFamily: 'Sage UI, Proxima Nova, sans-serif !important',
    borderBottom: '1px solid #E6EBED',
    marginRight: '-24px',
    marginLeft: '-24px',
    width: '115%'
  },
  modalHeaderTitleStyles: { fontWeight: 700, fontSize: 24 },
  modalPrimaryButtonStyle: {
    padding: '9px 24px'
  },
  modalSecondaryButtonStyle: {
    background: 'transparent !important',
    backgroundColor: 'transparent !important',
    border: '2px solid #007E45',
    color: '#007E45',
    padding: '9px 24px'
  },
  modalSecondaryDangerButtonStyle: {
    borderColor: '#c7384F',
    color: '#c7384F',
    hover: {
      backgroundColor: '#a22c3b',
      borderColor: '#a22c3b'
    },
    focus: {
      outline: '2px solid #ffb500',
      outlineOffset: '0px'
    }
  },
  modalBackgroundColor: '#FFF',
  modalMaxWidth: '440px',
  modalBorderRadius: '8px',
  titleFontSize: '24px',
  titleFontWeight: '700',
  titleFontColor: '#000',
  filterFirstBorderRadius: '8px 8px 0px 0px',
  filterSecondBorderRadius: '8px 8px 0px 0px',
  filterThirdBorderRadius: '8px 8px 0px 0px',
  finalFilterBorderRadius: '8px 8px 0px 0px',
  dataTable: {
    fontFamily: 'Sage UI, sans-serif',
    indexColumn: {
      backgroundColor: '#E6EBED',
      selected: {
        backgroundColor: 'rgba(0, 129, 93, 0.1)',
        color: 'rgba(0, 129, 93, 1)'
      }
    },
    table: {
      borderColor: '#D1D6DA'
    },
    cell: {
      borderColor: '#CBD0DB',
      active: {
        borderColor: '#00815D'
      },
      selected: {
        backgroundColor: 'rgba(0, 129, 93, 0.1)'
      }
    },
    column: {
      header: {
        backgroundColor: '#3D5A6E',
        color: '#fff'
      }
    },
    boolean: {
      toggleChecked: '#007E45',
      toggleError: 'red'
    }
  },
  textOverrides: {
    backToMatching: 'themes.sage.backToMatching',
    importRecords_one: 'themes.sage.importRecords_one',
    importRecords_other: 'themes.sage.importRecords_other',
    saveAndFinalize: 'themes.sage.saveAndFinalize',
    headerInitial: 'themes.sage.headerInitial',
    headerInitialDesc: 'themes.sage.headerInitialDesc',
    dragAndDropHint: 'themes.sage.dragAndDropHint',
    fileTypeHint: 'themes.sage.fileTypeHint',
    headerSource: 'themes.sage.headerSource',
    headerSourceDesc: 'themes.sage.headerSourceDesc',
    headerCheck: 'themes.sage.headerCheck',
    headerCheckDesc: 'themes.sage.headerCheckDesc',
    headerCheckAction: 'skip and use letters as headers.',
    headerMatch: 'themes.sage.headerMatch',
    headerMatchDesc: 'themes.sage.headerMatchDesc',
    headerReview: 'themes.sage.headerReview',
    headerReviewDesc: 'themes.sage.headerReviewDesc',
    templateFieldsLabel: 'themes.sage.templateFieldsLabel',
    ctaLabelFinal: 'themes.sage.ctaLabelFinal',
    rowFilter_allRows: 'themes.sage.rowFilter_allRows',
    rowFilter_valid: 'themes.sage.rowFilter_valid',
    rowFilter_error: 'themes.sage.rowFilter_error',
    discardModalHeader: 'themes.sage.discardModalHeader',
    discardModalDescriptionBeginning: 'themes.sage.discardModalDescriptionBeginning',
    discardModalDescription_one: 'themes.sage.discardModalDescription_one',
    discardModalDescription_other: 'themes.sage.discardModalDescription_other',
    discardModalDescriptionEnd: 'themes.sage.discardModalDescriptionEnd',
    allInvalidErrHeading: 'themes.sage.allInvalidErrHeading',
    allInvalidErrDescription: 'themes.sage.allInvalidErrDescription',
    loaderMessageImporting: 'themes.sage.loaderMessageImporting',
    successfulImport_one: 'themes.sage.successfulImport_one',
    successfulImport_other: 'themes.sage.successfulImport_other',
    finalViewImportedTab: 'themes.sage.finalViewImportedTab',
    invalidFileType: 'themes.sage.invalidFileType',
    columnExcluded_one: 'themes.sage.columnExcluded_one',
    columnExcluded_other: 'themes.sage.columnExcluded_other',
    addAllLink: 'themes.sage.addAllLink',
    numberSubmitted_one: 'themes.sage.numberSubmitted_one',
    numberSubmitted_other: 'themes.sage.numberSubmitted_other',
    finalViewReviewRejectedButton: 'themes.sage.finalViewReviewRejectedButton'
  },
  externalCSS: 'https://fonts.sage.com/sage.css'
}
