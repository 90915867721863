import { Colors } from 'src/resources/colors'

const colors = [
  `${Colors.purpleMuted}`,
  `${Colors.brandSecondary}`,
  `${Colors.dangerColor}`,
  `${Colors.yellow}`,
  `${Colors.blueLight}`,
  `${Colors.brandSuccess}`,
  `${Colors.blue}`,
  `${Colors.brandPrimary}`,
  `${Colors.orange}`,
  `${Colors.brandAccent}`
]

export const getCharCodes = (raw: string) => raw.split('').map((x) => x.charCodeAt(0))

export const sum = (acc: number, current: number) => acc + current

export const getStringColor = (raw: string) =>
  colors[getCharCodes(raw).reduce(sum, 0) % colors.length]
