import { ShowPrivateKeyNotice } from 'src/applications/Oversight/forms/CreatedEmbedForm'
import { InputContainer } from 'src/applications/Oversight/forms/EditEmbedForm'
import { Colors } from 'src/resources/colors'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Input } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { SmartEmbed_getEmbed } from 'src/smart/queries/types/SmartEmbed'
import styled from 'styled-components'

const ResetPrivateKeyButton = styled.button<{ disabled?: boolean }>`
  border: none;
  background: none;
  padding: 0;
  display: block;
  margin-left: auto;
  margin-top: ${Spacing.basePadding};
  font-size: ${fontSizes.type13};
  color: ${({ disabled }) => (disabled ? Colors.grayText : Colors.brandPrimary)};
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export interface EmbedFunction {
  autoSave?: () => void
  confirmModal?: () => void
}

export const EmbedKeys = ({
  disabled,
  embed,
  showPrivateKey,
  embedFunctions,
  schemaName,
  isEditable,
  embedPrivateKey,
  newSDK
}: {
  disabled?: boolean
  embed: Partial<SmartEmbed_getEmbed>
  showPrivateKey: boolean
  embedFunctions?: EmbedFunction
  schemaName: string
  isEditable?: boolean
  newSDK?: boolean
  embedPrivateKey?: string
}) => {
  return (
    <>
      {isEditable ? (
        <>
          <InputContainer>
            <Input
              disabled={disabled || newSDK}
              onBlur={embedFunctions?.autoSave}
              label='Name'
              name='name'
              initialValue={embed.name}
              placeholder='Example Portal name'
              required
              data-testid='portal-name'
            />
            <ErrorMessage name='name' />
          </InputContainer>

          <InputContainer>
            <Input
              disabled
              autoSelect
              fullWidth
              readOnly
              label='Data template'
              name='schemaName'
              initialValue={schemaName}
              data-testid='data-template-name'
            />
          </InputContainer>
        </>
      ) : null}

      <InputContainer>
        <Input
          disabled={disabled}
          autoSelect
          copyButton
          fullWidth
          readOnly
          label='Embed ID'
          name='id'
          initialValue={embed.id}
          data-testid='portal-id'
        />
      </InputContainer>

      {showPrivateKey && embedPrivateKey ? (
        <>
          <ShowPrivateKeyNotice data-testid='show-warning-state' />
          <InputContainer data-testid='show-private-key-container'>
            <Input
              disabled={disabled}
              autoComplete='off'
              autoSelect
              copyButton
              fullWidth
              readOnly
              label='Private Key'
              name='embedPrivateKey'
              formValue={embedPrivateKey}
              data-testid='portal-private-key'
            />
          </InputContainer>
        </>
      ) : (
        <>
          <InputContainer data-testid='hide-private-key-container'>
            <Input
              autoComplete='off'
              disabled={disabled}
              readOnly
              type='password'
              label='Private Key'
              name='privateKey'
              placeholder='**************************************'
              data-testid='portal-private-key'
            />
          </InputContainer>
        </>
      )}
      {embedFunctions?.confirmModal ? (
        <ResetPrivateKeyButton
          disabled={disabled}
          type='button'
          onClick={embedFunctions.confirmModal}
          data-testid='reset-private-key'
        >
          Reset Private Key
        </ResetPrivateKeyButton>
      ) : null}

      <InputContainer>
        <Input
          disabled={disabled}
          type='textarea'
          fullWidth
          helperText='Use markdown for formatting. This will appear in the sidebar of the Portal'
          onBlur={embedFunctions?.autoSave}
          label='Help Content'
          name='helpContent'
          initialValue={embed.helpContent}
          placeholder='Add help content to your Portal'
          data-testid='portal-help'
        />
      </InputContainer>
    </>
  )
}
