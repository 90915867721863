import { gql } from '@apollo/client'

export const EMAIL_LOGIN_SUBSCRIPTION = gql`
  subscription EmailLogin($eventId: UUID!) {
    emailLoginApproved(eventId: $eventId) {
      accessToken
      user {
        email
        id
        intercomHash
        jobTitle
        name
        superAdmin
      }
    }
  }
`
