import { ReactNode } from 'react'

import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import styled from 'styled-components'

const Outer = styled.div<{ transitionStatus: TransitionStatus }>`
  height: ${({ transitionStatus }) => (transitionStatus === 'entered' ? '600px' : 0)};
  overflow: hidden;
  position: relative;
  transition: 0.5s height ease;
`

const Inner = styled.div`
  position: absolute;
  inset: 0;
`

export const Expand = ({
  visible,
  children,
  timeout = 0
}: {
  visible: boolean
  children: ReactNode
  timeout?: number
}) => (
  <Transition in={visible} timeout={timeout}>
    {(state) => (
      <Outer transitionStatus={state}>
        <Inner>{children}</Inner>
      </Outer>
    )}
  </Transition>
)
