import { gql } from '@apollo/client'
import {
  UpdateWorkspace,
  UpdateWorkspaceVariables
} from 'src/smart/mutations/types/UpdateWorkspace'
import { SmartMutation } from 'src/smart/SmartMutation'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

export const SM_UPDATE_WORKSPACE: SmartMutation<
  UpdateWorkspaceVariables,
  UpdateWorkspace,
  IWorkspace
> = {
  mutation: gql`
    mutation UpdateWorkspace(
      $workspaceId: ID!
      $teamId: ID!
      $organizationId: UUID
      $name: String
    ) {
      updateWorkspace(
        workspaceId: $workspaceId
        teamId: $teamId
        organizationId: $organizationId
        name: $name
      ) {
        id
        name
        createdAt
        updatedAt
        lastActivity
        status
        primaryWorkbookId
        dataEngine
        secureEnv {
          variables
          signedKeys
        }
        primaryWorkbook {
          status
        }
      }
    }
  `,
  extract: (response) => response.updateWorkspace
}
