import { Span } from '@sentry/types'
import { useContext, useMemo, useState } from 'react'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FileUploadContext, IFileUploadState } from 'src/contexts/FileUploadContext'
import { useInitializeBatchAndUploadMutation } from 'src/queries/hooks/useInitializeBatchAndUploadMutation'
import { useUpdateUploadStatusMutation } from 'src/queries/hooks/useUpdateUploadStatusMutation'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'
import { GetBatches_getBatches_data_views } from 'src/queries/types/GetBatches'
import { IBatchUploadResponse } from 'src/types/interfaces/IBatch'
import axios from 'axios'
import { join } from 'lodash'
import { getSignedUrlHeaders } from 'src/utils/getSignedUrlHeaders'
import { IModal } from 'src/resources/elements/Modal'
import { isValidFileSize } from 'src/utils/validateFileSize'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import useReactRouter from 'use-react-router'
import { getContentTypeFromSignedUrl } from 'src/utils/contentType'
import { useTranslation } from 'react-i18next'

// Sentry performance tracking
const uploadFileSpans: Partial<Span>[] = [
  {
    op: 'upload-file-loaded'
  },
  {
    op: 'upload-file-onsubmit'
  },
  {
    op: 'upload-file-success'
  }
]

interface IUseUploadFile {
  cancel: () => void
  onUpload: ({ file }: { file: File }) => Promise<Error | IBatchUploadResponse>
  onMultipleUpload: (files: FileList) => void
  FailedUploadModal: IModal
}

export const useUploadFile = (
  workspaceId?: string,
  onComplete?: () => void,
  schemaId?: string,
  batchId?: string
): IUseUploadFile => {
  const { t } = useTranslation()
  const [cancellationIndex, setCancellationIndex] = useState(0)
  const cancelController = useMemo(() => new AbortController(), [cancellationIndex])
  const currentPage = useSearchParam.string('page', '1')
  const { history, location } = useReactRouter()

  const { setFileUploadState, setBatchViews } = useContext(FileUploadContext)
  const [failedUploads, setFailedUploads] = useState<string[]>([])
  const [initializeBatchAndUpload] = useInitializeBatchAndUploadMutation(batchId)
  const [updateUploadStatus] = useUpdateUploadStatusMutation()

  const sentryTransaction = useSentryTransaction({
    name: `workspaces/upload`,
    spans: uploadFileSpans
  })

  const FailedUploadModal = useConfirmModal({
    showCancelButton: false,
    header: t('components.Dropzone.uploadFailedHeader'),
    onConfirm: () => {
      setFailedUploads([])
      FailedUploadModal.close()
    },
    contents: (
      <>
        {t('components.Dropzone.uploadFailedContent', {
          fileName: join(failedUploads, ', '),
          count: failedUploads.length
        })}
      </>
    )
  })

  submitSentrySpan(sentryTransaction, 'upload-file-loaded')

  const onMultipleUpload = async (files: FileList) => {
    const fileList = Array.from(files)
    // keep valid file names to pass to actual upload flow
    const validFiles = fileList.filter((file) => isValidFileSize(file))
    // filter invalid names to pass to error modal
    const invalidFileNames = fileList
      .filter((file) => !isValidFileSize(file))
      .map((file) => file.name)
    setFailedUploads(invalidFileNames)
    FailedUploadModal.open()
    const [response] = await Promise.all(Array.from(validFiles).map((file) => onUpload({ file })))
    if (response instanceof Error) {
      throw new Error(response.message)
    }
  }

  const onUpload = async ({ file }: { file: File }): Promise<Error | IBatchUploadResponse> => {
    if (!isValidFileSize(file)) {
      setFailedUploads([...failedUploads, file.name])
      FailedUploadModal.open()
      return
    }

    if (sentryTransaction?.activeTransaction) {
      submitSentrySpan(sentryTransaction, 'upload-file-onsubmit')
    }

    if (currentPage !== '1' && workspaceId) {
      history.push(location.pathname)
    }

    const initializeFileExecutionResult = await initializeBatchAndUpload({
      variables: {
        schemaId: schemaId ?? null,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        workspaceId,
        batchId: batchId ?? null
      }
    })

    const uploadId = initializeFileExecutionResult.data.initializeBatchAndUpload.upload.id

    if (initializeFileExecutionResult.errors && initializeFileExecutionResult.errors.length) {
      return new Error(
        `Error initializing file upload: ${initializeFileExecutionResult.errors
          .map((err) => err.message)
          .join(', ')}`
      )
    }

    const urls = initializeFileExecutionResult.data.initializeBatchAndUpload.signedUrls
    let numFinished = 0
    let percent = 0
    const uploadData = async (data: Blob, signedUrl: string): Promise<string> => {
      try {
        const headers = getSignedUrlHeaders(signedUrl)
        // for GCS, content type will not come back from the signedUrl
        // this means we have to set this ourselves
        // additionally, GCS requires a lower case 'content-type':
        if (signedUrl.match('storage.googleapis.com')) {
          // the signedURL will contain the files extension:
          headers['content-type'] = getContentTypeFromSignedUrl(signedUrl)
        }
        const response = await axios({
          signal: cancelController.signal,
          method: 'PUT',
          headers,
          data: data,
          url: signedUrl,
          onUploadProgress: (progressEvent: any) => {
            const newPercent =
              (numFinished * 100) / urls.length +
              Math.round((progressEvent.loaded * (100 / urls.length)) / progressEvent.total)
            percent = Math.max(percent, newPercent)
            setFileUploadState((prevState: { [key: string]: IFileUploadState }) => {
              return {
                ...prevState,
                [uploadId]: { percent, workspaceId }
              }
            })
          }
        })
        numFinished++
        if (numFinished === urls.length) {
          setFileUploadState((prevState: { [key: string]: IFileUploadState }) => {
            return {
              ...prevState,
              [uploadId]: { percent: 100, workspaceId }
            }
          })
        }
        return response.headers.etag
      } catch (e) {
        if (initializeFileExecutionResult.data.initializeBatchAndUpload?.upload?.id) {
          const uploadStatusResponse = await updateUploadStatus({
            variables: {
              uploadId: initializeFileExecutionResult.data.initializeBatchAndUpload.upload.id,
              status: 'failed',
              failureReason: e.message
            }
          })
          setBatchViews((prevState: { [key: string]: GetBatches_getBatches_data_views[] }) => {
            return {
              ...prevState,
              [initializeFileExecutionResult.data.initializeBatchAndUpload?.batch?.id]: [
                uploadStatusResponse?.data?.updateUploadStatus?.view
              ]
            }
          })
        }

        setFileUploadState((prevState: { [key: string]: IFileUploadState }) => {
          return {
            ...prevState,
            [uploadId]: {
              ...prevState[uploadId],
              finished: true
            }
          }
        })

        throw new Error(`Error uploading file data: ${e.message}`)
      }
    }

    const parts: Promise<string>[] = []
    if (urls.length > 1) {
      urls.forEach((url, index) => {
        parts.push(
          uploadData(
            file.slice((index * file.size) / urls.length, ((index + 1) * file.size) / urls.length),
            url
          )
        )
      })
    } else {
      await uploadData(file, initializeFileExecutionResult.data.initializeBatchAndUpload.signedUrl)
    }

    const { batch, upload } = initializeFileExecutionResult.data.initializeBatchAndUpload

    if (sentryTransaction?.activeTransaction && batch && upload) {
      submitSentrySpan(sentryTransaction, 'upload-file-success')
      sentryTransaction.transaction.finish()
    }

    const uploadStatusResponse = await updateUploadStatus({
      variables: { uploadId: upload.id, status: 'uploaded', parts: await Promise.all(parts) }
    })
    setBatchViews((prevState: { [key: string]: GetBatches_getBatches_data_views[] }) => {
      return {
        ...prevState,
        [batch.id]: [uploadStatusResponse.data.updateUploadStatus.view]
      }
    })

    setFileUploadState((prevState: { [key: string]: IFileUploadState }) => {
      return {
        ...prevState,
        [uploadId]: {
          ...prevState?.[uploadId],
          finished: true
        }
      }
    })

    if (onComplete) {
      onComplete()
    }

    return {
      batchId: batch.id,
      uploadId: upload.id,
      viewId: uploadStatusResponse.data.updateUploadStatus.view.id
    }
  }

  function cancel() {
    cancelController.abort()
    setCancellationIndex(cancellationIndex + 1)
    setFileUploadState({})
  }

  return { cancel, onUpload, onMultipleUpload, FailedUploadModal }
}
