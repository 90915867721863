import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const EmptyState = styled.div`
  width: 360px;
  margin: 10vh auto;
  padding: 40px;
  background: ${Colors.white};
  box-shadow: ${Shadows.large};
  border-radius: 4px;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const EmptyStateBlock = styled.div<{ margin?: boolean }>`
  background-color: ${Colors.white};
  border-bottom-left-radius: ${Spacing.halfBasePadding};
  border-bottom-right-radius: ${Spacing.halfBasePadding};
  display: flex;
  margin-top: ${({ margin = true }) => (margin ? Spacing.basePadding2x : '0')};
  overflow: hidden;
`

export const EmptyStateContent = styled.div`
  flex-basis: calc(50% + 48px);
  padding: ${Spacing.basePadding6x} ${Spacing.basePadding6x} ${Spacing.basePadding7x};
`
export const EmptyStateGraphic = styled.div`
  position: relative;
  img {
    position: absolute;
  }
`

export const EmptyTitle = styled.h1`
  font-size: ${fontSizes.type28};
  font-weight: 500;
  margin: 0;
`

export const EmptyDescription = styled.div`
  color: ${Colors.textAccent};
  font-size: ${fontSizes.type18};
  line-height: 1.6;
  margin-top: ${Spacing.basePadding2x};
  max-width: 500px;
  p {
    margin: 0;
    & + p {
      margin-top: ${Spacing.basePadding};
    }
  }
`

export const EmptyTableMessage = styled.td`
  font-weight: 500;
  padding: ${Spacing.contentPadding} !important;
  padding-top: ${Spacing.basePadding5x} !important;
  text-align: center;
`

const ArrowWrapper = styled.svg`
  margin-left: ${Spacing.basePadding};
  position: relative;
  top: 2px;
  transition: all 0.2s ease-out;
`

export const Arrow = () => {
  return (
    <ArrowWrapper xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16'>
      <defs />
      <path
        fill='currentColor'
        d='M23.707,11.291,18.929,6.513a1,1,0,0,0-1.707.707v3.028a.25.25,0,0,1-.25.25H1.5a1.5,1.5,0,0,0,0,3H16.972a.25.25,0,0,1,.25.25v3.028a1,1,0,0,0,1.707.707l4.778-4.778a1,1,0,0,0,0-1.414Z'
      />
    </ArrowWrapper>
  )
}

const ActionWrapper = styled.div<{
  bgColor?: string
  border?: string
  color?: string
  icon?: boolean
}>`
  background-color: ${({ bgColor = 'inherit' }) => bgColor};
  border-radius: 0.25rem;
  border: 1px solid ${({ border = Colors.brandPrimary }) => border};
  color: ${({ color = Colors.brandPrimary }) => color};
  cursor: pointer;
  display: inline-block;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type17};
  letter-spacing: 0.0125em;
  margin-top: ${Spacing.basePadding3x};
  padding: 14px ${Spacing.basePadding2x};
  text-decoration: none;
  transition: background-color 0.2s ease-out;
  white-space: pre;
  &:hover {
    border: 1px solid
      ${(props) => (props.bgColor ? Colors.grayLightWithCharacter : Colors.transparent)};
    background-color: ${(props) => (props.bgColor ? 'inherit' : Colors.brandPrimary)};
    color: ${({ bgColor = Colors.white }) => bgColor};
    svg {
      transform: translateX(2px);
    }
  }
`

export const EmptyAction = ({
  bgColor,
  border,
  color,
  icon = true,
  label
}: {
  bgColor?: string
  border?: string
  color?: string
  icon?: boolean
  label: string
}) => {
  return (
    <ActionWrapper bgColor={bgColor} border={border} color={color}>
      {label}
      {icon && <Arrow />}
    </ActionWrapper>
  )
}
