import { useQuery } from '@apollo/client'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import WorkspaceAvatar from 'src/applications/Oversight/components/WorkspaceAvatar'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { GET_USERS_FOR_WORKSPACE } from 'src/queries/GET_USERS_FOR_WORKSPACE'
import {
  GetUsersForWorkspace,
  GetUsersForWorkspaceVariables
} from 'src/queries/types/GetUsersForWorkspace'
import { Colors } from 'src/resources/colors'
import { GroupAvatar } from 'src/resources/elements/Avatar'
import { ClickableTR } from 'src/resources/elements/Table'
import { getStringColor } from 'src/resources/utils/getStringColor'
import { ITask } from 'src/types/interfaces/ITask'
import useReactRouter from 'use-react-router'

interface IWorkspaceSectionRow {
  avatarColor?: string
  id: string
  members?: string[]
  tasks?: ITask[]
  name: string
  lastActivity: string
}

export const WorkspaceSectionRow = ({ id, name, lastActivity }: IWorkspaceSectionRow) => {
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()

  const { data, loading } = useQuery<GetUsersForWorkspace, GetUsersForWorkspaceVariables>(
    GET_USERS_FOR_WORKSPACE,
    {
      variables: {
        workspaceId: id
      }
    }
  )

  if (loading) {
    return null
  }

  const goToWorkspace = (workspace: string) => () =>
    history.push(`${teamRoot}/workspaces/${workspace}`)

  const OrganizationsCell = () => <>{data?.getDefaultOrganizationForWorkspace?.name}</>

  const CollaboratorsCell = () => {
    if (data?.getUsersForWorkspace) {
      return data.getUsersForWorkspace.length ? (
        <GroupAvatar
          names={data.getUsersForWorkspace.map((x, _y) => x.name)}
          borderColor={Colors.white}
          showTooltips
        />
      ) : (
        <>No collaborators</>
      )
    } else {
      return null
    }
  }

  return (
    <ClickableTR
      onClick={goToWorkspace(id)}
      changeBorderOnHover
      data-testid='workspace-section-row-container'
    >
      <td>
        <WorkspaceAvatar
          bgColor={getStringColor(id)}
          workspaceName={name}
          showFullName
          type='table'
        />
      </td>
      <td>
        <OrganizationsCell />
      </td>
      <td>
        <CollaboratorsCell />
      </td>
      <td align='right'>{formatDistanceToNow(new Date(Number(lastActivity)))} ago</td>
    </ClickableTR>
  )
}
