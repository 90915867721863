import { TabAndContents } from 'src/applications/Oversight/hooks/useTabs'
import { Colors } from 'src/resources/colors'
import { SettingsIcon } from 'src/resources/elements/Icons'
import { NewStyledTable } from 'src/resources/elements/Table'
import { Spacing } from 'src/resources/layout'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'

import { EmptyState } from '../../ImportDataPanel'

const FieldName = styled.td``

const DataCheckListTable = styled(NewStyledTable)`
  margin: 0;
  th:first-of-type,
  td:first-of-type {
    padding-left: ${Spacing.basePadding2x};
  }
  td {
    white-space: normal;
    vertical-align: top;
    line-height: 1.4;
  }

  ${FieldName} {
    font-weight: bold;
    white-space: pre;
  }
`

const DataChecklistTableHeader = styled.thead`
  background: #f3f5f9;

  th {
    color: #3f425c;
  }
`

const Container = styled.div`
  padding: 30px;
`

const Code = styled.div`
  background: #f3f5f9;
  display: inline-block;
  font-family: monospace;
  font-size: 14px;
  font-weight: 600;
  padding: 0 5px;
  border-radius: 3px;
`
const INSTRUCTIONS = `Use the Embed JWT or an API callback to configure signed environment variables for this workspace.`
export const SecureEnvTab: TabAndContents<{
  teamId: string
  workspace: IWorkspace
  refetchWorkspace(): Promise<void>
}> = {
  id: 'env',
  label: 'Environment',
  contents({ workspace }) {
    const vars = workspace.secureEnv?.variables ?? {}
    const signed: string[] = workspace.secureEnv?.signedKeys ?? []

    return (
      <Container>
        {workspace.secureEnv ? (
          <div>
            <div
              style={{
                paddingBottom: Spacing.basePadding5x,
                color: Colors.text,
                maxWidth: '200px'
              }}
            >
              {INSTRUCTIONS}
            </div>
            <DataCheckListTable>
              <DataChecklistTableHeader>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Signed</th>
                </tr>
              </DataChecklistTableHeader>
              <tbody>
                {Object.keys(vars).map((v) => (
                  <tr key={v}>
                    <FieldName>{v}</FieldName>
                    <td>
                      <Code>{JSON.stringify(vars[v])}</Code>
                    </td>
                    <td>{signed.find((s) => s === v) && '🔐 Signed by JWT'}</td>
                  </tr>
                ))}
              </tbody>
            </DataCheckListTable>
          </div>
        ) : (
          <EmptyState>
            <SettingsIcon
              style={{ width: '64px', marginBottom: '24px' }}
              fill={Colors.pigeon400}
            />
            <p
              style={{
                maxWidth: '440px',
                padding: `${Spacing.basePadding2x} 0 ${Spacing.basePadding10x}`,
                textAlign: 'center',
                color: Colors.pigeon700
              }}
            >
              {INSTRUCTIONS}
            </p>
          </EmptyState>
        )}
      </Container>
    )
  }
}
