import { useCallback, useContext } from 'react'

import { Breadcrumbs } from 'src/applications/Oversight/components/Breadcrumbs'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useTabs } from 'src/applications/Oversight/hooks/useTabs'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { BasicInfoTab } from 'src/applications/Oversight/panels/Concierge/WorkspaceSettingsTabs/BasicInfoTab'
import { GraphQLTab } from 'src/applications/Oversight/panels/Concierge/WorkspaceSettingsTabs/GraphQLTab'
import { SFTPTab } from 'src/applications/Oversight/panels/Concierge/WorkspaceSettingsTabs/SFTPTab'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { useParams } from 'src/resources/hooks/useParams'
import { WorkspaceActionBarSkeleton } from 'src/resources/skeletons/WorkspaceActionBarSkeleton'
import { WorkspaceSettingsBasicInfoSkeleton } from 'src/resources/skeletons/WorkspaceSettingsBasicInfoSkeleton'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'

import { SecureEnvTab } from './WorkspaceSettingsTabs/SecureEnvTab'
import { TeamContext } from 'src/contexts/TeamContext'

export const WorkspaceSettingsPanel = () => {
  const { workspaceId, teamId } = useParams<{ workspaceId: string; teamId: string }>()
  const teamRoot = useTeamRootUrl()
  const team = useContext(TeamContext)
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const { refetch: refetchWorkspaceQuery } = workspaceQuery.state

  const refetchWorkspace = useCallback(async () => {
    await refetchWorkspaceQuery()
  }, [refetchWorkspaceQuery])

  const showSkeletons = !workspaceQuery.result

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      current: true,
      label: 'Workspace settings'
    }
  ]

  const showSFTPTab = team.featureFlags?.FEATURE_WORKSPACE_SFTP
  const showGraphQLTab = team.featureFlags?.WORKSPACES_GRAPHQL

  const workspaceSettingsTabs = useTabs({
    tabs: [BasicInfoTab]
      .concat(showGraphQLTab ? [GraphQLTab] : [])
      .concat(showSFTPTab ? [SFTPTab] : [])
      .concat([SecureEnvTab])
  })

  const workspaceActionBar = showSkeletons ? (
    <WorkspaceActionBarSkeleton hasAvatars={true} />
  ) : (
    <WorkspaceActionBar
      title={<Breadcrumbs links={links} />}
      workspaceId={workspaceId}
      inline
      noMargin={false}
      noPadding
      workspaceActive={!workspaceQuery?.result?.status}
    />
  )

  const workspaceSettings = showSkeletons ? (
    <WorkspaceSettingsBasicInfoSkeleton />
  ) : (
    <Card>
      <PageHeaderContainer
        header='Workspace settings'
        description='Collaborate on customer data by inviting your team or uploading data.'
        hasMargin={true}
      />
      <workspaceSettingsTabs.render
        teamId={teamId}
        refetchWorkspace={refetchWorkspace}
        workspace={workspaceQuery?.result}
      />
    </Card>
  )

  return (
    <>
      {workspaceActionBar}
      {workspaceSettings}
    </>
  )
}
