import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { ImportListDataPanel } from 'src/applications/Oversight/panels/ImportListDataPanel'
import { ImportListNoDataPanel } from 'src/applications/Oversight/panels/ImportListNoDataPanel'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_BATCHES } from 'src/queries/GET_BATCHES'
import { GetBatches, GetBatchesVariables } from 'src/queries/types/GetBatches'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useSearch } from 'src/resources/hooks/useSearch'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { EFilterEmbedsType } from 'src/types/interfaces/IBatch'
import { IBatchesResponse } from 'src/types/interfaces/IBatchesResponse'
import useReactRouter from 'use-react-router'
import { SessionContext } from 'src/contexts/SessionContext'

export const ImportListPanel = () => {
  const team = useContext(TeamContext)
  const sessionContext = useContext(SessionContext)
  const environment = useContext(EnvironmentContext)
  const teamRoot = useTeamRootUrl()
  const { history, location } = useReactRouter()

  const searchVariables = useSearch<
    { u: string; nodata: string; s: string },
    { endUserId?: string; noDataRedirect?: string }
  >(location.search, ({ u, nodata, s }) => ({
    endUserId: u,
    schemaId: s,
    noDataRedirect: nodata
  }))

  const { data, loading, error } = useQuery<GetBatches, GetBatchesVariables>(GET_BATCHES, {
    fetchPolicy: 'network-only',
    variables: {
      deleted: false,
      environmentId: environment?.id,
      teamId: sessionContext.activeTeamId,
      licenseKey: team.licenses[0].key,
      embedFilter: EFilterEmbedsType.embedsOrLegacy,
      ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE),
      ...searchVariables
    }
  })

  if (error || loading) {
    return QueryAlert({ error, loading })
  }

  if (searchVariables.noDataRedirect && data.getBatches.data.length === 0) {
    history.push(`${teamRoot}/${searchVariables.noDataRedirect}`)
    return null
  }

  return data ? <ImportPanel batches={data.getBatches} /> : null
}

const ImportPanel = ({ batches }: { batches: IBatchesResponse }) =>
  batches.pagination.totalCount > 0 ? (
    <ImportListDataPanel batches={batches} />
  ) : (
    <ImportListNoDataPanel />
  )
