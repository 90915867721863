import { useCallback } from 'react'
import { Exclamation } from 'src/resources/elements/Icons'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatButtonColor } from 'src/resources/elements/buttons/IFlatButton'
import { useModal } from 'src/resources/elements/Modal'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const ErrorContainerWrapper = styled.div`
  display: flex;
  font-family: ${fontFamily.fontPrimary};
  padding: 16px 0;
`

const ErrorBg = styled.div<{ grayIcon?: boolean }>`
  background: ${({ grayIcon }) => (grayIcon ? Colors.pigeon200 : Colors.dangerBGColor)};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 24px;
`

const Description = styled.div`
  font-weight: 400;
  font-size: ${fontSizes.type14};
  line-height: 18px;
  color: ${Colors.pigeon800};

  strong {
    color: ${Colors.text};
  }
}
`

export const CustomButtonGroup = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  *:last-of-type {
    margin-left: 12px;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

interface IConfirmModal {
  cancelColor?: FlatButtonColor
  cancelLabel?: string
  confirmColor?: FlatButtonColor
  confirmLabel?: string
  grayIcon?: boolean
  showCancelButton?: boolean
  contents?: JSX.Element
  header: string
  onConfirm(): void
  onCancel?(): void
  zIndex?: number
  theme?: any
}

export function useConfirmModal({
  cancelColor = 'secondary',
  cancelLabel = 'Cancel',
  confirmColor = 'danger',
  confirmLabel = 'Confirm',
  grayIcon = false,
  showCancelButton = true,
  contents,
  header,
  onConfirm,
  onCancel,
  zIndex,
  theme
}: IConfirmModal) {
  const modal = useModal({
    showCloseIcon: false,
    width: theme?.modalMaxWidth || '512px',
    // Turntable has z-indexes up to 300 and overlaps the modal otherwise
    zIndex: typeof zIndex !== 'undefined' ? zIndex : 400,
    header: (
      <div style={{ ...(theme?.modalHeaderStyles ?? {}) }}>
        {!theme?.sage && (
          <ErrorBg grayIcon={grayIcon}>
            <Exclamation stroke={grayIcon ? Colors.pigeon700 : Colors.dangerStrong} />
          </ErrorBg>
        )}
        {header}
      </div>
    ),
    contents: () => (
      <>
        <ErrorContainerWrapper>
          <FlexColumn>
            <Description>{contents}</Description>
          </FlexColumn>
        </ErrorContainerWrapper>
        <CustomButtonGroup style={{ ...(theme?.modalContentStyle ?? {}) }}>
          {showCancelButton && (
            <FlatButton
              color={cancelColor}
              onClick={cancel}
              variant='outlined'
              style={theme?.modalSecondaryButtonStyle}
            >
              {cancelLabel}
            </FlatButton>
          )}
          <FlatButton
            color={confirmColor}
            onClick={onConfirm}
            style={theme?.modalPrimaryButtonStyle}
          >
            {confirmLabel}
          </FlatButton>
        </CustomButtonGroup>
      </>
    )
  })

  const cancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    modal.close()
  }, [modal])

  return modal
}
