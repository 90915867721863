import moment from 'moment'
import { useContext } from 'react'
import { FileUploadContext } from 'src/contexts/FileUploadContext'
import { GetBatches_getBatches_data_views } from 'src/queries/types/GetBatches'
import { Spinner } from 'src/resources/elements/Spinner'
import { Status } from 'src/resources/elements/Status'
import { EBatchStatus } from 'src/types/enums/EBatchStatus'
import { EViewStatus } from 'src/types/enums/EViewStatus'
import { EBatchType, IBatchData } from 'src/types/interfaces/IBatch'
import styled from 'styled-components'

const StyledSpinnerWrapper = styled.div`
  transform: scale(0.7);

  svg circle {
    stroke-width: 7px;
  }
`

export const ImportStatus = ({
  batch,
  view
}: {
  batch: IBatchData
  view?: GetBatches_getBatches_data_views
}) => {
  const { fileUploadState } = useContext(FileUploadContext)
  if (batch.deleted) {
    return <Status type='completed'>Archived</Status>
  }
  if (batch.failedAt) {
    return <Status type='failed'>Failed</Status>
  }

  if (batch.submittedAt || batch.status === EBatchStatus.submitted) {
    return <Status type='completed'>Completed</Status>
  }

  if (batch.status === EBatchStatus.cancelled) {
    return <Status type='inProgress'>Cancelled</Status>
  }

  if (batch.embedId || batch.type === EBatchType.legacyUpload) {
    return <Status type='inProgress'>Unsubmitted</Status>
  }

  if (!view) {
    const uploadProgress = Object.entries(fileUploadState ?? {}).find(([uploadId]) =>
      (batch.uploads ?? []).find(({ id }) => id === uploadId)
    )?.[1]
    if (uploadProgress && !uploadProgress.finished) {
      return (
        <Status type='inProgress'>
          Uploading file...
          <StyledSpinnerWrapper>
            <Spinner />
          </StyledSpinnerWrapper>
          {Math.round(uploadProgress.percent)}%
        </Status>
      )
    }
    /**
     * If the file hasn't received a status in 15min (by default), the status is failed
     */
    const [upload] = batch.uploads

    if (
      upload &&
      !upload.status &&
      moment(new Date(parseInt(upload.createdAt, 10)))
        .add(900, 'seconds')
        .isBefore(moment())
    ) {
      return (
        <Status type='failed' tooltip='Upload has timed out'>
          Upload failed
        </Status>
      )
    }
    return <Status type='inProgress'>Upload in progress</Status>
  }

  switch (view?.status) {
    case EViewStatus.UPLOADED:
      if (!batch.isParsable) {
        return <Status type='completed'>View file</Status>
      }
      return <Status type='needsAttention'>Select datasource</Status>
    case EViewStatus.UPLOAD_FAILED:
      const tooltip = batch.uploads.find(({ errorInfo }) => errorInfo?.message)?.errorInfo?.message
      return (
        <Status type='failed' {...(tooltip ? { tooltip } : {})}>
          Upload failed
        </Status>
      )
    case EViewStatus.DATA_LOADED:
      return <Status type='completed'>Ready to match</Status>
    case EViewStatus.HEADER_ROW_SELECTED:
    case EViewStatus.SCHEMA_MAPPED:
      return <Status type='needsAttention'>Finish matching</Status>
    case EViewStatus.MATCHED:
      return <Status type='completed'>Data extracted</Status>
    case EViewStatus.LOADED:
      return <Status type='completed'>Loaded</Status>
    case EViewStatus.PROCESS_FAILED:
      return <Status type='failed'>Process failed</Status>
    default:
      return <Status type='failed'>Unknown</Status>
  }
}
