import { useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { storage } from 'src/resources/utils/storage'

const prefix = 'CONFIG_OVERRIDE'

export interface IConfigValueExtra {
  nestUnder?: string
  insignificant?: boolean
  options?: string[]
  visible?: boolean
}

let originalConfigValues: IConfigValue[] = []
let configValues: IConfigValue[] = []

export const localStorageOverride = (
  name: string,
  defaultValue: string,
  { nestUnder = '', insignificant, options, visible = true }: IConfigValueExtra = {}
): string => {
  const key = `${prefix}:${name}`
  const value = storage(key).getRaw()
  const isOverride = typeof value === 'string'
  const configValue: IConfigValue = {
    defaultValue,
    insignificant,
    isOverride,
    key,
    name,
    nestUnder,
    options,
    value: value === null ? undefined : value,
    visible
  }
  configValues = [...configValues, { ...configValue }]
  originalConfigValues = [...originalConfigValues, { ...configValue }]
  return typeof value === 'string' ? value : defaultValue
}

export const storedConfigValue = (
  name: string,
  defaultValue: string,
  extra?: IConfigValueExtra
) => {
  localStorageOverride(name, defaultValue, extra)
  return configValues.find((x: IConfigValue) => x.name === name)
}

export interface IConfigValue {
  defaultValue: string
  insignificant: boolean
  isOverride: boolean
  key: string
  name: string
  nestUnder: string
  options?: string[]
  value?: string
  visible: boolean
}

export const extractValue = (configValue: IConfigValue) => {
  return configValue.isOverride && configValue.value !== null
    ? configValue.value
    : configValue.defaultValue
}

export const getConfigValue = <T extends string = string>(
  configValue: IConfigValue,
  includePending: boolean = false
): T | undefined => {
  if (includePending) {
    const latestConfigValue = configValues.find((x) => x.key === configValue.key)
    if (latestConfigValue) {
      return extractValue(latestConfigValue) as T
    }
  }
  return extractValue(configValue) as T
}

const updateConfigValue = (
  configValueToUpdate: IConfigValue,
  patch: Pick<IConfigValue, 'isOverride' | 'value'>
) => {
  configValues = configValues.map((configValue) =>
    configValue.key !== configValueToUpdate.key ? configValue : { ...configValue, ...patch }
  )
}

export const removeConfigValue = (configValue: IConfigValue) => {
  const key = `${prefix}:${configValue.name}`
  storage(key).clear()
  updateConfigValue(configValue, { isOverride: false, value: configValue.defaultValue })
}

export const setConfigValue = <T extends string = string>(configValue: IConfigValue, value: T) => {
  const key = `${prefix}:${configValue.name}`
  storage(key).set(value)
  updateConfigValue(configValue, { isOverride: true, value })
}

export interface IConfigValuesManager {
  isModified: boolean
  values: IConfigValue[]
  get(configValue: IConfigValue, includePending?: boolean): string | undefined
  remove(configValue: IConfigValue): void
  rollbackAll(): void
  set(configValue: IConfigValue, value: string): void
}

const sortConfigValues = (values: IConfigValue[]): IConfigValue[] => {
  const byName = (x: IConfigValue, y: IConfigValue) => x.name.localeCompare(y.name)
  const nested = values.filter((x) => x.visible && x.nestUnder.length > 0).sort(byName)
  const top = values.filter((x) => x.visible && x.nestUnder === '').sort(byName)

  return top.reduce(
    (acc, item) =>
      acc.concat(
        item.isOverride ? [item, ...nested.filter((x) => x.nestUnder === item.name)] : [item]
      ),
    [] as IConfigValue[]
  )
}

export const useConfigValues = (): IConfigValuesManager => {
  const [, setIteration] = useState(0)
  const nextIteration = () => setIteration((value) => value + 1)

  const isModified = useMemo(
    () =>
      !isEqual(
        originalConfigValues
          .filter((x) => !x.insignificant)
          .sort((a, b) => a.key.localeCompare(b.key))
          .map((x) => (x.isOverride ? x.value : x.defaultValue)),
        configValues
          .filter((x) => !x.insignificant)
          .sort((a, b) => a.key.localeCompare(b.key))
          .map((x) => (x.isOverride ? x.value : x.defaultValue))
      ),
    [configValues]
  )

  return {
    isModified,
    remove(configValue: IConfigValue) {
      removeConfigValue(configValue)
      nextIteration()
    },
    rollbackAll() {
      configValues = originalConfigValues.slice()
      nextIteration()
    },
    get: getConfigValue,
    set(configValue: IConfigValue, value: string) {
      setConfigValue(configValue, value)
      nextIteration()
    },
    values: sortConfigValues(configValues)
  }
}
