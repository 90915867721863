export const getSignedUrlHeaders = (signedUrl: string) => {
  const headers: any = {}
  // https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html
  const allowedHeaders = [
    'x-amz-acl',
    'Cache-Control',
    'Content-Disposition',
    'Content-Encoding',
    'Content-Language',
    'Content-Length',
    'Content-MD5',
    'Content-Type',
    'Expires',
    'x-amz-grant-full-control',
    'x-amz-grant-read',
    'x-amz-grant-read-acp',
    'x-amz-grant-write-acp',
    'x-amz-server-side-encryption',
    'x-amz-storage-class',
    'x-amz-website-redirect-location',
    'x-amz-server-side-encryption-customer-algorithm',
    'x-amz-server-side-encryption-customer-key',
    'x-amz-server-side-encryption-customer-key-MD5',
    'x-amz-server-side-encryption-aws-kms-key-id',
    'x-amz-server-side-encryption-context',
    'x-amz-request-payer',
    'x-amz-tagging',
    'x-amz-object-lock-mode',
    'x-amz-object-lock-retain-until-date',
    'x-amz-object-lock-legal-hold',
    'x-amz-expected-bucket-owner',
    'content-type'
  ]
  const parsedSignedURL = new URL(signedUrl)
  parsedSignedURL.searchParams.forEach((value, key) => {
    if (allowedHeaders.indexOf(key) > -1 || key.indexOf('x-amz-meta-') > -1) {
      headers[key] = value
    }
  })
  return headers
}
