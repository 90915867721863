import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import PortalOnboardingPanel from 'src/applications/Oversight/panels/Concierge/PortalOnboardingPanel'
import { EmbedPrivateKeyProvider } from 'src/contexts/CreatedEmbedPrivateKeyContext'

import { EmbedEditPanel } from '../../panels/EmbedEditPanel'

export const EmbedRoutes = ({
  match: { path: embedsPath }
}: RouteComponentProps<{ teamId: string }>) => (
  <EmbedPrivateKeyProvider>
    <Switch>
      <Route component={EmbedEditPanel} path={`${embedsPath}/embeds/:embedId`} />
      <Route component={PortalOnboardingPanel} exact path={`${embedsPath}`} />
    </Switch>
  </EmbedPrivateKeyProvider>
)
