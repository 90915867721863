import queryString from 'query-string'

/**
 * Update a query string with new values
 * @param q          eg ?page=1&foo=bar
 * @param newValues  Data to replace in the query string
 *
 * @return Another query string such as ?page=2&foo-bar
 */
export const updateQueryString = (q: string, newValues: IQueryObject = {}): string => {
  const obj = queryString.parse(q)
  const updated = { ...obj, ...newValues }
  return '?' + queryString.stringify(updated)
}

interface IQueryObject {
  [urlParam: string]: string | number
}
