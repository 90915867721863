import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'

import {
  SmartFetchWorkbookRows,
  SmartFetchWorkbookRowsVariables,
  SmartFetchWorkbookRows_fetchRowsWithSchema
} from './types/SmartFetchWorkbookRows'

export const SQ_FETCH_WORKBOOK_ROWS: SmartQuery<
  SmartFetchWorkbookRowsVariables,
  SmartFetchWorkbookRows,
  SmartFetchWorkbookRows_fetchRowsWithSchema
> = {
  query: gql`
    query SmartFetchWorkbookRows(
      $workbookId: UUID!
      $schemaId: Int!
      $skip: Int!
      $limit: Int!
      $filter: String
      $status: String
      $errorFilter: String
      $mergeId: UUID
      $sortColumn: Int
      $sortOrder: String
      $skipSummary: Boolean
    ) {
      fetchRowsWithSchema(
        workbookId: $workbookId
        schemaId: $schemaId
        skip: $skip
        limit: $limit
        filter: $filter
        status: $status
        errorFilter: $errorFilter
        mergeId: $mergeId
        sortColumn: $sortColumn
        sortOrder: $sortOrder
        skipSummary: $skipSummary
      ) {
        totalRows
        review
        accepted
        dismissed
        submitted
        filtered
        counts {
          valid
          invalid
          merged
        }
        errors
        rows {
          _id
          cells
          validations {
            error
            key
            message
          }
        }
      }
    }
  `,
  extract({ fetchRowsWithSchema }) {
    return fetchRowsWithSchema
  }
}
