import { Colors, ColorUtils } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'

export const ElectricFlatButtonStyle = css<IFlatButtonStyle>`
  background-color: ${Colors.electric200} !important;
  color: ${Colors.textLogo} !important;

  &:hover {
    background-color: ${ColorUtils.hoverColor(Colors.electric600)} !important;
  }
`
