import { useMutation } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'

export const useSmartMutation = <Variables, RawResult, Result>({
  extract,
  mutation,
  options
}: SmartMutation<Variables, RawResult, Result>) => {
  const [trigger, state] = useMutation<RawResult, Variables>(mutation, options)

  const alert = state.loading || typeof state.error !== 'undefined'

  const run = async (variables: Variables) => {
    const result = await trigger({ variables })

    return extract(result.data)
  }

  if (state.data) {
    const result = extract(state.data)

    return { run, result, state, alert }
  }

  return { run, state, alert }
}
