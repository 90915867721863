import { useCallback, useEffect, useState } from 'react'

import { useSubscription } from '@apollo/client'
import { Span } from '@sentry/types'
import { ObservableQuery } from 'apollo-client'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { EXPORT_UPDATED_SUBSCRIPTION } from 'src/queries/EXPORT_UPDATED_SUBSCRIPTION'
import { ExportUpdated, ExportUpdatedVariables } from 'src/queries/types/ExportUpdated'
import { ButtonBase } from 'src/resources/elements/buttons/FlatButton'
import { EExportStatus } from 'src/types/enums/EExportStatus'
import { IBatch } from 'src/types/interfaces/IBatch'

import { useSmartQuery } from '../../../smart/hooks/useSmartQuery'
import { SQ_GET_FILE_ACCESS_TOKEN } from '../../../smart/queries/SQ_GET_FILE_ACCESS_TOKEN'
import { ExportFailed } from './ExportFailed'
import { ExportInProgress } from './ExportInProgress'
import { ExportSuccessful } from './ExportSuccessful'

// Sentry performance tracking
const ExportInitialized = 'export-initialized'
const ExportSuccess = 'export-success'
const exportSpans: Partial<Span>[] = [{ op: ExportInitialized }, { op: ExportSuccess }]

export const ExportViewButton = ({ batch }: { batch: IBatch }) => {
  const [exportId, setExportId] = useState<string | undefined>()
  const [exportStatus, setExportStatus] = useState<string | undefined>()
  const [fetchingAccessToken, setFetchingAccessToken] = useState(false)
  const [downloadOriginalInProgress, setDownloadOriginalInProgress] = useState(false)
  const [exportFileFormat, setExportFileFormat] = useState(null)

  const { state } = useSmartQuery(SQ_GET_FILE_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    skip: true,
    variables: { batchId: batch.id, type: 'original' }
  })
  const refetchFileAccessToken = state && (state.refetch as ObservableQuery['refetch'])

  const sentryTransaction = useSentryTransaction({
    name: `workspaces/export`,
    spans: exportSpans
  })

  const { data: { exportUpdated } = {}, error: exportError } = useSubscription<
    ExportUpdated,
    ExportUpdatedVariables
  >(EXPORT_UPDATED_SUBSCRIPTION, {
    skip: !exportId,
    variables: { exportId }
  })

  useEffect(() => {
    if (exportUpdated?.status) {
      setExportStatus(exportUpdated.status)
    }
  }, [exportUpdated?.status])

  useEffect(() => {
    if (!refetchFileAccessToken) {
      return
    }
    const getFileAccessToken = async () => {
      setFetchingAccessToken(true)
      const { data: refetchData } = await refetchFileAccessToken({
        batchId: batch.id,
        type: 'original'
      })

      const fileType = refetchData?.getFileAccessToken?.type
      const downloadToken = refetchData?.getFileAccessToken?.token

      if (fileType && downloadToken) {
        const url = `${FLATFILE_REFINERY_URL}/batch/${batch.id}/original-file?token=${downloadToken}`
        setDownloadOriginalInProgress(false)
        window.open(url, '_blank')
        submitSentrySpan(sentryTransaction, ExportSuccess)
        sentryTransaction.transaction.finish()
      }
      setFetchingAccessToken(false)
    }
    if (downloadOriginalInProgress) {
      getFileAccessToken()
    }
  }, [downloadOriginalInProgress])

  useEffect(() => {
    if (exportUpdated?.status === EExportStatus.UPLOADED) {
      window.open(`${FLATFILE_REFINERY_URL}/export/${exportUpdated.id}?format=${exportFileFormat}`)
      setExportFileFormat(null)
      setExportId(null)
      submitSentrySpan(sentryTransaction, ExportSuccess)
      sentryTransaction.transaction.finish()
    }
  }, [exportUpdated?.status])

  const downloadOriginalCsv = useCallback(() => {
    setDownloadOriginalInProgress(true)
  }, [setDownloadOriginalInProgress])

  if (exportError) {
    return ExportFailed
  } else if (exportId) {
    switch (exportStatus) {
      case EExportStatus.ERROR:
      case EExportStatus.UPLOAD_FAILED:
        return ExportFailed
      case EExportStatus.CREATED:
      case EExportStatus.QUEUED:
      case EExportStatus.IN_PROGRESS:
        return ExportInProgress
      case EExportStatus.UPLOADED:
        return ExportSuccessful
    }
  } else if (fetchingAccessToken) {
    return ExportInProgress
  }

  return (
    <ButtonBase onClick={downloadOriginalCsv} color='success'>
      Download
    </ButtonBase>
  )
}
