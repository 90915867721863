import { Colors } from 'src/resources/colors'
import styled from 'styled-components'

export const Spinner = ({ color }: { color?: string }) => (
  <StyledSpinner viewBox='0 0 50 50' color={color}>
    {/* eslint-disable react/forbid-component-props */}
    <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5' />
  </StyledSpinner>
)

export const SpinnerWrapper = styled.div`
  display: inline-block;

  & > svg {
    width: 13px;
    height: 13px;
    vertical-align: -3px;
    margin: 0 auto;
  }
`

export const StyledSpinner = styled.svg<{ color?: string }>`
  animation: rotate 1s linear infinite;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  vertical-align: -6px;
  display: inline-block;
  & .path {
    stroke: ${Colors.pigeon700};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
