import { useQuery } from '@apollo/client'
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { FileUploadContext } from 'src/contexts/FileUploadContext'
import { GET_BATCH } from 'src/queries/GET_BATCH'
import { GET_BATCHES } from 'src/queries/GET_BATCHES'
import { GetBatch, GetBatchVariables } from 'src/queries/types/GetBatch'
import { GetBatches_getBatches_data_views } from 'src/queries/types/GetBatches'
import { Badge } from 'src/resources/elements/Badge'
import { HaloButton } from 'src/resources/elements/HaloButton'
import { Icon } from 'src/resources/elements/Icon'
import { ArchiveIcon, RestoreIcon } from 'src/resources/elements/Icons'
import { ClickableTR, NoClickTd } from 'src/resources/elements/Table'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_ARCHIVE_BATCH } from 'src/smart/mutations/SM_ARCHIVE_BATCH'
import { SM_RESTORE_VIEW } from 'src/smart/mutations/SM_RESTORE_VIEW'
import { uploadUpdatedSubscription } from 'src/smart/queries/SQ_UPLOAD_UPDATES_SUBSCRIPTION'
import { EViewStatus } from 'src/types/enums/EViewStatus'
import { EViewType } from 'src/types/enums/EViewType'
import { EBatchSource, IBatchData } from 'src/types/interfaces/IBatch'
import { renderDate } from 'src/utils/date'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { ImportStatus } from './ImportStatus'
import { OverflowData } from './OverflowData'
import { FeatureFlagContext } from 'src/applications/Embed/fragments/FeatureFlagContext'

const FileName = styled.span`
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type14};
  font-weight: 500;
`

const TableDataFlex = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }
`
interface BatchViews {
  [key: string]: GetBatches_getBatches_data_views[]
}

export interface WorkspaceUploadRowProps {
  batch: IBatchData
  schemaLabel: string
  sheetLink?: string
  workspaceRootUrl: string
  onViewHistory?: (batchId: string) => void
}

const stopClickPropagation = (e: MouseEvent) => {
  e.stopPropagation()
}

export const BatchTableRow = ({
  batch,
  schemaLabel,
  sheetLink,
  workspaceRootUrl,
  onViewHistory
}: WorkspaceUploadRowProps) => {
  const { batchViews, setBatchViews } = useContext(FileUploadContext)
  const features = useContext(FeatureFlagContext)
  const { history } = useReactRouter()
  const archiveBatch = useSmartMutation({
    ...SM_ARCHIVE_BATCH,
    options: { refetchQueries: [GET_BATCHES, 'GetBatches'] }
  })
  const restoreBatch = useSmartMutation({
    ...SM_RESTORE_VIEW,
    options: { refetchQueries: [GET_BATCHES, 'GetBatches'] }
  })
  const { refetch } = useQuery<GetBatch, GetBatchVariables>(GET_BATCH, {
    fetchPolicy: 'network-only',
    variables: { batchId: batch.id },
    skip: true
  })

  const updateUpload = uploadUpdatedSubscription(batch.workspaceId)
  const [selectedSchema, setSchema] = useState(undefined)
  const [selectedSheet, setSheet] = useState(undefined)

  const getView = (viewType: EViewType) =>
    batchViews?.[batch.id]
      ?.slice()
      .reverse()
      .find((v) => {
        return v.type === viewType && v?.status !== EViewStatus.ARCHIVED
      })

  const batchView = getView(EViewType.DERIVED) ?? getView(EViewType.ORIGINAL)

  useEffect(() => {
    let isMounted = true
    if (
      updateUpload?.updateType &&
      updateUpload?.updateType === 'uploadFinished' &&
      updateUpload?.batch.id === batch.id
    ) {
      updateUploadRowStatus(updateUpload?.batch.id).then(({ sheet, schema }) => {
        if (isMounted) {
          setSchema(schema)
          setSheet(sheet)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [updateUpload?.updateType])

  const updateUploadRowStatus = async (batchId: string) => {
    const { data } = await refetch({ batchId })

    if (data?.getBatch?.id) {
      setBatchViews((batchViews: BatchViews) => ({
        ...batchViews,
        [updateUpload?.batch.id]: data.getBatch.views
      }))
    }

    return {
      sheet: data?.getBatch?.uploads[0]?.selectedDataSourceIndex,
      schema: data?.getBatch?.schema?.id
    }
  }
  useEffect(() => {
    let isMounted = true
    if (selectedSchema === undefined || selectedSheet === undefined) {
      updateUploadRowStatus(batch.id).then(({ sheet, schema }) => {
        if (isMounted) {
          setSchema(schema)
          setSheet(sheet)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [])

  const archive = useCallback(() => archiveBatch.run({ batchId: batch.id }), [archiveBatch, batch])
  const restore = useCallback(
    () => restoreBatch.run({ viewId: batchView?.id }),
    [restoreBatch, batchView]
  )

  const flagImportTimeline = features?.includes('IMPORT_TIMELINE')

  const handleBatchClick = () => {
    if (batch.deleted) {
      history.push(`${workspaceRootUrl}/imports/${batch.id}`)
    } else if (batchView?.status) {
      if (!batch.isParsable) {
        history.push(`${workspaceRootUrl}/preview/${batch.id}`)
        return
      }
      // TODO: Cleanup Routing here vs WorkspaceUploadhistory.pushorScene
      if (batchView?.status === EViewStatus.DATA_LOADED) {
        history.push(`${workspaceRootUrl}/uploads/${batch.uploads[0].id}/source`)
      } else if (batchView?.status === EViewStatus.SCHEMA_MAPPED) {
        if (selectedSchema === undefined || selectedSheet === undefined) {
          history.push(`${workspaceRootUrl}/uploads/${batch.uploads[0].id}/source`)
        } else {
          history.push(
            `${workspaceRootUrl}/uploads/${batch.uploads[0].id}/sheet/${selectedSheet}/schema/${selectedSchema}/header-row`
          )
        }
      } else if (batchView?.status === EViewStatus.HEADER_ROW_SELECTED) {
        history.push(
          `${workspaceRootUrl}/uploads/${batch.uploads[0].id}/view/${batchView.id}/match`
        )
      } else if (
        batchView?.status === EViewStatus.MATCHED ||
        batchView?.status === EViewStatus.LOADED
      ) {
        history.push(`${workspaceRootUrl}/imports/${batch.id}`)
      } else {
        // Goes to WorkspaceUploadhistory.pushorScene
        history.push(`${workspaceRootUrl}/uploads/${batch.uploads[0].id}`)
      }
    }
  }

  const viewHistory = useCallback(() => onViewHistory(batch.id), [batch, onViewHistory])

  return (
    <ClickableTR onClick={handleBatchClick}>
      <td>
        <TableDataFlex>
          <OverflowData dataTip={batch.originalFile} dataFor={batch.originalFile}>
            <FileName>{batch.originalFile}</FileName>
          </OverflowData>
        </TableDataFlex>
      </td>
      <td data-testid='batch-status'>
        <ImportStatus batch={batch} view={batchView} />
      </td>
      <td>
        {batch.endUser?.name || ''}
        {batch.source === EBatchSource.sftp && <Badge>SFTP</Badge>}
      </td>
      <td>
        <OverflowData dataFor={schemaLabel} dataTip={schemaLabel}>
          {sheetLink ? (
            <Link to={sheetLink} onClick={stopClickPropagation}>
              {schemaLabel}
            </Link>
          ) : (
            <span>{schemaLabel}</span>
          )}
        </OverflowData>
      </td>
      <td>{renderDate(batch.createdAt, true)}</td>

      <NoClickTd onClick={stopClickPropagation}>
        {flagImportTimeline && (
          <HaloButton data-tip='View history' data-for='view-history' onClick={viewHistory}>
            <Icon name='history' />
            <ReactTooltip id='view-history' place='bottom' type='dark' effect='float' />
          </HaloButton>
        )}
        {batch.deleted ? (
          <HaloButton data-tip='Restore' data-for='restore' onClick={restore}>
            <RestoreIcon width='24' height='24' viewBox='0 0 32 40' />
            <ReactTooltip id='restore' place='bottom' type='dark' effect='float' />
          </HaloButton>
        ) : (
          <HaloButton data-tip='Archive' data-for='Archive' onClick={archive}>
            <ArchiveIcon width='24' height='24' viewBox='0 0 40 40' />
            <ReactTooltip id='Archive' place='bottom' type='dark' effect='float' />
          </HaloButton>
        )}
      </NoClickTd>
    </ClickableTR>
  )
}
