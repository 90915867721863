import { useMutation } from '@apollo/client'
import { RouteComponentProps } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { PreAuthTemplate } from 'src/applications/Oversight/templates/PreAuthTemplate'
import { RESET_PASSWORD } from 'src/queries/RESET_PASSWORD'
import { ResetPassword, ResetPasswordVariables } from 'src/queries/types/ResetPassword'
import { Colors } from 'src/resources/colors'
import { AuthSheet } from 'src/resources/elements/AuthSheet'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const ResetPasswordContentContainer = styled.section`
  max-width: 400px;
  width: 100%;
  padding: ${Spacing.basePadding4x};
  background: ${Colors.white};
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin: 0 auto ${Spacing.basePadding3x};
`

const ResetPasswordContentHeader = styled.div`
  margin-bottom: ${Spacing.basePadding4x} !important;

  h1 {
    margin-bottom: ${Spacing.basePadding};
    color: ${Colors.textAccent};
    font-family: ${fontFamily.fontPrimary};
  }

  p {
    color: ${Colors.text};
    font-size: ${fontSizes.type14};
    margin-top: 1rem;
  }
`

const StyledInput = styled(Input)`
  max-width: 100%;
`

export const ResetPasswordScene = ({
  match: {
    params: { resetToken }
  }
}: RouteComponentProps<{ resetToken: string }>) => (
  <PreAuthTemplate>
    <ResetPasswordPanel resetToken={resetToken} />
  </PreAuthTemplate>
)

const ResetPasswordPanel = ({ resetToken }: { resetToken: string }) => {
  const { addToast } = useToasts()

  const ResetPasswordForm: TForm<{
    password: string
    passwordConfirm: string
  }> = Form

  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD)

  return (
    <AuthSheet>
      <ResetPasswordContentContainer>
        <ResetPasswordContentHeader>
          <h1>Reset password</h1>
          <p>Enter a new password to restore your access</p>
        </ResetPasswordContentHeader>
        <ResetPasswordForm
          onSubmit={async (event) => {
            const { password, passwordConfirm } = event.data

            if (!password) {
              event.formContext.setValue({
                errors: { password: 'Please enter a new password.' }
              })
              return
            } else if (password.length < 8) {
              event.formContext.setValue({
                errors: {
                  password: 'Password must be at least 8 characters long.'
                }
              })
              return
            }

            if (!passwordConfirm) {
              event.formContext.setValue({
                errors: {
                  passwordConfirm: 'Please confirm your new password.'
                }
              })
              return
            } else if (passwordConfirm !== password) {
              event.formContext.setValue({
                errors: {
                  passwordConfirm: 'Confirm password does not match new password.'
                }
              })
              return
            }

            const response = await resetPassword({
              variables: {
                password,
                passwordConfirm,
                resetToken
              }
            })

            if (
              response.data &&
              response.data.resetPassword &&
              response.data.resetPassword.email
            ) {
              event.formContext.setValue({
                data: { password: '', passwordConfirm: '' }
              })

              addToast(`Your password was changed successfully!`, {
                appearance: 'success',
                autoDismiss: true
              })
            }
          }}
        >
          <StyledInput label='New Password' name='password' placeholder='' type='password' />
          <ErrorMessage name='password' />
          <StyledInput
            label='New Password (confirm)'
            name='passwordConfirm'
            placeholder=''
            type='password'
          />
          <ErrorMessage name='passwordConfirm' />
          <ButtonGroup>
            <FormButton primary submit aria-label='Reset password'>
              Reset
            </FormButton>
          </ButtonGroup>
        </ResetPasswordForm>
      </ResetPasswordContentContainer>
    </AuthSheet>
  )
}
