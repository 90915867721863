import { gql } from 'apollo-boost'
import {
  SmartGetBatch,
  SmartGetBatch_getBatch,
  SmartGetBatchVariables
} from 'src/smart/queries/types/SmartGetBatch'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_BATCH: SmartQuery<SmartGetBatchVariables, SmartGetBatch, SmartGetBatch_getBatch> =
  {
    query: gql`
      query SmartGetBatch($batchId: UUID!) {
        getBatch(batchId: $batchId) {
          countColumns
          countColumnsMatched
          countRows
          countRowsAccepted
          countRowsInvalid
          createdAt
          failedAt
          failureReason
          filename
          handledAt
          headerHash
          headersMatched {
            index
            matched_key
            value
          }
          headersRaw {
            index
            value
          }
          id
          importedFromUrl
          managed
          manual
          matchedAt
          memo
          originalFile
          parsingConfig
          status
          submittedAt
          type
          updatedAt
          validatedIn
          schema {
            id
            name
            jsonSchema {
              schema
            }
          }
          template {
            id
            name
            jsonSchema {
              schema
            }
          }
          teamId
          uploads {
            id
            fileName
            fileSize
            fileType
            selectedDataSourceIndex
            signedUrl
          }
          views {
            id
            status
            type
          }
          workspace {
            dataEngine
          }
        }
      }
    `,
    extract({ getBatch }) {
      return getBatch
    }
  }
