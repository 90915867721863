import { gql } from '@apollo/client'

export const UPDATE_UPLOAD_DATA_SOURCE_FIELD = gql`
  mutation UpdateUploadDataSourceField(
    $id: Int!
    $matchKey: String
    $matchKeySub: String
    $ignored: Boolean
    $matchMeta: JSON
    $force: Boolean
  ) {
    updateUploadDataSourceField(
      id: $id
      matchKey: $matchKey
      matchKeySub: $matchKeySub
      ignored: $ignored
      matchMeta: $matchMeta
      force: $force
    ) {
      data {
        id
        matchKey
        matchKeySub
        matchMeta
        confirmed
        ignored
      }
      validation {
        message
        fields
        uniqueProps
        type
      }
    }
  }
`
