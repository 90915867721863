import { Link } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState, MouseEvent, useRef } from 'react'
import { useEditSchemaPropertyModal } from 'src/applications/Oversight/hooks/useEditSchemaPropertyModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { GetSchema_getSchema_linkedSchemas } from 'src/queries/types/GetSchema'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { Badge } from 'src/resources/elements/Badge'
import { DangerIcon, LinkIcon, Menu, NewWindow } from 'src/resources/elements/Icons'
import { inputStyles } from 'src/resources/inputs'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { EFieldAction } from 'src/types/enums/EFieldAction'
import { ISchema, ISchemaProperty } from 'src/types/interfaces/ISchema'
import { EPropertyType } from 'src/utils/data/Schema'
import { deserializeErrors as deserializeSchemaErrors } from 'src/utils/schema-normalizer'
import styled, { css } from 'styled-components'
import { FlatButton, FlatButtonBase } from '../buttons/FlatButton'
import { ClickableTR, NewStyledTable } from '../Table'
import { getFormContext } from './Form'
import { isEqual } from 'lodash'
import { useWarnBeforeUnload } from 'src/applications/Oversight/hooks/useWarnBeforeUnload'
import Pill from 'src/resources/elements/Pill'
import { useGetSchemas } from 'src/applications/Oversight/hooks/useGetSchemas'
import { SmartGetSchemas_getSchemas_data } from 'src/smart/queries/types/SmartGetSchemas'
import { LinkedSchema } from 'src/applications/Oversight/forms/EditSchemaPropertyForm'
import { FormButton } from './Button'
import { ButtonsWrapper } from 'src/applications/Oversight/components/JsonSchemaCodeEditor'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { arrayMove } from 'src/resources/utils/arrayMove'

const SchemaModelContainer = styled.div`
  position: relative;
  font-family: ${fontFamily.fontPrimary};
  font-weight: 400;

  label {
    color: ${Colors.pigeon800};
    display: block;
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type14};
    font-weight: 600;
    margin: ${Spacing.basePadding2x} auto 0;
  }
`

const DeleteButton = styled(FlatButton)`
  min-width: 16px;
  display: table-cell !important;
  margin: 0 4px;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > a {
    margin: 0 ${Spacing.basePadding};
    background-color: transparent;
    box-shadow: none;
    opacity: 0.8;
    font-weight: 600;

    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }
`

const Row = styled.div`
  display: flex;
  margin: 0 ${Spacing.contentPadding};
`

export const SchemaModel = styled.tbody<{ disabled?: boolean }>`
  background-color: ${Colors.white};
  border-radius: ${inputStyles.borderRadius};
  width: 100%;
  box-sizing: border-box;
  transition: ${Transitions.baseEaseWithProperty('filter')};

  & + label {
    font-weight: bold;
    pointer-events: none;
    color: ${Colors.grayText};
  }

  &:focus {
    box-shadow: 0 2px 7px rgba(84, 115, 238, 0.2), inset 0 1px 5px rgba(80, 80, 80, 0.1);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

const WrappedLi = styled.li`
  white-space: normal;
  color: ${Colors.red};
`

const CenteredTh = styled.th`
  text-align: center !important;
`

const ErrorsTh = styled(CenteredTh)`
  color: ${Colors.red};
`

const StyledIconRow = styled.span`
  position: relative;
  a {
    font-size: ${fontSizes.type15};
    font-weight: 400;
    display: inline;
  }
  span {
    color: ${Colors.text};
  }
  svg:first-of-type {
    position: absolute;
    width: 16px;
    height: 16px;
    left: -22px;
    top: 2px;
  }
  svg:nth-of-type(2) {
    height: 13px;
    width: 13px;
    margin-left: 2px;
  }
  ${Badge} {
    height: 27px;
    line-height: 17px;
    margin-left: 6px;
    vertical-align: 0px;
  }
`

const JustifyLeft = styled.div`
  margin-right: auto;
  margin-left: 0 !important;
`

const ScrollableDiv = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 305px);
`

const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  background: ${Colors.white};
  z-index: 2;
  width: 100%;
`

const SchemaPropertiesTable = styled(NewStyledTable)`
  margin-bottom: 0 unset;
  table-layout: fixed;
`

const SkinnyTh = styled.td`
  width: 32px;
`

const HoverState = styled.div`
  height: 32px;
  width: 32px;

  &:hover {
    border-radius: 4px;
    background-color: ${Colors.pigeon200};
    padding: 0;
  }

  &:active {
    cursor: grabbing;
    border-radius: 4px;
    background-color: ${Colors.pigeon200};
    padding: 0;
  }

  > svg {
    margin-left: 6px;
    margin-top: 6px;
  }
`

const DraggableClickableTR = styled(ClickableTR)<{ isDragging: boolean; disabled?: boolean }>`
  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: ${Colors.white};
      display: table;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
    `}
`

export const StatusPill = ({ unsavedChanges }: { unsavedChanges: boolean }) => {
  const customStyle = {
    background: Colors.warningBGColor,
    color: Colors.warningStrong,
    height: '28px',
    fontSize: fontSizes.type15,
    padding: '6px 10px'
  }

  return unsavedChanges && <Pill customStyle={customStyle}>{'Changes not saved'}</Pill>
}

const LinkedColumn = ({
  linkedSchema,
  schema
}: {
  linkedSchema: GetSchema_getSchema_linkedSchemas
  schema: ISchema
}) => {
  const teamRoot = useTeamRootUrl()
  const activeTemplateLinksToArchivedTemplate = useMemo(
    () => !schema.archived && linkedSchema.archived,
    [schema, linkedSchema]
  )
  const selfLink = useMemo(() => schema.id === linkedSchema.id, [schema, linkedSchema])
  return !linkedSchema.hasUniques || activeTemplateLinksToArchivedTemplate ? (
    <StyledIconRow>
      <DangerIcon />
      Select a new template
    </StyledIconRow>
  ) : (
    <StyledIconRow>
      {selfLink ? (
        <>
          <LinkIcon fill={Colors.brandText} />
          <span>{linkedSchema.name ?? linkedSchema.id}</span>
        </>
      ) : (
        <Link
          target='_blank'
          to={`${teamRoot}/templates/${linkedSchema.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <LinkIcon fill={Colors.brandText} />
          {linkedSchema.name ?? linkedSchema.id}
          <NewWindow fill={Colors.brandSecondary} />
        </Link>
      )}
      {linkedSchema.archived ? <Badge>Archived</Badge> : ''}
    </StyledIconRow>
  )
}

const FieldInput = ({
  jsonSchemaErrorObj,
  onSubmit,
  onDelete,
  property,
  schema,
  index,
  schemasArray,
  templateHasDefaultValues,
  shouldScroll,
  disabled
}: {
  editing: boolean
  jsonSchemaErrorObj: any
  onSubmit: (newField: any, updatedLinkedSchemas: LinkedSchema[]) => void
  onDelete: () => void
  property: ISchemaProperty
  schema: ISchema
  index: number
  schemasArray: SmartGetSchemas_getSchemas_data[]
  templateHasDefaultValues: boolean
  shouldScroll: boolean
  disabled: boolean
}) => {
  const ref = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    if (ref.current && shouldScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [])

  const schemaPropertyModal = useEditSchemaPropertyModal({
    isNew: false,
    schemaProperty: property,
    schemasArray: schemasArray,
    onSubmit: (property, updatedLinkedSchemas = null) => {
      onSubmit(property, updatedLinkedSchemas)
    }
  })

  const onClickDelete = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onDelete()
    },
    [onDelete]
  )

  const linkedSchema =
    property.type === EPropertyType.SCHEMA_REF
      ? schemasArray.find((schema) => schema.id === property.$schemaId)
      : undefined

  return (
    <Draggable draggableId={property.field} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <DraggableClickableTR
          onClick={!disabled && schemaPropertyModal.open}
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          disabled={disabled}
        >
          {!disabled ? (
            <SkinnyTh>
              <HoverState {...provided.dragHandleProps}>
                <Menu />
              </HoverState>
            </SkinnyTh>
          ) : (
            <td />
          )}
          <td ref={ref}>{property.field}</td>
          <td>{property.label}</td>
          <td>
            {linkedSchema?.id ? (
              <LinkedColumn schema={schema} linkedSchema={linkedSchema} />
            ) : (
              property.type
            )}
          </td>
          <td>
            {property.required ? <Badge>Required</Badge> : null}
            {property.regexp ? <Badge>Regex</Badge> : null}
            {property.unique ? <Badge>Unique</Badge> : null}
            {property.primary ? <Badge>Primary</Badge> : null}
          </td>
          {templateHasDefaultValues && (
            <td>{property.default ? <span>{property.default}</span> : null}</td>
          )}
          <td>
            {!disabled && (
              <ActionsContainer>
                <DeleteButton renderAs='a' color='danger' variant='empty' onClick={onClickDelete}>
                  Delete
                </DeleteButton>
                {schemaPropertyModal.render()}
              </ActionsContainer>
            )}
          </td>
          {Object.keys(jsonSchemaErrorObj).length > 0 && (
            <td colSpan={2}>
              <ol>
                {(jsonSchemaErrorObj[property.field] || []).map((error: string, index: number) => {
                  return <WrappedLi key={index}>{error}</WrappedLi>
                })}
              </ol>
            </td>
          )}
        </DraggableClickableTR>
      )}
    </Draggable>
  )
}

export const BaseFormButtons = ({
  unsavedChanges,
  jsonInvalid,
  onClick
}: {
  unsavedChanges: boolean
  jsonInvalid?: boolean
  onClick?: () => void
}) => {
  return (
    <>
      <StatusPill unsavedChanges={unsavedChanges} />
      {unsavedChanges &&
        (onClick ? (
          <FlatButtonBase color='white' onClick={onClick} fontWeight='bold'>
            Dismiss changes
          </FlatButtonBase>
        ) : (
          <FormButton color='white' reset fontWeight='bold'>
            Dismiss changes
          </FormButton>
        ))}
      <FormButton submit disabled={!unsavedChanges || jsonInvalid}>
        Save
      </FormButton>
    </>
  )
}

const StyledTh = styled.th`
  padding-left: 24px !important;
`

export const SchemaModelInput = ({
  disabled,
  schema,
  fieldAction,
  fieldId,
  jsonSchemaErrors,
  name
}: {
  disabled?: boolean
  schema: ISchema
  fieldAction?: EFieldAction
  fieldId?: string
  jsonSchemaErrors: string[]
  name: string
}) => {
  const formContext = getFormContext()
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [shouldScroll, setShouldScroll] = useState(false)
  const schemasQuery = useGetSchemas({}, { fetchPolicy: 'cache-and-network' })
  const schemasArray = schemasQuery.result?.data ?? []
  const { data } = formContext.value

  const schemaPropArray: ISchemaProperty[] =
    name in formContext.value.data
      ? formContext.value.data[name]
      : schema.jsonSchemaPropArray ?? []

  const update = useCallback(
    (
      updateValue: ISchemaProperty[],
      updatedlinkedSchemas?: LinkedSchema[],
      previewFieldIndex?: number
    ) => {
      let previewFieldChanged = {}

      // if the previewField was updated to a new key, update the previewFieldKey to point to the new one
      if (previewFieldIndex && data.previewFieldKey === schemaPropArray[previewFieldIndex].field) {
        previewFieldChanged = {
          previewFieldKey: updateValue[previewFieldIndex].field
        }
      }

      formContext.setValue(
        updatedlinkedSchemas
          ? {
              data: {
                ...data,
                [name]: updateValue,
                linkedSchemas: updatedlinkedSchemas,
                ...previewFieldChanged
              }
            }
          : { data: { ...data, [name]: updateValue, ...previewFieldChanged } }
      )
    },
    [formContext]
  )

  const jsonSchemaErrorObj: any = deserializeSchemaErrors(jsonSchemaErrors)

  const schemaCreatePropModal = useEditSchemaPropertyModal({
    isNew: true,
    schemaProperty: null,
    schemasArray: schemasArray,
    onSubmit(schemaProperty: ISchemaProperty) {
      update([...schemaPropArray, schemaProperty])
      setShouldScroll(true)
    }
  })

  useEffect(() => {
    setUnsavedChanges(!isEqual(formContext?.value?.data, schema))
  }, [formContext, schema])

  const templateHasDefaultValues =
    schemaPropArray.filter((x) => x.default !== undefined).length > 0

  const beforeUnloadPrompt = useWarnBeforeUnload(
    unsavedChanges,
    'You have unsaved changes. Are you sure you want to leave?'
  )

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const fromIndex = result.source.index
    const toIndex = result.destination!.index
    const updatedPropArray = arrayMove(schemaPropArray, fromIndex, toIndex)
    formContext.setValue({ data: { ...data, jsonSchemaPropArray: updatedPropArray } })
  }

  return (
    <SchemaModelContainer>
      {beforeUnloadPrompt}
      <Row>
        <ButtonsWrapper marginTop>
          <JustifyLeft>
            <FlatButtonBase
              color='primary'
              variant={disabled ? 'default' : 'outlined'}
              onClick={schemaCreatePropModal.open}
              type='button'
              disabled={disabled}
            >
              + Add field
            </FlatButtonBase>
          </JustifyLeft>
          <BaseFormButtons unsavedChanges={unsavedChanges} />
        </ButtonsWrapper>
      </Row>
      <ScrollableDiv>
        <SchemaPropertiesTable>
          <StickyHeader>
            <tr>
              <SkinnyTh></SkinnyTh>
              <StyledTh>Internal key</StyledTh>
              <StyledTh>Column Label</StyledTh>
              <StyledTh>Type</StyledTh>
              <StyledTh>Validation</StyledTh>
              {templateHasDefaultValues && <th>Default</th>}
              {Object.keys(jsonSchemaErrorObj).length > 0 && (
                <ErrorsTh colSpan={2}>Errors</ErrorsTh>
              )}
              <StyledTh></StyledTh>
            </tr>
          </StickyHeader>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='droppable' direction='vertical'>
              {(provided) => (
                <SchemaModel
                  disabled={disabled || formContext.value.submitting}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {schemaPropArray.length === 0 ? (
                    <tr>
                      <td colSpan={5}>
                        <p>
                          There are no configured fields. To configure your first field click on
                          "Add field".
                        </p>
                      </td>
                    </tr>
                  ) : null}
                  {schemaPropArray.map((property, index) => (
                    <FieldInput
                      editing={fieldAction === EFieldAction.edit && fieldId === property.field}
                      jsonSchemaErrorObj={jsonSchemaErrorObj}
                      key={property.field}
                      property={property}
                      schema={schema}
                      templateHasDefaultValues={templateHasDefaultValues}
                      shouldScroll={shouldScroll}
                      schemasArray={schemasArray}
                      index={index}
                      onSubmit={(newField, newLinkedSchemas) => {
                        update(
                          [
                            ...schemaPropArray.slice(0, index),
                            { ...newField },
                            ...schemaPropArray.slice(index + 1)
                          ],
                          newLinkedSchemas,
                          index
                        )
                      }}
                      onDelete={() => {
                        update([
                          ...schemaPropArray.slice(0, index),
                          ...schemaPropArray.slice(index + 1)
                        ])
                      }}
                      disabled={disabled || formContext.value.submitting}
                    />
                  ))}
                  {provided.placeholder}
                </SchemaModel>
              )}
            </Droppable>
          </DragDropContext>
        </SchemaPropertiesTable>
      </ScrollableDiv>
      {schemaCreatePropModal.render()}
    </SchemaModelContainer>
  )
}
