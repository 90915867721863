import { useCallback, useContext } from 'react'
import { useCreateWorkspaceModal } from 'src/applications/Oversight/hooks/useCreateWorkspaceModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FLATFILE_DEVELOPERS_BASE, LIVE_ENVIRONMENT_SLUG } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { getOnboardingContext } from 'src/contexts/OnboardingContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { SidebarLink } from 'src/resources/elements/SecondarySidebarLinks'
import { UnderConstruction } from 'src/resources/elements/UnderConstruction'
import { Spacing } from 'src/resources/layout'
import { Menu } from 'src/resources/sidebars/Menu'
import { named } from 'src/resources/utils/named'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACES } from 'src/smart/queries/SQ_WORKSPACES'
import { ESidebarItemHeadingType } from 'src/types/enums/ESidebarItemHeadingType'
import { ESidebarItemLinkType } from 'src/types/enums/ESidebarItemLinkType'
import { ESidebarItemType } from 'src/types/enums/ESidebarItemType'
import { EWorkspaceStatus } from 'src/types/enums/EWorkspaceStatus'
import { GlobalFrontChatWindow } from 'src/types/frontchat'
import { normalizeRedirectPath } from 'src/utils/normalizeRedirectPath'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'

declare let window: GlobalFrontChatWindow

export const openFrontChat = () => {
  try {
    window['FrontChat']('show')
  } catch {
    // no ability to use front for onprem:
    return
  }
}

export const shutdownFrontChat = () => {
  try {
    window['FrontChat']('shutdown', { clearSession: true })
  } catch {
    // no ability to use front for onprem:
    return
  }
}

const Spacer = styled.div`
  flex-grow: 1;
`

const DevToggleWrapper = styled.div`
  border-bottom: 1px solid ${Colors.pigeon200};
  border-top: 1px solid ${Colors.pigeon200};
  padding-bottom: ${Spacing.basePadding2_5x};
  padding-top: ${Spacing.basePadding2_5x};
  position: relative;
  z-index: 9; /* Places the wrapper 1 step above ProfileComponent which has a z-index of 8 */
`

export const PlatformSidebar = named('PlatformSidebar', () => {
  const { history, location } = useReactRouter()
  const sidebarName = useCallback(() => 'PlatformSidebar.tsx', [])
  const createWorkspaceModal = useCreateWorkspaceModal()
  const team = useContext(TeamContext)
  const sessionContext = useContext(SessionContext)
  const teamRoot = useTeamRootUrl()
  const progressContext = getOnboardingContext().value
  const environment = useContext(EnvironmentContext)
  const useNewDataHooksUI = !team.featureFlags?.V3_DATA_HOOKS_LEGACY
  const hasWorkspaceAccess = team.featureFlags?.PRODUCT_ACCESS_WORKSPACES
  const { isCollaborator } = useTeamRole()

  const workspaces = useSmartQuery(SQ_WORKSPACES, {
    variables: {
      environmentId: environment?.id,
      status: EWorkspaceStatus.ACTIVE,
      teamId: sessionContext.activeTeamId
    }
  })

  if (workspaces.data?.length > 0) progressContext.workspace = true

  const handleDevToggleClick = useCallback(() => {
    if (!team.testModeOnly) {
      history.push(
        normalizeRedirectPath(location.pathname, {
          environmentId:
            environment.slug === LIVE_ENVIRONMENT_SLUG ? 'test' : LIVE_ENVIRONMENT_SLUG,
          search: location.search
        })
      )
    }
  }, [location])

  const platformSidebarLinks: SidebarLink[] = [
    {
      type: ESidebarItemHeadingType.Heading,
      value: 'platform'
    },
    hasWorkspaceAccess && {
      type: ESidebarItemLinkType.Simple,
      url: `${teamRoot}/workspaces`,
      value: 'Workspaces'
    },
    !isCollaborator && {
      type: ESidebarItemLinkType.Simple,
      url: `${teamRoot}/imports`,
      value: 'Imports'
    },
    !isCollaborator &&
      useNewDataHooksUI && {
        type: ESidebarItemLinkType.Simple,
        url: `${teamRoot}/logs`,
        value: 'Logs'
      }
  ]

  const configurationSidebarLinks: SidebarLink[] = !isCollaborator
    ? [
        { type: ESidebarItemHeadingType.Heading, value: 'configuration' },
        {
          type: ESidebarItemLinkType.Simple,
          url: `${teamRoot}/setup`,
          value: 'Portal'
        },
        {
          type: ESidebarItemLinkType.Simple,
          url: `${teamRoot}/templates`,
          value: 'Templates'
        },
        {
          type: ESidebarItemLinkType.Simple,
          url: `${teamRoot}/team`,
          value: 'Team'
        }
      ]
    : []

  const helpSidebarLinks: SidebarLink[] = [
    {
      type: ESidebarItemHeadingType.Heading,
      value: 'help'
    },
    ...(!isCollaborator
      ? [
          {
            type: ESidebarItemLinkType.External,
            url: FLATFILE_DEVELOPERS_BASE,
            value: 'Documentation'
          }
        ]
      : []),
    {
      type: ESidebarItemLinkType.External,
      url: 'https://support.flatfile.com/hc/en-us',
      value: 'Knowledge base'
    },
    {
      type: ESidebarItemType.HelpLaunch,
      value: 'Talk to Support'
    }
  ]

  const sidebarLinks: SidebarLink[] = [
    ...platformSidebarLinks,
    ...configurationSidebarLinks,
    ...helpSidebarLinks
  ].filter((x) => x)

  const devSwitcher = team.capabilities.mayManageSchemas && (
    <>
      <Spacer />
      {!team.testModeOnly && (
        <DevToggleWrapper>
          <BooleanInput
            label='Developer mode'
            value={environment.slug !== LIVE_ENVIRONMENT_SLUG}
            onClick={handleDevToggleClick}
            disabled={team.testModeOnly}
          />
        </DevToggleWrapper>
      )}
    </>
  )

  return (
    <>
      {createWorkspaceModal.render()}
      <Menu
        onClick={createWorkspaceModal.open}
        sidebarLinks={sidebarLinks}
        endContent={devSwitcher}
      >
        <UnderConstruction render={sidebarName} />
      </Menu>
    </>
  )
})
