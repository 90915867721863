import { SmartGetViewSchema_getViewSchema_linkedSchemas } from 'src/smart/queries/types/SmartGetViewSchema'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'

import { DataSourceField } from './classes/DataSourceField'
import { IMenuOption } from './components/MenuSelect'

export interface Enum {
  sourceValue: string
  enumValue?: string
}

export enum ColumnOptionKey {
  suggested = 'suggested',
  unmatched = 'unmatched',
  matched = 'matched'
}

type ColumnOptions = {
  [key in ColumnOptionKey]?: IMenuOption[]
}

export const enumCompare = (a: Enum, b: Enum) => {
  if (typeof a.enumValue === typeof b.enumValue) {
    return a.sourceValue.localeCompare(b.sourceValue)
  } else {
    return typeof a.enumValue === 'string' ? 1 : -1
  }
}

export const getGroupedOptions = (
  schemaProperties: JsonSchemaProperty[],
  dataSourceField: DataSourceField,
  templateName: string,
  templateId: string,
  linkedTemplates: SmartGetViewSchema_getViewSchema_linkedSchemas[],
  activeMatchKeys: string[]
): IMenuOption[] => {
  const memoizedTemplates = linkedTemplates.reduce(
    (templateObj: Record<string, SmartGetViewSchema_getViewSchema_linkedSchemas>, template) => ({
      ...templateObj,
      [template.id]: template
    }),
    {}
  )

  const columnOptionsObject = schemaProperties.reduce(
    (acc, prop) => {
      if (prop.visibility?.mapping === false) {
        return acc
      }

      const option: IMenuOption = {
        $schema: prop.$schema,
        label: prop.label,
        required: prop.required || prop.primary,
        unique: prop.unique,
        value: prop.field,
        type: prop.type,
        custom: prop.custom
      }

      const optionMatched = activeMatchKeys.includes(option.value)
      const optionSuggested = dataSourceField.suggestedMatchKey?.includes(option.value)

      if (linkedTemplates.length > 0) {
        let fieldTemplateName = option.$schema?.id
          ? memoizedTemplates[option.$schema.id]?.name
          : templateName

        // TODO: need design input on how to differentiate self linked templates
        // options are grouped in dropdown by templateName
        if (option.$schema?.id && memoizedTemplates[option.$schema.id]?.name === templateName) {
          fieldTemplateName = `Self linked > ${templateName}`
        }

        const defaultRelationalOption = {
          label: fieldTemplateName,
          subheading: true,
          templateName: fieldTemplateName,
          options: [{ ...option }],
          isLinkedTemplate: templateName !== fieldTemplateName || prop.$schemaId === templateId
        }

        if (optionMatched) {
          const templateOptions = acc[ColumnOptionKey.matched].find(
            (match) => match.label === fieldTemplateName
          )
          templateOptions
            ? templateOptions.options.push({ ...option })
            : acc[ColumnOptionKey.matched].push({
                ...defaultRelationalOption,
                columnOption: ColumnOptionKey.matched
              })
        } else if (optionSuggested) {
          acc[ColumnOptionKey.suggested].push({
            ...option,
            columnOption: ColumnOptionKey.suggested,
            templateName: fieldTemplateName
          })
        } else {
          const templateOptions = acc[ColumnOptionKey.unmatched].find(
            (unmatch) => unmatch.label === fieldTemplateName
          )
          templateOptions
            ? templateOptions.options.push({ ...option })
            : acc[ColumnOptionKey.unmatched].push({
                ...defaultRelationalOption,
                columnOption: ColumnOptionKey.unmatched
              })
        }
      } else {
        if (optionMatched) {
          acc[ColumnOptionKey.matched].push({
            ...option,
            columnOption: ColumnOptionKey.matched
          })
        } else if (optionSuggested) {
          acc[ColumnOptionKey.suggested].push({
            ...option,
            columnOption: ColumnOptionKey.suggested
          })
        } else {
          acc[ColumnOptionKey.unmatched].push({
            ...option,
            columnOption: ColumnOptionKey.unmatched
          })
        }
      }
      return acc
    },
    {
      suggested: [],
      unmatched: [],
      matched: []
    } as ColumnOptions
  )

  const optionsArray: IMenuOption[] = []

  //sets headings as disabled options
  Object.values(ColumnOptionKey).forEach((key) => {
    if (columnOptionsObject[key].length > 0) {
      optionsArray.push({ label: key, isDisabled: true }, ...columnOptionsObject[key])
    }
  })

  return optionsArray
}

export const sortEnumFields = (f: Record<string, string | undefined | null>): Enum[] => {
  return Object.entries(f)
    .map(([sourceValue, enumValue]) => ({
      enumValue,
      sourceValue
    }))
    .sort(enumCompare)
}
