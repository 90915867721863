export type Duration =
  | '0.1s'
  | '0.2s'
  | '0.3s'
  | '0.4s'
  | '0.5s'
  | '0.6s'
  | '0.7s'
  | '0.8s'
  | '0.9s'
  | '1.0s'
  | '1.1s'
  | '1.2s'
  | '1.3s'
  | '1.4s'
  | '1.5s'

export const time5: Duration = '0.5s'
