import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useParams } from 'src/resources/hooks/useParams'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

import { HeaderRowSelectionPanel } from '../../panels/HeaderRowSelectionPanel'

const Wrapper = styled.div`
  padding: 40px;
`

export const WorkspaceUploadSourceHeaderRowScene = () => {
  const { dataSourceIndex, schemaId, uploadId, workspaceId } = useParams()
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()

  return (
    <Wrapper>
      <HeaderRowSelectionPanel
        dataSourceIndex={parseInt(dataSourceIndex, 10)}
        schemaId={parseInt(schemaId, 10)}
        uploadId={uploadId}
        onReset={() => location.reload()}
        onComplete={({ viewId }) => {
          history.push(
            `${teamRoot}/workspaces/${workspaceId}/uploads/${uploadId}/view/${viewId}/match`
          )
        }}
      />
    </Wrapper>
  )
}
