import { ReactText } from 'react'

import { useToasts } from 'react-toast-notifications'
import { createContextState } from 'src/resources/hooks/createContextState'
import { localStorageOverride } from 'src/utils/localStorageOverride'

export interface IErrorContext {
  errors: (IErrorType | Error)[]
}

export interface IErrorType {
  message: string
  path?: readonly ReactText[]
}

export const [ErrorContext, getErrorContext] = createContextState<IErrorContext>('Error', {
  errors: []
})

const DEBUG_MODE = localStorageOverride('DEBUG', 'off') === 'on'
const DEBUG_ON_ERROR =
  localStorageOverride('DEBUG ON ERROR', 'off', { nestUnder: 'DEBUG' }) === 'on' && DEBUG_MODE

export const ErrorDisplay = (): null => {
  const {
    value: { errors },
    setValue
  } = getErrorContext()

  const { addToast } = useToasts()

  if (!errors.length) {
    return null
  }

  errors.forEach((e) => {
    window.setTimeout(() => {
      if (e.message === 'Failed to fetch' || e.message === 'Team not found') {
        return
      }
      return addToast(String(e.message) === e.message ? e.message : 'Unknown Error', {
        appearance: 'error',
        autoDismiss: true
      })
    }, 5)

    if (DEBUG_ON_ERROR) {
      // eslint-disable-next-line no-debugger
      debugger
    }
  })

  setTimeout(() => setValue({ errors: [] }), 0)

  return null
}
