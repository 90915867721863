import { gql } from '@apollo/client'

export const GET_ADMIN_ORGANIZATIONS = gql`
  query GetAdminOrganizations($take: Int!, $skip: Int!, $teamId: ID!, $environmentId: UUID) {
    getOrganizationsForTeam(
      take: $take
      skip: $skip
      teamId: $teamId
      environmentId: $environmentId
    ) {
      pagination {
        currentPage
        limit
        nextOffset
        offset
        onPage
        pageCount
        previousOffset
        totalCount
      }
      data {
        id
        name
        createdAt
        updatedAt
        deletable
      }
    }
  }
`
