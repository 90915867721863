import { createContext } from 'react'

export interface IBillingPlan {
  deactivated: boolean
  loaded: boolean
  trialEndsAt: number
}

export const BillingPlanContext = createContext<IBillingPlan>({
  deactivated: false,
  loaded: false,
  trialEndsAt: NaN
})
