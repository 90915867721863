import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'

interface ICreateAccessKeyFormData {
  memo: string
}

const CreateAccessKeyFormElement: TForm<ICreateAccessKeyFormData> = Form

export const CreateAccessKeyForm = ({
  onSubmit,
  onCancel
}: {
  onSubmit: (event: IFormSubmitEvent<ICreateAccessKeyFormData>) => Promise<void>
  onCancel: () => void
}) => (
  <CreateAccessKeyFormElement onSubmit={onSubmit} initialValue={{ memo: '' }}>
    <Input label='Memo' name='memo' />
    <ErrorMessage name='memo' />
    <ButtonGroup left>
      <FormButton primary submit>
        Create
      </FormButton>
      <FormButton secondary onClick={onCancel}>
        Cancel
      </FormButton>
    </ButtonGroup>
  </CreateAccessKeyFormElement>
)
