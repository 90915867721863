import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${Colors.elemBorder};
  margin-bottom: ${Spacing.basePadding5x};
  margin-top: ${Spacing.basePadding5x};
`
