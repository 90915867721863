import { MutationTuple, useMutation } from '@apollo/client'
import { REMOVE_WORKSPACE_INVITATION } from '../REMOVE_WORKSPACE_INVITATION'
import {
  RemoveWorkspaceInvitation,
  RemoveWorkspaceInvitationVariables
} from '../types/RemoveWorkspaceInvitation'

export const useRemoveWorkspaceInvitationMutation = (): MutationTuple<
  RemoveWorkspaceInvitation,
  RemoveWorkspaceInvitationVariables
> => {
  const [removeWorkspaceInvitation, results] = useMutation<
    RemoveWorkspaceInvitation,
    RemoveWorkspaceInvitationVariables
  >(REMOVE_WORKSPACE_INVITATION)

  return [removeWorkspaceInvitation, results]
}
