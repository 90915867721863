import { Colors } from 'src/resources/colors'
import styled, { css } from 'styled-components'

export const Card = styled.section<{ fullHeight?: boolean }>`
  background: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px -6px rgba(0, 0, 0, 0.15),
    0 6px 24px -8px rgba(0, 0, 0, 0.1);
  position: relative;
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      display: flex;
      flex-direction: column;
      height: calc(100vh - 138px);
    `}
`
