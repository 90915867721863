import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'

interface IAddSchemaFormData {
  name: string
}

const AddSchemaFormElement: TForm<IAddSchemaFormData> = Form

export const AddSchemaForm = ({
  onSubmit,
  onCancel
}: {
  onSubmit: (event: IFormSubmitEvent<IAddSchemaFormData>) => Promise<void>
  onCancel: () => void
}) => (
  <AddSchemaFormElement onSubmit={onSubmit}>
    <InputGroup>
      <Input autoFocus label='Data template name' name='name' onKeys={{ Escape: onCancel }} />
      <ErrorMessage name='name' />
    </InputGroup>
    <ButtonGroup left>
      <FormButton primary submit>
        Configure
      </FormButton>
      <FormButton secondary onClick={onCancel}>
        Cancel
      </FormButton>
    </ButtonGroup>
  </AddSchemaFormElement>
)
