import { MutationTuple, useMutation } from '@apollo/client'
import { REFRESH_WORKSPACE_INVITATION } from 'src/queries/REFRESH_WORKSPACE_INVITATION'
import {
  RefreshWorkspaceInvitation,
  RefreshWorkspaceInvitationVariables
} from '../types/RefreshWorkspaceInvitation'

export const useRefreshWorkspaceInvitationMutation = (): MutationTuple<
  RefreshWorkspaceInvitation,
  RefreshWorkspaceInvitationVariables
> => {
  return useMutation<RefreshWorkspaceInvitation, RefreshWorkspaceInvitationVariables>(
    REFRESH_WORKSPACE_INVITATION
  )
}
