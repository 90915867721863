import { ReactNode } from 'react'

import { HighLayer1, LowLayer1 } from 'src/applications/Oversight/layers'
import { localStorageOverride } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

export const ConstructionWarningSign = styled.div`
  padding: 0 10px;
  margin: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 0.2em rgba(255, 0, 0);
  opacity: 0.5;
`

export const ConstructionDots = styled.div`
  outline: 2px dotted red;
  overflow: visible;
  box-shadow: 0 0 0.2em rgba(255, 0, 0);
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  pointer-events: none;
  z-index: ${HighLayer1};
  opacity: 0.5;
`

export const StyledContainer = styled.div`
  position: relative;

  &:hover ${ConstructionDots} {
    opacity: 0.2;
  }
`

export const ConstructionPaper = styled.div`
  overflow: hidden;
  background: white;
  position: relative;
  z-index: ${LowLayer1};
`

export const Label = styled.label`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  vertical-align: 2px;
`

const showUnderConstruction = localStorageOverride('Show 🚧 Under construction', 'off') === 'on'

export const UnderConstruction = ({ render }: { render(): ReactNode }) => {
  return showUnderConstruction ? (
    <>
      <ConstructionWarningSign>
        🚧 <Label>Under construction</Label>
      </ConstructionWarningSign>
      <StyledContainer>
        <ConstructionDots />
        <ConstructionPaper>{render()}</ConstructionPaper>
      </StyledContainer>
    </>
  ) : null
}
