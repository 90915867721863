import { Link } from 'react-router-dom'
import { Colors } from 'src/resources/colors'
import arrowLeft from 'src/resources/icons/arrow-left.svg'
import arrowRight from 'src/resources/icons/arrow-right.svg'
import { Spacing } from 'src/resources/layout'
import styled, { css } from 'styled-components'
import { fontFamily } from 'src/resources/typography'

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: ${Spacing.contentPadding};
`

export const PaginationItem = styled(Link)<{
  selected?: boolean
  disabled?: boolean
  hidden?: boolean
}>`
  font-family: ${fontFamily.fontPrimary};
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 0.875rem;
  color: ${Colors.blackLight};
  margin-right: 4px;
  font-weight: 500;

  ${({ selected, disabled, hidden }) => {
    if (selected) {
      return css`
        background-color: ${Colors.brandPrimary};
        color: ${Colors.white};
      `
    }

    if (disabled) {
      return hidden
        ? css`
            pointer-events: none;
            opacity: 0;
          `
        : css`
            pointer-events: none;
          `
    }

    return ``
  }}
`

const PaginationDirectionArrow = styled.span`
  width: 12px;
  height: 12px;
  background-position: center center;
  background-repeat: no-repeat;
`

export const PaginationPrevious = styled(PaginationDirectionArrow)`
  background-image: url(${arrowLeft});
`
export const PaginationNext = styled(PaginationDirectionArrow)`
  background-image: url(${arrowRight});
`
