import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { CheckBox } from 'src/resources/elements/form/CheckBox'
import {
  FlatInputContainer,
  FlatInputDiv,
  ICommonInputProps,
  Input,
  InputGroup,
  InputHeader,
  InputLabel,
  RequiredMark
} from 'src/resources/elements/form/Input'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { Spacing } from 'src/resources/layout'
import {
  ISchemaProperty,
  ISchemaStub,
  JsonSchemaProperty,
  JsonSchemaPropertyType,
  PRIMARY_COLUMN_DATA_TYPES,
  PRIMARY_COLUMN_DATA_TYPE_VALUES,
  SCHEMA_DATA_TYPES
} from 'src/types/interfaces/ISchema'
import { EPropertyType } from 'src/utils/data/Schema'
import { sortAndMapSchemas } from 'src/utils/sortAndMapSchemas'
import { titleCase } from 'src/utils/titleCase'
import styled, { css } from 'styled-components'
import { FieldLabel, HelperText } from 'src/resources/elements/form/FieldLabel'
import { fontSizes } from 'src/resources/typography'
import { Transitions } from 'src/resources/animations/transitions'
import { getFormContext } from 'src/resources/elements/form/Form'
import { GetSchema_getSchema_linkedSchemas } from 'src/queries/types/GetSchema'
import { ErrorContainer } from 'src/resources/elements/form/ErrorMessage'
import { TeamContext } from 'src/contexts/TeamContext'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'

interface PropertyTypeSelectOption extends SelectOption {
  value: JsonSchemaPropertyType
}

export interface LinkedSchema extends GetSchema_getSchema_linkedSchemas {
  previewFieldKey: string
}

const StyledForm = styled.form`
  margin-top: ${Spacing.basePadding2x};
`

const FlexyRow = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    min-width: 135px;
    width: 100%;
  }

  ${InputLabel} {
    margin-top: 0;
  }
`

export const SpacedFlexyRow = styled(FlexyRow)`
  > div {
    &:first-child {
      margin-left: 0;
      margin-right: ${Spacing.basePadding};
    }
    &:last-child {
      margin-left: ${Spacing.basePadding};
      margin-right: 0;
    }
  }
  ${InputLabel} {
    margin-top: 0;
  }
`

const StyledFlexyRow = styled(FlexyRow)`
  margin-top: ${Spacing.basePadding};
  ${InputLabel} {
    margin-top: 0;
  }
`

const ZeroMarginChild = styled.div`
  > div {
    margin: 0;
  }
  & + div {
    margin-top: ${Spacing.basePadding2_5x};
  }
`

const SecondaryInput = styled(Input)`
  max-width: 180px;
`

const WideInput = styled(Input)`
  max-width: unset;
`

const FlagsInputWrap = styled(FlatInputDiv)`
  display: flex;
  padding: 4px ${Spacing.basePadding};
`

const InputContainer = styled.div`
  flex-direction: row;
`
interface IFlagsInputProps extends ICommonInputProps {
  formValue: string
  onChange(newFlags: string): void
}

const FlagTip = styled.div`
  color: ${Colors.pigeon700};
  display: block;
  font-size: ${fontSizes.type12};
  left: 0;
  letter-spacing: 0;
  margin: ${Spacing.halfBasePadding} 0;
  position: absolute;
  opacity: 0;
  right: 0;
  text-align: left;
  top: 100%;
  transition: ${Transitions.baseEaseWithProperty('opacity')};
`

const FlagInitial = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`

const StyledFlag = styled.div<{ active: boolean }>`
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  line-height: 22px;
  margin: 4px;
  text-align: center;

  &:hover {
    ${FlagTip} {
      opacity: 1;
    }
  }

  ${({ active }) =>
    active
      ? css`
          &,
          &:hover {
            background-color: ${Colors.brandPrimary};
            color: ${Colors.white};
          }
        `
      : css`
          color: ${Colors.gray60};

          &:hover {
            background-color: ${Colors.gray20};
          }
        `}
`

const Flag = ({
  active,
  description,
  flag,
  onChange
}: {
  active: boolean
  description: string
  flag: string
  onChange(flag: string, active: boolean): void
}) => (
  <StyledFlag
    active={active}
    onClick={useCallback(() => onChange(flag, !active), [active, onChange])}
  >
    <FlagTip>
      <FlagInitial>{flag}</FlagInitial> = {description}
    </FlagTip>
    <FlagInitial>{flag}</FlagInitial>
  </StyledFlag>
)

const FlagsInput = ({ label, required, formValue = '', onChange }: IFlagsInputProps) => {
  const modifyFlag = useCallback(
    (flag: string, active: boolean) =>
      onChange(active ? `${formValue}${flag}` : formValue.replace(flag, '')),
    [formValue, onChange]
  )

  return (
    <FlatInputContainer>
      {label || required ? (
        <InputLabel title={required ? `${label} is required` : undefined}>
          {label}
          {required ? <RequiredMark>*</RequiredMark> : null}
        </InputLabel>
      ) : null}
      <FlagsInputWrap>
        {[
          ['i', 'ignore case'],
          ['m', 'multiline'],
          ['s', '. matches newlines'],
          ['g', 'global'],
          ['u', 'unicode']
        ].map(([flag, description]) => (
          <Flag
            active={formValue.indexOf(flag[0]) !== -1}
            description={description}
            flag={flag}
            key={flag}
            onChange={modifyFlag}
          />
        ))}
      </FlagsInputWrap>
    </FlatInputContainer>
  )
}

const NoUniqueFieldsLabel = styled.div`
  display: inline-block;
  color: ${Colors.grayMuted};
  margin-left: ${Spacing.basePadding};

  &:hover {
    cursor: not-allowed;
  }
`

const ToSpan = styled.span`
  align-self: center;
  color: ${Colors.pigeon700};
  font-weight: 600;
  margin-top: ${Spacing.basePadding};
`

const CustomOption = ({ label, disabled }: { label: string; disabled?: boolean }) => {
  return (
    <>
      {label}
      {disabled && <NoUniqueFieldsLabel> no unique fields</NoUniqueFieldsLabel>}
    </>
  )
}

export const EditSchemaPropertyForm = ({
  isNew,
  iSchemaProperty,
  onCancel,
  onSubmit,
  schemas
}: {
  isNew: boolean
  iSchemaProperty: ISchemaProperty
  onCancel: () => void
  onSubmit: (data: ISchemaProperty, updatedLinkedSchemas: LinkedSchema[]) => void | Promise<void>
  schemas?: ISchemaStub[]
}) => {
  const needToHaveOptionOnCategoryFieldMessage =
    'Add at least 1 option to finish configuring this field'
  const team = useContext(TeamContext)
  const relationalFlag = team.featureFlags?.FEATURE_DATA_RELATIONAL

  const selfLinkedTemplatesFlag = team.featureFlags?.RELATIONAL_SELF_LINKED_TEMPLATES
  const formContext = getFormContext()
  const { data, errors } = formContext.value
  const { linkedSchemas } = data

  const [schemaProperty, setSchemaProperty] = useState({
    ...iSchemaProperty,
    type: iSchemaProperty?.type || 'string'
  })

  const selectedSchema = useMemo(
    () => schemas?.find((schema) => schema.id === schemaProperty?.$schemaId),
    [schemas, schemaProperty?.$schemaId]
  )

  const formLinkedSchema: LinkedSchema = useMemo(() => {
    return linkedSchemas?.find((schema: any) => schema.id === selectedSchema?.id)
  }, [formContext, selectedSchema])

  // temporary filter for data types
  const FILTERED_TYPES = relationalFlag
    ? SCHEMA_DATA_TYPES
    : SCHEMA_DATA_TYPES.filter((type) => type.value !== EPropertyType.SCHEMA_REF)
  const [columnTypes, setColumnTypes] = useState(FILTERED_TYPES)
  const [isColumnLabelDirty, setIsColumnLabelDirty] = useState(false)
  const [previewFieldKey, setPreviewFieldKey] = useState<string>(
    selectedSchema?.previewFieldKey ?? null
  )

  const previewFieldKeyOptions = useMemo(
    () =>
      Object.entries(selectedSchema?.jsonSchema?.schema?.properties ?? {}).map(
        ([key, prop]: [string, ISchemaProperty]) => ({ value: key, label: prop.label })
      ),
    [selectedSchema?.jsonSchema?.schema?.properties]
  )

  const schemaOptions: SelectOption[] = useMemo(
    () =>
      sortAndMapSchemas(
        (selfLinkedTemplatesFlag
          ? schemas
          : schemas?.filter((s) => s.id !== schemaProperty?.$schemaId)) ?? [],
        (schema) => !schema.hasUniques,
        'no unique values'
      ),
    [schemas, selfLinkedTemplatesFlag]
  )

  const updateLinkedSchemas = (selectedSchema: ISchemaStub, previewFieldKey: string) => {
    if (!linkedSchemas.includes((schema: LinkedSchema) => schema.id === selectedSchema.id)) {
      const selectedLinkedSchema = (({ archived, hasUniques, id, name }) => ({
        archived,
        hasUniques,
        id,
        name,
        previewFieldKey
      }))(selectedSchema)
      selectedLinkedSchema.previewFieldKey = previewFieldKey
      return linkedSchemas.concat(selectedLinkedSchema)
    } else {
      return linkedSchemas.map((schema: any) =>
        schema.id === selectedSchema.id ? { ...schema, previewFieldKey } : schema
      )
    }
  }

  useEffect(() => {
    setPreviewFieldKey(
      formLinkedSchema?.previewFieldKey ?? selectedSchema?.previewFieldKey ?? null
    )
  }, [selectedSchema, schemaProperty.$schemaId, formLinkedSchema])

  useEffect(() => {
    if (schemaProperty.primary) {
      setColumnTypes(PRIMARY_COLUMN_DATA_TYPES)
      if (!PRIMARY_COLUMN_DATA_TYPE_VALUES.includes(schemaProperty.type)) {
        setSchemaProperty({ ...schemaProperty, type: null })
      }
    } else {
      setColumnTypes(FILTERED_TYPES)
    }
  }, [schemaProperty.primary])

  const handleSubmit = () => {
    const schemaPropertyData: any = { ...schemaProperty }
    Object.keys(schemaPropertyData).forEach(
      (key) => schemaPropertyData[key] === undefined && delete schemaPropertyData[key]
    )

    if (schemaPropertyData.enumArray) {
      schemaPropertyData.enumArray = schemaPropertyData.enumArray
        .map((category: { label?: string; value?: string }) =>
          category.label ? category : { ...category, label: category.value }
        )
        .filter((category: { label?: string; value?: string }) => Boolean(category.value))
    }

    let linkedSchemas = null
    if (
      schemaProperty.type === EPropertyType.SCHEMA_REF &&
      selectedSchema &&
      previewFieldKey &&
      selectedSchema.previewFieldKey !== previewFieldKey
    ) {
      linkedSchemas = updateLinkedSchemas(selectedSchema, previewFieldKey)
    }

    if (schemaPropertyData.type && schemaPropertyData.field && schemaPropertyData.label) {
      onSubmit(schemaPropertyData, linkedSchemas)
    }
  }

  const addNewCategory = useCallback(() => {
    const enumArray = 'enumArray' in schemaProperty ? schemaProperty.enumArray : []
    const enumArrayLength = enumArray.length
    if (
      enumArrayLength === 0 ||
      enumArray.every((category: { label?: string; value?: string }) => category.value)
    ) {
      setSchemaProperty((prevSchema) => ({
        ...prevSchema,
        enumArray: prevSchema.enumArray
          ? [...prevSchema.enumArray, { label: undefined, value: undefined }]
          : [{ label: undefined, value: undefined }]
      }))

      window.setTimeout(() => {
        const focusableElement = document.getElementById(`enum-value-${enumArrayLength}`)
        if (focusableElement) focusableElement.focus()
      })
    }
  }, [schemaProperty])

  const labelKeyDownHandler = useCallback(() => {
    setIsColumnLabelDirty(true)
  }, [])

  const labelChangeHandler = useCallback(
    (label: string) => {
      setSchemaProperty({ ...schemaProperty, label })
    },
    [schemaProperty]
  )

  const keyChangeHandler = useCallback(
    (field: string) => {
      setSchemaProperty({ ...schemaProperty, field })
    },
    [schemaProperty, setSchemaProperty]
  )

  const keyBlurHandler = useCallback(
    (fieldValue: string) => {
      const isDuplicate = formContext.value.data.jsonSchemaPropArray.some(
        (property: JsonSchemaProperty) => property.field.toLowerCase() === fieldValue.toLowerCase()
      )
      const showDuplicateError = isNew
        ? isDuplicate
        : isDuplicate && iSchemaProperty.field !== fieldValue

      const { field, ...otherErrors } = errors

      if (fieldValue === '') {
        formContext.setValue({ errors: { ...otherErrors, field: 'This key is required' } })
      } else if (showDuplicateError) {
        formContext.setValue({ errors: { ...otherErrors, field: 'Key must be unique' } })
      } else {
        formContext.setValue({ errors: { ...otherErrors } })
      }

      const shouldUpdateColumnLabel =
        (!isColumnLabelDirty || !schemaProperty.label) && !showDuplicateError
      if (shouldUpdateColumnLabel) {
        setSchemaProperty({ ...schemaProperty, label: titleCase(fieldValue) })
        const { label, ...restOfErrors } = otherErrors
        formContext.setValue({ errors: { ...restOfErrors } })
      }
    },
    [isColumnLabelDirty, schemaProperty, setSchemaProperty]
  )

  const labelBlurHandler = useCallback(
    (field: string) => {
      const { label, ...otherErrors } = errors

      if (field === '') {
        formContext.setValue({ errors: { ...otherErrors, label: 'This field is required' } })
      } else {
        formContext.setValue({ errors: { ...otherErrors } })
      }
    },
    [schemaProperty, setSchemaProperty]
  )

  const fieldTypeChangeHandler = useCallback(
    (option: PropertyTypeSelectOption) => {
      setSchemaProperty({ ...schemaProperty, type: option.value, default: null })
    },
    [schemaProperty, setSchemaProperty]
  )

  const linkedSchemaChangeHandler = useCallback(
    (option: PropertyTypeSelectOption) => {
      setSchemaProperty({ ...schemaProperty, $schemaId: option.value })
    },
    [schemaProperty, setSchemaProperty]
  )

  const isCategoryFieldEmpty = useMemo(() => {
    const { enumArray, type } = schemaProperty
    return type === 'category' && (!enumArray?.length || !enumArray?.[0].value)
  }, [schemaProperty])

  const disableForm = useMemo(() => {
    if (!(schemaProperty.type && schemaProperty.field && schemaProperty.label)) {
      return true
    }
    if (Object.keys(formContext.value.errors).length !== 0) {
      return true
    }

    if (isCategoryFieldEmpty) {
      return true
    }
    return (
      schemaProperty.type === EPropertyType.SCHEMA_REF &&
      (!schemaProperty.$schemaId || !previewFieldKey)
    )
  }, [schemaProperty, previewFieldKey, formContext.value.errors])

  useEffect(() => {
    formContext.setValue({ errors: {} })
  }, [])

  return (
    <StyledForm data-testid='edit-schema-property-form'>
      <InputGroup>
        <SpacedFlexyRow>
          <InputContainer>
            <Input
              autoFocus={!schemaProperty.field}
              formValue={schemaProperty.field}
              label='Internal key'
              onBlur={keyBlurHandler}
              onChange={keyChangeHandler}
              onKeys={{ Escape: onCancel }}
              placeholder='field_name'
              showRequired={true}
            />
            {formContext?.value?.errors?.field && (
              <ErrorContainer> {formContext?.value?.errors?.field}</ErrorContainer>
            )}
          </InputContainer>
          <InputContainer>
            <Input
              formValue={schemaProperty.label}
              label='Field label'
              onBlur={labelBlurHandler}
              onChange={labelChangeHandler}
              onKeyDown={labelKeyDownHandler}
              onKeys={{ Escape: onCancel }}
              placeholder='Field name'
              showRequired={true}
            />
            {formContext?.value?.errors?.label && (
              <ErrorContainer> {formContext?.value?.errors?.label}</ErrorContainer>
            )}
          </InputContainer>
        </SpacedFlexyRow>
      </InputGroup>

      <InputGroup>
        <Select
          key={schemaProperty.type}
          label='Field type'
          required={true}
          options={columnTypes}
          selectedValue={schemaProperty.type}
          onChange={fieldTypeChangeHandler}
        />
      </InputGroup>

      {schemaProperty.type === 'category' && (
        <>
          <InputGroup>
            {schemaProperty.enumArray && schemaProperty.enumArray.length ? (
              <SpacedFlexyRow>
                <div>
                  <InputHeader label='Internal Value' required showRequired />
                </div>
                <div>
                  <InputHeader label='Label' optional />
                </div>
              </SpacedFlexyRow>
            ) : null}
            {schemaProperty.enumArray &&
              schemaProperty.enumArray.map((enumObject, index) => {
                return (
                  <SpacedFlexyRow key={index}>
                    <SecondaryInput
                      formValue={enumObject.value}
                      id={`enum-value-${index}`}
                      key={`enum-value-${index}`}
                      placeholder='option_value'
                      onChange={(value: string) => {
                        const enumArrayCopy = schemaProperty.enumArray.map(
                          (enumObject, enumIndex) => {
                            if (index === enumIndex) {
                              return {
                                ...enumObject,
                                value
                              }
                            }
                            return enumObject
                          }
                        )

                        setSchemaProperty({
                          ...schemaProperty,
                          enumArray: enumArrayCopy,
                          default: enumArrayCopy.some(
                            ({ value }) => value === schemaProperty.default
                          )
                            ? schemaProperty.default
                            : ''
                        })
                      }}
                      onKeys={{ Enter: addNewCategory }}
                    />
                    <SecondaryInput
                      id={`enum-label-${index}`}
                      key={`enum-label-${index}`}
                      placeholder='Option label'
                      formValue={enumObject.label}
                      onChange={(label: string) => {
                        const enumArrayCopy = schemaProperty.enumArray.map(
                          (enumObject, enumIndex) => {
                            if (index === enumIndex) {
                              return {
                                ...enumObject,
                                label
                              }
                            }
                            return enumObject
                          }
                        )

                        setSchemaProperty({ ...schemaProperty, enumArray: enumArrayCopy })
                      }}
                      onKeys={{ Enter: addNewCategory }}
                    />
                  </SpacedFlexyRow>
                )
              })}
          </InputGroup>
          <FormButton
            color='white'
            onClick={addNewCategory}
            style={{
              fontWeight: 600,
              marginBottom: `20px`,
              marginTop: `-20px`,
              textAlign: `left`,
              width: `100%`
            }}
          >
            + Add option
          </FormButton>
        </>
      )}

      {schemaProperty.type === EPropertyType.SCHEMA_REF && (
        <>
          <Select
            label='Template'
            required
            selectedValue={schemaProperty.$schemaId}
            formatOptionLabel={CustomOption}
            onChange={linkedSchemaChangeHandler}
            options={[...schemaOptions]}
          />
          {schemaProperty.$schemaId && (
            <Select
              key={schemaProperty.$schemaId}
              label='Preview Field'
              helperText={`Select a field from ${
                schemaOptions.find((option) => option.value === schemaProperty.$schemaId)?.label ??
                'the selected template'
              } to use as a visual preview of the linked data. The value selected here will apply to all linked templates.`}
              required
              selectedValue={previewFieldKey}
              onChange={({ value }) => {
                setPreviewFieldKey(value)
              }}
              options={[...previewFieldKeyOptions]}
            />
          )}
        </>
      )}

      {schemaProperty.type === 'string' && (
        <InputGroup>
          <ZeroMarginChild>
            <CheckBox
              label='Set regex validation'
              type='secondary'
              checked={!!schemaProperty.regexp}
              onChange={(regexEnabled) => {
                setSchemaProperty({
                  ...schemaProperty,
                  regexp: regexEnabled ? { pattern: '', flags: '', ignoreBlanks: false } : null
                })
              }}
            />
          </ZeroMarginChild>
          {schemaProperty.regexp && (
            <FlexyRow>
              <SecondaryInput
                label='Pattern'
                placeholder='Regex pattern'
                formValue={schemaProperty.regexp.pattern}
                onChange={(pattern) => {
                  setSchemaProperty({
                    ...schemaProperty,
                    regexp: {
                      ...schemaProperty.regexp,
                      pattern
                    }
                  })
                }}
              />
              <div style={{ width: '32px', height: '1px', minWidth: 'auto' }} />
              <FlagsInput
                label='Flags'
                formValue={schemaProperty.regexp.flags}
                onChange={(flags) => {
                  setSchemaProperty({
                    ...schemaProperty,
                    regexp: { ...schemaProperty.regexp, flags }
                  })
                }}
              />
            </FlexyRow>
          )}
        </InputGroup>
      )}

      {schemaProperty.type === 'number' && (
        <InputGroup>
          <FieldLabel>Value range</FieldLabel>
          <SpacedFlexyRow>
            <Input
              type='number'
              placeholder='Minimum value'
              formValue={schemaProperty.minimum}
              onChange={(minimum) => {
                setSchemaProperty({ ...schemaProperty, minimum: +minimum })
              }}
            />
            <ToSpan>to</ToSpan>
            <Input
              type='number'
              placeholder='Maximum value'
              formValue={schemaProperty.maximum}
              onChange={(maximum) => {
                setSchemaProperty({ ...schemaProperty, maximum: +maximum })
              }}
            />
          </SpacedFlexyRow>
        </InputGroup>
      )}

      <InputGroup>
        <FlexyRow>
          <WideInput
            label='Description'
            optional
            placeholder='Description'
            formValue={schemaProperty.description}
            onChange={(description) => {
              setSchemaProperty({ ...schemaProperty, description })
            }}
          />
        </FlexyRow>
      </InputGroup>

      <SetDefaultValueField
        schemaProperty={schemaProperty}
        onChange={(defaultValue) => {
          setSchemaProperty({ ...schemaProperty, default: defaultValue })
        }}
      />

      <InputGroup>
        <InputLabel>Additional settings</InputLabel>
        <StyledFlexyRow>
          <CheckBox
            type='secondary'
            label='Set field as unique'
            checked={schemaProperty.unique}
            disabled={schemaProperty.primary}
            onChange={(unique) => {
              setSchemaProperty({ ...schemaProperty, unique })
            }}
          />
        </StyledFlexyRow>

        <StyledFlexyRow>
          <CheckBox
            label='Field is required'
            type='secondary'
            checked={schemaProperty.required}
            disabled={schemaProperty.primary}
            onChange={(required) => {
              setSchemaProperty({ ...schemaProperty, required })
            }}
          />
        </StyledFlexyRow>
        {!schemaProperty.regexp ? null : (
          <StyledFlexyRow>
            <CheckBox
              label={`Don't apply regular expressions to blank rows`}
              type='secondary'
              checked={!!schemaProperty.regexp.ignoreBlanks}
              onChange={(ignoreBlanks) => {
                setSchemaProperty({
                  ...schemaProperty,
                  regexp: { ...schemaProperty.regexp, ignoreBlanks }
                })
              }}
            />
          </StyledFlexyRow>
        )}
      </InputGroup>
      <ButtonGroup left>
        <div
          data-for={needToHaveOptionOnCategoryFieldMessage}
          data-tip={needToHaveOptionOnCategoryFieldMessage}
        >
          <FormButton primary disabled={disableForm} onClick={handleSubmit}>
            {isNew ? 'Add' : 'Modify'}
          </FormButton>

          {isCategoryFieldEmpty && <Tooltip id={needToHaveOptionOnCategoryFieldMessage} />}
        </div>
        <FormButton secondary onClick={onCancel}>
          Cancel
        </FormButton>
      </ButtonGroup>
    </StyledForm>
  )
}

interface ISetDefaultSchemaProperty {
  schemaProperty: ISchemaProperty
  onChange(val?: string): void
}
function SetDefaultValueField({ schemaProperty, onChange }: ISetDefaultSchemaProperty) {
  switch (schemaProperty.type) {
    case EPropertyType.SCHEMA_REF:
      return null
    case 'boolean':
    case 'category': {
      const options =
        schemaProperty.type === 'category'
          ? (schemaProperty.enumArray || [])
              .filter(({ value }) => !!value)
              .map(({ value, label }) => ({
                value,
                label: label || value
              }))
          : [
              { value: 'true', label: 'true' },
              { value: 'false', label: 'false' }
            ]
      return (
        <InputGroup data-testid={`${schemaProperty.type}-select`}>
          <Select
            autoSelectSingleOption={false}
            isClearable
            key={schemaProperty.default}
            label='Set default value'
            options={options}
            selectedValue={schemaProperty.default}
            onChange={(defaultValue) => {
              onChange(defaultValue?.value ?? null)
            }}
          />
          <HelperText>When left blank by user, field auto-populates with this text</HelperText>
        </InputGroup>
      )
    }
    default:
      return (
        <InputGroup>
          <FlexyRow>
            <WideInput
              data-testid={`${schemaProperty.type}-input`}
              label='Set default value'
              optional
              placeholder={`e.g. default ${
                schemaProperty.label ?? schemaProperty.field ?? 'field'
              }`}
              formValue={schemaProperty.default}
              onChange={onChange}
            />
          </FlexyRow>
          <HelperText>When left blank by user, field auto-populates with this text</HelperText>
        </InputGroup>
      )
  }
}
