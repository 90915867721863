import { createGlobalStyle } from 'styled-components'

export const FilterModes = createGlobalStyle`
  body.filter-dark {
    filter: invert(1) hue-rotate(180deg);
  }
  body.filter-monochrome {
    background: #404040;
    filter: grayscale(1) contrast(80%) invert();
  }
  body.filter-dim:after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #aaaaaa;
    mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 2150000000;
  }
  body.filter-gold:after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ffcc00;
    mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 2150000000;
  }
  body.filter-dimgold:after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #aa7700;
    mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 2150000000;
  }
  body.filter-night:after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #606000;
    mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 2150000000;
  }
`

export enum EFilterMode {
  dark = 'dark',
  dim = 'dim',
  dimgold = 'dimgold',
  gold = 'gold',
  monochrome = 'monochrome',
  night = 'night',
  none = 'none'
}

export function applyFilter(mode: EFilterMode) {
  document.body.classList.add(`filter-${mode}`)
}
