import { gql } from '@apollo/client'

export const SQ_WORKSPACES_TEMPLATE = gql`
  query GetWorkspacesByTemplate(
    $teamId: ID!
    $templateId: Int!
    $environmentId: UUID
    $versionsFilter: String
  ) {
    getWorkspacesByTemplate(
      teamId: $teamId
      templateId: $templateId
      environmentId: $environmentId
      versionsFilter: $versionsFilter
    ) {
      name
      createdAt
      status
    }
  }
`
