import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const BlockTitle = styled.h2`
  background: ${Colors.pigeon100};
  border-bottom: 1px solid ${Colors.border};
  color: ${Colors.textAccent};
  font-size: ${fontSizes.type14};
  font-weight: 600;
  margin: 0;
  max-height: 52px;
  padding: ${Spacing.basePadding2x};
  text-align: left;

  span {
    background: ${Colors.pigeon700};
    border-radius: 100px;
    color: ${Colors.white};
    display: inline-block;
    font-size: ${fontSizes.type13};
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    margin-right: 8px;
    text-align: center;
    vertical-align: 1px;
    width: 20px;
  }
`
