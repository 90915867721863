import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'

interface IInputStyles {
  border?: string
  borderRadius?: string
  boxShadow?: string
  color?: string
  fontSize?: string
  fontWeight?: string
  fontFamily?: string
  padding?: string
}

export const inputStyles: IInputStyles = {
  border: `1px solid ${Colors.border}`,
  borderRadius: `5px`,
  boxShadow: `0 1px 2px rgba(0, 0, 0, 0.05)`,
  color: Colors.text,
  fontSize: fontSizes.type15,
  fontWeight: `400`,
  fontFamily: fontFamily.fontPrimary,
  padding: `9px ${Spacing.baseAndHalfPadding}`
}
