import { gql } from '@apollo/client'
import {
  ReplaceWorkspaceGraphQLAuth,
  ReplaceWorkspaceGraphQLAuthVariables,
  ReplaceWorkspaceGraphQLAuth_createWorkspaceGraphQLAuth
} from 'src/smart/mutations/types/ReplaceWorkspaceGraphQLAuth'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_REPLACE_WORKSPACE_GRAPHQL_AUTH: SmartMutation<
  ReplaceWorkspaceGraphQLAuthVariables,
  ReplaceWorkspaceGraphQLAuth,
  ReplaceWorkspaceGraphQLAuth_createWorkspaceGraphQLAuth
> = {
  mutation: gql`
    mutation ReplaceWorkspaceGraphQLAuth(
      $workspaceId: UUID!
      $graphQLEndpoint: String!
      $headersSecret: JSON!
    ) {
      createWorkspaceGraphQLAuth(
        workspaceId: $workspaceId
        graphQLEndpoint: $graphQLEndpoint
        headersSecret: $headersSecret
      ) {
        id
      }
    }
  `,
  extract(response) {
    return response.createWorkspaceGraphQLAuth
  }
}
