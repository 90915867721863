import { useCallback, useMemo, useState } from 'react'

import { storage } from 'src/resources/utils/storage'

export function useStoredState<T>(
  storageKey: string,
  initialValue: T
): [T, (newState: T) => void] {
  const store = useMemo(() => {
    return storage<T>(storageKey)
  }, [storageKey])
  const [value, setValue] = useState<T>(store.exists() ? store.get() : initialValue)
  const set = useCallback(
    (newState: T) => {
      store.set(newState)
      setValue(newState)
    },
    [store, setValue]
  )
  return [value, set]
}
