import { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { HighLayer2 } from 'src/applications/Oversight/layers'
import styled from 'styled-components'

interface IPortalProps {
  children: ReactNode
  positioner: HTMLElement
}

export const StyledPortal = styled.div`
  position: absolute;
  z-index: ${HighLayer2};
`

export const Portal = ({ children, positioner }: IPortalProps) => {
  const el = document.createElement('div')
  const mount = document.body

  useEffect(() => {
    mount.appendChild(el)
    return () => {
      mount.removeChild(el)
    }
  }, [el, mount])

  const positionerBox = positioner ? positioner.getBoundingClientRect() : null
  return (
    <>
      {positionerBox &&
        ReactDOM.createPortal(
          <StyledPortal
            style={{
              height: `${positionerBox.height}px`,
              left: `${positionerBox.left}px`,
              top: `${positionerBox.top}px`,
              width: `${positionerBox.width}px`
            }}
          >
            {children}
          </StyledPortal>,
          el
        )}
    </>
  )
}
