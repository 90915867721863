import { Colors, ColorUtils } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'
import { BookOpen } from './Icons'

export const ActionContainer = styled.div`
  padding: ${Spacing.basePadding2x};
`

export const StatusContainer = styled(ActionContainer)`
  background-color: ${Colors.gray40};
  padding-top: ${Spacing.basePadding};
  padding-bottom: ${Spacing.basePadding};
`

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100px;
  border-top: 1px solid #dbe5f0;
`

const Inner = styled.div`
  min-height: 675px;
`

const Resources = styled.div`
  background: #fff;
  border-right: #dbe5f0 1px solid;
  flex-shrink: 0;
  position: relative;
  min-width: 320px;
  flex: 0 1 320px;
  border-right: #dbe5f0 1px solid;

  ${Inner} {
    position: sticky;
    top: 0;
  }
`

const Detail = styled.div`
  flex: 0 1 1200px;
  min-width: 320px;
  position: relative;
`

const Message = styled.div`
  padding: ${Spacing.basePadding4x} ${Spacing.basePadding3x};
`

const StyledLink = styled.a`
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledH3 = styled.h3<{ bold?: boolean; italic?: boolean }>`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 500 : 400)};
  line-height: 24px;
  font-size: ${fontSizes.type18};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`

export const StyledP = styled.p<{
  bold?: boolean
  italic?: boolean
  light?: boolean
  nowrap?: boolean
  small?: boolean
}>`
  margin: 0;
  font-weight: ${({ bold, light }) => (bold ? 500 : light ? 100 : 400)};
  line-height: 1;
  font-size: ${fontSizes.type14};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  ${({ nowrap }) =>
    nowrap &&
    `
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    `}
  ${({ small }) =>
    small &&
    `
    margin: 0;
    font-weight: 500;
    line-height: 20px;
    font-size: ${fontSizes.type12};
    color: ${Colors.pigeon700};
    `}
`

const HeaderRow = styled(Row)<{ verticalCenter?: boolean }>`
  justify-content: flex-start;
  align-items: ${({ verticalCenter }) => (verticalCenter ? 'center' : 'flex-start')};
  margin-bottom: ${Spacing.baseAndHalfPadding};
`

const Badge = styled.span`
  background-color: ${Colors.darkBadge};
  color: ${Colors.white};
  border-radius: ${Spacing.basePadding2x};
  padding: ${Spacing.quarterBasePadding} ${Spacing.basePadding};
  font-size: ${fontSizes.type12};
`

const SpinnerBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const AddItemButton = styled(FlatButton)`
  width: 100%;
  text-align: center;
  margin-top: ${Spacing.basePadding};
`

const IconWrapper = styled.div`
  background: ${ColorUtils.hexToRgba(Colors.brandPrimary, 0.1)};
  border-radius: 4px;
  padding: 5px;
  margin-right: ${Spacing.basePadding};
  svg {
    display: block;
  }
`

const ActiveCount = styled(StyledP)`
  font-size: ${fontSizes.type16};
`

export const ResourceBrowserComponents = {
  ActionContainer,
  AddItemButton,
  Badge,
  Container,
  Detail,
  HeaderRow,
  Inner,
  Message,
  Resources,
  Row,
  SpinnerBlock,
  StatusContainer,
  StyledLink,
  StyledP
}

export interface ISidebarConfig {
  title: string
  description?: string
  activeCount?: boolean
}

export interface BrowserProps {
  actionBar?: JSX.Element
  active?: Number
  addItem?(): void | Promise<void>
  alwaysRenderChildren?: boolean
  children: JSX.Element
  components?: typeof ResourceBrowserComponents
  docsLink?: string
  inactive?: Number
  items?: JSX.Element[]
  selectedResourceId?: string | number
  sidebarConfig?: ISidebarConfig
  sidebarListItem?: JSX.Element
}

export const UpdatedResourceBrowser = ({
  actionBar,
  active,
  addItem,
  alwaysRenderChildren,
  children,
  components,
  docsLink,
  inactive,
  items,
  selectedResourceId,
  sidebarConfig
}: BrowserProps) => {
  const {
    ActionContainer: XActionContainer,
    AddItemButton: XAddItemButton,
    Container: XContainer,
    Detail: XDetail,
    HeaderRow: XHeaderRow,
    Inner: XInner,
    Message: XMessage,
    Resources: XResources,
    SpinnerBlock: XSpinnerBlock,
    StyledLink: XStyledLink,
    StyledP: XStyledP
  } = components ?? ResourceBrowserComponents

  const loading = !items
  const noItems = !items?.length

  return (
    <XContainer>
      <XResources>
        <XInner>
          {(!!sidebarConfig || !!addItem) && (
            <XActionContainer>
              <XHeaderRow verticalCenter={!sidebarConfig.description}>
                <IconWrapper>
                  <BookOpen />
                </IconWrapper>
                <div>
                  <StyledH3 bold>{sidebarConfig.title}</StyledH3>
                  {sidebarConfig.description && <XStyledP>{sidebarConfig.description}</XStyledP>}
                </div>
              </XHeaderRow>
              {sidebarConfig?.activeCount && typeof active === 'number' && (
                <ActiveCount bold>
                  {active} Active / {inactive} Inactive
                </ActiveCount>
              )}
              {addItem && <XAddItemButton onClick={addItem}>{'\uff0b'} Create New</XAddItemButton>}
            </XActionContainer>
          )}
          {loading ? (
            <XSpinnerBlock data-testid='spinner'>
              <Spinner />
            </XSpinnerBlock>
          ) : (
            <>
              {noItems && (
                <XMessage>
                  <XStyledP bold italic>
                    No items
                  </XStyledP>
                </XMessage>
              )}
              {actionBar}
              {items?.length > 0 && items}
            </>
          )}
        </XInner>
      </XResources>
      <XDetail>
        <XInner>
          {selectedResourceId || alwaysRenderChildren ? <>{children}</> : null}
          {!selectedResourceId && docsLink?.length && (
            <XMessage>
              Not sure where to start?{' '}
              <XStyledLink href={docsLink} target='_blank'>
                Learn more here
              </XStyledLink>
            </XMessage>
          )}
        </XInner>
      </XDetail>
    </XContainer>
  )
}
