import { gql } from '@apollo/client'
import {
  SmartGetSchema,
  SmartGetSchemaVariables,
  SmartGetSchema_getSchema
} from 'src/smart/queries/types/SmartGetSchema'
import { SmartQuery } from 'src/smart/SmartQuery'

type SchemaSmartQueryType = SmartQuery<
  SmartGetSchemaVariables,
  SmartGetSchema,
  SmartGetSchema_getSchema
>

export const SQ_SCHEMA: SchemaSmartQueryType = {
  query: gql`
    query SmartGetSchema($schemaId: ID!) {
      getSchema(schemaId: $schemaId) {
        archived
        createdAt
        id
        importCount
        name
        slug
        teamId
        updatedAt
        jsonSchema {
          schema
        }
        createdBy {
          name
        }
      }
    }
  `,
  extract({ getSchema }) {
    return getSchema
  }
}
