import { CSSProperties, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { IFileUploadState } from 'src/contexts/FileUploadContext'
import { Colors } from 'src/resources/colors'
import { ExpandDiagonal, FolderDownloadAlt } from 'src/resources/elements/Icons'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_UPLOAD } from 'src/smart/queries/SQ_GET_UPLOAD'
import styled from 'styled-components'

const Container = styled.div`
  background: ${Colors.white};
  border-radius: 6px;
  width: 100%;
  position: relative;
`

const MiniContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentWrap = styled.div<{ borderTop?: boolean; paddingBottom?: boolean }>`
  padding: ${({ paddingBottom }) =>
    paddingBottom
      ? `${Spacing.basePadding2x} 0 ${Spacing.basePadding2x}`
      : `${Spacing.basePadding2x} 0 0`};
  margin: ${Spacing.basePadding} ${Spacing.basePadding2x};
  ${({ borderTop }) => borderTop && `border-top: 1px solid ${Colors.border}`};
`

const Title = styled.div<{ small?: boolean }>`
  color: ${Colors.text};
  font-size: ${({ small }) => (small ? fontSizes.type14 : fontSizes.type16)};
`

const UploadDetails = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.fontSecondary};
  font-weight: 600;
  position: relative;
  width: ${({ width }) => (width ? width : 'auto')};
`

const FlexStatusWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UploadProgress = styled.p<{ showMargin?: boolean }>`
  font-size: ${fontSizes.type14};
  color: ${Colors.textAccent};
  opacity: 0.6;
  margin: ${({ showMargin }) => (showMargin ? Spacing.halfBasePadding : 0)};
`

const ExpandButton = styled.div`
  position: absolute;
  top: 3px;
  right: 40px;
  width: 8px;
  svg {
    transition: all 0.5s ease;

    &:hover {
      transform: scale(0.8);
      background-color: transparent;
      border-radius: 0;
      cursor: pointer;
    }
  }
`

const FolderIconWrap = styled.div`
  margin-top: ${Spacing.halfBasePadding};
  padding-right: ${Spacing.halfBasePadding};

  #arrow {
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    #arrow {
      fill: ${Colors.green};
      transform: translateX(2px);
    }
  }
`

const DefaultDetails = ({
  singleLink,
  title,
  progress,
  showGoToLink
}: {
  singleLink?: string
  title: string
  progress: number
  showGoToLink: boolean
}) => {
  return (
    <UploadDetails>
      <Title>
        <OverflowData dataFor={title} dataTip={title} offset={{ top: -8, left: 10 }}>
          <span>{title}</span>
        </OverflowData>
      </Title>
      <FlexStatusWrap>
        <UploadProgress>
          {progress}% - {progress === 100 ? 'Upload complete' : 'Uploading'}
        </UploadProgress>
        {progress === 100 && showGoToLink && (
          <Link data-testid='progressbar-goto' to={singleLink}>
            <FolderIconWrap>
              <FolderDownloadAlt fill={Colors.textAccent} />
            </FolderIconWrap>
          </Link>
        )}
      </FlexStatusWrap>
    </UploadDetails>
  )
}

const ExpandedHeader = ({ title }: { title: string }) => (
  <UploadDetails>
    <Title>{title}</Title>
  </UploadDetails>
)

const getUploadTitle = (uploads: { [key: string]: IFileUploadState }) => {
  const [singleTitle, setSingleTitle] = useState<string>('')
  const uploadIds = Object.keys(uploads)
  const uploadId = uploadIds[0]

  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, {
    variables: { uploadId }
  })

  useEffect(() => {
    if (uploadQuery?.result?.fileName) {
      setSingleTitle(uploadQuery?.result?.fileName)
    }
  }, [uploadQuery?.result?.fileName])

  const numUploads = uploadIds.length
  const inProgressUploads = uploadIds.filter((id) => uploads[id].percent !== 100)
  const numberOfInProgressUploads = inProgressUploads.length
  const lastFileProgress = uploads[uploadId].percent
  if (numberOfInProgressUploads > 1) {
    return `Uploading ${numberOfInProgressUploads} files`
  }
  if (numberOfInProgressUploads === 1 && lastFileProgress === 100) {
    return 'Uploading file'
  }
  if (lastFileProgress !== 100) {
    return `Uploading ${singleTitle ? singleTitle : 'file'}`
  }
  if (numUploads > 1) {
    return `${numUploads} files uploaded`
  }
  return singleTitle
}

export const ProgressBar = ({
  percentage,
  uploads
}: {
  percentage?: number
  uploads: { [key: string]: IFileUploadState }
}) => {
  const { teamId, workspaceId, environmentSlug } = useParams<{
    workspaceId: string
    teamId: string
    environmentSlug: string
  }>()
  const [expand, setExpand] = useState(false)

  const fillStyles: CSSProperties = {
    height: '10px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: `${percentage === 100 ? '4px' : '0'}`,
    borderTop: `1px solid ${Colors.brandPrimary}`,
    width: `${percentage}%`,
    transition: 'width 2s',
    backgroundColor: Colors.purpleLight
  }

  const remainingFillStyles: CSSProperties = {
    flexGrow: 1,
    borderTop: `1px solid ${Colors.border}`
  }

  const flexFill: CSSProperties = {
    display: 'flex'
  }

  return (
    <Container data-testid='progressbar-container'>
      {Object.keys(uploads).length > 1 && (
        <ExpandButton data-testid='progressbar-expand' onClick={() => setExpand(!expand)}>
          <ExpandDiagonal fill={Colors.textAccent} />
        </ExpandButton>
      )}
      <ContentWrap data-testid='progressbar-content' paddingBottom={expand}>
        {expand ? (
          <ExpandedHeader title={getUploadTitle(uploads)} />
        ) : (
          <DefaultDetails
            title={getUploadTitle(uploads)}
            progress={percentage}
            showGoToLink={Object.keys(uploads).length === 1}
            singleLink={`/a/${teamId}/env/${environmentSlug}/workspaces/${workspaceId}/uploads/${
              Object.keys(uploads)[0]
            }`}
          />
        )}
      </ContentWrap>
      {!expand && (
        <div data-testid='progressbar-fill' style={flexFill}>
          <div style={fillStyles} />
          <div style={remainingFillStyles} />
        </div>
      )}
      {expand && <MiniProgressBarMap uploads={uploads} />}
    </Container>
  )
}

interface IMiniProgressBarMap {
  uploads: { [key: string]: IFileUploadState }
}

const MiniProgressBarMap = ({ uploads }: IMiniProgressBarMap) => {
  return (
    <>
      {Object.keys(uploads).map((upload: any, i) => {
        return <MiniProgressBar key={i} id={upload} progress={uploads[upload].percent} />
      })}
    </>
  )
}

const MiniProgressBar = ({ id, progress }: { id?: string; progress?: number }) => {
  const { teamId, workspaceId, environmentSlug } = useParams<{
    workspaceId: string
    teamId: string
    environmentSlug: string
  }>()
  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, { variables: { uploadId: id } })
  const fileName = uploadQuery?.result?.fileName

  const fillStyles: CSSProperties = {
    height: '10px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: `${progress === 100 ? '4px' : '0'}`,
    borderTop: `1px solid ${Colors.brandPrimary}`,
    width: `${progress}%`,
    transition: 'width 2s'
  }

  const remainingFillStyles: CSSProperties = {
    flexGrow: 1,
    borderTop: `1px solid ${Colors.border}`
  }

  const flexFill: CSSProperties = {
    display: 'flex',
    marginTop: Spacing.basePadding
  }
  return (
    <ContentWrap borderTop>
      <MiniContainer>
        <UploadDetails data-testid='progressbar-mini-title' width='200px'>
          <Title small>
            <OverflowData
              dataFor={fileName}
              dataTip={fileName}
              maxWidth={200}
              offset={{ top: -8, left: 10 }}
            >
              <span>{fileName}</span>
            </OverflowData>
          </Title>
          <div style={flexFill}>
            <div style={fillStyles} />
            <div style={remainingFillStyles} />
          </div>
        </UploadDetails>
        <UploadDetails data-testid='progressbar-mini-progress'>
          {progress === 100 ? (
            <Link
              to={`/a/${teamId}/env/${environmentSlug}/workspaces/${workspaceId}/uploads/${id}`}
            >
              <FolderIconWrap>
                <FolderDownloadAlt fill={Colors.textAccent} />
              </FolderIconWrap>
            </Link>
          ) : (
            <UploadProgress showMargin>{progress}%</UploadProgress>
          )}
        </UploadDetails>
      </MiniContainer>
    </ContentWrap>
  )
}
