import { gql } from '@apollo/client'
import { RequestLogin, RequestLoginVariables } from 'src/smart/mutations/types/RequestLogin'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_REQUEST_LOGIN: SmartMutation<
  RequestLoginVariables,
  RequestLogin,
  { success: boolean; eventId?: string }
> = {
  mutation: gql`
    mutation RequestLogin($email: LowercaseString!, $rememberMe: Boolean!) {
      requestLoginEmail(email: $email, rememberMe: $rememberMe) {
        success
        eventId
      }
    }
  `,
  extract({ requestLoginEmail }) {
    return { success: requestLoginEmail.success, eventId: requestLoginEmail.eventId }
  }
}
