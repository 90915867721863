import { Colors, ColorUtils } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

export const SecondaryFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'empty':
        return css`
          background-color: transparent !important;
          color: ${themed('primary')} !important;

          &:hover {
            background-color: ${themed('primary')} !important;
            color: white !important;
          }
        `
      default:
        return css`
          background-color: ${Colors.gray40} !important;

          &:hover {
            background-color: ${ColorUtils.hoverColor(Colors.gray40)} !important;
          }
        `
    }
  }}
`
