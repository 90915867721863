import { SmartEmbeds_getEmbeds_data_schemas } from 'src/smart/queries/types/SmartEmbeds'
import { ISchemaStub } from 'src/types/interfaces/ISchema'

import { Version } from './Version'

export const SchemaNameAndId = ({
  schema
}: {
  schema: ISchemaStub | SmartEmbeds_getEmbeds_data_schemas
}) => {
  return (
    <>
      <span data-testid='schema-name'>{schema.name}</span>
      <Version v={schema.id} data-testid='schema-version' />
    </>
  )
}
