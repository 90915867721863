import { useCallback, useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'
import WorkspaceAvatar from 'src/applications/Oversight/components/WorkspaceAvatar'
import { GoBackLink } from 'src/applications/Oversight/controls/GoBackLink'
import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { getWorkspaceDataRequestSchemas } from 'src/queries/GET_WORKSPACE_DATA_REQUEST_SCHEMAS'
import { GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas } from 'src/queries/types/GetWorkspaceDataRequestSchemas'
import { Colors } from 'src/resources/colors'
import {
  DataChecklistIcon,
  FilesIcon,
  SettingsIcon,
  SheetIcon,
  WelcomeIcon
} from 'src/resources/elements/Icons'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { SidebarLink } from 'src/resources/elements/SecondarySidebarLinks'
import { UnderConstruction } from 'src/resources/elements/UnderConstruction'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { SideBarWorkspaceSkeleton } from 'src/resources/skeletons/SideBarWorkspaceSkeleton'
import { getStringColor } from 'src/resources/utils/getStringColor'
import { named } from 'src/resources/utils/named'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_WORKBOOK_SHEETS } from 'src/smart/queries/SQ_GET_WORKBOOK_SHEETS'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { SmartGetWorkbookSheets_getWorkbookSheets } from 'src/smart/queries/types/SmartGetWorkbookSheets'
import { EDataEngineType } from 'src/types/enums/EDataEngineTypes'
import { ESidebarItemHeadingType } from 'src/types/enums/ESidebarItemHeadingType'
import { ESidebarItemLinkType } from 'src/types/enums/ESidebarItemLinkType'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import { GlobalFrontChatWindow } from 'src/types/frontchat'
import styled, { css } from 'styled-components'

import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { isLoading } from 'src/resources/utils/isLoading'
import { Menu } from './Menu'

declare let window: GlobalFrontChatWindow

export const openFrontChat = () => {
  window['FrontChat']('show')
}

const WorkspaceHeaderLink = styled(Link)`
  > div {
    margin: ${Spacing.basePadding3x} 0 0;
  }
`
const WorkspaceStatusIndicator = styled.div<{ status?: boolean }>`
  ${({ status }) =>
    status
      ? css`
          background-color: ${Colors.successBGColor};
          color: ${Colors.successStrong};
        `
      : css`
          background-color: ${Colors.gray40};
          color: ${Colors.matchingDarkerBlue};
        `}
  margin-bottom: ${Spacing.basePadding4x};
  margin-left: ${Spacing.basePadding6x};
  width: fit-content;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type12};
  font-style: normal;
  font-weight: 600;
  border-radius: calc(${Spacing.basePadding} + 2px);
  span {
    padding: 2px 10px;
  }
`
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const WorkspaceSidebar = named('WorkspaceSidebar', () => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const query = useQuery()
  const templateTimestamp = query.get('templateTimestamp')

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, { variables: { workspaceId } })
  const workspaceSheets = useSmartQuery(SQ_GET_WORKBOOK_SHEETS, { variables: { workspaceId } })

  const {
    data: dataRequestSchemas,
    error: schemasError,
    loading: schemasLoading,
    refetch: refetchSchemas
  } = getWorkspaceDataRequestSchemas(workspaceId)

  useEffect(() => {
    refetchSchemas()
  }, [templateTimestamp])

  const error = workspaceQuery.state.error || schemasError

  if (error) {
    return QueryAlert({ error })
  }

  if (isLoading(workspaceQuery, schemasLoading)) {
    return <SideBarWorkspaceSkeleton />
  }

  return (
    <WorkspaceSidebarDisplay
      workspace={workspaceQuery.result}
      workspaceSchemas={dataRequestSchemas.getWorkspaceDataRequestSchemas}
      workspaceSheets={workspaceSheets?.result}
    />
  )
})

export const WorkspaceSidebarDisplay = ({
  workspace,
  workspaceSchemas,
  workspaceSheets
}: {
  workspace: IWorkspace
  workspaceSchemas: GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas[]
  workspaceSheets: SmartGetWorkbookSheets_getWorkbookSheets[]
}) => {
  const sidebarName = useCallback(() => 'WorkspaceSidebar.tsx', [])
  const teamRoot = useTeamRootUrl()
  const createDataRequestModal = useCreateDataRequestModal(workspace)
  const workspaceRootUrl = `${teamRoot}/workspaces/${workspace.id}`
  const workspaceIsActive = !workspace.status
  const { isCollaborator } = useTeamRole()

  const workspaceSidebarLinks: SidebarLink[] = workspaceSchemas
    ? [
        isCollaborator && {
          type: ESidebarItemLinkType.EndUser,
          url: `${workspaceRootUrl}/welcome`,
          value: 'Welcome',
          icon: WelcomeIcon
        },
        {
          type: ESidebarItemLinkType.EndUser,
          url: `${workspaceRootUrl}/files`,
          value: 'Files',
          icon: FilesIcon,
          regex: new RegExp('/imports/(.*)')
        },
        {
          type: ESidebarItemLinkType.EndUser,
          url: `${workspaceRootUrl}/data-checklist`,
          value: 'Data Checklist',
          icon: DataChecklistIcon
        },
        !isCollaborator && {
          type: ESidebarItemLinkType.EndUser,
          url: `${workspaceRootUrl}/settings`,
          value: 'Settings',
          icon: SettingsIcon
        },
        {
          type: ESidebarItemHeadingType.EndUserHeading,
          value: 'sheets'
        },
        ...(workspaceSchemas?.map(
          (schema: GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas) => {
            const templateSheet = workspaceSheets?.find((sheet) => sheet.schema?.id === schema.id)
            const url =
              workspace.dataEngine === EDataEngineType.dal &&
              workspace.primaryWorkbookId &&
              templateSheet
                ? `${workspaceRootUrl}/workbooks/${workspace.primaryWorkbookId}/sheets/${templateSheet?.id}`
                : `${workspaceRootUrl}/sheets/${schema.id}`
            return {
              type: ESidebarItemLinkType.EndUser,
              url,
              value: schema.name,
              icon: SheetIcon,
              linkedSchemas: schema.linkedSchemas,
              regex: new RegExp(`/sheets/(${schema.id}|${templateSheet?.id})($|/)`)
            } as SidebarLink
          }
        ) || [])
      ]
    : [
        {
          type: ESidebarItemLinkType.EndUser,
          url: `${workspaceRootUrl}/data-checklist`,
          value: 'Data Checklist',
          icon: DataChecklistIcon
        }
      ]

  return (
    <Menu sidebarLinks={workspaceSidebarLinks} inset>
      <UnderConstruction render={sidebarName} />
      {createDataRequestModal.render()}
      <GoBackLink to={`${teamRoot}/workspaces`} />
      <WorkspaceHeaderLink to={workspaceRootUrl}>
        <WorkspaceAvatar
          bgColor={getStringColor(workspace.id)}
          workspaceName={workspace.name || 'Workspace'}
          nameColor={Colors.black}
          showFullName
          type='sidebar'
        />
      </WorkspaceHeaderLink>
      <WorkspaceStatusIndicator status={workspaceIsActive}>
        <span>{workspaceIsActive ? 'Active' : 'Inactive'}</span>
      </WorkspaceStatusIndicator>
    </Menu>
  )
}
