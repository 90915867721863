import { ReactElement } from 'react'

import { Link } from 'react-router-dom'
import { DropdownMenu } from 'src/applications/Oversight/components/Dropdown'
import { LowLayer1 } from 'src/applications/Oversight/layers'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export interface IDropdownChildNavOption {
  label: string
  path: string
}

export interface IDropdownChildNav {
  header?: string
  options: IDropdownChildNavOption[]
  children: ReactElement
}

const Header = styled.p`
  color: rgb(33, 45, 55);
  font-size: ${fontSizes.type12};
  font-family: ${fontFamily.fontPrimary};
  font-weight: bold;
  margin-bottom: ${Spacing.basePadding};
  padding-left: ${Spacing.basePadding};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  opacity: 0.6;
`

export const DropdownOptionStyles = `
  border-radius: 4px;
  color: ${Colors.brandText};
  cursor: pointer;
  display: block;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type14};
  font-weight: 500;
  overflow: hidden;
  padding: ${Spacing.basePadding};
  margin: 0;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: background-color 0.1s ease, position 0.2s ease;
  white-space: nowrap;
  z-index: ${LowLayer1};

  &:hover {
    background-color: ${Colors.actionHover};
  }
`
export const DropdownOption = styled(Link)`
  ${DropdownOptionStyles}
`

export const HelpOption = styled.span`
  ${DropdownOptionStyles}
`

const Wrap = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 8px 24px 0 rgba(16, 22, 26, 0.2);
  box-sizing: border-box;
  min-width: 140px;
  padding: ${Spacing.basePadding};
  transition: ${Transitions.baseEaseWithProperty('opacity')};
  width: 100%;
`

export const DropdownChildNav = ({ header, options, children }: IDropdownChildNav) => {
  return (
    <DropdownMenu
      arrow={false}
      content={
        <Wrap>
          <Header>{header}</Header>
          {options.map((option: IDropdownChildNavOption, index: number) => (
            <DropdownOption key={index} to={option.path}>
              {option.label}
            </DropdownOption>
          ))}
        </Wrap>
      }
      interactive={true}
      offset={[-20, 16]}
    >
      {children}
    </DropdownMenu>
  )
}
