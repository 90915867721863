import { useEffect } from 'react'

export const onUnmount = (callback: () => Promise<void> | void): void => {
  useEffect(
    () => () => {
      callback()
    },
    []
  )
}
