import { Link } from 'react-router-dom'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import archivedSchemaImage from 'src/resources/images/archived_schema.png'
import customSchemaImage from 'src/resources/images/custom_schema.png'
import { fontFamily } from 'src/resources/typography'
import styled from 'styled-components'

export const SchemaCard = {
  Archived: styled(Link)`
    background-image: url(${archivedSchemaImage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    padding: 20px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid rgba(29, 40, 48, 0.1);
    transition: ${Transitions.baseEaseInOut};

    * {
      color: #50535b !important;
    }

    &:hover {
      background-position: 13%;
      box-shadow: 0 15px 30px -20px rgba(0, 0, 0, 0.5);
    }

    h4 {
      color: #1d2830;
    }
    span,
    small {
      color: #3f5c71;
    }
  `,
  Body: styled.div`
    display: grid;
    margin-bottom: 32px;

    h3 {
      margin: 0;
      color: ${Colors.white};
    }

    small {
      color: ${Colors.white};
    }
  `,
  Error: <strong>Error loading datasets</strong>,
  Footer: styled.div`
    small {
      color: ${Colors.white};
    }
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      opacity: 0.8;
      font-family: ${fontFamily.fontPrimary};
      font-size: 11px;
      color: ${Colors.white};
      letter-spacing: 2px;
    }
  `,
  List: styled.div`
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 24px;
  `,
  Loading: <strong>Loading templates&hellip;</strong>,
  Normal: styled(Link)`
    background-image: url(${customSchemaImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: ${Colors.white};
    padding: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: ${Transitions.baseEaseInOut};

    &:hover {
      background-position: top left;
      box-shadow: 0 15px 30px -20px rgba(0, 0, 0, 0.5);
    }
  `,
  Section: styled.section`
    h2 {
      font-family: ${fontFamily.fontPrimary};
      font-size: 16px;
      color: #50535b;
      margin-top: 40px;
      margin-bottom: 16px;
      position: relative;
    }
  `
}
