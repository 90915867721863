import { gql } from '@apollo/client'

export const GLOBAL_SEARCH = gql`
  query GlobalSearch($licenseKey: UUID!, $teamId: ID!, $search: String!) {
    getBatches(licenseKey: $licenseKey, search: $search, skip: 0, take: 10) {
      data {
        id
        filename
        memo
        originalFile
        endUser {
          name
        }
      }
    }
    getEndUsers(licenseKey: $licenseKey, search: $search, skip: 0, take: 10) {
      data {
        id
        userId
        name
        email
        companyName
      }
    }
    getSchemas(take: 10, skip: 0, teamId: $teamId, search: $search) {
      data {
        id
        importCount
        name
      }
    }
  }
`
