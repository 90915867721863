import { useCallback, useEffect, useMemo, useState } from 'react'

import { OnSelectedRows, RowData } from '@turntable/core'
import { useToasts } from 'react-toast-notifications'
import Notification from 'src/resources/elements/Notification'
import { FlatButton, FlatButtonButton } from 'src/resources/elements/buttons/FlatButton'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_SELECT_HEADER_ROW } from 'src/smart/mutations/SM_SELECT_HEADER_ROW'
import { SQ_GET_NUMERICALLY_INDEXED_VIEW } from 'src/smart/queries/SQ_GET_NUMERICALLY_INDEXED_VIEW'
import { SQ_GET_UPLOAD } from 'src/smart/queries/SQ_GET_UPLOAD'
import { SQ_SCHEMA } from 'src/smart/queries/SQ_SCHEMA'
import { mergedQueryResult } from 'src/utils/mergedQueryResult'
import { convertToAlphaCode } from 'src/utils/string'
import styled from 'styled-components'

import { DataTable } from '../components/DataTable'
import { fontFamily } from 'src/resources/typography'
import { SkipHeaderRow } from 'src/applications/Embed/fragments/SkipHeaderRow'

const NewTableLeft = styled.div`
  flex: 1;
  position: relative;
  > p {
    position: absolute;
    right: 0;
    top: 0;
  }
`

const NewTableHeader = styled.div`
  font-family: ${fontFamily.fontPrimary};
  display: flex;
  flex-direction: row;

  h2 {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
  }

  h4 {
    font-weight: 600;
    margin-top: 0;
  }
`
interface IHeaderRowSelectionPanelProps {
  dataSourceIndex: number
  schemaId: number
  uploadId: string
  onComplete: (p: { viewId: string }) => void
  onReset: () => void
  renderConfirm?: (onConfirm: () => void) => JSX.Element // only temporary; Jared will fix it
  fullScreen?: boolean
}

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > button {
    margin-top: 16px;
  }
`

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledButton = styled(FlatButtonButton)`
  margin: 0 ${Spacing.basePadding};
  min-width: 70px;
`

const QuestionText = styled.div`
  font-family: ${fontFamily.fontPrimary};
  font-size: 18px;
  font-weight: 600;
`

const DataTableWrap = styled.div<{ fullScreen?: boolean }>`
  height: ${({ fullScreen }) => (!!fullScreen ? '100%' : '50vh')};
  overflow: hidden;
`

const HeaderRowQuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const HeaderRowQuestionHelperText = styled.p`
  margin: ${Spacing.halfBasePadding} 0;
`

const UNINITIALIZED: null = null
const UNSELECTED: number = NaN

export function HeaderRowSelectionPanel({
  dataSourceIndex,
  schemaId,
  uploadId,
  renderConfirm,
  onComplete,
  onReset,
  fullScreen = false
}: IHeaderRowSelectionPanelProps) {
  const { addToast } = useToasts()

  const [selectedRow, setSelectedRow] = useState<number | null>(UNINITIALIZED)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const schemaQuery = useSmartQuery(SQ_SCHEMA, { variables: { schemaId: String(schemaId) } })
  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, { variables: { uploadId } })

  const { result: schema } = schemaQuery
  const { result: upload } = uploadQuery

  const viewRowsQuery = useSmartQuery(SQ_GET_NUMERICALLY_INDEXED_VIEW, {
    fetchPolicy: 'network-only',
    variables: {
      dataSourceIndex,
      limit: 100,
      skip: 0,
      uploadId
    }
  })

  const mergedQuery = mergedQueryResult(viewRowsQuery, schemaQuery, uploadQuery)

  const tableRows: RowData[] = useMemo(() => {
    if (!viewRowsQuery.result) {
      return []
    }
    return viewRowsQuery.result.rawRowData.map((row, i) => ({
      cells: row.map((value) => ({ value })),
      position: i,
      rowIndex: i + 1
    }))
  }, [viewRowsQuery.result])

  const tableHeaders = useMemo(() => {
    if (!viewRowsQuery.result) {
      return []
    }

    const columnCount = viewRowsQuery.result.rawRowData.length
      ? Math.max(...viewRowsQuery.result.rawRowData.map((cells) => cells.length))
      : 0
    return Array(columnCount)
      .fill(null)
      .map((_, i) => {
        return { value: convertToAlphaCode(i + 1) }
      })
  }, [viewRowsQuery.result])

  useEffect(() => {
    const index = viewRowsQuery.result?.autoDetectedHeaderRowIndex
    if (typeof index === 'number' && selectedRow === UNINITIALIZED) {
      setSelectedRow(index)
    }
  }, [selectedRow, setSelectedRow, viewRowsQuery.result?.autoDetectedHeaderRowIndex])

  const updateSelectedRows: OnSelectedRows = useCallback(
    (rows) => {
      if (rows.length > 0) {
        setSelectedRow(rows[0].rowIndex - 1)
      }
    },
    [setSelectedRow]
  )

  const [showSelectAnotherRow, setShowSelectAnotherRow] = useState(false)

  const restoreDetectedHeaderRow = useCallback(() => {
    const index = viewRowsQuery.result?.autoDetectedHeaderRowIndex
    if (Number.isFinite(index)) {
      setSelectedRow(index + 1)
    }
    setShowSelectAnotherRow(false)
  }, [setShowSelectAnotherRow, viewRowsQuery.result?.autoDetectedHeaderRowIndex, setSelectedRow])

  const selectAnotherRow = useCallback(() => {
    setSelectedRow(UNSELECTED)
    setShowSelectAnotherRow(true)
  }, [setSelectedRow, setShowSelectAnotherRow])

  const selectHeaderRowMutation = useSmartMutation(SM_SELECT_HEADER_ROW)

  const submitHeaderRowSelection = async (headerRowIndex: number) => {
    setIsSubmitting(true)

    const response = await selectHeaderRowMutation.run({
      dataSourceIndex,
      headerRowIndex,
      schemaId,
      uploadId
    })

    if (response && Number.isFinite(response.viewId)) {
      onComplete({
        viewId: `${response.viewId}`
      })
    } else {
      addToast('Failed to select header row, please try again', {
        appearance: 'error',
        autoDismiss: true
      })

      setIsSubmitting(false)
    }
  }

  const skipHeaderRowSelection = useCallback(async () => {
    await submitHeaderRowSelection(-1)
  }, [selectedRow, setIsSubmitting, selectHeaderRowMutation])

  const submitSelectedHeaderRow = useCallback(async () => {
    await submitHeaderRowSelection(selectedRow)
  }, [selectedRow, setIsSubmitting, selectHeaderRowMutation])

  const headerRowQuestion = useMemo(() => {
    if (showSelectAnotherRow) {
      return (
        <HeaderRowQuestionWrapper>
          <QuestionText>Select the row containing column headers</QuestionText>
          <ActionsContainer>
            <StyledButton
              color='primary'
              disabled={!Number.isFinite(selectedRow)}
              onClick={submitSelectedHeaderRow}
            >
              Confirm
            </StyledButton>
            {Math.random() < 0 && (
              <StyledButton color='secondary' onClick={restoreDetectedHeaderRow}>
                Undo
              </StyledButton>
            )}
          </ActionsContainer>
        </HeaderRowQuestionWrapper>
      )
    }

    return (
      <HeaderRowQuestionWrapper>
        <div>
          <QuestionText>Does row {selectedRow + 1} contain column headers?</QuestionText>
          <HeaderRowQuestionHelperText>
            If not, please select the correct row.
          </HeaderRowQuestionHelperText>
        </div>
        <ActionsContainer>
          <StyledButton
            color='primary'
            disabled={!Number.isFinite(selectedRow)}
            onClick={submitSelectedHeaderRow}
          >
            Yes
          </StyledButton>
        </ActionsContainer>
      </HeaderRowQuestionWrapper>
    )
  }, [
    restoreDetectedHeaderRow,
    selectAnotherRow,
    selectedRow,
    showSelectAnotherRow,
    submitSelectedHeaderRow
  ])

  if (isSubmitting) {
    return QueryAlert({ loading: true })
  }

  if (mergedQuery.alert || !upload || !schema) {
    return QueryAlert(mergedQuery)
  }

  if (!tableRows.length) {
    return (
      <ErrorContainer>
        Something went wrong while parsing your file.
        <FlatButton color='primary' onClick={onReset}>
          Try again
        </FlatButton>
      </ErrorContainer>
    )
  }

  return (
    <>
      {!renderConfirm && (
        <>
          <NewTableHeader>
            <NewTableLeft>
              <h2>Upload {schema.name}</h2>
              <h4>{upload.fileName}</h4>
            </NewTableLeft>
          </NewTableHeader>
          <Notification fullWidth>{headerRowQuestion}</Notification>
        </>
      )}
      {!!renderConfirm && <SkipHeaderRow skipHeaderRowSelection={skipHeaderRowSelection} />}
      <DataTableWrap fullScreen={fullScreen}>
        <DataTable
          readOnly
          onSelectedRows={updateSelectedRows}
          selectedRows={Number.isFinite(selectedRow) ? [selectedRow] : []}
          tableHeaders={tableHeaders}
          tableRows={tableRows}
          totalCount={tableRows.length}
        />
      </DataTableWrap>
      {!!renderConfirm && renderConfirm(submitSelectedHeaderRow)}
    </>
  )
}
