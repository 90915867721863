import { useMemo, useState } from 'react'

import { queryAlert } from 'src/resources/elements/QueryAlert'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_LINKED_SHEETS } from 'src/smart/queries/SQ_GET_LINKED_SHEETS'
import { ISheetFilter } from 'src/types/interfaces/ISheetFilter'

import { useConfirmModal } from './useConfirmModal'
import { useTranslation } from 'react-i18next'

export function useConfirmDismissModal(
  onDismiss: () => void,
  sheetId: string,
  rowIds: number[],
  filter: ISheetFilter
) {
  const { t } = useTranslation()
  const linkedSheets = useSmartQuery(SQ_GET_LINKED_SHEETS, {
    skip: true,
    variables: { sheetId }
  })

  const [message, setMessage] = useState('')

  const modal = useConfirmModal({
    header: t('hooks.useConfirmDismissModal.dismissHeader'),
    confirmLabel: t('hooks.useConfirmDismissModal.dismissLabel'),
    onConfirm: onDismiss,
    contents: linkedSheets.alert ? (
      queryAlert(linkedSheets)
    ) : (
      <>
        <p>{t('hooks.useConfirmDismissModal.paragraphOne', { message })}</p>
        <p>{t('hooks.useConfirmDismissModal.paragraphTwo')}</p>
      </>
    )
  })

  return useMemo(
    () => ({
      ...modal,
      async open() {
        const result = SQ_GET_LINKED_SHEETS.extract(
          (
            await linkedSheets.state.refetch(
              rowIds.length
                ? { rowIds, filter: undefined, sheetId }
                : { filter, rowIds: undefined, sheetId }
            )
          ).data
        )
        if (result.length) {
          setMessage(
            result
              ?.map((link) =>
                t('hooks.useConfirmDismissModal.linkRecords', {
                  countRecords: link.countRecords,
                  schemaName: link.schema.name
                })
              )
              ?.join(', ')
          )
          modal.open()
        } else {
          onDismiss()
        }
      }
    }),
    [rowIds, filter, modal, onDismiss, setMessage]
  )
}
