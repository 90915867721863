import { useMemo } from 'react'
import { getFormContext } from 'src/resources/elements/form/Form'
import { SmartGetViewSchema_getViewSchema_linkedSchemas } from 'src/smart/queries/types/SmartGetViewSchema'
import { IMatchData, JsonSchemaProperty } from 'src/types/interfaces/ISchema'
import { DataSourceField, IUpdateDataSourceField } from '../classes/DataSourceField'
import { getGroupedOptions } from '../helpers'
import { MenuSelect } from './MenuSelect'

interface IField {
  dataSourceField: DataSourceField
  findDataSourceField: (f: string) => DataSourceField | undefined
  schemaProperties: JsonSchemaProperty[]
  allowCustomFields?: boolean
  onUpdate: (values: Partial<IMatchData>, updateForm?: Function) => void
  onSingleFieldUpdate: (
    f: string | number,
    p: IUpdateDataSourceField,
    updateForm?: Function
  ) => Promise<void> | void
  templateName: string
  templateId: string
  linkedTemplates: SmartGetViewSchema_getViewSchema_linkedSchemas[]
  activeMatchKeys: string[]
  changesApproved?: boolean
}

export function Field({
  dataSourceField,
  findDataSourceField,
  schemaProperties,
  onUpdate,
  onSingleFieldUpdate,
  templateName,
  templateId,
  linkedTemplates,
  allowCustomFields,
  activeMatchKeys,
  changesApproved
}: IField) {
  const formContext = getFormContext()
  const options = useMemo(
    () =>
      getGroupedOptions(
        schemaProperties,
        dataSourceField,
        templateName,
        templateId,
        linkedTemplates,
        activeMatchKeys
      ),
    [schemaProperties, dataSourceField, templateName, templateId, linkedTemplates, activeMatchKeys]
  )

  const selectedOption = useMemo(
    () => schemaProperties.find((p) => p.field === formContext.value.data[dataSourceField.id]),
    [formContext.value.data, schemaProperties]
  )

  return (
    <MenuSelect
      name={dataSourceField.id.toString()}
      selectedValue={
        selectedOption
          ? {
              label: selectedOption.label,
              value: selectedOption.field,
              $schema: selectedOption.$schema
            }
          : null
      }
      validateOption={(optionValue) => {
        const mappedOption = (Object.entries(formContext.value.data) as [string, string][]).filter(
          ([_key, value]) => value === optionValue
        )?.[0]

        if (mappedOption) {
          const field = findDataSourceField(mappedOption[0])

          if (field) {
            return {
              fieldId: mappedOption[0],
              label: field.label
            }
          }
        }

        return null
      }}
      onUpdate={async (values, __INTERNAL_SKIP_CONFIRM__) => {
        const updateForm = () => {
          formContext.setValue({
            data: Object.assign({}, formContext.value.data, values)
          })
        }

        onUpdate(values, updateForm)
      }}
      onAddCustomField={
        allowCustomFields
          ? () => {
              onSingleFieldUpdate(dataSourceField.id, {
                matchKey: dataSourceField.label,
                customMatchKey: true
              })
            }
          : null
      }
      skipConfirm={formContext.value.data.__INTERNAL_SKIP_CONFIRM__ || false}
      columnOptions={options}
      changesApproved={changesApproved}
    />
  )
}
