import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { FileUploadContext } from 'src/contexts/FileUploadContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { GET_BATCHES } from 'src/queries/GET_BATCHES'
import {
  GetBatches,
  GetBatchesVariables,
  GetBatches_getBatches_data_views
} from 'src/queries/types/GetBatches'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { NewStyledTable } from 'src/resources/elements/Table'
import { useSearch } from 'src/resources/hooks/useSearch'
import { Spacing } from 'src/resources/layout'
import { WorkspaceFilesSkeleton } from 'src/resources/skeletons/WorkspaceFilesSkeleton'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { useWarnBeforeUnload } from '../hooks/useWarnBeforeUnload'
import { useWorkspaceUpdateNotifications } from '../hooks/useWorkspaceUpdateNotifications'
import { BatchTable } from './BatchTable'

const NoDataMessage = styled.td`
  font-weight: 500;
  padding: ${Spacing.basePadding3x} !important;
  text-align: center;
`

export const WorkspaceBatchTable = ({
  workspace,
  deleted
}: {
  workspace: IWorkspace
  deleted: boolean
}) => {
  const sessionContext = useContext(SessionContext)
  const environment = useContext(EnvironmentContext)
  const { fileUploadState, setBatchViews } = useContext(FileUploadContext)

  const hasInProgressUpload =
    fileUploadState &&
    Object.values(fileUploadState).filter((value) => value.percent < 100).length > 0
  useWarnBeforeUnload(hasInProgressUpload, 'A refresh will interrupt your import. Are you sure?')

  const { location } = useReactRouter()
  const searchVariables = useSearch<{ u: string }, { endUserId?: string }>(
    location.search,
    ({ u }) => ({
      endUserId: u
    })
  )

  const { data, loading, error } = useQuery<GetBatches, GetBatchesVariables>(GET_BATCHES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
    variables: {
      workspaceId: workspace.id,
      teamId: sessionContext.activeTeamId,
      environmentId: environment?.id,
      ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE),
      ...searchVariables,
      deleted
    },
    onCompleted(data) {
      const batchViews = data.getBatches.data.reduce((acc, prop) => {
        acc[prop.id] = prop.views
        return acc
      }, {} as { [key: string]: GetBatches_getBatches_data_views[] })
      setBatchViews(batchViews)
    }
  })

  useWorkspaceUpdateNotifications(workspace.id)
  const batchLength = data?.getBatches?.data?.length

  if (loading) {
    return <WorkspaceFilesSkeleton />
  }

  if (error) {
    return QueryAlert({ error })
  }

  return (
    <div data-testid='workspace-upload-table-container'>
      <NewStyledTable>
        <thead>
          <tr>
            <td>File name</td>
            <td>Status</td>
            <td>Uploaded by</td>
            <td>Sheet</td>
            <td>Upload date</td>
            <td />
          </tr>
        </thead>

        <tbody>
          {batchLength === 0 && !deleted && (
            <tr>
              <NoDataMessage colSpan={5}>No data available, please upload a file</NoDataMessage>
            </tr>
          )}
          {!data?.getBatches?.data?.length && deleted && (
            <tr>
              <NoDataMessage colSpan={5}>No archived files</NoDataMessage>
            </tr>
          )}
          {data?.getBatches?.data?.length > 0 && (
            <BatchTable batches={data?.getBatches?.data} workspace={workspace} />
          )}
        </tbody>
      </NewStyledTable>
      <ListPagination pagination={data?.getBatches?.pagination} />
    </div>
  )
}
