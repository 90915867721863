import { MutableRefObject } from 'react'

export const handleScrollWithCallback = (
  innerRef: MutableRefObject<any>,
  outerRef: MutableRefObject<any>,
  scrollBufferAmount: number,
  loading: boolean,
  callback: Function
) => {
  if (
    innerRef?.current &&
    innerRef.current.getBoundingClientRect().top - scrollBufferAmount <
      outerRef.current.getBoundingClientRect().bottom &&
    !loading
  ) {
    callback()
  }
}
