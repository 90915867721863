import { SmartMutation } from 'src/smart/SmartMutation'

import { gql } from '@apollo/client'
import {
  UpdateWorkspaceStatus,
  UpdateWorkspaceStatus_updateWorkspaceStatus,
  UpdateWorkspaceStatusVariables
} from 'src/smart/mutations/types/UpdateWorkspaceStatus'

export const SM_UPDATE_WORKSPACE_STATUS: SmartMutation<
  UpdateWorkspaceStatusVariables,
  UpdateWorkspaceStatus,
  UpdateWorkspaceStatus_updateWorkspaceStatus
> = {
  mutation: gql`
    mutation UpdateWorkspaceStatus($workspaceId: UUID!) {
      updateWorkspaceStatus(workspaceId: $workspaceId) {
        success
      }
    }
  `,
  extract: (response) => response.updateWorkspaceStatus
}
