import { ReactNode } from 'react'

import { Colors } from 'src/resources/colors'
import { GridCell, GridCellHeader, GridRow, Identifier } from 'src/resources/elements/GridRow'
import { IEndUser } from 'src/types/interfaces/IBatch'
import styled from 'styled-components'

export const Cell = styled(GridCell)`
  color: ${Colors.blueLight};
`

export function ImportMetadata({
  children,
  endUser
}: {
  children?: ReactNode
  endUser?: IEndUser
}) {
  return (
    <GridRow>
      {endUser?.userId && (
        <Cell>
          <GridCellHeader>User</GridCellHeader>
          {endUser.name}
          <Identifier>{endUser.userId}</Identifier>
        </Cell>
      )}
      {endUser?.companyId && (
        <Cell>
          <GridCellHeader>Company Name</GridCellHeader>
          {endUser.companyName}
          <Identifier>{endUser.companyId}</Identifier>
        </Cell>
      )}
      {children}
    </GridRow>
  )
}
