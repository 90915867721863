import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { useCreateOrganizationModal } from 'src/applications/Oversight/hooks/useCreateOrganizationModal'
import { OrganizationListDataPanel } from 'src/applications/Oversight/panels/Concierge/OrganizationListDataPanel'
import { EmptyPanel } from 'src/applications/Oversight/panels/EmptyPanel'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_ADMIN_ORGANIZATIONS } from 'src/queries/GET_ADMIN_ORGANIZATIONS'
import {
  GetAdminOrganizations,
  GetAdminOrganizationsVariables,
  GetAdminOrganizations_getOrganizationsForTeam
} from 'src/queries/types/GetAdminOrganizations'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import EmptyIllustration from 'src/resources/images/Organizations@2x.png'
import { WorkspaceOrganizationsSkeleton } from 'src/resources/skeletons/WorkspaceOrganizationsSkeleton'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { wrapWith } from 'src/resources/utils/wrapWith'
import useReactRouter from 'use-react-router'

export const OrganizationsListPanel = wrapWith(Card, () => {
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const { location } = useReactRouter()

  const { data, loading, error, refetch } = useQuery<
    GetAdminOrganizations,
    GetAdminOrganizationsVariables
  >(GET_ADMIN_ORGANIZATIONS, {
    variables: {
      teamId: team.id,
      environmentId: environment?.id,
      ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE)
    }
  })

  if (error) {
    return QueryAlert({ error })
  }

  if (loading || !data) {
    return <WorkspaceOrganizationsSkeleton />
  }

  return (
    <OrganizationsList
      organizations={data.getOrganizationsForTeam}
      onOrganizationUpdate={refetch}
    />
  )
})

const OrganizationsList = ({
  organizations,
  onOrganizationUpdate
}: {
  organizations: GetAdminOrganizations_getOrganizationsForTeam
  onOrganizationUpdate?: () => void
}) => {
  const createOrganizationModal = useCreateOrganizationModal()

  return (
    <>
      {createOrganizationModal.render()}
      {organizations.pagination.totalCount > 0 ? (
        <>
          <OrganizationListDataPanel
            organizations={organizations}
            children={
              <>
                <FlatButton onClick={createOrganizationModal.open}>Create organization</FlatButton>
              </>
            }
            onOrganizationUpdate={onOrganizationUpdate}
          />
          <ListPagination pagination={organizations.pagination} />
        </>
      ) : (
        <EmptyPanel
          header='Organizations'
          description={`You haven't added any Organizations yet. Once you've created an Organization, you can
        invite them to collaborate with you on data in a private Workspace.`}
          image={EmptyIllustration}
          onClick={createOrganizationModal.open}
          buttonLabel='Create an organization'
        />
      )}
    </>
  )
}
