import { gql } from 'apollo-boost'
import { SmartQuery } from 'src/smart/SmartQuery'

import {
  SmartGetWorkbookSheets,
  SmartGetWorkbookSheets_getWorkbookSheets,
  SmartGetWorkbookSheetsVariables
} from './types/SmartGetWorkbookSheets'

export const SQ_GET_WORKBOOK_SHEETS: SmartQuery<
  SmartGetWorkbookSheetsVariables,
  SmartGetWorkbookSheets,
  SmartGetWorkbookSheets_getWorkbookSheets[]
> = {
  query: gql`
    query SmartGetWorkbookSheets($workspaceId: UUID!) {
      getWorkbookSheets(workspaceId: $workspaceId) {
        id
        template {
          id
          name
          jsonSchema {
            schema
          }
        }
        schemaId
        schema {
          archived
          id
          name
          linkedSchemas {
            name
            id
          }
        }
        percentComplete
      }
    }
  `,
  extract({ getWorkbookSheets }) {
    return getWorkbookSheets
  }
}
