import { Colors } from 'src/resources/colors'
import { BooleanInput, BooleanInputLabel } from 'src/resources/elements/form/BooleanInput'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup, ButtonWrap } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { IFeature } from 'src/types/interfaces/IFeature'
import styled from 'styled-components'

export type IFeatureData = Pick<IFeature, 'id' | 'archived' | 'enabled' | 'key' | 'name'>

const FeatureElement: TForm<IFeatureData> = Form

const InputContainer = styled.div`
  color: ${Colors.white};
  padding: ${Spacing.basePadding2x};

  label {
    margin-top: 0;
  }

  & + & {
    padding-top: 0;
  }

  ${BooleanInputLabel} {
    color: ${Colors.white};
  }
`

const DarkInput = styled(Input)`
  background-color: ${Colors.blackLight};
`

export const FeatureForm = ({
  initialValue,
  onSubmit
}: {
  initialValue: Partial<IFeatureData>
  onSubmit: (event: IFormSubmitEvent<IFeatureData>) => Promise<void>
}) => (
  <FeatureElement disableIfUnchanged initialValue={initialValue} onSubmit={onSubmit}>
    <DarkInput
      required
      label='Key'
      name='key'
      transform={(value) => value?.toUpperCase()?.replace(/\W+/g, '_')}
    />
    <ErrorMessage name='key' />
    <DarkInput required label='Name' name='name' />
    <ErrorMessage name='name' />
    <InputContainer>
      <BooleanInput
        name='archived'
        label='Archived (archived features still remain in effect if enabled)'
      />
    </InputContainer>
    <InputContainer>
      <BooleanInput name='enabled' label='Enabled (feature is activated for everyone)' />
    </InputContainer>

    <ButtonWrap>
      <ButtonGroup left>
        <FormButton secondary reset>
          Revert
        </FormButton>
        <FormButton primary submit>
          {initialValue?.id ? 'Publish changes' : 'Create and publish'}
        </FormButton>
      </ButtonGroup>
    </ButtonWrap>
  </FeatureElement>
)
