import { MutationTuple, useMutation } from '@apollo/client'
import {
  UpdateImportCredentials,
  UpdateImportCredentialsVariables
} from 'src/queries/types/UpdateImportCredentials'
import { UPDATE_IMPORT_CREDENTIALS } from 'src/queries/UPDATE_IMPORT_CREDENTIALS'

export const useUpdateImportCredentialsMutation = (): MutationTuple<
  UpdateImportCredentials,
  UpdateImportCredentialsVariables
> => {
  const [updateImportCredentials, updateImportCredentialsVariables] = useMutation<
    UpdateImportCredentials,
    UpdateImportCredentialsVariables
  >(UPDATE_IMPORT_CREDENTIALS)

  return [updateImportCredentials, updateImportCredentialsVariables]
}
