import { useCallback } from 'react'

import { components as reactSelect, OptionProps, SingleValueProps } from 'react-select'
import {
  FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS,
  FLATFILE_GEOGRAPHIC_REGION_LABEL
} from 'src/config'
import { Colors } from 'src/resources/colors'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { Shadows } from 'src/resources/shadows'
import styled from 'styled-components'

/**
 * To generate codepoints
 * ;(e => Array(e.length).fill().map((_,i) =>
 *   e.charCodeAt(i)).join('-'))('<emoji>')
 */

const REGION_CURRENT_URI = location.origin

const regions: SelectOption[] = [
  {
    iconCodepoints: FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS.split('-').map((x) =>
      parseInt(x, 10)
    ),
    label: FLATFILE_GEOGRAPHIC_REGION_LABEL,
    value: REGION_CURRENT_URI
  },
  {
    iconCodepoints: [55356, 56826, 55356, 56824], // united states flag
    label: 'United States',
    value: 'https://app.flatfile.com'
  }
]

function goToRegion(region: SelectOption, path: string) {
  window.location.href = `${region.value}${path}`
}

const CircleIcon = styled.div`
  border-radius: 100%;
  box-shadow: ${Shadows.large};
  display: inline-block;
  height: 22px;
  margin: 0 8px 0 0;
  overflow: hidden;
  position: relative;
  vertical-align: -5px;
  width: 22px;

  span {
    background-color: ${Colors.brandPrimary};
    font-size: 24px;
    left: -1px;
    line-height: 29px;
    padding: 0;
    position: absolute;
    top: 0;
    transform: scaleY(1.9);
  }
`

type OptionValueProps = OptionProps<SelectOption, false> | SingleValueProps<SelectOption>

const IconCodepointOption =
  (Component: (props: OptionValueProps) => JSX.Element) =>
  ({ children, ...props }: OptionValueProps) => {
    const icon = props.data?.iconCodepoints && String.fromCodePoint(...props.data?.iconCodepoints)
    return (
      <Component {...props}>
        {icon && (
          <CircleIcon>
            <span>{icon}</span>
          </CircleIcon>
        )}
        {children}
      </Component>
    )
  }

const iconSelectComponents = {
  Option: IconCodepointOption(reactSelect.Option),
  SingleValue: IconCodepointOption(reactSelect.SingleValue)
}

export function GeographicRegionSelect({ path }: { path: string }) {
  const goToRegionPath = useCallback(
    (region: SelectOption) => {
      goToRegion(region, path)
    },
    [path]
  )
  if (!FLATFILE_GEOGRAPHIC_REGION_LABEL) {
    return null
  }
  return (
    <Select
      components={iconSelectComponents}
      label='Geographic Region'
      onChange={goToRegionPath}
      options={regions}
      selectedValue={REGION_CURRENT_URI}
    />
  )
}
