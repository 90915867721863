import { MutationTuple, useMutation } from '@apollo/client'
import { CLONE_SCHEMA } from 'src/queries/CLONE_SCHEMA'
import { CloneSchema, CloneSchemaVariables } from 'src/queries/types/CloneSchema'

export const useCloneSchemaMutation = (): MutationTuple<CloneSchema, CloneSchemaVariables> => {
  const [cloneSchema, cloneSchemaStatus] = useMutation<CloneSchema, CloneSchemaVariables>(
    CLONE_SCHEMA
  )

  return [cloneSchema, cloneSchemaStatus]
}
