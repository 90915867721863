export enum ESidebarItemLinkType {
  EndUser = 'end-user-link',
  External = 'external-link',
  Heading = 'heading-link',
  HeadingWithIcon = 'heading-link-with-icon',
  HelpLaunch = 'help-launch',
  LinkClipped = 'link-clipped',
  Simple = 'link',
  SubExternal = 'sub-external-link',
  Title = 'title-link'
}
