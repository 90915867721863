import { gql } from '@apollo/client'

export const CREATE_SCHEMA_FROM_FILE = gql`
  mutation CreateSchemaFromFile($fileId: ID!, $licenseKey: UUID!, $environmentId: UUID) {
    getSchemaFromFile(fileId: $fileId, licenseKey: $licenseKey, environmentId: $environmentId) {
      archived
      id
      name
    }
  }
`
