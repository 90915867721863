import { Colors } from 'src/resources/colors'
import { fontFamily } from 'src/resources/typography'
import styled from 'styled-components'

const Badge = styled.div<{ secondary: boolean }>`
  background-color: ${Colors.brandPrimary};
  border: 1px solid ${({ secondary }) => (secondary ? Colors.white : Colors.textAccent)};
  border-radius: 50%;
  color: ${Colors.white};
  font-family: ${fontFamily.fontPrimary};
  font-size: 6px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
`

const WorkspaceBadge = ({
  name,
  secondaryBorder
}: {
  name: string
  secondaryBorder?: boolean
}) => {
  return <Badge secondary={secondaryBorder}>{name}</Badge>
}

export default WorkspaceBadge
