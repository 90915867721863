import { useToasts } from 'react-toast-notifications'
import { submitSentrySpan, useSentryTransaction } from './useSentryTransaction'
import * as Sentry from '@sentry/react'
import { Span } from '@sentry/types'
import { useSendWorkspaceInvitationMutation } from 'src/queries/hooks/useSendWorkspaceInvitationMutation'
import { SendWorkspaceInvitationVariables } from 'src/queries/types/SendWorkspaceInvitation'
import { useRefreshWorkspaceInvitationMutation } from 'src/queries/hooks/useRefreshWorkspaceInvitationMutation'

// Sentry performance tracking
const createDataRequestSpans: Partial<Span>[] = [
  {
    op: 'create-data-request-loaded'
  },
  {
    op: 'create-data-request-onsubmit'
  },
  {
    op: 'create-data-request-success'
  }
]

export type IInviteData = SendWorkspaceInvitationVariables

export function useWorkspaceInvite() {
  const { addToast } = useToasts()
  const [sendWorkspaceInvitation] = useSendWorkspaceInvitationMutation()
  const [refreshWorkspaceInvitation] = useRefreshWorkspaceInvitationMutation()
  const sentryTransaction = useSentryTransaction({
    name: `workspaces/data-request`,
    spans: createDataRequestSpans
  })

  const handleResult = ({ success }: { success: boolean }) => {
    if (success) {
      submitSentrySpan(sentryTransaction, 'create-data-request-success')
      sentryTransaction.transaction?.finish()
      addToast('Successfully sent workspace invitation', {
        appearance: 'success',
        autoDismiss: true
      })
    } else {
      Sentry.captureException('Failed to create workspace invitation, please try again')
      addToast('Failed to create workspace invitation, please try again', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const sendInvite = async (inviteData: IInviteData) => {
    submitSentrySpan(sentryTransaction, 'create-data-request-onsubmit')

    const { data } = await sendWorkspaceInvitation({
      variables: inviteData
    })

    const result = (data?.sendWorkspaceInvitation ?? {}) as { success: boolean }

    handleResult(result)

    return result
  }

  const refreshInvite = async (token: string) => {
    submitSentrySpan(sentryTransaction, 'create-data-request-onsubmit')

    const { data } = await refreshWorkspaceInvitation({
      variables: { token }
    })

    const result = (data?.refreshWorkspaceInvitation ?? {}) as { success: boolean }

    handleResult(result)

    return result
  }

  return { sendInvite, refreshInvite }
}
