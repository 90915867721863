import { useState, useEffect } from 'react'
import { MessageMarquee } from 'src/applications/Oversight/components/MessageMarquee'
import { Colors } from 'src/resources/colors'
import { ProgressCircle } from 'src/resources/elements/ProgressCircle'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { ButtonBase } from 'src/resources/elements/buttons/FlatButton'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

const Container = styled.div<{
  top?: number
  padding?: number
  fontSize?: number
  height?: string
}>`
  height: 100vh;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : fontSizes.type18)};
  ${({ top, padding }) =>
    !!top &&
    css`
      height: calc(100vh - ${top}px);
      padding-bottom: ${padding ?? top}px;
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    margin-bottom: ${Spacing.basePadding4x};
    color: ${Colors.textLogo};
  }
  height: ${({ height }) => height};
  // Overriding ButtonBase here because attempting to restyle the component affects Dashboard buttons, even though this component is used on Portal 3 loading states
  ${ButtonBase} {
    border: ${themed('borderWidth')} solid transparent;
    border-radius: ${themed('borderRadius')};
    font-size: ${themed('buttonSize')};
    font-weight: ${themed('buttonWeight')};
    letter-spacing: ${themed('buttonLetterSpacing')};
    line-height: ${themed('buttonLineHeight')};
    padding: ${themed('buttonPadding')};
  }
`

const SpinnerContainer = styled.div`
  height: 120px;
`

interface ILoader {
  height?: string
  children?: React.ReactNode
  top?: number
  padding?: number
  message?: any
  percentage?: number
  displayLoadingCircle?: boolean
  loadingStages?: string[]
  fontSize?: number
}

export function Loader({
  children,
  displayLoadingCircle,
  loadingStages,
  message = 'Thinking...',
  percentage,
  ...props
}: ILoader) {
  const [percentLoaded, setPercentLoaded] = useState(percentage)
  useEffect(() => {
    // ensure loading appears to always increase in value
    if (percentage > percentLoaded) {
      setPercentLoaded(percentage)
    }
  }, [percentage])
  return (
    <Container {...props}>
      <span>{!loadingStages && message}</span>
      {loadingStages && <MessageMarquee loadingStages={loadingStages} stageInterval={7000} />}
      {displayLoadingCircle ? (
        <ProgressCircle percentage={percentLoaded} />
      ) : (
        <SpinnerContainer>
          <Spinner color={Colors.pigeon700} />
        </SpinnerContainer>
      )}
      {children}
    </Container>
  )
}
