import { gql } from 'apollo-boost'

export const UPDATE_IMPORT_CREDENTIALS = gql`
  mutation UpdateImportCredentials(
    $teamId: ID!
    $workspaceId: UUID!
    $active: Boolean!
    $reset: Boolean
  ) {
    updateImportCredentials(
      teamId: $teamId
      workspaceId: $workspaceId
      active: $active
      reset: $reset
    ) {
      teamId
      workspaceId
      active
      updatedAt
      credentials
    }
  }
`
