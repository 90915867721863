import { useContext } from 'react'

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { TeamContext } from 'src/contexts/TeamContext'
import { useParams } from 'src/resources/hooks/useParams'
import { ETeamUserRole } from 'src/types/interfaces/ITeam'

export const MainWorkspaceScene = withRouter(
  ({
    match: { path: workspacePath }
  }: RouteComponentProps<{ teamId: string; workspaceId: string }>) => {
    const team = useContext(TeamContext)
    const { environmentSlug, workspaceId } = useParams<{
      environmentSlug: string
      workspaceId: string
    }>()

    const redirectPath = workspacePath
      .replace(':environmentSlug', environmentSlug)
      .replace(':workspaceId', workspaceId)
      .replace(':teamId', team.id)

    if (team.role === ETeamUserRole.collaborator) {
      return <Redirect to={`${redirectPath}/welcome`} />
    } else {
      return <Redirect to={`${redirectPath}/files`} />
    }
  }
)
