import { useContext } from 'react'

import { Span } from '@sentry/types'
import { AddSchemaForm } from 'src/applications/Oversight/forms/AddSchemaForm'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { useCreateSchemaMutation } from 'src/queries/hooks/useCreateSchemaMutation'
import { graphClient } from 'src/resources/clients/graphClient'
import { useModal } from 'src/resources/elements/Modal'
import useReactRouter from 'use-react-router'

import { useTeamRootUrl } from './useTeamRootUrl'

// Sentry performance tracking
const addSchemaSpans: Partial<Span>[] = [
  {
    op: 'create-data-template-loaded'
  },
  {
    op: 'create-data-template-onsubmit'
  },
  {
    op: 'create-data-template-success'
  }
]

export const useAddSchema = () => {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  const environment = useContext(EnvironmentContext)
  const [createSchema] = useCreateSchemaMutation()

  const sentryTransaction = useSentryTransaction({
    spans: addSchemaSpans
  })

  submitSentrySpan(sentryTransaction, 'create-data-template-loaded')

  const addSchemaModal = useModal({
    header: 'Create data template',
    contents: () => (
      <AddSchemaForm
        onSubmit={async (event) => {
          if (!event.data.name || event.data.name.length < 1) {
            event.formContext.setValue({
              errors: { name: 'Please enter a name' }
            })
            return
          }

          submitSentrySpan(sentryTransaction, 'create-data-template-success')

          const response = await createSchema({
            variables: {
              name: event.data.name,
              teamId: team.id,
              environmentId: environment?.id
            }
          })

          if (response?.data) {
            submitSentrySpan(sentryTransaction, 'create-data-template-loaded')
            sentryTransaction.transaction.finish()
          }

          addSchemaModal.close()
          const newSchema = response.data.createSchema
          await graphClient.clearStore() // important: needed to trigger re-fetch of schemas later
          history.push(`${teamRoot}/templates/${newSchema.id}`)
        }}
        onCancel={addSchemaModal.close}
      />
    )
  })

  return {
    open: addSchemaModal.open,
    render: addSchemaModal.render
  }
}
