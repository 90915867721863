import { keyframes } from 'styled-components'

export const ellipsisFadeInOut = keyframes`
  0% {
    opacity: .2;
  }
  80% {
    opacity: 1;
    transform: translateY(1px);
  }
  100% {
    opacity: .2;
  }
`

export const ellipsisFadeIn = keyframes`
  0% {
    opacity: .2;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
`
