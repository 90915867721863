import prettyBytes from 'pretty-bytes'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import DownloadIcon from 'src/resources/icons/download'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_BATCH } from 'src/smart/queries/SQ_BATCH'
import { SQ_GET_FILE_ACCESS_TOKEN } from 'src/smart/queries/SQ_GET_FILE_ACCESS_TOKEN'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { getBatchFilenameExtention } from 'src/utils/getBatchFilename'
import styled from 'styled-components'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { WorkspaceActionBar } from '../components/WorkspaceActionBar'

const PreviewFileContent = styled.div`
  padding: ${Spacing.contentPadding};
  flex-grow: 1;
`

const FileContainer = styled.div`
  background-color: #f4f9ff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FileType = styled.div`
  background: #eaeff4;
  border-radius: 40px;
  margin-bottom: ${Spacing.basePadding3x};
  padding: ${Spacing.basePadding} ${Spacing.basePadding3x};
  color: #70727b;
  font-size: ${fontSizes.type28};

  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

const FileContent = styled.div`
  color: #52545c;
  font-size: ${fontSizes.type16};
  font-family: ${fontFamily.fontSecondary};
  font-weight: 600;
`

const StyledFlatButton = styled(FlatButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 100%;
    width: 16px;
    margin-right: ${Spacing.basePadding};
  }
`

export const PreviewFilePanel = ({
  workspaceId,
  batchId
}: {
  workspaceId: string
  batchId: string
}) => {
  const teamRoot = useTeamRootUrl()

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const batchQuery = useSmartQuery(SQ_BATCH, {
    fetchPolicy: 'network-only',
    skip: !batchId,
    variables: {
      batchId
    }
  })

  const fileAccessTokenQuery = useSmartQuery(SQ_GET_FILE_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    variables: { batchId: batchId, type: 'original' },
    skip: !batchId
  })

  if (batchQuery.alert) {
    return queryAlert(batchQuery)
  }

  const batch = batchQuery.result

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      to: `${teamRoot}/workspaces/${workspaceId}/files`,
      label: 'Files'
    },
    {
      current: true,
      label: batch?.originalFile
    }
  ]

  const upload = batch?.uploads[0]

  return (
    <>
      <WorkspaceActionBar
        inline={true}
        noMargin={false}
        noPadding={true}
        title={<Breadcrumbs links={links} />}
        workspaceId={workspaceId}
      />
      <Card fullHeight>
        <PageHeaderContainer header={batch?.originalFile}>
          {batch && upload && (
            <StyledFlatButton
              color='success'
              onClick={() => {
                window.open(
                  `${FLATFILE_REFINERY_URL}/batch/${batch.id}/original-file?token=${fileAccessTokenQuery?.result?.token}`,
                  '_blank'
                )
              }}
            >
              <DownloadIcon fill='white' />
              Download
            </StyledFlatButton>
          )}
        </PageHeaderContainer>
        <PreviewFileContent>
          <FileContainer>
            <FileType>{getBatchFilenameExtention(batch)}</FileType>
            <FileContent>
              {upload ? `${prettyBytes(upload?.fileSize ?? 0)}  •  ` : ''}No preview available
            </FileContent>
          </FileContainer>
        </PreviewFileContent>
      </Card>
    </>
  )
}
