import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'
import { ISchemaMutation } from 'src/types/interfaces/ISchemaMutation'

import { CreateSchemaMutation, CreateSchemaMutationVariables } from './types/CreateSchemaMutation'

export const SM_CREATE_SCHEMA_MUTATION: SmartMutation<
  CreateSchemaMutationVariables,
  CreateSchemaMutation,
  ISchemaMutation
> = {
  mutation: gql`
    mutation CreateSchemaMutation($schemaId: ID!) {
      createSchemaMutation(schemaId: $schemaId) {
        createdAt
        enabled
        includeUserHeaders
        graphQLURI
        id
        mutationBody
        mutationType
        resultPrimaryKey
        schemaId
        updatedAt
      }
    }
  `,
  extract: (response) => response.createSchemaMutation
}
