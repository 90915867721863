import styled from 'styled-components'

export interface IContainerProps {
  flex?: boolean
}

export const Container = styled.div<IContainerProps>`
  box-sizing: border-box;
  display: ${(props: IContainerProps) => (props.flex ? 'flex' : 'block')};
`

export const AuthSheet = styled(Container)`
  h1,
  p {
    margin: 0;
  }
`
