import { MutationTuple, useMutation } from '@apollo/client'
import { INITIALIZE_SHEET_EXPORT } from 'src/queries/INITIALIZE_SHEET_EXPORT'
import {
  InitializeSheetExport,
  InitializeSheetExportVariables
} from 'src/queries/types/InitializeSheetExport'

export const useInitializeSheetExportMutation = (): MutationTuple<
  InitializeSheetExport,
  InitializeSheetExportVariables
> => {
  const [initializeSheetExport, initializeSheetExportVariables] = useMutation<
    InitializeSheetExport,
    InitializeSheetExportVariables
  >(INITIALIZE_SHEET_EXPORT)

  return [initializeSheetExport, initializeSheetExportVariables]
}
