import { IJsonSchemaSchema } from 'src/types/interfaces/ISchema'
import { convertToAlphaCode } from 'src/utils/string'

import { Schema } from '../Schema'
import { ERecordState, PrimitiveRecord, VirtualRecord } from '../VirtualRecord'
import { Transport } from './Transport'

export class NumericallyIndexedTransport extends Transport {
  /**
   * In some cases, such as a raw file upload, data comes as an array of strings
   *
   * @param data
   * @returns
   */
  public static fromNumericallyIndexed(data: string[][]): VirtualRecord[] {
    const firstRow = data[0]
    const headers = firstRow.map((_v, i) => convertToAlphaCode(i + 1))
    const schema = new Schema(this.fakeSchema(headers))

    return data.map((r, idx) => {
      const obj = r.reduce((acc, v, i) => {
        return { ...acc, [headers[i]]: v }
      }, {} as PrimitiveRecord)
      return new VirtualRecord(schema, obj, {
        id: idx.toString(),
        state: ERecordState.ACCEPTED,
        messages: [],
        pointer: idx,
        valid: true,
        ordinal: idx + 1
      })
    })
  }

  private static fakeSchema(headers: string[]): IJsonSchemaSchema {
    const properties: IJsonSchemaSchema['properties'] = headers.reduce((acc, header) => {
      return { ...acc, [header]: { type: 'string', label: header } }
    }, {} as IJsonSchemaSchema['properties'])
    return { properties }
  }
}
