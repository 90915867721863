import { useCallback, useState } from 'react'
import { Breadcrumbs } from 'src/applications/Oversight/components/Breadcrumbs'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { getWorkspaceDataRequestSchemas } from 'src/queries/GET_WORKSPACE_DATA_REQUEST_SCHEMAS'
import { GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas as WorkspaceSchema } from 'src/queries/types/GetWorkspaceDataRequestSchemas'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import {
  CheckCircleIcon,
  DownArrowIcon,
  LinkIcon,
  RemoveCircleIcon,
  SheetIcon
} from 'src/resources/elements/Icons'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { NewStyledTable } from 'src/resources/elements/Table'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { ISchema } from 'src/types/interfaces/ISchema'
import { deserialize as deserializeSchema } from 'src/utils/schema-normalizer'
import styled, { css } from 'styled-components'

const ChecklistItem = styled.div`
  background: ${Colors.white};
  border: 1px solid ${Colors.border};
  border-radius: 4px;
  box-shadow: ${Shadows.light};
  margin-bottom: ${Spacing.baseAndHalfPadding};
`

const RequiredCell = styled.td`
  text-align: center;
  padding: ${Spacing.basePadding2x} !important;

  > svg {
    vertical-align: middle;
  }
`

const FieldName = styled.td``

const ChecklistItemHeader = styled.div<{ open?: boolean }>`
  align-items: center;
  cursor: pointer;
  color: ${Colors.text};
  display: flex;
  padding: ${Spacing.basePadding2x};
  transition: ${Transitions.baseEase};

  &:hover {
    background-color: ${Colors.actionHover};
  }

  > svg {
    margin-right: 12px;
    position: relative;
    top: -1px;
  }

  > p {
    margin: 0;
    font-weight: 500;
    flex: 1;
  }

  ${({ open }) =>
    open &&
    css`
      background-color: ${Colors.actionHover};
    `}
`

const ChecklistItemArrowWrapper = styled.div<{ open?: boolean }>`
  position: relative;

  > svg {
    position: relative;
    transform: rotate(0);
    transition: ${Transitions.baseEase};
    z-index: 1;
  }

  &::after {
    content: '';
    height: 32px;
    width: 32px;
    background: #f5f9ff;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: ${Transitions.baseEase};
  }

  ${({ open }) =>
    open &&
    `
      > svg {
        transform: rotate(180deg);
      }
      &::after {
        transform: translate(-50%, -50%) scale(1);
        transition-delay: 0.2s;
      }
    
  `}
`

const DataCheckListTable = styled(NewStyledTable)`
  margin: 0;
  th:first-of-type,
  td:first-of-type {
    padding-left: ${Spacing.basePadding2x};
  }
  td {
    white-space: normal;
    vertical-align: top;
    line-height: 1.4;
  }

  ${FieldName} {
    white-space: pre;
  }
`

const DataChecklistTableHeader = styled.thead`
  background: #f3f5f9;

  th {
    color: #3f425c;
  }
`
const LinkedField = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${Spacing.threeQuarterBasePadding};
  }
`

const RequiredIndicator = ({ required }: { required: boolean }) => {
  return required ? <CheckCircleIcon /> : <RemoveCircleIcon />
}

const ChecklistItemWrapper = ({
  schema,
  schemaData
}: {
  schema: WorkspaceSchema
  schemaData: WorkspaceSchema[]
}) => {
  const linkedField = useCallback(
    (schemaId) => {
      const targetSchema = schemaData.find((s) => s.id === schemaId)
      return (
        <LinkedField>
          <LinkIcon fill={Colors.pigeon700} />
          {targetSchema?.name ?? schemaId}
        </LinkedField>
      )
    },
    [schemaData]
  )
  const [open, setOpen] = useState(false)
  const deSerializedSchema: ISchema = deserializeSchema({ ...schema })
  return (
    <ChecklistItem>
      <ChecklistItemHeader onClick={() => setOpen(!open)} open={open}>
        <SheetIcon height={24} width={24} />
        <p>{schema.name}</p>
        <ChecklistItemArrowWrapper open={open}>
          <DownArrowIcon />
        </ChecklistItemArrowWrapper>
      </ChecklistItemHeader>
      {open && (
        <DataCheckListTable>
          <DataChecklistTableHeader>
            <tr>
              <th>Field</th>
              <RequiredCell>Required</RequiredCell>
              <th>Recommended Format</th>
              <th>Notes</th>
            </tr>
          </DataChecklistTableHeader>
          <tbody>
            {deSerializedSchema.jsonSchemaPropArray.map((item, schemaIndex) => {
              return (
                <tr key={JSON.stringify(item.label) + schemaIndex}>
                  <FieldName>{item.label}</FieldName>
                  <RequiredCell>
                    <RequiredIndicator required={item.required} />
                  </RequiredCell>
                  <td>
                    {typeof item.$schemaId === 'string' ? linkedField(item.$schemaId) : item.type}
                  </td>
                  <td>{item.description}</td>
                </tr>
              )
            })}
          </tbody>
        </DataCheckListTable>
      )}
    </ChecklistItem>
  )
}
const ChecklistItemContainer = styled.div`
  padding: 0 ${Spacing.basePadding2x} ${Spacing.basePadding};
`
const SchemaList = ({ schemaData }: { schemaData: WorkspaceSchema[] }) => {
  return (
    <ChecklistItemContainer>
      {schemaData?.map((schema: WorkspaceSchema, index: number) => {
        return (
          <ChecklistItemWrapper
            key={schema.name + index}
            schema={schema}
            schemaData={schemaData}
          />
        )
      })}
    </ChecklistItemContainer>
  )
}

export const DataChecklistPanel = () => {
  const { workspaceId } = useParams<{ workspaceId: string; teamId: string }>()
  const teamRoot = useTeamRootUrl()
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const { data, loading, error } = getWorkspaceDataRequestSchemas(workspaceId)

  if (error || loading || data.getWorkspaceDataRequestSchemas.length === 0) {
    return QueryAlert({ error, loading })
  }
  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      current: true,
      label: 'Data Checklist'
    }
  ]

  return (
    <>
      <WorkspaceActionBar
        title={<Breadcrumbs links={links} />}
        workspaceId={workspaceId}
        inline={true}
        noPadding={true}
        workspaceActive={!workspaceQuery?.result?.status}
      />
      <Card>
        <PageHeaderContainer
          header='Data Checklist'
          description='Learn more about the data that is needed.'
        />

        <SchemaList schemaData={data.getWorkspaceDataRequestSchemas} />
      </Card>
    </>
  )
}
