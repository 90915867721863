import { CopyToClipboard } from 'react-copy-to-clipboard'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { useToasts } from 'react-toast-notifications'
import { Icon } from 'src/resources/elements/Icon'
import styled from 'styled-components'

import { Colors } from '../colors'
import { fontMonospace } from '../typography'

export const InsideCodeSnippetButton = styled(Icon)`
  color: ${Colors.gray60};
  cursor: pointer;
  position: absolute;
  right: 23px;
  top: 12px;

  &:hover {
    color: ${Colors.brandSecondary};
  }

  &:active {
    opacity: 0.5;
  }
`
const SnippetWrap = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  position: relative;
`

export const SnippetDiv = styled(SyntaxHighlighter)`
  max-width: 840px;
  max-height: 640px;
`

export const CodeSnippet = ({
  framework = 'js',
  children,
  copyButton,
  width
}: {
  framework?: string
  children: string
  copyButton?: boolean
  width?: string
}) => {
  const { addToast } = useToasts()
  return (
    <SnippetWrap width={width}>
      {copyButton && (
        <CopyToClipboard
          text={children}
          onCopy={() => {
            addToast(`Copied code snippet to clipboard`, {
              appearance: 'success',
              autoDismiss: true
            })
          }}
        >
          <InsideCodeSnippetButton name='copy' />
        </CopyToClipboard>
      )}
      <SnippetDiv
        language={framework === 'js' ? (children.length > 40 ? 'html' : 'javascript') : framework}
        style={atomOneDark}
        customStyle={{
          borderRadius: '4px',
          fontFamily: fontMonospace,
          fontSize: '14px',
          padding: '2.3em'
        }}
      >
        {children}
      </SnippetDiv>
    </SnippetWrap>
  )
}
