import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import { DropdownMenu } from 'src/applications/Oversight/components/Dropdown'
import { DropdownOptionStyles } from 'src/applications/Oversight/components/DropdownChildNav'
import { Colors } from 'src/resources/colors'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatInput } from 'src/resources/elements/form/Input'
import { SearchIcon } from 'src/resources/elements/Icons'
import { useModal } from 'src/resources/elements/Modal'
import { Spinner } from 'src/resources/elements/Spinner'
import { useDebounce } from 'src/resources/hooks/useDebounce'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${Spacing.basePadding4x};
`

const ResultRow = styled(Row).attrs({ as: 'button' })<{ selected?: boolean }>`
  align-items: flex-start;
  padding: ${Spacing.basePadding2x} ${Spacing.basePadding4x};
  width: 100%;
  background-color: ${({ selected }) => (selected ? Colors.brandPrimary : Colors.white)};
  color: ${({ selected }) => (selected ? Colors.white : 'initial')};
  border: none;
  border-bottom: 2px solid ${Colors.grayLight};
  text-align: left;
  cursor: pointer;
  &:first-of-type {
    border-top: 2px solid ${Colors.grayLight};
  }
  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const ButtonRow = styled(Row)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: ${Spacing.basePadding4x};
`

const ResultLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  padding: 0 ${Spacing.basePadding2x};
`

const StyledInput = styled(FlatInput)`
  border: none;
  margin-left: ${Spacing.basePadding};
  margin-top: 0;
`

const ResultsContainer = styled.div`
  margin: ${Spacing.basePadding3x} 0;
`

const SelectedIndicator = styled.div<{ selected?: boolean }>`
  position: relative;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? Colors.purpleGray : Colors.white)};
  border: 1px solid ${Colors.brandPlaceholderText};
  width: ${Spacing.basePadding2x};
  height: ${Spacing.basePadding2x};
  ${({ selected }) =>
    selected
      ? `
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${Colors.white};
    }
  `
      : ''}
`

const PillContainer = styled.div`
  margin-bottom: ${Spacing.basePadding2x};
`

const DependencyPill = styled.span`
  padding: ${Spacing.halfBasePadding} ${Spacing.basePadding2x};
  padding-right: ${Spacing.quarterBasePadding};
  border-radius: 20px;
  background-color: ${Colors.purpleGray};
  color: ${Colors.brandPrimary};
  position: relative;
  margin-bottom: ${Spacing.basePadding};
  margin-right: ${Spacing.basePadding};
  white-space: nowrap;
  display: inline-block;

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    margin-left: ${Spacing.basePadding};
    width: ${Spacing.basePadding2x};
    height: 100%;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: ${fontSizes.type12};
  }
`

const StyledFlatButton = styled(FlatButton)`
  display: flex;
  align-items: center;
  font-weight: 600;
  span {
    font-size: ${fontSizes.type24};
  }
`

const VersionDropdown = styled(ButtonBase).attrs({ as: 'div' })`
  text-align: center;
  min-width: 150px;
`

const DropdownMenuContainer = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: default;
  transition: ${Transitions.baseEase};
  max-height: 300px;
  overflow-y: scroll;
`

const DropdownOption = styled.button`
  ${DropdownOptionStyles}
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${Colors.grayLight};
  display: block;
  width: 100%;
  padding: ${Spacing.basePadding2x} ${Spacing.basePadding4x};
  text-align: left;
`

const Caret = styled.span`
  position: relative;
  margin-left: ${Spacing.basePadding};
  bottom: ${Spacing.halfBasePadding};
  font-weight: 700;
`

const SpinnerContainer = styled.div`
  margin-right: ${Spacing.basePadding3x};
`

const CodeSpan = styled.span`
  font-family: monospace;
  font-size: ${fontSizes.type16};
`

export type Dependency = {
  name: string
  version: string
  description: string
  versions?: string[]
  tags?: { [key: string]: string }
}
export interface IDependencyData {
  package: Dependency
  highlight: string
}

export const DependencyManager = ({
  dependencies,
  add,
  remove
}: {
  dependencies: Dependency[]
  add(val: Dependency): void
  remove(name: string): void
}) => {
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState<IDependencyData[]>([])
  const [selectedPackage, setSelectedPackage] = useState<Dependency | null>(null)
  const [loadingVersions, setLoadingVersions] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const debouncedValue = useDebounce(search, 800)

  useEffect(() => {
    let fetching = true
    if (debouncedValue && debouncedValue.length > 1) {
      const fetchPackages = async () => {
        const {
          data: { objects }
        }: { data: { objects: IDependencyData[] } } = await axios.get(
          'https://registry.npmjs.com/-/v1/search',
          {
            params: { text: encodeURIComponent(debouncedValue), size: 4 }
          }
        )

        if (fetching) {
          setSearchResults(objects)
        }
      }
      fetchPackages()
    } else {
      setSearchResults([])
    }
    return () => {
      fetching = false
    }
  }, [debouncedValue])

  useEffect(() => {
    let fetching = true
    if (selectedPackage?.name) {
      const fetchPackage = async () => {
        if (fetching) setLoadingVersions(true)
        const {
          data
        }: {
          data: { versions: { [key: string]: object }; ['dist-tags']: { [key: string]: string } }
        } = await axios.get(`https://registry.npmjs.cf/${selectedPackage.name}`, {
          headers: {
            Accept: 'application/vnd.npm.install-v1+json'
          }
        })
        const { versions: versionsObj } = data
        const distTags: { [key: string]: string } = data['dist-tags']
        const latest = distTags.latest
        const memoTags: { [key: string]: string } = Object.entries(distTags).reduce(
          (memo, [tag, version]) => ({ ...memo, [version]: tag }),
          {}
        )
        const versions = Object.keys(versionsObj).reverse()
        if (fetching) {
          setSelectedPackage((prevSelected) => ({
            ...prevSelected,
            versions,
            tags: memoTags,
            version: latest
          }))
          setLoadingVersions(false)
        }
      }
      fetchPackage()
    }
    return () => {
      fetching = false
    }
  }, [selectedPackage?.name])

  const dependencyModal = useModal({
    width: '800px',
    header: 'Add dependencies',
    minHeight: '600px',
    modalStyle: { paddingLeft: 0, paddingRight: 0 },
    headerStyle: { paddingLeft: Spacing.basePadding4x, paddingRight: Spacing.basePadding4x },
    onClose(): boolean {
      setSearch('')
      setSearchResults([])
      setSelectedPackage(null)
      setLoadingVersions(false)
      setDropdownOpen(false)
      return true
    },
    contents(): JSX.Element {
      return (
        <>
          <Row>
            <p>
              Search NPM for packages to add to your hook. Access these packages in your hook as
              follows.
              <br />
              <br />
              <CodeSpan>
                // Example importing the dependency 'date-fns' and using the 'format' function{' '}
                <br />
                const fn = require('date-fns')
                <br />
                const today = fn.format(new Date(), "'Today is a' eeee")
              </CodeSpan>
            </p>
          </Row>
          <Row>
            <SearchIcon />
            <StyledInput
              autoFocus
              fullWidth
              value={search}
              onChange={handleChange}
              placeholder='Search for an npm package'
              autoComplete='off'
            />
          </Row>
          <ResultsContainer>
            {searchResults.map(({ package: dependency }) => {
              const selected = selectedPackage && selectedPackage.name === dependency.name
              return (
                <ResultRow
                  key={dependency.name}
                  selected={selected}
                  onClick={() => {
                    if (dependency.name !== selectedPackage?.name) {
                      setSelectedPackage(dependency)
                    }
                  }}
                >
                  <SelectedIndicator selected={selected} />
                  <ResultLabel>
                    <p>
                      <strong>{dependency.name}</strong>
                    </p>
                    <p>{dependency.description}</p>
                  </ResultLabel>
                  {selected && (
                    <DropdownMenu
                      interactive={true}
                      trigger='click'
                      placement='bottom'
                      arrow={false}
                      offset={[0, 10]}
                      hideOnClick={true}
                      visible={dropdownOpen}
                      content={
                        <DropdownMenuContainer>
                          {selectedPackage?.versions?.map((version) => (
                            <DropdownOption
                              key={version}
                              onClick={() => {
                                setDropdownOpen(false)
                                setSelectedPackage((prevPackage) => ({ ...prevPackage, version }))
                              }}
                            >
                              {version}
                              {selectedPackage?.tags?.[version] &&
                                ` - ${selectedPackage?.tags?.[version]}`}
                            </DropdownOption>
                          ))}
                        </DropdownMenuContainer>
                      }
                    >
                      {loadingVersions ? (
                        <SpinnerContainer>
                          <Spinner color={Colors.white} />
                        </SpinnerContainer>
                      ) : (
                        <VersionDropdown color='white' onClick={() => setDropdownOpen(true)}>
                          {selectedPackage?.version}
                          {selectedPackage?.tags?.[selectedPackage?.version] &&
                            ` - ${selectedPackage?.tags?.[selectedPackage?.version]}`}
                          <Caret>&#8964;</Caret>
                        </VersionDropdown>
                      )}
                    </DropdownMenu>
                  )}
                </ResultRow>
              )
            })}
          </ResultsContainer>
          <ButtonRow>
            <FlatButton disabled={!selectedPackage} onClick={() => handleSubmit(selectedPackage)}>
              Add dependency
            </FlatButton>
          </ButtonRow>
        </>
      )
    }
  })

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const handleSubmit = useCallback(
    (dependency: Dependency) => {
      add(dependency)
      dependencyModal.close()
    },
    [dependencyModal]
  )

  return (
    <>
      <PillContainer>
        {dependencies.map((dependency: Dependency) => (
          <DependencyPill key={dependency.name}>
            {dependency.name}@{dependency.version}
            <button onClick={() => remove(dependency.name)}>&#10005;</button>
          </DependencyPill>
        ))}
      </PillContainer>
      <StyledFlatButton color={'accent'} variant={'outlined'} onClick={dependencyModal.open}>
        <span>&#65291;</span> Add dependencies
      </StyledFlatButton>
      {dependencyModal.render()}
    </>
  )
}
