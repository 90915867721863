import { cloneElement, useEffect, useMemo, useRef, useState } from 'react'

import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { hasOverflow } from 'src/utils/hasOverflow'
import { dasherize } from 'src/utils/string'
import styled from 'styled-components'

const StyledDataCell = styled.td<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OverflowSpan = styled.span<{ maxWidth?: number; maxLines?: number }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ maxLines }) => maxLines};
  display: -webkit-inline-box;
  line-height: 1.25;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`

let uniqueTipId = 0
function uniqueTooltipId() {
  return `tooltip-${uniqueTipId++}`
}

export const OverflowData = ({
  children,
  dataFor,
  dataTip,
  isTableCell = false,
  maxWidth = 150,
  maxLines = 2,
  offset
}: {
  children: JSX.Element
  dataFor?: string
  dataTip: string
  isTableCell?: boolean
  maxWidth?: number
  maxLines?: number
  offset?: { [key: string]: number }
}) => {
  const dataRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const tooltipId = useMemo(() => (!!dataFor ? dasherize(dataFor) : uniqueTooltipId()), [])
  useEffect(() => {
    if (!dataRef.current) {
      return
    }
    const shouldShowTooltip = hasOverflow(dataRef.current)
    setShowTooltip(shouldShowTooltip)
  }, [dataRef.current])

  return isTableCell ? (
    <StyledDataCell data-tip={dataTip} data-for={tooltipId} maxWidth={maxWidth} ref={dataRef}>
      {cloneElement(children, { ref: dataRef })}
      {showTooltip && <Tooltip id={tooltipId} content={dataTip} offset={offset} />}
    </StyledDataCell>
  ) : (
    <OverflowSpan
      data-tip={dataTip}
      data-for={tooltipId}
      maxWidth={maxWidth}
      maxLines={maxLines}
      ref={dataRef}
    >
      {cloneElement(children, { ref: dataRef })}
      {showTooltip && <Tooltip id={tooltipId} content={dataTip} offset={offset} />}
    </OverflowSpan>
  )
}
