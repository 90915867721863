import { MutationTuple, useMutation } from '@apollo/client'
import { ACCEPT_WORKSPACE_INVITATION } from 'src/queries/ACCEPT_WORKSPACE_INVITATION'
import {
  AcceptWorkspaceInvitation,
  AcceptWorkspaceInvitationVariables
} from 'src/queries/types/AcceptWorkspaceInvitation'

export const useAcceptWorkspaceInvitationMutation = (): MutationTuple<
  AcceptWorkspaceInvitation,
  AcceptWorkspaceInvitationVariables
> => {
  return useMutation<AcceptWorkspaceInvitation, AcceptWorkspaceInvitationVariables>(
    ACCEPT_WORKSPACE_INVITATION
  )
}
