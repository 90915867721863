import { Fragment, useCallback, useMemo, useRef, useState } from 'react'

import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

export interface BrowserProps<Resource> {
  initialSelectedResourceId?: string
  resourceList?: Resource[]
  resourceListWidth?: number
  resourceName: string | JSX.Element
  noResourceSelected?: string | JSX.Element
  showAddResource?: boolean
  onCreateResource?(): Promise<Resource | void>
  renderListHead?(): JSX.Element
  renderDetail(props: { resource: Resource }): JSX.Element
  renderListItem(props: {
    resource: Resource
    selected: boolean
    onSelect(id: string): void
  }): JSX.Element
}

const Container = styled.div`
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100px;
`

const Resources = styled.div`
  background: #e8e8e8;
  position: relative;
  width: 240px;
`

const Detail = styled.div`
  flex-grow: 1;
  position: relative;
`

const Inner = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border-radius: ${Spacing.halfBasePadding};
  bottom: ${Spacing.basePadding2x};
  left: ${Spacing.basePadding2x};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: ${Spacing.basePadding2x};
  top: ${Spacing.basePadding2x};
`

const ActionContainer = styled.div`
  padding: ${Spacing.basePadding2x};
  text-align: center;
`

const SpinnerBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const Message = styled.div`
  padding: ${Spacing.basePadding2x};
`

export type TResourceBrowser<T extends { id: string }> = (props: BrowserProps<T>) => JSX.Element

export function ResourceBrowser<T extends { id: string }>(props: BrowserProps<T>) {
  const restoreSelectedResourceId = useRef<string | null>(null)
  const [selectedResourceId, setSelectedResourceIdRaw] = useState<string | undefined>(
    props.initialSelectedResourceId
  )

  const width = `${props.resourceListWidth}px`

  const setSelectedResourceId = useCallback(
    (id: string) => {
      restoreSelectedResourceId.current = id
      setSelectedResourceIdRaw(id)
    },
    [setSelectedResourceIdRaw]
  )

  const selectedResource = useMemo(() => {
    const previouslySelectedResource =
      restoreSelectedResourceId.current &&
      (props.resourceList ?? []).find((x) => x.id === restoreSelectedResourceId.current)
    if (previouslySelectedResource && previouslySelectedResource.id !== selectedResourceId) {
      setSelectedResourceId(restoreSelectedResourceId.current)
      return previouslySelectedResource
    }
    const resource =
      selectedResourceId && (props.resourceList ?? []).find((x) => x.id === selectedResourceId)
    if (!resource) {
      setSelectedResourceIdRaw(
        props.resourceList?.length > 0 ? props.resourceList[0].id : undefined
      )
      return props.resourceList?.length > 0 ? props.resourceList[0] : undefined
    }
    return resource
  }, [setSelectedResourceId, selectedResourceId, props.resourceList])

  const renderedResources = useMemo(
    () =>
      props.resourceList?.map((currentResource) => (
        <Fragment key={currentResource.id}>
          {props.renderListItem({
            resource: currentResource,
            selected: currentResource.id === selectedResourceId,
            onSelect: setSelectedResourceId
          })}
        </Fragment>
      )),
    [props.resourceList, props.renderListItem, selectedResourceId]
  )

  const handleAdd = useCallback(async () => {
    const newResource = await props.onCreateResource()
    if (newResource) {
      setSelectedResourceId(newResource.id)
    }
  }, [props.onCreateResource])

  return (
    <Container>
      <Resources style={{ width }}>
        <Inner>
          {props?.renderListHead?.()}
          {renderedResources ?? (
            <SpinnerBlock>
              <Spinner />
            </SpinnerBlock>
          )}
          {(props.resourceList ? props.resourceList.length === 0 : false) ? (
            <Message>No items</Message>
          ) : null}
          {props?.showAddResource ? (
            <ActionContainer>
              <FlatButton color='white' onClick={handleAdd}>
                + Add {props.resourceName}
              </FlatButton>
            </ActionContainer>
          ) : null}
        </Inner>
      </Resources>
      <Detail>
        <Inner>
          {selectedResource ? (
            props.renderDetail({ resource: selectedResource })
          ) : (
            <Message>{props.noResourceSelected ?? `No ${props.resourceName} selected`}</Message>
          )}
        </Inner>
      </Detail>
    </Container>
  )
}
