import { ISchema } from 'src/types/interfaces/ISchema'
import { fontSizes } from 'src/resources/typography'
import { CheckBox, FormCheckBox } from 'src/resources/elements/form/CheckBox'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ButtonsWrapper } from 'src/applications/Oversight/components/JsonSchemaCodeEditor'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Input } from 'src/resources/elements/form/Input'
import { HelperText } from 'src/resources/elements/form/FieldLabel'
import { Select } from 'src/resources/elements/form/Select'
import styled from 'styled-components'
import { Spacing } from 'src/resources/layout'
import { getFormContext } from 'src/resources/elements/form/Form'
import { isEqual } from 'lodash'
import { BaseFormButtons } from 'src/resources/elements/form/SchemaModelInput'
import { Colors } from 'src/resources/colors'
import { TeamContext } from 'src/contexts/TeamContext'

const FormWrapper = styled.div`
  padding: 0 ${Spacing.contentPadding} ${Spacing.contentPadding};
`

const FormElement = styled.div`
  padding: ${Spacing.basePadding} 0;
`

export const FormSubHeading = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  font-size: ${fontSizes.type15};
  letter-spacing: 0.01em;
  font-weight: 600;
  line-height: 24px;
  color: ${Colors.text};

  ${({ marginBottom }) => marginBottom && `margin-bottom: 4px;`}

  > div {
    margin-left: 4px;
  }
  svg {
    position: absolute;
  }
`

export const SchemaSettingsForm = ({
  showArchiveToggle,
  disabled,
  schema
}: {
  showArchiveToggle: boolean
  disabled: boolean
  schema: ISchema
}) => {
  const team = useContext(TeamContext)
  const relationalFlag = team.featureFlags?.FEATURE_DATA_RELATIONAL

  const schemaHasEmbeds = schema.embeds.length > 0 && !schema.archived
  const formContext = getFormContext<ISchema>()
  const { data } = formContext.value
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const previewFieldKeyOptions = useMemo(() => {
    return schema.jsonSchemaPropArray.map((prop) => {
      return { value: prop.field, label: prop.label }
    })
  }, [formContext?.value?.data?.jsonSchemaPropArray, schema.jsonSchemaPropArray])

  const updateFormContext = useCallback(
    (newValue: boolean) => {
      const { jsonSchema = { schema: {} } } = data
      const { schema = {} } = jsonSchema
      const { allowCustomFields, ...noCustomFieldsSchema } = schema
      formContext.setValue({
        data: {
          ...data,
          jsonSchema: {
            ...jsonSchema,
            schema: {
              ...noCustomFieldsSchema,
              ...(newValue ? { allowCustomFields: newValue } : {})
            }
          }
        }
      })
    },
    [formContext]
  )

  useEffect(() => {
    setUnsavedChanges(!isEqual(formContext?.value?.data, schema))
  }, [formContext, schema])

  useEffect(() => {
    if (formContext?.value?.data.previewFieldKey === undefined) {
      formContext.setValue({ data: { ...data, previewFieldKey: null } })
    }
  }, [formContext.value.data?.previewFieldKey])

  return (
    <FormWrapper>
      <ButtonsWrapper fullWidth>
        <BaseFormButtons unsavedChanges={unsavedChanges} />
      </ButtonsWrapper>
      <FormElement>
        <FormSubHeading>Template name</FormSubHeading>
        <Input
          disabled={disabled}
          initialValue={schema.name}
          name='name'
          placeholder='Example dataset'
        />
        <ErrorMessage name='name' />
        <HelperText>Template ID #{schema.id}</HelperText>
      </FormElement>
      {relationalFlag && (
        <FormElement>
          <FormSubHeading> Use as preview </FormSubHeading>
          <Select
            autoSelectSingleOption={false}
            name='previewFieldKey'
            options={previewFieldKeyOptions}
            isClearable
            helperText='When this template is linked to another this field will represent the linked record visually. Choose a field that easily identifies the record being referenced. The value selected here will apply to all linked templates.'
          />
        </FormElement>
      )}
      <FormElement>
        <FormSubHeading marginBottom>Custom fields</FormSubHeading>
        <CheckBox
          disabled={disabled || !Object.keys(schema?.jsonSchema.schema?.properties ?? {}).length}
          checked={formContext?.value?.data?.jsonSchema?.schema?.allowCustomFields}
          label='Allow users to upload Custom Fields?'
          onChange={updateFormContext}
        />
      </FormElement>

      {showArchiveToggle && (
        <FormElement>
          <FormSubHeading marginBottom>Archive</FormSubHeading>
          <ErrorMessage name='name' />
          <FormCheckBox
            disabled={disabled || schemaHasEmbeds}
            label={
              schemaHasEmbeds
                ? 'Unable to archive because this data template is in use by an embed.'
                : 'Archive this template?'
            }
            name='archived'
          />
        </FormElement>
      )}
    </FormWrapper>
  )
}
