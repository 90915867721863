import { temporaryStorage } from 'src/resources/utils/temporaryStorage'

const getStorageEngine = () => {
  try {
    return typeof localStorage === 'object' ? localStorage : temporaryStorage
  } catch (error) {
    console.error(error)
  }
  return temporaryStorage
}

let storageEngine: typeof temporaryStorage = getStorageEngine()

export const storage = <TData extends {}>(key: string) => ({
  clear() {
    storageEngine.removeItem(key)
  },
  exists(): boolean {
    const data = storageEngine.getItem(key)

    return typeof data === 'string' && data.length > 0
  },

  get(): TData | undefined {
    try {
      return JSON.parse(storageEngine.getItem(key)) as TData
    } catch (e) {
      throw new Error(e)
    }
  },

  getRaw(): string | undefined {
    return storageEngine.getItem(key)
  },

  set(data: TData | undefined) {
    if (data === undefined) {
      return this.clear()
    }
    storageEngine.setItem(key, JSON.stringify(data))
  },

  setRaw(data: string | undefined) {
    if (data === undefined) {
      return this.clear()
    }
    storageEngine.setItem(key, data)
  },

  useTemporaryStorage() {
    storageEngine = temporaryStorage
  }
})
