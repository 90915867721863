import queryString from 'query-string'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { IPaginationVariables } from 'src/types/interfaces/IPagination'

export const paginationFromQuery = (
  qs: string,
  take: number = DEFAULT_PAGE_SIZE
): IPaginationVariables => {
  let pg = 1
  const query = queryString.parse(qs, { parseNumbers: true })
  if (query.page && typeof query.page === 'number') {
    pg = query.page
  }
  return {
    skip: (pg - 1) * take,
    take
  }
}
