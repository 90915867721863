import { useContext } from 'react'

import { useToasts } from 'react-toast-notifications'
import { CreateOrganizationForm } from 'src/applications/Oversight/forms/CreateOrganizationForm'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { graphClient } from 'src/resources/clients/graphClient'
import { useModal } from 'src/resources/elements/Modal'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_CREATE_ORGANIZATION } from 'src/smart/mutations/SM_CREATE_ORGANIZATION'
import useReactRouter from 'use-react-router'

import { useTeamRootUrl } from './useTeamRootUrl'

export const useCreateOrganizationModal = () => {
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  const createOrganization = useSmartMutation(SM_CREATE_ORGANIZATION)

  const createOrganizationModal = useModal({
    header: 'Create organization',
    contents: () => {
      const { addToast } = useToasts()

      return (
        <CreateOrganizationForm
          onSubmit={async (event) => {
            if (!event.data.name || event.data.name.length < 1) {
              event.formContext.setValue({
                errors: { name: 'Please enter a name' }
              })
              return
            }

            await createOrganization.run({
              environmentId: environment.id,
              name: event.data.name,
              teamId: team.id
            })

            addToast('Organization created successfully!', {
              appearance: 'success',
              autoDismiss: true
            })

            createOrganizationModal.close()
            history.push(`${teamRoot}/organizations`)

            // important: needed to trigger re-fetch of organizations
            await graphClient.resetStore()
          }}
          onCancel={createOrganizationModal.close}
        />
      )
    }
  })

  return {
    open: createOrganizationModal.open,
    render: createOrganizationModal.render
  }
}
