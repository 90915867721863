import { gql, useSubscription } from '@apollo/client'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { useSubscriptionWithPollFallback } from 'src/smart/hooks/useSubscriptionWithPollFallback'
import { SQ_GET_UPLOAD } from './SQ_GET_UPLOAD'
import { SmartGetUpload_getUpload } from './types/SmartGetUpload'

import {
  UploadUpdated,
  UploadUpdatedVariables,
  UploadUpdated_uploadUpdated_update
} from './types/UploadUpdated'

export const UPLOAD_UPDATES_SUBSCRIPTION = gql`
  subscription UploadUpdated($workspaceId: UUID!) {
    uploadUpdated(workspaceId: $workspaceId) {
      update {
        workspaceId
        status
        emitDateTime
        uploadedBy
        updateUserId
        updateType
        batch {
          id
        }
        views {
          id
          type
          status
        }
      }
    }
  }
`

export const uploadUpdatedSubscription = (workspaceId: string) => {
  const { data } = useSubscription<UploadUpdated, UploadUpdatedVariables>(
    UPLOAD_UPDATES_SUBSCRIPTION,
    {
      variables: {
        workspaceId
      }
    }
  )
  return data?.uploadUpdated ? data.uploadUpdated.update : undefined
}

export const uploadUpdatedSubscriptionWithFallback = (workspaceId: string, uploadId: string) => {
  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, {
    fetchPolicy: 'network-only',
    skip: true,
    variables: { uploadId }
  })

  const subscriptionFallback = async () => {
    const { data } = await uploadQuery.state.refetch({ uploadId })

    return data.getUpload.status === 'selectHeaderRow' ? data.getUpload : null
  }

  const { result, loading, fallbackResult } = useSubscriptionWithPollFallback<
    UploadUpdatedVariables,
    UploadUpdated,
    UploadUpdated_uploadUpdated_update,
    SmartGetUpload_getUpload
  >({
    subscription: UPLOAD_UPDATES_SUBSCRIPTION,
    extract: ({ uploadUpdated }) => uploadUpdated.update,
    options: {
      variables: {
        workspaceId
      }
    },
    pollFallback: {
      fallback: subscriptionFallback
    }
  })

  return { result, loading, fallbackResult }
}
