import { gql } from 'apollo-boost'
import {
  SmartGetEnvironment,
  SmartGetEnvironmentVariables,
  SmartGetEnvironment_getEnvironmentBySlug
} from 'src/smart/queries/types/SmartGetEnvironment'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_ENVIRONMENT: SmartQuery<
  SmartGetEnvironmentVariables,
  SmartGetEnvironment,
  SmartGetEnvironment_getEnvironmentBySlug
> = {
  query: gql`
    query SmartGetEnvironment($slug: String!, $teamId: ID!) {
      getEnvironmentBySlug(slug: $slug, teamId: $teamId) {
        id
        slug
        webhook {
          subscribedEvents
          url
        }
      }
    }
  `,
  extract({ getEnvironmentBySlug }) {
    return getEnvironmentBySlug
  }
}
