import { Colors } from 'src/resources/colors'
import { IResponse } from 'src/resources/hooks/useFetch'
import { Spacing } from 'src/resources/layout'
import { fontMonospace, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const StyledResponseInfo = styled.div`
  background: ${Colors.pigeon200};
  border-radius: 4px;
  margin: ${Spacing.basePadding2x} 0;
  padding: ${Spacing.basePadding2x};
  h3 {
    color: ${Colors.text};
    font-size: ${fontSizes.type12};
    font-weight: 600;
    margin-bottom: 0;
    &:first-child {
      margin-top: 0;
    }
  }
  p,
  pre,
  code {
    margin: 0;
    font-size: ${fontSizes.type12};
  }
  pre,
  code {
    font-family: ${fontMonospace};
  }
  code {
    display: block;
    overflow-x: auto;
  }
`

export const ResponseInfo = ({ response }: { response: IResponse<any> | undefined }) => (
  <StyledResponseInfo>
    <h3>Status:</h3>
    {response.request ? (
      typeof response.ok === 'boolean' ? (
        <p>Request completed</p>
      ) : (
        <p>Waiting for response</p>
      )
    ) : (
      <p>Unsubmitted</p>
    )}
    {response.request ? (
      <>
        <h3>Request</h3>
        <p>
          <b>URL:</b> {response.request.url}
        </p>
        <p>
          <b>Init:</b>
        </p>
        <code>
          <pre>{JSON.stringify(response.request.init, null, 2)}</pre>
        </code>
      </>
    ) : null}
    {response?.ready ? (
      <>
        <h3>Response</h3>
        <p>
          <b>Status:</b> {response.ok ? 'success' : 'error'}
        </p>
        <p>
          <b>Status code:</b> {response.status}
        </p>
        <p>
          <b>Body:</b>
        </p>
        <code>
          <pre>{JSON.stringify(response.data, null, 2)}</pre>
        </code>
      </>
    ) : null}
  </StyledResponseInfo>
)
