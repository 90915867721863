import { getErrorContext, IErrorType } from 'src/contexts/ErrorContext'

export const useGlobalError = () => {
  const {
    value: { errors },
    setValue
  } = getErrorContext()

  return (errs: (Error | IErrorType)[]): void => {
    setValue({ errors: [...errors, ...errs] })
  }
}
