import { MutationTuple, useMutation } from '@apollo/client'
import { INITIALIZE_FILE } from 'src/queries/INITIALIZE_FILE'
import { InitializeFile, InitializeFileVariables } from 'src/queries/types/InitializeFile'

export const useInitializeFileMutation = (): MutationTuple<
  InitializeFile,
  InitializeFileVariables
> => {
  const [initializeFile, initializeFileStatus] = useMutation<
    InitializeFile,
    InitializeFileVariables
  >(INITIALIZE_FILE)

  return [initializeFile, initializeFileStatus]
}
