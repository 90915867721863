const Base = {
  duration: 0.25,
  ease: 'ease'
}

export const Transition = (
  duration?: number,
  ease?: string,
  property?: string,
  delay?: number
) => {
  const propertyArray = [
    ...(property ? [property] : []),
    ...(duration ? [duration + 's'] : [Base.duration + 's']),
    ...(delay ? [delay + 's'] : []),
    ...(ease ? [ease] : [Base.ease])
  ]
  return propertyArray.join(' ')
}

export const Transitions = {
  baseEase: Transition(Base.duration, Base.ease),
  baseEaseOut: Transition(Base.duration, 'ease-out'),
  baseEaseInOut: Transition(Base.duration, 'ease-in-out'),
  baseEaseSlowerDelay: Transition(0.35, Base.ease, undefined, 0.1),
  baseEaseWithDelay: (delay: number) => Transition(Base.duration, Base.ease, undefined, delay),
  baseEaseWithProperty: (property: string) => Transition(Base.duration, Base.ease, property)
}
