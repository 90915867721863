import prettyBytes from 'pretty-bytes'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { Icon } from 'src/resources/elements/Icon'
import { fontAwesomeIconName } from 'src/resources/types/fontAwesomeIconName'
import { dasherize } from 'src/utils/string'
import styled from 'styled-components'

const iconsByFileExtension: Record<string, fontAwesomeIconName> = {
  csv: 'file-csv',
  xls: 'file-excel',
  xlsx: 'file-excel',
  '*': 'file-import'
}

const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`

let uniqueTipId = 0
export function TimelineFile({ fileName, fileSize }: { fileName: string; fileSize: number }) {
  const ext = fileName.split('.').pop() ?? '*'
  const tooltipKey = dasherize(`${fileName}-detailed-${uniqueTipId++}`)

  return (
    <div data-for={tooltipKey} data-tip={fileName}>
      <Icon
        /* eslint-disable-next-line react/forbid-component-props */
        className='fa-2x'
        name={iconsByFileExtension[ext in iconsByFileExtension ? ext : '*']}
      />
      <Name>
        {fileName} {prettyBytes(fileSize)}
      </Name>
      <Tooltip id={tooltipKey} content={fileName} />
    </div>
  )
}
