import { WorkspaceImportSettings } from 'src/applications/Oversight/components/WorkspaceImportSettings'
import { TabAndContents } from 'src/applications/Oversight/hooks/useTabs'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

export const SFTPTab: TabAndContents<{
  teamId: string
  workspace: IWorkspace
  refetchWorkspace(): Promise<void>
}> = {
  id: 'sftp',
  label: 'SFTP',
  contents({ teamId, workspace }) {
    return <WorkspaceImportSettings teamId={teamId} workspace={workspace} />
  }
}
