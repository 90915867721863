import { Dispatch } from 'react'

import { DeveloperMenuRouter } from 'src/applications/Oversight/developer/DeveloperMenuRoutes'
import { Colors } from 'src/resources/colors'
import { Icon } from 'src/resources/elements/Icon'
import { TapArea } from 'src/resources/elements/TapArea'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontFamily } from 'src/resources/typography'
import { IConfigValuesManager } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

import { DispatchChangeOverride, IconWrap, TextWrap } from './DeveloperMenuCommon'

export const PanelContainer = styled.div<{ expanded: boolean; fullWidth: boolean }>`
  bottom: 0;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: ${({ expanded, fullWidth }) => (expanded && fullWidth ? '100%' : 0)};
  z-index: 2147483647;
`

export const inset = ({ expanded, fullWidth }: { expanded: boolean; fullWidth: boolean }) =>
  expanded ? (fullWidth ? 0 : Spacing.basePadding2x) : 'auto'

export const Panel = styled.div<{ expanded: boolean; fullWidth: boolean }>`
  background-color: ${Colors.brandPrimaryMidnight};
  border-radius: ${({ expanded, fullWidth }) =>
    expanded && fullWidth ? 0 : Spacing.halfBasePadding};
  bottom: ${({ expanded, fullWidth }) => (expanded && !fullWidth ? Spacing.basePadding2x : 0)};
  box-shadow: ${Shadows.xLarge};
  color: ${Colors.white};
  left: ${inset};
  opacity: ${({ expanded }) => (expanded ? '1' : '0.65')};
  overflow: hidden;
  position: absolute;
  right: ${({ expanded, fullWidth }: { expanded: boolean; fullWidth: boolean }) =>
    expanded ? (fullWidth ? 0 : 'auto') : 'auto'};
  transform-origin: bottom left;
  transform: ${({ expanded }) => (expanded ? 'scale(1)' : 'scale(0.5)')};
  transition: transform 0.2s ease, opacity 0.2s ease, top 0.2s ease;
  top: ${inset};
  width: ${({ expanded, fullWidth }) => (expanded && !fullWidth ? '440px' : 'auto')};
`

export const DeveloperMenuContentArea = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  width: 100%;
`

export const DeveloperMenuToolbar = styled.div`
  background: ${Colors.brandPrimary};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  height: 32px;
  position: relative;

  &:nth-of-type(2) {
    background: ${Colors.brandPrimaryMidnight};
  }

  h1 {
    font-size: 14px;
    line-height: 33px;
    margin: 0;
    overflow: hidden;
    padding: 0 4px;
    text-overflow: ellipsis;
  }

  ${TapArea} {
    min-width: ${Spacing.basePadding4x};
  }
`

export const Spacer = styled.div`
  flex-grow: 1;
`

export const ScrollArea = styled.div`
  flex-grow: 1;
  overflow: auto;
`

interface PanelProps {
  config: IConfigValuesManager
  fullWidth: boolean
  onChangeIsOverride: DispatchChangeOverride
  pinned: boolean
  route: string[]
  setRoute: Dispatch<string[]>
  toggleIsExpanded(): void
  toggleIsFullWidth(): void
  toggleIsPinned(): void
}

export const Plus = styled.div`
  display: inline-block;
  font-size: 150%;
  font-family: ${fontFamily.fontMonospace};
  font-weight: bold;
  padding: 0 ${Spacing.basePadding};
`

export const Key = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: ${Spacing.halfBasePadding};
  display: inline-block;
  font-size: 150%;
  font-family: ${fontFamily.fontMonospace};
  font-weight: bold;
  padding: 2px 4px;
`

export const Collapsed = ({ toggleIsExpanded }: Pick<PanelProps, 'toggleIsExpanded'>) => (
  <TapArea onClick={toggleIsExpanded}>
    <Key>ctrl</Key>
    <Plus>+</Plus>
    <Key>`</Key>
  </TapArea>
)

export const Expanded = ({
  config,
  fullWidth,
  onChangeIsOverride,
  pinned,
  route,
  setRoute,
  toggleIsExpanded,
  toggleIsFullWidth,
  toggleIsPinned
}: PanelProps) => (
  <DeveloperMenuContentArea>
    <DeveloperMenuToolbar>
      <TapArea onClick={toggleIsPinned}>
        <Icon name={pinned ? 'circle' : 'map-pin'} />
      </TapArea>
      <TapArea onClick={toggleIsFullWidth}>
        <Icon name={fullWidth ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
      </TapArea>
      <TapArea grow onClick={toggleIsExpanded}>
        <TextWrap>Developer menu</TextWrap>
        <Spacer />
        <IconWrap>
          <Icon name='times-circle' />
        </IconWrap>
      </TapArea>
    </DeveloperMenuToolbar>
    <DeveloperMenuRouter control={{ config, onChangeIsOverride, route, setRoute }} />
    {config.isModified ? (
      <DeveloperMenuToolbar>
        <TapArea grow onClick={() => config.rollbackAll()}>
          <Icon name='undo' />
          <span>Rollback changes</span>
        </TapArea>
        <TapArea grow onClick={() => location.reload()}>
          <Icon name='check' />
          <span>Apply changes</span>
        </TapArea>
      </DeveloperMenuToolbar>
    ) : null}
  </DeveloperMenuContentArea>
)
