import { gql } from '@apollo/client'

export const GET_ORIGINAL_DATA = gql`
  query GetOriginalData($batchId: UUID!) {
    getOriginalData(batchId: $batchId) {
      dataHeaders
      dataRows
    }
  }
`
