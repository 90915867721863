import {
  FormEvent,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef
} from 'react'

import { createContextState, IContext } from 'src/resources/hooks/createContextState'
import { useIsMounted } from 'src/resources/hooks/useIsMounted'
import styled from 'styled-components'

export interface IFormContextValue<TFormData extends Record<string, any>> {
  disableIfUnchanged: boolean
  data: TFormData
  errors: Partial<{ [key in keyof TFormData]: string | JSX.Element }>
  initialData: TFormData
  requiredFields: Partial<{ [key in keyof TFormData]: boolean }>
  submitting: boolean
  onSubmit?(event: IFormSubmitEvent<TFormData>): void | Promise<void>
  hasJsonChanges: boolean
}

const initialData = {}

const [_FormContext, _getFormContext] = createContextState<IFormContextValue<any>>('Form', {
  data: initialData,
  disableIfUnchanged: false,
  initialData,
  errors: {},
  requiredFields: {},
  submitting: false,
  hasJsonChanges: false
})

export const FormContext = _FormContext

export function getFormContext<T = Record<string, any>>(): IContext<IFormContextValue<T>> {
  return _getFormContext() as unknown as IContext<IFormContextValue<T>>
}

export const FlatForm = styled.form``

export interface IFormContext<TFormData extends Record<string, any>> {
  value: IFormContextValue<TFormData>
  setValue(
    value: Partial<{
      data: Partial<TFormData>
      disableIfUnchanged: boolean
      errors: IFormContextValue<TFormData>['errors']
      requiredFields: IFormContextValue<TFormData>['requiredFields']
      submitting: IFormContextValue<TFormData>['submitting']
      hasJsonChanges: boolean //@TODO: remove this from context
    }>
  ): void
}

export interface IFormSubmitEvent<TFormData extends Record<string, any>>
  extends Partial<FormEvent<HTMLFormElement>> {
  data: TFormData
  errors: Partial<{ [key in keyof TFormData]: string | JSX.Element }>
  formContext: IFormContext<TFormData>
}

export interface IFormSubmitEventDisconnected<TFormData extends Record<string, any>> {
  data: TFormData
  errors: Partial<{ [key in keyof TFormData]: string | JSX.Element }>
  formContext: {
    value: IFormContextValue<TFormData>
    setValue(
      value: Partial<{
        data: Partial<TFormData>
        errors: IFormContextValue<TFormData>['errors']
        requiredFields: IFormContextValue<TFormData>['requiredFields']
      }>
    ): void
  }
}

const handleFormSubmit = async <TFormData extends Record<string, any>>(
  event: Partial<IFormSubmitEvent<TFormData>>,
  formContext: IFormContext<TFormData>,
  onSubmit: (event: IFormSubmitEvent<TFormData>) => void | Promise<void>,
  isMounted: MutableRefObject<boolean>,
  onError?: ((error: Error) => any) | undefined
) => {
  event.preventDefault?.()
  event.stopPropagation?.()

  const requiredErrors: IFormContextValue<TFormData>['errors'] = {}
  Object.keys(formContext.value.requiredFields).forEach((stringKey) => {
    const key: keyof TFormData = stringKey as keyof TFormData
    if (formContext.value.requiredFields[key]) {
      const value = formContext.value.data[key]
      if (typeof value !== 'string' || value.length === 0) {
        requiredErrors[key] = 'This field is required'
      }
    }
  })

  if (Object.keys(requiredErrors).length > 0) {
    formContext.setValue({ submitting: false, errors: requiredErrors })

    return
  }

  formContext.setValue({ submitting: true, errors: {} })

  // attach form data to event
  event.data = formContext.value.data

  // attach context to event
  event.formContext = formContext

  try {
    await onSubmit(event as IFormSubmitEvent<TFormData>)
  } catch (e) {
    if (onError) {
      onError(e)
    } else {
      console.error(e)
    }
  }

  // form may be unmounted during onSubmit
  if (isMounted.current) {
    formContext.setValue({ submitting: false })
  }
}

export type TForm<TFormData extends Record<string, any>> = (props: {
  children: ReactNode
  disableIfUnchanged?: boolean
  editingId?: string
  formRef?: RefObject<HTMLFormElement>
  initialValue?: Partial<TFormData>
  onError?: (error: Error) => any
  onSubmit?(event: IFormSubmitEvent<TFormData>): void | Promise<void>
}) => JSX.Element

export const Form: TForm<{ [key: string]: any }> = ({
  children,
  disableIfUnchanged,
  editingId,
  formRef,
  initialValue,
  onSubmit,
  onError
}) => {
  return (
    <FormContext>
      <FormContainer
        disableIfUnchanged={disableIfUnchanged}
        editingId={editingId}
        formRef={formRef}
        initialValue={initialValue}
        onError={onError}
        onSubmit={onSubmit}
      >
        {children}
      </FormContainer>
    </FormContext>
  )
}

const FormContainer = <TFormData extends Record<string, any>>({
  children,
  disableIfUnchanged,
  editingId,
  formRef,
  initialValue,
  onSubmit,
  onError
}: {
  children: ReactNode
  disableIfUnchanged?: boolean
  editingId?: string
  formRef?: RefObject<HTMLFormElement>
  initialValue?: Partial<TFormData>
  onError?: (error: Error) => any
  onSubmit?(event: IFormSubmitEvent<TFormData>): void | Promise<void>
}) => {
  const formContext = getFormContext()
  const isMounted = useIsMounted()

  const resetForm = useCallback(() => {
    const data = { ...(initialValue ?? {}) }
    formContext.setValue({
      data,
      disableIfUnchanged,
      initialData: data,
      submitting: false
    })
  }, [formContext, initialValue])

  useEffect(() => {
    if (initialValue) {
      setTimeout(resetForm)
    }
  }, [editingId, initialValue])

  const contextRef = useRef(formContext)
  contextRef.current = formContext

  if (onSubmit && !contextRef.current.value.onSubmit) {
    window.setTimeout(() => {
      if (!contextRef.current.isMounted) {
        return
      }
      contextRef.current.setValue({
        ...contextRef.current.value,
        onSubmit: async () => {
          await handleFormSubmit(
            {
              ...contextRef.current.value,
              formContext: contextRef.current
            },
            contextRef.current,
            onSubmit,
            isMounted,
            onError
          )
        }
      })
    })
  }

  const submit = useCallback(
    (e: Partial<IFormSubmitEvent<Record<string, any>>>) =>
      handleFormSubmit(e, formContext, onSubmit ?? (() => void 0), isMounted, onError),
    [handleFormSubmit, formContext, onSubmit, isMounted, onError]
  )

  return (
    <FlatForm ref={formRef} onReset={resetForm} onSubmit={submit}>
      {children}
    </FlatForm>
  )
}
