import { MouseEvent } from 'react'
import { useToasts } from 'react-toast-notifications'
import ReactTooltip from 'react-tooltip'
import { EmptyTableMessage } from 'src/applications/Oversight/components/EmptyState'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { HaloButton } from 'src/resources/elements/HaloButton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { ArchiveIcon, RestoreIcon } from 'src/resources/elements/Icons'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { ClickableTR, NewStyledTable, NoClickTd } from 'src/resources/elements/Table'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_UPDATE_EMBED } from 'src/smart/mutations/SM_UPDATE_EMBED'
import { SmartEmbeds_getEmbeds_data } from 'src/smart/queries/types/SmartEmbeds'
import { IPartialPagination } from 'src/types/interfaces/IPagination'
import { renderDate } from 'src/utils/date'
import useReactRouter from 'use-react-router'

import { SchemaNameAndId } from 'src/applications/Oversight/components/SchemaNameAndId'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { useCreateEmbedModal } from 'src/applications/Oversight/hooks/useCreateEmbedModal'
import styled from 'styled-components'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import Lightning from 'src/resources/images/lightning.svg'
import { FLATFILE_DEVELOPERS_BASE } from 'src/config'
import { SQ_EMBEDS } from 'src/smart/queries/SQ_EMBEDS'
import { useListDataTabs } from 'src/applications/Oversight/hooks/useListDataTabs'

export const EmbedListPanel = () => {
  const { queryResults: embedQuery, hasValues } = useListDataTabs(SQ_EMBEDS, 7)
  const createEmbedModal = useCreateEmbedModal()
  const { data, pagination } = embedQuery
  const activeArchivedDropdown = useActiveArchivedTabs()

  return (
    <>
      {createEmbedModal.render()}
      {embedQuery.alert ? (
        queryAlert(embedQuery)
      ) : (
        <>
          {!hasValues ? (
            <EmptyPortalV3Section onClickCreate={createEmbedModal.open} />
          ) : (
            <>
              <PageHeaderContainer hasMargin header={activeArchivedDropdown.dropdown()}>
                <FlatButton onClick={createEmbedModal.open} data-testid='create-portal'>
                  Create Portal
                </FlatButton>
              </PageHeaderContainer>
              {data && (
                <EmbedListData
                  embeds={data}
                  pagination={pagination}
                  refetch={embedQuery.state.refetch}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const EmbedListData = ({
  refetch,
  embeds,
  pagination
}: {
  refetch: () => void
  embeds: SmartEmbeds_getEmbeds_data[]
  pagination: IPartialPagination
}) => {
  const updateEmbed = useSmartMutation(SM_UPDATE_EMBED)
  const { addToast } = useToasts()
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  const archived = useSearchParam.boolean('archived', false)

  const onToggleArchiveEmbed = async (embed: SmartEmbeds_getEmbeds_data) => {
    try {
      const updatedEmbed = await updateEmbed.run({
        embedId: embed.id,
        archived: !embed.archived,
        schemaIds: [embed.schemas[0].id]
      })
      if (updatedEmbed) {
        addToast('Successfully updated Portal!', {
          appearance: 'success',
          autoDismiss: true
        })
        refetch()
      }
    } catch (error) {
      throw error
    }
  }
  return (
    <>
      <NewStyledTable>
        <thead>
          <tr>
            <td>Name</td>
            <td>Template (ID)</td>
            <td>Created at</td>
            <td>Last updated</td>
          </tr>
        </thead>
        <tbody>
          {embeds.length === 0 && (
            <tr>
              <EmptyTableMessage colSpan={6}>
                {archived
                  ? `You haven't archived any Portals yet. Once you do, they'll appear here.`
                  : `You don’t have any active Portals at this time. Create a new Portal or restore an archived one.`}
              </EmptyTableMessage>
            </tr>
          )}
          {embeds &&
            embeds.map((embed: SmartEmbeds_getEmbeds_data) => {
              return (
                <ClickableTR
                  key={embed.id}
                  onClick={() => history.push(`${teamRoot}/setup/embeds/${embed.id}`)}
                >
                  <td>{embed.name}</td>
                  <td>
                    <SchemaNameAndId schema={embed.schemas[0]} />
                  </td>
                  <td>{renderDate(embed.createdAt)}</td>
                  <td>{renderDate(embed.updatedAt)}</td>
                  <NoClickTd onClick={(e: MouseEvent) => e.stopPropagation()}>
                    {embed.archived ? (
                      <span
                        onClick={async (e) => {
                          e.stopPropagation()
                          await onToggleArchiveEmbed(embed)
                        }}
                      >
                        <HaloButton data-tip='Restore' data-for='restore'>
                          <RestoreIcon width='24' height='24' viewBox='0 0 32 40' />
                          <ReactTooltip id='restore' place='bottom' type='dark' effect='float' />
                        </HaloButton>
                      </span>
                    ) : (
                      <HaloButton
                        data-tip='Archive'
                        data-for='archive'
                        onClick={() => onToggleArchiveEmbed(embed)}
                      >
                        <ArchiveIcon width='24' height='24' viewBox='0 0 40 40' />
                        <ReactTooltip id='archive' place='bottom' type='dark' effect='float' />
                      </HaloButton>
                    )}
                  </NoClickTd>
                </ClickableTR>
              )
            })}
        </tbody>
      </NewStyledTable>
      <ListPagination pagination={pagination} />
    </>
  )
}

const EmptyPortal3Content = styled.div`
  border: 1px solid ${Colors.electric050};
  border-radius: ${Spacing.borderRadius};
  margin-top: ${Spacing.basePadding2x};
  display: flex;
  gap: ${Spacing.basePadding2x};
  padding: ${Spacing.basePadding4x};

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }

  h1 {
    font-size: ${fontSizes.type16};
    color: ${Colors.pigeon800};
    font-weight: 500;
  }

  p {
    font-size: ${fontSizes.type14};
    color: ${Colors.pigeon700};
    margin-top: ${Spacing.basePadding};
    margin-bottom: ${Spacing.basePadding2x};
  }

  img {
    align-self: flex-start;
  }

  a {
    cursor: pointer;
    color: ${Colors.sky600};
    :hover {
      color: ${Colors.purpleHover};
    }
  }
`

const EmptyPortalV3Section = ({ onClickCreate }: { onClickCreate: () => void }) => {
  return (
    <EmptyPortal3Content>
      <img alt='lightning' src={Lightning} />
      <div>
        <h1>It looks like you haven’t created a 3.0 Portal yet.</h1>
        <p>
          Once you’ve created your first Portal, it will show up here. For help getting started,
          view the <a href={FLATFILE_DEVELOPERS_BASE}>docs →</a>
        </p>
        <FlatButton variant='outlined' onClick={onClickCreate}>
          + Create Portal
        </FlatButton>
      </div>
    </EmptyPortal3Content>
  )
}
