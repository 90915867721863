import { gql } from '@apollo/client'

export const INITIALIZE_BATCH_AND_UPLOAD = gql`
  mutation InitializeBatchAndUpload(
    $schemaId: ID
    $headersRaw: JSON
    $fileType: String!
    $fileSize: Int!
    $fileName: String!
    $workspaceId: UUID!
    $batchId: UUID
  ) {
    initializeBatchAndUpload(
      schemaId: $schemaId
      headersRaw: $headersRaw
      fileType: $fileType
      fileSize: $fileSize
      fileName: $fileName
      workspaceId: $workspaceId
      batchId: $batchId
    ) {
      signedUrl
      signedUrls
      batch {
        id
      }
      upload {
        id
      }
    }
  }
`
