import { Link } from 'react-router-dom'
import { Colors } from 'src/resources/colors'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

export const HeaderText = styled.p`
  font-size: ${fontSizes.type13};
  color: ${Colors.textAccent};
  font-weight: 400;
`

export const TableLinkStyle = css`
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type13};
  color: ${Colors.elemLink};
`

export const TableLink = styled(Link)`
  ${TableLinkStyle}
`

export const TableNotLink = styled.div`
  ${TableLinkStyle}
`

export const IDLabel = styled.span<{ hideMargin?: boolean }>`
  background-color: ${Colors.lightCodeBackground};
  border-radius: 5px;
  color: ${Colors.blackLight};
  display: inline-block;
  font-family: ${fontFamily.fontMonospace};
  font-size: ${fontSizes.type11};
  line-height: 1;
  margin-left: ${({ hideMargin }) => (hideMargin ? '0' : '4px')};
  padding: 4px 6px;
  position: relative;
  top: -1px;
  vertical-align: middle;
`
