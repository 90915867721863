export const detach = <T,>(promiseOrAsyncFn: (() => Promise<T>) | Promise<T>) => {
  const promise = typeof promiseOrAsyncFn === 'function' ? promiseOrAsyncFn() : promiseOrAsyncFn
  promise.then(
    () => void 0,
    (e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  )
}
