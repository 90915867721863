import SectionHeader from 'src/applications/Oversight/components/SectionHeader'
import { TypeBadge } from 'src/applications/Oversight/components/TypeBadge'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import styled from 'styled-components'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  background: ${Colors.gray20};
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: ${Spacing.basePadding3x};
  font-family: ${fontFamily.fontSecondary};
  line-height: 1.6;

  p {
    margin: auto 0 1rem 0;
  }

  > div > div {
    margin-bottom: ${Spacing.basePadding3x};
  }
`
const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const RequiredText = styled.div`
  font-weight: bold;
  margin-top: ${Spacing.basePadding2x};
`

interface ISchemaProperties {
  description: string
  label: string
  type: string
}

export const IColumnExampleCard = ({
  activeColumn,
  isRequired,
  schema
}: {
  schema: { [key: string]: ISchemaProperties }
  activeColumn: string
  isRequired?: boolean
}) => {
  const hasActiveColumn = schema && schema[activeColumn]
  const showType = hasActiveColumn && schema[activeColumn].type
  const showName = hasActiveColumn && schema[activeColumn].label
  const showDescription = hasActiveColumn && schema[activeColumn].description
  return (
    <Wrap>
      <div>
        <FlexWrap>
          <div>
            {showName && <SectionHeader name='Column Name' />}
            {showName}
          </div>
          <div>{showType && <TypeBadge type={showType} />}</div>
        </FlexWrap>
        {showDescription && (
          <div>
            <SectionHeader name='Description' />
            {showDescription}
          </div>
        )}
      </div>
      {isRequired && <RequiredText>This is a required column</RequiredText>}
    </Wrap>
  )
}
