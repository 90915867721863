import { gql } from '@apollo/client'

import { GetFeatures, GetFeaturesVariables } from 'src/smart/queries/types/GetFeatures'
import { SmartQuery } from 'src/smart/SmartQuery'
import { IFeature } from 'src/types/interfaces/IFeature'

export const SQ_FEATURES: SmartQuery<GetFeaturesVariables, GetFeatures, IFeature[]> = {
  query: gql`
    query GetFeatures($take: Int!, $skip: Int!) {
      getFeatures(take: $take, skip: $skip) {
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
        data {
          archived
          createdAt
          createdBy {
            id
            email
            name
          }
          enabled
          id
          key
          name
          updatedAt
        }
      }
    }
  `,
  extract({ getFeatures: { data } }) {
    return data
  }
}
