import { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

export const PrettyCount = ({ count, suffix }: { count: number; suffix?: string }) => {
  const { formatted, tooltipId } = useMemo(() => {
    const formatted = formatLargeNumber(count)

    if (formatted) {
      return {
        formatted,
        tooltipId: Math.random().toString(36).slice(2)
      }
    }

    return { formatted: count }
  }, [count])
  return (
    <>
      {tooltipId && <ReactTooltip id={tooltipId} place='bottom' type='dark' effect='float' />}
      <span
        {...(tooltipId
          ? {
              'data-tip': `${count.toLocaleString()}${suffix ? ` ${suffix}` : ''}`,
              'data-for': tooltipId
            }
          : {})}
      >
        {formatted}
      </span>
    </>
  )
}

const formatLargeNumber = (int: number): string | void => {
  if (int >= 1e9) {
    return Math.floor(int / 1e9) + 'b'
  }
  if (int >= 1e6) {
    return Math.floor(int / 1e6) + 'm'
  }
  if (int >= 1e3) {
    return Math.floor(int / 1e3) + 'k'
  }
}
