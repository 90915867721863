import { MutationTuple, useMutation } from '@apollo/client'
import { CREATE_SCHEMA_FROM_FILE } from 'src/queries/CREATE_SCHEMA_FROM_FILE'
import {
  CreateSchemaFromFile,
  CreateSchemaFromFileVariables
} from 'src/queries/types/CreateSchemaFromFile'

export const useCreateSchemaFromFileMutation = (): MutationTuple<
  CreateSchemaFromFile,
  CreateSchemaFromFileVariables
> => {
  const [createSchemaFromFile, createSchemaFromFileStatus] = useMutation<
    CreateSchemaFromFile,
    CreateSchemaFromFileVariables
  >(CREATE_SCHEMA_FROM_FILE)

  return [createSchemaFromFile, createSchemaFromFileStatus]
}
