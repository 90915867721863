import { Link } from 'react-router-dom'
import { Version } from 'src/applications/Oversight/components/Version'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { GridCell, GridCellHeader } from 'src/resources/elements/GridRow'
import { ISchemaStubMinimal } from 'src/types/interfaces/ISchema'

export const SchemaCell = ({ schema }: { schema: ISchemaStubMinimal }) => {
  const teamRoot = useTeamRootUrl()
  return (
    <GridCell>
      <GridCellHeader>Data Template (Id)</GridCellHeader>
      <Link to={`${teamRoot}/templates/${schema.id}`}>
        {schema.name}
        <Version v={schema.id} />
      </Link>
    </GridCell>
  )
}
