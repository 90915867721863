import { useContext, useMemo } from 'react'
import { TeamContext } from 'src/contexts/TeamContext'
import { ETeamUserRole } from 'src/types/interfaces/ITeam'

export function useTeamRole() {
  const team = useContext(TeamContext)

  const isCollaborator = useMemo(
    () => !!team.role && team.role === ETeamUserRole.collaborator,
    [team.role]
  )

  return { isCollaborator }
}
