import { useState } from 'react'

export const useDelayedState = <TState,>(
  initialValue: TState
): [TState, (newValue: TState, delay?: number) => void] => {
  const [value, setValue] = useState<TState>(initialValue)

  const setValueWithDelay = (newValue: TState, delay: number = 500) => {
    window.setTimeout(() => setValue(newValue), delay)
  }

  return [value, setValueWithDelay]
}
