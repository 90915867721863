import { useContext } from 'react'

import { useCreateAccessKeyModal } from 'src/applications/Oversight/hooks/useCreateAccessKeyModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { ManageAccessKeysPanel } from 'src/applications/Oversight/panels/ManageAccessKeysPanel'
import { TeamContext } from 'src/contexts/TeamContext'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Icon } from 'src/resources/elements/Icon'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { grow } from 'src/utils/grow'

const AccessKeyHead = () => {
  const team = useContext(TeamContext)
  const createAccessKeyModal = useCreateAccessKeyModal({ team })

  return { createAccessKeyModal }
}

export const AccessKeysScene = grow(AccessKeyHead, ({ createAccessKeyModal }) => {
  const teamRoot = useTeamRootUrl()
  return (
    <BreadcrumbsFullTemplate
      crumbs={[
        {
          to: `${teamRoot}/account`,
          label: 'Account Settings'
        },
        {
          current: true,
          label: 'Access Keys'
        }
      ]}
    >
      {createAccessKeyModal.render()}
      <PageHeaderContainer hasMargin header='Access keys'>
        <FlatButton onClick={createAccessKeyModal.open}>
          <Icon name='plus-circle' />
          Create access key
        </FlatButton>
      </PageHeaderContainer>
      <ManageAccessKeysPanel />
    </BreadcrumbsFullTemplate>
  )
})
