import { createContextState } from 'src/resources/hooks/createContextState'
import { identifySegment } from 'src/resources/utils/analytics'
import { IUser } from 'src/types/interfaces/IUser'

interface IUserContext {
  user?: IUser
}

export const [UserContext, getUserContext] = createContextState<IUserContext>(
  'User',
  {},
  ({ user }) => {
    if (user) {
      identifySegment(user)
    }
  }
)
