import { useCallback, useContext, useEffect, useState } from 'react'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { EWorkspaceStatus } from 'src/types/enums/EWorkspaceStatus'
import useReactRouter from 'use-react-router'
import { useLazyQuery } from '@apollo/client'
import { GET_WORKSPACES_WITH_FILTERING } from 'src/queries/GET_WORKSPACES_WITH_FILTERING'
import {
  GetWorkspacesWithFiltering,
  GetWorkspacesWithFilteringVariables
} from 'src/queries/types/GetWorkspacesWithFiltering'

export const useWorkspaceListDataTabs = () => {
  const { location } = useReactRouter()
  const sessionContext = useContext(SessionContext)
  const environment = useContext(EnvironmentContext)
  const [ready, setReady] = useState(false)
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({
    active: false,
    archived: false,
    totalCount: 0
  })
  const archived = useSearchParam.boolean('archived', false)

  const [getAllWorkspacesWithFiltering, allWorkspacesResult] = useLazyQuery<
    GetWorkspacesWithFiltering,
    GetWorkspacesWithFilteringVariables
  >(GET_WORKSPACES_WITH_FILTERING, {
    fetchPolicy: 'network-only'
  })

  const [getWorkspacesWithFiltering, workspacesResult] = useLazyQuery<
    GetWorkspacesWithFiltering,
    GetWorkspacesWithFilteringVariables
  >(GET_WORKSPACES_WITH_FILTERING, {
    fetchPolicy: 'network-only'
  })

  const workspacesQuery = useCallback(
    (searchTerm?: string, resetPagination?: boolean) => {
      const pagination = resetPagination
        ? { skip: 0, take: DEFAULT_PAGE_SIZE }
        : paginationFromQuery(location.search, DEFAULT_PAGE_SIZE)
      getAllWorkspacesWithFiltering({
        variables: {
          environmentId: environment?.id,
          teamId: sessionContext.activeTeamId,
          ...pagination,
          searchTerm
        }
      })
      getWorkspacesWithFiltering({
        variables: {
          environmentId: environment?.id,
          teamId: sessionContext.activeTeamId,
          ...pagination,
          searchTerm,
          status: archived ? EWorkspaceStatus.ARCHIVED : EWorkspaceStatus.ACTIVE
        }
      })
    },
    [
      getAllWorkspacesWithFiltering,
      getWorkspacesWithFiltering,
      environment?.id,
      sessionContext.activeTeamId,
      location.search,
      archived
    ]
  )

  useEffect(() => {
    if (!allWorkspacesResult.loading && allWorkspacesResult.data?.getWorkspacesWithFiltering) {
      const data = allWorkspacesResult.data.getWorkspacesWithFiltering.data
      const hasArchivedValues = data.some((item: any) => item.status === EWorkspaceStatus.ARCHIVED)
      const hasActiveValues = data.some((item: any) => item.status === EWorkspaceStatus.ACTIVE)
      setFilterValues({
        archived: hasArchivedValues,
        active: hasActiveValues,
        totalCount: data?.length | 0
      })
      setReady(true)
    }
  }, [allWorkspacesResult])

  const hasValues = Object.values(filterValues).some((filterValue) => filterValue === true)
  return {
    workspacesQuery,
    workspacesResult,
    hasValues,
    ready,
    totalCount: filterValues.totalCount
  }
}
