export const FlexportFileTypeIcon = (
  <svg width='80' height='75' viewBox='0 0 80 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M37.688 75C58.5024 75 75.3759 58.2107 75.3759 37.5C75.3759 16.7893 58.5024 0 37.688 0C16.8735 0 0 16.7893 0 37.5C0 58.2107 16.8735 75 37.688 75Z'
      fill='#4A74E7'
    />
    <path
      opacity='0.05'
      d='M37.688 75C58.5024 75 75.3759 58.2107 75.3759 37.5C75.3759 16.7893 58.5024 0 37.688 0C16.8735 0 0 16.7893 0 37.5C0 58.2107 16.8735 75 37.688 75Z'
      fill='url(#paint0_linear_62887_6027)'
    />
    <path
      d='M16.0522 21.6075C15.9897 21.6075 15.9298 21.6322 15.8856 21.6762C15.8415 21.7201 15.8167 21.7798 15.8167 21.8419V60.4714C15.8167 60.5335 15.8415 60.5931 15.8856 60.6371C15.9298 60.681 15.9897 60.7057 16.0522 60.7057H45.7527C45.8151 60.7057 45.875 60.681 45.9192 60.6371C45.9634 60.5931 45.9882 60.5335 45.9882 60.4714V28.2938C45.9801 28.1454 45.9214 28.0043 45.8217 27.8937L39.6705 21.7735C39.5594 21.6742 39.4176 21.6157 39.2685 21.6075H16.0522Z'
      fill='#4A74E7'
    />
    <g opacity='0.25'>
      <path
        opacity='0.25'
        d='M16.0522 21.6075C15.9897 21.6075 15.9298 21.6322 15.8856 21.6762C15.8415 21.7201 15.8167 21.7798 15.8167 21.8419V60.4714C15.8167 60.5335 15.8415 60.5931 15.8856 60.6371C15.9298 60.681 15.9897 60.7057 16.0522 60.7057H45.7527C45.8151 60.7057 45.875 60.681 45.9192 60.6371C45.9634 60.5931 45.9882 60.5335 45.9882 60.4714V28.2938C45.9801 28.1454 45.9214 28.0043 45.8217 27.8937L39.6705 21.7735C39.5594 21.6742 39.4176 21.6157 39.2685 21.6075H16.0522Z'
        fill='url(#paint1_linear_62887_6027)'
      />
    </g>
    <path
      opacity='0.1'
      d='M42.9114 29.8818V28.0068H20.1069V29.8818H42.9114Z'
      fill='url(#paint2_linear_62887_6027)'
    />
    <path
      opacity='0.1'
      d='M42.9114 35.5615V33.6865H20.1069V35.5615H42.9114Z'
      fill='url(#paint3_linear_62887_6027)'
    />
    <path
      opacity='0.1'
      d='M42.9114 41.2411V39.3661H20.1069V41.2411H42.9114Z'
      fill='url(#paint4_linear_62887_6027)'
    />
    <path d='M46.1414 28.125H39.4968V21.5137' fill='#7E8598' />
    <g style={{ mixBlendMode: 'luminosity' }} opacity='0.2'>
      <rect x='30.9397' y='12.9841' width='23.7905' height='23.6719' fill='url(#pattern0)' />
    </g>
    <g style={{ mixBlendMode: 'luminosity' }}>
      <g style={{ mixBlendMode: 'luminosity' }}>
        <path
          d='M31.7446 14.1359C31.6823 14.1359 31.6226 14.1604 31.5784 14.2041C31.5343 14.2478 31.5093 14.3071 31.509 14.3691V50.5406C31.509 50.6028 31.5338 50.6624 31.578 50.7064C31.6222 50.7503 31.6821 50.775 31.7446 50.775H59.5489C59.6113 50.775 59.6713 50.7503 59.7154 50.7064C59.7596 50.6624 59.7844 50.6028 59.7844 50.5406V14.505C59.7839 14.443 59.7589 14.3837 59.7148 14.3399C59.6708 14.2961 59.6112 14.2712 59.5489 14.2706L31.7446 14.1359Z'
          fill='white'
        />
      </g>
    </g>
    <path
      opacity='0.5'
      d='M71.1467 35.5615V32.7314H35.1929V35.5615H71.1467Z'
      fill='url(#paint5_linear_62887_6027)'
    />
    <path
      opacity='0.5'
      d='M80 28.1189V25.2888L35.1928 25.2888V28.1189L80 28.1189Z'
      fill='url(#paint6_linear_62887_6027)'
    />
    <path
      opacity='0.5'
      d='M80 20.6763V17.8463L35.1928 17.8463V20.6763L80 20.6763Z'
      fill='url(#paint7_linear_62887_6027)'
    />
    <path
      d='M10.4672 18.4326C13.8511 18.4326 16.5943 15.7031 16.5943 12.3361C16.5943 8.96903 13.8511 6.2395 10.4672 6.2395C7.08329 6.2395 4.34009 8.96903 4.34009 12.3361C4.34009 15.7031 7.08329 18.4326 10.4672 18.4326Z'
      fill='#6494FF'
    />
    <path
      d='M8.09375 11.8629H9.96778V9.94098H10.9571V11.8629H12.8415V12.8093H10.9571V14.7311H9.96778V12.8093H8.09375V11.8629Z'
      fill='white'
    />
    <defs>
      <pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
        <use xlinkHref='#image0_62887_6027' transform='scale(0.00990099)' />
      </pattern>
      <linearGradient
        id='paint0_linear_62887_6027'
        x1='64.2335'
        y1='11.0869'
        x2='14.484'
        y2='61.0857'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#141414' stopOpacity='0' />
        <stop offset='1' stopColor='#141414' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_62887_6027'
        x1='2164.03'
        y1='9832.95'
        x2='5635.48'
        y2='6468.8'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#141414' stopOpacity='0' />
        <stop offset='1' stopColor='#141414' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_62887_6027'
        x1='197208'
        y1='-8948.53'
        x2='195056'
        y2='-8948.53'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#141414' stopOpacity='0' />
        <stop offset='1' stopColor='#141414' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_62887_6027'
        x1='197202'
        y1='-8903.11'
        x2='195050'
        y2='-8903.11'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#141414' stopOpacity='0' />
        <stop offset='1' stopColor='#141414' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_62887_6027'
        x1='197197'
        y1='-8857.65'
        x2='195044'
        y2='-8857.65'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#141414' stopOpacity='0' />
        <stop offset='1' stopColor='#141414' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_62887_6027'
        x1='74.9242'
        y1='34.1465'
        x2='39.8711'
        y2='34.1465'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A74E7' stopOpacity='0' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_62887_6027'
        x1='396949'
        y1='-14194'
        x2='388639'
        y2='-14194'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A74E7' stopOpacity='0' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_62887_6027'
        x1='396957'
        y1='-14283.8'
        x2='388647'
        y2='-14283.8'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A74E7' stopOpacity='0' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <image
        id='image0_62887_6027'
        width='101'
        height='101'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABlCAYAAABUfC3PAAAACXBIWXMAAAsSAAALEgHS3X78AAAEU0lEQVR4Xu2cX4gVZRjGn7c4yF5oV4FhBXvRFlsoaVBBKkS7UFLRXyVWK4RIooukIKj0YBKUFBSkFrSFeZFtQhoJSRS20CKI0UWL7EJGbRgEUi34osv2dDFzzpnpzJnxSp9zfH/wcb7vm3e+OZwf7/vNDOwaSQRaXFYVEFx4QoogIUWQkCJISBEkpAgSUgQJKYKEFEFCiiAhRZCQIkhIESSkCBJSBAkpgoQUQUKKICFFkJAiSEgRJKQIElIECSmChBRBQoogIUWQkCJISBEkpAgSUgQJKYKEFEFCiiAhRRA5KWZ2yf8RppwUEHNmdq4qrJfRk2I4A+CMmf1VFdqr6EnhuAPwk4Rjp52qCu9F9KTYSn+M8H6D2zPw5ww/V53Sa5jaPzcws5+K5kneWDTfi+hlCuCNxqlPm30zO1Z+Wu8gKIVNEUMDk54dm9l4+bm9gWL5OsxbgI01YnTC2gMIEBxuP9A7CGYK3I7BRyfMibeTMvYGHHzNAfj3RjezA1WLdDOKmfJJaQBXA3YE/Ogw8PjQutLYLkVRyoetEQEYeOt1sKPThfEknyw80MVIlq9Ws2SDPzrdmuNCz8aMYf3Ozkt1J+JSCprNOgC/6X36cdAftb1uZm92Xq77UCxf29smkypWytMEdoEvl0d1B9KZQhxKs6Nz5qxg8rnb4Oi3lzot2k1ISjmbPjCOcSYn4AF+nu4rcD6/xmffpX+N+eZx+wW+BXdtLlm7K1AsX5tyE5wA7Pa0j6SMZcoZ5wCrJc/91gfwBcB2ACR3oUuRzJRG46uDDrsts8nDeTf8Fcu8eqnRX+yjWx/8Cj7stiOdN3ui8yW0UcyUtdlxIynqG4D6nnzsngXEhrNJyswdIWqr2+8GSO5rmxRHUcp9VTH/Z8nVdcx8WYctReFdGsmD7bO6SJevosYT+53L0v7fyefvM3W3ZSV3aZseGSq6kCpyUib5cV4C4N8uzOwhNzzk9mPaX/RgLnbVAJ21dMzWvO3+zGFb7yi+oh5yUgZtvb/OD1o/KOAbZzOSxlr97af356R8N2WONUn/qfRmgPONdba5ma0ovqoWinvKYHY8RWCg5GmeBwC7vzU+QeB6uweGQ825OxcT3/yRLEIAICchjKKU/qL3KufxpiUXYwTY4YRfeS+uwcGTxUcvPnLlC4A33g4D8LUcdgA+TDj4W9smTmzNlToAvo7wf5cn/ZH3kjI2eprNveZa+8LfwarFhVcXQDFTFnEbcG4LsaAgN1bycozbPK76gTh1c1Xu5HmLI9hse5PBnwSuNJD8p/ysiwBJqQagVtWIfYXzX4E1jiT9abbmlz7L8vXO43tdyCaXKYHmnnLJE1IECSmChBRBQoogIUWQkCJISBEkpAgSUgQJKYKEFEFCiiAhRZCQIkhIESSkCBJSBAkpgoQUQUKKICFFkJAiSEgRJKQIElIECSmChBRBQoogIUWQkCJISBEkpAgSUgQJKYL8B3/lqbl92NEOAAAAAElFTkSuQmCC'
      />
    </defs>
  </svg>
)

export const FlexportUploadIcon = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.5 0.559988V4.68749C9.5 4.70406 9.50658 4.71996 9.51831 4.73168C9.53003 4.7434 9.54592 4.74999 9.5625 4.74999H13.69C13.6949 4.74992 13.6997 4.74842 13.7037 4.74566C13.7078 4.74291 13.7109 4.73903 13.7128 4.73451C13.7146 4.72998 13.7151 4.72501 13.7142 4.72021C13.7133 4.71541 13.7109 4.71098 13.7075 4.70749L9.5425 0.542488C9.53901 0.539062 9.53458 0.536737 9.52978 0.535801C9.52498 0.534865 9.52 0.535359 9.51548 0.537222C9.51095 0.539086 9.50708 0.542236 9.50432 0.546283C9.50157 0.55033 9.50007 0.555095 9.5 0.559988Z'
      fill='#39414D'
    />
    <path
      d='M13.5976 6.289H7.99985V0.691285C8.00024 0.68798 7.99988 0.68463 7.99879 0.681485C7.9977 0.67834 7.99591 0.675484 7.99356 0.673131C7.99121 0.670777 7.98835 0.668989 7.9852 0.667899C7.98206 0.66681 7.97871 0.666448 7.9754 0.666841H2.4388C2.42259 0.666841 2.40704 0.673279 2.39558 0.68474C2.38412 0.6962 2.37769 0.711744 2.37769 0.727951V15.2722C2.37769 15.2885 2.38412 15.304 2.39558 15.3155C2.40704 15.3269 2.42259 15.3334 2.4388 15.3334H13.5609C13.5771 15.3334 13.5927 15.3269 13.6041 15.3155C13.6156 15.304 13.622 15.2885 13.622 15.2722V6.31345C13.6224 6.31014 13.622 6.30679 13.621 6.30365C13.6199 6.3005 13.6181 6.29765 13.6157 6.29529C13.6134 6.29294 13.6105 6.29115 13.6074 6.29006C13.6042 6.28897 13.6009 6.28861 13.5976 6.289ZM10.7498 11.5445H8.73317V13.5612C8.73317 13.5774 8.72674 13.5929 8.71528 13.6044C8.70382 13.6158 8.68827 13.6223 8.67206 13.6223H7.32763C7.31143 13.6223 7.29588 13.6158 7.28442 13.6044C7.27296 13.5929 7.26652 13.5774 7.26652 13.5612V11.5445H5.24988C5.23367 11.5445 5.21813 11.5381 5.20667 11.5266C5.19521 11.5151 5.18877 11.4996 5.18877 11.4834V10.139C5.18877 10.1228 5.19521 10.1072 5.20667 10.0958C5.21813 10.0843 5.23367 10.0779 5.24988 10.0779H7.26652V8.06121C7.26652 8.045 7.27296 8.02946 7.28442 8.018C7.29588 8.00654 7.31143 8.0001 7.32763 8.0001H8.67206C8.68827 8.0001 8.70382 8.00654 8.71528 8.018C8.72674 8.02946 8.73317 8.045 8.73317 8.06121V10.0779H10.7498C10.766 10.0779 10.7816 10.0843 10.793 10.0958C10.8045 10.1072 10.8109 10.1228 10.8109 10.139V11.4834C10.8109 11.4996 10.8045 11.5151 10.793 11.5266C10.7816 11.5381 10.766 11.5445 10.7498 11.5445Z'
      fill='#39414D'
    />
  </svg>
)
