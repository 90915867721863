import ReactTooltip from 'react-tooltip'
import { Colors } from 'src/resources/colors'
import { fontSecondary } from 'src/resources/typography'
import { device } from 'src/resources/utils/mediaQuery'
import styled, { css } from 'styled-components'

export const NavContainer = styled.ol`
  padding: 0;
  position: absolute;
  right: 45%;
  text-align: center;
  top: 1.75rem;

  @media screen and ${device.tabletAndUp} {
    left: 0;
    right: 40%;
  }
`

export const NavItem = styled.li<{ active?: boolean; complete?: boolean }>`
  border: 1px solid ${Colors.text80};
  border-radius: 50%;
  display: inline-block;
  height: 0.625rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 0.625rem;
  ${(props) =>
    props.active &&
    css`
      border-color: ${Colors.brandPrimary};
    `}
  ${(props) =>
    props.complete &&
    css`
      background-color: ${Colors.brandPrimary};
      border-color: ${Colors.brandPrimary};
    `}
`
export const StyledTooltip = styled(ReactTooltip)`
  font-family: ${fontSecondary};
`
export const SignUpNavigation = ({ step }: { step: number }) => (
  <NavContainer>
    <NavItem active={step === 1} complete={step > 1} data-tip='Create account' data-for='s1' />
    <NavItem active={step === 2} complete={step > 2} data-tip='Personalize' data-for='s2' />
    <StyledTooltip id='s1' place='top' type='dark' effect='float' />
    <StyledTooltip id='s2' place='top' type='dark' effect='float' />
  </NavContainer>
)
