import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import styled from 'styled-components'

interface ITokenExpiredFormData {
  email: string
}

const TokenExpiredFormElement: TForm<ITokenExpiredFormData> = Form

const Container = styled.div`
  button {
    width: 100%;
  }
`

export const TokenExpiredForm = ({
  data,
  onSubmit,
  sent
}: {
  data: ITokenExpiredFormData
  onSubmit: (event: IFormSubmitEvent<ITokenExpiredFormData>) => Promise<void>
  sent: boolean
}) => {
  return (
    <Container>
      <TokenExpiredFormElement onSubmit={onSubmit}>
        <InputGroup>
          <Input label='EMAIL ADDRESS' name='email' formValue={data.email} disabled />
        </InputGroup>
        <ButtonGroup>
          <FlatButton
            aria-label='Send me an email to accept the invite'
            size='md'
            type='submit'
            disabled={sent}
          >
            Resend activation link
          </FlatButton>
        </ButtonGroup>
      </TokenExpiredFormElement>
    </Container>
  )
}
