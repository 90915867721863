import { PreviewFilePanel } from 'src/applications/Oversight/panels/PreviewFilePanel'
import { useParams } from 'src/resources/hooks/useParams'

export const PreviewFileScene = () => {
  const { workspaceId, batchId } = useParams<{
    workspaceId: string
    batchId: string
  }>()

  return <PreviewFilePanel workspaceId={workspaceId} batchId={batchId} />
}
