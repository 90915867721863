import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { EmptyState } from 'src/applications/Oversight/panels/ImportDataPanel'
import { Header } from 'src/resources/elements/Header'
import { useParams } from 'src/resources/hooks/useParams'
import NoActivityIcon from 'src/resources/icons/no-activity.svg'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import styled from 'styled-components'

const CenteredHeader = styled(Header)`
  p {
    text-align: center;
  }
`

const DEFAULT_TITLE = 'No activity'
const DEFAULT_BODY = (
  <CenteredHeader>
    <p>
      Gather and collaborate on customer data by
      <br />
      inviting your team or uploading data
    </p>
  </CenteredHeader>
)
export const EmptyUploadsPanel = ({
  title = DEFAULT_TITLE,
  children = DEFAULT_BODY
}: {
  title?: string
  children?: JSX.Element
}) => {
  const { workspaceId } = useParams<{
    workspaceId: string
    schemaId: string
  }>()

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const createDataRequestModal = useCreateDataRequestModal(workspaceQuery.result)

  return (
    <>
      {createDataRequestModal.render()}
      <EmptyState>
        <img alt='No activity icon' src={NoActivityIcon} />
        <h1>{title}</h1>
        {children}
      </EmptyState>
    </>
  )
}
