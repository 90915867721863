export enum EBatchStatus {
  cancelled = 'cancelled',
  dataHookError = 'dataHookError',
  initial = 'initial',
  matchAutoActive = 'matchAutoActive',
  matchAutoPending = 'matchAutoPending',
  matchComplete = 'matchComplete',
  matchFailed = 'matchFailed',
  matchNeedsHumanAttention = 'matchNeedsHumanAttention',
  reset = 'reset',
  submitted = 'submitted',
  webhookFailed = 'webhookFailed'
}
