import { useQuery } from '@apollo/client'
import { isEqual } from 'lodash'
import { MutableRefObject, useCallback, useContext, useRef, useState } from 'react'
import { JsonSchemaCodeEditor } from 'src/applications/Oversight/components/JsonSchemaCodeEditor'
import { SchemaMutations } from 'src/applications/Oversight/components/SchemaMutations'
import { ManageDataHooksPanel } from 'src/applications/Oversight/panels/ManageDataHooksPanel'
import { TemplateDataHooksPanel } from 'src/applications/Oversight/panels/TemplateDataHooksPanel'
import { LIVE_ENVIRONMENT_SLUG } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { Form, getFormContext, TForm } from 'src/resources/elements/form/Form'
import { SchemaModelInput } from 'src/resources/elements/form/SchemaModelInput'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import Pill from 'src/resources/elements/Pill'
import { Spinner, SpinnerWrapper } from 'src/resources/elements/Spinner'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { TextSymbol } from 'src/resources/elements/TextSymbol'
import { Spacing } from 'src/resources/layout'
import { fontPrimary, fontSizes } from 'src/resources/typography'
import { SQ_WORKSPACES_TEMPLATE } from 'src/smart/queries/SQ_WORKSPACES_TEMPLATE'
import {
  GetWorkspacesByTemplate,
  GetWorkspacesByTemplateVariables,
  GetWorkspacesByTemplate_getWorkspacesByTemplate
} from 'src/smart/queries/types/GetWorkspacesByTemplate'
import { SmartGetEnvironment_getEnvironmentBySlug } from 'src/smart/queries/types/SmartGetEnvironment'
import { EFieldAction } from 'src/types/enums/EFieldAction'
import { ESchemaTab } from 'src/types/enums/ESchemaTab'
import { ISchema } from 'src/types/interfaces/ISchema'
import { pluralize } from 'src/utils/pluralize'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { SchemaMutationsLegacy } from '../components/SchemaMutationsLegacy'
import { SchemaSettingsForm } from '../components/SchemaSettingsForm'
import { Tooltip } from '../components/Tooltip'

interface HeaderCountPillProps {
  entity: string
  label: string
  items: { name: string }[]
  loading: boolean
  autoLabel?: string
}

const StyledPillTooltip = styled(Tooltip)`
  &.__react_component_tooltip {
    padding: 6px 8px;
    width: 200px;
  }
`

const TooltipContent = styled.div`
  display: flex;
  flex-flow: column;

  span,
  li {
    font-family: ${fontPrimary};
    font-style: normal;
    font-size: ${fontSizes.type14};
    line-height: 20px;
    color: ${Colors.white};
  }

  span {
    margin-top: 10px;
  }

  ul {
    list-style: outside;
    padding: 0;
    margin: 0 0 0 20px;

    li {
      font-weight: 600;
      margin-top: 0;
      line-height: 13px;
      word-break: break-word;
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

const PillContainer = styled.div`
  display: flex;
  margin-bottom: 0 !important; // Something is applying margin-bottom and I want to cancel it out here to center the pills
`

const HeaderButton = styled(FormButton)`
  color: ${Colors.brandPrimary};
  margin-left: ${Spacing.basePadding2x};
`

const TabLabels: Record<ESchemaTab, string | JSX.Element> = {
  [ESchemaTab.Configure]: 'Configure',
  [ESchemaTab.GraphQLMutationHooks]: 'GraphQL Mutation Hooks',
  [ESchemaTab.Json]: 'JSON',
  [ESchemaTab.Settings]: 'Settings',
  [ESchemaTab.DataHooks]: (
    <>
      Data Hooks
      <TextSymbol reg />
    </>
  )
}

const SchemaTabContent = ({
  state,
  disabled,
  fieldAction,
  fieldId,
  jsonSchemaErrors,
  schema,
  showArchiveToggle = true
}: {
  state: string
  disabled: boolean
  fieldAction?: EFieldAction
  dataHookfieldAction?: EFieldAction
  fieldId?: string
  jsonSchemaErrors: string[]
  schema: ISchema
  showArchiveToggle?: boolean
}) => {
  switch (state) {
    case ESchemaTab.Configure:
      return (
        <>
          <SchemaModelInput
            disabled={disabled}
            schema={schema}
            fieldAction={fieldAction}
            fieldId={fieldId}
            jsonSchemaErrors={jsonSchemaErrors}
            name='jsonSchemaPropArray'
          ></SchemaModelInput>
        </>
      )
    case ESchemaTab.Json:
      return (
        <JsonSchemaCodeEditor
          jsonSchema={schema.jsonSchema}
          jsonSchemaErrors={jsonSchemaErrors}
          disabled={disabled}
        />
      )
    case ESchemaTab.Settings:
      return (
        <SchemaSettingsForm
          showArchiveToggle={showArchiveToggle}
          disabled={disabled}
          schema={schema}
        />
      )
    default:
      return null
  }
}

const HeaderCountPill = ({ entity, label, items, loading, autoLabel }: HeaderCountPillProps) => {
  const customStyle = {
    color: Colors.pigeon800,
    background: Colors.pigeon100
  }

  return (
    <div data-for={entity} data-tip>
      <Pill customStyle={customStyle}>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          label
        )}
      </Pill>
      {!!items?.length && (
        <StyledPillTooltip
          backgroundColor={Colors.textLogo}
          id={entity}
          content={
            <TooltipContent>
              <ul data-testid={`${entity}-items-list`}>
                {items.map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>

              {autoLabel && <span>{autoLabel}</span>}
            </TooltipContent>
          }
        />
      )}
    </div>
  )
}

const insertIf = (condition: boolean, ...elements: ESchemaTab[]) => (condition ? elements : [])

const SchemaTabHeaders = ({ tabState, schema }: { tabState: string; schema: ISchema }) => {
  const team = useContext(TeamContext)
  const { history } = useReactRouter()
  const formContext = getFormContext()

  const reload = () => {
    formContext.setValue({ data: { ...schema }, hasJsonChanges: false })
  }

  const TEMPLATE_FROM_PLATFORM_SDK = !!schema.slug
  const enableDataHookEditorForPlatformSDKDeployments =
    team.featureFlags?.ENABLE_DATA_HOOKS_EDITOR_FOR_PLATFORM_SDK_DEPLOYMENTS
  return (
    <Tabstrip>
      {[
        ESchemaTab.Configure,
        ...insertIf(!TEMPLATE_FROM_PLATFORM_SDK, ESchemaTab.Json),
        ESchemaTab.Settings,
        ...insertIf(
          !TEMPLATE_FROM_PLATFORM_SDK || enableDataHookEditorForPlatformSDKDeployments,
          ESchemaTab.DataHooks
        )
      ]
        .concat(team.featureFlags?.WORKSPACES_GRAPHQL ? [ESchemaTab.GraphQLMutationHooks] : [])
        .map((state, index) => {
          return (
            <Tab
              key={index}
              active={tabState === state}
              onClick={() => {
                if (
                  !isEqual(formContext.value.initialData, formContext.value.data) ||
                  formContext.value.hasJsonChanges
                ) {
                  const confirmed = window.confirm(
                    'You have unsaved changes. Are you sure you want to leave?'
                  )
                  if (confirmed) {
                    reload()
                    history.push(`${location.pathname}?tab=${state}`)
                  }
                } else {
                  history.push(`${location.pathname}?tab=${state}`)
                }
              }}
            >
              {TabLabels[state]}
            </Tab>
          )
        })}
    </Tabstrip>
  )
}

const SchemaTabAfterFormContent = ({
  state,
  onSchemaIdUpdate,
  schema
}: {
  state: string
  onSchemaIdUpdate(id: number | string, tab: string, dataHook?: string): void
  schema: ISchema
}) => {
  const team = useContext(TeamContext)
  const useNewDataHooksUI = !team.featureFlags?.V3_DATA_HOOKS_LEGACY
  switch (state) {
    case ESchemaTab.GraphQLMutationHooks:
      if (useNewDataHooksUI) {
        return <SchemaMutations onSchemaIdUpdate={onSchemaIdUpdate} schema={schema} />
      } else {
        return <SchemaMutationsLegacy onSchemaIdUpdate={onSchemaIdUpdate} schema={schema} />
      }
    case ESchemaTab.DataHooks:
      if (useNewDataHooksUI) {
        return <TemplateDataHooksPanel onSchemaIdUpdate={onSchemaIdUpdate} schema={schema} />
      } else {
        return <ManageDataHooksPanel onSchemaIdUpdate={onSchemaIdUpdate} schema={schema} />
      }
    default:
      return null
  }
}

const HeaderButtons = ({
  environment,
  onClone,
  testMode
}: {
  environment: SmartGetEnvironment_getEnvironmentBySlug
  onClone(environmentSlug?: string): void | Promise<void>
  testMode: boolean
}) => {
  const formContext = getFormContext()

  const copyTemplateToProd = useCallback(() => {
    onClone(LIVE_ENVIRONMENT_SLUG)
  }, [onClone])

  const cloneTemplate = useCallback(() => {
    onClone()
  }, [onClone])

  return (
    <>
      <HeaderButton
        color='secondary'
        onClick={cloneTemplate}
        disabled={!isEqual(formContext.value.initialData, formContext.value.data)}
      >
        Duplicate
      </HeaderButton>

      {environment.slug !== LIVE_ENVIRONMENT_SLUG && !testMode && (
        <HeaderButton color='secondary' onClick={copyTemplateToProd}>
          Copy to production
        </HeaderButton>
      )}
    </>
  )
}

/* Form used for create or edit schema */
export const EditSchemaForm = ({
  disabled,
  fieldAction,
  fieldId,
  formRef,
  jsonSchemaErrors,
  schema,
  showArchiveToggle,
  showHeader = true,
  tabState,
  onClone,
  onSchemaIdUpdate,
  onSubmit
}: {
  disabled: boolean
  fieldAction?: EFieldAction
  fieldId?: string
  formRef?: MutableRefObject<HTMLFormElement>
  jsonSchemaErrors: string[]
  schema: ISchema
  showArchiveToggle?: boolean
  showHeader?: boolean
  tabState: string
  onClone(environmentSlug?: string): void | Promise<void>
  onSchemaIdUpdate(id: number | string, tab: string, dataHook?: string): void
  onSubmit(data: any): void | Promise<void>
}) => {
  const team = useContext(TeamContext)
  const [usingWorkspaces, setUsingWorkspaces] = useState([])
  const environment = useContext(EnvironmentContext)
  const SchemaFormElement: TForm<ISchema> = Form
  const formElementRef = useRef<HTMLFormElement>()
  const createTemplatesFromUI = team.featureFlags?.CREATE_TEMPLATES_FROM_UI

  const { loading } = useQuery<GetWorkspacesByTemplate, GetWorkspacesByTemplateVariables>(
    SQ_WORKSPACES_TEMPLATE,
    {
      fetchPolicy: 'network-only',
      variables: {
        teamId: team.id,
        templateId: Number(schema.id),
        environmentId: environment.id,
        versionsFilter: 'exact'
      },
      skip: !schema.id || !team.id || !environment.id,
      onCompleted: ({ getWorkspacesByTemplate }) => {
        const workspaces: GetWorkspacesByTemplate_getWorkspacesByTemplate[] =
          getWorkspacesByTemplate
            ?.filter(({ status }) => !status)
            .sort((a, b) => Number(a.createdAt) - Number(b.createdAt))

        setUsingWorkspaces(workspaces)
      }
    }
  )

  return (
    <>
      <SchemaFormElement
        formRef={formRef ?? formElementRef}
        initialValue={schema}
        onSubmit={onSubmit}
      >
        {showHeader && (
          <PageHeaderContainer
            hasMargin
            header={schema.name}
            pill={
              <PillContainer>
                <HeaderCountPill
                  entity='embeds'
                  label={`${schema.embeds.length} ${pluralize('Portal', schema.embeds.length)}`}
                  items={schema.embeds}
                  loading={!schema}
                  autoLabel='Portals update automatically'
                />
                <HeaderCountPill
                  entity='workspaces'
                  label={`${usingWorkspaces.length} ${pluralize(
                    'Workspace',
                    usingWorkspaces.length
                  )}`}
                  items={usingWorkspaces}
                  loading={loading}
                />
              </PillContainer>
            }
          >
            {createTemplatesFromUI && (
              <HeaderButtons
                environment={environment}
                onClone={onClone}
                testMode={team.testModeOnly}
              />
            )}
          </PageHeaderContainer>
        )}
        <SchemaTabHeaders tabState={tabState} schema={schema} />
        <SchemaTabContent
          state={tabState}
          disabled={disabled}
          fieldId={fieldId}
          fieldAction={fieldAction}
          jsonSchemaErrors={jsonSchemaErrors}
          schema={schema}
          showArchiveToggle={showArchiveToggle}
        />
      </SchemaFormElement>
      <SchemaTabAfterFormContent
        state={tabState}
        onSchemaIdUpdate={onSchemaIdUpdate}
        schema={schema}
      />
    </>
  )
}
