import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { EStatusType } from 'src/types/enums/EStatusType'
import styled, { css } from 'styled-components'

import ReactTooltip from 'react-tooltip'
import { useMemo } from 'react'
import { Transitions } from '../animations/transitions'

export const StatusWrapper = styled.span<{
  type: string
  width?: number
}>`
  font-family: ${fontFamily.fontSecondary};
  font-weight: 600;
  font-size: ${fontSizes.type14};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: ${fontSizes.type20};
  padding: ${Spacing.halfBasePadding} 14px;
  border-radius: ${Spacing.baseAndHalfPadding};
  -webkit-font-smoothing: antialiased;
  transition: ${Transitions.baseEase};
  overflow: hidden;

  ${({ width }) =>
    width
      ? `
        width: ${width}px;`
      : `
        min-width: 185px;
        max-width: 185px;
  `}

  ${(props) => {
    if (props.type === EStatusType.abandoned) {
      return css`
        color: ${Colors.brandText};
      `
    }

    if (props.type === EStatusType.failed) {
      return css`
        color: ${Colors.dangerStrong};
        background-color: ${Colors.dangerBGColor};
      `
    }

    if (props.type === EStatusType.inProgress) {
      return css`
        background-color: ${Colors.selectHighlight};
        color: ${Colors.brandPrimary};
      `
    }

    if (props.type === EStatusType.needsAttention) {
      return css`
        color: ${Colors.warningStrong};
        background-color: ${Colors.warningBGColor};
      `
    }

    if (props.type === EStatusType.completed) {
      return css`
        color: ${Colors.successStrong};
        background-color: ${Colors.successBGColor};
      `
    }

    return ``
  }}
`

export const Status: React.FC<{ type: string; tooltip?: string; width?: number }> = ({
  children,
  tooltip,
  width,
  ...props
}) => {
  const tooltipId = useMemo(() => {
    if (tooltip) {
      return Math.random().toString(36).slice(2)
    }
  }, [tooltip])
  return (
    <>
      {tooltipId && <ReactTooltip id={tooltipId} place='bottom' type='dark' effect='float' />}
      <StatusWrapper
        {...(tooltipId ? { 'data-tip': tooltip, 'data-for': tooltipId } : {})}
        {...(width ? { width } : {})}
        {...props}
      >
        {children}
      </StatusWrapper>
    </>
  )
}
