import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'

import {
  ArchiveBatch,
  ArchiveBatchVariables,
  ArchiveBatch_archiveBatch
} from './types/ArchiveBatch'

export const SM_ARCHIVE_BATCH: SmartMutation<
  ArchiveBatchVariables,
  ArchiveBatch,
  ArchiveBatch_archiveBatch
> = {
  mutation: gql`
    mutation ArchiveBatch($batchId: UUID!) {
      archiveBatch(batchId: $batchId) {
        id
        deleted
      }
    }
  `,
  extract: (response: any) => response.archiveBatch
}
