import { WatchQueryFetchPolicy } from '@apollo/client'
import { useContext } from 'react'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_SCHEMAS } from 'src/smart/queries/SQ_SCHEMAS'

export function useGetSchemas(
  {
    includeIds,
    archived = false,
    take = 1000
  }: {
    includeIds?: string[] // schema ids to always load, even if archived (ignores archived flag)
    archived?: boolean
    take?: number
  } = {},
  { fetchPolicy }: { fetchPolicy?: WatchQueryFetchPolicy } = {}
) {
  const sessionContext = useContext(SessionContext)
  const environment = useContext(EnvironmentContext)

  return useSmartQuery(SQ_SCHEMAS, {
    fetchPolicy,
    variables: {
      archived,
      environmentId: environment?.id,
      includeIds, // schema ids to always load, even if archived
      skip: 0,
      take,
      teamId: sessionContext.activeTeamId
    }
  })
}
