import { useState } from 'react'
import { storage } from 'src/resources/utils/storage'
// This uses defaultValue: any on purpose to accept any kind of state,
// then stringifies it for storage
export const useLocalStorageState = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = storage(keyName).getRaw()

      if (value) {
        return JSON.parse(value)
      } else {
        if (!!defaultValue) {
          storage(keyName).set(defaultValue)
        }
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  const setValue = (newValue: string) => {
    try {
      storage(keyName).set(newValue)
    } catch (err) {}
    setStoredValue(newValue)
  }

  const removeItem = () => {
    storage(keyName).clear()
  }

  return [storedValue, setValue, removeItem]
}

export const hasLocalStorageItem = (keyName: string) => {
  try {
    const value = storage(keyName).getRaw()
    return !!value
  } catch (err) {
    return false
  }
}
