import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { NotFoundPanel } from 'src/applications/Oversight/scenes/NotFoundScene'
import {
  GithubScene,
  PersonalizeScene,
  SignUpScene
} from 'src/applications/Oversight/scenes/SignUp'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const PanelWrap = styled.div`
  margin: ${Spacing.contentPadding};
`

export const SignupRoutes = ({ match: { path: signupPath } }: RouteComponentProps) => {
  return (
    <Switch>
      {/* Sign up */}
      <Route component={PersonalizeScene} exact path={`${signupPath}/personalize`} />
      <Route component={GithubScene} exact path={`${signupPath}/github`} />
      <Route component={SignUpScene} exact path={signupPath} />
      <Route
        render={() => (
          <PanelWrap>
            <NotFoundPanel />
          </PanelWrap>
        )}
      />
    </Switch>
  )
}
