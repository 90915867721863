import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { FieldLabel, HelperText, RequiredMark } from 'src/resources/elements/form/FieldLabel'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Select } from 'src/resources/elements/form/Select'
import { IOrganization } from 'src/types/interfaces/IOrganization'
import { ISchemaStub } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { SelectWorkspaceSchemas } from './SelectWorkspaceSchemas'
import { CheckBox } from 'src/resources/elements/form/CheckBox'
import { useCallback, useState } from 'react'
import { fontSizes } from 'src/resources/typography'
import { Spacing } from 'src/resources/layout'

export interface ICreateWorkspaceFormData {
  organizationId: string
  name: string
  schemaIds: string[]
}

const WideInput = styled(Input)`
  max-width: unset;
`

const NonFullWidthInputGroup = styled(InputGroup)`
  max-width: 440px;
  margin-bottom: 32px;
`

const DataTemplateLink = styled.a`
  :hover {
    cursor: pointer;
  }
`

const CheckboxContent = styled.div`
  position: relative;
  top: calc(${Spacing.basePadding} + ${Spacing.quarterBasePadding});
  font-size: ${fontSizes.type14};
  font-weight: 400;
  margin-bottom: ${Spacing.halfBasePadding};
`

const CreateWorkspaceFormElement: TForm<ICreateWorkspaceFormData> = Form

export const CreateWorkspaceForm = ({
  organizations,
  initialValue,
  schemas,
  isLiveEnvironment,
  onCancel,
  onSubmit
}: {
  schemas: ISchemaStub[]
  organizations: IOrganization[]
  initialValue: Partial<ICreateWorkspaceFormData>
  isLiveEnvironment: boolean
  onCancel: () => void
  onSubmit: (event: IFormSubmitEvent<ICreateWorkspaceFormData>) => Promise<void>
}) => {
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()
  const [confirmWorkspaceCreditUsage, setConfirmWorkspaceCreditUsage] = useState(false)
  const teamHasSchemas = schemas.length > 0

  const toggleConfirmWorkspaceCreditUsage = useCallback(() => {
    setConfirmWorkspaceCreditUsage(!confirmWorkspaceCreditUsage)
  }, [setConfirmWorkspaceCreditUsage, confirmWorkspaceCreditUsage])

  return (
    <CreateWorkspaceFormElement disableIfUnchanged initialValue={initialValue} onSubmit={onSubmit}>
      {!teamHasSchemas && (
        <NonFullWidthInputGroup>
          <FieldLabel>
            Data template(s)<RequiredMark>*</RequiredMark>
          </FieldLabel>
          <HelperText>
            You don't have a data template. Please configure one{' '}
            <DataTemplateLink onClick={() => history.push(`${teamRoot}/templates`)}>
              here
            </DataTemplateLink>{' '}
            before continuing.
          </HelperText>
        </NonFullWidthInputGroup>
      )}

      <NonFullWidthInputGroup>
        <WideInput
          autoFocus
          label='Workspace name'
          name='name'
          placeholder='Coconut Labs Onboarding Workspace'
          required
        />
        <ErrorMessage name='name' />
      </NonFullWidthInputGroup>
      <NonFullWidthInputGroup>
        <Select
          required
          label='Organization'
          helperText='The group you are collaborating with.'
          selectLabel='Select an organization'
          createLabel='Create an organization'
          name='organizationId'
          creatable
          options={organizations.map((organization) => ({
            label: organization.name,
            value: organization.id
          }))}
        />
        <ErrorMessage name='organizationId' />
      </NonFullWidthInputGroup>
      {teamHasSchemas && <SelectWorkspaceSchemas schemas={schemas} />}
      {isLiveEnvironment && (
        <CheckBox
          type='primary'
          checked={confirmWorkspaceCreditUsage}
          onChange={toggleConfirmWorkspaceCreditUsage}
          smallLabel
        >
          <CheckboxContent>
            I confirm the use of workspace credits to create this new workspace.
          </CheckboxContent>
        </CheckBox>
      )}
      <ButtonGroup left>
        <FormButton
          primary
          disabled={!teamHasSchemas || (isLiveEnvironment && !confirmWorkspaceCreditUsage)}
          submit
        >
          Create workspace
        </FormButton>
        <FormButton secondary onClick={onCancel}>
          Cancel
        </FormButton>
      </ButtonGroup>
    </CreateWorkspaceFormElement>
  )
}
