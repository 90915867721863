import { FC } from 'react'

import { DefaultTheme, themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { SCENE } from 'src/applications/Embed/types/IScene'
import { Colors, ColorUtils } from 'src/resources/colors'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

export const HeaderWrapper = styled.div<{ smallMargin?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ smallMargin }) => (smallMargin ? 16 : 24)}px;
`

export const HeaderBlock = styled.div`
  margin-left: ${({ theme }) => (theme.invertedIcon ? '1.5em' : '0.75em')};
`

export const IconBox = styled.div`
  background: ${(props) =>
    ColorUtils.hexToRgba(
      props.theme.invertedIcon ? 'transparent' : props.theme.primary || Colors.brandPrimary,
      props.theme.invertedIcon ? 1 : props.theme.primaryBackgroundIcon ? 1 : 0.1
    )};
  border-radius: ${themed('largeBorderRadius')};
  width: ${themed('headerIconBoxSize')};
  height: ${themed('headerIconBoxSize')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: ${themed('headerIconSize')};
    width: ${themed('headerIconSize')};
    path {
      fill: ${({ theme }) =>
        theme.invertedIcon
          ? theme.secondary
          : theme.primaryBackgroundIcon
          ? '#fff !important'
          : 'inherit'};
    }
  }
`

export const HeaderTitle = styled.h1`
  color: ${themed('titleFontColor')};
  font-size: ${themed('titleFontSize')};
  font-weight: ${themed('titleFontWeight')};
  line-height: 1.5;
`

export const Header: FC<{ scene?: SCENE }> = ({ scene, children }) => {
  const { t } = useTranslation()
  const theme = useTheme() as DefaultTheme
  const header: Partial<{ title: string; description?: string }> = (() => {
    switch (scene) {
      case SCENE.INITIAL:
        return {
          title: theme.textOverrides.headerInitial,
          description: theme.textOverrides?.headerInitialDesc.includes('vindicia')
            ? theme.textOverrides?.headerInitialDesc
            : null
        }
      case SCENE.SOURCE:
        return {
          title: theme.textOverrides.headerSource,
          // sage and vindicia are currently only using the headerSourceDesc - so we want this to return
          // null if it isn't them
          description:
            theme.textOverrides?.headerSourceDesc.includes('sage') ||
            theme.textOverrides?.headerSourceDesc.includes('vindicia')
              ? theme.textOverrides?.headerSourceDesc
              : null
        }
      case SCENE.HEADER_CHECK:
        return {
          title: theme.textOverrides.headerCheck,
          // sage currently only uses the headerCheckDesc - so we want this to return
          // null if it isn't Sage
          description: theme.textOverrides?.headerCheckDesc.includes('sage')
            ? theme.textOverrides?.headerCheckDesc
            : null
        }
      case SCENE.MATCH:
        return {
          title: theme.textOverrides.headerMatch,
          // sage currently only uses the headerMatchDesc - so we want this to return
          // null if it isn't Sage
          description: theme.textOverrides?.headerMatchDesc.includes('sage')
            ? theme.textOverrides?.headerMatchDesc
            : null
        }
      case SCENE.REVIEW:
        return {
          title: theme.textOverrides.headerReview,
          // sage currently only uses the headerReviewDesc - so we want this to return
          // null if it isn't Sage
          description:
            theme.textOverrides?.headerReviewDesc.includes('sage') ||
            theme.textOverrides?.headerReviewDesc.includes('vindicia')
              ? theme.textOverrides?.headerReviewDesc
              : null
        }
      default:
        return { title: 'N/A' }
    }
  })()

  return (
    <>
      <HeaderWrapper smallMargin={scene === SCENE.REVIEW}>
        <IconBox>{theme?.iconOverrides_Header}</IconBox>
        <HeaderBlock>
          <HeaderTitle>{children ?? t(header.title)}</HeaderTitle>
          {header.description && <p>{t(header.description)}</p>}
        </HeaderBlock>
      </HeaderWrapper>
    </>
  )
}
