import { useState } from 'react'

import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener'
import Slider from 'react-slick'
import {
  Container,
  Icon,
  Label,
  Logo,
  Quote,
  SourceWrap
} from 'src/applications/Oversight/scenes/SignUp/components/Hero'
import { Colors } from 'src/resources/colors'
import ClickupLogo from 'src/resources/icons/customer-logos/clickup.svg'
import EmployUsLogo from 'src/resources/icons/customer-logos/employus.svg'
import HealthspaceLogo from 'src/resources/icons/customer-logos/healthspace.svg'
import OversightLogo from 'src/resources/icons/customer-logos/oversight.svg'
import PopbookingsLogo from 'src/resources/icons/customer-logos/popbookings.svg'
import TableclothLogo from 'src/resources/icons/customer-logos/tablecloth.svg'
import QuoteIcon from 'src/resources/icons/quote.svg'
import JerryKClickup from 'src/resources/images/customer_images/jerry_krusinski_clickup.png'
import JoePopBookings from 'src/resources/images/customer_images/joe_abele_popbookings.png'
import JohnOversight from 'src/resources/images/customer_images/john_r_oversight.png'
import KellyTablecloth from 'src/resources/images/customer_images/kelly_tablecloth.png'
import MattEmployUs from 'src/resources/images/customer_images/matt_cotter_employus.png'
import SilasHealthspace from 'src/resources/images/customer_images/silas_garrison_healthspace.png'
import styled from 'styled-components'

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  dots: false,
  draggable: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false
}

const logoSettings = {
  className: 'center',
  centerMode: true,
  centerPadding: '30px',
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  draggable: false,
  dots: false,
  infinite: true,
  slidesToShow: 3,
  pauseOnHover: false
}

interface ITestimonial {
  logo: string
  quote: string
  image: string
  source: string
  title: string
  company: string
}

const logos = [
  HealthspaceLogo,
  TableclothLogo,
  PopbookingsLogo,
  OversightLogo,
  ClickupLogo,
  EmployUsLogo
]

const testimonials: { [key: string]: ITestimonial } = {
  tablecloth: {
    logo: TableclothLogo,
    image: KellyTablecloth,
    quote:
      'We have cut the amount of time we spend wrangling with files by 95%. We basically had all hands working to solve those data problems. The reduction in effort has saved us tens of thousands of dollars. Flatfile is a really good value for us.',
    source: 'Kelly Abbot',
    title: 'Co-founder and CTO',
    company: 'Tablecloth'
  },
  popbookings: {
    logo: PopbookingsLogo,
    image: JoePopBookings,
    quote:
      'Flatfile is an easy-to-use, easy-to-implement, and very effective tool to move data. It’s a great product. We’ve tried many third-party products and this is the only one that’s exceeded my expectations.',
    source: 'Joe Abele',
    title: 'CTO',
    company: 'PopBookings'
  },
  oversight: {
    logo: OversightLogo,
    image: JohnOversight,
    quote:
      'Flatfile enables customers to easily upload and import their files without tripping themselves up. The upload solution relies on back end validation rather than client side validation, so it gives us control over how data is formatted and ensures imported data is clean and ready to use.',
    source: 'John Baigent',
    title: 'Co-founder',
    company: 'Oversight'
  },
  clickup: {
    logo: ClickupLogo,
    image: JerryKClickup,
    quote: 'Flatfile saved us a big task in implementing a CSV parser ourselves.',
    source: 'Jerry Krusinski',
    title: 'Backend Engineer',
    company: 'ClickUp'
  },
  employus: {
    logo: EmployUsLogo,
    image: MattEmployUs,
    quote:
      'My first instinct is to always build it. But my co-founder found Flatfile and asked me to test it out. I got it set up in 30 minutes and told him ‘yeah, we’re using this.',
    source: 'Matt Cotter',
    title: 'CTO and Co-founder',
    company: 'EmployUs'
  },
  healthspace: {
    logo: HealthspaceLogo,
    image: SilasHealthspace,
    quote:
      'Flatfile enabled all of our product suite to bring in data from a spreadsheet in just a few short hours of engineering work.',
    source: 'Silas Garrison',
    title: 'CEO and Founder',
    company: 'HealthSpace'
  }
}

const TestimonialSource = ({
  image,
  name = 'John Doe',
  title = 'Engineer',
  company = 'Clickup',
  inverted = false
}: {
  image: string
  name: string
  title: string
  company: string
  inverted?: boolean
}) => {
  return (
    <SourceWrap inverted={inverted}>
      <img src={image} />
      <div>
        <p>{name}</p>
        <p>
          {title}, {company}
        </p>
      </div>
    </SourceWrap>
  )
}

const CarouselWrapper = styled.div`
  * {
    outline: none;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${Container} {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .slick-slide {
    outline: none;
  }

  .slick-slider {
    width: 100%;

    &:nth-of-type(2) {
      flex-grow: 1;
    }
  }

  .slick-dots {
    bottom: 0;
  }

  slick-slider slick-initialized {
    margin-bottom: 100px;
  }

  .slick-dots li button:before {
    color: ${Colors.white};
  }

  .slick-dots li.slick-active button:before {
    color: ${Colors.white};
  }
`

interface CarouselProps {
  initialSlides?: number[]
  inverted?: boolean
  compact?: boolean
  oneLogoAtATime?: boolean
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

export const RandomCarousel = (props: CarouselProps) => {
  const initialLogo = getRandomInt(logos.length)
  const initialQuote = initialLogo === 0 ? Object.keys(testimonials).length - 1 : initialLogo - 1
  return <Carousel {...props} initialSlides={[initialLogo, initialQuote]} />
}

export const Carousel = ({
  initialSlides = [1, 0],
  inverted = false,
  compact = false,
  oneLogoAtATime = false
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialSlides[0])

  const afterChangeHandler = (currentSlide: number) => {
    setCurrentIndex(currentSlide)
  }
  return (
    <CarouselWrapper>
      <Label inverted={inverted}>TRUSTED BY</Label>
      <Slider {...logoSettings} afterChange={afterChangeHandler} initialSlide={initialSlides[0]}>
        {logos.map((item, index) => {
          return (
            <Container key={index}>
              <Logo
                key={index}
                inverted={inverted}
                active={currentIndex === index}
                visible={!oneLogoAtATime || currentIndex === index}
              >
                <img src={item} />
              </Logo>
            </Container>
          )
        })}
      </Slider>

      <Slider {...settings} initialSlide={initialSlides[1]}>
        {Object.keys(testimonials).map((item, index) => {
          return (
            <Container key={index}>
              <div>
                {!compact && <Icon src={QuoteIcon} inverted={inverted} />}
                <Quote inverted={inverted}>{testimonials[item].quote}</Quote>
                <TestimonialSource
                  image={testimonials[item].image}
                  name={testimonials[item].source}
                  title={testimonials[item].title}
                  company={testimonials[item].company}
                  inverted={inverted}
                />
              </div>
              <div />
            </Container>
          )
        })}
      </Slider>
    </CarouselWrapper>
  )
}
