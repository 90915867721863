import { useContext, useState } from 'react'

import { useSentryTransaction } from 'src/applications/Oversight/hooks/useSentryTransaction'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { useCreateSchemaFromFileMutation } from 'src/queries/hooks/useCreateSchemaFromFileMutation'
import { useInitializeFileMutation } from 'src/queries/hooks/useInitializeFileMutation'
import { FlatUploadButton } from 'src/resources/elements/buttons/FlatUploadButton'
import { Message } from 'src/resources/elements/Message'
import { Spinner } from 'src/resources/elements/Spinner'
import { useGlobalError } from 'src/resources/hooks/useGlobalError'
import { getSignedUrlHeaders } from 'src/utils/getSignedUrlHeaders'
import useReactRouter from 'use-react-router'

import { useTeamRootUrl } from './useTeamRootUrl'
import * as Sentry from '@sentry/react'

export const useCreateSchemaFromCSV = (onComplete?: () => void) => {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const environment = useContext(EnvironmentContext)
  const [isCreatingSchemaFromCSV, setIsCreatingSchemaFromCSV] = useState(false)
  const [createSchemaFromFile] = useCreateSchemaFromFileMutation()
  const [initializeFile] = useInitializeFileMutation()
  const addErrors = useGlobalError()
  const { history } = useReactRouter()

  const sentryTransaction = useSentryTransaction({})

  const handleErrors =
    <T,>(callback: (arg: T) => Promise<Error | void>) =>
    async (arg: T) => {
      const errorResult = await callback(arg)
      if (errorResult) {
        addErrors([errorResult])
      }
    }

  const createSchemaFromCSV = async ({ file }: { file: File }): Promise<Error | void> => {
    // this creates an array from the filename and then takes the last piece as the file extension
    const fileExtension = file.name.split('.').pop()
    if (fileExtension !== 'csv' || !['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
      Sentry.captureException(
        `Can't parse ${file.name} of type ${file.type} into readable format`,
        {
          extra: {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            lastModified: file.lastModified
          }
        }
      )
      return new Error('Only CSV files are accepted.')
    }

    sentryTransaction.setTag('data-template', 'create-from-csv')
    setIsCreatingSchemaFromCSV(true)
    /**
     * 1. Get signedUrl to upload file to
     * 2. Upload file
     * 3. Create schema from file
     * 4. Redirect to the newly-created schema
     */
    const initializeFileExecutionResult = await initializeFile({
      variables: {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        licenseKey: team.licenses[0].key
      }
    })

    if (initializeFileExecutionResult.errors && initializeFileExecutionResult.errors.length) {
      setIsCreatingSchemaFromCSV(false)
      return new Error(
        `Error initializing file upload: ${initializeFileExecutionResult.errors
          .map((err) => err.message)
          .join(', ')}`
      )
    }

    try {
      const response = await fetch(initializeFileExecutionResult.data.initializeFile.signedUrl, {
        body: file,
        headers: getSignedUrlHeaders(initializeFileExecutionResult.data.initializeFile.signedUrl),
        method: 'PUT'
      })

      if (!response.ok) {
        setIsCreatingSchemaFromCSV(false)
        return new Error(`Error uploading file data: ${response.statusText}`)
      }
    } catch (e) {
      setIsCreatingSchemaFromCSV(false)
      return new Error(`Error uploading file data: ${e.message}`)
    }

    const createSchemaFromFileExecutionResult = await createSchemaFromFile({
      variables: {
        fileId: initializeFileExecutionResult.data.initializeFile.file.id,
        licenseKey: team.licenses[0].key,
        environmentId: environment?.id
      }
    })

    if (
      createSchemaFromFileExecutionResult.errors &&
      createSchemaFromFileExecutionResult.errors.length
    ) {
      setIsCreatingSchemaFromCSV(false)
      return new Error(
        `Error creating schema from uploaded file: ${createSchemaFromFileExecutionResult.errors
          .map((err) => err.message)
          .join(', ')}`
      )
    }

    const { id } = createSchemaFromFileExecutionResult.data.getSchemaFromFile

    if (onComplete) {
      await onComplete()
    }

    setIsCreatingSchemaFromCSV(false)

    history.push(`${teamRoot}/templates/${id}`)
  }

  return {
    uploadButton: isCreatingSchemaFromCSV ? (
      <Message>
        <Spinner />
        Please wait, processing...
      </Message>
    ) : (
      <FlatUploadButton
        accept='.csv'
        variant='outlined'
        onFileSelected={handleErrors(createSchemaFromCSV)}
      >
        Create from CSV
      </FlatUploadButton>
    )
  }
}
