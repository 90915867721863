import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'src/resources/hooks/useParams'

import { useSearchParam } from './useSearchParam'

export type Validation = 'accepted' | 'review' | 'dismissed' | 'submitted'
export type Filter = 'valid' | 'invalid' | 'merged' | ''

export const useTableFilters = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const params = useParams<{
    schemaId: string
    sheetId: string
    validationState: Validation
  }>()

  const filterParameter = useSearchParam.string('filter', '')
  const errorsParameter = useSearchParam.string('errors', '')
  const themeParameter = useSearchParam.string('theme', '')
  const jwtParameter = useSearchParam.string('jwt', '')

  const tableFilters = {
    validationState: params.validationState || 'review',
    filter: filterParameter as Filter,
    errors: errorsParameter
  }

  const pathnameWithoutQuery = pathname.split('?')[0]

  const setTableFilters = ({
    filter = '',
    errors = '',
    validationState = tableFilters.validationState
  }: {
    filter?: Filter
    errors?: string
    validationState?: Validation
  }) => {
    let pathWithFilters: string
    const pathComponent = pathnameWithoutQuery.split('/')

    if ([params.schemaId, params.sheetId].includes(pathComponent[pathComponent.length - 1])) {
      pathWithFilters = `${pathnameWithoutQuery}/${validationState}`
    } else {
      pathWithFilters = [
        ...pathComponent.slice(0, pathComponent.length - 1),
        `${validationState}`
      ].join('/')
    }

    const query = [
      ...(jwtParameter ? [`jwt=${jwtParameter}`] : []),
      ...(themeParameter ? [`theme=${themeParameter}`] : []),
      ...(filter ? [`filter=${filter}`] : []),
      ...(errors ? [`errors=${errors}`] : [])
    ].join('&')

    pathWithFilters += `${query ? `?${query}` : ''}`
    history.push(pathWithFilters)
  }

  return [tableFilters, setTableFilters] as const
}
