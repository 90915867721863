import { Search } from 'history'
import queryString from 'query-string'

export const useSearch = <TSearch extends {}, TResult>(
  search: Search,
  inflate: (data: Partial<TSearch>) => TResult
): TResult => {
  const query = queryString.parse(search, { parseNumbers: true })
  return inflate(query as any as Partial<TSearch>)
}
