import { GET_ADMIN_ORGANIZATIONS } from 'src/queries/GET_ADMIN_ORGANIZATIONS'
import {
  GetAdminOrganizations,
  GetAdminOrganizationsVariables
} from 'src/queries/types/GetAdminOrganizations'
import { SmartQuery } from 'src/smart/SmartQuery'
import { IOrganization } from 'src/types/interfaces/IOrganization'

export const SQ_ORGANIZATIONS: SmartQuery<
  GetAdminOrganizationsVariables,
  GetAdminOrganizations,
  IOrganization[]
> = {
  query: GET_ADMIN_ORGANIZATIONS,
  extract({ getOrganizationsForTeam: { data } }) {
    return data
  }
}
