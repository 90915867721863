import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { withProfiler } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'
import posthog from 'posthog-js'
import { Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { DefaultTheme } from 'src/applications/Embed/fragments/ThemeWrapper'
import { BillingPlanManager } from 'src/applications/Oversight/controls/BillingPlanManager'
import { SessionManager } from 'src/applications/Oversight/controls/SessionManager'
import { DeveloperAlerts } from 'src/applications/Oversight/developer/DeveloperAlerts'
import { DeveloperMenu } from 'src/applications/Oversight/developer/DeveloperMenu'
import { ConfigVariableNames, SENTRY_CONFIG } from 'src/config'
import { ActiveTeamContext } from 'src/contexts/ActiveTeamContext'
import { ErrorContext, ErrorDisplay } from 'src/contexts/ErrorContext'
import { UserContext } from 'src/contexts/UserContext'
import { WorkspaceChatContext } from 'src/contexts/WorkspaceChatContext'
import { TemporaryIOCOMRedirect } from 'src/controls/TemporaryIOCOMRedirect'
import { env } from 'src/env'
import { ErrorCapture, graphClient, updateClientLink } from 'src/resources/clients/graphClient'
import { applyFilter, EFilterMode, FilterModes } from 'src/resources/effects/FilterMode'
import { ScrollToTop } from 'src/resources/elements/ScrollToTop'
import { ToastContainer } from 'src/resources/elements/Toast'
import { Reset } from 'src/resources/Reset'
import { Analytics } from 'src/resources/utils/analytics'
import { trimTransactionName } from 'src/resources/utils/trimTransactionName'
import { localStorageOverride } from 'src/utils/localStorageOverride'
import { ThemeProvider } from 'styled-components'

import { routes } from './routes'
import { I18nextProvider } from 'react-i18next'
import i18n from 'src/applications/Embed/i18n'
import { Suspense } from 'react'

const history = createBrowserHistory()

if (env.POST_HOG_API_KEY && env.POST_HOG_HOST) {
  posthog.init(env.POST_HOG_API_KEY, { api_host: env.POST_HOG_HOST })
  posthog.capture('$pageview')
}
/**
 * See: https://sentry.io/organizations/flatfile/issues/
 */
if (SENTRY_CONFIG.enabled) {
  Sentry.init({
    debug: SENTRY_CONFIG.debug,
    dsn: SENTRY_CONFIG.dsn,
    environment: SENTRY_CONFIG.environment,
    release: env.COMMIT_REF || SENTRY_CONFIG.release,
    tracesSampleRate: parseFloat(env.SENTRY_TRACES_SAMPLE_RATE || '0.1'),
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['api.us.flatfile.io', 'api.flatfile.io'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        beforeNavigate: (context) => {
          return {
            ...context,
            name: trimTransactionName(location.pathname)
          }
        }
      })
    ]
  })
}

const [, session] = location.hash.match(/session=([^=&]+)/) ?? []
if (session) {
  updateClientLink(session)
}

applyFilter(
  localStorageOverride(ConfigVariableNames.FILTER_MODE, 'none', {
    options: ['none', 'dim', 'dark', 'gold', 'dimgold', 'monochrome', 'night']
  }) as EFilterMode
)

export const Oversight = withProfiler(
  () => (
    <>
      <TemporaryIOCOMRedirect />
      <Reset />
      <FilterModes />
      <ErrorContext>
        <ThemeProvider theme={DefaultTheme}>
          <WorkspaceChatContext>
            <ApolloProvider client={graphClient}>
              <ToastProvider placement='bottom-center' components={{ ToastContainer }}>
                <SessionManager>
                  <BillingPlanManager>
                    <I18nextProvider i18n={i18n}>
                      <Suspense fallback='Loading...'>
                        <Router history={history}>
                          <ScrollToTop />
                          <Analytics />
                          <ErrorCapture />
                          <ErrorDisplay />
                          <UserContext>
                            <ActiveTeamContext>
                              {routes()}
                              <DeveloperMenu />
                              <DeveloperAlerts />
                            </ActiveTeamContext>
                          </UserContext>
                        </Router>
                      </Suspense>
                    </I18nextProvider>
                  </BillingPlanManager>
                </SessionManager>
              </ToastProvider>
            </ApolloProvider>
          </WorkspaceChatContext>
        </ThemeProvider>
      </ErrorContext>
    </>
  ),
  { name: 'Oversight' }
)
