import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import {
  EditWorkspaceForm,
  IEditWorkspaceFormData
} from 'src/applications/Oversight/forms/EditWorkspaceForm'
import { TabAndContents } from 'src/applications/Oversight/hooks/useTabs'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { getWorkspaceDataRequestSchemas } from 'src/queries/GET_WORKSPACE_DATA_REQUEST_SCHEMAS'
import { IFormSubmitEvent } from 'src/resources/elements/form/Form'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { basicInfoSkeleton } from 'src/resources/skeletons/WorkspaceSettingsBasicInfoSkeleton'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_CREATE_DATA_REQUESTS } from 'src/smart/mutations/SM_CREATE_DATA_REQUESTS'
import { SM_CREATE_ORGANIZATION } from 'src/smart/mutations/SM_CREATE_ORGANIZATION'
import { SM_UPDATE_WORKSPACE } from 'src/smart/mutations/SM_UPDATE_WORKSPACE'
import { SQ_ORGANIZATIONS } from 'src/smart/queries/SQ_ORGANIZATIONS'
import { SQ_GET_WORKBOOK_SHEETS } from 'src/smart/queries/SQ_GET_WORKBOOK_SHEETS'
import { useFindDefaultOrganizationForWorkspace } from 'src/smart/queries/SQ_WORKSPACE_ORGANIZATION'
import { IOrganization } from 'src/types/interfaces/IOrganization'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'
import {
  BooleanSwitchInputContent,
  BooleanWrapper
} from 'src/resources/elements/form/BooleanInput'
import { SM_UPDATE_WORKSPACE_STATUS } from 'src/smart/mutations/SM_UPDATE_WORKSPACE_STATUS'
import { useConfirmWorkspaceUseModal } from 'src/applications/Oversight/hooks/useConfirmWorkspaceUseModal'

const WorkspaceSettingsFormWrapper = styled.div`
  padding-bottom: ${Spacing.basePadding4x};
`

const ArchiveBooleanWrapper = styled(BooleanWrapper)`
  margin-top: ${Spacing.contentPadding};
  margin-bottom: 0;
`

const BooleanContainer = styled.div`
  padding: 0 ${Spacing.contentPadding};
`

export const BasicInfoTab: TabAndContents<{
  teamId: string
  workspace: IWorkspace
  refetchWorkspace(): Promise<void>
}> = {
  id: 'basic',
  label: 'Basic info',
  contents({ teamId, workspace, refetchWorkspace }) {
    const [organizationId, setOrganizationId] = useState<string>()
    const [newOrganization, setNewOrganization] = useState<boolean>(false)
    const confirmWorkspaceUseModal = useConfirmWorkspaceUseModal({ refetch: refetchWorkspace })
    const { addToast } = useToasts()
    const environment = useContext(EnvironmentContext)
    const updateWorkspaceStatus = useSmartMutation(SM_UPDATE_WORKSPACE_STATUS)
    const updateWorkspace = useSmartMutation(SM_UPDATE_WORKSPACE)
    const createOrganization = useSmartMutation(SM_CREATE_ORGANIZATION)
    const createDataRequests = useSmartMutation(SM_CREATE_DATA_REQUESTS)

    const { refetch: refetchWorkbookSheets } = useSmartQuery(SQ_GET_WORKBOOK_SHEETS, {
      variables: {
        workspaceId: workspace.id
      }
    }).state

    const organizations = useSmartQuery(SQ_ORGANIZATIONS, {
      variables: {
        environmentId: environment?.id,
        skip: 0,
        take: 1000,
        teamId
      }
    })

    const defaultOrganization = useFindDefaultOrganizationForWorkspace(workspace.id)

    useEffect(() => {
      if (defaultOrganization.ready) {
        setOrganizationId(defaultOrganization.data.id)
      }
    }, [defaultOrganization, setOrganizationId])

    useEffect(() => {
      if (defaultOrganization.ready) {
        setOrganizationId(defaultOrganization.data.id)
      }
    }, [defaultOrganization, setOrganizationId])

    const {
      data: dataRequestSchemas,
      error: schemasError,
      refetch: refetchWorkspaceSchemas
    } = getWorkspaceDataRequestSchemas(workspace.id)

    const schemaIds: string[] = useMemo(
      () => dataRequestSchemas?.getWorkspaceDataRequestSchemas?.map((schema) => schema.id) ?? [],
      [dataRequestSchemas?.getWorkspaceDataRequestSchemas]
    )

    const submit = useCallback(
      async (event: IFormSubmitEvent<IEditWorkspaceFormData>) => {
        if (!event.data.name || event.data.name.trim().length === 0) {
          event.formContext.setValue({
            ...event.formContext.value,
            errors: { name: 'Please enter a workspace name' }
          })
          return
        }

        if (!event.data.organizationId || event.data.organizationId.trim().length === 0) {
          event.formContext.setValue({
            ...event.formContext.value,
            errors: { organizationId: 'Please select a collaborator' }
          })
          return
        }

        if (newOrganization) {
          const organization: IOrganization = organizations.result.find(
            (item) => item.name === organizationId
          )

          if (!organization) {
            await createOrganization.run({
              name: organizationId,
              teamId
            })
          }
          setNewOrganization(false)
        }

        if (event.data.name !== workspace.name) {
          await updateWorkspace.run({
            name: event.data.name,
            teamId,
            workspaceId: workspace.id
          })

          await refetchWorkspace()

          addToast(`Updated Workspace Name successfully`, {
            appearance: 'success',
            autoDismiss: true
          })
        }

        if (event.data.schemaIds.join(',') !== schemaIds.join(',')) {
          await createDataRequests.run({
            schemaIds: event.data.schemaIds,
            teamId,
            workspaceId: workspace.id
          })
          await refetchWorkspaceSchemas()
          await refetchWorkspace()
          await refetchWorkbookSheets()
          addToast('Updated Workspace Sheets successfully', {
            appearance: 'success',
            autoDismiss: true
          })
        }
      },
      [addToast, setNewOrganization, updateWorkspace]
    )

    const handleToggleChange = useCallback(async () => {
      if (workspace.status) {
        confirmWorkspaceUseModal.open()
      } else {
        await updateWorkspaceStatus.run({ workspaceId: workspace.id })
        await refetchWorkspace()
        addToast('Your workspace has been successfully deactivated.', {
          appearance: 'success',
          autoDismiss: true
        })
      }
    }, [workspace])

    const showSkeletons =
      !organizations.result ||
      !defaultOrganization.ready ||
      !dataRequestSchemas?.getWorkspaceDataRequestSchemas
    return showSkeletons ? (
      basicInfoSkeleton
    ) : (
      <>
        {confirmWorkspaceUseModal.render()}
        {(organizations.state?.error || schemasError) &&
          QueryAlert({
            error: organizations.state?.error ?? schemasError
          })}
        {!organizations.alert && dataRequestSchemas && defaultOrganization.ready && (
          <WorkspaceSettingsFormWrapper>
            <EditWorkspaceForm
              initialValue={{
                name: workspace.name,
                organizationId: defaultOrganization.data.name,
                schemaIds
              }}
              onSubmit={submit}
            />
            {environment.slug !== 'test' && workspace.dataEngine === 1 && (
              <BooleanContainer>
                <ArchiveBooleanWrapper>
                  <BooleanSwitchInputContent
                    isDisabled={false}
                    onClick={handleToggleChange}
                    label='Active Workspace'
                    helperText='(Inactive workspaces cannot receive or export data)'
                    value={!workspace.status}
                  />
                </ArchiveBooleanWrapper>
              </BooleanContainer>
            )}
          </WorkspaceSettingsFormWrapper>
        )}
      </>
    )
  }
}
