import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'

interface ICreatedAccessKeyFormData {
  accessKeyId: string
  secretAccessKey: string
  teamId: string
}

const CreatedAccessKeyFormElement: TForm<ICreatedAccessKeyFormData> = Form

export const multiLineInit = (data: ICreatedAccessKeyFormData) => `
npx flatfile init --team ${data.teamId} 
--key ${data.accessKeyId}  
--secret ${data.secretAccessKey}  
--environment test --name "flatfile-workbook"
`

export const CreatedAccessKeyForm = ({
  data,
  onSubmit
}: {
  data: ICreatedAccessKeyFormData
  onSubmit: (event: IFormSubmitEvent<ICreatedAccessKeyFormData>) => Promise<void>
}) => {
  const dataAndInit = { ...data, init: multiLineInit(data) }
  return (
    <CreatedAccessKeyFormElement onSubmit={onSubmit} initialValue={dataAndInit}>
      <Input autoSelect copyButton fullWidth readOnly label='Access key id' name='accessKeyId' />
      <Input
        autoSelect
        copyButton
        fullWidth
        readOnly
        label='Secret access key'
        helperText='The secret access key is displayed exactly once, please
      copy it before continuing.'
        name='secretAccessKey'
      />
      <Input
        autoSelect
        copyButton
        fullWidth
        readOnly
        label='Quick start command'
        helperText='Paste this command into your terminal to start configuring Flatfile.'
        name='init'
      />
      <ButtonGroup left>
        <FormButton primary submit>
          Continue
        </FormButton>
      </ButtonGroup>
    </CreatedAccessKeyFormElement>
  )
}
