import { MutationTuple, useMutation } from '@apollo/client'
import { CREATE_SCHEMA } from 'src/queries/CREATE_SCHEMA'
import { CreateSchema, CreateSchemaVariables } from 'src/queries/types/CreateSchema'

export const useCreateSchemaMutation = (): MutationTuple<CreateSchema, CreateSchemaVariables> => {
  const [createSchema, createSchemaStatus] = useMutation<CreateSchema, CreateSchemaVariables>(
    CREATE_SCHEMA
  )

  return [createSchema, createSchemaStatus]
}
