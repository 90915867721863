/* eslint-disable  */

export const Spacing = {
  basePadding: '8px',
  basePadding2x: '16px',
  basePadding2_5x: '20px',
  basePadding3x: '24px',
  basePadding3_5x: '28px',
  basePadding4x: '32px',
  basePadding5x: '40px',
  basePadding6x: '48px',
  basePadding7x: '56px',
  basePadding8x: '64px',
  basePadding9x: '72px',
  basePadding10x: '80px',
  container: '1280px',
  largeContainer: '1600px',
  gridOffset: '-8px',
  halfBasePadding: '4px',
  quarterBasePadding: '2px',
  threeQuarterBasePadding: '6px',
  baseAndHalfPadding: '12px',
  baseAndThreeQuarterPadding: '14px',
  contentPadding: '32px',
  windowPadding: '40px',
  inputPadding: '10px 11px',
  borderRadius: '4px',
  roundBorderRadius: '10px'
}
