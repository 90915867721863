import { gql } from '@apollo/client'
import {
  GetExistingMatches,
  GetExistingMatchesVariables,
  GetExistingMatches_getExistingMatches
} from 'src/smart/queries/types/GetExistingMatches'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_EXISTING_MATCHES: SmartQuery<
  GetExistingMatchesVariables,
  GetExistingMatches,
  GetExistingMatches_getExistingMatches[][]
> = {
  query: gql`
    query GetExistingMatches($sourceKeys: [String!]!, $targetKeys: [String!]!) {
      getExistingMatches(sourceKeys: $sourceKeys, targetKeys: $targetKeys) {
        count
        key
      }
    }
  `,
  extract(result) {
    return result.getExistingMatches
  }
}
