import {
  createContext as reactCreateContext,
  MutableRefObject,
  Provider as ReactProvider,
  ReactNode,
  useContext,
  useState
} from 'react'

import { named } from 'src/resources/utils/named'

import { useIsMounted } from './useIsMounted'

export interface IContext<TContext> {
  isMounted: MutableRefObject<boolean>
  value: TContext
  setValue(newValue: Partial<TContext>): void
}

/**
 * ContextState
 * ContextState is a 2-part solution to common problems
 * (1) the need to share state with child components many
 * levels below where the value is first available
 * and (2) to make the state editable from the child
 * component
 * The return value is a context provider element, and a
 * context getter hook function to be used in the
 * children components
 * Usage (i.e. src/contexts/MeContext.tsx):
 * (0) interface Me {
 *  myName: string
 * }
 * (1) export const [MeContext, useMeContext] =
 *  createContextState<Me>('Me', { myName: 'Foo' })
 * (2) import { MeContext } from 'src/contexts/MeContext'
 * return <MeContext><SeriousApp/></MeContext>
 * (3) import { useMeContext } from 'src/contexts/MeContext'
 * const me = useMeContext()
 * console.log(me.value) // to read
 * me.setValue({ myName: 'Bar' }) // to update
 * @param name string
 * @param defaultState initial state
 * @param onSetState get notified when the state changes
 * @returns [ MeContextProviderElement, useMeContext ]
 */
export const createContextState = function <TState>( // eslint-disable-line
  name: string,
  defaultState: TState,
  onSetState?: (value: TState) => void
): [
  ({ children, value }: { children: ReactNode; value?: TState }) => JSX.Element,
  () => IContext<TState>,
  ReactProvider<IContext<TState>>
] {
  const componentName = `${name}Context`
  const context = reactCreateContext<IContext<TState>>({
    isMounted: { current: false },
    setValue() {
      throw new Error(`<${componentName}> element must appear in the parents of this element`)
    },
    value: defaultState
  })

  const { Provider } = context

  const ContextElement = named(
    'ContextElement',
    ({ children, value: inputValue }: { children: ReactNode; value?: TState }) => {
      const [value, setFullValue] = useState<TState>(defaultState)
      const isMounted = useIsMounted()

      let newValue = { ...value, ...(inputValue ?? {}) }

      const setValue = (updates: Partial<TState>) => {
        if (isMounted.current) {
          newValue = { ...newValue, ...updates }

          setFullValue(newValue)

          if (typeof onSetState === 'function') {
            onSetState(newValue)
          }
        } else {
          console.warn(`setValue() on unmounted <${componentName}>`)
        }
      }

      return <Provider value={{ isMounted, value, setValue }}>{children}</Provider>
    }
  )

  const CE = ContextElement as any
  CE.displayName = componentName

  return [ContextElement, () => useContext<IContext<TState>>(context), Provider]
}
