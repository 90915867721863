import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import DarkBanner from 'src/resources/images/DarkBannerBackground@2x.png'
import LightBanner from 'src/resources/images/LightBannerBackground@2x.png'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

const BannerDiv = styled.div<{ lightBG?: boolean }>`
  position: relative;
  height: 100%;
  padding: ${Spacing.basePadding3x};
  display: flex;
  border-radius: 0.25rem;
  background-image: url(${({ lightBG }) => (lightBG ? LightBanner : DarkBanner)});
  ${({ lightBG }) =>
    lightBG
      ? css`
          background-color: rgb(60, 100, 221);
        `
      : css`
          background-color: #303346;
        `}

  background-repeat: round;

  vertical-align: middle;
  div p {
    color: white;
  }

  div h3 {
    color: white;
  }

  div h2 {
    color: white;
    font-size: ${fontSizes.type24};
    font-weight: 300;
    margin: ${Spacing.basePadding} 0;

    span {
      font-weight: 600;
    }
  }
`

const DemoButton = styled(FlatButton)`
  position: absolute;
  right: 40px;
`

const WelcomeContent = () => {
  return (
    <>
      <div>
        <p>Welcome to Concierge</p>
        <h3>World&mdash;class data import at the click of a button</h3>
      </div>
      <DemoButton
        renderAs='a'
        variant='outlined'
        color='white'
        href='https://flatfile.freshsales.io/web_forms/5d8c95dd4a301e400f40f3e49356b8734d8b1ece07193bc877ab799a057f2c8f/form.html'
      >
        Book a demo
      </DemoButton>
    </>
  )
}

const WaitlistContent = (name?: string) => {
  return (
    <>
      <div>
        <h2>
          Hi <span>{name}!</span>
        </h2>
        <h2>
          Welcome to <span>Concierge</span>
        </h2>
      </div>
    </>
  )
}

interface IWelcomeBanner {
  isWaitlist?: boolean
  name?: string
}

const WelcomeBanner = ({ isWaitlist, name }: IWelcomeBanner) => {
  return (
    <BannerDiv lightBG={isWaitlist}>
      {!isWaitlist ? WaitlistContent(name) : WelcomeContent()}
    </BannerDiv>
  )
}

export default WelcomeBanner
