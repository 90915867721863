import Color from 'color'

export const Colors = {
  actionHover: '#F0F4F9',
  alertText: '#f0706d',
  alertTextAlternate: '#FF0000',
  alertBackground: 'rgba(255, 0, 0, 0.1)',
  avatarGrey: '#8D9AA5',
  black: '#000000',
  blackLight: '#50535B',
  blue: '#3c64dd',
  blueDark: '#516b7f',
  blueLight: '#1b5eab',
  blueMuted: '#d7ebff',
  blueSelected: '#0266c5',
  border: '#CAD0DC',
  brandAccent: '#EE6F57',
  brandAccent2: '#ECEEFF',
  brandBackground: '#1B2831',
  brandBlue: '#5473EE',
  brandLightBlue: '#2D9CDB',
  brandDarkBlue: '#278ac2',
  brandPlaceholderText: '#5f6c79',
  brandPrimary: '#3b2fc9',
  brandPrimaryRGBABorder: 'rgba(59, 47, 201, 0.1)',
  brandPrimaryHighlight: '#d4ccff',
  brandPrimaryHover: '#002E70',
  brandPrimaryMidnight: '#330099',
  brandSecondary: '#4D79FF',
  brandSecondaryText: '#8896A6',
  brandSuccess: '#6DD400',
  brandText: '#50535B',
  brightBlue: '#1f87ff',
  codeText: '#DC322F',
  conciergeIconBackground: '#FDF0F0',
  dandelion: '#f5a623',
  dangerBGColor: '#FFE9E8',
  dangerBorderColor: '#f5c6cb',
  dangerColor: '#721c24',
  dangerStrong: '#E04B36',
  darkCodeBackground: '#073642',
  darkerGreen: '#00946A',
  darkGreen: '#51a262',
  darkBadge: '#374151',
  defaultText: '#2C2D30',
  earlyAccessBlue: '#e4ebfe',
  elemBorder: '#C6D0DD',
  elemLink: '#1B5EAB',
  electric050: '#ECEEFF',
  electric200: '#A1A8FF',
  electric600: '#808AFF',
  electric700: '#4C48EF',
  electric900: '#0C009D',
  gray100: '#082335',
  gray200: '#2E3440',
  gray20: '#F6FAFC',
  gray30: '#F0F4F9',
  gray40: '#EAEFF5',
  gray60: '#DBE5F0',
  gray80: '#163249',
  figmaGray80: '#4E5A65',
  gray: '#C6D0DD',
  blue100: '#009EE1',
  grayBG: '#DFE3E6',
  grayBorderBlur: 'rgba(198, 203, 212, 100)',
  grayDark: '#AAAAAA',
  grayDarkBackground: '#eff1f4',
  grayInputText: '#555658',
  grayLight: '#DDDDDD',
  grayLightWithCharacter: '#c9d3df',
  grayMuted: '#7F7F7F',
  grayRgba: 'rgba(80, 83, 91, 1)',
  graySecondaryNav: '#344149',
  grayText: '#7F8FA4',
  grayVeryLight: '#EEE',
  green: '#60B700',
  horizontalRuleGray: '#97A5B5',
  iconBlue: '#526C7E',
  iconGray: '#CAD0DC',
  lightCodeBackground: '#F7FAFC',
  lightYellow: '#FFFFF3',
  jade: '#6fd0a6',
  medGray: '#777',
  medGreen: '#26A65B',
  mediumText: '#838695',
  matchingButtonBlue: '#567B9C',
  matchingDarkerBlue: '#3F5C71',
  navy: '#303346',
  orange: '#FB6500',
  pigeon100: '#F6F8FC',
  pigeon200: '#E8EDF4',
  pigeon400: '#CAD0DC',
  pigeon600: '#8b93a4',
  pigeon700: '#616A7D',
  pigeon800: '#404857',
  pinata800: '#EE6F57',
  pinata600: '#FFA197',
  portalIconBackground: '#F0EBFF',
  purpleAlert: '#DDE0FF',
  purpleBlur: 'rgba(70, 60, 100, 0.2)',
  purpleHover: '#8C66FF',
  purpleLight: '#f3eeff',
  purpleMuted: '#4a4ead',
  purpleGray: '#C4C9FF',
  purpleTestResult: '#EBF0FF',
  lightRed: '#EEBBBE',
  red: '#d0021b',
  redDark: '#a01010',
  rowHover: '#FFF9F5',
  sky400: '#54A0EA',
  sky600: '#2473D1',
  selectHighlight: '#F6F8FC',
  selectHover: '#ECEEFF',
  successBGColor: '#e9f5ee',
  successStrong: '#199c4d',
  tableBorder: 'rgba(198, 208, 221, 1)',
  text80: '#AFBED0',
  text: '#1D2830',
  textLogo: '#090b2b',
  textAccent: '#3F5C71',
  tint: 'rgba(0, 0, 0, 0.1)',
  transparent: 'transparent',
  warningBGColor: '#fff5d9',
  warningDark: '#F5A623',
  warningLight: '#FFEBCA',
  warningStrong: '#da7519',
  white: '#fff',
  whiteBlur: 'rgba(255, 255, 255, 0.95)',
  whiteBlur2x: 'rgba(255, 255, 255, 0.8)',
  whiteDark: '#F7FAFC',
  whiteTable: '#eaeff6',
  whiteTranslucent: '#ffffffcf',
  yellow: '#f7b500',
  yellowBlur: 'rgba(247, 181, 0, 0.1)'
}

export const StateColors = {
  alert: Colors.orange,
  error: Colors.red,
  muted: Colors.grayMuted,
  success: Colors.green,
  warning: Colors.yellow,
  info: Colors.purpleAlert
}

export const ColorUtils = {
  hexToRgba: (hex: string, alpha?: number) => {
    const [r, g, b] = [1, 3, 5].map((i) => parseInt(hex.slice(i, i + 2), 16))
    if (Number.isFinite(alpha)) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`
    } else {
      return `rgb(${r}, ${g}, ${b})`
    }
  },
  hoverColor: (color: string) => {
    const base = Color(color)
    if (color === Colors.brandPrimary) {
      return Colors.brandPrimaryHover
    }
    if (base.isLight()) {
      return base.darken(0.075).hex()
    } else {
      return base.lighten(0.075).hex()
    }
  },
  rgbaToRgb: (rgba: string) => {
    //assumes white background
    const numbers = /\(([^)]*)\)/.exec(rgba)[1]
    const colorArray = numbers.split(',').map(Number)
    const alpha = colorArray[3]
    const r = colorArray[0] * alpha + 255 * (1 - alpha)
    const g = colorArray[1] * alpha + 255 * (1 - alpha)
    const b = colorArray[2] * alpha + 255 * (1 - alpha)
    return `rgb(${r}, ${g}, ${b})`
  }
}

// Todo: these are only used in the ResourceSdiebarItem so far - should we make these more global?
export const sidebarStatusColors: { [key: string]: string } = {
  active: `#6FD0A6`,
  failure: '#BE3900',
  changesPending: '#FFD152',
  inactive: Colors.iconGray
}
