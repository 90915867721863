import { gql } from '@apollo/client'
import {
  SmartEmbed,
  SmartEmbedVariables,
  SmartEmbed_getEmbed
} from 'src/smart/queries/types/SmartEmbed'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_EMBED: SmartQuery<
  SmartEmbedVariables,
  SmartEmbed,
  SmartEmbed_getEmbed & { testingToken: string }
> = {
  query: gql`
    query SmartEmbed($id: UUID!) {
      getEmbed(id: $id) {
        id
        name
        archived
        teamId
        privateKeyId
        legacyWebhookURL
        helpContent
        createdBy {
          id
          name
        }
        schemas {
          id
        }
        updatedAt
      }
      getEmbedTestingKey(id: $id) {
        token
      }
    }
  `,
  extract({ getEmbed, getEmbedTestingKey: { token } }) {
    return { ...getEmbed, testingToken: token }
  }
}
