import NotificationBadge from 'src/applications/Oversight/components/NotificationBadge'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import WorkspaceBadge from 'src/applications/Oversight/components/WorkspaceBadge'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { dasherize } from 'src/utils/string'

const sizes: { [key: string]: string } = {
  tile: '115px',
  sidebar: '150px',
  table: '300px'
}

const Wrap = styled.div<{ nameColor?: string; type?: string; onClick?(): void }>`
  ${({ nameColor }) =>
    nameColor
      ? css`
          color: ${nameColor};
        `
      : css`
          color: ${Colors.blackLight};
        `};
  align-items: center;
  display: flex;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${({ type }) => (type === 'sidebar' ? fontSizes.type19 : 'inherit')};
  font-weight: 500;
  overflow: hidden;

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : null}

  span:last-of-type {
    max-width: ${({ type }) => sizes[type]};

    ${({ type }) =>
      type === 'table'
        ? css`
            overflow: hidden;
            text-overflow: ellipsis;
          `
        : null}

    ${({ type }) =>
      type === 'tile' || type === 'sidebar'
        ? css`
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            line-height: 1.25;
            overflow: hidden;
            text-overflow: ellipsis;
          `
        : null}
  }
`

const BadgeWrap = styled.div<{ hasMargin?: boolean }>`
  margin-right: ${({ hasMargin }) => (hasMargin ? `${Spacing.basePadding}` : null)};
  position: relative;
`

const Container = styled.div<{ bgColor?: string }>`
  ${({ bgColor }) =>
    bgColor
      ? css`
          background-color: ${bgColor};
        `
      : css`
          background-color: ${Colors.brandPrimary};
        `}
  border-radius: 50%;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.type14};
  font-weight: bold;
  letter-spacing: 0.25px;
  height: ${Spacing.basePadding5x};
  width: ${Spacing.basePadding5x};
`

const NotificationContainer = styled.div`
  position: absolute;
  top: 0;
  right: 9px;
`

const SharedWorkspaceBadgeContainer = styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
`

const WorkspaceName = styled.span`
  padding: ${Spacing.basePadding} 0;
`

interface IWorkspaceAvatar {
  bgColor?: string
  sharedWorkspace?: string
  hasNotification?: boolean
  hasWhiteBG?: boolean
  nameColor?: string
  showFullName?: boolean
  big?: boolean
  type?: 'tile' | 'sidebar' | 'table'
  workspaceName: string
  onClick?(): void
}

const getAbbrev = ({ name }: { name: string }) => {
  return name?.charAt(0).toUpperCase() + name?.charAt(1).toLowerCase()
}

const WorkspaceAvatar = ({
  bgColor,
  hasNotification,
  hasWhiteBG,
  onClick,
  nameColor,
  sharedWorkspace,
  showFullName,
  workspaceName,
  type
}: IWorkspaceAvatar) => {
  const tooltipKey = dasherize(workspaceName)

  return (
    <Wrap data-testid='wrap' onClick={onClick} nameColor={nameColor} type={type}>
      <BadgeWrap hasMargin={showFullName}>
        {sharedWorkspace && (
          <SharedWorkspaceBadgeContainer>
            <WorkspaceBadge
              name={getAbbrev({ name: sharedWorkspace })}
              secondaryBorder={hasWhiteBG}
            />
          </SharedWorkspaceBadgeContainer>
        )}
        <Container bgColor={bgColor}>{getAbbrev({ name: workspaceName })}</Container>
        {hasNotification && (
          <NotificationContainer data-testid='notification'>
            <NotificationBadge secondaryBorder={hasWhiteBG} />
          </NotificationContainer>
        )}
      </BadgeWrap>
      {showFullName && (
        <OverflowData dataTip={workspaceName} dataFor={tooltipKey}>
          <WorkspaceName>
            <span>{workspaceName}</span>
            <Tooltip id={tooltipKey} content={workspaceName} />
          </WorkspaceName>
        </OverflowData>
      )}
    </Wrap>
  )
}

export default WorkspaceAvatar
