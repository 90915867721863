import { useEffect, useState } from 'react'

import { JSONSchema7Definition } from 'json-schema'
import { isEmpty, isEqual } from 'lodash'
import {
  FilterWithCount,
  IFilterWithCount
} from 'src/applications/Oversight/components/FilterWithCount'

export const useFilterWithCounts = (
  counts: IFilterWithCount['counts'],
  workbookLoading: boolean,
  errors: IFilterWithCount['errors'],
  schemaProperties: JSONSchema7Definition,
  showMerged: boolean,
  refreshingDataset: boolean
) => {
  const [finalCounts, setFinalCounts] = useState({} as IFilterWithCount['counts'])
  const finalCountsUpated = isEqual(finalCounts, counts)

  useEffect(() => {
    if (!finalCountsUpated && !workbookLoading) {
      setFinalCounts(counts)
    }
  }, [setFinalCounts, counts, finalCounts, workbookLoading])

  const finalCountPending = isEmpty(finalCounts) || (!isEmpty(counts) && !finalCountsUpated)

  return !workbookLoading ? (
    <FilterWithCount
      counts={counts}
      errors={errors}
      schemaProperties={schemaProperties}
      showMerged={showMerged}
      loading={workbookLoading || finalCountPending || refreshingDataset}
    />
  ) : null
}
