import { MutationTuple, useMutation } from '@apollo/client'
import { REMOVE_WORKSPACE_COLLABORATOR } from 'src/queries/REMOVE_WORKSPACE_COLLABORATOR'
import {
  RemoveWorkspaceCollaborator,
  RemoveWorkspaceCollaboratorVariables
} from 'src/queries/types/RemoveWorkspaceCollaborator'

export const useRemoveCollaboratorMutation = (): MutationTuple<
  RemoveWorkspaceCollaborator,
  RemoveWorkspaceCollaboratorVariables
> => {
  const [removeWorkspaceCollaborator, results] = useMutation<
    RemoveWorkspaceCollaborator,
    RemoveWorkspaceCollaboratorVariables
  >(REMOVE_WORKSPACE_COLLABORATOR)

  return [removeWorkspaceCollaborator, results]
}
