import { getUserContext } from 'src/contexts/UserContext'
import { QueryAlert } from 'src/resources/elements/QueryAlert'

export function DeveloperSuperAdmin({ children }: { children: React.ReactNode }) {
  const { user } = getUserContext().value
  if (!user?.id || !user?.superAdmin) {
    return QueryAlert({ error: 'Not authorized' })
  }
  return <>{children}</>
}
