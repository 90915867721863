import { useRef } from 'react'

interface Option {
  requires?: string[]
  value: string
}

export function useEnsureRequiredOptions<T extends readonly Option[]>(
  allOptions: T,
  initialOptions: T = [] as unknown as T
) {
  const previousSelection = useRef(initialOptions)

  return (selectedOptions: T): T => {
    const removedOptions = previousSelection.current.filter(
      (x) => !selectedOptions.find((y) => y.value === x.value)
    )
    const removedOptionValues = removedOptions.map((x) => x.value)
    const remainingSelectedOptions = selectedOptions.filter(
      (x) => !removedOptionValues.some((removedValue) => x.requires?.includes(removedValue))
    )
    const requiredOptions: string[] = remainingSelectedOptions
      .filter((x) => x.requires)
      .map((option) => option.requires ?? [])
      .reduce((prev, current) => prev.concat(current), [])
    const requiredNotInSelected = allOptions
      .filter((x) => requiredOptions.includes(x.value))
      .filter((y) => !remainingSelectedOptions.find((z) => z.value === y.value))
    const finalOptions = [...remainingSelectedOptions, ...requiredNotInSelected] as unknown as T
    previousSelection.current = finalOptions
    return finalOptions
  }
}
