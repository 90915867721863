import { FC, useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { BillingPlanStatusSwitch } from 'src/applications/Oversight/controls/BillingPlanStatusSwitch'
import { ActiveTeamRequired, getActiveTeamContext } from 'src/contexts/ActiveTeamContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { ITeamWithCapabilities, TeamContext } from 'src/contexts/TeamContext'
import { getUserContext } from 'src/contexts/UserContext'
import { GET_CURRENT_USER } from 'src/queries/GET_CURRENT_USER'
import {
  CurrentUser,
  CurrentUser_myCapabilities,
  CurrentUser_myTeams
} from 'src/queries/types/CurrentUser'
import { Message } from 'src/resources/elements/Message'
import { Spinner } from 'src/resources/elements/Spinner'
import { createContextState } from 'src/resources/hooks/createContextState'
import useReactRouter from 'use-react-router'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_FEATURE_FLAGS } from 'src/smart/queries/SQ_FEATURE_FLAGS'
import { useFeatureUpdatedSubscription } from 'src/smart/queries/SQ_FEATURE_UPDATED_SUBSCRIPTION'

const getOnPremCapabilities = (): Partial<CurrentUser_myCapabilities> => {
  const { ONPREM_LICENSE } = window as unknown as {
    ONPREM_LICENSE: { fields: { field: string; value: boolean }[] }
  }

  if (ONPREM_LICENSE?.fields) {
    const ConciergeWorkspacesField = ONPREM_LICENSE.fields.find(
      (x) => x.field === 'ConciergeWorkspaces'
    )

    if (ConciergeWorkspacesField?.value) {
      return { concierge: true }
    }

    return { portal: true }
  }
}

type IBasicInfoContext =
  | {
      data: { currentTeam: null; me: null; myTeams: null; myCapabilities: null }
      done: boolean
      error: boolean
      success: false
      refetch(): Promise<void>
    }
  | {
      data: CurrentUser
      done: true
      error: false
      success: true
      refetch(): Promise<void>
    }

const defaultBasicInfoData: {
  currentTeam: null
  me: null
  myTeams: null
  myCapabilities: null
} = {
  currentTeam: null,
  me: null,
  myTeams: null,
  myCapabilities: null
}

export const [BasicInfoContextProvider, getBasicInfoContext] =
  createContextState<IBasicInfoContext>('BasicInfo', {
    data: defaultBasicInfoData,
    done: false,
    error: false,
    success: false,
    refetch: async () => void 0
  })

let lastFeatureFlagLoadId = 0
let featureFlagLoadId = 0

export const BasicInfoLoader = ({ teamId }: { teamId?: string }): null => {
  const { result: featureFlags, state: featureFlagsQuery } = useSmartQuery(SQ_FEATURE_FLAGS, {
    variables: { teamId }
  })

  const featureUpdated = useFeatureUpdatedSubscription()

  useEffect(() => {
    if (featureUpdated) {
      featureFlagsQuery.refetch()
      featureFlagLoadId++
    }
  }, [featureUpdated])

  const basicInfoContext = getBasicInfoContext()
  const userContext = getUserContext()
  const activeTeamContext = getActiveTeamContext()
  const sessionContext = useContext(SessionContext)
  const { history } = useReactRouter()

  let setBasicInfoTimeout: number

  useEffect(
    () => () => {
      clearTimeout(setBasicInfoTimeout)
    },
    []
  )

  const { called, data, loading, error, refetch } = useQuery<CurrentUser>(GET_CURRENT_USER, {
    fetchPolicy: 'network-only'
  })
  const teamChanged =
    !activeTeamContext.value.team ||
    (teamId && activeTeamContext.value.team.id !== teamId) ||
    featureFlagLoadId !== lastFeatureFlagLoadId // new features need to be loaded

  useEffect(() => {
    if (teamId && data && !data.myTeams.some((candidate) => candidate.id === teamId)) {
      // user does not have access to this team, so let's redirect them silently
      history.push('/')
    }
  }, [data?.myTeams])

  useEffect(() => {
    if (
      !featureFlagsQuery.loading &&
      featureFlags &&
      basicInfoContext.value.success &&
      teamChanged &&
      data?.myTeams &&
      data.myTeams.length > 0
    ) {
      const findTeamById = (id: string | number) =>
        data.myTeams.find((candidate) => candidate.id === id.toString())

      let team: CurrentUser_myTeams

      if (data && teamChanged && teamId) {
        // if the user is switching teams and they're logged in, use team from url param
        // this is reliable because it only checks against the GetCurrentUser teams
        team = findTeamById(teamId)
      } else if (data?.currentTeam) {
        // if the GetCurrentUser query returns successfully, let's use that. this is
        // the most accurate because it comes from the backend.
        team = data?.currentTeam
      } else if (sessionContext.activeTeamId) {
        // if everything else is null so far, we can check localStorage for the last
        // teamId that was stored and try that
        team = findTeamById(sessionContext.activeTeamId)
      }

      // if after all of that there's still no team, return early cause they're not logged in
      if (!team) {
        return null
      }

      const teamCapabilities = data.myCapabilities.find((c) => c.teamId === parseInt(team.id, 10))

      const capabilities = {
        ...teamCapabilities,
        ...getOnPremCapabilities()
      }

      window.setTimeout(() => {
        lastFeatureFlagLoadId = featureFlagLoadId
        activeTeamContext.setValue({
          team,
          capabilities,
          featureFlags
        })
      })

      if (sessionContext.activeTeamId !== team.id) {
        sessionContext.setActiveTeamId(team.id)
      }
    }

    if (
      !basicInfoContext.value.success &&
      typeof data !== 'undefined' &&
      !error &&
      called &&
      !loading
    ) {
      setBasicInfoTimeout = window.setTimeout(() => {
        basicInfoContext.setValue({
          data,
          done: true,
          error: false,
          success: true,
          async refetch() {
            await refetch()
          }
        })
        userContext.setValue({ user: data.me })
      })
    } else if (
      !basicInfoContext.value.done &&
      (!!error || (typeof data === 'undefined' && !loading))
    ) {
      setBasicInfoTimeout = window.setTimeout(() => {
        basicInfoContext.setValue({
          data: defaultBasicInfoData,
          done: called && !loading,
          error: !!error,
          success: false,
          async refetch() {
            await refetch()
          }
        })
        userContext.setValue({ user: undefined })
      })
    }
  }, [
    basicInfoContext.value.success,
    teamChanged,
    data,
    error,
    called,
    loading,
    featureFlagLoadId
  ])

  return null
}

export const BasicInfoContext: FC<{
  teamId?: string
  onTeamFoundRender: (team: ITeamWithCapabilities, trialEnded?: boolean) => JSX.Element
  onTeamNotFoundRender?: () => JSX.Element
  onUserNotFoundRender?: () => JSX.Element
  onTrialEnded?: () => JSX.Element
}> = ({
  teamId,
  onTeamFoundRender,
  onTeamNotFoundRender = () => <Redirect to='/account-sign-up/personalize' />,
  onUserNotFoundRender,
  onTrialEnded
}) => {
  const {
    location: { pathname }
  } = useReactRouter()

  onUserNotFoundRender =
    onUserNotFoundRender ||
    (() => <Redirect to={`/login${pathname && pathname !== '/' ? `?redir=${pathname}` : ''}`} />)

  return (
    <BasicInfoContextProvider>
      <BasicInfoLoader teamId={teamId} />
      <ActiveTeamRequired>
        {(team) => (
          <BillingPlanStatusSwitch
            onLoading={() => (
              <Message center>
                <Spinner />
              </Message>
            )}
            onActivePlan={() => onTeamFoundRender(team)}
            onExpiredTrial={onTrialEnded}
          />
        )}
      </ActiveTeamRequired>
      <TeamNotFound>{onTeamNotFoundRender}</TeamNotFound>
      <UserNotFound>{onUserNotFoundRender}</UserNotFound>
    </BasicInfoContextProvider>
  )
}

export const UserNotFound = ({ children }: { children(): JSX.Element }) => {
  const {
    done,
    data: { me }
  } = getBasicInfoContext().value
  if (done && !me) {
    return children()
  }

  return null
}

export const TeamNotFound = ({ children }: { children(): JSX.Element }) => {
  const {
    done,
    data: { me, myTeams }
  } = getBasicInfoContext().value
  const team = useContext(TeamContext)
  if (done && me && !team && (!myTeams || myTeams.length === 0)) {
    return children()
  }

  return null
}

export enum ETeamType {
  full = 0,
  organizationOnly = 1
}
