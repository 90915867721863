import { Search } from 'history'
import queryString from 'query-string'

export const useCode = <TCode extends {}, TResult>(
  search: Search,
  inflate: (data: Partial<TCode>) => TResult
): TResult | undefined => {
  const query = queryString.parse(search)
  try {
    if (!query.code) {
      return inflate({})
    }
    const code = JSON.parse(atob(String(query.code)))
    return inflate(code as any as Partial<TCode>)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
