import Skeleton from 'react-loading-skeleton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { ClickableTR, NewStyledTable } from 'src/resources/elements/Table'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'
import { Spacing } from 'src/resources/layout'

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

const SearchContainer = styled.section`
  padding-left: ${Spacing.contentPadding};
`

const MainCont = styled.section`
  .secondary {
    margin-left: 15px;
    position: relative;
    top: 12px;
  }
`

export const WorkspaceSkeleton = named('WorkspaceSkeleton', () => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <MainCont>
        <PageHeaderContainer header='Workspaces'>
          <Skeleton width={156} height={35} />
        </PageHeaderContainer>

        <SearchContainer>
          <Skeleton width={400} height={35} />
        </SearchContainer>

        <StyledContainer>
          <NewStyledTable>
            <thead>
              <tr>
                <td>Workspace</td>
                <td>Organization</td>
                <td>Collaborators</td>
                <td align='right'>Last Activity</td>
              </tr>
            </thead>
            <tbody>
              <ClickableTR>
                <td>
                  <Skeleton width={40} height={40} circle={true} />
                  <Skeleton width={105} className='secondary' />
                </td>
                <td>
                  <Skeleton width={120} />
                </td>
                <td>
                  <Skeleton width={110} />
                </td>
                <td align='right'>
                  <Skeleton width={95} />
                </td>
              </ClickableTR>
            </tbody>
          </NewStyledTable>
        </StyledContainer>
      </MainCont>
    </>
  )
})
