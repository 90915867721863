import { AccessKeysPanel } from 'src/applications/Oversight/panels/AccessKeysPanel'
import { AccountInfoPanel } from 'src/applications/Oversight/panels/AccountInfoPanel'
import { ChangePasswordPanel } from 'src/applications/Oversight/panels/ChangePasswordPanel'
import { Card } from 'src/resources/elements/Card'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'

export const AccountScene = () => {
  const { isCollaborator } = useTeamRole()

  return (
    <Card>
      <PageHeaderContainer hasMargin header='Account settings' />
      <ContentContainer>
        <AccountInfoPanel />
        <ChangePasswordPanel />
        {!isCollaborator && <AccessKeysPanel />}
      </ContentContainer>
    </Card>
  )
}
