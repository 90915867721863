import Skeleton from 'react-loading-skeleton'
import { NewStyledTable } from 'src/resources/elements/Table'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

const TableContainer = styled.div``

export const WorkspaceFilesSkeleton = named('WorkspaceFilesSkeleton', () => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <TableContainer>
        <NewStyledTable>
          <thead>
            <tr>
              <td>File Name</td>
              <td align='center'>Status</td>
              <td>Uploaded By</td>
              <td align='center'>Sheet</td>
              <td align='center'>Upload Date</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={120} height={30} />
              </td>
              <td>
                <Skeleton width={130} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={125} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={115} height={20} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={120} height={30} />
              </td>
              <td>
                <Skeleton width={130} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={125} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={115} height={20} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={120} height={30} />
              </td>
              <td>
                <Skeleton width={130} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={125} height={20} />
              </td>
              <td align='center'>
                <Skeleton width={115} height={20} />
              </td>
            </tr>
          </tbody>
        </NewStyledTable>
      </TableContainer>
    </>
  )
})
