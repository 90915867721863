import { gql } from '@apollo/client'

export const GET_BATCHES = gql`
  query GetBatches(
    $take: Int!
    $skip: Int!
    $licenseKey: UUID
    $embedId: UUID
    $endUserId: UUID
    $environmentId: UUID
    $type: [Int!]
    $embedFilter: Int
    $search: String
    $teamId: ID
    $schemaId: ID
    $workspaceId: UUID
    $deleted: Boolean
  ) {
    getBatches(
      take: $take
      skip: $skip
      licenseKey: $licenseKey
      teamId: $teamId
      environmentId: $environmentId
      embedId: $embedId
      endUserId: $endUserId
      search: $search
      type: $type
      embedFilter: $embedFilter
      schemaId: $schemaId
      workspaceId: $workspaceId
      deleted: $deleted
    ) {
      pagination {
        currentPage
        limit
        nextOffset
        offset
        onPage
        pageCount
        previousOffset
        totalCount
      }
      data {
        countColumns
        countRows
        countRowsAccepted
        countRowsInvalid
        createdAt
        deleted
        failedAt
        filename
        originalFile
        isParsable
        headersMatched {
          index
          matched_key
          value
        }
        headersRaw {
          index
          value
        }
        settings {
          type
        }
        id
        managed
        manual
        memo
        source
        status
        submittedAt
        type
        workspaceId
        schema {
          id
          name
        }
        template {
          id
          name
        }
        embedId
        validatedIn
        endUser {
          id
          companyId
          companyName
          email
          name
          userId
        }
        uploads {
          id
          status
          createdAt
          errorInfo {
            message
          }
        }
        views {
          id
          status
          type
        }
      }
    }
  }
`
