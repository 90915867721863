import { useCallback, useState } from 'react'

import { Input } from 'src/resources/elements/form/Input'
import styled from 'styled-components'

const ColorPreview = styled.div`
  border-radius: 3px;
  height: 20px;
  left: 530px;
  position: relative;
  top: -30px;
  width: 20px;
`

export function ColorInput(props: {
  initialColor: any
  placeholder: string
  label: string
  name: string
}) {
  const [color, setColor] = useState(props.initialColor)
  const validateColor = useCallback(
    (colorValue: string) => {
      // clears colorValue
      if (!colorValue) {
        setColor(props.initialColor)
      }

      const withoutHex = colorValue
        .split('')
        .filter((x) => x !== '#')
        .join('')
      if (withoutHex.length === 3 || withoutHex.length === 6) {
        setColor('#' + withoutHex)
      } else {
        return
      }
    },
    [color, setColor]
  )
  return (
    <>
      <Input
        placeholder={props.placeholder}
        label={props.label}
        name={props.name}
        onBlur={validateColor}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            validateColor(event.currentTarget.value)
          }
        }}
      />
      <ColorPreview style={{ backgroundColor: color }} />
    </>
  )
}
