import { gql } from '@apollo/client'
import { useSubscription } from '@apollo/client'

import { FeatureUpdated } from './types/FeatureUpdated'

export const FEATURE_UPDATED_SUBSCRIPTION = gql`
  subscription FeatureUpdated {
    featureUpdated {
      archived
      enabled
      id
      key
      name
    }
  }
`

export function useFeatureUpdatedSubscription() {
  const { data } = useSubscription<FeatureUpdated, void>(FEATURE_UPDATED_SUBSCRIPTION)
  return data?.featureUpdated
}
