import { ReactElement, ReactNode } from 'react'

import { GetBatches_getBatches_pagination } from 'src/queries/types/GetBatches'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const PaddedResults = styled.div`
  margin-top: ${Spacing.basePadding};
  strong {
    font-weight: 600;
  }
`

type Pagination = GetBatches_getBatches_pagination

export const renderHeaderCount = ({ totalCount, offset, onPage }: Partial<Pagination>) => {
  if (totalCount <= 0) {
    return <PaddedResults>No records found</PaddedResults>
  }
  if (totalCount >= 1) {
    return (
      <PaddedResults>
        Showing{' '}
        <strong>
          {offset + 1}-{offset + onPage}
        </strong>{' '}
        of <strong>{totalCount.toLocaleString('en-US')}</strong>
      </PaddedResults>
    )
  }
}

export const PaginatedResultsHeader = ({
  children,
  pagination,
  hasMargin,
  header
}: {
  children?: ReactNode
  hasMargin?: boolean
  pagination?: IPartialPagination
  header: ReactElement | string
}) => {
  return (
    <>
      <PageHeaderContainer
        hasMargin={hasMargin}
        header={header}
        content={renderHeaderCount(pagination)}
      >
        {children}
      </PageHeaderContainer>
    </>
  )
}

interface IPartialPagination {
  totalCount: number
  offset: number
  onPage: number
}
