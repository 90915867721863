import { memo, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Colors } from '../colors'
import { Spacing } from '../layout'
import { fontSizes } from '../typography'

interface CustomPillStyle {
  color?: string
  background?: string
  fontSize?: string
  height?: string
  padding?: string
}

interface PillProps {
  children: ReactElement | string
  customStyle?: CustomPillStyle
}

export const PillStyle = styled.div<{ custom: CustomPillStyle }>`
  display: inline-block;
  font-weight: 600;
  line-height: 16px;
  border-radius: 14px;
  margin-left: ${Spacing.basePadding};
  font-size: ${fontSizes.type12};

  ${({ custom }) => {
    return css`
      color: ${custom?.color ?? Colors.brandPrimary};
      background: ${custom?.background ?? Colors.pigeon100};
      font-size: ${custom?.fontSize ?? fontSizes.type12};
      height: ${custom?.height ?? 'unset'};
      padding: ${custom?.padding ?? `${Spacing.quarterBasePadding} ${Spacing.baseAndHalfPadding}`};
    `
  }}
`

const Pill = ({ children, customStyle }: PillProps) => {
  return <PillStyle custom={customStyle}>{children}</PillStyle>
}

export default memo(Pill)
