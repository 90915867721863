import { FC, ReactSVG } from 'react'

import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css, CSSProperties } from 'styled-components'

import { getFormContext } from './Form'
import { IconToggleOff, IconToggleOn } from 'src/resources/elements/Icons'
import { Transitions } from 'src/resources/animations/transitions'

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const BooleanWrapper = styled.div`
  background-color: ${Colors.gray20};
  border-radius: 0.25rem;
  margin-bottom: ${Spacing.contentPadding};
  padding: ${Spacing.basePadding2x};
`

export const FlatBooleanInput = styled.div<{
  disabled?: boolean
  small?: boolean
  value: boolean
}>`
  background-color: ${Colors.pigeon400};
  padding: 2px;
  border-radius: ${Spacing.basePadding3x};
  width: 44px;
  margin-right: ${Spacing.baseAndHalfPadding};
  max-width: 100%;
  height: ${Spacing.basePadding3x};
  box-sizing: border-box;
  transition: ${Transitions.baseEase};
  outline: 1px solid transparent;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.white};
    border-radius: ${Spacing.basePadding2_5x};
    color: ${Colors.white};
    height: ${Spacing.basePadding2_5x};
    transition: transform 0.2s ease-in-out, background-color 0.1s ease;
    width: ${Spacing.basePadding2_5x};
  }

  &:hover {
    background-color: ${Colors.pigeon600};
  }

  ${({ value }) =>
    value
      ? css`
          background-color: ${Colors.brandPrimary};
          span {
            transform: translateX(100%);
            background-color: ${Colors.white};
          }
          &:hover {
            background-color: ${Colors.electric700};
          }
        `
      : undefined}

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          background-color: ${Colors.grayBG};
          border-color: transparent;
          color: ${Colors.grayDark};
        `
      : ''}

  ${({ small }) =>
    small
      ? css`
          border-radius: 16px;
          height: 16px;
          width: 28px;
          span {
            height: ${Spacing.baseAndHalfPadding};
            width: ${Spacing.baseAndHalfPadding};
          }
        `
      : ''}
`

export const BooleanInputLabel = styled.label<{ small?: boolean }>`
  display: inline-block;
  font-size: ${fontSizes.type14};
  font-weight: 500;
  pointer-events: none;
  color: ${Colors.text};
  ${({ small }) =>
    small
      ? css`
          font-size: ${fontSizes.type12};
          font-weight: normal;
          color: #090b2b;
        `
      : ''}

  span {
    color: ${Colors.figmaGray80};
    font-weight: 400;
    padding-left: ${Spacing.halfBasePadding};
  }
`

interface BooleanToggleProps {
  checked?: boolean
  checkedIcon?: ReactSVG | JSX.Element
  checkedText?: string
  disabled?: boolean
  label?: string
  name?: string
  small?: boolean
  style?: CSSProperties
  uncheckedText?: string
  value?: boolean
  onChange?: (newValue: boolean) => void
  onClick?: () => void
}

const withBooleanToggle = (WrappedComponent: any) => {
  const BooleanToggleComponent = (props: BooleanToggleProps) => {
    const formContext = getFormContext()

    const value =
      props.checked ||
      (props.name in formContext.value.data ? Boolean(formContext.value.data[props.name]) : false)

    const update = (updateValue: boolean) => {
      if (props.name) {
        const { data } = formContext.value
        formContext.setValue({ data: { ...data, [props.name]: updateValue } })
      }
      props.onChange?.(updateValue)
    }

    const isDisabled = props.disabled || formContext.value.submitting

    const onClick = (_e: any) => {
      isDisabled ? void 0 : update(!value)
    }
    return <WrappedComponent value={value} onClick={onClick} isDisabled={isDisabled} {...props} />
  }
  return BooleanToggleComponent
}

export const BooleanSwitchInputContent: FC<{
  label: string
  value: boolean
  onClick: any
  isDisabled: boolean
  helperText?: string
  small?: boolean
}> = ({ label, value, onClick, isDisabled, helperText, small, ...props }) => {
  return (
    <InputWrapper>
      <FlatBooleanInput
        tabIndex={0}
        disabled={isDisabled}
        onClick={onClick}
        value={value}
        small={small}
        {...props}
      >
        <span>{value ? <IconToggleOn /> : <IconToggleOff />}</span>
      </FlatBooleanInput>
      <BooleanInputLabel small={small}>
        {label}
        {helperText && <span>{helperText}</span>}
      </BooleanInputLabel>
    </InputWrapper>
  )
}

export const BooleanInput = withBooleanToggle(BooleanSwitchInputContent)

const BooleanTextInputWrapper = styled.span<{
  disabled?: boolean
  value: boolean
}>`
  display: flex;
  font-size: ${fontSizes.type14};
  font-weight: 500;
  color: ${(props) => (props.value ? Colors.blue : Colors.red)};
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.7;
        `
      : ''}
`

const IconWrap = styled.div`
  margin-right: ${Spacing.halfBasePadding};
`

export const BooleanTextInputContent: FC<{
  checkedIcon?: ReactSVG
  label: string
  value: boolean
  onClick: any
  isDisabled: boolean
  checkedText: string
  uncheckedText: string
}> = ({
  checkedIcon,
  label,
  checkedText,
  uncheckedText,
  value,
  onClick,
  isDisabled,
  ...props
}) => {
  return (
    <BooleanTextInputWrapper
      tabIndex={0}
      disabled={isDisabled}
      onClick={onClick}
      value={value}
      aria-label={label}
      {...props}
    >
      {value && checkedIcon && <IconWrap>{checkedIcon}</IconWrap>}
      {value ? checkedText : uncheckedText}
    </BooleanTextInputWrapper>
  )
}

export const BooleanTextInput = withBooleanToggle(BooleanTextInputContent)
