import { ApolloClient, ApolloLink, from, InMemoryCache, Operation, split } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { createHttpLink } from '@apollo/client/link/http'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/link-context'
import * as Sentry from '@sentry/react'
import { getMainDefinition } from 'apollo-utilities'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { FLATFILE_REFINERY_URL, FLATFILE_REFINERY_WS_URL } from 'src/config'
import { getErrorContext, IErrorType } from 'src/contexts/ErrorContext'
import { env } from 'src/env'
import { storage } from 'src/resources/utils/storage'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'

const graphqlUrl = `${FLATFILE_REFINERY_URL}/graphql`
const webSocketUrl = `${FLATFILE_REFINERY_WS_URL}/graphql`

const isEmbedded = location.pathname[1] === 'e'

export const accessTokenStorage = storage<string>(
  isEmbedded ? 'embeddedAccessToken' : 'accessToken'
)

export function accessTokenHeader() {
  return { Authorization: `Bearer ${accessTokenStorage.get()}` }
}

export const superAdminAccessTokenStorage = storage<string>('superAdminAccessToken')

let addErrors = (_errs: IErrorType[]): void => {
  // template
}

export const humanReadableGraphQLError = ({ graphQLErrors, networkError }: ErrorResponse) => {
  const errors: string[] = []
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      if (String(message) !== message) {
        message = (message as any).error || 'Unknown error'
      }
      errors.push(message)
    })
  }
  if (networkError) {
    if (!graphQLErrors || !graphQLErrors.length) {
      errors.push(networkError.message.toString())
    }
  }

  return errors.join(',')
}

/**
 * Borrowed and enhanced from https://github.com/Haegin/apollo-sentry-link
 */
const operationInfo = (operation: Operation) => ({
  type: (
    operation.query.definitions.find((defn) => 'operation' in defn) as OperationDefinitionNode
  ).operation,
  name: operation.operationName,
  fragments: (
    operation.query.definitions.filter(
      (defn) => defn.kind === 'FragmentDefinition'
    ) as FragmentDefinitionNode[]
  )
    .map((defn) => defn.name.value)
    .join(', ')
})

export const graphClient = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  name: 'oversight'
})

const ApolloSentryLink = new ApolloLink((operation, forward) => {
  if (env.NODE_ENV === 'production') {
    Sentry.addBreadcrumb({
      category: 'graphql',
      data: operationInfo(operation),
      level: Sentry.Severity.Debug
    })
  }
  return forward(operation)
})

const httpLink = createHttpLink({
  uri: graphqlUrl
})

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  const errors: IErrorType[] = []
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, message, locations, path }) => {
      const isHttpUnauthorized =
        extensions?.exception?.status === 401 || extensions?.response?.statusCode === 401

      if (!isHttpUnauthorized && message === 'Workspace access denied') {
        UserTransceiver.logout()
        window.location.replace('/request-access')
        return
      }

      if (
        isHttpUnauthorized &&
        UserTransceiver.hasToken() &&
        window.location.pathname.startsWith('/a/')
      ) {
        UserTransceiver.logout()
        // reload the page to clear memory
        window.location.replace(
          `/login?reason=expired&redir=${encodeURIComponent(location.pathname)}`
        )
        return
      }
      if (typeof message !== 'string') {
        message = (message as any)?.error || 'Unknown Error'
      }
      errors.push({ message, path })
      return console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }
  if (networkError) {
    if (!graphQLErrors || !graphQLErrors.length) {
      const message = networkError.message.toString()
      if (!message.includes('NetworkError')) {
        errors.push({ message })
      }
    }
    console.error(`[Network error]: ${networkError}`)
  }
  addErrors(errors)
})

const createWSLink = (token: string) =>
  new WebSocketLink({
    uri: webSocketUrl,
    options: {
      reconnect: true,
      connectionParams: () => {
        return {
          isWebSocket: true,
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      }
    }
  })

const createAuthLink = (token: string) => {
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  })) as unknown as ApolloLink
}

export const updateClientLink = (token: string) => {
  accessTokenStorage.set(token)
  const updatedLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    createWSLink(token),
    httpLink
  )

  graphClient.setLink(from([ApolloSentryLink, errorLink, createAuthLink(token), updatedLink]))
}
// set gql client link right away with current accessToken
updateClientLink(accessTokenStorage.get())

const GRAPHQL_IGNORE_ERROR_PHRASES = [
  'billing profile',
  'organization not found on workspace',
  'probably not a chargebee',
  'unauthorized'
]

export const ErrorCapture = (): null => {
  const {
    value: { errors },
    setValue
  } = getErrorContext()

  addErrors = (errs: IErrorType[]): void => {
    const filteredErrs = errs.filter(
      (err) =>
        !GRAPHQL_IGNORE_ERROR_PHRASES.some((phrase) =>
          (err.message ?? '').toLowerCase().includes(phrase)
        )
    )
    if (filteredErrs.length > 0) {
      setValue({ errors: [...errors, ...filteredErrs] })
    }
  }
  return null
}
