import { useEffect } from 'react'

import { CHARGEBEE_PUBLISHABLE_KEY, CHARGEBEE_SITE } from 'src/config'
import { createContextState } from 'src/resources/hooks/createContextState'
import { waitForCondition } from 'src/resources/utils/waitForCondition'

export interface IVendorChargebeeContext {
  chargebeeInstance: any
}

export const [VendorChargebeeContext, getVendorChargebeeContext] =
  createContextState<IVendorChargebeeContext>('Chargebee', {
    chargebeeInstance: null
  })

export const VendorChargebeeRequired = ({
  children,
  fallback
}: {
  children(chargebeeInstance: any): JSX.Element
  fallback?(): JSX.Element
}) => {
  const { chargebeeInstance } = getVendorChargebeeContext().value

  if (chargebeeInstance) {
    return children(chargebeeInstance)
  }

  return fallback ? fallback() : null
}

export const VendorChargebeeInit = (): null => {
  const { setValue } = getVendorChargebeeContext()

  const setupChargebee = async () => {
    const w = window as any
    await waitForCondition(() => w.Chargebee)

    return w.Chargebee.init({
      site: CHARGEBEE_SITE,
      publishableKey: CHARGEBEE_PUBLISHABLE_KEY
    })
  }
  useEffect(() => {
    setupChargebee().then((chargebeeInstance) => {
      setValue({ chargebeeInstance })
    })
  }, [])

  return null
}
