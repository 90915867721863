import { gql } from '@apollo/client'
import {
  UpdateEmbed,
  UpdateEmbedVariables,
  UpdateEmbed_updateEmbed
} from 'src/smart/mutations/types/UpdateEmbed'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_EMBED: SmartMutation<
  UpdateEmbedVariables,
  UpdateEmbed,
  UpdateEmbed_updateEmbed
> = {
  mutation: gql`
    mutation UpdateEmbed(
      $embedId: UUID!
      $name: String
      $archived: Boolean!
      $schemaIds: [ID!]!
      $legacyWebhookURL: String
      $helpContent: String
    ) {
      updateEmbed(
        embedId: $embedId
        name: $name
        archived: $archived
        schemaIds: $schemaIds
        legacyWebhookURL: $legacyWebhookURL
        helpContent: $helpContent
      ) {
        id
        archived
        name
        privateKeyId
        legacyWebhookURL
        helpContent
      }
    }
  `,
  extract: (response) => response.updateEmbed
}
