import Skeleton from 'react-loading-skeleton'
import { SchemaCard } from 'src/applications/Oversight/components/SchemaCard'
import { TableOverflow } from 'src/applications/Oversight/panels/ImportListDataPanel'
import { NewStyledTable } from 'src/resources/elements/Table'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

const Inline = styled.div.attrs(() => ({ tabIndex: 0 }))`
  display: flex;

  .sidebar {
    margin-left: 10px;
    position: relative;
    top: 10px;
  }
`

export const WorkspaceDataTemplatesSkeleton = named('WorkspaceDataTemplatesSkeleton', () => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <SchemaCard.Section>
        <TableOverflow fullHeight>
          <NewStyledTable>
            <thead>
              <tr>
                <td width='33%'>Name</td>
                <td align='center'>Files</td>
                <td>Created by</td>
                <td>Date created</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton width={85} height={20} className='mt-16' />
                </td>
                <td align='center'>
                  <Skeleton width={20} height={20} className='mt-16' />
                </td>
                <td>
                  <Inline>
                    <Skeleton circle={true} width={40} height={40} className='mt-16' />
                    <Skeleton width={100} height={20} className='mt-16 sidebar' />
                  </Inline>
                </td>
                <td>
                  <Skeleton width={100} height={20} className='mt-16' />
                </td>
              </tr>
            </tbody>
          </NewStyledTable>
        </TableOverflow>
      </SchemaCard.Section>
    </>
  )
})
