import { ellipsisFadeInOut } from 'src/resources/animations/ellipsisFadeInOut'
import styled from 'styled-components'

const EllipsisWrap = styled.span`
  margin-bottom: 0;
  span {
    display: inline-block;
    animation: ${ellipsisFadeInOut} 0.9s infinite;
    margin-bottom: 0 !important;
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
`

export const Ellipsis = () => {
  return (
    <EllipsisWrap>
      {' '}
      {Array.from({ length: 3 }).map((_, idx) => (
        <span className='ellipsis' key={idx}>
          .
        </span>
      ))}
    </EllipsisWrap>
  )
}
