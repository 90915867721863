import { ReactNode } from 'react'

import { PostAuthGenericTemplate } from 'src/applications/Oversight/templates/PostAuthGenericTemplate'
import { PreAuthTemplate } from 'src/applications/Oversight/templates/PreAuthTemplate'
import { getUserContext } from 'src/contexts/UserContext'

interface IAutoSelectAuthTemplate {
  children: ReactNode
}

export const AutoSelectAuthTemplate = ({ children }: IAutoSelectAuthTemplate) => {
  const {
    value: { user }
  } = getUserContext()

  if (!user) {
    return <PreAuthTemplate>{children}</PreAuthTemplate>
  }

  return <PostAuthGenericTemplate>{children}</PostAuthGenericTemplate>
}
