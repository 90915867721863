import { ReactNode } from 'react'

import { SessionRequired } from 'src/controls/SessionRequired'
import { SidebarLayout } from 'src/resources/layouts/SidebarLayout'

interface IPostAuthTemplate {
  children: ReactNode
}

export const PostAuthGenericTemplate = ({ children }: IPostAuthTemplate) => (
  <SessionRequired onUnauthenticatedUrl='/login'>
    {() => <SidebarLayout>{children}</SidebarLayout>}
  </SessionRequired>
)
