import { useCallback, useState } from 'react'
import { useModal } from 'src/resources/elements/Modal'
import styled from 'styled-components'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Spacing } from 'src/resources/layout'
import { Colors } from 'src/resources/colors'
import { fontSizes } from 'src/resources/typography'
import { useTranslation } from 'react-i18next'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.basePadding2_5x};
  color: ${Colors.pigeon700};
  font-weight: 400;
  font-size: ${fontSizes.type14};

  li {
    list-style-type: disc;
    font-weight: 600;
    margin-left: ${Spacing.basePadding2_5x};
  }
`

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${Spacing.basePadding};
`

export const useMappingWarningModal = (onClickContinue: () => void) => {
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const { t } = useTranslation()

  const warningModal = useModal({
    header: 'Heads Up!',
    zIndex: 16000,
    showCloseIcon: false,
    contents: () => (
      <Content>
        <p>{t('hooks.useMappingWarningModal.requiredFields')}</p>
        <ul>
          {requiredFields.map((field) => (
            <li key={field}>{field}</li>
          ))}
        </ul>
        <p>{t('hooks.useMappingWarningModal.manual')}</p>
        <p>{t('hooks.useMappingWarningModal.proceed')}</p>
        <ButtonGroup>
          <FlatButton onClick={onCancel} color='accent' variant='outlined'>
            {t('elements.buttons.cancel')}
          </FlatButton>
          <FlatButton onClick={onContinue}>{t('elements.buttons.continue')}</FlatButton>
        </ButtonGroup>
      </Content>
    )
  })

  const onContinue = useCallback(() => {
    onClickContinue()
    warningModal.close()
  }, [onClickContinue, warningModal])

  const onCancel = useCallback(() => {
    warningModal.close()
  }, [warningModal])

  return [
    () => warningModal.render(),
    (_requiredFields: string[]) => {
      setRequiredFields(_requiredFields)
      warningModal.open()
    }
  ] as [() => JSX.Element, (m: string[]) => void]
}
