import { CellChangeDetail } from '@turntable/core'

import { Primitive } from '../VirtualRecord'
import { Atom } from './Atom'

export class DefaultAtom extends Atom<Primitive> {
  public toCellValue() {
    return this.value
  }

  /**
   * Get the primitive value from from a changeset
   * @param change
   */
  public valueFromCellChangeDetail(change: CellChangeDetail<Primitive>) {
    return change.value
  }
}
