import { AccessKeyTag } from 'src/resources/elements/AccessKeyTag'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { IAccessKey } from 'src/types/interfaces/IAccessKey'

const InactivateAccessKeyFormElement: TForm<{}> = Form

export const InactivateAccessKeyForm = ({
  accessKey,
  onSubmit,
  onCancel
}: {
  accessKey: IAccessKey
  onSubmit: (event: IFormSubmitEvent<{}>) => Promise<void>
  onCancel: () => void
}) => (
  <InactivateAccessKeyFormElement onSubmit={onSubmit}>
    <p>You're about to deactivate the following access key:</p>

    <p>
      <AccessKeyTag accessKey={accessKey} />
    </p>

    <p>
      All sessions created using this access key will be immediately expired. Access keys cannot be
      reactivated once deactivated.
    </p>

    <ButtonGroup right>
      <FormButton secondary onClick={onCancel}>
        Cancel
      </FormButton>
      <FormButton danger submit>
        Deactivate
      </FormButton>
    </ButtonGroup>
  </InactivateAccessKeyFormElement>
)
