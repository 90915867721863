import { useState } from 'react'

interface IPageControls {
  prev(): void
  next(): void
}

export const usePages = (pages: ((controls: IPageControls) => JSX.Element)[]) => {
  const [page, setPage] = useState(0)

  const prev = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const next = () => {
    if (page < pages.length - 1) {
      setPage(page + 1)
    }
  }

  return pages[page]({ next, prev })
}
