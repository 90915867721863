import { HTMLAttributes, MouseEvent } from 'react'

import { fontAwesomeIconName } from 'src/resources/types/fontAwesomeIconName'

interface IIconProps {
  className?: string
  name: fontAwesomeIconName
  onClick?(e: MouseEvent): void
}

export const Icon = ({
  className = '',
  name,
  onClick,
  ...props
}: HTMLAttributes<HTMLSpanElement> & IIconProps) => (
  <i
    {...props}
    // eslint-disable-next-line react/forbid-component-props
    className={`icon fas fa-${name} ${className ?? ''}`}
    onClick={onClick}
  />
)
