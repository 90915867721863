import { gql } from '@apollo/client'
import { GetFeatureFlags, GetFeatureFlagsVariables } from 'src/smart/queries/types/GetFeatureFlags'
import { SmartQuery } from 'src/smart/SmartQuery'
import { FeatureFlags } from 'src/types/interfaces/FeatureFlags'

export const SQ_FEATURE_FLAGS: SmartQuery<
  GetFeatureFlagsVariables,
  GetFeatureFlags,
  Partial<FeatureFlags>
> = {
  query: gql`
    query GetFeatureFlags($teamId: ID) {
      getFeatureFlags(teamId: $teamId)
    }
  `,
  extract({ getFeatureFlags }) {
    const flags: Partial<FeatureFlags> = {}
    for (const key of getFeatureFlags) {
      flags[key as keyof FeatureFlags] = true
    }
    return flags
  }
}
