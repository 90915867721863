import { AutoSelectAuthTemplate } from 'src/applications/Oversight/templates/AutoSelectAuthTemplate'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Header } from 'src/resources/elements/Header'
import AccessDeniedImage from 'src/resources/images/404.svg'
import { Spacing } from 'src/resources/layout'
import { ContentArea } from 'src/resources/layouts/ContentArea'
import { openFrontChat } from 'src/resources/sidebars/PlatformSidebar'
import styled from 'styled-components'

const AccessDeniedContainer = styled.div`
  margin-top: ${Spacing.basePadding8x};
  text-align: center;

  small a {
    color: ${Colors.brandBlue};
    opacity: 1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p:last-child {
    margin-bottom: ${Spacing.basePadding3x};
  }

  img {
    margin-top: ${Spacing.basePadding6x};
  }
`

export const AccessDeniedPanel = () => (
  <ContentArea>
    <AccessDeniedContainer>
      <Header>
        <h2>Access denied</h2>
        <p>
          <small>
            If you are having trouble accessing your account,
            <br /> please <a onClick={openFrontChat}>let us know</a>
          </small>
        </p>
        <p>
          <small>You can log in to continue using Flatfile.</small>
        </p>
        <FlatButton renderAs='link' aria-label='Login to your account' to='/login'>
          Login
        </FlatButton>
      </Header>
      <img src={AccessDeniedImage} alt='401 Unauthorized' width='200px' />
    </AccessDeniedContainer>
  </ContentArea>
)

export const AccessDeniedScene = () => (
  <AutoSelectAuthTemplate>
    <AccessDeniedPanel />
  </AutoSelectAuthTemplate>
)
