import { ChangeEvent, useCallback, useState } from 'react'
import { Colors } from 'src/resources/colors'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { ErrorContainer } from 'src/resources/elements/form/ErrorMessage'
import { FlatInput } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_UPDATE_TEAM_FEATURE_FLAG } from 'src/smart/mutations/SM_UPDATE_TEAM_FEATURE_FLAG'
import { SQ_FEATURE_TEAMS } from 'src/smart/queries/SQ_FEATURE_TEAMS'
import { GetFeatureTeams_getFeatureTeams_data } from 'src/smart/queries/types/GetFeatureTeams'
import { IFeature } from 'src/types/interfaces/IFeature'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: ${Spacing.basePadding4x};
`

const DarkInput = styled(FlatInput)`
  background-color: ${Colors.blackLight};
`

const UnorderedList = styled.ul`
  padding: 0;
  padding-inline-start: 0;
  margin: 0;
`

const ListItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${Spacing.basePadding2x} 0 0;
  width: 360px;
  input {
    flex-shrink: 1;
    margin: 0;
  }
  ${ButtonBase}[color="success"] {
    height: ${Spacing.basePadding5x};
    margin-left: ${Spacing.basePadding};
  }
  ${ButtonBase}[color="danger"] {
    font-size: 24px;
    height: 28px;
    line-height: 24px;
    margin-left: 1em;
    opacity: 0;
    padding: 0 0 2px 0;
    text-align: center;
    transition: opacity 0.2s ease;
    width: 28px;
  }
  &:hover {
    ${ButtonBase}[color="danger"] {
      opacity: 1;
    }
  }
  p {
    margin: 0;
    padding: 10px;
  }
`

function FeatureTeam({
  feature,
  refreshList,
  team
}: {
  feature: IFeature
  refreshList: () => Promise<void>
  team: GetFeatureTeams_getFeatureTeams_data
}) {
  const removeMutation = useSmartMutation(SM_UPDATE_TEAM_FEATURE_FLAG)
  const removeTeamFeature = useCallback(async () => {
    await removeMutation.run({
      enabled: false,
      featureId: feature.id,
      teamId: parseInt(team.id, 10)
    })
    await refreshList()
  }, [])
  return (
    <ListItem>
      <p>
        {team.name} (#{team.id})
      </p>
      <FlatButton
        color='danger'
        title={`Remove "${feature.name}" from team "${team.name}"`}
        onClick={removeTeamFeature}
      >
        &times;
      </FlatButton>
    </ListItem>
  )
}

export function DeveloperFeatureTeams({ feature }: { feature: IFeature }) {
  const [addError, setAddError] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [newTeamId, setNewTeamId] = useState<string>('')
  const handleTeamIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewTeamId(e.currentTarget.value)
    },
    [setNewTeamId]
  )
  const featureTeams = useSmartQuery(SQ_FEATURE_TEAMS, {
    variables: {
      skip: 0,
      take: 1000,
      featureId: feature.id
    }
  })
  const refreshFeatures = useCallback(async () => {
    await featureTeams.state.refetch()
  }, [featureTeams.state.refetch])
  const addMutation = useSmartMutation(SM_UPDATE_TEAM_FEATURE_FLAG)
  const addTeamFeature = useCallback(async () => {
    const teamId = parseInt(newTeamId, 10)
    if (teamId.toString(10) !== newTeamId) {
      setAddError('Team id must be a positive integer')
      return
    }
    setAddError('')
    setIsAdding(true)
    setNewTeamId('')
    try {
      const result = await addMutation.run({
        enabled: true,
        featureId: feature.id,
        teamId: parseInt(newTeamId, 10)
      })
      if (!result.success) {
        setAddError(result.message ?? 'Error setting feature flag for team, please try again')
      }
      await refreshFeatures()
    } catch (e) {
      setAddError(e.message)
    } finally {
      setIsAdding(false)
    }
  }, [addMutation, feature, newTeamId, refreshFeatures, setAddError, setIsAdding, setNewTeamId])
  return (
    <Container>
      <h3>
        Teams with "{feature.name}" enabled: {featureTeams?.pagination?.totalCount}
      </h3>
      <p>
        Since this feature flag is globally {feature.enabled ? 'enabled' : 'disabled'},{' '}
        {feature.enabled ? (
          <>
            all teams have access to this feature, regardless of inclusion in this list. However,
            you may still want to edit these teams in case the feature is turned off globally in
            the future. Teams listed here will retain access to the feature, even if it is turned
            off globally.
          </>
        ) : (
          <>only teams in this list will have access to this feature.</>
        )}
      </p>
      <UnorderedList>
        <ListItem>
          <DarkInput
            disabled={isAdding}
            placeholder='Team id'
            onChange={handleTeamIdChange}
            value={newTeamId}
          />
          <FlatButton color='success' disabled={isAdding} onClick={addTeamFeature}>
            Add team
          </FlatButton>
        </ListItem>
        {addError ? (
          <ListItem>
            <ErrorContainer>{addError}</ErrorContainer>
          </ListItem>
        ) : null}
        {featureTeams.result?.data?.map((team) => (
          <FeatureTeam feature={feature} key={team.id} refreshList={refreshFeatures} team={team} />
        ))}
      </UnorderedList>
    </Container>
  )
}
