export type Ref<T> = {
  current?: T
}

export type Creator<T> = (self: Ref<T>) => T

export const ref = <T,>(creator: Creator<T>): T => {
  const self: Ref<T> = { current: undefined }
  const returnValue = creator(self)
  self.current = returnValue
  return returnValue
}
