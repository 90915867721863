import { gql } from '@apollo/client'

export const GET_SCHEMA = gql`
  query GetSchema($schemaId: ID!) {
    getSchema(schemaId: $schemaId) {
      archived
      id
      importCount
      embeds {
        id
        name
      }
      jsonSchema {
        schema
      }
      name
      createdAt
      updatedAt
      slug
      createdBy {
        name
      }
      previewFieldKey
      linkedSchemas {
        archived
        id
        name
        hasUniques
      }
    }
  }
`
