import { gql } from '@apollo/client'
import {
  SmartGetDataHooks,
  SmartGetDataHooksVariables,
  SmartGetDataHooks_getDataHooks
} from 'src/smart/queries/types/SmartGetDataHooks'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_DATAHOOKS: SmartQuery<
  SmartGetDataHooksVariables,
  SmartGetDataHooks,
  SmartGetDataHooks_getDataHooks[]
> = {
  query: gql`
    query SmartGetDataHooks($schemaId: ID!) {
      getDataHooks(schemaId: $schemaId) {
        deploymentState
        archived
        code
        createdAt
        description
        id
        lambdaARN
        name
        packageJSON
        schemaId
        version
        createdAt
        updatedAt
        ancestor {
          id
        }
        root {
          id
        }
      }
    }
  `,
  extract({ getDataHooks }) {
    return getDataHooks
  }
}
