import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  AcceptWorkspaceInvitationForm,
  IAcceptWorkspaceInvitationFormData
} from 'src/applications/Oversight/forms/AcceptWorkspaceInvitationForm'
import { BlankWorkspaceTemplate } from 'src/applications/Oversight/templates/BlankWorkspaceTemplate'
import { GET_WORKSPACE_INVITATION } from 'src/queries/GET_WORKSPACE_INVITATION'
import {
  GetWorkspaceInvitation,
  GetWorkspaceInvitationVariables
} from 'src/queries/types/GetWorkspaceInvitation'
import { IFormSubmitEvent } from 'src/resources/elements/form/Form'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { useSearch } from 'src/resources/hooks/useSearch'
import { TokenExpiredScene } from './TokenExpiredScene'
import { getUserContext } from 'src/contexts/UserContext'
import { useAcceptWorkspaceInvitationMutation } from 'src/queries/hooks/useAcceptWorkspaceInvitationMutation'
import { useHistory } from 'react-router-dom'
import { updateClientLink } from 'src/resources/clients/graphClient'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'

export const WorkspaceInvitationScene = () => {
  const { token } = useParams<{ ref: string; token: string }>()
  const searchVariables = useSearch<{ existingUser: string }, { existingUser: string }>(
    location.search,
    ({ existingUser }) => ({
      existingUser
    })
  )

  return <WorkspaceInvitation token={token} existingUser={searchVariables.existingUser} />
}

export const WorkspaceInvitation = ({
  token,
  existingUser
}: {
  token: string
  existingUser: string
}) => {
  const userContext = getUserContext()
  const [acceptInvitation] = useAcceptWorkspaceInvitationMutation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const teamRoot = useTeamRootUrl()

  const invitationQuery = useQuery<GetWorkspaceInvitation, GetWorkspaceInvitationVariables>(
    GET_WORKSPACE_INVITATION,
    {
      variables: { token },
      onError: ({ message }) => {
        if (message === `Workspace Invite with token ID ${token} not found!`) {
          const isUserSigned = UserTransceiver.hasToken()
          history.push(`/${isUserSigned ? 'login' : `${teamRoot}/workspaces`}?reason=invalid`)
          return
        }
      }
    }
  )

  const data = invitationQuery?.data?.getWorkspaceInvitation

  useEffect(() => {
    if (existingUser && data) {
      setLoading(true)
      acceptInvite({ token })
    }
  }, [existingUser, token, data])

  const acceptInvite = async (params: { token: string; password?: string; name?: string }) => {
    setLoading(true)
    acceptInvitation({ variables: { ...params, passwordConfirm: params.password } })
      .then((result) => {
        const {
          user,
          workspaceTeam: { workspace, team },
          environment,
          token: accessToken
        } = result.data.acceptWorkspaceInvitation

        userContext.setValue({ user })
        updateClientLink(accessToken)

        history.push(`/a/${team.id}/env/${environment.slug}/workspaces/${workspace.id}`)
      })
      .catch(({ message }) => {
        if (message === 'You already have access to this workspace') {
          history.push(`/`)
        }
      })
  }

  const onSubmitForm = useCallback(
    async (event: IFormSubmitEvent<IAcceptWorkspaceInvitationFormData>) => {
      const { password, accept, name } = event.data
      let formErrors = {}

      if (!password) {
        formErrors = {
          ...formErrors,
          password: 'Please enter a password.'
        }
      } else if (password.length < 8) {
        formErrors = {
          ...formErrors,
          password: 'Password must be at least 8 characters long.'
        }
      }

      if (!name) {
        formErrors = {
          ...formErrors,
          name: 'Please enter your name.'
        }
      }

      if (!accept) {
        formErrors = {
          ...formErrors,
          accept: 'You must accept the terms of service to continue.'
        }
      }

      if (Object.keys(formErrors).length > 0) {
        event.formContext.setValue({
          errors: formErrors
        })
        return
      }

      await acceptInvite({ token, password, name })
    },
    []
  )

  if (data?.expired) {
    return <TokenExpiredScene data={data} />
  }

  if (!loading && data) {
    return (
      <BlankWorkspaceTemplate>
        <AcceptWorkspaceInvitationForm data={data} onSubmit={onSubmitForm} />
      </BlankWorkspaceTemplate>
    )
  } else {
    return QueryAlert(
      { ...invitationQuery, loading: invitationQuery.loading || loading },
      <p>Error fetching invitation</p>,
      <p>Loading invitation</p>
    )
  }
}
