import { ReactNode } from 'react'

import { useModal } from 'src/resources/elements/Modal'

interface IBlankWorkspaceTemplate {
  children: ReactNode
}

export const BlankWorkspaceTemplate = ({ children }: IBlankWorkspaceTemplate) => {
  const modal = useModal({
    contents: () => {
      return <>{children}</>
    },
    initialIsOpenState: true
  })
  return <>{modal.render()}</>
}
