import { Vertical } from 'src/applications/Oversight/developer/DeveloperMenuCommon'
import { useStateInput } from 'src/applications/Oversight/hooks/useStateInput'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_EXISTING_MATCHES } from 'src/smart/queries/SQ_EXISTING_MATCHES'
import { GetExistingMatches_getExistingMatches } from 'src/smart/queries/types/GetExistingMatches'
import styled from 'styled-components'

const Padded = styled.div`
  padding: 0 ${Spacing.basePadding2x} ${Spacing.basePadding2x};
`

const FullHeight = styled.div`
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 1px;
`

const Horizontal = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
  }
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: ${Spacing.basePadding};
  }
`

const CountHeader = styled.th`
  min-width: 50px;
  width: 20%;
`

const OuterCell = styled.td`
  border: 1px solid white;
  text-align: left;
`

const TextArea = styled.textarea`
  height: 50vh;
  resize: vertical;
`

function splitByNewLine(x: string) {
  return x.trim().split('\n')
}

function newLinearIssueLink(title: string, description: string) {
  const team = 'FLA'
  return `https://linear.app/flatfile/team/${team}/new?title=${encodeURIComponent(
    title
  )}&description=${encodeURIComponent(description)}`
}

function MatchResults({
  matches,
  sourceKeys,
  targetKeys
}: {
  matches: GetExistingMatches_getExistingMatches[][]
  sourceKeys: string[]
  targetKeys: string[]
}) {
  const genericTargetMessage = `## What went wrong?\n\n- _type here_\n\nAvailable target keys were:\n${targetKeys
    .map((key) => `- ${key}`)
    .join('\n')}`

  return (
    <Table>
      <tr>
        <th>Source key</th>
        <th>Match detail</th>
      </tr>
      {sourceKeys.map((sourceKey, index) => {
        return (
          <tr key={index}>
            <OuterCell>{sourceKey}</OuterCell>
            <OuterCell>
              <Table>
                <tr>
                  <th>Key</th>
                  <CountHeader>Count</CountHeader>
                  <CountHeader>Actions</CountHeader>
                </tr>
                {matches[index].map((match, matchIndex) => (
                  <tr key={matchIndex}>
                    <td>{match.key}</td>
                    <td>{match.count}</td>
                    <td>
                      <a
                        target='_blank'
                        rel='noopener'
                        title='Create new Linear issue'
                        href={newLinearIssueLink(
                          `Match engine bad match: ${sourceKey} → ${match.key} (count: ${match.count})`,
                          genericTargetMessage
                        )}
                      >
                        🎟
                      </a>
                    </td>
                  </tr>
                ))}
                {matches[index].length === 0 && (
                  <tr>
                    <td colSpan={2}>No matches found</td>
                    <td>
                      <a
                        target='_blank'
                        rel='noopener'
                        title='Create new Linear issue'
                        href={newLinearIssueLink(
                          `Match engine found no match: ${sourceKey} → ?`,
                          genericTargetMessage
                        )}
                      >
                        🎟
                      </a>
                    </td>
                  </tr>
                )}
              </Table>
            </OuterCell>
          </tr>
        )
      })}
    </Table>
  )
}

export function DeveloperMenuToolMatching() {
  const sourceKeys = useStateInput(TextArea, '', splitByNewLine, 'source')
  const targetKeys = useStateInput(TextArea, '', splitByNewLine, 'target')
  const status = useSmartQuery(SQ_EXISTING_MATCHES, {
    variables: {
      sourceKeys: sourceKeys.transformed,
      targetKeys: targetKeys.transformed
    }
  })

  return (
    <Padded>
      <Horizontal>
        <Vertical>
          <label>Source columns</label>
          {sourceKeys.render()}
        </Vertical>
        <Vertical>
          <label>Target columns</label>
          {targetKeys.render()}
        </Vertical>
      </Horizontal>
      <FullHeight>
        <h3>Match results</h3>
        {status.alert ? (
          queryAlert(status)
        ) : (
          <MatchResults
            matches={status.result}
            sourceKeys={sourceKeys.transformed}
            targetKeys={targetKeys.transformed}
          />
        )}
      </FullHeight>
    </Padded>
  )
}
