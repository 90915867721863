import { FC, ReactNode } from 'react'

export const wrapWith =
  <T,>(Outer: FC<{ children: ReactNode }>, Inner: FC<T>) =>
  (props: T) =>
    (
      <Outer>
        <Inner {...props} />
      </Outer>
    )
