import { HTMLAttributes, ReactNode, useCallback } from 'react'

import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatButtonColor, FlatButtonFontWeight } from 'src/resources/elements/buttons/IFlatButton'
import styled from 'styled-components'

import { getFormContext, IFormContext, IFormSubmitEvent } from './Form'

export const FlatButtonContainer = styled.div``

interface IFormButtonProps<TFormData extends Record<string, any>>
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'onSubmit'> {
  buttonSibling?: ReactNode
  children: ReactNode
  color?: FlatButtonColor
  danger?: boolean
  disabled?: boolean
  primary?: boolean
  reset?: boolean
  secondary?: boolean
  submit?: boolean
  tabIndex?: number
  fontWeight?: FlatButtonFontWeight
  onSubmit?(event: Pick<IFormSubmitEvent<TFormData>, 'data' | 'formContext'>): void
}

export type TFormButton<TFormData> = (props: IFormButtonProps<TFormData>) => JSX.Element

export const FormButton: TFormButton<any> = <TFormData extends Record<string, any>>({
  buttonSibling,
  children,
  color, // takes precedence over primary/secondary/danger
  danger,
  disabled,
  onSubmit,
  primary,
  reset,
  secondary,
  submit,
  tabIndex,
  fontWeight,
  ...props
}: IFormButtonProps<TFormData>) => {
  const formContext = getFormContext()
  const isFormControl = reset || submit
  const dataUnchanged =
    formContext.value.data === formContext.value.initialData ||
    (Object.keys(formContext.value.data).length === 0 && !formContext.value.initialData)
  const formControlDisabled =
    isFormControl && dataUnchanged && formContext.value.disableIfUnchanged
  const click = useCallback(() => {
    if (submit && onSubmit) {
      onSubmit({
        data: formContext.value.data as TFormData,
        formContext: formContext as IFormContext<TFormData>
      })
    }
  }, [submit, onSubmit, formContext])
  return (
    <FlatButtonContainer>
      <FlatButton
        renderAs='button'
        disabled={disabled || formContext.value.submitting || formControlDisabled}
        tabIndex={tabIndex || 0}
        type={submit ? 'submit' : reset ? 'reset' : 'button'}
        onClick={click}
        {...props}
        color={color ?? (secondary ? 'secondary' : danger ? 'danger' : 'primary')}
        fontWeight={fontWeight}
      >
        {children}
      </FlatButton>
      {buttonSibling}
    </FlatButtonContainer>
  )
}
