import { useMutation } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import { getUserContext } from 'src/contexts/UserContext'
import {
  UpdateUserPassword,
  UpdateUserPasswordVariables
} from 'src/queries/types/UpdateUserPassword'
import { UPDATE_USER_PASSWORD } from 'src/queries/UPDATE_USER_PASSWORD'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { ContentHeader } from 'src/resources/elements/Header'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'

export const ChangePasswordPanel = () => {
  const { addToast } = useToasts()

  const { user } = getUserContext().value

  const [updateUserPassword] = useMutation<UpdateUserPassword, UpdateUserPasswordVariables>(
    UPDATE_USER_PASSWORD
  )

  const ResetPasswordForm: TForm<{
    currentPassword: string
    password: string
    passwordConfirm: string
  }> = Form

  return (
    <ResetPasswordForm
      onSubmit={async (event) => {
        const { currentPassword, password, passwordConfirm } = event.data

        if (!currentPassword) {
          event.formContext.setValue({
            errors: { currentPassword: 'Please enter your current password.' }
          })
          return
        }

        if (!password) {
          event.formContext.setValue({
            errors: { password: 'Please enter a new password.' }
          })
          return
        } else if (password.length < 8) {
          event.formContext.setValue({
            errors: { password: 'Password must be at least 8 characters long.' }
          })
          return
        } else if (password === currentPassword) {
          event.formContext.setValue({
            errors: {
              password: 'Choose a password that is different from your current password.'
            }
          })
          return
        }

        if (!passwordConfirm) {
          event.formContext.setValue({
            errors: {
              passwordConfirm: 'Please confirm your new password.'
            }
          })
          return
        } else if (passwordConfirm !== password) {
          event.formContext.setValue({
            errors: {
              passwordConfirm: 'Confirm password does not match new password.'
            }
          })
          return
        }

        try {
          const response = await updateUserPassword({
            variables: {
              currentPassword,
              id: user.id,
              password,
              passwordConfirm
            }
          })

          if (
            response.data &&
            response.data.updateUserPassword &&
            response.data.updateUserPassword.id
          ) {
            event.formContext.setValue({
              data: { currentPassword: '', passwordConfirm: '', password: '' }
            })
            addToast(`Password changed successfully!`, {
              appearance: 'success',
              autoDismiss: true
            })
          }
        } catch (e) {
          const message =
            e.graphQLErrors && e.graphQLErrors.length
              ? e.graphQLErrors.map((x: { message: string }) => x.message).join(' ')
              : 'An unknown error occurred, please try again later'
          event.formContext.setValue({
            errors: { currentPassword: message }
          })
        }
      }}
    >
      <SubContentContainer>
        <ContentHeader header='Reset your password' />
        <Input label='Current Password' name='currentPassword' placeholder='' type='password' />
        <ErrorMessage name='currentPassword' />
        <Input label='New Password' name='password' placeholder='' type='password' />
        <ErrorMessage name='password' />
        <Input
          label='New Password (confirm)'
          name='passwordConfirm'
          placeholder=''
          type='password'
        />
        <ErrorMessage name='passwordConfirm' />
        <ButtonGroup left>
          <FormButton submit>Reset password</FormButton>
        </ButtonGroup>
      </SubContentContainer>
    </ResetPasswordForm>
  )
}
