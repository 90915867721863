import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { useModal } from 'src/resources/elements/Modal'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const modalBackdropStyle = {
  opacity: 0.5
}

const WelcomeHeadline = {
  fontFamily: fontFamily.fontPrimary,
  fontWeight: 500,
  fontSize: fontSizes.type32,
  justifyContent: 'center',
  color: Colors.text
}

const WelcomeWrapper = styled.div`
  text-align: center;

  p {
    padding: 30px;
    font-size: ${fontSizes.type20};
  }
`

export const useCreateWelcomeModal = ({
  headline,
  copy
}: {
  headline?: string
  copy?: string
}) => {
  const createWelcomeModal = useModal({
    header: headline,
    headerStyle: WelcomeHeadline,
    backdropStyle: modalBackdropStyle,
    width: '800px',
    confetti: true,
    welcome: true,
    contents: () => <WelcomeContents onCancel={createWelcomeModal.close} copy={copy} />,
    onClose: () => {
      window.history.replaceState(null, null, window.location.pathname)
      return true
    }
  })
  return {
    open: createWelcomeModal.open,
    render: createWelcomeModal.render
  }
}

const WelcomeContents = ({ copy, onCancel }: { copy?: string; onCancel?: () => void }) => {
  return (
    <WelcomeWrapper>
      <p>{copy}</p>
      <FlatButton size='lg' renderAs='button' onClick={onCancel}>
        Get Started
      </FlatButton>
    </WelcomeWrapper>
  )
}
