import { useCode } from 'src/resources/hooks/useCode'
import { storage } from 'src/resources/utils/storage'
import { CreateTeamMetaDto } from '../../../../../types/globalTypes'

export interface SignUpData {
  companyName: string
  companySize: string
  email: string
  fullName: string
  meta: CreateTeamMetaDto
  role: string
  plan: string
}

export const useSignUpCode = (search: string, defaults: Partial<SignUpData> = {}) =>
  useCode<
    {
      cn: string
      cs: string
      e: string
      m: CreateTeamMetaDto
      n: string
      r: string
      p: string
    },
    Partial<SignUpData>
  >(search, ({ cn, cs, e, m, n, r, p }) => {
    const signUpData = {
      ...defaults,
      companyName: cn,
      companySize: cs,
      email: e,
      fullName: n,
      meta: m,
      role: r,
      plan: p
    }

    if (signUpData.email) {
      storage('signUpData').set(signUpData)
      return signUpData
    }

    try {
      return storage('signUpData').get() ?? signUpData
    } catch (error) {
      return signUpData
    }
  })
