import { Link } from 'react-router-dom'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { Colors } from 'src/resources/colors'
import BreadcrumbArrow from 'src/resources/icons/BreadcrumbArrow.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const BreadcrumbContent = styled.span``

export const Breadcrumb = styled.span<{ current?: boolean }>`
  color: ${Colors.textAccent};
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type14};
  font-weight: 600;

  span {
    > span {
      opacity: ${({ current }) => (current ? '1' : '0.6')};
    }
  }

  span,
  a {
    display: inline-block;
    vertical-align: middle;
  }
  a {
    color: inherit;
    transition: color 0.12s ease-out;
    &:hover {
      color: ${Colors.brandPrimary};
    }
  }
  & + & {
    margin-left: ${Spacing.basePadding4x};
    position: relative;
    &::after {
      background-image: url(${BreadcrumbArrow});
      background-position: center;
      background-repeat: no-repeat;
      background-size: ${Spacing.basePadding2x} ${Spacing.basePadding2x};
      bottom: 0;
      content: '';
      left: -24px;
      position: absolute;
      top: 2px;
      width: ${Spacing.basePadding2x};
    }
  }

  ${BreadcrumbContent} {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-inline-box;
    line-height: 1.4;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`

const BreadcrumbContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${Spacing.basePadding3x};
`

export interface IBreadcrumb {
  current?: boolean
  label: string
  to?: string
}

export const Breadcrumbs = ({ links }: { links: IBreadcrumb[] }) => {
  return (
    <BreadcrumbContainer data-testid='breadcrumbs-container'>
      {links &&
        links.map((link: IBreadcrumb, index: number) => {
          return (
            <Breadcrumb key={index} current={link.current}>
              {!link.current ? (
                <Link to={link.to}>
                  <OverflowData dataTip={link.label} offset={{ top: -8, left: 10 }} maxWidth={250}>
                    <BreadcrumbContent>{link.label}</BreadcrumbContent>
                  </OverflowData>
                </Link>
              ) : (
                <OverflowData dataTip={link.label} offset={{ top: -8, left: 10 }} maxWidth={300}>
                  <BreadcrumbContent>{link.label}</BreadcrumbContent>
                </OverflowData>
              )}
            </Breadcrumb>
          )
        })}
    </BreadcrumbContainer>
  )
}
