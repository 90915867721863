import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'

import { SelectHeaderRow, SelectHeaderRowVariables } from './types/SelectHeaderRow'

export const SM_SELECT_HEADER_ROW: SmartMutation<
  SelectHeaderRowVariables,
  SelectHeaderRow,
  { viewId: number }
> = {
  mutation: gql`
    mutation SelectHeaderRow(
      $dataSourceIndex: Int!
      $headerRowIndex: Int!
      $schemaId: Int!
      $uploadId: UUID!
    ) {
      selectHeaderRow(
        dataSourceIndex: $dataSourceIndex
        headerRowIndex: $headerRowIndex
        schemaId: $schemaId
        uploadId: $uploadId
      ) {
        viewId
      }
    }
  `,
  extract: (response) => ({ viewId: response.selectHeaderRow.viewId })
}
