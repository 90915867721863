import { fadeIn } from 'src/resources/animations/fadeIn'
import { fadeZoomOut } from 'src/resources/animations/fadeZoomOut'
import { time5 } from 'src/resources/durations'
import { Fill } from 'src/resources/layouts/Fill'
import styled from 'styled-components'

export const Fade = styled(Fill)<{ entering: boolean }>`
  transform: translate(0, 0);
  animation: ${({ entering }) => (entering ? fadeIn : fadeZoomOut)} ${time5} ease;
`
