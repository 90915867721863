import { SelectOption } from 'src/resources/elements/form/Select'
import { ISchemaStub } from 'src/types/interfaces/ISchema'

export const sortAndMapSchemas = (
  schemas: ISchemaStub[],
  isDisabled: (schema: ISchemaStub) => boolean = () => false,
  disabledMessage?: string
): SelectOption[] =>
  schemas
    .map((schema: ISchemaStub) => {
      const disabled = isDisabled(schema)
      const nameAndId = `${schema.name} (#${schema.id})`
      return {
        disabled,
        label: disabled && disabledMessage ? `${nameAndId} ${disabledMessage}` : nameAndId,
        requires: schema.linkedSchemas?.map((x) => x.id) ?? [],
        value: schema.id
      }
    })
    .sort((_a, b) => (b.disabled ? -1 : 1))
