import { useCallback, useContext } from 'react'

import { useCreateWorkspaceModal } from 'src/applications/Oversight/hooks/useCreateWorkspaceModal'
import { useStoredState } from 'src/applications/Oversight/hooks/useStoredState'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors, StateColors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { CloseIcon, LogoIcon } from 'src/resources/elements/Icons'
import { CloseModal } from 'src/resources/elements/Modal'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { openFrontChat } from 'src/resources/sidebars/WorkspaceSidebar'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { EDataEngineType } from 'src/types/enums/EDataEngineTypes'
import { ETeamUserRole } from 'src/types/interfaces/ITeam'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  background-color: ${StateColors.info};
  border-radius: 4px;
  ${Shadows.cardBg};
  color: ${Colors.brandPrimary};
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type14};
  line-height: 1.5;
  justify-content: center;
  padding: ${Spacing.basePadding2x};
  margin: 0 0 ${Spacing.basePadding2x};
`

const Message = styled.div`
  flex-grow: 1;
  margin-bottom: ${Spacing.basePadding2x};

  h3 {
    font-size: ${fontSizes.type16};
    margin: 0 0 ${Spacing.basePadding};
  }
`

const SupportLink = styled.a`
  text-decoration: underline;
  font-weight: 500;

  :hover {
    cursor: pointer;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledLogoIcon = styled(LogoIcon)`
  margin-right: 2px;
`

export const UpgradeToDALBanner = ({ workspace }: { workspace: IWorkspace }) => {
  const createWorkspaceModal = useCreateWorkspaceModal()
  const team = useContext(TeamContext)
  const usesLegacy = workspace.dataEngine === EDataEngineType.legacy
  const [dismissed, setDismissed] = useStoredState(
    `UpgradeToDALBanner.${workspace.id}.dismissed`,
    false
  )
  const dismissBanner = useCallback(() => setDismissed(true), [setDismissed])

  if (team.features.v3 && usesLegacy && team.role === ETeamUserRole.owner && !dismissed) {
    return (
      <Wrapper>
        {createWorkspaceModal.render()}
        <CloseModal onClick={dismissBanner}>
          <CloseIcon />
        </CloseModal>
        <Message>
          <h3>
            <StyledLogoIcon /> Data Processing has improved
          </h3>
          <b>We’ve made significant improvements to how data is processed. </b> Create a new
          workspace for increased performance and new features. <br /> Note: You can continue using
          this workspace safely, but if you experience issues, contact support to upgrade your
          legacy workspaces.
        </Message>
        <ActionWrapper>
          <div>
            Experiencing issues?{' '}
            <SupportLink onClick={openFrontChat}>Contact Support.</SupportLink>
          </div>
          <FlatButton onClick={createWorkspaceModal.open}>
            Create new workspace to upgrade
          </FlatButton>
        </ActionWrapper>
      </Wrapper>
    )
  }
  return null
}
