import { gql } from '@apollo/client'
import {
  UpdateEnvironment,
  UpdateEnvironmentVariables,
  UpdateEnvironment_updateEnvironment
} from 'src/smart/mutations/types/UpdateEnvironment'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_ENVIRONMENT: SmartMutation<
  UpdateEnvironmentVariables,
  UpdateEnvironment,
  UpdateEnvironment_updateEnvironment
> = {
  mutation: gql`
    mutation UpdateEnvironment($input: EnvironmentDto!) {
      updateEnvironment(input: $input) {
        webhook {
          subscribedEvents
          url
        }
      }
    }
  `,
  extract: (response) => response.updateEnvironment
}
