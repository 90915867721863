export function isValidStringEnumValue<T>(
  enumDefinition: Record<keyof T, string>,
  value: string | T
): value is T {
  return Object.values(enumDefinition).includes(value as string)
}

export function enumFromString<T>(enumDefinition: any, value: string | T): T | undefined {
  const valid = isValidStringEnumValue<T>(enumDefinition, value)
  return valid ? value : undefined
}
