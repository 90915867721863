import { useContext, useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import { CreatedEmbedPrivateKeyContext } from 'src/contexts/CreatedEmbedPrivateKeyContext'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { CreateEmbedForm } from 'src/forms/CreateEmbedForm'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { useModal } from 'src/resources/elements/Modal'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_CREATE_EMBED } from 'src/smart/mutations/SM_CREATE_EMBED'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

import { useGetSchemas } from './useGetSchemas'
import { useTeamRootUrl } from './useTeamRootUrl'

const EmptySchemasText = styled.p`
  margin: ${Spacing.contentPadding} 0;
`

export const useCreateEmbedModal = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { setCreatedEmbedPrivateKey } = useContext(CreatedEmbedPrivateKeyContext)
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  const createEmbed = useSmartMutation(SM_CREATE_EMBED)
  const schemaQuery = useGetSchemas({ archived: false }, { fetchPolicy: 'network-only' })

  const NoSchemasContent = () => (
    /*
      Creating an embed is dependent upon a schema being selected,
      if no schema (data template) exists, redirect user to create one.
    */
    <>
      <EmptySchemasText>A data template must exist in order to create a Portal</EmptySchemasText>
      <FlatButton onClick={() => history.push(`${teamRoot}/templates`)}>
        Go to data templates
      </FlatButton>
    </>
  )

  const createEmbedModal = useModal({
    width: '400px',
    header: 'Create Portal',
    contents: () => {
      const { addToast } = useToasts()

      if (!schemaQuery.data.length && !loading) {
        return <NoSchemasContent />
      }
      return (
        <>
          {!loading && (
            <CreateEmbedForm
              schemas={schemaQuery?.data}
              onSubmit={async (event) => {
                if (!event.data.name || event.data.name.length < 1) {
                  event.formContext.setValue({
                    errors: { name: 'Please enter a Portal name' }
                  })
                  return
                }

                if (!event.data.schema) {
                  event.formContext.setValue({
                    errors: { schema: 'Please select a data template' }
                  })
                  return
                }

                try {
                  const response = await createEmbed.run({
                    name: event.data.name,
                    schemaIds: [event.data.schema],
                    teamId: team.id,
                    environmentId: environment.id
                  })
                  setLoading(true)

                  addToast('Portal created successfully!', {
                    appearance: 'success',
                    autoDismiss: true
                  })

                  // put private key in context to use on initial page load
                  // after this redirect
                  setCreatedEmbedPrivateKey(response.privateKeyString)

                  history.push(`${teamRoot}/setup/embeds/${response.embed.id}`)
                } catch {
                  // do nothing, error toast is already handled
                }
              }}
              onCancel={createEmbedModal.close}
            />
          )}
        </>
      )
    }
  })

  return {
    open: createEmbedModal.open,
    render: createEmbedModal.render
  }
}
