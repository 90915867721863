import { gql } from '@apollo/client'

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword(
    $id: ID!
    $currentPassword: String!
    $password: String!
    $passwordConfirm: String!
  ) {
    updateUserPassword(
      id: $id
      currentPassword: $currentPassword
      password: $password
      passwordConfirm: $passwordConfirm
    ) {
      id
    }
  }
`
