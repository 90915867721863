import { Breadcrumbs, IBreadcrumb } from 'src/applications/Oversight/components/Breadcrumbs'
import { Card } from 'src/resources/elements/Card'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const BreadcrumbWrapper = styled.div`
  padding: 0 0 ${Spacing.basePadding3x};
`

interface IBreadcrumbsFullTemplate {
  children: any
  crumbs?: IBreadcrumb[]
}

export const BreadcrumbsFullTemplate = ({ children, crumbs }: IBreadcrumbsFullTemplate) => {
  return (
    <FlexContainer>
      {crumbs && (
        <BreadcrumbWrapper>
          <Breadcrumbs links={crumbs} />
        </BreadcrumbWrapper>
      )}
      <Card>{children}</Card>
    </FlexContainer>
  )
}
