import { MouseEvent, ReactNode, TouchEvent, useCallback, useEffect, useRef, useState } from 'react'

import { Link } from 'react-router-dom'
import { Colors } from 'src/resources/colors'
import { newTableFlatButtonStyle } from 'src/resources/elements/buttons/styles/newTableFlatButtonStyle'
import { StyledSpinner } from 'src/resources/elements/Spinner'
import { inputStyles } from 'src/resources/inputs'
import { fontSizes, fontFamily } from 'src/resources/typography'
import styled, { css, keyframes } from 'styled-components'

import {
  IFlatButtonBase,
  IFlatButtonBaseAnchor,
  IFlatButtonBaseButton,
  IFlatButtonBaseLink,
  IFlatButtonStyle
} from './IFlatButton'
import { AccentFlatButtonStyle } from './styles/AccentFlatButtonStyle'
import { AlternatePrimaryFlatButtonStyle } from './styles/AlternatePrimaryFlatButtonStyle'
import { DangerFlatButtonStyle } from './styles/DangerFlatButtonStyle'
import { PrimaryFlatButtonStyle } from './styles/PrimaryFlatButtonStyle'
import { SecondaryFlatButtonStyle } from './styles/SecondaryFlatButtonStyle'
import { SuccessFlatButtonStyle } from './styles/SuccessFlatButtonStyle'
import { WhiteFlatButtonStyle } from './styles/WhiteFlatButtonStyle'
import { ElectricFlatButtonStyle } from 'src/resources/elements/buttons/styles/ElectricFlatButtonColor'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

const rippleEffect = keyframes`
  0% { transform: scale(0); }
  90% { transform: scale(1); }
  100% { transform: scale(1); opacity: 0; }
`

const Ripple = styled.span``
const RippleInside = styled.span``

const detectRippleShadow = ({ color, variant }: { color: string; variant: string }): string => {
  if (
    (color === 'primary' && variant === 'outlined') ||
    color === 'secondary' ||
    color === 'accent' ||
    color === 'white' ||
    (color === 'danger' && variant === 'empty')
  ) {
    return 'rgba(0,0,0,0.15)'
  }

  return 'rgba(255,255,255,0.25)'
}

export const FlatButtonStyle = css<IFlatButtonStyle>`
  position: relative;
  border: 1px solid transparent;
  border-radius: ${themed('borderRadius')};
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: ${inputStyles.fontSize};
  font-weight: ${themed('buttonWeight')};
  letter-spacing: ${themed('buttonLetterSpacing')};
  line-height: initial;
  padding: ${themed('buttonPadding')};
  text-decoration: none;
  transition: background-color 0.1s linear, box-shadow 0.2s ease;
  white-space: pre;
  background-position: 10px 50%;
  background-repeat: no-repeat;

  i.icon {
    margin-right: 4px;
  }

  &:focus {
    outline: 2px solid ${themed('primary')};
    outline-offset: 2px;
  }

  img {
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }

  &:disabled {
    background-color: ${Colors.grayBG}!important;
    color: ${Colors.grayDark}!important;
    box-shadow: none;
    cursor: not-allowed;

    &:hover {
      background-color: ${Colors.grayLight}!important;
    }
  }

  ${StyledSpinner} {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
  }

  ${({ margin }) =>
    margin &&
    css`
      ${Object.keys(margin)
        .map(
          (rel: 'top' | 'left' | 'right' | 'bottom') =>
            `margin-${rel}: ${margin[rel]}${typeof margin[rel] === 'number' ? 'px' : ''};`
        )
        .join('\n')}
    `}

  ${({ size }) => {
    switch (size) {
      case 'md':
        return css`
          font-size: ${fontSizes.type16};
        `
      case 'lg':
        return css`
          font-size: ${fontSizes.type16};
          padding: 16px 18px;
        `
      default:
        return ''
    }
  }}

  ${({ color }) => {
    switch (color) {
      case 'primary':
        return PrimaryFlatButtonStyle
      case 'secondary':
        return SecondaryFlatButtonStyle
      case 'electric':
        return ElectricFlatButtonStyle
      case 'success':
        return SuccessFlatButtonStyle
      case 'danger':
        return DangerFlatButtonStyle
      case 'accent':
        return AccentFlatButtonStyle
      case 'white':
        return WhiteFlatButtonStyle
      case 'alternative':
        return AlternatePrimaryFlatButtonStyle
      case 'newTableStyles':
        return newTableFlatButtonStyle
      default:
        return PrimaryFlatButtonStyle
    }
  }}
  
  ${({ active }) =>
    active
      ? css`
          box-shadow: 0 1px 2px inset rgba(0, 0, 0, 0.3);
        `
      : null}

  ${Ripple} {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: ${inputStyles.borderRadius};

    & > ${RippleInside} {
      position: absolute;
      content: '';
      width: 150px;
      height: 150px;
      display: block;
      border-radius: 50%;
      background-color: ${({ color, variant }) => detectRippleShadow({ color, variant })};
      animation-name: ${rippleEffect};
      animation-duration: 0.5s;
      animation-timing-function: ease-in;
    }
  }
`

export const ButtonBase = styled.button`
  ${FlatButtonStyle}
`

const LinkBase = styled(Link)`
  ${FlatButtonStyle}
`

const SmallLinkBase = styled(Link)`
  color: ${Colors.sky600};
  font-size: ${fontSizes.type14};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 600;
  width: 100%;
  text-align: right;
`

const AnchorBase = styled.a`
  ${FlatButtonStyle}
`

export const UploadInputWrapper = styled.a`
  ${FlatButtonStyle}
  border: 0;
  &:after {
    border: 1px solid ${Colors.border};
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`

export const FlatButtonBase = ({
  children,
  color = 'primary',
  variant = 'default',
  renderAs = 'button',
  ...props
}: IFlatButtonBase) => {
  if (renderAs === 'small-link') {
    return (
      <SmallLinkBase color={color} variant={variant} {...(props as IFlatButtonBaseLink)}>
        {children}
      </SmallLinkBase>
    )
  }
  if (renderAs === 'link') {
    return (
      <LinkBase color={color} variant={variant} {...(props as IFlatButtonBaseLink)}>
        {children}
      </LinkBase>
    )
  }

  if (renderAs === 'a') {
    return (
      <AnchorBase color={color} variant={variant} {...(props as IFlatButtonBaseAnchor)}>
        {children}
      </AnchorBase>
    )
  }

  return (
    <ButtonBase color={color} variant={variant} {...(props as IFlatButtonBaseButton)}>
      {children}
    </ButtonBase>
  )
}

export const FlatButtonButton = (props: IFlatButtonBaseButton) => FlatButton(props)

export const FlatButton = ({ children, ...props }: IFlatButtonBase) => {
  const nextRipple = useRef(0)
  const ignoreMouseDown = useRef(false)
  const timer = useRef(null)

  useEffect(() => () => clearTimeout(timer.current), [timer])

  const [ripples, setRipples] = useState<ReactNode[]>([])

  const startEffect = useCallback(
    (e: TouchEvent | MouseEvent) => {
      const rect = e.currentTarget.getBoundingClientRect()

      if (e.type === 'mousedown' && ignoreMouseDown.current) {
        ignoreMouseDown.current = false
        return
      }

      let clientX: number
      let clientY: number

      if (e.type === 'touchstart') {
        ignoreMouseDown.current = true

        const touchEvent = e as TouchEvent

        clientX = touchEvent.touches[0].clientX
        clientY = touchEvent.touches[0].clientY
      } else {
        const mouseEvent = e as MouseEvent

        clientX = mouseEvent.clientX
        clientY = mouseEvent.clientY
      }

      const rippleX = Math.round(clientX - rect.left)
      const rippleY = Math.round(clientY - rect.top)

      setRipples((old: ReactNode[]) => {
        return [
          ...old,
          <Ripple key={nextRipple.current}>
            <RippleInside
              style={{
                top: -75 + rippleY,
                left: -75 + rippleX
              }}
            />
          </Ripple>
        ]
      })

      nextRipple.current += 1

      timer.current = window.setTimeout(() => {
        setRipples((old: any[]) => {
          if (old.length) {
            return old.slice(1)
          }

          return old
        })
      }, 500)
    },
    [setRipples]
  )

  return (
    <FlatButtonBase {...props} onMouseDown={startEffect} onTouchStart={startEffect}>
      {children}
      {ripples}
    </FlatButtonBase>
  )
}
