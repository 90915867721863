import { StateColors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { opacity } from 'src/resources/opacities'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const Notice = styled.p`
  font-size: ${fontSizes.type14};
`

const stateColorMap: { [key: string]: string } = {
  error: StateColors.error,
  warning: StateColors.warning
}

export const NoticeBlock = styled.div<{ maxWidth?: string; state?: 'error' | 'warning' }>`
  font-size: ${fontSizes.type14};
  border-radius: 4px;
  margin: ${Spacing.basePadding2x} 0 ${Spacing.basePadding2x};
  padding: ${Spacing.basePadding2x};
  background-color: ${({ state }) => opacity(stateColorMap[state], 0.1)};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
`
