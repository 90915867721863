import { useCallback, useMemo } from 'react'
import { useEnsureRequiredOptions } from 'src/applications/Oversight/hooks/useEnsureRequiredOptions'
import { getFormContext } from 'src/resources/elements/form/Form'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { ISchemaStub } from 'src/types/interfaces/ISchema'
import { sortAndMapSchemas } from 'src/utils/sortAndMapSchemas'

export interface IEditWorkspaceSchemas {
  schemaIds: string[]
}

export function SelectWorkspaceSchemas({ schemas }: { schemas: ISchemaStub[] }) {
  const formContext = getFormContext<IEditWorkspaceSchemas>()
  const schemaOptions: SelectOption[] = useMemo(
    () =>
      sortAndMapSchemas(
        schemas,
        (schema) => !Object.keys(schema.jsonSchema?.schema?.properties ?? {}).length,
        'no fields'
      ),
    [schemas]
  )
  const selectedSchemaOptions = useMemo(
    () => schemaOptions.filter((x) => formContext.value.data.schemaIds?.includes(x.value)),
    [formContext, schemaOptions]
  )
  const ensureLinkedSchemas = useEnsureRequiredOptions(schemaOptions, selectedSchemaOptions)
  const onSelectedSchemasChanged = useCallback(
    (options) => {
      const schemaIds = ensureLinkedSchemas(options).map((x) => x.value)
      setTimeout(() =>
        formContext.setValue({
          data: {
            ...formContext.value.data,
            schemaIds
          }
        })
      )
    },
    [ensureLinkedSchemas, formContext]
  )
  return (
    <Select
      autoSelectSingleOption={false}
      helperText='The Data Template(s) you want to upload data to. These will be represented as sheets in the workspace.'
      isMulti
      label='Data Template(s)'
      name='schemaIds'
      onMultiChange={onSelectedSchemasChanged}
      options={schemaOptions}
      required
      styles={{
        multiValue: (styles: any) => ({
          ...styles,
          background: 'rgba(2,102, 197, 0.1)'
        }),
        multiValueLabel: (styles: any) => ({
          ...styles
        }),
        multiValueRemove: (styles: any) => ({
          ...styles
        }),
        clearIndicator: () => ({
          display: 'none'
        })
      }}
    />
  )
}
