import { useContext, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { EmptyState } from 'src/applications/Oversight/components/EmptyState'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { useInactivateAccessKeyModal } from 'src/applications/Oversight/hooks/useInactivateAccessKeyModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useTestAccessKeyModal } from 'src/applications/Oversight/hooks/useTestAccessKeyModal'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_ACCESS_KEYS } from 'src/queries/GET_ACCESS_KEYS'
import { GetAccessKeys, GetAccessKeysVariables } from 'src/queries/types/GetAccessKeys'
import { AccessKeyTag } from 'src/resources/elements/AccessKeyTag'
import { Code } from 'src/resources/elements/Code'
import { HeaderText } from 'src/resources/elements/HeaderText'
import { Icon } from 'src/resources/elements/Icon'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { NewTable } from 'src/resources/elements/Table'
import { IAccessKey } from 'src/types/interfaces/IAccessKey'
import { renderDate } from 'src/utils/date'
import { grow } from 'src/utils/grow'
import styled from 'styled-components'
import Notification from 'src/resources/elements/Notification'

export const ManageAccessKeysPanel = () => {
  const activeInactiveTabs = useActiveArchivedTabs({
    archivedLabel: 'Inactive',
    urlParam: 'inactive'
  })
  const team = useContext(TeamContext)

  const accessKeysQuery = useQuery<GetAccessKeys, GetAccessKeysVariables>(GET_ACCESS_KEYS, {
    variables: {
      isActive: activeInactiveTabs.showActive,
      teamId: team.id
    }
  })

  const loadStatus = QueryAlert(
    accessKeysQuery,
    <>Error loading access keys</>,
    <>Loading access keys</>
  )

  const AccessKeyListElement = AccessKeyList({
    onInactivateSuccess: () => accessKeysQuery.refetch()
  })

  return (
    <>
      {activeInactiveTabs.tabs()}
      {accessKeysQuery.error || accessKeysQuery.loading ? loadStatus : null}
      {accessKeysQuery?.data?.getAccessKeys?.length === 0 && (
        <EmptyState>
          <h4>
            <Icon name='info-circle' /> No{' '}
            {activeInactiveTabs.showActive ? <>active</> : <>inactive</>} access keys
          </h4>
          {activeInactiveTabs.showActive ? (
            <p>Create an access key to use the Flatfile REST and GraphQL apis.</p>
          ) : (
            <p>Once an access key is inactivated, it will be listed here.</p>
          )}
        </EmptyState>
      )}
      {accessKeysQuery?.data?.getAccessKeys?.length > 0 && (
        <AccessKeyListElement
          accessKeys={accessKeysQuery?.data?.getAccessKeys}
          inactive={activeInactiveTabs.showArchived}
        />
      )}
    </>
  )
}

const AccessKeyListHead =
  ({ onInactivateSuccess }: { onInactivateSuccess: () => void }) =>
  () => {
    const [accessKeyToInactivate, setAccessKeyToInactivate] = useState<IAccessKey | undefined>(
      undefined
    )
    const inactivateAccessKeyModal = useInactivateAccessKeyModal({
      accessKeyToInactivate,
      onSuccess: onInactivateSuccess
    })

    const [accessKeyToTest, setAccessKeyToTest] = useState<IAccessKey | undefined>(undefined)
    const testAccessKeyModal = useTestAccessKeyModal({
      accessKeyToTest
    })

    return {
      inactivateAccessKeyModal,
      setAccessKeyToInactivate(accessKey: IAccessKey) {
        setAccessKeyToInactivate(accessKey)
        inactivateAccessKeyModal.open()
      },
      testAccessKeyModal,
      setAccessKeyToTest(accessKey: IAccessKey) {
        setAccessKeyToTest(accessKey)
        testAccessKeyModal.open()
      }
    }
  }

const AccessKeyList = ({ onInactivateSuccess }: { onInactivateSuccess: () => void }) =>
  grow(
    AccessKeyListHead({ onInactivateSuccess }),
    (
      {
        inactivateAccessKeyModal,
        setAccessKeyToInactivate,
        testAccessKeyModal,
        setAccessKeyToTest
      },
      {
        accessKeys,
        inactive
      }: {
        accessKeys: GetAccessKeys['getAccessKeys']
        inactive: boolean
      }
    ) => (
      <>
        {inactivateAccessKeyModal.render()}
        {testAccessKeyModal.render()}
        <Notification type='info'>
          To use an access key with the&nbsp;
          <a href='https://api.us.flatfile.io/rest/docs/' target='_blank'>
            REST API
          </a>
          , set HTTP header:
          <Code>X-Api-Key: &lt;access key id&gt;+&lt;secret access key&gt;</Code>
        </Notification>
        <NewTable>
          <thead>
            <AccessKeyHeaderRow inactive={inactive} />
          </thead>
          <tbody>
            {accessKeys.map((accessKey) => (
              <AccessKeyCardRow
                accessKey={accessKey}
                inactive={inactive}
                key={accessKey.accessKeyId}
                onRequestInactivate={() => setAccessKeyToInactivate(accessKey)}
                onRequestTest={() => setAccessKeyToTest(accessKey)}
              />
            ))}
          </tbody>
        </NewTable>
      </>
    )
  )

const StyledLink = styled.a`
  cursor: pointer;
`

const AccessKeyHeaderRow = ({ inactive }: { inactive: boolean }) => (
  <tr>
    <td>
      <HeaderText>Access key</HeaderText>
    </td>
    <td>
      <HeaderText>Test</HeaderText>
    </td>
    <td>
      <HeaderText>Scope</HeaderText>
    </td>
    <td>
      <HeaderText>Last used</HeaderText>
    </td>
    <td>
      <HeaderText>Date created</HeaderText>
    </td>
    {inactive ? (
      <td>
        <HeaderText>Date deactivated</HeaderText>
      </td>
    ) : (
      <td />
    )}
  </tr>
)

const AccessKeyCardRow = ({
  accessKey,
  inactive,
  onRequestInactivate,
  onRequestTest
}: {
  accessKey: IAccessKey
  inactive: boolean
  onRequestInactivate(): void
  onRequestTest(): void
}) => {
  const teamRoot = useTeamRootUrl()

  return (
    <tr>
      <td>
        <AccessKeyTag accessKey={accessKey} />
      </td>
      <td>
        <StyledLink onClick={onRequestTest}>
          <Icon name='bolt' />
        </StyledLink>
      </td>
      <td>
        {accessKey.workspace ? (
          <>
            Workspace:{' '}
            <Link to={`${teamRoot}/workspaces/${accessKey.workspace.id}`}>
              {accessKey.workspace.name}
            </Link>
          </>
        ) : (
          <em>(all)</em>
        )}
      </td>
      <td>&mdash;</td>
      <td>{renderDate(accessKey.createdAt)}</td>
      <td>
        {inactive ? (
          <>
            {renderDate(accessKey.inactivatedAt)} by{' '}
            {accessKey && accessKey.inactivatedByUser && accessKey.inactivatedByUser.name}
          </>
        ) : (
          <StyledLink onClick={onRequestInactivate}>Deactivate</StyledLink>
        )}
      </td>
    </tr>
  )
}
