import { MutationTuple, useMutation } from '@apollo/client'
import { CREATE_ACCESS_KEY } from 'src/queries/CREATE_ACCESS_KEY'
import { CreateAccessKey, CreateAccessKeyVariables } from 'src/queries/types/CreateAccessKey'

export const useCreateAccessKeyMutation = (): MutationTuple<
  CreateAccessKey,
  CreateAccessKeyVariables
> => {
  const [createAccessKey, createAccessKeyStatus] = useMutation<
    CreateAccessKey,
    CreateAccessKeyVariables
  >(CREATE_ACCESS_KEY)

  return [createAccessKey, createAccessKeyStatus]
}
