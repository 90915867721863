import { RouteComponentProps } from 'react-router'
import { WorkspaceDetailPanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceDetailPanel'
import { useParams } from 'src/resources/hooks/useParams'

export const WorkspaceDetailScene = ({
  match: { path: workspacePath }
}: RouteComponentProps<{ workspaceId: string }>) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()

  return <WorkspaceDetailPanel workspaceId={workspaceId} workspacePath={workspacePath} />
}
