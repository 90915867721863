import { useCallback, useContext } from 'react'
import { EmptyTableMessage } from 'src/applications/Oversight/components/EmptyState'
import { WorkspaceSectionRow } from 'src/applications/Oversight/components/WorkspaceSectionRow'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { LIVE_ENVIRONMENT_SLUG } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { Colors } from 'src/resources/colors'
import { NewStyledTable } from 'src/resources/elements/Table'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { fontSizes } from 'src/resources/typography'
import { IWorkspace, IWorkspaceStub } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

const StyledCountLink = styled.span`
  color: ${Colors.brandSecondary};
  font-size: ${fontSizes.type14};
  font-weight: 500;
  cursor: pointer;
`

const CountLink = ({
  workspaceCount,
  handleClick
}: {
  workspaceCount: number
  handleClick: () => void
}) => {
  return (
    <StyledCountLink onClick={handleClick}>
      {workspaceCount} inactive {workspaceCount > 1 ? 'workspaces' : 'workspace'}
    </StyledCountLink>
  )
}

export const WorkspacesListDataPanel = ({
  workspaces,
  workspaceCount
}: {
  workspaces: IWorkspace[]
  workspaceCount: number
}) => {
  const { history } = useReactRouter()
  const environment = useContext(EnvironmentContext)
  const archivedLabel = environment.slug !== LIVE_ENVIRONMENT_SLUG ? null : 'Inactive'
  const activeArchivedTabs = useActiveArchivedTabs({ archivedLabel })
  const archived = useSearchParam.boolean('archived', false)

  const historyRedirect = useCallback(() => {
    history.push(`${location.pathname}?archived=true`)
  }, [history])

  return (
    <>
      {activeArchivedTabs.tabs()}
      <StyledContainer>
        <NewStyledTable>
          <thead>
            <tr>
              <td>Workspace</td>
              <td>Organization</td>
              <td>Collaborators</td>
              <td align='right'>Last Activity</td>
            </tr>
          </thead>
          <tbody>
            {!!workspaces.length ? (
              <WorkspacesList workspaces={workspaces} />
            ) : (
              <tr>
                <EmptyTableMessage colSpan={6}>
                  {archived ? (
                    `You haven't archived any workspaces yet. Once you do, they'll appear here.`
                  ) : (
                    <div>
                      You have 0 active workspaces and{' '}
                      <CountLink handleClick={historyRedirect} workspaceCount={workspaceCount} />.
                      Create a new workspace to start onboarding new data
                    </div>
                  )}
                </EmptyTableMessage>
              </tr>
            )}
          </tbody>
        </NewStyledTable>
      </StyledContainer>
    </>
  )
}

const WorkspacesList = ({ workspaces }: { workspaces: IWorkspace[] }) => {
  return (
    <>
      {workspaces?.map((workspace: IWorkspaceStub, i: number) => {
        const { id, name, lastActivity } = workspace
        return <WorkspaceSectionRow key={i} id={id} name={name} lastActivity={lastActivity} />
      })}
    </>
  )
}
