import { useState } from 'react'

import { RouteComponentProps } from 'react-router'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { useParams } from 'src/resources/hooks/useParams'

export const WorkspaceDebugScene = ({
  match: { path: workspacePath }
}: RouteComponentProps<{ workspaceId: string }>) => {
  const [throwError, setThrowError] = useState(false)
  const { workspaceId } = useParams<{ workspaceId: string }>()

  if (throwError) {
    throw new Error('An example error message')
  }

  return (
    <ContentContainer>
      <PageHeaderContainer header='Workspace Debug' />
      <ul>
        <li>Path: {workspacePath}</li>
        <li>Workspace: {workspaceId}</li>
        <li>
          <FlatButton onClick={() => setThrowError(!throwError)}>Trigger Error</FlatButton>
        </li>
      </ul>
    </ContentContainer>
  )
}
