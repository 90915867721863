import { StateColors } from 'src/resources/colors'
import { Icon } from 'src/resources/elements/Icon'
import { NoticeBlock } from 'src/resources/elements/Notice'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  color: ${StateColors.warning};
`

export const ShowPrivateKeyNotice = () => (
  <NoticeBlock state='warning' maxWidth='560px'>
    <StyledIcon name='exclamation-triangle' /> The Portal private key is displayed exactly once,
    please copy it before continuing.
  </NoticeBlock>
)
