import AceEditor from 'react-ace'

import styled from 'styled-components'

import { getFormContext } from './Form'

const StyledAceEditor = styled(AceEditor)<{ readOnly: boolean }>`
  filter: ${({ readOnly }) => (readOnly ? 'greyscale(1)' : 'none')};
  flex-grow: 1;
  opacity: ${({ readOnly }) => (readOnly ? '0.25' : '1')};
  height: 100%;
`

export function AceInput({
  defaultValue = '',
  disabled = false,
  height,
  name,
  onChange
}: {
  defaultValue?: string
  disabled?: boolean
  height: string
  name: string
  onChange?(newValue: string): void
}) {
  const formContext = getFormContext()

  const value =
    name in formContext.value.data ? String(formContext.value.data[name]) : defaultValue

  const update = (updateValue: string) => {
    const { data } = formContext.value
    formContext.setValue({ data: { ...data, [name]: updateValue } })
    onChange(updateValue)
  }

  return (
    <StyledAceEditor
      readOnly={disabled}
      height={height}
      mode='graphql'
      theme='twilight'
      value={value}
      onBlur={(_event, editor) => {
        update(editor.getValue())
      }}
      wrapEnabled={true}
      fontSize={14}
    />
  )
}
