import { gql } from '@apollo/client'

export const MAP_DATA_SOURCE_AND_DATA_TEMPLATE = gql`
  mutation MapDataSourceAndDataTemplate(
    $viewId: ID!
    $uploadId: UUID!
    $schemaId: Int!
    $index: Int
  ) {
    mapDataSourceAndDataTemplate(
      viewId: $viewId
      uploadId: $uploadId
      schemaId: $schemaId
      index: $index
    ) {
      success
    }
  }
`
