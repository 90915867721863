import { Link, LinkProps } from 'react-router-dom'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import BackArrow from 'src/resources/icons/backarrow.svg'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const StyledGoBackLink = styled(Link)<{ $newTableStyles?: boolean }>`
  align-items: center;
  background-color: ${Colors.gray30};
  border-radius: ${Spacing.basePadding5x};
  color: ${Colors.textAccent};
  display: inline-flex;
  font-size: ${fontSizes.type14};
  font-weight: 600;
  height: ${Spacing.basePadding5x};
  padding: 0 ${Spacing.basePadding};
  position: relative;
  transition: ${Transitions.baseEase};
  width: fit-content;

  > img {
    transition: ${Transitions.baseEaseOut};
    opacity: 0.6;

    & + span {
      margin-left: ${Spacing.basePadding};
      padding-right: ${Spacing.basePadding};
    }
  }

  &:hover {
    color: ${Colors.brandPrimary};
    > img {
      transform: translateX(-2px);
    }
  }

  ${({ $newTableStyles }) =>
    $newTableStyles &&
    `
      margin-top: 0;
      margin-right: ${Spacing.basePadding2x};
      background-color: transparent;
      font-size: ${fontSizes.type15};
      > * {
        position: relative;
        z-index: 1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${Colors.gray20};
        border-radius: ${Spacing.basePadding5x};
        z-index: 0;
        transition: ${Transitions.baseEaseSlowerDelay};
        transform: scale(0);
      }
      &:hover {
        color: ${Colors.matchingButtonBlue};
        &:after {
          transform: scale(1);
        }
      }
    `}
`

export const GoBackLink = ({
  children,
  label = 'Go back',
  newTableStyles = false,
  ...props
}: LinkProps & { label?: string; newTableStyles?: boolean }) => {
  return (
    <StyledGoBackLink {...props} $newTableStyles={newTableStyles}>
      <img src={BackArrow} alt='arrow-left' height={24} width={24} />
      {label && <span>{label}</span>}
      {children}
    </StyledGoBackLink>
  )
}
