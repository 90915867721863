import { useState } from 'react'

import { IColumnExampleCard } from 'src/applications/Oversight/components/IColumnExampleCard'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import SectionHeader, { Wrap } from 'src/applications/Oversight/components/SectionHeader'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { getWorkspaceDataRequestSchemas } from 'src/queries/GET_WORKSPACE_DATA_REQUEST_SCHEMAS'
import { GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas as WorkspaceSchema } from 'src/queries/types/GetWorkspaceDataRequestSchemas'
import { Colors } from 'src/resources/colors'
import { Select } from 'src/resources/elements/form/Select'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import styled from 'styled-components'

const MainFlexWrap = styled.div`
  display: flex;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;

  ${Wrap} {
    margin-left: ${Spacing.contentPadding};
    p {
      margin-left: 0;
    }
  }

  > div:first-child {
    margin-left: ${Spacing.contentPadding};
    width: auto;
  }
`

const ExampleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  margin: 0 ${Spacing.basePadding4x};

  > div {
    margin: 100px 0 ${Spacing.contentPadding};
  }
`
const ColumnLinkWrap = styled.p<{ active?: boolean }>`
  height: 24px;
  color: ${({ active }) => (active ? Colors.brandPrimary : Colors.textAccent)};
  font-size: ${fontSizes.type15};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 500;
  padding-left: ${Spacing.contentPadding};
  margin: 0;

  &:hover {
    cursor: pointer;
  }
`

const NoColumnsText = styled.p`
  weight: 200;
  padding: 16px;
`

interface ISchemaProperties {
  description: string
  label: string
  type: string
}

const filterColumns = ({ schema, required }: { schema: WorkspaceSchema; required: boolean }) => {
  if (!schema.jsonSchema) return {}
  if (required && schema.jsonSchema.schema.required === undefined) return {}

  const filteredColumns: { [key: string]: ISchemaProperties } = {}

  required
    ? schema?.jsonSchema?.schema?.required.forEach((name: string) => {
        filteredColumns[name] = { ...schema.jsonSchema.schema.properties[name] }
      })
    : Object.keys(schema?.jsonSchema?.schema?.properties)
        .filter((x: any) => !schema.jsonSchema.schema.required?.includes(x))
        .forEach((name: string) => {
          filteredColumns[name] = { ...schema.jsonSchema.schema.properties[name] }
        })

  return filteredColumns
}

const ColumnLink = ({
  active,
  columnName,
  onClick,
  required
}: {
  active?: boolean
  columnName: string
  required?: boolean
  onClick?(): void
}) => (
  <ColumnLinkWrap active={active} onClick={onClick}>
    <OverflowData dataTip={columnName} dataFor={columnName} offset={{ top: -8, left: 10 }}>
      <span>
        {columnName} {required && `*`}
      </span>
    </OverflowData>
  </ColumnLinkWrap>
)

const WorkspaceWithSchemas = ({ schemaData }: { schemaData: WorkspaceSchema[] }) => {
  // set initial state to the first schema
  const [activeDB, setActiveDB] = useState(schemaData[0] ? schemaData[0].id : '')
  const [activeColumn, setActiveColumn] = useState(
    schemaData[0].jsonSchema ? Object.keys(schemaData[0]?.jsonSchema?.schema?.properties)[0] : ''
  )

  const handleChange = (id: string) => {
    setActiveDB(id)
    const matchingDB = Object.keys(
      schemaData.find((data: WorkspaceSchema) => data.id === id).jsonSchema.schema.properties
    )[0]
    setActiveColumn(matchingDB)
  }

  const requiredColumns =
    schemaData &&
    filterColumns({
      schema: schemaData.find((x: any) => x.id === activeDB),
      required: true
    })

  const optionalColumns =
    schemaData &&
    filterColumns({
      schema: schemaData.find((x: any) => x.id === activeDB),
      required: false
    })

  return (
    <>
      <Sidebar>
        <Select
          label='Select a dataset'
          name='schema'
          selectedValue={activeDB}
          options={schemaData?.map((schema: WorkspaceSchema, index: number) => ({
            label: schema.name,
            value: schema.id,
            key: index
          }))}
          onChange={(e) => handleChange(e.value)}
        />
        {!!Object.keys(requiredColumns).length && <SectionHeader name='Required columns' />}
        {Object.keys(requiredColumns)?.map((jsonSchema: string, index: number) => {
          return (
            <ColumnLink
              key={index}
              active={activeColumn === jsonSchema}
              columnName={requiredColumns[jsonSchema].label}
              required
              onClick={() => setActiveColumn(jsonSchema)}
            />
          )
        })}
        {!!Object.keys(optionalColumns).length && <SectionHeader name='Columns' />}
        {Object.keys(optionalColumns)?.map((jsonSchema: string, index: number) => {
          return (
            <ColumnLink
              key={index}
              active={activeColumn === jsonSchema}
              columnName={optionalColumns[jsonSchema].label}
              onClick={() => setActiveColumn(jsonSchema)}
            />
          )
        })}
      </Sidebar>
      <ExampleWrap>
        {schemaData?.find((x: any) => x.id === activeDB).jsonSchema ? (
          <IColumnExampleCard
            activeColumn={activeColumn}
            schema={schemaData?.find((x: any) => x.id === activeDB).jsonSchema?.schema.properties}
            isRequired={schemaData
              .find((x: any) => x.id === activeDB)
              .jsonSchema.schema.required?.includes(activeColumn)}
          />
        ) : (
          <NoColumnsText>No datasets columns found, please update your model</NoColumnsText>
        )}
      </ExampleWrap>
    </>
  )
}

export const WorkspaceKnowledgeBasePanel = () => {
  const { workspaceId } = useParams<{ workspaceId: string; teamId: string }>()
  const teamRoot = useTeamRootUrl()
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const { data, loading, error } = getWorkspaceDataRequestSchemas(workspaceId)

  if (error || loading || data.getWorkspaceDataRequestSchemas.length === 0) {
    return QueryAlert({ error, loading })
  }

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      current: true,
      label: 'Knowledge base'
    }
  ]

  return (
    <BreadcrumbsFullTemplate crumbs={links}>
      <WorkspaceActionBar title='Knowledge base' workspaceId={workspaceId} />
      <MainFlexWrap>
        <WorkspaceWithSchemas schemaData={data.getWorkspaceDataRequestSchemas} />
      </MainFlexWrap>
    </BreadcrumbsFullTemplate>
  )
}
