import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import {
  canNavigateTo,
  TableOverflow
} from 'src/applications/Oversight/panels/ImportListDataPanel'
import { GetBatches_getBatches, GetBatches_getBatches_data } from 'src/queries/types/GetBatches'
import { IDLabel, TableLink, TableNotLink } from 'src/resources/elements/HeaderText'
import { ClickableTR, NewStyledTable } from 'src/resources/elements/Table'
import { EBatchType } from 'src/types/enums/EBatchTypes'
import { renderDate } from 'src/utils/date'
import {
  renderEndUserId,
  renderEndUserLink,
  renderEndUserName,
  renderSchemaLink
} from 'src/utils/formatListData'
import { getBatchFilename } from 'src/utils/getBatchFilename'
import useReactRouter from 'use-react-router'

import { ImportStatus } from './ImportStatus'

type BatchType = GetBatches_getBatches_data

export const ImportsTable = ({ batches }: { batches: GetBatches_getBatches }) => {
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()

  return (
    <TableOverflow fullHeight>
      <NewStyledTable data-testid='imports-table'>
        <thead>
          <tr>
            <td>File name</td>
            <td align='center'>Status</td>
            <td>Uploaded by</td>
            <td align='right'>Rows</td>
            <td>Data template</td>
            <td align='right'>Upload date</td>
          </tr>
        </thead>
        <tbody data-testid='imports-table-body'>
          {batches &&
            batches.data.map((batch: BatchType, index) => {
              const canNavigate = canNavigateTo(batch)
              const TableData = () => (
                <OverflowData
                  dataTip={getBatchFilename(batch)}
                  dataFor={getBatchFilename(batch)}
                  maxWidth={250}
                >
                  <span>{getBatchFilename(batch)}</span>
                </OverflowData>
              )
              const batchSchema = batch?.template
              return (
                <ClickableTR
                  data-testid={`batch-row-${index}`}
                  key={index}
                  disableClick={!canNavigate}
                  onClick={
                    canNavigate ? () => history.push(`${teamRoot}/imports/${batch.id}`) : undefined
                  }
                >
                  <td>
                    {batch.managed || batch.type !== EBatchType.legacyUpload ? (
                      <TableNotLink>
                        <TableData />
                      </TableNotLink>
                    ) : (
                      <TableData />
                    )}
                  </td>
                  <td align='center'>
                    <ImportStatus batch={batch} />
                  </td>
                  <td>
                    {batch.endUser ? (
                      <TableLink
                        to={`${teamRoot}/imports/${renderEndUserLink(batch.endUser)}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {renderEndUserName(batch.endUser)}
                        <IDLabel hideMargin={!renderEndUserName(batch.endUser)}>
                          {renderEndUserId(batch.endUser)}
                        </IDLabel>
                      </TableLink>
                    ) : (
                      renderEndUserName()
                    )}
                  </td>
                  <td align='right'>{batch.countRows ? batch.countRows : 0}</td>
                  <td>
                    {batchSchema?.name ? (
                      <TableLink
                        to={`${teamRoot}/imports/${renderSchemaLink(batchSchema)}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {batchSchema?.name}
                      </TableLink>
                    ) : (
                      batch?.settings?.type ?? ''
                    )}
                  </td>
                  <td align='right'>{renderDate(batch.createdAt, true)}</td>
                </ClickableTR>
              )
            })}
        </tbody>
      </NewStyledTable>
    </TableOverflow>
  )
}
