const cachedAlphaCodes: Record<number, string> = {}

export const convertToAlphaCode = (index: number) => {
  if (index in cachedAlphaCodes) {
    return cachedAlphaCodes[index]
  }
  const baseCharCode = 'A'.charCodeAt(0)
  let alphaCode = ''

  do {
    index -= 1
    alphaCode = String.fromCharCode(baseCharCode + (index % 26)) + alphaCode
    index = Math.floor(index / 26)
  } while (index > 0)

  cachedAlphaCodes[index] = alphaCode

  return alphaCode
}

/**
 * converts any of the [hyphen, underscore, period, space] types to dash/kebab-casing and lowercase.
 *
 * @param text
 */
export const dasherize = (text?: string | null) => {
  const result = (text ?? '').replace(/[-_\s.]+(.)?/g, '-$1').toLowerCase()
  return result === '-' ? '' : result
}
