import { useContext, useEffect, useState } from 'react'

import { FileUploadContext, IFileUploadState } from 'src/contexts/FileUploadContext'

export const useUploads = (workspaceId?: string) => {
  const { fileUploadState: allUploads } = useContext(FileUploadContext)

  const [uploads, setUploads] = useState({})

  useEffect(() => {
    if (allUploads) {
      setUploads(
        Object.entries(allUploads).reduce(
          (acc: { [key: string]: IFileUploadState }, [uploadId, uploadState]) => {
            if (uploadState.workspaceId === workspaceId) {
              return { ...acc, [uploadId]: uploadState }
            }
            return acc
          },
          {}
        )
      )
    }
  }, [allUploads, workspaceId])

  return { uploads }
}
