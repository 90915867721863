import { MutationTuple, useMutation } from '@apollo/client'
import { UpdateSchema, UpdateSchemaVariables } from 'src/queries/types/UpdateSchema'
import { UPDATE_SCHEMA } from 'src/queries/UPDATE_SCHEMA'

export const useUpdateSchemaMutation = (): MutationTuple<UpdateSchema, UpdateSchemaVariables> => {
  const [createSchema, createSchemaStatus] = useMutation<UpdateSchema, UpdateSchemaVariables>(
    UPDATE_SCHEMA
  )

  return [createSchema, createSchemaStatus]
}
