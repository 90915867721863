import { useCallback, useMemo } from 'react'
import { EmptyUploadsPanel } from 'src/applications/Oversight/panels/EmptyUploadsPanel'
import { useTableFilters, Validation } from 'src/resources/hooks/useTableFilters'
import { useUploadFile } from 'src/applications/Oversight/hooks/useUploadFile'
import { Colors } from 'src/resources/colors'
import { useParams } from 'src/resources/hooks/useParams'

import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import styled from 'styled-components'

import {
  getEmptyStateTitle,
  getEmptyValidationBody,
  IRowCounts
} from 'src/utils/emptyTableMethods'
import { FlatUploadButton } from 'src/resources/elements/buttons/FlatUploadButton'
import { availableFilters } from './FilterSelect'
import { pluralize } from 'src/utils/pluralize'

type ValidationAndRecordCount = [Validation, number]

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`

const StyledContainer = styled.div`
  margin-top: ${Spacing.basePadding2x};
  p {
    font-family: ${fontFamily.fontPrimary};
    font-weight: 600;
    color: ${Colors.pigeon800};
  }
`

export const EmptyTableState = ({ rowCounts }: { rowCounts?: IRowCounts }) => {
  const { workspaceId } = useParams<{
    workspaceId: string
  }>()
  const [{ validationState, filter: filterChoice }, setTableFilters] = useTableFilters()
  const { onUpload, FailedUploadModal } = useUploadFile(workspaceId)

  const onFilterClick = useCallback(
    (stateLabel) => {
      setTableFilters({ validationState: stateLabel })
    },
    [setTableFilters]
  )

  const renderLinks = useMemo(
    () =>
      ([[firstState, firstCount], [secondState, secondCount]]: ValidationAndRecordCount[]) => {
        const onValidOrErrorFilter = filterChoice !== availableFilters[0].value
        if (onValidOrErrorFilter) {
          return null
        }
        if (!firstCount && !secondCount) {
          return (
            <StyledContainer>
              <FlatUploadButton styleAsLink onFileSelected={onUpload}>
                Load a file
              </FlatUploadButton>
              into this sheet to get started.
            </StyledContainer>
          )
        }
        return (
          <StyledContainer>
            <p>
              There {pluralize('are', rowCounts.totalRows - rowCounts.accepted)}{' '}
              <StyledLink onClick={() => onFilterClick(firstState)}>
                {getEmptyValidationBody(firstState, firstCount)}
              </StyledLink>{' '}
              and{' '}
              <StyledLink onClick={() => onFilterClick(secondState)}>
                {getEmptyValidationBody(secondState, secondCount)}
              </StyledLink>
            </p>
          </StyledContainer>
        )
      },
    [onFilterClick, filterChoice, availableFilters, rowCounts]
  )

  return (
    <EmptyUploadsPanel title={getEmptyStateTitle({ filterChoice, validationState, rowCounts })}>
      <>
        {FailedUploadModal.render()}
        {(() => {
          switch (validationState) {
            case 'accepted':
              return renderLinks([
                ['review', rowCounts.review ?? rowCounts.totalRows - rowCounts.accepted],
                ['dismissed', rowCounts.dismissed]
              ])
            case 'dismissed':
              return renderLinks([
                ['accepted', rowCounts.accepted],
                ['review', rowCounts.review ?? rowCounts.totalRows - rowCounts.accepted]
              ])
            case 'review':
              return (
                <>
                  {renderLinks([
                    ['accepted', rowCounts.accepted],
                    ['dismissed', rowCounts.dismissed]
                  ])}
                  {!!rowCounts.accepted ||
                    (!!rowCounts.dismissed && (
                      <StyledContainer>
                        <p>Add more data by loading a file to this sheet</p>
                      </StyledContainer>
                    ))}
                </>
              )
            default:
              return null
          }
        })()}
      </>
    </EmptyUploadsPanel>
  )
}
