import { ReactNode } from 'react'

import { Content } from 'src/resources/elements/Content'
import { getFormContext } from 'src/resources/elements/form/Form'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

export const StyledButtonGroup = styled.div<{ embed?: boolean }>`
  padding-top: ${Spacing.basePadding2_5x};
  position: ${(props) => (props.embed ? 'absolute' : 'static')};
  top: ${(props) => (props.embed ? '83px' : '0')};
  right: ${(props) => (props.embed ? '32px' : '0')};

  div {
    margin-right: ${Spacing.baseAndHalfPadding};

    &:last-child {
      margin-right: 0;
    }
  }

  > div {
    display: flex;
    > a {
      margin-right: ${Spacing.baseAndHalfPadding};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Thinking = styled.div`
  margin: ${Spacing.basePadding2x} 0;
`

export const ButtonWrap = styled.div`
  padding-top: ${Spacing.basePadding2x};
`

export const ButtonGroup = ({
  activityLabel = 'Thinking...',
  children,
  left,
  right,
  embed,
  stretch,
  className
}: {
  activityLabel?: string
  children: ReactNode
  left?: boolean
  right?: boolean
  embed?: boolean
  stretch?: boolean
  className?: string
}) => {
  const formContext = getFormContext()
  return (
    // "className" is required to apply "styled-components" styles
    // (passed down from the parent component)
    // @ref https://styled-components.com/docs/advanced#styling-normal-react-components
    // eslint-disable-next-line react/forbid-component-props
    <StyledButtonGroup embed={embed} className={className}>
      {formContext?.value?.submitting ? (
        <Thinking>
          <Spinner /> {activityLabel}
        </Thinking>
      ) : null}
      <Content right={right} left={left} stretch={stretch}>
        {children}
      </Content>
    </StyledButtonGroup>
  )
}
