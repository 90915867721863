import { memo, MutableRefObject, useCallback, useContext, useRef } from 'react'

import { useQuery } from '@apollo/client'
import { ContextMenu } from '@flatfile/design-system'
import { Link, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { CreateDataRequestForm } from 'src/applications/Oversight/forms/CreateDataRequestForm'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_USERS_FOR_WORKSPACE } from 'src/queries/GET_USERS_FOR_WORKSPACE'
import { useRemoveCollaboratorMutation } from 'src/queries/hooks/useRemoveCollaboratorMutation'
import {
  GetUsersForWorkspace,
  GetUsersForWorkspaceVariables
} from 'src/queries/types/GetUsersForWorkspace'
import { Colors } from 'src/resources/colors'
import { AvatarCircleInitials } from 'src/resources/elements/Avatar'
import { DiagonalUpArrow, InfoIcon, LockIcon } from 'src/resources/elements/Icons'
import { IModal, useModal } from 'src/resources/elements/Modal'
import Pill, { PillStyle } from 'src/resources/elements/Pill'
import { queryAlert, QueryAlert } from 'src/resources/elements/QueryAlert'
import { Tabstrip } from 'src/resources/elements/Tabstrip'
import { Spacing } from 'src/resources/layout'
import { fontPrimary, fontSizes } from 'src/resources/typography'
import { named } from 'src/resources/utils/named'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_ORGANIZATIONS } from 'src/smart/queries/SQ_ORGANIZATIONS'
import { SQ_WORKSPACE_COLLABORATORS } from 'src/smart/queries/SQ_WORKSPACE_COLLABORATORS'
import { SQ_WORKSPACE_ORGANIZATION } from 'src/smart/queries/SQ_WORKSPACE_ORGANIZATION'
import {
  GetWorkspaceCollaborators_getWorkspaceCollaborators,
  GetWorkspaceCollaborators_getWorkspaceCollaborators_collaborators
} from 'src/smart/queries/types/GetWorkspaceCollaborators'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import { mergedQueryResult } from 'src/utils/mergedQueryResult'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { useConfirmModal } from './useConfirmModal'
import { useTabs } from './useTabs'
import { useTeamRole } from './useTeamRole'
import { IInviteData, useWorkspaceInvite } from './useWorkspaceInvite'
import { useRemoveWorkspaceInvitationMutation } from 'src/queries/hooks/useRemoveWorkspaceInvitationMutation'

interface IDataRequestEvent {
  organizationId?: string
  currentOwnerUserId?: number | string
  email?: string
  name?: string
  schemas: string[]
}

export const useCreateDataRequestModal = (workspace?: IWorkspace) => {
  const modalRef = useRef<IModal>()
  const { isCollaborator } = useTeamRole()

  modalRef.current = useModal({
    width: '600px',
    showCloseIcon: !isCollaborator,
    extraContents: () => (
      <CreateWorkspaceMemberModalContents workspace={workspace} modalRef={modalRef} />
    ),
    showExtraContentCloseIcon: isCollaborator,
    contents: () =>
      !isCollaborator ? (
        <CreateDataRequestInitialContents title workspace={workspace} modalRef={modalRef} />
      ) : null
  })

  return modalRef.current
}

export const CreateDataRequestInitialContents = named(
  'CreateDataRequestInitialContents',
  ({
    modalRef,
    workspace,
    title
  }: {
    modalRef: MutableRefObject<IModal>
    workspace?: IWorkspace
    title?: boolean
  }) => {
    if (!workspace) {
      return QueryAlert({ loading: true })
    }

    return (
      <CreateDataRequestModalContents workspace={workspace} modalRef={modalRef} title={title} />
    )
  }
)

const MemberProfile = styled.div<{ owner: boolean; isCollaborator: boolean }>`
  flex: 1;
  margin-left: ${Spacing.baseAndHalfPadding};

  label {
    font-weight: 600;
    font-size: 1em;
    min-width: 166px;

    span {
      color: ${Colors.textAccent};
      font-weight: 500;
      margin-left: ${Spacing.halfBasePadding};
    }
  }

  label,
  span {
    margin: 0;
  }

  span {
    line-break: anywhere;
    flex: 1;
    max-width: ${({ owner, isCollaborator }) =>
      owner ? (isCollaborator ? '215px' : '160px') : 'unset'};
  }

  &,
  div {
    display: flex;
    align-items: center;

    button {
      margin-left: 20px;
    }
  }
`

const PendingPill = styled(PillStyle)`
  min-width: 101px;
`

export const MemberRow = styled.div<{ idx: number }>`
  display: flex;
  padding: ${Spacing.basePadding} ${Spacing.basePadding} ${Spacing.basePadding}
    ${Spacing.basePadding2x};
  background-color: ${({ idx }) => (idx % 2 === 0 ? Colors.white : Colors.pigeon100)};
  border-radius: 3px;
  align-items: center;
`

const Wrapper = styled.div`
  * {
    font-family: ${fontPrimary};
  }

  ${Tabstrip} {
    padding: 10px 0;
    font-size: ${fontSizes.type14};
  }
`

const TeamMembersLabel = styled.span`
  font-size: ${fontSizes.type12};
  color: ${Colors.pigeon800};
  margin-top: 12px;
  margin-bottom: ${Spacing.basePadding2_5x};
  display: block;
`

const Owner = styled.div<{ marginTop?: string }>`
  display: flex;
  border: 1px ${Colors.pigeon400} solid;
  padding: 15px;
  border-radius: ${Spacing.borderRadius};
  margin-top: ${({ marginTop }) => marginTop ?? 'unset'};

  svg {
    min-height: 15px;
    min-width: 15px;
    margin-right: 15px;
  }

  span {
    font-weight: 600;
    font-size: ${fontSizes.type14};
    line-height: 20px;
    color: ${Colors.pigeon700};
  }
`

const MemberCard = memo(
  ({
    user,
    workspaceId,
    modalRef,
    idx,
    owner
  }: {
    user: GetWorkspaceCollaborators_getWorkspaceCollaborators_collaborators
    workspaceId: string
    modalRef: MutableRefObject<IModal>
    idx: number
    owner?: boolean
  }) => {
    const team = useContext(TeamContext)
    const { isCollaborator } = useTeamRole()
    const {
      data: { me }
    } = getBasicInfoContext().value
    const { sendInvite } = useWorkspaceInvite()
    const teamRoot = useTeamRootUrl()
    const { addToast } = useToasts()
    const history = useHistory()
    const [removeWorkspaceCollaborator] = useRemoveCollaboratorMutation()
    const [removeWorkspaceInvitation] = useRemoveWorkspaceInvitationMutation()

    const currentMember = me.id === user.id

    const resendInvite = useCallback(async () => {
      const dataReqValues: IInviteData = {
        email: user.email,
        teamId: team.id,
        workspaceId
      }
      await sendInvite(dataReqValues)

      modalRef.current.close()

      history.push(`${teamRoot}/workspaces/${workspaceId}`)
    }, [])

    const revokeInvite = useCallback(async () => {
      await removeWorkspaceInvitation({
        variables: { teamId: team.id, workspaceId: workspaceId, email: user.email }
      })

      removeUserModal.close()
      modalRef.current.close()

      addToast('Successfully revoked the invite', {
        appearance: 'success',
        autoDismiss: true
      })
    }, [])

    const removeUser = useCallback(async () => {
      await removeWorkspaceCollaborator({
        variables: { teamId: team.id, workspaceId: workspaceId, userId: user.id }
      })

      removeUserModal.close()
      modalRef.current.close()

      if (currentMember) {
        history.push(`${teamRoot}/workspaces`)
      }

      addToast('Successfully removed the user', {
        appearance: 'success',
        autoDismiss: true
      })
    }, [])

    const removeUserModal = useConfirmModal({
      header: 'Are you sure you want to remove this user?',
      onConfirm: removeUser
    })

    const menuOptions = []

    if (user.pending && !isCollaborator) {
      menuOptions.push(
        {
          label: 'Resend invite',
          key: 'resend',
          onAction: resendInvite
        },
        {
          label: 'Revoke invite',
          key: 'revoke',
          onAction: revokeInvite
        }
      )
    }

    if ((!isCollaborator || (isCollaborator && currentMember)) && !user.pending && !owner) {
      menuOptions.push({
        label: 'Remove user',
        key: 'remove',
        onAction: removeUserModal.open
      })
    }

    return (
      <MemberRow idx={idx}>
        <AvatarCircleInitials name={user.name} small />
        <MemberProfile owner={owner} isCollaborator={isCollaborator}>
          <label>
            {user.name}
            {currentMember && <span>(You)</span>}
          </label>
          <span>{user.email}</span>
          <div>
            {owner && (
              <Pill
                customStyle={{
                  background: Colors.electric050,
                  color: Colors.electric900
                }}
              >
                Owner
              </Pill>
            )}
            {user.pending && (
              <PendingPill
                custom={{
                  background: Colors.warningBGColor,
                  color: Colors.warningStrong
                }}
              >
                Invite pending
              </PendingPill>
            )}
            {!!menuOptions.length && <ContextMenu options={menuOptions} />}
          </div>
        </MemberProfile>
        {removeUserModal.render()}
      </MemberRow>
    )
  }
)

const UnderlinedLink = styled(Link)`
  text-decoration: underline;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 5px;
    width: 7px;
  }
`

export const CreateWorkspaceMemberModalContents = named(
  'CreateWorkspaceMemberModalContents',
  ({ workspace, modalRef }: { workspace?: IWorkspace; modalRef: MutableRefObject<IModal> }) => {
    const teamRootUrl = useTeamRootUrl()
    const { isCollaborator } = useTeamRole()
    const collaboratorsTabs = useTabs({
      urlParam: 'tab',
      tabs: [
        {
          id: 'collaborators',
          label: 'Collaborators',
          contents: WorkspaceCollaborators
        }
      ]
    })

    const workspaceCollaboratorsQuery = useSmartQuery(SQ_WORKSPACE_COLLABORATORS, {
      skip: !workspace,
      fetchPolicy: 'network-only',
      variables: { workspaceId: workspace?.id }
    })
    const organizationQuery = useSmartQuery(SQ_WORKSPACE_ORGANIZATION, {
      skip: !workspace,
      variables: { workspaceId: workspace?.id }
    })
    const organizationId = String(organizationQuery.result?.id)
    const workspaceId = String(workspace?.id)

    if (workspaceCollaboratorsQuery.alert) {
      return queryAlert(workspaceCollaboratorsQuery)
    }

    const { owner } = workspaceCollaboratorsQuery?.result

    return (
      <Wrapper>
        {isCollaborator ? (
          <Owner marginTop={Spacing.basePadding3x}>
            <InfoIcon />
            <span>This workspace is managed by {`${owner?.name} (${owner?.email})`}.</span>
          </Owner>
        ) : (
          <Owner>
            <LockIcon />
            <span>
              This workspace was created by {`${owner?.name} (${owner?.email})`}. Everyone on your
              Team has access to this workspace. You can manage your team{' '}
              <UnderlinedLink to={`${teamRootUrl}/team`}>
                here <DiagonalUpArrow />
              </UnderlinedLink>
            </span>
          </Owner>
        )}
        {collaboratorsTabs.render({
          workspaceCollaborators: workspaceCollaboratorsQuery.result,
          workspaceId,
          organizationId,
          modalRef
        })}
      </Wrapper>
    )
  }
)

const WorkspaceCollaborators = ({
  workspaceCollaborators,
  workspaceId,
  modalRef
}: {
  workspaceCollaborators: GetWorkspaceCollaborators_getWorkspaceCollaborators
  workspaceId: string
  organizationId: string
  modalRef: MutableRefObject<IModal>
}) => {
  return (
    <>
      <TeamMembersLabel>Users that have access to this Workspace only.</TeamMembersLabel>
      <MemberCard
        idx={-1}
        key={workspaceCollaborators.owner.id}
        user={workspaceCollaborators.owner}
        workspaceId={workspaceId}
        modalRef={modalRef}
        owner
      />
      {workspaceCollaborators?.collaborators.map((user, idx) => (
        <MemberCard
          idx={idx}
          key={user.id}
          user={user}
          workspaceId={workspaceId}
          modalRef={modalRef}
        />
      ))}
    </>
  )
}

export const CreateDataRequestModalContents = named(
  'CreateDataRequestModalContents',
  ({
    modalRef,
    workspace,
    title
  }: {
    modalRef: MutableRefObject<IModal>
    workspace?: IWorkspace
    title?: boolean
  }) => {
    const team = useContext(TeamContext)
    const teamRoot = useTeamRootUrl()
    const environment = useContext(EnvironmentContext)
    const { history } = useReactRouter()
    const { sendInvite } = useWorkspaceInvite()

    const organization = useSmartQuery(SQ_WORKSPACE_ORGANIZATION, {
      fetchPolicy: 'network-only',
      variables: workspace
        ? {
            workspaceId: workspace.id
          }
        : {}
    })
    const organizations = useSmartQuery(SQ_ORGANIZATIONS, {
      variables: {
        teamId: team.id,
        environmentId: environment?.id,
        skip: 0,
        take: 1000
      }
    })

    const usersQuery = useQuery<GetUsersForWorkspace, GetUsersForWorkspaceVariables>(
      GET_USERS_FOR_WORKSPACE,
      {
        variables: {
          workspaceId: workspace.id
        }
      }
    )

    const mergedQuery = mergedQueryResult(organization.state, organizations.state, usersQuery)

    return mergedQuery.alert ? (
      QueryAlert(mergedQuery)
    ) : (
      <CreateDataRequestForm
        users={usersQuery.data?.getUsersForWorkspace}
        organizationId={organization.result?.id}
        organizations={organizations.result}
        title={title}
        onCancel={modalRef.current.close}
        onSubmit={async (event: IDataRequestEvent) => {
          const { email, organizationId } = event

          const dataReqValues = {
            email,
            // react-select's Select component expects a string for values so
            // we have to convert it back here
            organizationId: organization.result?.id || organizationId,
            teamId: team.id,
            workspaceId: workspace.id
          }

          await sendInvite(dataReqValues)

          modalRef.current.close()

          history.push(`${teamRoot}/workspaces/${workspace.id}`)
        }}
      />
    )
  }
)
