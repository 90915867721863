import { EmojiIcon, Vertical } from 'src/applications/Oversight/developer/DeveloperMenuCommon'

import { DeveloperMenuLink } from './DeveloperMenuLink'
import { DeveloperMenuRouteControl } from './DeveloperMenuRoutes'

export const DeveloperMenuHome = ({ control }: { control: DeveloperMenuRouteControl }) => (
  <Vertical>
    <DeveloperMenuLink to={['about']} control={control}>
      <EmojiIcon>🗒</EmojiIcon>
      <div>About</div>
    </DeveloperMenuLink>
    <DeveloperMenuLink to={['config']} control={control}>
      <EmojiIcon>🧮️</EmojiIcon>
      <div>Config</div>
    </DeveloperMenuLink>
    <DeveloperMenuLink to={['features']} control={control}>
      <EmojiIcon>🔑</EmojiIcon>
      <div>Features</div>
    </DeveloperMenuLink>
    <DeveloperMenuLink to={['tools']} control={control}>
      <EmojiIcon>🛠</EmojiIcon>
      <div>Tools</div>
    </DeveloperMenuLink>
  </Vertical>
)
