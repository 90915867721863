import { gql } from '@apollo/client'

export const CREATE_ACCESS_KEY = gql`
  mutation CreateAccessKey($licenseKey: UUID!, $memo: String!, $teamId: ID!, $workspaceId: UUID) {
    createAccessKey(
      licenseKey: $licenseKey
      memo: $memo
      teamId: $teamId
      workspaceId: $workspaceId
    ) {
      secretAccessKey {
        secret
      }
      accessKey {
        accessKeyId
        createdAt
        expiresAt
        inactivatedAt
        inactivatedByUser {
          id
          name
        }
        workspace {
          id
          name
        }
      }
    }
  }
`
