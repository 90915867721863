import { ContextMenu } from '@flatfile/design-system'
import { useCallback, useContext, useMemo } from 'react'

import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { DropdownChildNav } from 'src/applications/Oversight/components/DropdownChildNav'
import { IDropdownChildNavOption } from 'src/applications/Oversight/components/DropdownChildNav'
import { ProfileCard } from 'src/applications/Oversight/components/ProfileCard'
import WorkspaceAvatar from 'src/applications/Oversight/components/WorkspaceAvatar'
import { useLoginAsModal } from 'src/applications/Oversight/hooks/useLoginAsModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { HighLayer3 } from 'src/applications/Oversight/layers'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { getUserContext } from 'src/contexts/UserContext'
import { useWorkspaceChatContext } from 'src/contexts/WorkspaceChatContext'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { TopLevelPortal } from 'src/resources/elements/TopLevelPortal'
import arrowRight from 'src/resources/icons/arrow-right.svg'
import lockIcon from 'src/resources/images/lock.svg'
import { Spacing } from 'src/resources/layout'
import {
  Columns,
  Content,
  Sidebar,
  SIDEBAR_WIDTH_PX
} from 'src/resources/layouts/VariableWidthContentLayout'
import { normalizeRedirectPath } from 'src/utils/normalizeRedirectPath'
import styled from 'styled-components'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'

const FlexyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`
const UserMenuLauncher = styled(FlexyDiv)`
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${Spacing.basePadding2x} 0;
  user-select: none;
  width: ${SIDEBAR_WIDTH_PX - 40}px;

  ul {
    width: 320px;
    bottom: 60px;
    left: -20px;
  }
`

const AvatarWrap = styled.div`
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  &:after {
    background-image: url(${arrowRight});
    background-position: right 6px top 50%;
    background-repeat: no-repeat;
    background-size: ${Spacing.basePadding2x};
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0.2;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: ${Transitions.baseEaseWithProperty('opacity')};
  }

  &:hover {
    &:after {
      opacity: 0.6;
    }
  }
`

const LoginAsOption = styled.span`
  color: ${Colors.brandPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DropdownContent = styled.div`
  width: ${SIDEBAR_WIDTH_PX - 40}px;
`

const FixedColumns = styled(Columns)`
  bottom: 0;
  left: 0;
  overflow: visible;
  position: fixed;
  right: 0;
  height: 76px;
  pointer-events: none;
  z-index: ${HighLayer3};
`

interface IAccountDropdownOption {
  label: string
  path?: string
  Component?: React.FC<{}>
  onClick?: () => void
}

export const ProfileComponent = withRouter(({ location: { pathname } }: RouteComponentProps) => {
  const teamRoot = useTeamRootUrl()
  const {
    data: { me, myTeams }
  } = getBasicInfoContext().value
  const history = useHistory()
  const { isCollaborator } = useTeamRole()

  const team = useContext(TeamContext)
  const {
    value: { user }
  } = getUserContext()

  const teamSelectionOptions: IDropdownChildNavOption[] = useMemo(() => {
    return (
      myTeams?.map((listedTeam) => ({
        label: listedTeam.name === 'Unknown - Github' ? me?.githubUsername : listedTeam.name,
        path: normalizeRedirectPath(pathname, { teamId: listedTeam.id })
      })) ?? []
    )
  }, [myTeams, me, pathname])

  const loginAsModal = useLoginAsModal()
  const loginAsComponent = useCallback(() => {
    if (me?.superAdmin) {
      return (
        <LoginAsOption>
          {loginAsModal.render()}
          Login as
          <img src={lockIcon} height={20} width={20} alt='Login as' />
        </LoginAsOption>
      )
    }
    return null
  }, [me?.superAdmin, loginAsModal])

  const accountDropdownOptions = useMemo(() => {
    return [
      {
        label: 'Settings',
        path: `${teamRoot}/account`
      },
      ...(!isCollaborator
        ? [
            {
              label: 'Billing',
              path: `${teamRoot}/billing`
            }
          ]
        : []),
      {
        label: 'Logout',
        path: '/logout'
      },
      ...(!!loginAsComponent()
        ? [
            {
              label: 'Login as',
              Component: loginAsComponent,
              onClick: loginAsModal.open
            }
          ]
        : [])
    ] as IAccountDropdownOption[]
  }, [teamRoot, loginAsComponent])

  const {
    value: { online }
  } = useWorkspaceChatContext()

  const handleCloseOnInteractOutside = useCallback((e: HTMLElement) => {
    const interactElements = ['FlatInput', 'Modal', 'ButtonBase', 'RippleInside']

    return (
      e &&
      !(
        interactElements.some((element) => e.classList.value.match(new RegExp(element, 'g'))) ||
        e.nodeName === 'svg'
      )
    )
  }, [])

  return (
    <TopLevelPortal>
      <FixedColumns>
        <Sidebar>
          <UserMenuLauncher>
            <ContextMenu
              handleCloseOnInteractOutside={handleCloseOnInteractOutside}
              options={[
                {
                  key: 'profile-header',
                  label: (
                    <DropdownChildNav header='Teams' options={teamSelectionOptions}>
                      <AvatarWrap>
                        <WorkspaceAvatar
                          hasWhiteBG={true}
                          workspaceName={
                            team.name === 'Unknown - Github' ? me?.githubUsername : team?.name
                          }
                          bgColor={Colors.brandPrimary}
                          showFullName
                        />
                      </AvatarWrap>
                    </DropdownChildNav>
                  ),
                  onAction: null,
                  shouldCloseOnSelect: false
                },
                ...accountDropdownOptions.map(({ Component, label, path, onClick }) => {
                  if (Component) {
                    return {
                      key: label,
                      label: <Component key={label} />,
                      onAction: onClick,
                      shouldCloseOnSelect: false
                    }
                  }
                  return {
                    key: label,
                    label: label,
                    onAction: () => history.push(path)
                  }
                })
              ]}
            >
              {(onPress) => (
                <DropdownContent onClick={onPress}>
                  <ProfileCard
                    onlineStatus={online ?? true}
                    name={user.name}
                    teamName={team.name}
                    githubUsername={me?.githubUsername}
                  />
                </DropdownContent>
              )}
            </ContextMenu>
          </UserMenuLauncher>
        </Sidebar>
        <Content />
      </FixedColumns>
    </TopLevelPortal>
  )
})
