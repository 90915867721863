import {
  useState,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent
} from 'react'
import styled from 'styled-components'
import { Transitions } from '../animations/transitions'
import { Colors } from '../colors'
import { inputStyles } from '../inputs'
import { Spacing } from '../layout'
import { fontSizes } from '../typography'
import { Pencil } from './Icons'

const TextArea = styled.textarea<{ height: string }>`
  background-color: ${Colors.white};
  box-shadow: none;
  padding: ${Spacing.basePadding} ${Spacing.baseAndHalfPadding};
  margin-top: ${Spacing.basePadding};
  border-radius: 5px;
  border: ${inputStyles.border};
  border-color: transparent;
  font-size: ${fontSizes.type16};
  font-weight: 600;
  width: calc(100% + ${Spacing.baseAndHalfPadding} + ${Spacing.baseAndHalfPadding});
  margin-left: -${Spacing.baseAndHalfPadding};
  min-height: ${Spacing.basePadding5x};
  box-sizing: border-box;
  transition: ${Transitions.baseEase};
  resize: none;
  height: ${({ height }) => height};

  &::placeholder {
    color: ${Colors.pigeon600};
  }
  &::selection {
    background: ${Colors.brandSecondary};
    color: white;
  }

  &:hover {
    border-color: ${Colors.pigeon400};
    cursor: text;
  }

  &:focus {
    border-color: ${Colors.brandPrimary};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

const PlacholderTextWrapper = styled.div<{
  hovered?: boolean
  focused?: boolean
  visible: boolean
}>`
  pointer-events: none;
  position: absolute;
  top: 17px;
  left: 1px;
  right: 1px;
  bottom: 0;
  svg {
    vertical-align: top;
    margin-left: 4px;
    transition: ${Transitions.baseEase};
    path {
      transition: ${Transitions.baseEase};
    }
  }
  ${({ hovered }) =>
    hovered &&
    `
    svg path {
        fill: #000
      }
    `}
  ${({ focused }) =>
    focused &&
    `
    svg {
        opacity: 0;
    }
    `}

  ${({ visible }) =>
    visible &&
    `
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
      padding: ${Spacing.basePadding} ${Spacing.baseAndHalfPadding};
      margin-top: ${Spacing.basePadding};
    `}
`

const PlaceholderTextArea = styled.span<{ visible: boolean }>`
  white-space: pre-wrap;
  font-size: ${fontSizes.type16};
  font-weight: 600;
  word-break: break-word;
  color: ${({ visible }) => (visible ? 'black' : 'transparent')};
`

const AutoTextAreaWrapper = styled.div<{ minHeight?: string }>`
  min-height: ${({ minHeight }) => minHeight};
  position: relative;
`

export const AutoResizingTextArea = ({
  onChange,
  autoSave,
  value,
  placeholder,
  readonly = false
}: {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  autoSave: (e?: FocusEvent<HTMLTextAreaElement, Element>) => void
  value: string
  placeholder: string
  readonly?: boolean
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [text, setText] = useState('')
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [textAreaHeight, setTextAreaHeight] = useState('auto')
  const [parentHeight, setParentHeight] = useState('auto')

  useEffect(() => {
    if (textAreaRef.current) {
      setParentHeight(`${textAreaRef.current.scrollHeight ?? 20}px`)
      setTextAreaHeight(`${textAreaRef.current.scrollHeight ?? 20}px`)
    }
  }, [text])

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaHeight('auto')
      if (textAreaRef.current) {
        setParentHeight(`${textAreaRef.current.scrollHeight ?? 20}px`)
      }
      setText(event.target.value)
      if (onChange !== undefined) {
        onChange(event)
      }
    },
    [text]
  )

  const onKeyDownHandler = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (autoSave) {
          autoSave()
        }
      }
    },
    [text]
  )

  const onMouseEnter = useCallback(() => {
    return setHover(true)
  }, [hover])

  const onMouseOut = useCallback(() => {
    return setHover(false)
  }, [hover])

  const onFocus = useCallback(() => {
    setFocus(true)
  }, [focus])

  const onBlur = (e: FocusEvent<HTMLTextAreaElement, Element>) => {
    setHover(false)
    setFocus(false)
    autoSave(e)
  }

  return (
    <AutoTextAreaWrapper minHeight={parentHeight}>
      {!readonly && (
        <TextArea
          ref={textAreaRef}
          rows={1}
          height={textAreaHeight}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          onFocus={onFocus}
          onMouseEnter={onMouseEnter}
          onMouseOut={onMouseOut}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
        />
      )}
      <PlacholderTextWrapper visible={readonly} hovered={hover} focused={focus}>
        <PlaceholderTextArea visible={readonly}>
          {value.length ? value : 'Untitled'}
        </PlaceholderTextArea>
        {!readonly && <Pencil />}
      </PlacholderTextWrapper>
    </AutoTextAreaWrapper>
  )
}

export default AutoResizingTextArea
