export function submitForm(form: HTMLFormElement) {
  const hiddenSubmitButton = document.createElement('input')
  Object.assign(hiddenSubmitButton, {
    hidden: true,
    type: 'submit'
  })
  form.appendChild(hiddenSubmitButton)
  hiddenSubmitButton.click()
  form.removeChild(hiddenSubmitButton)
}
