import { useCallback } from 'react'

import { useToasts } from 'react-toast-notifications'
import { getUserContext } from 'src/contexts/UserContext'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { ContentHeader } from 'src/resources/elements/Header'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_EDIT_USER } from 'src/smart/mutations/SM_EDIT_USER'

interface UserFormData {
  email: string
  name: string
}

export const AccountInfoPanel = () => {
  const { addToast } = useToasts()
  const {
    value: { user },
    setValue: setUserContext
  } = getUserContext()

  const AccountInfoForm: TForm<UserFormData> = Form

  const updateUser = useSmartMutation(SM_EDIT_USER)

  const handleSubmit = useCallback(
    async (event: IFormSubmitEvent<UserFormData>): Promise<void> => {
      if (!event.data.name) {
        event.formContext.setValue({
          errors: {
            name: 'Name must not be empty'
          }
        })
        return
      }
      try {
        const response = await updateUser.run({
          id: user.id,
          name: event.data.name
        })
        setUserContext({
          user: {
            ...user,
            name: response.name
          }
        })
        addToast('Successfully updated profile', {
          appearance: 'success',
          autoDismiss: true
        })
      } catch (e) {
        addToast('Failed to update Name', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    },
    [user, setUserContext, addToast]
  )

  return (
    <AccountInfoForm initialValue={user} onSubmit={handleSubmit}>
      <SubContentContainer>
        <ContentHeader header='User profile' />
        <Input label='Name' name='name' placeholder='Jane Smith' />
        <ErrorMessage name='name' />
        <Input disabled label='Email' name='email' placeholder='jane@smith.com' />
        <ButtonGroup left>
          <FormButton submit>Save changes</FormButton>
        </ButtonGroup>
      </SubContentContainer>
    </AccountInfoForm>
  )
}
