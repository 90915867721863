import { ReactElement, useCallback } from 'react'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'

import ReactTooltip from 'react-tooltip'
import { useEditOrganizationModal } from 'src/applications/Oversight/hooks/useEditOrganizationModal'
import { GetAdminOrganizations_getOrganizationsForTeam } from 'src/queries/types/GetAdminOrganizations'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { ClickableTR, NewStyledTable } from 'src/resources/elements/Table'
import { Spacing } from 'src/resources/layout'
import { IOrganizationStub } from 'src/types/interfaces/IOrganization'
import { renderDate } from 'src/utils/date'
import styled from 'styled-components'
import { SM_DELETE_ORGANIZATION } from 'src/smart/mutations/SM_DELETE_ORGANIZATION'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > a {
    margin: 0 ${Spacing.basePadding};
    background-color: transparent;
    box-shadow: none;
    opacity: 0.8;
    font-weight: 600;

    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }
`

const ActionButon = styled(FlatButton)`
  min-width: 16px;
  display: table-cell !important;
  margin: 0 4px;
`

export const OrganizationListDataPanel = ({
  children,
  organizations,
  onOrganizationUpdate
}: {
  children: ReactElement
  organizations: GetAdminOrganizations_getOrganizationsForTeam
  onOrganizationUpdate?: () => void
}) => {
  return (
    organizations.data.length > 0 && (
      <>
        <PageHeaderContainer header='Organizations' children={children} />
        <NewStyledTable>
          <thead>
            <tr>
              <td>Name</td>
              <td align='right'>Created at</td>
            </tr>
          </thead>
          <tbody>
            {organizations?.data?.map((organization: IOrganizationStub) => {
              return (
                <ClickableTR key={organization.id}>
                  <td>{organization.name}</td>
                  <td align='right'>{renderDate(organization.createdAt)}</td>
                  <td>
                    <OrganizationActions
                      organization={organization}
                      onOrganizationUpdate={onOrganizationUpdate}
                    />
                  </td>
                </ClickableTR>
              )
            })}
          </tbody>
        </NewStyledTable>
      </>
    )
  )
}

const OrganizationActions = ({
  organization,
  onOrganizationUpdate
}: {
  organization: IOrganizationStub
  onOrganizationUpdate?: () => void
}) => {
  const editOrganizationModal = useEditOrganizationModal(organization, onOrganizationUpdate)
  const deleteOrganization = useSmartMutation(SM_DELETE_ORGANIZATION)

  const onDelete = useCallback(async () => {
    await deleteOrganization.run({
      id: organization.id
    })
    deleteOrganizationModal.close()
    onOrganizationUpdate()
  }, [organization.id])

  const deleteOrganizationModal = useConfirmModal({
    header: 'Are you sure you want to delete organization?',
    onConfirm: onDelete
  })

  return (
    <ActionsContainer>
      <ActionButon
        renderAs='a'
        color='secondary'
        variant='empty'
        onClick={editOrganizationModal.open}
      >
        Edit
      </ActionButon>
      <ActionButon
        renderAs='a'
        color={organization.deletable ? 'danger' : 'secondary'}
        variant='empty'
        data-tip='Organization has active workspaces and cannot be deleted.'
        data-for={`undeletable-info-${organization.id}`}
        onClick={() => {
          if (!organization.deletable) return
          deleteOrganizationModal.open()
        }}
      >
        Delete
      </ActionButon>
      {!organization.deletable && (
        <ReactTooltip
          id={`undeletable-info-${organization.id}`}
          place='top'
          type='error'
          effect='float'
        />
      )}
      {editOrganizationModal.render()}
      {deleteOrganizationModal.render()}
    </ActionsContainer>
  )
}
