import { Colors, ColorUtils } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'

export const SuccessFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      default:
        return css`
          background-color: ${Colors.darkGreen} !important;
          color: ${Colors.white} !important;
          box-shadow: 0 1px 8px -2px ${ColorUtils.hexToRgba(Colors.brandPrimary, 0.3)},
            0 2px 12px -3px ${ColorUtils.hexToRgba(Colors.brandPrimary, 0.2)};

          &:hover {
            background-color: ${ColorUtils.hoverColor(Colors.darkGreen)} !important;
          }
        `
    }
  }}
`
