import { useEffect, useMemo } from 'react'

import { FLATFILE_REFINERY_URL } from 'src/config'
import { useFetch } from 'src/resources/hooks/useFetch'
import { Spacing } from 'src/resources/layout'
import styled, { css } from 'styled-components'

const FullHeight = styled.div`
  height: 100%;
  overflow: auto;
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: ${Spacing.basePadding};
  }

  tr:nth-child(odd) {
    background: rgba(255, 255, 255, 0.2);
  }
`

interface ApiModule {
  name: string
  imports: string[]
  [key: string]: any
}

type ApiModulesByName = Record<string, ApiModule>

const Padded = styled.div`
  padding: ${Spacing.basePadding2x};
`

const Tag = styled.div<{ error?: boolean; clickable?: boolean }>`
  display: inline-block;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  margin: 2px;
  padding: 2px 4px;

  ${({ error }) =>
    error
      ? css`
          background: rgba(255, 100, 100);
        `
      : null}

  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
          text-decoration: underline;

          &:hover {
            opacity: 0.8;
          }
        `
      : null}
`

function RenderObjects({
  name,
  obj
}: {
  name?: string
  obj: string | { [key: string]: any } | string[]
}): JSX.Element {
  if (Array.isArray(obj)) {
    return (
      <>
        {obj.map((x, i) => (
          <RenderObjects name={name ? `${name}.${i}` : ''} key={i} obj={x} />
        ))}
      </>
    )
  }
  if (obj === null) {
    return (
      <Tag>
        {name ? `${name}:` : ''}
        null
      </Tag>
    )
  }
  if (typeof obj === 'object') {
    return (
      <>
        {Object.keys(obj).map((x, i) => (
          <RenderObjects name={`${name ? `${name}.` : ''}${x}`} obj={obj[x]} key={i} />
        ))}
      </>
    )
  }
  const onClick =
    typeof obj === 'string' && obj.endsWith('Module')
      ? () => {
          const elem = document.getElementById(`debug-scroll-${obj}`)
          console.log(elem)
          if (elem) {
            elem.scrollIntoView({ behavior: 'smooth' })
          }
        }
      : null
  return (
    <Tag clickable={onClick !== null} onClick={onClick}>
      {name ? `${name}:` : ''}
      {obj}
    </Tag>
  )
}

export function DeveloperMenuToolApiModules() {
  const [makeRequest, response] = useFetch<void, ApiModule[]>(() => [
    `${FLATFILE_REFINERY_URL}/debug/modules`,
    {}
  ])

  useEffect(() => makeRequest(), [])

  const sortOrder = useMemo(
    () =>
      (response?.data ?? [])
        .map(({ name }, index) => [name, index] as [string, number])
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([_, index]) => index),
    [response?.data]
  )

  const modulesByName = useMemo(() => {
    const bucket: ApiModulesByName = {}
    response?.data?.forEach((module) => {
      bucket[module.name] = module
    })
    return bucket
  }, [response?.data])

  if (!response || typeof response.status !== 'number') {
    return <Padded>Loading</Padded>
  }

  if (response.status < 200 || response.status >= 300 || !Array.isArray(response.data)) {
    return <Padded>Error: {response.status}</Padded>
  }

  return (
    <FullHeight>
      <Table>
        <tbody>
          {sortOrder.map((index) => {
            const module = response.data[index]

            return (
              <tr key={index} id={`debug-scroll-${module.name}`}>
                <td>{module.name}</td>
                <td>
                  {Object.keys(module)
                    .filter((x) => x !== 'name')
                    .map((key, i) => {
                      const circularImports: string[][] =
                        key === 'imports' ? findCircles(modulesByName, module.name) : []
                      return (
                        <div key={i}>
                          <p>{key}</p>
                          <div>
                            {/*{JSON.stringify(module[key])}*/}
                            <RenderObjects obj={module[key]} />
                            {key === 'imports' ? (
                              <CircularImports circularImports={circularImports} />
                            ) : null}
                          </div>
                        </div>
                      )
                    })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </FullHeight>
  )
}

function CircularImports({ circularImports }: { circularImports: string[][] }) {
  return (
    <>
      {circularImports.map((circularImport, i) => {
        return (
          <div key={i}>
            ⭕️
            {circularImport.map((moduleName, j) => {
              if (j < circularImport.length - 1) {
                return (
                  <>
                    <Tag>{moduleName}</Tag> ⬅{' '}
                  </>
                )
              }
              return (
                <Tag key={j} error>
                  {moduleName}
                </Tag>
              )
            })}
          </div>
        )
      })}
    </>
  )
}

const CIRCLE_EXCLUDE_START_WITH = ['Bull', 'Config', 'MailerCore', 'Raven']

function walkUntilCircleFound(
  modulesByName: ApiModulesByName,
  startAt: string,
  pathPrefix: string[],
  seenModules?: Set<string>
): string[] {
  if (
    !(startAt in modulesByName) ||
    CIRCLE_EXCLUDE_START_WITH.some((x) => startAt.startsWith(x))
  ) {
    return []
  }
  if (!seenModules) {
    seenModules = new Set<string>(pathPrefix)
  }
  const module = modulesByName[startAt]
  for (const importName of module.imports) {
    if (seenModules.has(importName)) {
      if (CIRCLE_EXCLUDE_START_WITH.some((x) => importName.startsWith(x))) {
        return []
      }
      return pathPrefix.concat([importName])
    }
  }
  return module.imports
    .map((importName) =>
      walkUntilCircleFound(modulesByName, importName, pathPrefix.concat([importName]))
    )
    .find((x) => x)
}

function findCircles(modulesByName: ApiModulesByName, startAt: string): string[][] {
  const { imports } = modulesByName[startAt]
  return imports
    .map((importName) => walkUntilCircleFound(modulesByName, importName, [startAt, importName]))
    .filter((x) => x.length > 0)
}
