import { ReactNode, useEffect, useRef } from 'react'

import styled from 'styled-components'

const FitContainer = styled.div`
  max-width: 100%;
  overflow: hidden;

  div {
    transform-origin: left center;
    display: inline-block;
  }
`

export function AutoFit({
  children,
  height = false,
  width = false
}: {
  children: ReactNode
  height?: boolean
  width?: boolean
}) {
  const scaleRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (!scaleRef.current) {
      return
    }
    if (width) {
      scaleRef.current.style.transform = `scale(${Math.min(
        1,
        scaleRef.current.parentElement.clientWidth / scaleRef.current.clientWidth
      )})`
    }
    if (height) {
      scaleRef.current.style.transform = 'scale(0.5)'
    }
  }, [children, scaleRef])
  return (
    <FitContainer>
      <div ref={scaleRef}>{children}</div>
    </FitContainer>
  )
}
