import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import EmbedLockIcon from 'src/resources/icons/EmbedLock.svg'
import { Transitions } from 'src/resources/animations/transitions'
import styled, { css } from 'styled-components'

export const FooterButton = styled.button<{ embeds?: boolean }>`
  width: ${themed('footerButtonWidth')};
  height: ${themed('footerButtonHeight')};
  font-family: inherit !important;
  text-align: center;
  cursor: pointer;
  color: white;
  border: none;
  transition: ${Transitions.baseEaseInOut};
  border: ${themed('borderWidth')} solid transparent;
  background: ${themed('primary')};
  border-radius: ${themed('borderRadius')};
  line-height: ${themed('buttonLineHeight')};
  font-size: ${themed('buttonSize')};
  font-weight: ${themed('buttonWeight')};
  letter-spacing: ${themed('buttonLetterSpacing')};
  padding: ${themed('buttonPadding')};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #eaf0f6;
      color: #99acc2;
      cursor: not-allowed;

      & > span {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 4px;
          left: -18px;
          width: 12px;
          height: 12px;
          background-image: url(${EmbedLockIcon});
          background-size: 12px;
        }
      }
    `}
`

export const Footer = styled.footer<{ boxShadow?: boolean; disabled?: boolean; embeds?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${themed('footerBackgroundColor')};
  height: ${themed('footerHeight')};
  min-height: ${themed('footerHeight')};
  max-height: 120px;
  padding: 0 ${themed('footerGutter')};
  display: flex;
  flex-direction: ${themed('footerDirection')};
  justify-content: ${themed('footerAlignment')};
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #5c5c5c;

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${themed('boxShadowSpec')};
    `}
`
