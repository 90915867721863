import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { FormCheckBox } from 'src/resources/elements/form/CheckBox'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Header } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export interface IAcceptWorkspaceInvitationFormData {
  name: string
  email: string
  adminTeam: {
    name: string
  }
  password?: string
  accept?: boolean
}

const AcceptWorkspaceInvitation: TForm<IAcceptWorkspaceInvitationFormData> = Form

const TextWithLink = styled.div<{ fontSize?: string; align?: string; marginTop?: boolean }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : null)};
  align-items: center;
  color: ${Colors.blackLight};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : fontSizes.type13)};
  font-weight: 500;
  padding: ${Spacing.basePadding2x} 0;

  p {
    margin: 0 ${Spacing.halfBasePadding} 0;
  }

  a {
    color: ${Colors.blue};
    font-weight: 600;
  }

  ${({ marginTop }) => marginTop && `margin-top: ${Spacing.basePadding}`};
`

export const AcceptWorkspaceInvitationForm = ({
  data,
  onSubmit
}: {
  data: IAcceptWorkspaceInvitationFormData
  onSubmit: (data: IFormSubmitEvent<IAcceptWorkspaceInvitationFormData>) => void
}) => {
  const {
    name,
    email,
    adminTeam: { name: teamName }
  } = data

  return (
    <AcceptWorkspaceInvitation initialValue={data} onSubmit={onSubmit}>
      <Header>
        <h2>Hi {name ? name : 'there'},</h2>
      </Header>
      <p>
        You've been invited by <b>{teamName}</b> to onboard your data using Flatfile. To continue,
        please set a password &amp; accept the Terms of Service.
      </p>

      <InputGroup>
        <Input label='EMAIL ADDRESS' name='email' initialValue={email} disabled />
        <Input label='Name' name='name' tabIndex={1} />
        <ErrorMessage name='name' />
        <Input
          autoComplete='new-password'
          label='Password'
          name='password'
          type='password'
          placeholder='• • • • • • • •'
          tabIndex={2}
        />
        <ErrorMessage name='password' />
        <FormCheckBox tabIndex={3} type='primary' name='accept'>
          <TextWithLink fontSize={fontSizes.type15}>
            <p>Accept the platform </p>
            <a href='/terms' target='_blank' tabIndex={4}>
              Terms of Service
            </a>
          </TextWithLink>
        </FormCheckBox>
        <ErrorMessage name='accept' />
      </InputGroup>

      <ButtonGroup left>
        <FormButton primary submit tabIndex={5}>
          Create account
        </FormButton>
      </ButtonGroup>
    </AcceptWorkspaceInvitation>
  )
}
