import { useCallback, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'
import { Colors } from 'src/resources/colors'
import DragUploadIcon from 'src/resources/icons/dragUpload.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'
import { join } from 'lodash'
import { useTranslation } from 'react-i18next'

const DropzoneWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
`

const FullScreenOverlay = styled.div<{ isDragActive?: boolean }>`
  &:focus {
    outline: none;
  }
  position: absolute;
  height: 100vh;
  width: 100%;
  transition: 0.5s ease;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  div {
    height: 100%;
    width: 100%;
    border: 1px dotted ${Colors.textAccent};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      height: 100px;
      width: 100px;
    }
  }
  padding: ${Spacing.contentPadding};
  background: ${Colors.gray40};
  opacity: 0.9;
  box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px -6px rgba(0, 0, 0, 0.15),
    0 6px 24px -8px rgba(0, 0, 0, 0.1);
  color: ${Colors.textAccent};
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type32};
  img {
    height: 50px;
    width: 64px;
  }
`

export const Dropzone = ({
  workspaceStatus,
  children,
  handleDrop
}: {
  workspaceStatus?: boolean
  children: JSX.Element | JSX.Element[]
  handleDrop(file: any): any
}) => {
  const { t } = useTranslation()
  const [failedUploads, setFailedUploads] = useState<string[]>([])
  const confirmFailedUploadModal = useConfirmModal({
    showCancelButton: false,
    header: t('components.Dropzone.uploadFailedHeader'),
    onConfirm: () => {
      setFailedUploads([])
      confirmFailedUploadModal.close()
    },
    contents: (
      <>
        {t('components.Dropzone.uploadFailedContent', {
          fileName: join(failedUploads, ', '),
          count: failedUploads.length
        })}
      </>
    )
  })

  const onDrop: DropzoneOptions['onDrop'] = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      setFailedUploads(fileRejections.map((rejection) => rejection.file.name))
      confirmFailedUploadModal.open()
    }
    if (acceptedFiles.length) {
      handleDrop(acceptedFiles)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 1000000000
  })

  return (
    <>
      {workspaceStatus ? (
        <DropzoneWrap {...getRootProps()}>
          {confirmFailedUploadModal.render()}
          {isDragActive && (
            <FullScreenOverlay onClick={open}>
              <input {...getInputProps()} />
              <div>
                <img alt={t('components.Dropzone.dropFileAlt')} src={DragUploadIcon} />
                <p>
                  {t('components.Dropzone.dropFileBeginning')}
                  <br />
                  {t('components.Dropzone.dropFileEnd')}
                </p>
              </div>
            </FullScreenOverlay>
          )}
          {children}
        </DropzoneWrap>
      ) : (
        <> {children}</>
      )}
    </>
  )
}
