import { Dispatch, ReactNode } from 'react'

import { DeveloperMenuConfig } from 'src/applications/Oversight/developer/DeveloperMenuConfig'
import { DeveloperMenuHome } from 'src/applications/Oversight/developer/DeveloperMenuHome'
import { developerMenuToolsRoutes } from 'src/applications/Oversight/developer/tools'
import { Icon } from 'src/resources/elements/Icon'
import { TapArea } from 'src/resources/elements/TapArea'
import { IConfigValuesManager } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

import { DispatchChangeOverride, EmojiIcon, IconWrap, TextWrap } from './DeveloperMenuCommon'
import { DeveloperMenuToolbar, ScrollArea, Spacer } from './DeveloperMenuElements'
import { DeveloperSuperAdmin } from './DeveloperSuperAdmin'
import { DeveloperFeatures } from './tools/DeveloperFeatures'

export interface DeveloperMenuRouteControl {
  config: IConfigValuesManager
  onChangeIsOverride: DispatchChangeOverride
  route: string[]
  setRoute: Dispatch<string[]>
}

export interface DeveloperMenuRoute {
  prefix?: string[]
  toolbar?(control: DeveloperMenuRouteControl): JSX.Element
  contents?(control: DeveloperMenuRouteControl): JSX.Element
}

export const GoUpLevel = ({
  control,
  children
}: {
  control: DeveloperMenuRouteControl
  children: ReactNode
}) => (
  <DeveloperMenuToolbar>
    <TapArea grow onClick={() => control.setRoute(['..'])}>
      <TextWrap>{children}</TextWrap>
      <Spacer />
      <IconWrap>
        <Icon name='angle-double-up' />
      </IconWrap>
    </TapArea>
  </DeveloperMenuToolbar>
)

const Notice = styled.div`
  padding: 1em;
  text-align: center;
`

function You() {
  return (
    <Notice>
      <EmojiIcon>👋</EmojiIcon>
      <p>No content yet, have you considered coding it?</p>
    </Notice>
  )
}

const routes: DeveloperMenuRoute[] = [
  {
    prefix: ['about'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>About</GoUpLevel>
    },
    contents() {
      return <You />
    }
  },
  {
    prefix: ['config'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>Config</GoUpLevel>
    },
    contents(control) {
      return (
        <DeveloperMenuConfig
          config={control.config}
          onChangeIsOverride={control.onChangeIsOverride}
        />
      )
    }
  },
  {
    prefix: ['features'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>Features</GoUpLevel>
    },
    contents() {
      return (
        <DeveloperSuperAdmin>
          <DeveloperFeatures />
        </DeveloperSuperAdmin>
      )
    }
  },
  ...developerMenuToolsRoutes,
  {
    prefix: [],
    contents(control) {
      return <DeveloperMenuHome control={control} />
    }
  }
]

const notFound: DeveloperMenuRoute = {
  contents(): JSX.Element {
    return <p>Not found</p>
  }
}

function startsWith(arr1: string[], arr2: string[]) {
  return arr2.every((segment, index) => arr1[index] === segment)
}

function mergeRoutes(arr1: string[], arr2: string[]) {
  const final = arr1.slice()
  arr2.forEach((segment) => {
    if (segment === '..') {
      final.pop()
    } else {
      final.push(segment)
    }
  })
  return final
}

export const DeveloperMenuRouter = ({ control }: { control: DeveloperMenuRouteControl }) => {
  const matchedRoute = routes.find((route) => startsWith(control.route, route.prefix)) ?? notFound
  const { prefix = [] } = matchedRoute
  const descendControl: DeveloperMenuRouteControl = {
    ...control,
    route: control.route.slice(prefix.length),
    setRoute(newRoute) {
      control.setRoute(mergeRoutes(prefix, newRoute))
    }
  }
  return (
    <>
      {matchedRoute.toolbar?.(descendControl)}
      <ScrollArea>{matchedRoute.contents?.(descendControl)}</ScrollArea>
    </>
  )
}
