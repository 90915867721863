import { Route, Switch } from 'react-router-dom'
import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { WorkspaceActivitiesPanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceActivitiesPanel'
import { WorkspaceOverviewPanel } from 'src/applications/Oversight/panels/Concierge/WorkspaceOverviewPanel'
import { MainWorkspaceScene } from 'src/applications/Oversight/scenes/MainWorkspaceScene'
import { NotFoundPanel } from 'src/applications/Oversight/scenes/NotFoundScene'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

import { WorkspaceUploadScene } from '../../scenes/Concierge/WorkspaceUploadScene'

export const WorkspaceDetailPanel = ({
  workspaceId,
  workspacePath
}: {
  workspaceId: string
  workspacePath: string
}) => {
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, { variables: { workspaceId } })
  return <WorkspaceDetails workspace={workspaceQuery.result} workspacePath={workspacePath} />
}

const WorkspaceDetails = ({
  workspace,
  workspacePath
}: {
  workspace?: IWorkspace
  workspacePath: string
}) => {
  if (!workspace) {
    return QueryAlert({ loading: true })
  }

  const createDataRequestModal = useCreateDataRequestModal(workspace)

  return (
    <>
      {createDataRequestModal.render()}
      <Switch>
        <Route
          path={`${workspacePath}/activities`}
          exact
          render={() => <WorkspaceActivitiesPanel workspace={workspace} />}
        />
        <Route
          path={`${workspacePath}/files`}
          render={() => <WorkspaceOverviewPanel workspace={workspace} />}
        />
        <Route path={`${workspacePath}/uploads/:uploadId`} component={WorkspaceUploadScene} />
        <Route path={workspacePath} component={MainWorkspaceScene} />
        <Route render={() => <NotFoundPanel />} />
      </Switch>
    </>
  )
}
