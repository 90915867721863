import { ReactNode } from 'react'

import { DefaultToastContainer } from 'react-toast-notifications'
import { ToastLayer } from 'src/applications/Oversight/layers'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  z-index: ${ToastLayer};
  position: relative;
  > div {
    overflow: hidden;
  }
`
export const ToastContainer = ({ children, ...props }: { children: ReactNode[] }) => {
  return (
    <StyledContainer>
      <DefaultToastContainer {...props}>{children}</DefaultToastContainer>
    </StyledContainer>
  )
}
