import { Component, ReactNode } from 'react'

import { Link } from 'react-router-dom'
import { LowLayer1 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import arrowDownIcon from 'src/resources/icons/arrow-down.svg'
import arrowRight from 'src/resources/icons/arrow-right.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

import { Portal } from './Portal'

const CustomDropdownMenu = styled.div<{
  noBackgroundArrow?: boolean
}>`
  appearance: none;
  ${(props) =>
    !props.noBackgroundArrow
      ? css`
          background-image: url(${arrowDownIcon});
          box-sizing: border-box;
          background-repeat: no-repeat;
          background-size: 8px;
          background-position: calc(100% - 8px) 50%;
        `
      : null}

  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 5px;
  color: ${Colors.grayLight};
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  line-height: 1;
  min-width: 56px;
  transition: background-color 0.1s linear, padding 0.1s ease;
  position: relative;
  z-index: ${LowLayer1};
`
const DropdownControl = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-bottom: ${Spacing.basePadding2x};

  &:hover > div {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
`

const DropdownPlaceholder = styled.div`
  padding-left: ${Spacing.basePadding};
  padding-right: ${Spacing.basePadding3x};
`

const DropdownHighlight = styled.span`
  background-color: ${Colors.brandBlue};
  border-radius: 5px;
  display: inline-block;
  height: 1px;
  left: 7px;
  opacity: 0;
  position: absolute;
  right: 7px;
  top: 4px;
  transition: all 0.1s linear;
  transform: translateY();
  z-index: 0;
`

const DropdownMenu = styled.div`
  box-sizing: border-box;
  transition: all 0.2s ease;
  background-color: ${Colors.white};
  border-radius: 0.25rem;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
  padding: 4px 0;
  position: absolute;
  width: 230px;

  &:after {
    width: 0;
    height: 0;
    display: inline-block;
    content: '';
    position: absolute;
    margin: auto;
  }
`

const DropdownOption = styled(Link)`
  background-color: rgba(${Colors.brandText}, 0.05);
  color: ${Colors.brandText};
  cursor: pointer;
  display: block;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type14};
  font-weight: 500;
  padding: 8px ${Spacing.basePadding2x};
  position: relative;
  text-decoration: none;
  z-index: ${LowLayer1};
  transition: background-color 0.1s ease, position 0.2s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 4px;
  }

  &:hover ~ span {
    height: 40px;
    opacity: 0.1;
  }

  span {
    transform: translateY(8px);
  }

  &:nth-child(0):hover ~ span {
    transform: translateY(8px);
  }

  &:nth-child(1):hover ~ span {
    transform: translateY(4px);
  }

  &:nth-child(2):hover ~ span {
    transform: translateY(53px);
  }

  &:nth-child(3):hover ~ span {
    transform: translateY(88px);
  }

  &:nth-child(4):hover ~ span {
    transform: translateY(123px);
  }

  &:nth-child(5):hover ~ span {
    transform: translateY(158px);
  }
`

const DropdownSubMenuHeader = styled.div`
  background-image: url(${arrowRight});
  background-color: rgba(${Colors.brandText}, 0.05);
  background-position: calc(100% - 0.5rem) center;
  background-size: 8px;
  background-repeat: no-repeat;
  color: ${Colors.brandText};
  cursor: pointer;
  display: block;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type13};
  margin-bottom: ${Spacing.halfBasePadding};
  padding: ${Spacing.basePadding} ${Spacing.basePadding} 4px 12px;
  position: relative;
  text-decoration: none;
  z-index: ${LowLayer1};
  transition: background-color 0.1s ease, position 0.2s ease;

  &:hover ~ span {
    height: 56px;
    opacity: 0.1;
  }
`

const DropdownMenuTop = styled(DropdownMenu)`
  bottom: calc(100% + 4px);
`

const DropdownMenuBottom = styled(DropdownMenu)`
  top: 100%;

  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-top: none;
    bottom: 100%;
    left: 50%;
  }
`

const DropdownMenuLeft = styled(DropdownMenu)`
  right: 100%;
`

const DropdownMenuRight = styled(DropdownMenu)`
  left: 100%;

  &:after {
    border-left: none;
    border-right: 6px solid white;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    bottom: 100%;
    left: -6px;
    top: 32px;
  }
`

const DropdownSubMenuContainer = styled.div``

const markUpStyles = {
  padding: '0',
  width: 'fit-content'
}

const DropdownMenus = {
  bottom: DropdownMenuBottom,
  left: DropdownMenuLeft,
  right: DropdownMenuRight,
  top: DropdownMenuTop
}

interface Props {
  subNav?: ReactNode
  header?: ReactNode
  open?: boolean
  placeholder?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
  optionType?: string
  placeholderNode?: ReactNode
  subOpen?: boolean
  noBackgroundArrow?: boolean
  dropdownOptions?: {
    label: string
    path?: string
  }[]
}
interface State {
  isOpen: boolean
  isSubOpen?: boolean
  ref: any
}
export class CustomDropdownElement extends Component<Props, State> {
  private dropdownRef: any = null
  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: this.props.open,
      isSubOpen: this.props.subOpen,
      ref: null
    }
  }

  public componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside)
  }

  public handleClickOutside = (event: any) => {
    if (
      this.state.ref &&
      this.dropdownRef &&
      !this.dropdownRef.contains(event.target) &&
      !this.state.ref.contains(event.target)
    ) {
      window.setTimeout(() => {
        this.setState({ isOpen: false, isSubOpen: false })
      })
    }
  }

  public render() {
    const DynamicDropdownMenu = DropdownMenus[this.props.position || 'bottom']

    return (
      <>
        <CustomDropdownMenu noBackgroundArrow={this.props.noBackgroundArrow}>
          <DropdownControl
            ref={(ref) =>
              this.state.ref ||
              this.setState({
                ref
              })
            }
            onClick={() => this.setState({ isOpen: true })}
          >
            {this.props.placeholderNode || (
              <DropdownPlaceholder>{this.props.placeholder}</DropdownPlaceholder>
            )}
          </DropdownControl>

          {this.state.isOpen && (
            <Portal
              children={
                <DynamicDropdownMenu
                  ref={(ref) => {
                    if (ref) {
                      this.dropdownRef = ref
                    }
                  }}
                  {...(this.props.optionType === 'markUp' && {
                    style: markUpStyles
                  })}
                >
                  {this.props.optionType === 'markUp' ? (
                    <>{this.props.children}</>
                  ) : (
                    <>
                      {this.props.header && (
                        <DropdownSubMenuHeader
                          onMouseEnter={() =>
                            !this.state.isSubOpen && this.setState({ isSubOpen: true })
                          }
                        >
                          {this.props.header}
                          {this.props.subNav && this.state.isSubOpen && (
                            <DropdownSubMenuContainer>
                              {this.props.subNav}
                            </DropdownSubMenuContainer>
                          )}
                        </DropdownSubMenuHeader>
                      )}
                      {this.props.dropdownOptions.map((option: any, index: any) => (
                        <DropdownOption key={index} to={option.path}>
                          {option.label}
                        </DropdownOption>
                      ))}
                      <DropdownHighlight />
                    </>
                  )}
                </DynamicDropdownMenu>
              }
              positioner={this.state.ref}
            />
          )}
        </CustomDropdownMenu>
      </>
    )
  }
}
