import { gql } from 'apollo-boost'
import {
  GetSchemaMutationVariables,
  GetSchemaMutationVariablesVariables
} from 'src/smart/queries/types/GetSchemaMutationVariables'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_SCHEMA_MUTATION_VARIABLES: SmartQuery<
  GetSchemaMutationVariablesVariables,
  GetSchemaMutationVariables,
  Record<string, string>
> = {
  query: gql`
    query GetSchemaMutationVariables($mutationId: UUID!) {
      getGraphQLMutationWebhookVariables(id: $mutationId) {
        message
      }
    }
  `,
  extract({ getGraphQLMutationWebhookVariables: { message } }) {
    return JSON.parse(message)
  }
}
