import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Menu, MenuOption } from 'src/applications/Oversight/components/Menu'
import { TeamContext } from 'src/contexts/TeamContext'
import { getUserContext } from 'src/contexts/UserContext'
import { INVITE_TEAM_USER } from 'src/queries/INVITE_TEAM_USER'
import { InviteUser } from 'src/queries/types/InviteUser'
import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { ContentHeader } from 'src/resources/elements/Header'
import { Icon } from 'src/resources/elements/Icon'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_REMOVE_TEAM_USER } from 'src/smart/mutations/SM_REMOVE_TEAM_USER'
import {
  SmartRemoveTeamUserVariables,
  SmartRemoveTeamUser_removeTeamUser
} from 'src/smart/mutations/types/SmartRemoveTeamUser'
import { SQ_TEAM } from 'src/smart/queries/SQ_TEAM'
import { ITeam } from 'src/types/interfaces/ITeam'
import { IUser } from 'src/types/interfaces/IUser'
import styled from 'styled-components'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'

const TeamSection = styled.section`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-left: ${Spacing.gridOffset};
  margin-right: ${Spacing.gridOffset};
`

const TeamMember = styled.div`
  background: ${Colors.white};
  border-radius: 2px;
  border: 1px solid ${Colors.elemBorder};
  color: ${Colors.brandText};
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type16};
  font-weight: 600;
  margin: ${Spacing.basePadding};
  padding: ${Spacing.basePadding4x};
  position: relative;
  text-align: center;

  small {
    color: ${Colors.brandSecondaryText};
    display: block;
    font-weight: 500;
    margin-top: ${Spacing.basePadding};
  }
`

const Container = styled.div`
  margin-bottom: ${Spacing.basePadding2x};
`

const TeamOwnerMark = styled.span`
  color: ${Colors.brandPrimary};
  font-size: ${fontSizes.type12};
  padding: ${Spacing.basePadding};
  text-transform: uppercase;
`

const UserActions = styled.div`
  position: absolute;
  right: ${Spacing.basePadding};
  top: ${Spacing.basePadding};
`

const RemoveUserModalWrapper = styled.div`
  h3,
  strong {
    font-weight: 600;
  }
  p {
    line-height: 1.6;
  }
`

const SendTeamInvitation = ({ teamId }: { teamId: string }) => {
  const [inviteTeamUser] = useMutation<InviteUser['inviteUser']>(INVITE_TEAM_USER)
  const { addToast } = useToasts()

  const InviteTeamUserForm: TForm<{ email: string }> = Form

  return (
    <InviteTeamUserForm
      onSubmit={async (event) => {
        if (!event.data.email || event.data.email.indexOf('@') === -1) {
          event.formContext.setValue({
            errors: { email: 'Please enter a valid email address.' }
          })
          return
        }
        const { email } = event.data
        await inviteTeamUser({ variables: { email, teamId } })
        event.formContext.setValue({ data: { email: '' } })
        addToast(`Invitation email sent to ${email}!`, {
          appearance: 'success',
          autoDismiss: true
        })
      }}
    >
      <ContentHeader
        header='Invite a team member'
        description='Add team members by email address.'
      />
      <Container>
        <Input label='Email address' name='email' />
        <ErrorMessage name='email' />
      </Container>
      <FormButton primary submit>
        Send invitation
      </FormButton>
    </InviteTeamUserForm>
  )
}

function RemoveUserMenuItem(
  user: IUser,
  team: ITeam,
  removeUser: {
    run: (variables: SmartRemoveTeamUserVariables) => Promise<SmartRemoveTeamUser_removeTeamUser>
  },
  addToast: (
    message: string,
    options: { appearance: 'success' | 'error'; autoDismiss: boolean }
  ) => void
): MenuOption {
  return {
    async action() {
      const response = await removeUser.run({
        removeUserId: user.id,
        teamId: team.id
      })
      if (response?.success) {
        addToast(`Successfully removed user ${user.email}`, {
          appearance: 'success',
          autoDismiss: true
        })
        setTimeout(() => window.location.reload(), 250)
        return true
      } else {
        addToast('Failed to remove team user, please try again', {
          appearance: 'error',
          autoDismiss: true
        })
      }
      return false
    },
    confirmationModalContents: (
      <RemoveUserModalWrapper>
        <h3 style={{ margin: 0 }}>Remove team member</h3>
        <p>
          Are you sure? The user <strong>{user.email}</strong> will lose access to the team{' '}
          <strong>{team.name}</strong>, and all associated files and data.
        </p>
      </RemoveUserModalWrapper>
    ),
    label: 'Remove from team'
  }
}

export function TeamMembersPanel() {
  const { user: currentUser } = getUserContext().value
  const team = useContext(TeamContext)
  const { isCollaborator } = useTeamRole()
  const removeUser = useSmartMutation(SM_REMOVE_TEAM_USER)
  const { addToast } = useToasts()

  const teamQuery = useSmartQuery(SQ_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: team.id
    }
  })

  if (teamQuery.alert) {
    return queryAlert(teamQuery)
  }

  const ownerIds = teamQuery.result?.ownerUserIds ?? []

  return (
    <>
      {!isCollaborator && (
        <SubContentContainer>
          <SendTeamInvitation teamId={team.id} />
        </SubContentContainer>
      )}
      <SubContentContainer>
        <ContentHeader header='Team members' />
        <TeamSection key={team.id}>
          {team.users.map((user) => (
            <TeamMember key={user.id}>
              <UserActions>
                {ownerIds.includes(parseInt(user.id, 10)) && <TeamOwnerMark>Owner</TeamOwnerMark>}
                {team.capabilities.isOwner && user.id !== currentUser.id ? (
                  <Menu
                    options={[
                      ...(team.capabilities.mayRemoveUsers
                        ? [RemoveUserMenuItem(user, team, removeUser, addToast)]
                        : [])
                    ]}
                  >
                    <Icon name='ellipsis-v' />
                  </Menu>
                ) : null}
              </UserActions>
              {user.name}
              <small>{user.email}</small>
            </TeamMember>
          ))}
        </TeamSection>
      </SubContentContainer>
    </>
  )
}
