import styled, { css } from 'styled-components'

export const Message = styled.div<{ center?: boolean }>`
  vertical-align: middle;

  ${({ center }) =>
    center
      ? css`
          display: block;
          width: 50%;
          text-align: left;
          margin: 0 auto;
          padding: 4em;
        `
      : css`
          display: inline-block;
          margin-right: 24px;
          h2 {
            font-weight: 600;
            margin-top: 0;
          }
        `}
`
