import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontMonospace, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const GridCell = styled.div`
  display: block;
  margin-right: ${Spacing.basePadding2x};
  min-width: 150px;
  padding: ${Spacing.basePadding2x};
`

export const GridRow = styled.div`
  border-radius: 2px;
  border-spacing: 0;
  border: 1px solid ${Colors.elemBorder};
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: 72px;
  min-width: 320px;
  position: relative;
  width: 100%;

  & > ${GridCell}:last-of-type {
    margin-right: 0;
  }
`

export const GridCellHeader = styled.div`
  color: ${Colors.grayText};
  font-family: ${fontFamily.fontPrimary};
  padding-bottom: ${Spacing.basePadding};
  font-size: ${fontSizes.type13};
  font-weight: 600;
  letter-spacing: 0.15em;
  text-align: left;
  text-transform: uppercase;
  white-space: pre;
`

export const Identifier = styled.span`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  color: ${Colors.grayText};
  font-family: ${fontMonospace};
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  line-height: 14px;
  margin: 0 ${Spacing.basePadding};
  overflow: hidden;
  padding: ${Spacing.halfBasePadding} ${Spacing.basePadding};
  text-overflow: ellipsis;
  vertical-align: 2px;
`
