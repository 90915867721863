import { Fragment, ReactNode, useContext } from 'react'

import NotificationBadge from 'src/applications/Oversight/components/NotificationBadge'
import SectionHeader from 'src/applications/Oversight/components/SectionHeader'
import { BillingPlanContext } from 'src/contexts/BillingPlanContext'
import {
  EndUserLink,
  ExternalLink,
  HelpLaunch,
  LinkClipped,
  LinkFlex,
  LinkGroup,
  NavLinkWithIcon,
  PrimaryLink,
  SidebarLink,
  StyledButton,
  SubExternalLink
} from 'src/resources/elements/SecondarySidebarLinks'
import { openFrontChat } from 'src/resources/sidebars/PlatformSidebar'
import styled from 'styled-components'

import { Colors } from '../colors'
import { NewWindow } from '../elements/Icons'
import { Spacing } from '../layout'
import { fontSizes } from '../typography'

const LinkGroupWrapper = styled(LinkGroup)`
  overflow: auto;
  flex-grow: 1;
`

export const NewBadge = styled.span`
  background-color: ${Colors.brandPrimaryHighlight};
  color: ${Colors.brandPrimary};
  margin-left: ${Spacing.basePadding};
  border-radius: ${Spacing.borderRadius};
  padding: ${Spacing.borderRadius};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: ${fontSizes.type10};
  font-weight: 600;
`

export const ErrorBadge = styled.span`
  background-color: ${Colors.lightRed};
  color: ${Colors.redDark};
  margin-left: ${Spacing.basePadding};
  border-radius: ${Spacing.borderRadius};
  padding: ${Spacing.borderRadius};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: ${fontSizes.type11};
  font-weight: 600;
`

export const Menu = ({
  children,
  endContent,
  onClick,
  sidebarLinks,
  inset
}: {
  children?: ReactNode
  endContent?: ReactNode
  sidebarLinks: SidebarLink[]
  inset?: boolean
  onClick?(): void
}) => {
  const { deactivated } = useContext(BillingPlanContext)

  return (
    <>
      {children}
      <LinkGroupWrapper deactivated={deactivated} inset={inset}>
        {sidebarLinks.map((link, index) => {
          return (
            <Fragment key={index}>
              {link.type === 'heading' && <SectionHeader name={link.value} />}
              {link.type === 'heading-link' && (
                <PrimaryLink to={link.url}>
                  <SectionHeader name={link.value} hideMargin />
                </PrimaryLink>
              )}
              {link.type === 'heading-link-with-icon' && (
                <NavLinkWithIcon to={link.url}>
                  <SectionHeader name={link.value} />
                </NavLinkWithIcon>
              )}
              {link.type === 'title-link' && <PrimaryLink to={link.url}>{link.value}</PrimaryLink>}
              {link.type === 'link' && (
                <PrimaryLink to={link.url}>
                  <LinkFlex>
                    {link.value}
                    {link.hasNotification && <NotificationBadge secondaryBorder />}
                    {!!link.newBadgeLabel && <NewBadge>{link.newBadgeLabel}</NewBadge>}
                  </LinkFlex>
                </PrimaryLink>
              )}
              {link.type === 'link-clipped' && (
                <PrimaryLink
                  // eslint-disable-next-line react/forbid-component-props
                  className={location.pathname.includes(link.url) ? 'active' : undefined}
                  to={link.url}
                  exact
                  title={link.value}
                >
                  <LinkClipped>
                    {link.value}
                    {link.hasNotification && <NotificationBadge secondaryBorder />}
                  </LinkClipped>
                </PrimaryLink>
              )}
              {link.type === 'external-link' && (
                <ExternalLink href={link.url} target='_blank'>
                  <div>{link.value}</div>
                  <NewWindow />
                </ExternalLink>
              )}
              {link.type === 'sub-external-link' && (
                <SubExternalLink href={link.url} target='_blank'>
                  <div>{link.value}</div>
                  <NewWindow />
                </SubExternalLink>
              )}
              {link.type === 'help-launch' && (
                <HelpLaunch onClick={openFrontChat}>{link.value}</HelpLaunch>
              )}

              {link.type === 'button' && (
                <StyledButton onClick={onClick}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    height='16'
                    width='16'
                  >
                    <defs />
                    <path
                      fill='currentColor'
                      d='M12 23.5A11.5 11.5 0 10.5 12 11.513 11.513 0 0012 23.5zM6 11h4.75a.25.25 0 00.25-.25V6a1 1 0 012 0v4.75a.25.25 0 00.25.25H18a1 1 0 010 2h-4.75a.25.25 0 00-.25.25V18a1 1 0 01-2 0v-4.75a.25.25 0 00-.25-.25H6a1 1 0 010-2z'
                    />
                  </svg>
                  <span>{link.value}</span>
                </StyledButton>
              )}
              {link.type === 'end-user-heading' && (
                <SectionHeader name={link.value} hideMargin={true} endUserHeading />
              )}
              {link.type === 'end-user-link' && <EndUserLink link={link} />}
            </Fragment>
          )
        })}
      </LinkGroupWrapper>
      {endContent}
    </>
  )
}
