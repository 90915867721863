import { ColumnConfigProps, RowData, SortOptions } from '@turntable/core'
import { useCallback, useMemo } from 'react'
import { Filter, Validation } from 'src/resources/hooks/useTableFilters'
import { SmartQueryOptions, useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_FETCH_WORKBOOK_ROWS } from 'src/smart/queries/SQ_FETCH_WORKBOOK_ROWS'
import { SQ_GET_RECORDS_BY_SEARCH } from 'src/smart/queries/SQ_GET_RECORDS_BY_SEARCH'
import { IHeaderRow } from 'src/types/interfaces/ITable'
import { Schema } from 'src/utils/data/Schema'
import { DALTransport } from 'src/utils/data/transports/DALTransport'
import { useTableColumnsBySchema } from 'src/utils/tableMethods'
import {
  SmartFetchWorkbookRows_fetchRowsWithSchema,
  SmartFetchWorkbookRowsVariables
} from 'src/smart/queries/types/SmartFetchWorkbookRows'

type IFetchMoreRows = (p: { skip: number; limit: number }) => Promise<RowData[]>

interface IFetchWorkbookRows {
  headers?: IHeaderRow[]
  labels?: ColumnConfigProps[]
  rows?: RowData[]
  firstRow?: any
  totalRows?: number
  accepted?: number
  dismissed?: number
  filtered?: number
  counts?: {
    valid?: number
    invalid?: number
  }
  errors?: Record<string, number>
  fetchMoreRows?: IFetchMoreRows
  refetchRows?: any
  alert: boolean
  review?: number
  loading?: boolean
}

export function useFetchWorkbookRows(
  workbookId: string,
  filter: Filter,
  status: Validation,
  schemaObj: any,
  schemaId: number,
  pageSize: number,
  errorFilter?: string,
  mergeId?: string,
  options?: Partial<
    SmartQueryOptions<SmartFetchWorkbookRowsVariables, SmartFetchWorkbookRows_fetchRowsWithSchema>
  >,
  sortColumn?: number,
  sortOrder?: SortOptions
): IFetchWorkbookRows {
  const data: IFetchWorkbookRows = {
    alert: false
  }
  const {
    result,
    alert: workbookAlert,
    state
  } = useSmartQuery(SQ_FETCH_WORKBOOK_ROWS, {
    fetchPolicy: 'network-only',
    ...options,
    variables: {
      workbookId,
      schemaId,
      limit: pageSize,
      skip: 0,
      filter,
      status,
      errorFilter,
      mergeId,
      sortColumn,
      sortOrder
    }
  })

  const { state: linkedOptionState } = useSmartQuery(SQ_GET_RECORDS_BY_SEARCH, {
    fetchPolicy: 'network-only',
    variables: { schemaId: '', workbookId: '', term: '' },
    skip: true
  })
  const createSearchFunction =
    (schemaId: string) =>
    async (term: string = '') =>
      SQ_GET_RECORDS_BY_SEARCH.extract(
        (
          await linkedOptionState.refetch({
            term,
            workbookId,
            schemaId
          })
        ).data
      ).map((item: { rowId: number; label: string; linkedUniqueValue?: string }) => ({
        label: item.label,
        linkedUniqueValue: item.linkedUniqueValue,
        value: item.rowId
      }))

  Object.assign(data, result)

  data.alert = workbookAlert
  data.firstRow = result?.rows?.[0]
  const schema = new Schema({ ...schemaObj })
  data.rows = useMemo(() => {
    if (result?.rows?.length > 0) {
      return result.rows.map((validatedRow, index) => {
        return DALTransport.fromDAL(schema, validatedRow, index).toTurntableProps()
      })
    }
    return []
  }, [result])

  data.fetchMoreRows = useCallback<IFetchMoreRows>(async ({ skip, limit }) => {
    const { data: fetchMoreRowsResult } = await state.fetchMore({
      variables: {
        workbookId,
        skip,
        limit,
        skipSummary: true
      },
      updateQuery: (existing, { fetchMoreResult }) => {
        if (!fetchMoreResult) return existing
        return Object.assign({}, existing, {
          fetchRowsWithSchema: {
            rows: [
              ...existing.fetchRowsWithSchema.rows?.slice(0 - limit),
              ...fetchMoreResult.fetchRowsWithSchema.rows
            ],
            totalRows: existing.fetchRowsWithSchema.totalRows,
            accepted: existing.fetchRowsWithSchema.accepted,
            review: existing.fetchRowsWithSchema.review,
            submitted: existing.fetchRowsWithSchema.submitted,
            dismissed: existing.fetchRowsWithSchema.dismissed,
            filtered: existing.fetchRowsWithSchema.filtered,
            counts: existing.fetchRowsWithSchema.counts,
            errors: existing.fetchRowsWithSchema.errors
          }
        })
      }
    })
    return fetchMoreRowsResult.fetchRowsWithSchema.rows.map((validatedRow, index) => {
      return DALTransport.fromDAL(schema, validatedRow, skip + index).toTurntableProps()
    })
  }, [])

  data.refetchRows = state.refetch
  data.loading = state.loading
  const { headers, labels } = useTableColumnsBySchema({ ...schemaObj }, createSearchFunction)

  return {
    ...data,
    headers,
    labels
  }
}
