import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const SourceWrap = styled.div<{ inverted?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ inverted }) => (inverted ? Colors.black : Colors.white)};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 600;

  p {
    margin: 0;
    text-align: left;
    &:first-of-type {
      font-size: ${fontSizes.type16};
    }
    &:nth-of-type(2) {
      font-size: ${fontSizes.type15};
      margin-top: ${Spacing.halfBasePadding};
      font-weight: 300;
    }
  }

  img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    margin-right: ${Spacing.basePadding2x};
  }
`

export const Label = styled.p<{ inverted?: boolean }>`
  color: ${({ inverted }) => (inverted ? Colors.black : Colors.white)};
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type14};
  letter-spacing: 0.125em;
  margin-top: ${Spacing.basePadding3x};
  font-weight: 500;
  text-align: center;
`

export const Quote = styled.p<{ inverted?: boolean }>`
  font-size: 1.5rem;
  color: ${({ inverted }) => (inverted ? Colors.black : Colors.white)};
  text-align: center;
  letter-spacing: 0.0125em;
  font-weight: lighter;
  font-family: ${fontFamily.fontPrimary};
  margin: 100px ${Spacing.contentPadding} 48px;
  max-width: 560px;
`

export const Logo = styled.div<{ active?: boolean; inverted?: boolean; visible?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  padding: ${Spacing.basePadding2x} ${Spacing.contentPadding} ${Spacing.contentPadding};
  opacity: ${({ active, visible }) => (active ? '1' : visible ? '0.4' : '0')};

  transition: all 0.5s ease-in-out;
  img {
    filter: ${({ inverted }) => (inverted ? 'invert(1)' : 'none')};
    max-height: 50px;
  }
`

export const Icon = styled.img<{ inverted?: boolean }>`
  max-height: 50px;
  max-width: 50px;
  filter: ${({ inverted }) => (inverted ? 'invert(1)' : 'none')};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
