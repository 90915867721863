import { useContext, useEffect } from 'react'

import queryString from 'query-string'
import { EmptyTableMessage } from 'src/applications/Oversight/components/EmptyState'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { SchemaCard } from 'src/applications/Oversight/components/SchemaCard'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { useAddSchema } from 'src/applications/Oversight/hooks/useAddSchema'
import { useCreateSchemaFromCSV } from 'src/applications/Oversight/hooks/useCreateSchemaFromCSV'
import { useListDataTabs } from 'src/applications/Oversight/hooks/useListDataTabs'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useCreateWelcomeModal } from 'src/applications/Oversight/hooks/useWelcomeModal'
import { EmptyPanel } from 'src/applications/Oversight/panels/EmptyPanel'
import { TableOverflow } from 'src/applications/Oversight/panels/ImportListDataPanel'
import { AvatarCircleInitials } from 'src/resources/elements/Avatar'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { ClickableTR, NewStyledTable } from 'src/resources/elements/Table'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import EmptyIllustration from 'src/resources/images/DatasetsEmpty@2x.png'
import { WorkspaceDataTemplatesSkeleton } from 'src/resources/skeletons/WorkspaceDataTemplatesSkeleton'
import { SQ_SCHEMAS } from 'src/smart/queries/SQ_SCHEMAS'
import { ISchemaStub } from 'src/types/interfaces/ISchema'
import { renderDate } from 'src/utils/date'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

import { SchemaNameAndId } from '../components/SchemaNameAndId'

import {
  EmptyStateBlock,
  EmptyStateContent,
  EmptyTitle
} from 'src/applications/Oversight/components/EmptyState'
import { TeamContext } from 'src/contexts/TeamContext'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { CodeSnippet, InsideCodeSnippetButton } from 'src/resources/elements/CodeSnippet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useToasts } from 'react-toast-notifications'
import { Colors } from 'src/resources/colors'
import { fontSizes } from 'src/resources/typography'

const FlexyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`

const CreatedBy = styled.p`
  margin-left: 0.625rem !important;
`

export const SchemaListPanel = () => {
  const team = useContext(TeamContext)
  const { queryResults: schemasQuery, hasValues, ready } = useListDataTabs(SQ_SCHEMAS)
  const createWelcomeModal = useCreateWelcomeModal({
    headline: 'Welcome to Flatfile',
    copy: "Get ready. You're about to create an intuitive import experience for your customers. The first step is creating a data template."
  })

  const { data, state, pagination } = schemasQuery

  const params: { welcome?: string } = queryString.parse(location.search)

  const addSchema = useAddSchema()
  const createTemplatesFromUI = team.featureFlags?.CREATE_TEMPLATES_FROM_UI

  const defaultToPlatformSDK = team.featureFlags?.PLATFORM_SDK

  useEffect(() => {
    if (params.welcome) {
      createWelcomeModal.open()
    }
  }, [params.welcome])

  const createSchemaFromCSV = useCreateSchemaFromCSV(state.refetch)
  const activeArchivedTabs = useActiveArchivedTabs()

  return (
    <Card>
      {createWelcomeModal.render()}
      {addSchema.render()}
      {!ready && <WorkspaceDataTemplatesSkeleton />}
      {ready &&
        !hasValues &&
        (defaultToPlatformSDK ? (
          <PlatformSDKEmptyState />
        ) : (
          <>
            <EmptyPanel
              header='Managing data templates'
              description={`Data templates are the structure you want incoming data to take on. Traditionally
          this requires templating or custom scripts, but Flatfile transforms data into
          what you need without any heavy lifting from you or from your users.`}
              image={EmptyIllustration}
              imgSize={[320, 490]}
              onClick={addSchema.open}
              buttonLabel='Create a data template'
            />
          </>
        ))}
      {ready && hasValues && (
        <>
          <PageHeaderContainer hasMargin header='Data templates'>
            {createTemplatesFromUI && (
              <>
                {createSchemaFromCSV.uploadButton}
                <FlatButton onClick={addSchema.open}>Add data template</FlatButton>
              </>
            )}
          </PageHeaderContainer>
          {activeArchivedTabs.tabs()}
          {state.loading ? (
            <WorkspaceDataTemplatesSkeleton />
          ) : (
            data && <SchemaList schemas={data} pagination={pagination} />
          )}
        </>
      )}
    </Card>
  )
}

const SchemaList = ({ schemas, pagination }: { schemas: any; pagination: any }) => {
  const archived = useSearchParam.boolean('archived', false)

  return (
    <>
      <SchemaCard.Section>
        <TableOverflow fullHeight>
          <NewStyledTable>
            <thead>
              <tr>
                <td>Name (Id)</td>
                <td>Created by</td>
                <td>Date created</td>
              </tr>
            </thead>
            <tbody>
              {schemas.length === 0 && (
                <tr>
                  <EmptyTableMessage colSpan={6}>
                    {archived
                      ? `You haven't archived any data templates yet. Once you do, they'll appear here.`
                      : `You only have archived data templates at this time.`}
                  </EmptyTableMessage>
                </tr>
              )}
              {schemas.map((schema: any) => (
                <SchemaCardRow key={schema.id} schema={schema} />
              ))}
            </tbody>
          </NewStyledTable>
        </TableOverflow>
      </SchemaCard.Section>
      <ListPagination pagination={pagination} />
    </>
  )
}

const SchemaCardRow = ({ schema }: { schema: ISchemaStub }) => {
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  return (
    <ClickableTR onClick={() => history.push(`${teamRoot}/templates/${schema.id}`)}>
      <OverflowData
        dataTip={schema.name}
        dataFor={schema.name}
        offset={{ top: -20, left: 10 }}
        isTableCell
      >
        <span>
          <SchemaNameAndId schema={schema} />
        </span>
      </OverflowData>
      <td>
        <FlexyDiv>
          <AvatarCircleInitials name={schema.createdBy.name} />
          <CreatedBy>{schema.createdBy.name}</CreatedBy>
        </FlexyDiv>
      </td>
      <td>{renderDate(schema.createdAt)}</td>
    </ClickableTR>
  )
}

const CodeWrapper = styled.div`
  padding: 48px 48px 56px;
`

const PlatformSDKRow = styled.div<{ bottomBorder?: boolean }>`
  border-top: 1px solid #e8edf4;
  display: flex;
  flex-direction: row;
  transition: 0.25s ease;
  ${({ bottomBorder }) => bottomBorder && 'border-bottom: 1px solid #e8edf4;'}
  &:hover {
    background-color: #f7f9fc;
    background-color: rgb(246, 248, 252);
  }
`
const PlatformSDKTitle = styled.div`
  flex: 0 0 225px;
  font-weight: 600;
  padding: 16px 0 14px;
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
  color: #616a7d;
`

const PlatformSDKContent = styled.div`
  flex: 1;
  position: relative;
  padding: 16px 0 14px;
  cursor: pointer;
  i {
    opacity: 0;
    transition: 0.35s ease;
    top: 21px;
  }
  :hover {
    i {
      opacity: 1;
    }
  }
`

const MonoSpaced = styled.span`
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
  color: #090b2b;
`

const PlatfromSDKSubheader = styled.div`
  color: #616a7d;
  padding-bottom: 24px;
`

const HeaderBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  border: #cad0dc 1px solid;
  width: fit-content;
  border-radius: 24px;
  padding: 6px 13px 4px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #404857;
`

const GettingStartedGuide = styled.a`
  margin-top: 24px;
  color: #303744;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: 0.25s ease;
  &:hover {
    color: ${Colors.brandPrimary};
  }
  svg {
    height: 12px;
  }
`

export const PlatfromSDKTitle = styled.h1`
  font-size: ${fontSizes.type28};
  font-weight: 600;
  margin: 0;
`

const PlatformSDKEmptyState = () => {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const environment = useContext(EnvironmentContext)
  const { addToast } = useToasts()
  return (
    <>
      <EmptyStateBlock>
        <EmptyStateContent>
          <HeaderBadge>
            <svg
              width='16'
              height='14'
              viewBox='0 0 16 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              role='img'
              name='Code'
            >
              <path
                d='M6.66683 12.3333L9.3335 1.66663M12.0002 4.33329L14.6668 6.99996L12.0002 9.66663M4.00016 9.66663L1.3335 6.99996L4.00016 4.33329'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
            FOR DEVELOPERS
          </HeaderBadge>

          <EmptyTitle data-testid='empty-state-title'>
            Configure a template to get started
          </EmptyTitle>
          <PlatfromSDKSubheader>
            Below is the information you need to get started. Visit our documentation to learn
            more.
          </PlatfromSDKSubheader>
          <PlatformSDKRow>
            <PlatformSDKTitle>FLATFILE_TEAM_ID:</PlatformSDKTitle>
            <PlatformSDKContent>
              <CopyToClipboard
                text={team.id}
                onCopy={() => {
                  addToast(`Copied team id to clipboard`, {
                    appearance: 'success',
                    autoDismiss: true
                  })
                }}
              >
                <MonoSpaced>
                  {team.id} <InsideCodeSnippetButton name='copy' />
                </MonoSpaced>
              </CopyToClipboard>
            </PlatformSDKContent>
          </PlatformSDKRow>
          <PlatformSDKRow>
            <PlatformSDKTitle>
              FLATFILE_SECRET:
              <br />
              FLATFILE_ACCESS_KEY_ID:
            </PlatformSDKTitle>
            <PlatformSDKContent>
              <FlatButton renderAs='link' to={`${teamRoot}/account/access-keys`}>
                Manage access keys
              </FlatButton>
            </PlatformSDKContent>
          </PlatformSDKRow>
          <PlatformSDKRow bottomBorder={true}>
            <PlatformSDKTitle>FLATFILE_ENV:</PlatformSDKTitle>
            <PlatformSDKContent>
              <CopyToClipboard
                text={environment.slug}
                onCopy={() => {
                  addToast(`Copied environment id to clipboard`, {
                    appearance: 'success',
                    autoDismiss: true
                  })
                }}
              >
                <MonoSpaced>
                  {environment.slug} <InsideCodeSnippetButton name='copy' />
                </MonoSpaced>
              </CopyToClipboard>
            </PlatformSDKContent>
          </PlatformSDKRow>
          <GettingStartedGuide
            href='https://flatfile.com/versioned-docs/3.0/get-started/quickstart/'
            target='_blank'
            rel='noreferrer'
          >
            Getting started guide
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              role='img'
              name='ArrowUpRight'
            >
              <path
                d='M6.6 1H15M15 1V9.4M15 1L1 15'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
          </GettingStartedGuide>
        </EmptyStateContent>
        <CodeWrapper>
          <CodeSnippet width='500px' key={`code-asdf`} copyButton framework={'javascript'}>
            {`import {
  Sheet,
  TextField,
  DateField,
  NumberField,
  Workbook,
} from '@flatfile/configure';

const ExampleSheet = new Sheet('ExampleSheet', {
  firstName: TextField({
    unique: true,
    primary: true,
  }),
  middleName: TextField('Middle'),
  lastName: TextField(),
  age: NumberField(),
  birthdate: DateField(),
});

export default new Workbook({
  name: 'Example Workbook',
  namespace: 'example',
  sheets: {
    ExampleSheet,
  },
});`}
          </CodeSnippet>
        </CodeWrapper>
      </EmptyStateBlock>
    </>
  )
}
