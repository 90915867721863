import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'
import {
  GetWorkspaceCollaborators,
  GetWorkspaceCollaboratorsVariables,
  GetWorkspaceCollaborators_getWorkspaceCollaborators
} from 'src/smart/queries/types/GetWorkspaceCollaborators'

export const SQ_WORKSPACE_COLLABORATORS: SmartQuery<
  GetWorkspaceCollaboratorsVariables,
  GetWorkspaceCollaborators,
  GetWorkspaceCollaborators_getWorkspaceCollaborators
> = {
  query: gql`
    query GetWorkspaceCollaborators($workspaceId: UUID!) {
      getWorkspaceCollaborators(workspaceId: $workspaceId) {
        owner {
          id
          name
          email
          pending
        }
        collaborators {
          id
          name
          email
          pending
        }
      }
    }
  `,
  extract({ getWorkspaceCollaborators }) {
    return getWorkspaceCollaborators
  }
}
