import { ReactNode } from 'react'

import { useStoredState } from 'src/applications/Oversight/hooks/useStoredState'
import { SessionContext } from 'src/contexts/SessionContext'

export const LAST_VISITED_TEAM_STORAGE_KEY = 'lastVisitedTeamId'

export function SessionManager({ children }: { children: ReactNode }) {
  const [activeTeamId, setActiveTeamId] = useStoredState<string>(
    LAST_VISITED_TEAM_STORAGE_KEY,
    undefined
  )
  return (
    <SessionContext.Provider value={{ activeTeamId, setActiveTeamId }}>
      {children}
    </SessionContext.Provider>
  )
}
