import { useContext, useEffect, useState } from 'react'

import { DEFAULT_PAGE_SIZE } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { SessionContext } from 'src/contexts/SessionContext'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import useReactRouter from 'use-react-router'

/*
  This hook can be used anytime a list data view has active/archived values
  to determine if a completely empty state should be rendered or a filtered empty state

  Accepts any smart query typically prefixed with 'SQ'
*/

export const useListDataTabs = (query: any, pageSize?: number) => {
  const sessionContext = useContext(SessionContext)
  const environment = useContext(EnvironmentContext)
  const [ready, setReady] = useState(false)
  const [filterValues, setFilterValues] = useState<{ [key: string]: boolean }>({
    active: false,
    archived: false
  })
  const { location } = useReactRouter()
  const archived = useSearchParam.boolean('archived', false)
  const queryResultsNoArchived = useSmartQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      environmentId: environment?.id,
      teamId: sessionContext.activeTeamId,
      ...paginationFromQuery(location.search, pageSize || DEFAULT_PAGE_SIZE)
    }
  })

  const queryResults = useSmartQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      environmentId: environment?.id,
      teamId: sessionContext.activeTeamId,
      ...paginationFromQuery(location.search, pageSize || DEFAULT_PAGE_SIZE),
      archived
    }
  })

  useEffect(() => {
    if (queryResultsNoArchived.data) {
      const data = queryResultsNoArchived.data
      const hasArchivedValues = data.some((item: any) => item.archived === true)
      const hasActiveValues = data.some((item: any) => item.archived === false)
      setFilterValues({
        archived: hasArchivedValues,
        active: hasActiveValues
      })
      setReady(true)
    }
  }, [queryResultsNoArchived.data])

  const hasValues = Object.values(filterValues).some((filterValue) => filterValue === true)

  return {
    queryResults,
    hasValues,
    ready
  }
}
