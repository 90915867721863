import { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DevRoutes } from 'src/applications/Oversight/routes/DevRoutes'
import { HotLinkRoutes } from 'src/applications/Oversight/routes/HotLinkRoutes'
import { FLATFILE_PRODUCT_HOMEPAGE, LIVE_ENVIRONMENT_SLUG } from 'src/config'

import { SignupRoutes } from './routes/SignupRoutes'
import { TeamRoutes } from './routes/TeamRoutes'
import { WorkspaceInvitationScene } from './scenes/Concierge/WorkspaceInvitationScene'
import { ForgotPasswordScene } from './scenes/ForgotPasswordScene'
import { InviteScene } from './scenes/InviteScene'
import { LoginScene } from './scenes/LoginScene'
import { LogoutScene } from './scenes/LogoutScene'
import { MainScene } from './scenes/MainScene'
import { NotFoundScene } from './scenes/NotFoundScene'
import { RequestAccessScene } from './scenes/RequestAccessScene'
import { ResetPasswordScene } from './scenes/ResetPasswordScene'

const _sanitizeUrl = (url: string): string => {
  const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

  /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
  const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i

  const ALWAYS_FORBIDDEN = /javascript/g

  url = String(url)
  if (url === 'null' || url.length === 0 || url === 'about:blank') return 'about:blank'
  if (url.match(ALWAYS_FORBIDDEN)) return `unsafe`
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url

  return `unsafe`
}

const sanitizeUrl = (unsafeUrl: string): string => {
  return _sanitizeUrl(String(unsafeUrl).trim())
}

const useSanitizeUrlMiddleware = () => {
  const location = window.location
  useEffect(() => {
    const safe = sanitizeUrl(location.search)
    if (safe === 'unsafe') {
      const safeUrl = new URL(`${location.origin}${location.pathname}`)
      window.location.assign(safeUrl)
    }
  }, [location])
}

export const routes = () => {
  useSanitizeUrlMiddleware()
  return (
    <Switch>
      <Route component={MainScene} exact path='/' />

      <Route component={TeamRoutes} path='/a/:teamId/env/:environmentSlug' />
      <Route
        path='/a/:teamId'
        render={({ location }) => {
          // redirect all URLs of the form /a/:teamId/foo/bar
          // to /a/:teamId/env/prod/foo/bar
          const segments = location.pathname.split('/')
          const prefix = segments.splice(0, 3)
          const envUrl = [...prefix, 'env', LIVE_ENVIRONMENT_SLUG, ...segments].join('/')
          return <Redirect to={envUrl} />
        }}
      />

      <Route component={SignupRoutes} path='/account-sign-up' />

      <Route
        path='/sign-up'
        component={(): null => {
          // Check for on-prem
          if (window.location.hostname.includes('flatfile.com')) {
            // Not on prem, redirect to platform signup
            window.location.href = 'https://platform.flatfile.com/account/sign-up'
          } else {
            // On-prem, redirect to /account-sign-up
            window.location.href = window.location.href.replace('/sign-up', '/account-sign-up')
          }
          return null
        }}
      ></Route>

      {/* Workspace invite */}
      <Route component={WorkspaceInvitationScene} exact path='/workspace-invite/:token' />
      {/* Invite */}
      <Route component={InviteScene} exact path='/invite/:inviteToken' />
      {/* Login & logout */}
      <Route component={LoginScene} exact path='/login' />
      <Route component={LogoutScene} exact path='/logout' />
      {/*Password reset */}
      <Route component={ForgotPasswordScene} exact path='/forgot-password' />
      <Route component={ResetPasswordScene} exact path='/reset-password/:resetToken' />
      {/* Terms and privacy */}
      <Route
        path='/terms'
        component={(): null => {
          window.location.href = `${FLATFILE_PRODUCT_HOMEPAGE}/terms`
          return null
        }}
      />
      <Route
        path='/privacy'
        component={(): null => {
          window.location.href = `${FLATFILE_PRODUCT_HOMEPAGE}/privacy`
          return null
        }}
      />
      <Route component={HotLinkRoutes} path='/h' />
      <Route component={DevRoutes} path='/dev' />
      <Route component={RequestAccessScene} exact path='/request-access' />
      <Route component={NotFoundScene} />
    </Switch>
  )
}
