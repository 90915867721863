import { useCallback, useMemo, useRef } from 'react'

import { useArray } from 'react-hanger/useArray'
import { mergeIdArrays } from 'src/utils/array'

export function useIdArrayMap<T>(initialValue: [string, T][]) {
  const timeout = useRef<number>()
  const array = useArray<[string, T]>(initialValue)
  const map = useMemo(() => new Map(array.value), [array.value])
  const updates = useRef<[string, T][]>([])

  const settle = useCallback(() => {
    // here, we'll merge all updates with the same ids
    array.setValue(mergeIdArrays(array.value, updates.current))
  }, [array, array, updates])

  const updateById = useCallback(
    (id: string, update: T) => {
      clearTimeout(timeout.current)
      timeout.current = window.setTimeout(settle)
      updates.current.push([id, update])
    },
    [updates]
  )

  return { array, map, updateById }
}
