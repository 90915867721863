import { createContext, Dispatch, ReactNode, useState } from 'react'

interface ITableValidationStateProps {
  validatingTable: boolean
  setValidatingTable: Dispatch<boolean>
}

const TableValidationContext = createContext<ITableValidationStateProps>({
  validatingTable: false,
  setValidatingTable: undefined
})

const TableValidationStateProvider = ({ children }: { children: ReactNode }) => {
  const [validatingTable, setValidatingTable] = useState(false)
  return (
    <TableValidationContext.Provider value={{ validatingTable, setValidatingTable }}>
      {children}
    </TableValidationContext.Provider>
  )
}

export { TableValidationContext, TableValidationStateProvider }
