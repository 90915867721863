import { useCallback, useContext, useMemo, useState } from 'react'

import { RowData, ColumnConfigProps } from '@turntable/core'
import { Link } from 'react-router-dom'
import { Table } from 'src/applications/Oversight/components/Table'
import { EditSchemaMutationForm } from 'src/applications/Oversight/components/EditSchemaMutationForm'
import { useTabs } from 'src/applications/Oversight/hooks/useTabs'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Icon } from 'src/resources/elements/Icon'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_UPDATE_SCHEMA_MUTATION } from 'src/smart/mutations/SM_UPDATE_SCHEMA_MUTATION'
import { SQ_GET_KNOWN_GRAPHQL_URIS } from 'src/smart/queries/SQ_GET_KNOWN_GRAPHQL_URIS'
import { SQ_GET_SCHEMA_MUTATION_LOGS } from 'src/smart/queries/SQ_GET_SCHEMA_MUTATION_LOGS'
import { ISchema } from 'src/types/interfaces/ISchema'
import { ISchemaMutation } from 'src/types/interfaces/ISchemaMutation'
import { ISchemaMutationLog } from 'src/types/interfaces/ISchemaMutationLog'
import styled from 'styled-components'
import { ESchemaTab } from 'src/types/enums/ESchemaTab'

const Container = styled.div`
  height: 100%;
  overflow: hidden;

  p {
    margin: ${Spacing.basePadding2x};
  }
`

const TableWrapper = styled.div`
  height: 621px;
  margin-right: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
`

const DetailOverlay = styled.div`
  background: ${Colors.white};
  inset: 0;
  overflow: auto;
  padding: ${Spacing.basePadding2x};
  position: absolute;
  z-index: 200;
`

function EditSchemaMutation({
  mutation,
  onSchemaIdUpdate
}: {
  mutation: ISchemaMutation
  onSchemaIdUpdate(id: number | string, tab: string): void
}) {
  const team = useContext(TeamContext)
  const updateSchemaMutation = useSmartMutation(SM_UPDATE_SCHEMA_MUTATION)

  const knownGraphQLURIs = useSmartQuery(SQ_GET_KNOWN_GRAPHQL_URIS, {
    variables: {
      teamId: team.id,
      archived: false
    }
  })

  const graphQLURIs = useMemo(
    () => knownGraphQLURIs.result ?? ([] as string[]),
    [knownGraphQLURIs.result]
  )

  const submit = useCallback(
    async (event) => {
      event.preventDefault()
      const updatedMutation = await updateSchemaMutation.run({ update: event.data })
      onSchemaIdUpdate(updatedMutation.schemaId, ESchemaTab.GraphQLMutationHooks)
    },
    [onSchemaIdUpdate, updateSchemaMutation]
  )

  return (
    <EditSchemaMutationForm knownGraphQLURIs={graphQLURIs} mutation={mutation} onSubmit={submit} />
  )
}

const mutationLogTableColumns: (ColumnConfigProps & {
  extract: (log: ISchemaMutationLog) => string
})[] = [
  {
    value: 'Successful',
    width: 80,
    extract(log) {
      return log.success ? 'Yes' : 'No'
    }
  },
  {
    value: 'Workspace',
    extract(log) {
      return log.workspace.name
    }
  },
  {
    value: 'Timestamp',
    width: 220,
    extract(log) {
      return new Date(parseInt(log.timestampRequestStart, 10)).toUTCString()
    }
  },
  {
    value: 'Duration',
    width: 80,
    extract(log) {
      return `${log.requestDuration}ms`
    }
  },
  {
    value: 'Request',
    width: 300,
    extract(log) {
      return `${log.requestMethod} ${log.requestUrl}`
    }
  },
  {
    value: 'Status',
    width: 80,
    extract(log) {
      return log.responseStatusCode.toString(10)
    }
  },
  {
    value: 'Response body',
    width: 300,
    extract(log) {
      return log.responseBody
    }
  },
  {
    value: 'Error message',
    width: 300,
    extract(log) {
      return log.errorMessage ?? '-'
    }
  }
]

function MutationLogDetail({
  mutationLog,
  onClose
}: {
  mutationLog: ISchemaMutationLog
  onClose: () => void
}) {
  const teamRoot = useTeamRootUrl()

  return (
    <DetailOverlay>
      <FlatButton onClick={onClose}>
        <Icon name='angle-double-left' /> Back
      </FlatButton>
      <h4>Workspace</h4>
      <p>
        <Link to={`${teamRoot}/workspaces/${mutationLog.workspace.id}`}>
          {mutationLog.workspace.name}
        </Link>
      </p>
      {mutationLog.requestHeaders && (
        <>
          <h4>Request headers</h4>
          <p>{JSON.stringify(mutationLog.requestHeaders, null, 2)}</p>
        </>
      )}
      <h4>Request</h4>
      <p>
        {mutationLog.requestMethod} {mutationLog.requestUrl} ({mutationLog.requestDuration}ms)
      </p>
      <code>
        <pre>{mutationLog.requestBody}</pre>
      </code>
      {mutationLog.success ? (
        <h4>Success</h4>
      ) : (
        <>
          <h4>Error message</h4>
          <p>{mutationLog.errorMessage}</p>
        </>
      )}
      <h4>Response body</h4>
      <p>{mutationLog.responseBody}</p>
    </DetailOverlay>
  )
}

function SchemaMutationLogs({ mutation }: { mutation: ISchemaMutation }) {
  const logTableHeaders = useMemo(() => {
    return mutationLogTableColumns.map(({ value, width }) => ({ value, width }))
  }, [])
  const mutationLogsQuery = useSmartQuery(SQ_GET_SCHEMA_MUTATION_LOGS, {
    fetchPolicy: 'network-only',

    variables: {
      mutationId: mutation.id,
      skip: 0,
      take: 20
    }
  })

  const mutationTableRows: RowData[] = useMemo(() => {
    if (!mutationLogsQuery.result?.data?.length) {
      return []
    }
    return mutationLogsQuery.result.data.map((log, rowIndex) => ({
      rowIndex: rowIndex + 1,
      position: rowIndex,
      cells: mutationLogTableColumns.map((col) => ({
        value: col.extract(log)
      }))
    }))
  }, [mutationLogsQuery.result])

  const [selectedRow, setSelectedRow] = useState<number>(undefined)

  if (mutationLogsQuery.alert) {
    return queryAlert(mutationLogsQuery)
  }

  const handleClickCell = (row: RowData) => {
    setSelectedRow(row.position)
  }

  return (
    <TableWrapper>
      {mutationTableRows.length > 0 ? (
        <>
          <Table
            readOnly
            columnConfig={logTableHeaders}
            count={mutationTableRows.length}
            initData={mutationTableRows}
            onCellClick={handleClickCell}
          />
          {mutationLogsQuery.result?.data && selectedRow !== undefined && (
            <MutationLogDetail
              mutationLog={mutationLogsQuery.result.data[selectedRow]}
              onClose={() => setSelectedRow(undefined)}
            />
          )}
        </>
      ) : (
        <p>No mutation logs found</p>
      )}
    </TableWrapper>
  )
}

interface MutationDetailTab {
  schema: ISchema
  mutation: ISchemaMutation

  onSchemaIdUpdate(id: number | string, tab: string): void
}

export function SchemaMutationDetail({ schema, mutation, onSchemaIdUpdate }: MutationDetailTab) {
  const tabs = useTabs({
    urlParam: 'detail',
    useUrl: true,
    tabs: [
      {
        id: 'edit',
        label: 'Edit',
        contents(tab: MutationDetailTab) {
          return (
            <EditSchemaMutation mutation={tab.mutation} onSchemaIdUpdate={tab.onSchemaIdUpdate} />
          )
        }
      },
      {
        id: 'logs',
        label: 'Logs',
        contents(tab: MutationDetailTab) {
          return <SchemaMutationLogs mutation={tab.mutation} />
        }
      }
    ]
  })

  if (!tabs.activeTabId) {
    setTimeout(() => tabs.setActiveTabId('edit'))
  }

  return <Container>{tabs.render({ schema, mutation, onSchemaIdUpdate })}</Container>
}
