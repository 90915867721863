export const numberFormatter = (num: number, digits: number): string => {
  const simbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let indicator

  for (indicator = simbols.length - 1; indicator > 0; indicator--) {
    if (num >= simbols[indicator].value) {
      break
    }
  }

  return (
    (num / simbols[indicator].value).toFixed(digits).replace(rx, '$1') + simbols[indicator].symbol
  )
}
