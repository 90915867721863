import { gql } from '@apollo/client'
import {
  SmartGetWorkspaces,
  SmartGetWorkspacesVariables,
  SmartGetWorkspaces_getWorkspaces
} from 'src/smart/queries/types/SmartGetWorkspaces'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_WORKSPACES: SmartQuery<
  SmartGetWorkspacesVariables,
  SmartGetWorkspaces,
  SmartGetWorkspaces_getWorkspaces
> = {
  query: gql`
    query SmartGetWorkspaces(
      $teamId: ID!
      $environmentId: UUID
      $status: Int
      $take: Int
      $skip: Int
    ) {
      getWorkspaces(
        teamId: $teamId
        environmentId: $environmentId
        status: $status
        take: $take
        skip: $skip
      ) {
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
        data {
          id
          name
          status
          createdAt
          updatedAt
          lastActivity
        }
      }
    }
  `,
  extract({ getWorkspaces }) {
    return getWorkspaces
  }
}
