import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const FieldLabel = styled.label`
  color: ${Colors.pigeon800};
  display: block;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type14};
  font-weight: 600;
  margin: ${Spacing.basePadding2x} auto 0;
`

export const RequiredMark = styled.span`
  color: ${Colors.red};
  margin-left: ${Spacing.halfBasePadding};
`

export const HelperText = styled.small`
  color: ${Colors.pigeon700};
  display: block;
  font-size: ${fontSizes.type13};
  letter-spacing: 0;
  margin: ${Spacing.halfBasePadding} 0;
`
