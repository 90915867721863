import { gql } from '@apollo/client'
import {
  SmartTeam,
  SmartTeamVariables,
  SmartTeam_getTeam
} from 'src/smart/queries/types/SmartTeam'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_TEAM: SmartQuery<SmartTeamVariables, SmartTeam, SmartTeam_getTeam> = {
  query: gql`
    query SmartTeam($teamId: ID!) {
      getTeam(id: $teamId) {
        id
        name
        type
        licenses {
          id
          key
        }
        users {
          id
          name
          email
        }
        ownerUserIds
        schemas(archived: false) {
          name
          id
        }
        themes {
          id
          name
          attributes {
            type
            name
            hex
          }
          colors {
            name
            hex
          }
          font {
            typeKitId
            fontFamily
          }
        }
        createdAt
        updatedAt
        testModeOnly
        paymentExpired
      }
    }
  `,
  extract({ getTeam }) {
    return getTeam
  }
}
