import { useRef, useState } from 'react'

import { Link } from 'react-router-dom'
// @ts-ignore
import { Accordion, AccordionItem } from 'react-sanfona'
import { Breadcrumbs } from 'src/applications/Oversight/components/Breadcrumbs'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useUploadFile } from 'src/applications/Oversight/hooks/useUploadFile'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { DownArrowIcon, PaperInkIcon } from 'src/resources/elements/Icons'
import { useParams } from 'src/resources/hooks/useParams'
import { Spacing } from 'src/resources/layout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { getConfigValue, setConfigValue, storedConfigValue } from 'src/utils/localStorageOverride'
import styled from 'styled-components'

const WelcomeBanner = styled.div`
  background: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px -6px rgba(0, 0, 0, 0.15),
    0 6px 24px -8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  padding: 40px 30px;
  margin-top: 30px;
  margin-bottom: 30px;
`

const WelcomeHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
`

const WelcomeText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #49687e;
  max-width: 550px;
`

const ContentWrapper = styled.div`
  padding: 0 15px;
`

const AccordionWrapper = styled(Accordion)`
  background: ${Colors.white};
  border: 1px solid #c6d0dd;
  border-radius: 4px;
  margin-bottom: 20px;

  a {
    text-decoration: underline;
    color: ${Colors.text};
    transition: ${Transitions.baseEase};

    &:hover {
      color: ${Colors.brandPrimary};
    }
  }
`

const AccordionHeaderArrowWrapper = styled.div`
  position: absolute;
  right: ${Spacing.basePadding4x};
  top: ${Spacing.basePadding2x};

  > svg {
    position: relative;
    transform: rotate(-90deg);
    transition: ${Transitions.baseEase};
    z-index: 1;
  }

  &::after {
    content: '';
    height: 40px;
    width: 40px;
    background: #f5f9ff;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: ${Transitions.baseEase};
  }

  .react-sanfona-item-expanded & > svg {
    transform: rotate(0deg);
  }

  .react-sanfona-item-expanded &::after {
    transform: translate(-50%, -50%) scale(1);
    transition-delay: 0.2s;
  }
`

const PaddedTitle = styled.div<{ first?: boolean }>`
  padding: 15px 30px;
  font-weight: 500;
  ${({ first }) => (first ? null : 'border-top: 1px solid #c6d0dd')};
  transition: ${Transitions.baseEase};
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #e1e7ee;

    ${AccordionHeaderArrowWrapper} {
      .react-sanfona-item-expanded &::after {
        transform: translate(-50%, -50%) scale(1.2);
        transition-delay: 0s;
      }
    }
  }
`

const PaddedContent = styled.div`
  padding: 15px 30px;
  border-top: 1px solid #c6d0dd;

  p {
    padding-bottom: ${Spacing.basePadding2x};
  }
`

const NumberIndicator = styled.span`
  color: grey;
  margin-right: 12px;
  transition: ${Transitions.baseEase};

  .react-sanfona-item-expanded & {
    color: #ff8500;
  }
`

const FollowStepsHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
`

const CloseButton = styled.div`
  position: absolute;
  right: ${Spacing.basePadding4x};
  top: ${Spacing.basePadding2x};
  z-index: 1;
  font-size: 30px;
  cursor: pointer;
  color: #889baa;
  transition: ${Transitions.baseEase};

  &::after {
    z-index: -1;
    content: '';
    height: 40px;
    width: 40px;
    background: #f5f9ff;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: ${Transitions.baseEase};
  }

  &:hover {
    color: ${Colors.text};

    &::after {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
`

const welcomeBannerVisibleConfigValue = storedConfigValue('END_USER_WELCOME_BANNER_VISIBLE', 'on')

export const WorkspaceWelcomePanel = () => {
  const { workspaceId } = useParams<{ workspaceId: string; teamId: string }>()
  const teamRoot = useTeamRootUrl()
  const [isWelcomeBannerVisible, setWelcomeBannerVisible] = useState(
    getConfigValue(welcomeBannerVisibleConfigValue) === 'on'
  )

  const dismissWelcomeBanner = () => {
    setConfigValue(welcomeBannerVisibleConfigValue, 'off')
    setWelcomeBannerVisible(false)
  }

  const inputRef = useRef(null)
  const { onUpload, FailedUploadModal } = useUploadFile(workspaceId)

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const createDataRequestModal = useCreateDataRequestModal(workspaceQuery.result)

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      current: true,
      label: 'Welcome'
    }
  ]

  return (
    <>
      {FailedUploadModal.render()}
      {createDataRequestModal.render()}
      <WorkspaceActionBar
        title={<Breadcrumbs links={links} />}
        workspaceId={workspaceId}
        inline={true}
        noMargin={true}
        noPadding={true}
        workspaceActive={true}
      />

      {isWelcomeBannerVisible && (
        <WelcomeBanner>
          <PaperInkIcon />
          <ContentWrapper>
            <WelcomeHeader>Welcome to your data onboarding workspace</WelcomeHeader>
            <WelcomeText>
              This is where we'll help onboard your data. Your data will be uploaded securely using
              Flatfile's encrypted connection.
            </WelcomeText>
          </ContentWrapper>
          <CloseButton onClick={dismissWelcomeBanner}>×</CloseButton>
        </WelcomeBanner>
      )}

      <FollowStepsHeader>Follow these steps to get started.</FollowStepsHeader>

      <AccordionWrapper>
        <AccordionItem
          title={
            <PaddedTitle first>
              <NumberIndicator>1</NumberIndicator>Review the data we need
              <AccordionHeaderArrowWrapper>
                <DownArrowIcon />
              </AccordionHeaderArrowWrapper>
            </PaddedTitle>
          }
          expanded
        >
          <PaddedContent>
            <p>
              For a quick view of the data we need to, check out the{' '}
              <Link to={`${teamRoot}/workspaces/${workspaceId}/data-checklist`}>
                Data Checklist.
              </Link>
            </p>
          </PaddedContent>
        </AccordionItem>
        <AccordionItem
          title={
            <PaddedTitle>
              <NumberIndicator>2</NumberIndicator>Export your Data
              <AccordionHeaderArrowWrapper>
                <DownArrowIcon />
              </AccordionHeaderArrowWrapper>
            </PaddedTitle>
          }
        >
          <PaddedContent>
            <p>Export the data that you want to import. Any CSV, XLS, or TSV accepted.</p>
          </PaddedContent>
        </AccordionItem>
        <AccordionItem
          title={
            <PaddedTitle>
              <NumberIndicator>3</NumberIndicator>Start uploading Files
              <AccordionHeaderArrowWrapper>
                <DownArrowIcon />
              </AccordionHeaderArrowWrapper>
            </PaddedTitle>
          }
        >
          <PaddedContent>
            <p>Go ahead and upload your first file!</p>
            <input
              type='file'
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  onUpload({ file: e.target.files[0] })
                }
              }}
            />
            <FlatButton onClick={() => inputRef.current.click()}>Upload</FlatButton>
          </PaddedContent>
        </AccordionItem>
      </AccordionWrapper>
    </>
  )
}
