import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { inputStyles } from 'src/resources/inputs'
import { EStatusType } from 'src/types/enums/EStatusType'
import styled from 'styled-components'

export const ProgressIndicator = styled.div<{ status?: EStatusType }>`
  border-radius: 0.25rem;
  border: 1px solid transparent;
  display: inline-block;
  float: right;
  font-family: ${inputStyles.fontFamily};
  font-size: ${inputStyles.fontSize};
  font-weight: ${inputStyles.fontWeight};
  font-weight: 500;
  letter-spacing: 0.015em;
  margin-left: 15px;
  padding: ${inputStyles.padding};
  padding: 10px 16px;
  transition: ${Transitions.baseEase};

  ${({ status }) => {
    switch (status) {
      case EStatusType.inProgress:
        return `
          background-color: ${Colors.yellowBlur};
          color: ${Colors.yellow};
        `
      case EStatusType.failed:
        return `
          background-color: ${Colors.red};
          color: ${Colors.white};
        `
      default:
        return ``
    }
  }}
`
