import { gql } from '@apollo/client'

export const INVITE_TEAM_USER = gql`
  mutation InviteUser($teamId: ID!, $email: LowercaseString!) {
    inviteUser(teamId: $teamId, email: $email) {
      id
      token
      email
      role
    }
  }
`
