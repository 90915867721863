import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { ContentHeader } from 'src/resources/elements/Header'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'

export const AccessKeysPanel = () => {
  const teamRoot = useTeamRootUrl()

  return (
    <SubContentContainer>
      <ContentHeader header='Access keys' />
      <FlatButton renderAs='link' to={`${teamRoot}/account/access-keys`}>
        Manage access keys
      </FlatButton>
    </SubContentContainer>
  )
}
