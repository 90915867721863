import { useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import { InactivateAccessKeyForm } from 'src/applications/Oversight/forms/InactivateAccessKeyForm'
import { recycle } from 'src/applications/Oversight/recycle'
import { useInactivateAccessKeyMutation } from 'src/queries/hooks/useInactivateAccessKeyMutation'
import { IModal, useModal } from 'src/resources/elements/Modal'
import { IAccessKey } from 'src/types/interfaces/IAccessKey'
import { grow } from 'src/utils/grow'
import { detach } from 'src/utils/promise'
import { ref } from 'src/utils/ref'

const InactivateAccessKeyModalHead = () => {
  const { addToast } = useToasts()
  const [inactivateSuccess, setInactivateSuccess] = useState(false)
  const [inactivateAccessKey, inactivateAccessKeyStatus] = useInactivateAccessKeyMutation()

  if (!inactivateSuccess && inactivateAccessKeyStatus.data) {
    window.setTimeout(() => {
      setInactivateSuccess(true)
      addToast('Access key deactivated successfully!', {
        appearance: 'success',
        autoDismiss: true
      })
    })
  }

  return { inactivateAccessKey, inactivateAccessKeyStatus }
}

export const useInactivateAccessKeyModal = grow(
  InactivateAccessKeyModalHead,
  (
    { inactivateAccessKey, inactivateAccessKeyStatus },
    { accessKeyToInactivate, onSuccess }: { accessKeyToInactivate?: IAccessKey; onSuccess(): void }
  ) =>
    ref<IModal>((self) =>
      useModal({
        header: 'Deactivate access key',
        contents: () => (
          <>
            <InactivateAccessKeyForm
              accessKey={accessKeyToInactivate}
              onSubmit={async () => {
                await inactivateAccessKey({
                  variables: {
                    accessKeyId: accessKeyToInactivate.accessKeyId
                  }
                })
                onSuccess()
              }}
              onCancel={() => self.current.close()}
            />
          </>
        ),
        onClose: () => {
          if (inactivateAccessKeyStatus.data) {
            detach(recycle())
            return false
          }
          return true
        }
      })
    )
)
