import { gql } from '@apollo/client'

export const CREATE_SCHEMA = gql`
  mutation CreateSchema(
    $name: String!
    $teamId: ID!
    $jsonSchema: JsonSchemaDto
    $environmentId: UUID
  ) {
    createSchema(
      teamId: $teamId
      name: $name
      jsonSchema: $jsonSchema
      environmentId: $environmentId
    ) {
      id
      name
    }
  }
`
