import { ReactNode, useContext, useEffect, useState } from 'react'

import { ErrorBoundary } from '@sentry/react'
import { Route, Switch } from 'react-router'
import Notification from 'src/resources/elements/Notification'
import { TrialNoticeCard } from 'src/applications/Oversight/components/TrialNoticeCard'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { EnvironmentBannerLayer, SidebarLayer } from 'src/applications/Oversight/layers'
import { FLATFILE_IS_CLOUD_ENV, LIVE_ENVIRONMENT_SLUG } from 'src/config'
import { ETeamType } from 'src/contexts/BasicInfoContext'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { superAdminAccessTokenStorage } from 'src/resources/clients/graphClient'
import { Colors } from 'src/resources/colors'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { VariableWidthContentLayout } from 'src/resources/layouts/VariableWidthContentLayout'
import { PlatformSidebar } from 'src/resources/sidebars/PlatformSidebar'
import { WorkspaceSidebar } from 'src/resources/sidebars/WorkspaceSidebar'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'
import { IsParent } from 'src/types/interfaces/IsParent'
import styled, { css } from 'styled-components'
import { fontSizes } from 'src/resources/typography'

const Strong = styled.span`
  font-weight: 600;
`

const ContentWrap = styled.div`
  padding: ${Spacing.basePadding5x};
`

const SidebarWrap = styled.div<{ floating?: boolean }>`
  height: 100vh;
  background-color: ${Colors.gray20};
  overflow: visible;
  padding: ${Spacing.basePadding5x} 0 0 ${Spacing.basePadding5x};
  transition: 0.5s ease box-shadow, 0.5s ease opacity;
  user-select: none;
  z-index: ${SidebarLayer};
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 1;
  }

  ${({ floating }) =>
    floating
      ? css`
          box-shadow: 0 0 ${Spacing.basePadding} ${Colors.blackLight}88,
            0 0 ${Spacing.basePadding2x} ${Colors.gray20};
        `
      : null}
`

const SupportLink = styled.a`
  cursor: pointer;
`

const SidebarFloatControl = ({ children }: IsParent) => {
  const GET = 0
  const SET = 1

  const isFloating = useState(false)

  useEffect(() => {
    function handleScroll() {
      const shouldFloat = window.scrollX > 0
      if (shouldFloat !== isFloating[GET]) {
        isFloating[SET](shouldFloat)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFloating[GET]])

  return <SidebarWrap floating={isFloating[GET]}>{children}</SidebarWrap>
}

const SidebarScrollArea = styled.div`
  height: calc(100vh - 120px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const TestEnvironment = styled.div`
  border-top: 6px solid ${Colors.brandPrimary};
  color: white;
  font-size: ${fontSizes.type12};
  font-weight: 500;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  z-index: ${EnvironmentBannerLayer};

  span {
    background: ${Colors.brandPrimary};
    border-radius: 0 0 ${Spacing.basePadding} ${Spacing.basePadding};
    letter-spacing: 0.125em;
    padding: ${Spacing.halfBasePadding} ${Spacing.baseAndHalfPadding};
  }
`

export const SidebarLayout = ({ children }: { children: ReactNode }) => {
  const teamRoot = useTeamRootUrl()
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const superAdminAccessToken = superAdminAccessTokenStorage.get()
  return (
    <>
      {environment.slug !== LIVE_ENVIRONMENT_SLUG ? (
        <TestEnvironment>
          <span>Developer mode</span>
        </TestEnvironment>
      ) : null}
      <VariableWidthContentLayout
        backgroundColor={Colors.gray20}
        ContentWrap={ContentWrap}
        SidebarWrap={SidebarFloatControl}
        sidebar={
          <SidebarScrollArea>
            <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
              <Switch>
                <Route component={WorkspaceSidebar} path={`${teamRoot}/workspaces/:workspaceId`} />
                <Route component={PlatformSidebar} />
              </Switch>
            </ErrorBoundary>
          </SidebarScrollArea>
        }
      >
        <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
          <>
            {superAdminAccessToken && (
              <Notification
                type='info'
                title='You are currently logged in as super admin.'
                fullWidth
              >
                <SupportLink onClick={UserTransceiver.restoreSuperAdminSession}>
                  <Strong>Click here</Strong>
                </SupportLink>{' '}
                to exit impersonation mode.
              </Notification>
            )}
            {FLATFILE_IS_CLOUD_ENV && team.type === ETeamType.full && <TrialNoticeCard />}
            {children}
          </>
        </ErrorBoundary>
      </VariableWidthContentLayout>
    </>
  )
}
