import { Colors } from 'src/resources/colors'
import { Fill } from 'src/resources/layouts/Fill'
import { fontSizes } from 'src/resources/typography'
import { device } from 'src/resources/utils/mediaQuery'
import styled from 'styled-components'

export const ContentArea = styled(Fill)`
  color: ${Colors.brandText};
  font-size: ${fontSizes.type14};
  box-sizing: border-box;
  position: relative;

  @media screen and ${device.tablet} {
    flex-direction: row;
  }
`
