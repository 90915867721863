import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { useTableFilters } from 'src/resources/hooks/useTableFilters'
import { Validation } from 'src/resources/hooks/useTableFilters'
import styled from 'styled-components'

const ValidationButtonWrapper = styled.div`
  display: flex;
  min-width: 0;
  margin-left: auto;
  position: relative;
`

export const ValidationTableActions = ({
  onMarkStatus,
  selectedRows,
  disabled
}: {
  onMarkStatus: (status: Validation) => void
  selectedRows: (string | number)[]
  disabled: boolean
}) => {
  const [{ validationState }] = useTableFilters()
  const selectedRowText = `${selectedRows.length > 0 ? selectedRows.length : 'All'} ${
    selectedRows.length === 1 ? 'row' : 'rows'
  }`
  return (
    <>
      <ValidationButtonWrapper>
        {validationState !== 'accepted' && (
          <FlatButton disabled={disabled} onClick={() => onMarkStatus('accepted')}>
            Approve {selectedRowText}
          </FlatButton>
        )}
        {validationState === 'accepted' && (
          <FlatButton disabled={disabled} onClick={() => onMarkStatus('review')}>
            Unapprove {selectedRowText}
          </FlatButton>
        )}
        {validationState !== 'dismissed' && (
          <FlatButton
            disabled={disabled}
            margin={{ left: 15 }}
            color='secondary'
            onClick={() => onMarkStatus('dismissed')}
          >
            Dismiss {selectedRowText}
          </FlatButton>
        )}
      </ValidationButtonWrapper>
    </>
  )
}
