import { gql } from '@apollo/client'
import {
  SmartGetSchemas,
  SmartGetSchemasVariables,
  SmartGetSchemas_getSchemas
} from 'src/smart/queries/types/SmartGetSchemas'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_SCHEMAS: SmartQuery<
  SmartGetSchemasVariables,
  SmartGetSchemas,
  SmartGetSchemas_getSchemas
> = {
  query: gql`
    query SmartGetSchemas(
      $archived: Boolean
      $environmentId: UUID
      $includeIds: [String!]
      $search: String
      $skip: Int!
      $take: Int!
      $teamId: ID
      $workspaceId: UUID
    ) {
      getSchemas(
        archived: $archived
        environmentId: $environmentId
        includeIds: $includeIds
        search: $search
        skip: $skip
        take: $take
        teamId: $teamId
        workspaceId: $workspaceId
      ) {
        pagination {
          totalCount
          pageCount
          currentPage
          nextOffset
          offset
          limit
          onPage
          previousOffset
        }
        data {
          archived
          id
          importCount
          name
          createdAt
          updatedAt
          jsonSchema {
            schema
          }
          hasUniques
          previewFieldKey

          createdBy {
            name
          }
          linkedSchemas {
            id
            name
          }
          slug
        }
      }
    }
  `,
  extract({ getSchemas }) {
    return getSchemas
  }
}
