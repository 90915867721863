import { ApolloError, useMutation } from '@apollo/client'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { useCreateWorkspaceModal } from 'src/applications/Oversight/hooks/useCreateWorkspaceModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { WorkspacesListDataPanel } from 'src/applications/Oversight/panels/Concierge/WorkspacesListDataPanel'
import { EmptyPanel } from 'src/applications/Oversight/panels/EmptyPanel'
import { ETeamType } from 'src/contexts/BasicInfoContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { SEND_SALES_FOLLOWUP } from 'src/queries/SEND_SALES_FOLLOWUP'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import EmptyIllustration from 'src/resources/images/DatasetsEmpty@2x.png'
import NonWorkspacesIllustration from 'src/resources/images/Organizations@2x.png'
import { Spacing } from 'src/resources/layout'
import { wrapWith } from 'src/resources/utils/wrapWith'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { useTeamRole } from 'src/applications/Oversight/hooks/useTeamRole'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { Search } from '@flatfile/design-system'
import { useWorkspaceListDataTabs } from 'src/applications/Oversight/hooks/useWorkspaceListDataTabs'
import { GetWorkspacesWithFiltering } from 'src/queries/types/GetWorkspacesWithFiltering'
import { useSearchParam, useUpdateSearchParam } from 'src/resources/hooks/useSearchParam'
import { debounce } from 'lodash'
import { WorkspaceSkeleton } from 'src/resources/skeletons/WorkspaceSkeleton'

const SearchBox = styled.div`
  width: 400px;
  padding-left: ${Spacing.contentPadding};
`

export const WorkspacesListPanel = wrapWith(Card, () => {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const { addToast } = useToasts()
  const { isCollaborator } = useTeamRole()
  const archived = useSearchParam.boolean('archived', false)

  const hasWorkspacesAccess =
    team.type === ETeamType.organizationOnly || team.featureFlags?.PRODUCT_ACCESS_WORKSPACES
  const [searchTerm, setSearchTerm] = useState<string>(undefined)
  const isSearching = useMemo(() => {
    return searchTerm !== undefined
  }, [searchTerm])

  const { workspacesQuery, workspacesResult, hasValues, ready, totalCount } =
    useWorkspaceListDataTabs()

  const { data: workspaces, loading, error } = workspacesResult

  useEffect(() => {
    workspacesQuery(searchTerm)
  }, [workspacesQuery, archived])

  const [sendSalesFollowup] = useMutation(SEND_SALES_FOLLOWUP)

  const sendEmailToUserAndSales = async () => {
    await sendSalesFollowup().then(() => {
      addToast(`Success! We'll email you soon!`, {
        appearance: 'success',
        autoDismiss: true
      })
    })
  }

  const { remove: clearSearchParams } = useUpdateSearchParam('page')
  const debouncedOnChange = useCallback(
    debounce((e: string) => {
      clearSearchParams()
      workspacesQuery(e, true)
    }, 500),
    [workspacesQuery, clearSearchParams]
  )

  return (
    <>
      {hasWorkspacesAccess ? (
        <>
          {(hasValues || isSearching) && (
            <>
              <PageHeaderContainer header='Workspaces'>
                {team.capabilities.mayCreateWorkspace && !isCollaborator && (
                  <FlatButton renderAs='link' to={`${teamRoot}/workspaces#create`}>
                    Create workspace
                  </FlatButton>
                )}
              </PageHeaderContainer>
              <SearchBox>
                <Search
                  size='lg'
                  loading={loading}
                  {
                    ...({
                      value: searchTerm,
                      onChange: (e: string) => {
                        setSearchTerm(e)
                        debouncedOnChange(e)
                      },
                      placeholder: 'Search by workspace name...'
                    } as {}) /* TODO: figure out why the input props type isn't working */
                  }
                />
              </SearchBox>
            </>
          )}
          <WorkspacesList
            workspaces={workspaces}
            hasValues={hasValues}
            totalCount={totalCount}
            ready={ready}
            search={isSearching}
            error={error}
          />
        </>
      ) : (
        <EmptyPanel
          header='Workspaces'
          description='The collaborative workspace for data onboarding.'
          subDescription='Great customer experience is all about building relationships. It’s hard to do that,
            though, if you spend all your time wrangling and cleaning up their data.'
          image={NonWorkspacesIllustration}
          onClick={sendEmailToUserAndSales}
          buttonLabel='Contact us'
        />
      )}
    </>
  )
})

const WorkspacesList = ({
  workspaces,
  hasValues,
  totalCount,
  ready,
  search,
  error
}: {
  workspaces: GetWorkspacesWithFiltering
  hasValues: boolean
  totalCount: number
  ready: boolean
  search: boolean
  error: ApolloError
}) => {
  const [lastWorkspaces, setLastWorkspaces] = useState({
    data: [],
    pagination: { pageCount: 0, currentPage: 0 }
  })

  useEffect(() => {
    if (
      workspaces?.getWorkspacesWithFiltering &&
      workspaces.getWorkspacesWithFiltering !== lastWorkspaces
    ) {
      setLastWorkspaces(workspaces.getWorkspacesWithFiltering)
    }
  }, [workspaces?.getWorkspacesWithFiltering])

  const { data, pagination } = lastWorkspaces
  if (error) {
    return queryAlert({ state: { error, loading: ready } })
  }

  const { location, history } = useReactRouter()
  const { isCollaborator } = useTeamRole()
  const createWorkspaceModal = useCreateWorkspaceModal({
    onClose() {
      history.push(location.pathname)
      return true
    }
  })
  useEffect(() => {
    if (location.hash === '#create') {
      createWorkspaceModal.open()
    }
  }, [location])

  if (!hasValues && !ready) {
    return <WorkspaceSkeleton />
  }

  return (
    <>
      {createWorkspaceModal.render()}
      {hasValues && ready ? (
        <>
          <WorkspacesListDataPanel workspaces={data} workspaceCount={totalCount} />
          <ListPagination pagination={pagination} />
        </>
      ) : !hasValues && ready && search ? (
        <WorkspacesListDataPanel workspaces={[]} workspaceCount={totalCount} />
      ) : (
        <EmptyPanel
          header='Workspaces'
          description={
            isCollaborator
              ? "You don't have access to any Workspaces. Please request access from your project manager if you believe you need to be added."
              : `Workspaces are spaces for organizations to share data. Create a workspace to select what
          data template you want collaborators to contribute to.`
          }
          image={EmptyIllustration}
          imgSize={[320, 490]}
          onClick={createWorkspaceModal.open}
          children={createWorkspaceModal.render()}
          buttonLabel={!isCollaborator && 'Create Workspace'}
        />
      )}
    </>
  )
}
