import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

export const RadioLabel = styled.label`
  align-items: center;
  color: ${Colors.text};
  display: flex;
  font-size: ${fontSizes.type15};
  font-weight: 400;
  letter-spacing: 0.0125em;
  line-height: 1;
  margin: 0;
  cursor: pointer;

  > input {
    margin-top: 0;
    width: auto;
  }
`

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.basePadding} 0;
  input {
    margin-left: 0;
    margin-right: 0.5rem;
  }
`
