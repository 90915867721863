import { DefaultTheme, themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { Spinner } from 'src/resources/elements/Spinner'
import styled, { useTheme } from 'styled-components'

import { FadeUpMessage } from '../styles'
import {
  IconBox,
  HeaderWrapper,
  HeaderTitle,
  HeaderBlock
} from 'src/applications/Embed/fragments/Header'
import { useTranslation } from 'react-i18next'

const LoadingWrapper = styled.div`
  margin-left: auto;
  margin-right: 16px;

  p {
    font-size: 12px;
    font-weight: 500;
    color: ${themed('textColor')};
  }
`

interface IHeader {
  loading?: boolean
}

export function Header({ loading = false }: IHeader) {
  const { t } = useTranslation()
  const theme = useTheme() as DefaultTheme
  return (
    <div>
      <HeaderWrapper smallMargin>
        <IconBox>{theme?.iconOverrides_Header}</IconBox>
        <HeaderBlock>
          <HeaderTitle>{t(theme.textOverrides.headerMatch)}</HeaderTitle>
          {theme.textOverrides.headerMatchDesc && <p>{t(theme.textOverrides.headerMatchDesc)}</p>}
        </HeaderBlock>
        {loading && (
          <LoadingWrapper>
            <FadeUpMessage
              key='auto-save-message'
              message={
                <>
                  <Spinner /> Autosaving
                </>
              }
              component={'p'}
            />
          </LoadingWrapper>
        )}
      </HeaderWrapper>
    </div>
  )
}
