import { useCallback } from 'react'

import { useToasts } from 'react-toast-notifications'
import { FLATFILE_SFTP_ENDPOINT } from 'src/config'
import { getImportCredentials } from 'src/queries/GET_IMPORT_CREDENTIALS'
import { useUpdateImportCredentialsMutation } from 'src/queries/hooks/useUpdateImportCredentialsMutation'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { FormButton } from 'src/resources/elements/form/Button'
import { Input } from 'src/resources/elements/form/Input'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'
import { Spacing } from 'src/resources/layout'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'
import { Colors } from 'src/resources/colors'

const WideInput = styled(Input)`
  max-width: 560px;
`

const SftpHeader = styled.h3`
  color: ${Colors.text};
  font-weight: 600;
  margin-bottom: 0 !important;
`

const HelpText = styled.p`
  margin: ${Spacing.basePadding} 0;
`

const SftpCredentials = styled.div`
  margin: 0 0 ${Spacing.basePadding4x};
`

export const WorkspaceImportSettings = ({
  teamId,
  workspace
}: {
  teamId: string
  workspace: IWorkspace
}) => {
  const { addToast } = useToasts()
  const {
    data: { getImportCredentials: importCredentials } = {},
    loading,
    error,
    refetch: refetchImportCredentials
  } = getImportCredentials(teamId, workspace.id)
  const [updateImportCredentials] = useUpdateImportCredentialsMutation()

  const updateCredentials = async (active: boolean, reset?: boolean) => {
    const result = await updateImportCredentials({
      variables: {
        teamId,
        workspaceId: workspace.id,
        active,
        reset
      }
    })
    await refetchImportCredentials()
    if (result.data.updateImportCredentials) {
      addToast(active ? 'SFTP credentials updated' : 'SFTP access disabled', {
        appearance: 'success',
        autoDismiss: true
      })
    }
  }

  const resetCredentials = useCallback(updateCredentials.bind(null, true, true), [
    updateCredentials
  ])

  if (loading || error) {
    return QueryAlert({ error, loading })
  }

  const { active: isActive, credentials } = importCredentials ?? {}
  const activeWorkspace = !workspace.status

  return (
    <SubContentContainer>
      <SftpHeader>Enable SFTP Support </SftpHeader>
      {activeWorkspace ? (
        <>
          <HelpText>
            Activating this will allow you to import files into this workspace using an SFTP
            client.
          </HelpText>
          <BooleanInput onChange={updateCredentials} checked={isActive} label='Active' />
        </>
      ) : (
        <>
          <HelpText>
            Inactive workspaces can't use the SFTP upload feature. Please go to the Basic info tab
            to reactivate your workspace.
          </HelpText>
        </>
      )}
      {isActive && activeWorkspace && (
        <>
          <SftpCredentials>
            <WideInput
              autoSelect
              copyButton
              readOnly
              label='Server URL'
              formValue={FLATFILE_SFTP_ENDPOINT}
            />
            <WideInput
              autoSelect
              copyButton
              readOnly
              label='Username'
              formValue={credentials.username}
            />
            <WideInput
              autoComplete='current-password'
              autoSelect
              copyButton
              readOnly
              label='Password'
              formValue={credentials.password}
            />
          </SftpCredentials>
          <SftpHeader>Reset SFTP access</SftpHeader>
          <HelpText>
            The previous credentials will stop working immediately. However, in-flight transfers
            will be allowed to complete.
          </HelpText>
          <FormButton danger onClick={resetCredentials}>
            Reset credentials
          </FormButton>
        </>
      )}
    </SubContentContainer>
  )
}
