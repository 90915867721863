import { SmartGetWorkbookSheets_getWorkbookSheets } from 'src/smart/queries/types/SmartGetWorkbookSheets'
import { IBatch } from 'src/applications/Embed/types/IBatch'

export enum SCENE {
  INITIAL = 'INITIAL',
  SOURCE = 'SOURCE',
  HEADER_CHECK = 'HEADER_CHECK',
  MATCH = 'MATCH',
  REVIEW = 'REVIEW',
  FINAL = 'FINAL'
}

export interface IScenePayload {
  batch: IBatch
  team?: { id: number, name: string }
  onCancelBatch?: () => void
  onCancelWorkbook?: () => void
  onComplete(b?: Partial<IBatch>): void
  embeds?: boolean
  onGoBack?: (scene: SCENE) => void
  sheet?: SmartGetWorkbookSheets_getWorkbookSheets
  workbookStatus?: string
  workspaceId?: string
  validating?: boolean
}
