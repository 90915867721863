import { DefaultTheme, themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import styled, { useTheme } from 'styled-components'
import { Header } from 'src/applications/Embed/fragments/Header'
import { useTranslation } from 'react-i18next'

const SkipHeaderRowSubHeader = styled.p`
  margin: 0;
  color: ${themed('textColor')};
  font-size: ${themed('contentSmallFontSize')};
  font-weight: 400;
`
const SkipHeaderRowSelection = styled.span`
  cursor: pointer;
  color: ${themed('primary')};

  :hover {
    text-decoration: underline;
  }
`

export interface SkipHeaderRowProps {
  skipHeaderRowSelection: any
}

export const SkipHeaderRow = ({ skipHeaderRowSelection }: SkipHeaderRowProps) => {
  const { t } = useTranslation()
  const theme = useTheme() as DefaultTheme

  return (
    <Header>
      {t(theme.textOverrides.headerCheck)}
      <SkipHeaderRowSubHeader>
        {t(theme.textOverrides?.headerCheckDesc) ? (
          <>
            {t(theme.textOverrides?.headerCheckDesc)}{' '}
            <SkipHeaderRowSelection
              data-testid='skip-header-row-selection'
              onClick={skipHeaderRowSelection}
            >
              {theme.textOverrides?.headerCheckAction}
            </SkipHeaderRowSelection>
          </>
        ) : (
          <>
            {t('fragments.SkipHeaderRow.noHeaderRow') + ' '}
            <SkipHeaderRowSelection
              data-testid='skip-header-row-selection'
              onClick={skipHeaderRowSelection}
            >
              {t('fragments.SkipHeaderRow.skipHeaderRow')}
            </SkipHeaderRowSelection>
          </>
        )}
      </SkipHeaderRowSubHeader>
    </Header>
  )
}
