//move an element in array to a new index and shift the rest of the elements
//will return the original array if index is out range

export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  while (fromIndex < 0) {
    fromIndex += arr.length
  }
  while (toIndex < 0) {
    toIndex += arr.length
  }
  if (toIndex >= arr.length || fromIndex >= arr.length) {
    return arr
  }
  const arrayCopy = [...arr]
  const element = arrayCopy[fromIndex]
  arrayCopy.splice(fromIndex, 1)
  arrayCopy.splice(toIndex, 0, element)

  return arrayCopy
}
