function MatchingArrow({ fill = '#DFE3EB' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='24' fill='none' viewBox='0 0 40 24'>
      <path fill={fill} d='M5 11a1 1 0 100 2h23a1 1 0 100-2H5z' />
      <path
        fill={fill}
        d='M27.293 17.293a1 1 0 001.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z'
      />
    </svg>
  )
}

export default MatchingArrow
