import { UploadDataSourceForm } from 'src/applications/Oversight/forms/UploadDataSourceForm'
import { useParams } from 'src/resources/hooks/useParams'
export const WorkspaceUploadDataSourceScene = () => {
  const { workspaceId, uploadId } = useParams<{
    workspaceId: string
    uploadId: string
  }>()

  return <UploadDataSourceForm workspaceId={workspaceId} uploadId={uploadId} />
}
