export const showAutoCompletion = (obj: any, monaco: any) => {
  // Disable default autocompletion for javascript
  //   monaco.languages.typescript.javascriptDefaults.setCompilerOptions({ noLib: true })

  // Helper function to return the monaco completion item type of a thing
  function getType(thing: any, isMember: boolean = false) {
    // Give isMember a default value of false

    switch ((typeof thing).toLowerCase()) {
      case 'object':
        return monaco.languages.CompletionItemKind.Class

      case 'function':
        return isMember
          ? monaco.languages.CompletionItemKind.Method
          : monaco.languages.CompletionItemKind.Function

      default:
        return isMember
          ? monaco.languages.CompletionItemKind.Property
          : monaco.languages.CompletionItemKind.Variable
    }
  }

  // Register object that will return autocomplete items
  monaco.languages.registerCompletionItemProvider('javascript', {
    // Run this function when the period or open parenthesis is
    // typed (and anything after a space)
    triggerCharacters: ['.', '('],

    // Function to generate autocompletion results
    provideCompletionItems(
      model: {
        getValueInRange: (arg0: {
          startLineNumber: any
          startColumn: number
          endLineNumber: any
          endColumn: any
        }) => any
      },
      position: { lineNumber: any; column: any }
    ) {
      // Split everything the user has typed on the current line
      //   up at each space, and only look at the last word
      const lastChars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      })
      const words = lastChars.replaceAll('\t', '').split(' ')
      // What the user is currently typing (everything after the last space)
      const activeTyping = words[words.length - 1]

      // If the last character typed is a period then we need to look
      // at member objects of the obj object
      const isMember = activeTyping.charAt(activeTyping.length - 1) === '.'

      // Array of autocompletion results
      const result: { label: string; kind: any; detail: string; insertText: string }[] = []

      // Used for generic handling between member and non-member objects
      let lastToken = obj
      let prefix = ''

      if (isMember) {
        // Is a member, get a list of all members, and the prefix
        const parents = activeTyping.substring(0, activeTyping.length - 1).split('.')
        lastToken = obj[parents[0]]
        prefix = parents[0]

        // Loop through all the parents the current one will have (to generate prefix)
        for (let i = 1; i < parents.length; i++) {
          if (lastToken.hasOwnProperty(parents[i])) {
            prefix += '.' + parents[i]
            lastToken = lastToken[parents[i]]
          } else {
            // Not valid
            return result
          }
        }

        prefix += '.'
      }

      // Get all the child properties of the last token
      for (const prop in lastToken) {
        // Do not show properites that begin with "__"
        if (lastToken.hasOwnProperty(prop) && !prop.startsWith('__')) {
          // Get the detail type (try-catch) incase object does not have prototype
          let details = ''
          try {
            details = lastToken[prop].__proto__.constructor.name
          } catch (e) {
            details = typeof lastToken[prop]
          }

          // Create completion object
          const toPush = {
            label: prefix + prop,
            kind: getType(lastToken[prop], isMember),
            detail: details,
            insertText: prop,
            documentation: ''
          }

          // Change insertText and documentation for functions
          if (toPush.detail.toLowerCase() === 'function') {
            toPush.insertText += '('
            toPush.documentation = lastToken[prop].toString().split('{')[0]
          }

          // Add to final results
          result.push(toPush)
        }
      }

      return {
        suggestions: result
      }
    }
  })
}

const BULK = 'bulk'
const SINGLE = 'single'
const SPLIT = 'split'
const VALIDATE = 'validate'
const ADD_PADDING = 'pad'
const NORMALIZE_DATE = 'date'
export const HOOK_TEMPLATES = [
  {
    type: SINGLE,
    label: 'Record Hook',
    description: 'An empty function that will process a single record.'
  },
  {
    type: SPLIT,
    label: 'Split Column',
    description: 'Split a column if it contains a space (e.g. for names).'
  },
  {
    type: VALIDATE,
    label: 'Validate Many',
    description: 'Validate at least one member of a group of columns is present.'
  },
  {
    type: ADD_PADDING,
    label: 'Add Padding',
    description: 'Add padding to a column if too short (e.g. for zip codes).'
  },
  {
    type: NORMALIZE_DATE,
    label: 'Normalize Date',
    description: 'Use the date-fns dependency to clean date columns.'
  },
  {
    type: BULK,
    label: 'Bulk API Call',
    description: 'Add latitude and longitude of addresses via SmartyStreets API.'
  }
]
export const getEditorSampleCode = (column: string, template: string) => {
  const description = HOOK_TEMPLATES.find((t) => t.type === template)?.description
  switch (template) {
    case BULK:
      return `// ${description}
// Remember to add the 'axios' dependency above in the "Dependencies" section!
const axios = require('axios');

module.exports = async ({recordBatch, session, logger}) => {
  const authId = session.env.authId; // Access an environment variable on your workspace called 'authId'
  const token = session.env.token; // Access an environment variable on your workspace called 'token'
  const license = 'us-core-cloud';
  const url = \`https://us-street.api.smartystreets.com/street-address?auth-id=\${authId}&auth-token=\${token}&license=\${license}\`

  // transform the batch of records into a bulk payload for Smarty 
  const addresses = await Promise.all(await recordBatch.records.map(async (record) => {
    return { street: record.get('${column}') }
  }));
  
  // Use provided logger to receive log output when testing a hook
  logger.info(addresses)

  // Query the external Smarty API for all addresses in this batch of records
  const { data } = await axios.post(url, addresses)

  // Use provided logger to receive log output when testing a hook
  logger.info(data)

  // Update records with the lat/log results that Smarty returned
  await Promise.all(await recordBatch.records.forEach(async (record, index) => {
    // Smarty uses the input_index to identify which address was used to validate against
    // We can compare that with the record's index to determine if Smarty found the address valid or not 
    const [validatedAddress] = data.filter((address) => Number(address.input_index) === Number(index));

    // Add error message to invalid records and clear lat/long fields
    if (!validatedAddress) {
      record.set('latitude', '');
      record.set('longitude', '');
      record.addError('${column}', 'Invalid address');
      return;
    }

    // Add lat/long to valid addresses
    record.set('latitude', validatedAddress.metadata.latitude);
    record.set('longitude', validatedAddress.metadata.longitude);
  }));
}`
    case SINGLE:
      return `// ${description}
module.exports = async ({recordBatch, session, logger}) => {
  recordBatch.records.forEach((record) => {
    //... do something one record at a time
  }) 
}`
    case SPLIT:
      return `// ${description}
module.exports = async ({recordBatch, session, logger}) => {
  await Promise.all(await recordBatch.records.map(async (record) => {
  
    if (record.get('${column}') && !record.get('lastName')) {
      if (record.get('${column}').includes(" ")) {
        const components = record.get('${column}').split(" ")
        record.set('${column}', components.shift())
        record.set('lastName',components.join(" "))
      }
    }
  }))
}`
    case VALIDATE:
      return `// ${description}
module.exports = async ({recordBatch, session, logger}) => {
  await Promise.all(await recordBatch.records.map(async (record) => {
  
    if (!record.get('email') && !record.get('${column}')) {
      record.addWarning(['email', '${column}'], 'must have either email or ${column}')
    }
  }))
}`
    case ADD_PADDING:
      return `// ${description}
module.exports = async ({recordBatch, session, logger}) => {
  await Promise.all(await recordBatch.records.map(async (record) => {
  
    if (
      record.get('${column}') &&
      record.get('${column}').length < 5
    ) {
      record.set('${column}', record.get('${column}').padStart(5, "0"))
      record.addComment('${column}', '${column} was padded with zeroes')
    }
  }))
}`
    case NORMALIZE_DATE:
      return `// ${description}
// Remember to add the 'date-fns' dependency above in the "Dependencies" section!
const dfns = require('date-fns');

module.exports = async ({recordBatch, session, logger}) => {
  await Promise.all(await recordBatch.records.map(async (record) => {

    if (record.get('${column}')) {
      let thisDate = dfns.format(new Date(record.get('${column}')), "yyyy-MM-dd");
      let realDate = dfns.parseISO(thisDate);
      if (dfns.isDate(realDate)) {
        record.set('${column}', thisDate)
        if (dfns.isFuture(realDate)) {
          record.addError('${column}', "${column} cannot be in the future.")
        }
      } else {
        record.addError('${column}', "Please check that the date is formatted YYYY-MM-DD.")
      }
    }
  }))
}`
    default:
      return `// Fill in the function below with your code, or select a template.
module.exports = async ({recordBatch, session, logger}) => { 
  // recordBatch.records.forEach((record) => {
  //   record
  //     // set the value of all records in the column ${column} to 'flatfile'
  //     .set('${column}', 'flatfile')
  //     // setting a value on a non-existent field will result in a console error: 'non-existing-field' doesn't exist
  //     .set('non-existing-field', 'flatfile')
  //     // add a custom comment to a field
  //     .addComment(['${column}'], 'this is a custom comment')
  //     // add a custom warning to a field
  //     .addWarning(['${column}'], 'this is a warning')
  //     // add a custom error to a field
  //     .addError('email', 'this is a custom error message')
  // })
}`
  }
}
