import { gql } from '@apollo/client'

export const GET_ROWS = gql`
  query GetRows($batchId: UUID!) {
    getRows(batchId: $batchId) {
      pagination {
        totalCount
        pageCount
        currentPage
        nextOffset
        previousOffset
      }
      data {
        id
        mapped
      }
    }
  }
`
