import { useState } from 'react'

import { EnumMatchPanel, IEnumMatchPanel } from '../components/EnumMatchPanel'

export const useEnumMatchPanel = () => {
  const [config, setConfig] = useState<IEnumMatchPanel>({
    visible: false
  })

  return [
    () => <EnumMatchPanel key='enum-match-panel' {...config} />,
    (payload) =>
      new Promise<Record<string, string>>((resolve) => {
        setConfig({
          ...payload,
          visible: true,
          onClose: (result) => {
            setConfig({
              visible: false
            })
            resolve(result)
          }
        })
      })
  ] as [() => JSX.Element, (p: Partial<IEnumMatchPanel>) => Promise<Record<string, string>>]
}
