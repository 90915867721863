import { gql } from '@apollo/client'
import {
  SmartTestRESTWebhookEndpoint,
  SmartTestRESTWebhookEndpointVariables,
  SmartTestRESTWebhookEndpoint_testRESTWebhookEndpoint
} from 'src/smart/queries/types/SmartTestRESTWebhookEndpoint'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_TEST_REST_WEBHOOK_ENDPOINT: SmartQuery<
  SmartTestRESTWebhookEndpointVariables,
  SmartTestRESTWebhookEndpoint,
  SmartTestRESTWebhookEndpoint_testRESTWebhookEndpoint
> = {
  query: gql`
    query SmartTestRESTWebhookEndpoint($teamId: ID!, $uri: String!, $legacy: Boolean!) {
      testRESTWebhookEndpoint(teamId: $teamId, uri: $uri, legacy: $legacy) {
        success
        message
      }
    }
  `,
  extract({ testRESTWebhookEndpoint }) {
    return testRESTWebhookEndpoint
  }
}
