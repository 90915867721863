import { useContext, useEffect, useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import { EditEmbedForm } from 'src/applications/Oversight/forms/EditEmbedForm'
import { useGetSchemas } from 'src/applications/Oversight/hooks/useGetSchemas'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { CreatedEmbedPrivateKeyContext } from 'src/contexts/CreatedEmbedPrivateKeyContext'
import { useParams } from 'src/resources/hooks/useParams'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_RESET_EMBED_PRIVATE_KEY } from 'src/smart/mutations/SM_RESET_EMBED_PRIVATE_KEY'
import { SM_UPDATE_EMBED } from 'src/smart/mutations/SM_UPDATE_EMBED'
import { SQ_EMBED } from 'src/smart/queries/SQ_EMBED'

export const EmbedEditPanel = () => {
  const { embedId } = useParams<{ embedId: string }>()
  const embedQuery = useSmartQuery(SQ_EMBED, {
    fetchPolicy: 'cache-and-network',
    variables: { id: embedId }
  })
  const schemaQuery = useGetSchemas({ archived: false }, { fetchPolicy: 'network-only' })

  const [showPrivateKey, setShowPrivateKey] = useState<boolean>(false)
  const { createdEmbedPrivateKey, setCreatedEmbedPrivateKey } = useContext(
    CreatedEmbedPrivateKeyContext
  )
  const resetEmbedPrivateKey = useSmartMutation(SM_RESET_EMBED_PRIVATE_KEY)
  const updateEmbed = useSmartMutation(SM_UPDATE_EMBED)
  const teamRoot = useTeamRootUrl()
  const { addToast } = useToasts()
  const previousEmbedName = embedQuery?.result?.name
  const previousEmbedHelpContent = embedQuery?.result?.helpContent
  const previousArchivedState = embedQuery?.result?.archived
  const previousLegacyWebhookURL = embedQuery?.result?.legacyWebhookURL

  useEffect(() => {
    // createEmbedPrivateKey is set when creating a new embed or resetting the private key.
    // It's the only time we show the private key to the user, so we need to update
    // showPrivateKey, and then clean the context and reset showPrivateKey when we
    // unmount this component
    if (createdEmbedPrivateKey) {
      setShowPrivateKey(true)
    }

    return () => {
      setCreatedEmbedPrivateKey(null)
      setShowPrivateKey(false)
    }
  }, [])

  const onResetPrivateKey = async () => {
    try {
      const updatedPrivateKey = await resetEmbedPrivateKey.run({
        embedId
      })

      if (updatedPrivateKey) {
        setCreatedEmbedPrivateKey(updatedPrivateKey.privateKeyString)
        addToast('Successfully reset private key!', {
          appearance: 'success',
          autoDismiss: true
        })
      }
    } catch (error) {
      throw error
    }
  }

  const onUpdateEmbed = async ({
    data: { name, archived, schema, legacyWebhookURL, helpContent }
  }: {
    data: {
      name: string
      archived: boolean
      schema: string
      legacyWebhookURL?: string
      helpContent?: string
    }
  }) => {
    // if name or archived hasn't changed, return early
    if (
      previousEmbedName === name &&
      previousArchivedState === archived &&
      previousLegacyWebhookURL === legacyWebhookURL &&
      previousEmbedHelpContent === helpContent
    )
      return

    try {
      const updatedEmbed = await updateEmbed.run({
        embedId,
        name,
        archived,
        legacyWebhookURL,
        helpContent,
        schemaIds: [schema]
      })
      if (updatedEmbed) {
        addToast('Successfully updated Portal!', {
          appearance: 'success',
          autoDismiss: true
        })
        await embedQuery.state.refetch({ id: embedId })
      }
    } catch (error) {
      await embedQuery.state.refetch({ id: embedId })
      throw error
    }
  }

  return (
    <BreadcrumbsFullTemplate
      crumbs={[
        {
          to: `${teamRoot}/setup`,
          label: 'Portal'
        },
        {
          current: true,
          label: 'Edit Portal'
        }
      ]}
    >
      {embedQuery?.result && !schemaQuery.state.loading && (
        <EditEmbedForm
          schemas={schemaQuery?.data}
          showPrivateKey={showPrivateKey}
          setShowPrivateKey={setShowPrivateKey}
          key='editEmbedForm'
          embed={embedQuery?.result}
          onSubmit={onUpdateEmbed}
          onResetPrivateKey={onResetPrivateKey}
          privateKey={createdEmbedPrivateKey}
        />
      )}
    </BreadcrumbsFullTemplate>
  )
}
