import { OverflowData, OverflowSpan } from 'src/applications/Oversight/components/OverflowData'
import { Colors } from 'src/resources/colors'
import { AvatarCircleInitials } from 'src/resources/elements/Avatar'
import TeamIcon from 'src/resources/icons/TeamIcon.svg'
import { Spacing } from 'src/resources/layout'
import styled, { css } from 'styled-components'
import { fontSizes } from 'src/resources/typography'

interface IProfileCard {
  onlineStatus?: boolean
  name?: string
  teamName?: string
  githubUsername?: string
}

interface INavIcon {
  color?: string
}

const ProfileName = styled.h5`
  color: ${Colors.text};
  font-size: ${fontSizes.type14};
  font-weight: 500;
  margin: 0 0 4px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 4px;
  text-overflow: ellipsis;
`

const ProfileTeam = styled.h5`
  align-items: start;
  color: ${Colors.mediumText};
  display: flex;
  font-weight: 500;
  font-size: ${fontSizes.type14};
  margin: 0;
  overflow: hidden;
  padding: 0 12px;
  text-overflow: ellipsis;

  > img {
    margin-right: 4px;
  }
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  ${OverflowSpan} {
    max-width: unset;
    width: 100%;
  }
`

const NavIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  top: ${Spacing.baseAndHalfPadding};
  width: ${Spacing.basePadding};
`

const NavIconDot = styled.div<{
  color?: string
}>`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-bottom: 3px;
  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
        `
      : css`
          background: ${Colors.medGray};
        `}
`

const ProfileContainer = styled.div`
  align-items: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  &:hover {
    ${NavIconDot} {
      background-color: ${Colors.brandPrimary};
    }
  }
`

export const ProfileCard = ({ name, onlineStatus, teamName }: IProfileCard) => {
  return (
    <ProfileContainer>
      <AvatarCircleInitials onlineStatus={onlineStatus} name={name} color={Colors.avatarGrey} />
      <NameContainer>
        <OverflowData dataTip={name} dataFor={name} maxWidth={105}>
          <ProfileName>{name}</ProfileName>
        </OverflowData>
        <ProfileTeam>
          {teamName !== 'Unknown - Github' ? (
            <>
              <img src={TeamIcon} height={14} width={14} alt={teamName} />
              <OverflowData dataTip={teamName} dataFor={teamName} maxWidth={105}>
                <span>{teamName}</span>
              </OverflowData>
            </>
          ) : null}
        </ProfileTeam>
      </NameContainer>
      <NavIcon color={Colors.medGray} />
    </ProfileContainer>
  )
}

export const NavIcon = ({ color }: INavIcon) => {
  return (
    <NavIconWrapper>
      <NavIconDot color={color} />
      <NavIconDot color={color} />
      <NavIconDot color={color} />
    </NavIconWrapper>
  )
}
