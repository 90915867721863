import { Redirect, withRouter } from 'react-router-dom'
import { BasicInfoContext } from 'src/contexts/BasicInfoContext'
import { ITeam } from 'src/types/interfaces/ITeam'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'
import { identifyPosthog } from 'src/resources/utils/analytics'

export const MainScene = withRouter(({ location: { search } }) => {
  const params = new URLSearchParams(search)
  const ref = params.get('ref')
  const dev = params.get('dev')

  if (ref) {
    return <Redirect to={ref} />
  }
  if (dev) {
    window.sessionStorage.setItem('dev', 'true')
  }

  const identifyUser = async () => {
    const referer = document.referrer
    if (referer && referer.toString().indexOf('login') === -1 && !window.sessionStorage?.dev) {
      const currentUserData = await UserTransceiver.current()
      if (currentUserData) {
        identifyPosthog(
          {
            email: currentUserData.me.email,
            user_id: currentUserData.me.id,
            source: 'GitHub'
          },
          'login - frontend'
        )
      }
    }
  }
  identifyUser()

  return (
    <BasicInfoContext
      onTeamFoundRender={(team: ITeam) => {
        window.sessionStorage?.dev
          ? location.replace(`/account-sign-up/github`)
          : location.replace(`/a/${team.id}`)
        return null
      }}
    />
  )
})
