import styled from 'styled-components'

export const TextSymbolWrap = styled.span`
  font-size: 60%;
  opacity: 0.8;
  padding: 0 1px;
  position: relative;
  top: -4px;
`

export const TextSymbol = ({ reg }: { reg?: boolean }) => (
  <TextSymbolWrap>{reg ? <>&reg;</> : ''}</TextSymbolWrap>
)
