import { createContext, Dispatch, ReactNode, useState } from 'react'

export interface ICreatedEmbedPrivateKeyContext {
  createdEmbedPrivateKey: string
  setCreatedEmbedPrivateKey: Dispatch<any>
}

export const CreatedEmbedPrivateKeyContext = createContext<ICreatedEmbedPrivateKeyContext>({
  createdEmbedPrivateKey: null,
  setCreatedEmbedPrivateKey: undefined
})

export const EmbedPrivateKeyProvider = ({ children }: { children: ReactNode }) => {
  const [createdEmbedPrivateKey, setCreatedEmbedPrivateKey] = useState<string>(null)

  return (
    <CreatedEmbedPrivateKeyContext.Provider
      value={{ createdEmbedPrivateKey, setCreatedEmbedPrivateKey }}
    >
      {children}
    </CreatedEmbedPrivateKeyContext.Provider>
  )
}
