import { Colors } from 'src/resources/colors'
import { createGlobalStyle } from 'styled-components'

export const Reset = createGlobalStyle`
  input:focus,
  textarea:focus,
  select:focus,
  button {
    outline: none;
  }
  // Temporary fix, we need a proper global reset with testing
  button {
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
  html {
  height: 100%;
  }
  body {
    margin: 0;
  }
  
  a { text-decoration: none;
    color: ${Colors.brandPrimary};
  }

  code { font-family: inherit; }
  
  strong {
    // Global override to prevent font weights >=700 for @Design
    font-weight: 600;
  }
  
  // TODO: remove when Tailwind base styles don't interfere
  body svg {
    display: inline-block;
  }
`
