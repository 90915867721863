import { Colors, ColorUtils } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'

export const AccentFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return css`
          background-color: transparent !important;
          border: 1px solid ${ColorUtils.hexToRgba(Colors.textAccent, 0.7)} !important;
          color: ${Colors.textAccent} !important;
          &:hover {
            border-color: ${Colors.textAccent} !important;
          }
        `
      default:
        return css`
          background-color: ${Colors.textAccent} !important;

          &:hover {
            background-color: ${ColorUtils.hoverColor(Colors.textAccent)} !important;
          }
        `
    }
  }}
`
