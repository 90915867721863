import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { Loader } from 'src/applications/Embed/fragments/Loader'
import { Breadcrumbs } from 'src/applications/Oversight/components/Breadcrumbs'
import { DataFilters } from 'src/applications/Oversight/components/DataFilters'
import { WorkbookDataTable } from 'src/applications/Oversight/components/WorkbookDataTable'
import { WorkspaceActionBar } from 'src/applications/Oversight/components/WorkspaceActionBar'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import {
  TableValidationContext,
  TableValidationStateProvider
} from 'src/contexts/TableValidationContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_BATCHES } from 'src/queries/GET_BATCHES'
import { GetBatches, GetBatchesVariables } from 'src/queries/types/GetBatches'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import Notification from 'src/resources/elements/Notification'
import { useParams } from 'src/resources/hooks/useParams'
import { useTableFilters } from 'src/resources/hooks/useTableFilters'
import { Spacing } from 'src/resources/layout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_SHEET } from 'src/smart/queries/SQ_SHEET'
import { useWorkbookUpdatedSubscription } from 'src/smart/queries/SQ_WORKBOOK_UPDATED_SUBSCRIPTION'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import {
  SmartGetSheet,
  SmartGetSheet_getSheet,
  SmartGetSheetVariables
} from 'src/smart/queries/types/SmartGetSheet'
import { SmartResult } from 'src/smart/SmartQuery'
import { EBatchStatus } from 'src/types/enums/EBatchStatus'
import { EValidationState } from 'src/types/enums/EValidationState'
import { EWorkbookStatus } from 'src/types/enums/EWorkbookStatus'
import styled from 'styled-components'
import { getRoundedNumber } from 'src/resources/utils/getRoundedNumber'
import { defaultProcessingStages } from 'src/resources/utils/loadingStages'

const BannerWrapper = styled.div`
  padding-top: ${Spacing.basePadding2x};
`

export const WorkbookViewSceneWrap = () => {
  return (
    <TableValidationStateProvider>
      <WorkbookViewScene />
    </TableValidationStateProvider>
  )
}

export const WorkbookViewScene = () => {
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  const { validatingTable, setValidatingTable } = useContext(TableValidationContext)

  const { sheetId, workbookId, workspaceId } = useParams<{
    sheetId: string
    workbookId: string
    workspaceId: string
  }>()

  const sheetQuery = useSmartQuery(SQ_SHEET, {
    fetchPolicy: 'network-only',
    variables: {
      sheetId
    }
  })

  const percentCompleteRounded = getRoundedNumber(sheetQuery.result?.percentComplete)

  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const workbookUpdatedSubscription = useWorkbookUpdatedSubscription(workbookId)
  const workbookStatus = workbookUpdatedSubscription?.data?.workbookUpdated?.status
  const workbookLoading =
    workbookStatus === EWorkbookStatus.IN_PROGRESS || workbookStatus == EWorkbookStatus.QUEUED

  const loading =
    // percentComplete will return 0 for empty workbooks so we don't want to continuously poll for sheetQuery
    (percentCompleteRounded < 99 && percentCompleteRounded !== 0) ||
    workbookLoading ||
    (workbookUpdatedSubscription?.loading &&
      typeof workbookUpdatedSubscription?.data !== 'undefined')

  useEffect(() => {
    if (loading) {
      sheetQuery.state.startPolling(1000)
    } else {
      sheetQuery.state.stopPolling()
    }
  }, [loading])

  useEffect(() => {
    if (!workbookLoading) {
      setValidatingTable(false)
    }
  }, [workbookLoading])

  const teamRoot = useTeamRootUrl()

  const links = [
    {
      to: `${teamRoot}/workspaces/${workspaceId}`,
      label: workspaceQuery?.result?.name
    },
    {
      current: true,
      label: sheetQuery?.result?.schema.name
    }
  ]

  const { data } = useQuery<GetBatches, GetBatchesVariables>(GET_BATCHES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      workspaceId,
      teamId: team.id,
      environmentId: environment?.id,
      deleted: false,
      schemaId: sheetQuery?.result?.template?.id,
      take: 1,
      skip: 0
    }
  })

  const showWorkbook = !loading && !workbookLoading
  return (
    <>
      <WorkspaceActionBar
        inline={true}
        noMargin={false}
        noPadding={true}
        sheetId={sheetQuery.result?.id}
        title={<Breadcrumbs links={links} />}
        workspaceActive={!workspaceQuery?.result?.status}
        workspaceId={workspaceId}
      />
      <Card fullHeight={showWorkbook}>
        <PageHeaderContainer header={sheetQuery.result?.schema.name} />
        {showWorkbook ? (
          <WorkbookViewSceneContent
            sheetQuery={sheetQuery}
            data={data}
            workspaceId={workspaceId}
            workbookId={workbookId}
            workbookStatus={workbookStatus}
            validating={validatingTable}
          />
        ) : (
          <Loader
            height='75vh'
            displayLoadingCircle={percentCompleteRounded > 1}
            percentage={percentCompleteRounded}
            loadingStages={defaultProcessingStages}
          />
        )}
      </Card>
    </>
  )
}

export const WorkbookViewSceneContent = ({
  sheetQuery,
  data,
  validating,
  workbookId,
  workbookStatus
}: {
  sheetQuery: SmartResult<SmartGetSheetVariables, SmartGetSheet, SmartGetSheet_getSheet>
  data: GetBatches
  validating: boolean
  workspaceId: string
  workbookId: string
  workbookStatus?: string
}) => {
  const [datahooksError, setDatahooksError] = useState(false)
  const [{ validationState }] = useTableFilters()

  const schema = sheetQuery?.result?.template?.jsonSchema?.schema

  const errorMessage =
    'Unable to complete automated reformatting. Hover over any error for guidance on correcting a value manually. Reach out to us if you need further assistance.'

  useEffect(() => {
    setDatahooksError(data?.getBatches.data[0]?.status === EBatchStatus.dataHookError)
  }, [data])

  return (
    <>
      <DataFilters />
      {datahooksError && validationState === EValidationState.review && (
        <BannerWrapper>
          <Notification type='warning' title={errorMessage} marginBottom={0} />
        </BannerWrapper>
      )}
      {schema && sheetQuery.result && (
        <WorkbookDataTable
          schemaId={sheetQuery.result.schema.id}
          schemaObj={schema}
          sheetId={sheetQuery.result.id}
          workbookId={workbookId}
          workbookStatus={workbookStatus}
          validating={validating}
        />
      )}
    </>
  )
}
