import { gql } from '@apollo/client'

export const GET_WORKSPACE_INVITATION = gql`
  query GetWorkspaceInvitation($token: String!) {
    getWorkspaceInvitation(token: $token) {
      email
      name
      organization {
        id
      }
      organizationTeam {
        id
      }
      workspace {
        id
      }
      adminTeam {
        id
        name
      }
      expired
    }
  }
`
