import { useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import resolvePathname from 'resolve-pathname'
import { GET_WORKSPACE_DATA_REQUEST_SCHEMAS } from 'src/queries/GET_WORKSPACE_DATA_REQUEST_SCHEMAS'
import { useMapDataSourceAndDataTemplateMutation } from 'src/queries/hooks/useMapDataSourceAndDataTemplateMutation'
import {
  GetWorkspaceDataRequestSchemas,
  GetWorkspaceDataRequestSchemasVariables
} from 'src/queries/types/GetWorkspaceDataRequestSchemas'
import { FormButton, TFormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { FieldLabel } from 'src/resources/elements/form/FieldLabel'
import { Form, IFormContext, TForm } from 'src/resources/elements/form/Form'
import { Select } from 'src/resources/elements/form/Select'
import { Header } from 'src/resources/elements/Header'
import { QueryAlert, queryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_UPLOAD } from 'src/smart/queries/SQ_GET_UPLOAD'
import styled from 'styled-components'
import useRouter from 'use-react-router'
import useReactRouter from 'use-react-router'

interface IUploadDataSourceFormData {
  index: string
  schemaId: string
}

const FileName = styled.p`
  margin-top: ${Spacing.basePadding};
  margin-bottom: 0;
  font-size: ${fontSizes.type14};
  font-weight: 500;
`

const FileNameLabel = styled(FieldLabel)`
  margin-top: ${Spacing.basePadding3x};
  margin-bottom: 0;
`

const UploadDataSourceFormElement: TForm<IUploadDataSourceFormData> = Form
const UploadDataSourceFormButton: TFormButton<IUploadDataSourceFormData> = FormButton

const CenteredContentContainer = styled.section`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
  }
`

const ControlBar = styled.div`
  margin-top: ${Spacing.basePadding2x};
`

export const UploadDataSourceForm = ({
  uploadId,
  workspaceId,
  onUploadDataSourceFormSubmit
}: {
  uploadId: string
  workspaceId: string
  onUploadDataSourceFormSubmit?: () => void
}) => {
  const {
    location: { pathname: currentUrlPath }
  } = useRouter()
  const [mapDataSourceAndDataTemplateMutation] = useMapDataSourceAndDataTemplateMutation()

  const [isLoading, setIsLoading] = useState(false)
  const { history } = useReactRouter()

  const uploadQuery = useSmartQuery(SQ_GET_UPLOAD, {
    fetchPolicy: 'network-only',
    variables: { uploadId }
  })

  const viewId = uploadQuery?.result?.nextAction?.originalViewId
  const dataSources = uploadQuery?.result?.uploadDataSources
  const chosenDataSourceIndex = uploadQuery?.result?.selectedDataSourceIndex
  const dataSourceAlreadyChosen = Number.isInteger(chosenDataSourceIndex)
  const fileName = uploadQuery?.result?.fileName

  const {
    data: schemasData,
    loading,
    error
  } = useQuery<GetWorkspaceDataRequestSchemas, GetWorkspaceDataRequestSchemasVariables>(
    GET_WORKSPACE_DATA_REQUEST_SCHEMAS,
    {
      fetchPolicy: 'network-only',
      variables: { workspaceId }
    }
  )

  const schemas = schemasData?.getWorkspaceDataRequestSchemas?.map((s) => ({
    id: s.id,
    name: s.name
  }))

  const initialValue = useMemo(() => {
    const sourceFormValue: { index?: string; schemaId?: string } = {}
    if (dataSourceAlreadyChosen) {
      sourceFormValue.index = chosenDataSourceIndex.toString()
    }
    if (typeof sourceFormValue.index !== 'string' && dataSources?.length === 1) {
      sourceFormValue.index = dataSources[0].index.toString(10)
    }
    if (typeof sourceFormValue.schemaId !== 'string' && schemas?.length === 1) {
      sourceFormValue.schemaId = schemas[0].id
    }
    return sourceFormValue
  }, [dataSources?.length, schemas?.length])

  if ([loading, isLoading, error].some((item) => item)) {
    return (
      <CenteredContentContainer>
        {QueryAlert({ loading: loading || isLoading, error })}
      </CenteredContentContainer>
    )
  }

  if (uploadQuery.alert) {
    return queryAlert(uploadQuery)
  }

  const submitUploadDataSourceForm = async (
    { index, schemaId }: IUploadDataSourceFormData,
    formContext: IFormContext<IUploadDataSourceFormData>
  ) => {
    if (!dataSourceAlreadyChosen && !index) {
      formContext.setValue({
        ...formContext.value,
        errors: {
          index: 'Please select a sheet'
        }
      })
      return
    }

    if (!schemaId) {
      formContext.setValue({
        ...formContext.value,
        errors: {
          schemaId: 'Please select a template'
        }
      })
      return
    }

    formContext.setValue({ ...formContext.value, errors: {} })

    setIsLoading(true)

    const response = await mapDataSourceAndDataTemplateMutation({
      variables: {
        viewId,
        uploadId,
        schemaId: parseInt(schemaId, 10),
        index: dataSourceAlreadyChosen ? undefined : +index
      }
    })

    setIsLoading(false)

    if (!response.errors) {
      // TODO: Do we need this setTimeout?
      window.setTimeout(onUploadDataSourceFormSubmit, 100)
      const redirectTo = resolvePathname(
        `./sheet/${index}/schema/${schemaId}/header-row`,
        currentUrlPath
      )

      history.push(redirectTo)
    }
  }

  return (
    <>
      <UploadDataSourceFormElement {...(Object.keys(initialValue).length && { initialValue })}>
        <Header hasMargin>
          <h2>Import data</h2>
        </Header>
        <FileNameLabel>File name</FileNameLabel>
        <FileName>{fileName}</FileName>
        <CenteredContentContainer data-testid='upload-data-source-container'>
          {!dataSourceAlreadyChosen && (
            <>
              <Select
                label='Select Sheet'
                required={true}
                name='index'
                disabled={dataSourceAlreadyChosen}
                options={dataSources.map((dataSource) => ({
                  label: dataSource.label,
                  value: dataSource.index.toString()
                }))}
              />
              <ErrorMessage name='index' />
            </>
          )}

          <Select
            label='Select Template'
            required={true}
            name='schemaId'
            options={schemas.map((schema) => ({
              label: schema.name,
              value: schema.id
            }))}
          />
          <ErrorMessage name='schemaId' />
          <ControlBar>
            <ButtonGroup activityLabel='Uploading...' left>
              <UploadDataSourceFormButton
                primary
                submit
                onSubmit={({ data, formContext }) => submitUploadDataSourceForm(data, formContext)}
              >
                Import
              </UploadDataSourceFormButton>
            </ButtonGroup>
          </ControlBar>
        </CenteredContentContainer>
      </UploadDataSourceFormElement>
    </>
  )
}
