import { createContext } from 'react'

export interface ISession {
  activeTeamId: string
  setActiveTeamId(teamId: string): void
}

export const SessionContext = createContext<ISession>({
  activeTeamId: undefined,
  setActiveTeamId() {
    return void 0
  }
})
