import { useCallback, useState } from 'react'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { useSearchParam, useUpdateSearchParam } from 'src/resources/hooks/useSearchParam'
import { NewBadge } from 'src/resources/sidebars/Menu'
import { NotFoundPanel } from '../scenes/NotFoundScene'

export interface TabAndContents<TProps> {
  id: string
  label: string | JSX.Element
  showBetaBadge?: boolean
  contents(props: TProps): JSX.Element
}

export function useTabs<TProps>({
  center = false,
  compact = false,
  useUrl = true,
  urlParam = 'tab',
  tabs
}: {
  center?: boolean
  compact?: boolean
  useUrl?: boolean
  tabs: TabAndContents<TProps>[]
  urlParam?: string
}) {
  const updateParam = useUpdateSearchParam(urlParam)
  const paramValue = useSearchParam.string(urlParam, tabs[0]?.id)

  function config() {
    if (useUrl) {
      return {
        activeTabId: paramValue,
        setActiveTabId: useCallback(
          (id?: string) => (id ? updateParam.set(id) : updateParam.remove()),
          [updateParam]
        )
      }
    }
    const [_activeTabId, _setActiveTabId] = useState<string>(paramValue)
    return {
      activeTabId: _activeTabId,
      setActiveTabId: _setActiveTabId
    }
  }
  const { activeTabId, setActiveTabId } = config()
  const render = useCallback(
    (props: TProps) => {
      const Contents = tabs.find(({ id }) => id === activeTabId)?.contents ?? NotFoundPanel
      return (
        <>
          <Tabstrip center={center} compact={compact}>
            {tabs.map((tab) => (
              <TabWrap
                key={tab.id}
                tab={tab}
                showBetaBadge={tab.showBetaBadge}
                active={tab.id === activeTabId}
                setActiveTabId={setActiveTabId}
              />
            ))}
          </Tabstrip>
          <Contents {...props} />
        </>
      )
    },
    [activeTabId, setActiveTabId]
  )

  return {
    setActiveTabId,
    activeTabId,
    showArchived: !activeTabId,
    render
  }
}

function TabWrap({
  active,
  showBetaBadge = false,
  tab,
  setActiveTabId
}: {
  active: boolean
  showBetaBadge?: boolean
  tab: Pick<TabAndContents<any>, 'id' | 'label'>
  setActiveTabId(id: string): void
}) {
  const activate = useCallback(() => {
    setActiveTabId(tab.id)
  }, [setActiveTabId, tab])
  return (
    <>
      <Tab key={tab.id} active={active} onClick={activate}>
        {tab.label}
      </Tab>
      {showBetaBadge && <NewBadge>Beta</NewBadge>}
    </>
  )
}
