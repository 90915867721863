import { JSONSchema7TypeName } from 'json-schema'
import { GetSchema_getSchema } from 'src/queries/types/GetSchema'
import { GetSchemaFunctions_getSchema_getSchemaFunctions } from 'src/queries/types/GetSchemaFunctions'
import { GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas_linkedSchemas } from 'src/queries/types/GetWorkspaceDataRequestSchemas'
import { SmartGetSchema_getSchema } from 'src/smart/queries/types/SmartGetSchema'
import { SmartGetSchemas_getSchemas_data } from 'src/smart/queries/types/SmartGetSchemas'
import { EPropertyType } from 'src/utils/data/Schema'

export type ISchemaStubMinimal = Pick<GetSchema_getSchema, 'id' | 'name'>

export type ISchemaStub = SmartGetSchemas_getSchemas_data

export type ISchemaFull = SmartGetSchema_getSchema

export interface ISchema extends Omit<GetSchema_getSchema, 'jsonSchema' | 'slug'> {
  jsonSchemaPropArray?: JsonSchemaProperty[]
  linkedSchemaIds?: number[]
  jsonSchema?: { schema: IJsonSchemaSchema }
  slug?: string
}

export interface IJsonSchemaSchema {
  properties?: { [key: string]: JsonSchemaProperty }
  type?: JsonSchemaPropertyType
  required?: string[]
  unique?: string[]
  primary?: string
  linkedProperties?: { [key: string]: JsonSchemaProperty }
  allowCustomFields?: boolean
}

export interface JsonSchemaProperty {
  sdkProvided?: boolean
  description?: string
  enum?: string[]
  enumArray?: { label: string; value: string }[]
  enumLabel?: string[]
  enumLabelArray?: string[] //todo: delete this
  field?: string
  format?: string
  isMultiple?: boolean
  items?: {
    type: JsonSchemaPropertyType
    enum?: string[]
    enumLabel?: string[]
  }
  label: string
  minimum?: number
  maximum?: number
  regexp?: { pattern: string; flags: string; ignoreBlanks?: boolean }
  required?: boolean
  type: JsonSchemaPropertyType
  exportDateFormat?: string
  unique?: boolean
  primary?: boolean
  $schema?: any
  $schemaId?: string
  sourceTemplate?: string
  default?: string
  custom?: boolean
  visibility?: JsonSchemaPropertyVisibility
}

export type JsonSchemaPropertyVisibility = {
  mapping?: boolean
  review?: boolean
  export?: boolean
}

export interface ISchemaProperty extends JsonSchemaProperty {
  field: string
}

export type JsonSchemaPropertyType =
  | JSONSchema7TypeName
  | 'category'
  | 'date'
  | 'email'
  | 'phone'
  | 'schema_ref'

export const SCHEMA_DATA_TYPES: { label: string; value: string }[] = [
  {
    label: 'String',
    value: 'string'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Boolean',
    value: 'boolean'
  },
  {
    label: 'Category',
    value: 'category'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Phone',
    value: 'phone'
  },
  {
    label: 'Link to template',
    value: EPropertyType.SCHEMA_REF
  }
  // {
  //   label: 'Date',
  //   value: 'date'
  // },
]

export const PRIMARY_COLUMN_DATA_TYPE_VALUES = ['string', 'number', 'email', 'phone']

export const PRIMARY_COLUMN_DATA_TYPES = SCHEMA_DATA_TYPES.filter((t) =>
  PRIMARY_COLUMN_DATA_TYPE_VALUES.includes(t.value)
)

export const EXPORT_DATE_FORMATS: { label: string; value: string }[] = [
  { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
  { label: 'MM/DD/YY (leading zeros)', value: 'MM/dd/yy' },
  { label: 'M/D/YY (no leading zeros)', value: 'M/d/yy' },
  { label: 'DD/MM/YY (leading zeros)', value: 'dd/MM/yy' },
  { label: 'D/M/YY', value: 'd/M/yy' },
  // { label: 'unix seconds', value: 'unix seconds' },
  { label: 'ms', value: 'T' }
  // { label: 'ns', value: 'ns' }
]

export const IMPORT_DATE_FORMATS = EXPORT_DATE_FORMATS

export const DELETABLE_KEYS: (keyof JsonSchemaProperty)[] = [
  'field',
  'required',
  'unique',
  'primary',
  'isMultiple',
  'enumArray'
]

export interface IMatchData {
  [key: string]: string
}

export type ISchemaFunction = GetSchemaFunctions_getSchema_getSchemaFunctions

export type ILinkedSchema =
  GetWorkspaceDataRequestSchemas_getWorkspaceDataRequestSchemas_linkedSchemas
