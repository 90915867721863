import { useContext, useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import { CreateAccessKeyForm } from 'src/applications/Oversight/forms/CreateAccessKeyForm'
import { CreatedAccessKeyForm } from 'src/applications/Oversight/forms/CreatedAccessKeyForm'
import { recycle } from 'src/applications/Oversight/recycle'
import { TeamContext } from 'src/contexts/TeamContext'
import { useCreateAccessKeyMutation } from 'src/queries/hooks/useCreateAccessKeyMutation'
import { IModal, useModal } from 'src/resources/elements/Modal'
import { grow } from 'src/utils/grow'
import { detach } from 'src/utils/promise'
import { ref } from 'src/utils/ref'

const AccessKeyModalHead = () => {
  const { addToast } = useToasts()
  const [accessKeySuccess, setAccessKeySuccess] = useState(false)
  const [createAccessKey, createAccessKeyStatus] = useCreateAccessKeyMutation()

  if (!accessKeySuccess && createAccessKeyStatus.data) {
    window.setTimeout(() => {
      setAccessKeySuccess(true)
      addToast('Access key created successfully!', {
        appearance: 'success',
        autoDismiss: true
      })
    })
  }

  return { createAccessKey, createAccessKeyStatus }
}

export const useCreateAccessKeyModal = grow(
  AccessKeyModalHead,
  ({ createAccessKey, createAccessKeyStatus }) =>
    ref<IModal>((self) => {
      const team = useContext(TeamContext)

      return useModal({
        header: 'Create access key',
        width: '500px',
        contents: () => (
          <>
            {createAccessKeyStatus.data ? (
              <CreatedAccessKeyForm
                data={{
                  accessKeyId: createAccessKeyStatus.data.createAccessKey.accessKey.accessKeyId,
                  secretAccessKey:
                    createAccessKeyStatus.data.createAccessKey.secretAccessKey.secret,
                  teamId: team.id
                }}
                onSubmit={async () => self.current.close()}
              />
            ) : (
              <CreateAccessKeyForm
                onSubmit={async (event) => {
                  await createAccessKey({
                    variables: {
                      licenseKey: team.licenses[0].key,
                      memo: event.data.memo,
                      teamId: team.id
                    }
                  })
                }}
                onCancel={() => self.current.close()}
              />
            )}
          </>
        ),
        onClose: () => {
          if (createAccessKeyStatus.data) {
            detach(recycle())
            return false
          }
          return true
        }
      })
    })
)
