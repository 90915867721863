import { useEffect } from 'react'
import { Prompt } from 'react-router'

export function useWarnBeforeUnload(active: boolean, message: string) {
  useEffect(() => {
    if (active) {
      const warnBeforeUnload = (ev: BeforeUnloadEvent): string => {
        ev.preventDefault()
        ev.returnValue = true
        return message
      }
      window.addEventListener('beforeunload', warnBeforeUnload)
      return () => {
        window.removeEventListener('beforeunload', warnBeforeUnload)
      }
    }
  }, [active, message])

  return <Prompt when={active} message={message} />
}
