import { gql } from 'apollo-boost'
import {
  KnownGraphQLURIs,
  KnownGraphQLURIsVariables
} from 'src/smart/queries/types/KnownGraphQLURIs'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_KNOWN_GRAPHQL_URIS: SmartQuery<
  KnownGraphQLURIsVariables,
  KnownGraphQLURIs,
  string[]
> = {
  query: gql`
    query KnownGraphQLURIs($teamId: ID!, $archived: Boolean) {
      getKnownGraphQLURIs(teamId: $teamId, archived: $archived) {
        value
      }
    }
  `,
  extract({ getKnownGraphQLURIs }) {
    return getKnownGraphQLURIs.map((x) => x.value)
  }
}
