export enum EViewStatus {
  ARCHIVED = 'archived',
  DATA_LOADED = 'dataLoaded',
  HEADER_ROW_SELECTED = 'headerRowSelected',
  INITIALIZED = 'initialized',
  LOADED = 'loaded',
  MATCHED = 'matched',
  PROCESS_FAILED = 'processFailed',
  SCHEMA_MAPPED = 'schemaMapped',
  UPLOADED = 'uploaded',
  UPLOAD_FAILED = 'uploadFailed'
}
