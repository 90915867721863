import { gql } from '@apollo/client'

export const UPDATE_SCHEMA = gql`
  mutation UpdateSchema(
    $schemaId: ID!
    $archived: Boolean
    $jsonSchema: JsonSchemaDto
    $name: String
    $previewFieldKey: String
  ) {
    updateSchema(
      schemaId: $schemaId
      archived: $archived
      jsonSchema: $jsonSchema
      name: $name
      previewFieldKey: $previewFieldKey
    ) {
      id
    }
  }
`
