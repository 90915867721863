import { useSubscription } from '@apollo/client'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { Span } from '@sentry/types'
import { EMAIL_LOGIN_SUBSCRIPTION } from 'src/queries/EMAIL_LOGIN_SUBSCRIPTION'
import { EmailLogin, EmailLoginVariables } from 'src/queries/types/EmailLogin'
import { getUserContext } from 'src/contexts/UserContext'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { getFormContext } from 'src/resources/elements/form/Form'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_REQUEST_LOGIN } from 'src/smart/mutations/SM_REQUEST_LOGIN'
import styled from 'styled-components'
import useRouter from 'use-react-router'
import { updateClientLink } from 'src/resources/clients/graphClient'

const Container = styled.div`
  margin-top: ${Spacing.basePadding2x};
  button {
    width: 100%;
    margin-bottom: 28px;
  }
`

const HiddenPasswordInput = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
`

enum EEmailLoginFormState {
  INTIAL,
  SUBMITTING,
  WAITING_FOR_APPROVAL
}

const WaitingForApproval = styled.div`
  p {
    margin-bottom: ${Spacing.basePadding2x};
  }
`

const ApprovalStatus = styled.p`
  text-align: center;
  padding: ${Spacing.basePadding3x};
`

// Sentry performance tracking
const loginSpans: Partial<Span>[] = [
  {
    op: 'login-with-email-loaded'
  },
  {
    op: 'login-with-email-onsubmit'
  },
  {
    op: 'login-with-email-success'
  }
]

export function SignInViaEmail({
  emailInputRef,
  rememberMeInputRef,
  onSubmit,
  onComplete
}: {
  emailInputRef: MutableRefObject<HTMLInputElement>
  rememberMeInputRef: MutableRefObject<HTMLInputElement>
  onSubmit: () => void
  onComplete: () => void
}) {
  const [emailSentTo, setEmailSentTo] = useState<string>()
  const [formState, setFormState] = useState<EEmailLoginFormState>(EEmailLoginFormState.INTIAL)
  const [eventId, setEventId] = useState(null)
  const requestLogin = useSmartMutation(SM_REQUEST_LOGIN)
  const { value, setValue } = getFormContext()
  const router = useRouter()
  const userContext = getUserContext()
  const sentryTransaction = useSentryTransaction({
    name: '/login',
    spans: loginSpans
  })

  submitSentrySpan(sentryTransaction, 'login-with-email-loaded')

  const { data: { emailLoginApproved } = {} } = useSubscription<EmailLogin, EmailLoginVariables>(
    EMAIL_LOGIN_SUBSCRIPTION,
    {
      skip: !eventId,
      variables: { eventId }
    }
  )

  useEffect(() => {
    if (emailLoginApproved) {
      const { user, accessToken } = emailLoginApproved
      userContext.setValue({ user })
      updateClientLink(accessToken)
      if (user) {
        submitSentrySpan(sentryTransaction, 'login-with-email-success')
        sentryTransaction.transaction.finish()
      }
      router.history.replace('/')
    }
  }, [emailLoginApproved])

  const sendEmail = useCallback(async () => {
    const email = emailInputRef.current?.value
    const rememberMe = rememberMeInputRef.current?.checked
    if (!email) {
      setValue({ errors: { username: 'Please enter an email address' } })
    }
    setFormState(EEmailLoginFormState.SUBMITTING)
    onSubmit()
    const result = await requestLogin.run({ email, rememberMe })
    if (result.success) {
      setEmailSentTo(email)
      setFormState(EEmailLoginFormState.WAITING_FOR_APPROVAL)
      setEventId(result.eventId)
      submitSentrySpan(sentryTransaction, 'login-with-email-onsubmit')
    } else {
      setValue({ errors: { username: 'Unable to log in at this time, please try again later' } })
      setFormState(EEmailLoginFormState.INTIAL)
      onComplete()
    }
  }, [value, setValue])
  const buttonDisabled =
    !emailInputRef.current?.value || formState === EEmailLoginFormState.SUBMITTING
  return (
    <Container>
      {/* this is here so browsers will try to autofill email */}
      <HiddenPasswordInput type='password' name='password' />
      {formState === EEmailLoginFormState.WAITING_FOR_APPROVAL ? (
        <WaitingForApproval>
          <p>
            If <strong>{emailSentTo}</strong> is a valid Flatfile account, we just sent an email
            requesting approval for this log in attempt.
          </p>
          <p>Don't close this tab, you will be automatically redirected when approved.</p>
          <ApprovalStatus>
            <Spinner /> Waiting for approval
          </ApprovalStatus>
        </WaitingForApproval>
      ) : (
        <FlatButton
          aria-label='Send me an email to complete log in'
          disabled={buttonDisabled}
          onClick={sendEmail}
          size='lg'
          type='button'
        >
          Sign in with email
        </FlatButton>
      )}
    </Container>
  )
}
