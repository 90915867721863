import { MutationTuple, useMutation } from '@apollo/client'
import { CONFIRM_UPLOAD_DATA_SOURCE_FIELDS } from 'src/queries/CONFIRM_UPLOAD_DATA_SOURCE_FIELDS'
import {
  ConfirmUploadDataSourceFields,
  ConfirmUploadDataSourceFieldsVariables
} from 'src/queries/types/ConfirmUploadDataSourceFields'

export const useConfirmUploadDataSourceFieldsMutation = (): MutationTuple<
  ConfirmUploadDataSourceFields,
  ConfirmUploadDataSourceFieldsVariables
> => {
  const [confirmUploadDataSourceFields, confirmUploadDataSourceFieldsVariables] = useMutation<
    ConfirmUploadDataSourceFields,
    ConfirmUploadDataSourceFieldsVariables
  >(CONFIRM_UPLOAD_DATA_SOURCE_FIELDS)

  return [confirmUploadDataSourceFields, confirmUploadDataSourceFieldsVariables]
}
