import { gql } from '@apollo/client'
import {
  SmartGetViewSchema,
  SmartGetViewSchemaVariables,
  SmartGetViewSchema_getViewSchema
} from 'src/smart/queries/types/SmartGetViewSchema'
import { SmartQuery } from 'src/smart/SmartQuery'

type SchemaSmartQueryType = SmartQuery<
  SmartGetViewSchemaVariables,
  SmartGetViewSchema,
  SmartGetViewSchema_getViewSchema
>

export const SQ_VIEW_SCHEMA: SchemaSmartQueryType = {
  query: gql`
    query SmartGetViewSchema($viewId: Int!) {
      getViewSchema(viewId: $viewId) {
        archived
        createdAt
        id
        importCount
        name
        teamId
        updatedAt
        jsonSchema {
          schema
        }
        linkedSchemas {
          id
          name
          jsonSchema {
            schema
          }
        }
      }
    }
  `,
  extract({ getViewSchema }) {
    return getViewSchema
  }
}
