import { gql } from '@apollo/client'

import {
  GetFeatureTeams,
  GetFeatureTeamsVariables,
  GetFeatureTeams_getFeatureTeams
} from 'src/smart/queries/types/GetFeatureTeams'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_FEATURE_TEAMS: SmartQuery<
  GetFeatureTeamsVariables,
  GetFeatureTeams,
  GetFeatureTeams_getFeatureTeams
> = {
  query: gql`
    query GetFeatureTeams($featureId: Int!, $take: Int!, $skip: Int!) {
      getFeatureTeams(featureId: $featureId, take: $take, skip: $skip) {
        pagination {
          currentPage
          limit
          nextOffset
          offset
          onPage
          pageCount
          previousOffset
          totalCount
        }
        data {
          id
          name
        }
      }
    }
  `,
  extract({ getFeatureTeams }) {
    return getFeatureTeams
  }
}
