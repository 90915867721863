import { useMemo, useState } from 'react'
import ReactSelect, { components, SingleValueProps } from 'react-select'
import {
  DropdownHeading,
  DropdownOption,
  ExternalSchemaName,
  HeadingWrapper,
  LinkedGroupWrapper,
  RightChevronWrapper,
  Sparkle,
  StyledRightChevron,
  TypeLabel
} from 'src/applications/Oversight/scenes/MatchingV3/styles'
import { Colors } from 'src/resources/colors'
import { BreadcrumbArrow, ClearIndicatorIcon, RightChevron } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import { IMatchData } from 'src/types/interfaces/ISchema'
import { useTheme } from 'styled-components'
import { ColumnOptionKey } from '../helpers'
import { useTranslation } from 'react-i18next'

const CUSTOM_FIELD = '__INTERNAL_CUSTOM_FIELD__'

export interface IMenuOption {
  label?: string
  value?: string
  required?: boolean
  $schema?: {
    id: string
    name: string
    matchKey: string
    matchKeyLabel: string
  }
  type?: string
  unique?: boolean
  templateName?: string
  custom?: boolean
  isLinkedTemplate?: boolean
  options?: IMenuOption[]
  isDisabled?: boolean
  subheading?: boolean
  columnOption?: ColumnOptionKey
}

const ClearIndicator = (props: any) => {
  const theme: any = useTheme()
  // The below condition can be refactored into something like invertedIcon:boolean + iconColor:string in theme
  const colors = theme.sage
    ? { color: '#8B93A4', backgroundColor: 'transparent' }
    : theme.flexport
    ? { color: '#8B93A4', backgroundColor: 'transparent' }
    : {}
  return (
    <components.ClearIndicator {...props}>
      <ClearIndicatorIcon {...colors} />
    </components.ClearIndicator>
  )
}

const Group = (props: any) => {
  const { label, subheading } = props.data
  const { inputValue, value } = props.selectProps
  const [expanded, setExpanded] = useState<boolean>(
    value?.$schema?.matchKeyLabel === props.label || !props?.data?.isLinkedTemplate
  )

  return (
    <components.Group {...props}>
      {subheading && (
        <>
          <DropdownHeading onClick={() => setExpanded(!expanded)}>
            <StyledRightChevron expanded={expanded} />
            {label}
          </DropdownHeading>
          {props?.data?.isLinkedTemplate && <TypeLabel>linked</TypeLabel>}
          {(expanded || inputValue) && <LinkedGroupWrapper>{props.children}</LinkedGroupWrapper>}
        </>
      )}
    </components.Group>
  )
}

const Option = (props: any) => {
  const { templateName, required, label, unique, custom, columnOption, isDisabled, $schema } =
    props.data
  const prefix = useMemo(() => $schema?.matchKeyLabel, [$schema])
  const { t } = useTranslation()
  const searchValue = props.selectProps.inputValue
  const labelWithSearchTerm = label?.split(new RegExp(`(${searchValue})`, 'gi'))

  return (
    <components.Option {...props}>
      {isDisabled && label === 'suggested' && (
        <HeadingWrapper>
          {t('suggested')}
          <Sparkle />
        </HeadingWrapper>
      )}
      {isDisabled && label !== 'suggested' && <HeadingWrapper>{t(label)}</HeadingWrapper>}
      {columnOption === ColumnOptionKey.suggested && (
        <DropdownOption>
          {templateName && (
            <span>
              {templateName}
              <RightChevronWrapper>
                <RightChevron />
              </RightChevronWrapper>
            </span>
          )}
          {prefix && (
            <ExternalSchemaName>
              <span>{prefix}</span>
              <BreadcrumbArrow />
            </ExternalSchemaName>
          )}
          {t(label)}
        </DropdownOption>
      )}
      {columnOption !== ColumnOptionKey.suggested && !isDisabled && (
        <>
          {searchValue && labelWithSearchTerm ? (
            labelWithSearchTerm.map((letter: string, i: number) => (
              <span
                key={i}
                style={
                  letter.toLowerCase() === searchValue.toLowerCase() ? { fontWeight: 600 } : {}
                }
              >
                {letter}
              </span>
            ))
          ) : (
            <DropdownOption>
              {prefix && (
                <ExternalSchemaName>
                  <span>{prefix}</span>
                  <BreadcrumbArrow />
                </ExternalSchemaName>
              )}
              {t(label)}
            </DropdownOption>
          )}
          {required && <TypeLabel required>*</TypeLabel>}
          {unique && <TypeLabel>{' ' + t('unique')}</TypeLabel>}
          {custom && <TypeLabel>{' ' + t('custom')}</TypeLabel>}
        </>
      )}
    </components.Option>
  )
}

const SingleValue = (props: SingleValueProps<IMenuOption>) => (
  <components.SingleValue {...props}>
    {props.data.$schema && (
      <ExternalSchemaName>
        <span>{props.data.$schema?.matchKeyLabel}</span>
        <BreadcrumbArrow />
      </ExternalSchemaName>
    )}
    {props.data.label}
  </components.SingleValue>
)
interface IMenuSelect {
  name: string
  selectedValue?: IMenuOption
  columnOptions: IMenuOption[]
  skipConfirm?: boolean
  validateOption?: (value: string) => { fieldId: string; label: string } | null
  onUpdate: (p: Partial<IMatchData>, s?: boolean) => void
  onAddCustomField?: () => void
  changesApproved?: boolean
}

export function MenuSelect({
  name,
  selectedValue,
  columnOptions,
  validateOption,
  onUpdate,
  onAddCustomField
}: IMenuSelect) {
  const theme: any = useTheme()
  const { t } = useTranslation()
  const handleOnChange = (option: IMenuOption) => {
    if (!option) {
      onUpdate({ [name]: null })
      return
    }

    if (option.value === CUSTOM_FIELD && onAddCustomField) {
      onAddCustomField()
      return
    }

    if (option.value === selectedValue?.value) {
      return
    }

    if (typeof validateOption === 'function') {
      const optionToReplace = validateOption(option.value)

      if (optionToReplace) {
        onUpdate({
          [optionToReplace.fieldId]: null,
          [name]: option.value
        })
        return
      }
    }

    onUpdate({ [name]: option.value })
  }

  const filterOption = useMemo(
    () => (option: { label: string }, input: string) => {
      if (!input) return true

      if (Object.keys(ColumnOptionKey).includes(option.label)) {
        const headerColumn = columnOptions.filter(
          ({ columnOption }) => columnOption === option.label
        )

        return !!headerColumn.some(({ label }) => label.match(new RegExp(input, 'i')))
      }

      return !!option.label.match(new RegExp(input, 'i'))
    },
    [columnOptions]
  )

  return (
    <ReactSelect
      name={name}
      value={selectedValue || null}
      isClearable
      onChange={handleOnChange}
      options={[...columnOptions].concat(
        onAddCustomField
          ? [
              {
                label: t('addAsCustom'),
                value: CUSTOM_FIELD
              }
            ]
          : []
      )}
      placeholder={t('searchOrSelect')}
      components={{
        ClearIndicator,
        Group,
        IndicatorSeparator: null,
        Option,
        SingleValue
      }}
      maxMenuHeight={500}
      menuPortalTarget={document.body}
      menuPlacement='auto'
      menuShouldBlockScroll
      filterOption={filterOption}
      styles={{
        control: (base, state) => ({
          ...base,
          borderRadius: theme.dropdownBorderRadius ?? theme?.borderRadius ?? '6px',
          boxShadow: 'none!important',
          background: state.isFocused ? '#fff' : state.hasValue ? 'transparent' : '#fff',
          borderColor: state.isFocused
            ? theme?.borderColor ?? Colors.border
            : selectedValue
            ? 'transparent'
            : theme?.borderColor ?? Colors.border,
          border: state.hasValue
            ? `${theme?.borderWidth ?? '1px'} solid transparent`
            : `${theme?.borderWidth ?? '1px'} solid ${theme?.borderColor ?? Colors.border}`,
          '&:hover': {
            backgroundColor: '#FFF',
            borderColor: theme?.borderColor ?? Colors.border
          },
          cursor: 'pointer'
        }),
        placeholder: (base) => ({
          ...base,
          color: theme?.placeholderColor ?? Colors.brandPlaceholderText,
          fontSize: theme?.contentSmallFontSize ?? 16,
          fontWeight: 400,
          fontFamily: theme?.customFontStack ?? fontFamily.fontPrimary
        }),
        groupHeading: () => ({
          display: 'none'
        }),
        group: (base) => ({
          ...base,
          padding: `${Spacing.halfBasePadding} 0`,
          cursor: 'pointer'
        }),
        singleValue: (base) => ({
          ...base,
          color: theme?.textColor ?? Colors.text,
          fontSize: theme?.contentFontSize || 16,
          fontWeight: theme?.sage ? 400 : theme?.flexport ? 700 : 500,
          fontFamily: theme?.customFontStack ?? fontFamily.fontPrimary
        }),
        option: (provided, state) => ({
          ...provided,
          color: theme?.textColor ?? Colors.textLogo,
          fontSize: theme?.contentSmallFontSize ?? 16,
          fontFamily: theme?.customFontStack ?? fontFamily.fontPrimary,
          background: state.isSelected ? Colors.selectHighlight : Colors.white,
          padding: `${Spacing.basePadding} ${Spacing.basePadding4x}`,
          cursor: state.isDisabled ? 'default' : 'pointer',
          ':hover': {
            background: state.isDisabled ? 'unset' : theme?.rowHoverColor ?? Colors.selectHover
          },
          ':active': {
            background: state.isSelected || state.isFocused ? Colors.selectHighlight : Colors.white
          },
          ...(state.data?.value === CUSTOM_FIELD
            ? {
                background: Colors.pigeon100,
                borderTop: `1px solid ${Colors.pigeon400}`
              }
            : {})
        }),
        clearIndicator: (base) => ({
          ...base,
          color: theme?.borderColor ?? Colors.pigeon700,
          cursor: 'pointer',
          '&:hover': {
            color: theme?.borderColor ?? Colors.pigeon800
          }
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          color: theme?.borderColor ?? Colors.pigeon700,
          cursor: 'pointer',
          '&:hover': {
            color: theme?.borderColor ?? Colors.pigeon800
          },
          ...(state.hasValue ? { display: 'none' } : {})
        }),
        menu: (base) => ({
          ...base,
          borderRadius: theme.dropdownBorderRadius ?? theme?.borderRadius ?? '4px',
          boxShadow:
            theme?.largeBoxShadow ??
            '0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1)',
          border: theme?.cardsHaveBorder ?? `1px solid ${theme?.borderColor}`,
          left: '0',
          top: (theme?.sage || theme?.flexport) && '-8px'
        }),
        menuPortal: (base) => ({
          ...base,
          width: '368px',
          zIndex: 9999
        })
      }}
    />
  )
}
