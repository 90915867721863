import Skeleton from 'react-loading-skeleton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { ClickableTR, NewStyledTable } from 'src/resources/elements/Table'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`

export const WorkspaceOrganizationsSkeleton = named('WorkspaceOrganizationsSkeleton', () => {
  return (
    /* eslint-disable react/forbid-component-props */
    <>
      <PageHeaderContainer header='Organizations'>
        <RightSide>
          <Skeleton width={168} height={35} />
        </RightSide>
      </PageHeaderContainer>
      <NewStyledTable>
        <thead>
          <tr>
            <td>Name</td>
            <td align='right'>Created At</td>
          </tr>
        </thead>
        <tbody>
          <ClickableTR disableClick>
            <td>
              <Skeleton width={240} />
            </td>
            <td align='right'>
              <Skeleton width={100} />
            </td>
          </ClickableTR>
        </tbody>
      </NewStyledTable>
    </>
  )
})
