import { EPropertyType } from '../Schema'
import { Primitive } from '../VirtualRecord'
import { SchemaProperty } from './SchemaProperty'

export class EnumSchemaProperty extends SchemaProperty {
  public readonly type = EPropertyType.ENUM

  /**
   * Return a list of properly formatted enum options based on the JSON Schema
   */
  get options(): IEnumOption[] {
    return this.definition.enum.map((value, i) => {
      const label = this.definition.enumLabel[i]
      return { value, label }
    })
  }
}

export interface IEnumOption {
  label: string
  value: Primitive
  icon?: string
}
