import { gql } from '@apollo/client'

export const UPDATE_UPLOAD_STATUS = gql`
  mutation UpdateUploadStatus(
    $uploadId: String!
    $status: String!
    $failureReason: String
    $parts: [String!]
  ) {
    updateUploadStatus(
      uploadId: $uploadId
      status: $status
      failureReason: $failureReason
      parts: $parts
    ) {
      upload {
        id
        status
      }
      view {
        id
        status
        type
      }
    }
  }
`
