import { FC } from 'react'
import * as Layout from 'src/applications/Embed/elements/LayoutElements'
import { Footer } from 'src/applications/Embed/fragments/Footer'
import { MobileNotAllowedIcon } from 'src/resources/icons/MobileNotAllowedIcon'
import { hasChild } from 'src/utils/has.child'
import { ShowOnLarge, ShowOnSmall } from './Responsive'
import { useTranslation } from 'react-i18next'

/**
 * TODO: remove inline styling here
 */
export const EmbedLayout: FC<{ hasFooter?: boolean }> = ({
  children,
  hasFooter: hasFooterProp
}) => {
  const { t } = useTranslation()
  const hasSidebar = hasChild(children, Layout.Sidebar)
  const hasFooter = hasFooterProp ?? hasChild(children, Footer)
  return (
    <Layout.Wrapper>
      <ShowOnSmall>
        <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
          <div style={{ padding: '40px 0' }}>
            <MobileNotAllowedIcon style={{ width: '55px' }} />
          </div>
          {t('fragments.Layout.mobileDevice')}
        </div>
      </ShowOnSmall>
      <ShowOnLarge>
        <Layout.Container hasSidebar={hasSidebar} hasFooter={hasFooter}>
          {children}
        </Layout.Container>
      </ShowOnLarge>
    </Layout.Wrapper>
  )
}
