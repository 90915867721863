import { gql } from '@apollo/client'

export const GET_ACCESS_KEYS = gql`
  query GetAccessKeys($teamId: ID!, $isActive: Boolean, $workspaceId: UUID) {
    getAccessKeys(teamId: $teamId, isActive: $isActive, workspaceId: $workspaceId) {
      accessKeyId
      createdAt
      expiresAt
      inactivatedAt
      inactivatedByUser {
        id
        name
      }
      memo
      workspace {
        id
        name
      }
    }
  }
`
