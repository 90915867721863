import { gql } from '@apollo/client'
import {
  ResetEmbedPrivateKey,
  ResetEmbedPrivateKeyVariables,
  ResetEmbedPrivateKey_resetEmbedPrivateKey
} from 'src/smart/mutations/types/ResetEmbedPrivateKey'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_RESET_EMBED_PRIVATE_KEY: SmartMutation<
  ResetEmbedPrivateKeyVariables,
  ResetEmbedPrivateKey,
  ResetEmbedPrivateKey_resetEmbedPrivateKey
> = {
  mutation: gql`
    mutation ResetEmbedPrivateKey($embedId: UUID!) {
      resetEmbedPrivateKey(embedId: $embedId) {
        embed {
          id
        }
        privateKeyString
      }
    }
  `,
  extract: (response) => response.resetEmbedPrivateKey
}
