import { MutableRefObject, ReactNode, useRef } from 'react'

import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { Colors } from 'src/resources/colors'
import { getFormContext } from 'src/resources/elements/form/Form'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { HelperText } from 'src/resources/elements/form/FieldLabel'

interface ICheckBoxPrimary {
  hideMargin?: boolean
  type?: string
  disabled?: boolean
}
export const CheckBoxPrimary = styled.div<ICheckBoxPrimary>`
  label {
    color: ${Colors.text};
    font-size: ${fontSizes.type15};
    display: block;
    position: relative;
    padding-left: ${Spacing.basePadding3x};
    cursor: pointer;
    user-select: none;
    margin: 0;
    min-height: 20px;
    font-weight: ${({ type }) => (type === 'secondary' ? '400' : '600')};
  }

  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    margin: auto;
    height: 16px;
    width: 16px;
    background-color: ${({ disabled }) => (disabled ? `${Colors.pigeon200}` : `${Colors.white}`)};
    border: 1px solid ${Colors.border};
    border-radius: 2px;
  }

  label:hover input ~ span {
    background-color: ${({ disabled }) => (disabled ? `${Colors.gray40}` : `${Colors.white}`)};
    ${({ type, disabled }) =>
      type === 'secondary' && !disabled
        ? css`
            border: 1px solid ${Colors.brandPrimary};
          `
        : css`
            border: 1px solid ${Colors.border};
          `}
  }

  label input:checked ~ span {
    background-color: ${({ disabled }) =>
      disabled ? `${Colors.pigeon200}` : `${Colors.brandPrimary}`};
    border: 1px solid
      ${({ type, disabled }) =>
        type === 'secondary' && !disabled ? Colors.brandPrimary : Colors.transparent};
  }

  label input:focus ~ span {
    outline: 1px solid ${Colors.brandPrimary};
    border: 1px solid ${Colors.brandPrimary};
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 1px;
    margin: auto;
    height: 8px;
    width: 3px;
    border: solid ${({ type }) => (type === 'secondary' ? `${Colors.white}` : `${Colors.white}`)};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  label input:checked ~ span:after {
    display: block;
  }

  & + ${HelperText} {
    padding-left: ${Spacing.basePadding3x};
  }
`

export const CheckBoxSlide = styled.div`
  label {
    border-radius: 0.25rem;
    border: 1px solid ${Colors.gray};
    box-sizing: border-box;
    display: inline-block;
    height: 24px;
    overflow: hidden;
    position: relative;
    width: 48px;
  }

  label input {
    display: none;
  }

  span {
    background-color: ${Colors.lightCodeBackground};
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  span:before {
    background-color: ${Colors.gray};
    bottom: 1px;
    content: '';
    height: 24px;
    left: 1px;
    position: absolute;
    transition: 0.4s;
    width: 24px;
    border-radius: 1px;
  }

  span:after {
    content: '';
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.2s;
  }

  label input:checked ~ span:after {
    display: block;
  }

  input:checked + span:before {
    background-color: ${Colors.green};
    transform: translateX(25px);
    right: 1px;
    left: 4px;
  }

  input:checked + span:after {
    left: 41px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid ${Colors.white};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    visibility: visible;
    opacity: 1;
  }
`

const CheckBoxSlideWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 32px;

  p {
    font-size: 0.8125rem;
    color: ${Colors.green};
    font-weight: 600;
    margin-left: 15px;
  }
`

const Label = styled.p<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? `${fontSizes.type12}` : `${fontSizes.type15}`)};
  color: ${Colors.black};
  font-weight: 400;
  margin: 0;
`

interface ICoreCheckboxProps {
  'aria-label'?: string
  children?: ReactNode
  label?: string
  type?: string
  inputRef?: MutableRefObject<HTMLInputElement>
}

export const FormCheckBox = ({
  name,
  tabIndex,
  tooltip,
  disabled,
  ...props
}: {
  name: string
  tabIndex?: number
  tooltip?: string
  disabled?: boolean
} & ICoreCheckboxProps) => {
  const formContext = getFormContext()
  const { data } = formContext.value

  const checked = (name && name in data ? data[name] : '') ?? false

  const update = (newChecked: boolean) => {
    formContext.setValue({ data: { ...data, [name]: newChecked } })
  }

  return (
    <CheckBox
      checked={checked}
      onChange={update}
      tabIndex={tabIndex}
      disabled={disabled}
      tooltip={tooltip}
      {...props}
    />
  )
}

export const CheckBox = ({
  checked,
  children,
  disabled,
  label,
  smallLabel,
  onChange,
  tabIndex,
  tooltip,
  type = 'primary',
  preventDefaultEvent,
  inputRef
}: {
  checked: boolean
  children?: ReactNode
  disabled?: boolean
  label?: string
  smallLabel?: boolean
  tabIndex?: number
  tooltip?: string
  type?: string
  preventDefaultEvent?: boolean
  onChange(newChecked: boolean): void
  inputRef?: MutableRefObject<HTMLInputElement>
}) => {
  const inputElRef = useRef<HTMLInputElement>(null)

  const ref = inputRef ?? inputElRef

  let timer: number

  const primaryChildren = () => (
    <label
      onClick={(e) => {
        if (disabled) {
          return
        }
        if (preventDefaultEvent) {
          e.preventDefault()
          e.stopPropagation()
        }
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          ref.current.checked = !checked
          onChange(!checked)
        }, 10)
      }}
    >
      <Label small={smallLabel}>{label}</Label>
      {children}
      <input
        checked={checked}
        disabled={disabled}
        readOnly
        ref={ref}
        tabIndex={tabIndex || 0}
        type='checkbox'
      />
      <span />
    </label>
  )

  const slideChildren = () => (
    <CheckBoxSlideWrapper>
      <label>
        <input
          checked={checked}
          disabled={disabled}
          readOnly
          tabIndex={tabIndex || 0}
          type='checkbox'
        />
        <span />
      </label>
      {label}
      {children}
    </CheckBoxSlideWrapper>
  )

  return (
    <>
      {type === 'primary' || type === 'secondary' ? (
        <CheckBoxPrimary data-for={tooltip} data-tip={tooltip} disabled={disabled} type={type}>
          {primaryChildren()}
        </CheckBoxPrimary>
      ) : (
        <CheckBoxSlide data-for={tooltip} data-tip={tooltip}>
          {slideChildren()}
        </CheckBoxSlide>
      )}
      {tooltip && <Tooltip id={tooltip} />}
    </>
  )
}
