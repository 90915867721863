import TinyCrossfade from 'react-tiny-crossfade'
import { useRafInterval } from 'ahooks'
import { useState } from 'react'
import styled from 'styled-components'
import { EllipsisLoader } from 'src/applications/Embed/elements/SceneElements'

// Needed to restrict the MarqueeWrap from jumping
const FixedHeight = styled.div`
  height: 50px;
`
const MarqueeWrap = styled(TinyCrossfade)`
  transition: height 0.5s;
  overflow: hidden;

  .before-enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: opacity 0.5s;
  }

  .before-leave {
    opacity: 1;
  }

  .leaving {
    opacity: 0;
    transition: opacity 0.5s;
  }
`

export const MessageMarquee = ({
  loadingStages,
  stageInterval
}: {
  loadingStages: string[]
  stageInterval?: number
}) => {
  const [index, setIndex] = useState(0)
  useRafInterval(() => {
    if (index < loadingStages.length - 1) {
      setIndex(index + 1)
    }
  }, stageInterval)

  return (
    <FixedHeight>
      <MarqueeWrap>
        <span key={index}>
          <EllipsisLoader text={loadingStages && loadingStages[index]} />
        </span>
      </MarqueeWrap>
    </FixedHeight>
  )
}
