import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import styled from 'styled-components'

export const Code = styled.code`
  background: ${Colors.lightCodeBackground};
  border-radius: 4px;
  font-family: ${fontFamily.fontMonospace};
  font-size: 90%;
  margin-left: ${Spacing.basePadding};
  padding: 2px 4px;
`
