import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { Icon } from 'src/resources/elements/Icon'
import { dasherize } from 'src/utils/string'

export function TimelineUser({ user }: { user: { name: string; email: string } }) {
  const tooltipKey = dasherize(user.email)

  return (
    <div data-for={tooltipKey} data-tip={user.email}>
      <Icon name='user' /> {user.name}
      <Tooltip id={tooltipKey} content={user.email} />
    </div>
  )
}
