import { gql } from '@apollo/client'
import {
  queueUpdateRecordStatus,
  queueUpdateRecordStatusVariables,
  queueUpdateRecordStatus_queueUpdateRecordStatus
} from 'src/smart/mutations/types/queueUpdateRecordStatus'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_QUEUE_UPDATE_RECORD_STATUS: SmartMutation<
  queueUpdateRecordStatusVariables,
  queueUpdateRecordStatus,
  queueUpdateRecordStatus_queueUpdateRecordStatus
> = {
  mutation: gql`
    mutation queueUpdateRecordStatus(
      $filter: JSON
      $rowIds: [Int!]
      $validationState: String!
      $workbookId: String!
      $schemaId: Int!
    ) {
      queueUpdateRecordStatus(
        filter: $filter
        rowIds: $rowIds
        validationState: $validationState
        workbookId: $workbookId
        schemaId: $schemaId
      ) {
        id
      }
    }
  `,
  extract: (response) => response.queueUpdateRecordStatus
}
