import { useEffect, useState } from 'react'
import { Colors } from 'src/resources/colors'
import styled, { useTheme } from 'styled-components'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

const StyledCircle = styled.circle`
  stroke: ${themed('primary')};
`

export const ProgressCircle = ({
  percentage = 0,
  size = 240,
  strokeWidth = 18
}: {
  percentage?: number
  size?: number
  strokeWidth?: number
}) => {
  const [percentComplete, setPercentComplete] = useState(0)
  const theme: any = useTheme()
  useEffect(() => {
    if (typeof percentage === 'number') {
      setPercentComplete(Math.floor(percentage))
    }
  }, [percentage])
  const viewBox = `0 0 ${size} ${size}`
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const dash = (circumference * percentComplete) / 100
  return (
    <StyledSpinner viewBox={viewBox}>
      <circle
        cx={size / 2}
        cy={size / 2}
        fill='none'
        r={radius}
        stroke={Colors.pigeon200}
        strokeWidth={`${strokeWidth}px`}
      />
      <StyledCircle
        cx={size / 2}
        cy={size / 2}
        fill='none'
        r={radius}
        stroke={Colors.pigeon700}
        strokeDasharray={`${dash} ${circumference - dash}`}
        strokeLinecap='round'
        strokeWidth={`${percentComplete ? strokeWidth : 0}px`}
        style={{ transition: 'all 0.25s' }}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <text
        dy='15px'
        fill={theme?.text ?? Colors.text}
        fontSize='40px'
        textAnchor='middle'
        x='50%'
        y='50%'
      >
        {`${percentComplete}%`}
      </text>
    </StyledSpinner>
  )
}

const StyledSpinner = styled.svg`
  height: 120px;
  width: 120px;
`
