import { JSXElementConstructor, ReactNode } from 'react'

/**
 * Analyze the children of a react component and check for a specific component
 *
 * @param children
 * @param el
 */
export const hasChild = (children: ReactNode, el: JSXElementConstructor<any>): boolean => {
  const ch = Array.isArray(children) ? children : [children]
  return (
    ch.findIndex((c) => {
      // exclude anything not ReactElement-like
      if (!c || typeof c !== 'object') {
        return false
      }

      // This tells us the item is a ReactElement and we can do an element comparison
      if ('type' in c && c.type === el) {
        return true
      }
    }) >= 0
  )
}
