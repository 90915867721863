import { useContext } from 'react'
import { TeamContext } from 'src/contexts/TeamContext'
import styled from 'styled-components'
import { Spacing } from 'src/resources/layout'
import { Colors } from 'src/resources/colors'
import { fontSizes } from 'src/resources/typography'
import { FlatButton, FlatButtonStyle } from 'src/resources/elements/buttons/FlatButton'
import CTAShapes from 'src/resources/images/cta_shapes.svg'
import Portal3Sample from 'src/resources/images/portal_3_sample.svg'
import { ContentHeader } from 'src/resources/elements/Header'
import { CodeSnippet } from 'src/resources/elements/CodeSnippet'
import { Content } from 'src/applications/Oversight/panels/Concierge/PortalOnboardingPanel'
import { openFrontChat } from 'src/resources/sidebars/PlatformSidebar'
import { GiftIcon } from 'src/resources/elements/Icons'

const ContentArea = styled.div`
  display: block;
  margin-top: ${Spacing.contentPadding};
`

export const PortalV2Panel = ({
  toggleView,
  showCTA
}: {
  toggleView: () => void
  showCTA: boolean
}) => {
  const team = useContext(TeamContext)

  const description =
    "If you're someone that prefers completing a simple test project before you try integrating into your own application, our basic JavaScript installation guide is a quick way to get you started. Let's get you set up!"
  const steps = [
    {
      header: 'License key',
      description:
        "For working with Portal, you'll need to make sure you use the below license key to initiate the importer",
      snippet: `${team.licenses[0].key}`
    },
    {
      header: 'Basic installation with JavaScript ',
      description:
        'This is a basic configuration of the Flatfile importer. It includes field configuration & how to identify your customers.',
      snippet: `<button onclick="importer.requestDataFromUser().then(function(results) { 
    // do something with the results
    importer.displaySuccess('Thanks for uploading your data!')
})">Start Import</button>

<script src="https://unpkg.com/@flatfile/adapter/build/dist/index.min.js"></script>

<script>
const importer = new FlatfileImporter("${team.licenses[0].key}", {
  type: "imports",
  fields: [
      {key: "name", label: "Name"},
      {key: "email", label: "Email"}
    ],
  managed: true,
});

importer.setCustomer({userId: "12345"});
</script>`
    },
    {
      header: 'Import data',
      description:
        'Test the snippet locally or use a code playground like codesandbox to get started.'
    }
  ]

  const title = 'Installation'
  return (
    <ContentArea>
      {showCTA ? (
        <UpgradeCTA toggleView={toggleView} />
      ) : (
        <PortalV3Navigation toggleView={toggleView} />
      )}
      <InstallationContent
        link='https://flatfile.com/developers/'
        text={description}
        title={title}
        steps={steps}
      />
    </ContentArea>
  )
}

const UpgradeCTAContainer = styled.div`
  position: relative;
  background-color: ${Colors.textLogo};
  border-radius: ${Spacing.borderRadius};
  margin-bottom: ${Spacing.basePadding4x};
  padding: ${Spacing.basePadding4x};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 1px 4px 1px rgba(0, 0, 0, 0.07);
`

const UpgradeContent = styled.div`
  display: flex;
  gap: ${Spacing.basePadding2x};
  flex-direction: column;
  color: ${Colors.white};
  width: 60%;

  h1 {
    font-weight: 600;
    font-size: ${fontSizes.type18};
  }

  p {
    font-weight: 400;
    font-size: ${fontSizes.type16};
  }

  div {
    display: flex;
    gap: ${Spacing.basePadding3_5x};
    align-items: center;
  }

  a {
    cursor: pointer;
    color: ${Colors.white};
    font-weight: 600;
    font-size: ${fontSizes.type14};
  }
`

const StyledImage = styled.img<{ variant: 'example' | 'shapes' }>`
  position: absolute;
  bottom: 0;
  right: ${(props): number => (props.variant === 'shapes' ? 100 : 0)}px;
  border-radius: ${Spacing.borderRadius};
`

const UpgradeCTA = ({ toggleView }: { toggleView: () => void }) => {
  return (
    <UpgradeCTAContainer>
      <UpgradeContent>
        <h1>The future of data onboarding is here 🎉</h1>
        <p>
          Flatfile’s Portal 3.0 Embeds has a robust underlying data processing engine, driving
          significant performance improvements and incredible new features.
        </p>
        <div>
          <FlatButton color='electric' onClick={openFrontChat}>
            Talk to your CSM
          </FlatButton>
          <a onClick={toggleView}>Are you a developer? Try it now →</a>
        </div>
      </UpgradeContent>
      <StyledImage variant='shapes' src={CTAShapes} />
      <StyledImage variant='example' src={Portal3Sample} />
    </UpgradeCTAContainer>
  )
}

const PortalV3NavigationContent = styled.div`
  border-radius: ${Spacing.borderRadius};
  margin-bottom: ${Spacing.basePadding4x};
  background-color: ${Colors.pigeon100};
  display: flex;
  gap: ${Spacing.baseAndHalfPadding};
  padding: ${Spacing.basePadding2x};

  p {
    font-size: ${fontSizes.type14};
    font-weight: 600;
    color: ${Colors.pigeon800};
    display: flex;
    gap: ${Spacing.basePadding};
  }

  a {
    cursor: pointer;
    color: ${Colors.sky600};
    :hover {
      color: ${Colors.purpleHover};
    }
  }
`

const PortalV3Navigation = ({ toggleView }: { toggleView: () => void }) => {
  return (
    <PortalV3NavigationContent>
      <GiftIcon />
      <p>
        You are viewing the license key and installation guide for Portal 2.0.
        <a onClick={toggleView}>Click here to switch to Portal 3.0 →</a>
      </p>
    </PortalV3NavigationContent>
  )
}

const StepContainer = styled.li`
  margin-bottom: ${Spacing.basePadding3x};
  pre {
    max-width: 660px;
  }
`

const FlatButtonLink = styled.a`
  ${FlatButtonStyle};
  font-size: ${fontSizes.type17};
  padding: 16px 18px;
`

interface IContentStep {
  header: string
  description?: string
  snippet?: string
}

interface IInstallationContent {
  title?: string
  text?: string
  steps?: IContentStep[]
  link?: string
}

const InstallationContent = ({ link, title, text, steps }: IInstallationContent) => {
  return (
    <Content>
      <h1>{title}</h1>
      <p>{text}</p>
      <ol>
        {steps.map(({ header, description, snippet }, i) => {
          return (
            <StepContainer key={i}>
              <ContentHeader header={`${header}`} description={description} />
              {snippet && (
                <CodeSnippet width={'70%'} copyButton>
                  {snippet}
                </CodeSnippet>
              )}
            </StepContainer>
          )
        })}
      </ol>
      <FlatButtonLink href={link} target='_blank' rel='noopener roreferrer'>
        View documentation
      </FlatButtonLink>
    </Content>
  )
}
