const ALLOWED_SECTIONS = ['workspaces', 'imports', 'setup', 'templates', 'team']

const ALLOWED_TEAM_SEARCHES = ['members', 'customization', 'webhook']

export const normalizeRedirectPath = (
  pathname: string,
  {
    teamId,
    environmentId,
    search
  }: { teamId?: string; environmentId?: string; search?: string } = {}
) => {
  const pathnameArr = pathname.split('/')
  const teamIdPath = pathnameArr[2]
  const environmentIdPath = pathnameArr[4]
  const sectionPath = pathnameArr[5]
  const searchPath = search && sectionPath === ALLOWED_SECTIONS[4] ? search.split('=')[1] : ''

  return `/a/${teamId ?? teamIdPath}/env/${environmentId ?? environmentIdPath}/${
    ALLOWED_SECTIONS.includes(sectionPath)
      ? sectionPath +
        (searchPath && ALLOWED_TEAM_SEARCHES.includes(searchPath) ? `?tab=` + searchPath : '')
      : ''
  }`
}
