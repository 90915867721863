import { useCallback, useEffect, useMemo, useState } from 'react'
import { FeatureForm, IFeatureData } from 'src/applications/Oversight/forms/FeatureForm'
import { Colors } from 'src/resources/colors'
import { IFormContext, IFormSubmitEvent } from 'src/resources/elements/form/Form'
import { InputLabel } from 'src/resources/elements/form/Input'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import {
  ResourceItemComponents,
  ResourceSidebarItem
} from 'src/resources/elements/ResourceSidebarItem'
import {
  ResourceBrowserComponents,
  UpdatedResourceBrowser
} from 'src/resources/elements/UpdatedResourceBrowser'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_UPDATE_FEATURE } from 'src/smart/mutations/SM_UPDATE_FEATURE'
import { SQ_FEATURES } from 'src/smart/queries/SQ_FEATURES'
import { useFeatureUpdatedSubscription } from 'src/smart/queries/SQ_FEATURE_UPDATED_SUBSCRIPTION'
import { IFeature } from 'src/types/interfaces/IFeature'
import styled from 'styled-components'
import { DeveloperFeatureTeams } from './DeveloperFeatureTeams'

const StyledP = styled(ResourceBrowserComponents.StyledP)`
  color: #fff;
`

const developerFeatureItem = {
  ...ResourceItemComponents,
  ItemContainer: styled(ResourceItemComponents.ItemContainer)`
    border-bottom: none;
  `,
  StyledP
}

const developerResourceBrowser = {
  ...ResourceBrowserComponents,
  ActionContainer: styled(ResourceBrowserComponents.ActionContainer)`
    border-bottom: none;
    color: inherit;
  `,
  Container: styled(ResourceBrowserComponents.Container)`
    background: ${Colors.transparent};
    border-top: 2px solid ${Colors.brandPrimary};
  `,
  Resources: styled(ResourceBrowserComponents.Resources)`
    background: ${Colors.transparent};
    border-right: 2px solid ${Colors.brandPrimary};
  `,
  StatusContainer: styled(ResourceBrowserComponents.StatusContainer)`
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-top: 2px solid ${Colors.brandPrimary};
    border-bottom: 2px solid ${Colors.brandPrimary};
    color: inherit;
  `,
  StyledP
}

const FeatureContainer = styled.div`
  color: ${Colors.white};
  padding: ${Spacing.contentPadding};

  h2 {
    margin-top: 0;
  }

  ${InputLabel} {
    color: ${Colors.white};
  }
`

export function DeveloperFeatures() {
  const [showAddNew, setShowAddNew] = useState(false)
  const getFeatures = useSmartQuery(SQ_FEATURES, { variables: { skip: 0, take: 1000 } })
  const [selectedFeatureId, rawSetSelectedFeatureId] = useState<string | number | undefined>()
  const setSelectedFeatureId = useCallback(
    (id: number) => {
      rawSetSelectedFeatureId(id)
      setShowAddNew(false)
    },
    [rawSetSelectedFeatureId, setShowAddNew]
  )
  const emptyFeature = useMemo(
    (): Partial<IFeature> => ({
      archived: false,
      enabled: false,
      key: '',
      name: ''
    }),
    []
  )
  const updateFeature = useSmartMutation(SM_UPDATE_FEATURE)

  const featureUpdated = useFeatureUpdatedSubscription()

  useEffect(() => {
    if (featureUpdated) {
      getFeatures.state.refetch()
    }
  }, [featureUpdated])

  const onSubmit = async (event: IFormSubmitEvent<IFeatureData>) => {
    const errors: IFormContext<IFeatureData>['value']['errors'] = {}
    for (const field of ['key', 'name']) {
      if (!event.data[field as 'key' | 'name']?.length) {
        errors[field as 'key' | 'name'] = 'This field is required'
      }
    }
    if (Object.keys(errors).length > 0) {
      event.formContext.setValue({ errors })
      return
    }
    event.formContext.setValue({ errors: {} })
    const response = await updateFeature.run(event.data)
    setSelectedFeatureId(response.id)
    getFeatures.state.refetch()
  }

  const activeFeatures = useMemo(
    () => getFeatures.result?.filter((x) => !x.archived),
    [getFeatures.result]
  )

  const [hideNew, showNew] = [false, true].map((x) =>
    useCallback(() => setShowAddNew(x), [setShowAddNew])
  )

  useEffect(() => {
    if (selectedFeatureId && showAddNew) {
      hideNew()
    }
  }, [hideNew, selectedFeatureId])

  const selectedFeature = useMemo(
    () =>
      typeof selectedFeatureId === 'number'
        ? getFeatures.result?.find((x) => x.id === selectedFeatureId)
        : undefined,
    [selectedFeatureId, getFeatures.result]
  )

  if (getFeatures.alert) {
    return queryAlert(getFeatures)
  }

  return (
    <UpdatedResourceBrowser
      items={getFeatures.result?.map((feature) => (
        <ResourceSidebarItem
          components={developerFeatureItem}
          enabled={feature.enabled}
          id={feature.id}
          key={feature.id}
          name={feature.name}
          onClick={setSelectedFeatureId}
        />
      ))}
      active={activeFeatures.length}
      addItem={showNew}
      alwaysRenderChildren
      components={developerResourceBrowser}
      selectedResourceId={selectedFeatureId}
      sidebarConfig={{
        description:
          'Global features can be enabled or disabled for all teams (including logged out users).',
        title: 'Flatfile features'
      }}
    >
      <FeatureContainer>
        <h2>
          {showAddNew
            ? 'Create feature'
            : selectedFeatureId
            ? `Edit feature #${selectedFeatureId}`
            : 'No selection'}
        </h2>
        {showAddNew ? (
          <FeatureForm onSubmit={onSubmit} initialValue={emptyFeature} />
        ) : selectedFeature ? (
          <>
            <FeatureForm onSubmit={onSubmit} initialValue={selectedFeature} />
            <DeveloperFeatureTeams feature={selectedFeature} />
          </>
        ) : (
          <p>Select a feature to edit, or press the "+" button to add new.</p>
        )}
      </FeatureContainer>
    </UpdatedResourceBrowser>
  )
}
