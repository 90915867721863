let zIndex = 0

function layer() {
  return zIndex++
}

// move these lines up and down to re-order

export const LowLayer1 = layer()
export const LowLayer2 = layer()
export const LowLayer3 = layer()

export const MidLayer1 = layer()
export const MidLayer2 = layer()
export const MidLayer3 = layer()

export const HighLayer1 = layer()
export const HighLayer2 = layer()
export const HighLayer3 = layer()

export const StickyLayer = layer()

export const SidebarLayer = layer()

export const UploadPanelLayer = layer()

export const ModalLayer = layer()

export const SelectDropdownLayer = 101 // hack

export const ToastLayer = layer()

export const EnvironmentBannerLayer = layer()
