import {
  ChangeEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import Editor from '@monaco-editor/react'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { SchemaContext } from 'src/contexts/SchemaContext'
import { useUpdateSchemaFunctionMutation } from 'src/queries/hooks/useUpdateSchemaFunctionMutation'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { FlatInput, InputLabel } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontMonospace } from 'src/resources/typography'
import { ISchemaFunction } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

const Grow = styled.div`
  flex-grow: 1;
  width: 100%;

  ${InputLabel} {
    margin: 0;
    padding: 16px 62px;
    text-transform: initial;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`

const InfoPanel = styled.div`
  height: 100%;
  min-width: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  h3 {
    font-weight: normal;
    margin: 0;
    padding: ${Spacing.basePadding2x} ${Spacing.basePadding2x} 0;
  }
`

const InputContainer = styled.div`
  padding: ${Spacing.basePadding2x};
  label {
    margin-top: 0;
  }
  & + & {
    padding-top: 0;
  }
  code {
    font-family: ${fontMonospace};
  }
  ${ButtonBase} {
    margin-right: ${Spacing.basePadding};
  }
`

function distinct(a: string | null, b: string | null) {
  if (a === null || a === '') {
    return b !== null && b !== ''
  }
  return a !== b
}

export function EditDataHook({ resource }: { resource: ISchemaFunction }) {
  const schema = useContext(SchemaContext)
  const [archived, setArchived] = useState(resource.archived)
  const [functionMeta, setFunctionMeta] = useState(resource.functionMeta)
  const [height, setHeight] = useState(0)
  const [name, setName] = useState(resource.name)
  const [order, setOrder] = useState(resource.order)
  const [updateSchemaFunction] = useUpdateSchemaFunctionMutation()

  const hasChanges = useMemo(
    () =>
      [
        resource.archived !== archived,
        distinct(resource.functionMeta, functionMeta),
        distinct(resource.name, name),
        resource.order !== order
      ].some((x) => x),
    [archived, functionMeta, name, order, resource]
  )
  const revert = useCallback(() => {
    setArchived(resource.archived)
    setFunctionMeta(resource.functionMeta)
    setName(resource.name)
    setOrder(resource.order)
  }, [])
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()
  const saveAndRedirect = useCallback(async () => {
    const {
      data: { updateSchemaFunction: schemaFunction }
    } = await updateSchemaFunction({
      variables: {
        archived,
        functionMeta,
        id: resource.id,
        name,
        order
      }
    })
    history.push(
      `${teamRoot}/templates/${schemaFunction.schema.id}?tab=data-hooks&dataHookId=${schemaFunction.id}`
    )
  }, [order, resource.id, archived, name, functionMeta])
  const codeContainer = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    setHeight(codeContainer.current.clientHeight)
  }, [setHeight, codeContainer])
  const changeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])
  const changeOrder = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOrder(e.target.valueAsNumber)
  }, [])
  const firstPropName = useMemo(() => {
    return Object.keys(schema.jsonSchema?.schema?.properties ?? {})[0] ?? 'example'
  }, [schema])
  const firstPropExampleValue = useMemo(() => {
    switch ((schema.jsonSchema?.schema?.properties ?? {})[firstPropName]?.type) {
      case 'string':
        return "'new value'"
      case 'number':
        return '123'
      case 'boolean':
        return 'true'
      default:
        return 'null'
    }
  }, [schema])
  return (
    <Wrap>
      <InfoPanel>
        <h3>Data Hook</h3>
        <InputContainer>
          <InputLabel>Name</InputLabel>
          <FlatInput fullWidth onChange={changeName} value={name ?? ''} />
        </InputContainer>
        <InputContainer>
          <InputLabel>Order</InputLabel>
          <FlatInput type='number' fullWidth onChange={changeOrder} value={order ?? 0} />
        </InputContainer>
        <InputContainer>
          <BooleanInput onChange={setArchived} label='Archived' checked={archived} />
        </InputContainer>
        <InputContainer>
          <FlatButton color='secondary' disabled={!hasChanges} onClick={revert}>
            Revert
          </FlatButton>
          <FlatButton disabled={!hasChanges} onClick={saveAndRedirect}>
            Save changes
          </FlatButton>
        </InputContainer>
        <InputContainer>
          <InputLabel>Variables</InputLabel>
          <code>
            {schema.jsonSchema?.schema?.properties &&
              Object.keys(schema.jsonSchema?.schema?.properties).map((key, i) => {
                const property = schema.jsonSchema?.schema?.properties[key]
                return (
                  <pre key={i}>
                    <strong>row.{key}</strong> {String(property.type)}
                  </pre>
                )
              })}
          </code>
        </InputContainer>
        <InputContainer>
          <InputLabel>Help</InputLabel>
          <code>
            <pre>// setup</pre>
            <pre>const info = []</pre>
            <pre>&nbsp;</pre>
            <pre>// modify record value(s)</pre>
            <pre>
              row['{firstPropName}'] = {firstPropExampleValue}
            </pre>
            <pre>&nbsp;</pre>
            <pre>info.push({'{'}</pre>
            <pre>// level can be error, warning, or info</pre>
            <pre> level: 'error',</pre>
            <pre> field: '{firstPropName}',</pre>
            <pre> message: 'Invalid value'</pre>
            <pre>{'}'})</pre>
            <pre>&nbsp;</pre>
            <pre>// return row modifications</pre>
            <pre>// and info (optional)</pre>
            <pre>return {'{ row, info }'}</pre>
          </code>
        </InputContainer>
      </InfoPanel>
      <Grow ref={codeContainer}>
        <InputLabel>JavaScript</InputLabel>
        <Editor
          height={`${height - 48}px`}
          language='javascript'
          onChange={setFunctionMeta}
          value={functionMeta}
        />
      </Grow>
    </Wrap>
  )
}
