import { gql } from '@apollo/client'
import { SmartMutation } from 'src/smart/SmartMutation'
import { ISchemaMutation } from 'src/types/interfaces/ISchemaMutation'

import { UpdateSchemaMutation, UpdateSchemaMutationVariables } from './types/UpdateSchemaMutation'

export const SM_UPDATE_SCHEMA_MUTATION: SmartMutation<
  UpdateSchemaMutationVariables,
  UpdateSchemaMutation,
  ISchemaMutation
> = {
  mutation: gql`
    mutation UpdateSchemaMutation($update: UpdateSchemaMutationDTO!) {
      updateSchemaMutation(update: $update) {
        archived
        createdAt
        enabled
        includeUserHeaders
        graphQLURI
        id
        mutationBody
        mutationType
        resultPrimaryKey
        schemaId
        updatedAt
      }
    }
  `,
  extract: (response) => response.updateSchemaMutation
}
