import { GetBatch_getBatch, GetBatch_getBatch_endUser } from 'src/queries/types/GetBatch'
import { GetBatches_getBatches_data } from 'src/queries/types/GetBatches'

export type IBatch = GetBatch_getBatch | GetBatches_getBatches_data

export type IEndUser = GetBatch_getBatch_endUser

export type IBatchData = GetBatches_getBatches_data

export interface IBatchUploadResponse {
  batchId: any
  uploadId: any
  viewId: any
}

export enum EBatchType {
  legacyUpload = 0,
  unusedFeelFreeToReuseThisType = 1,
  fileUpload = 2,
  robotUpload = 3
}

export enum EFilterEmbedsType {
  noEmbeds = 0,
  embedsOrLegacy = 1,
  embedsOrWorkspace = 2,
  embedsOnly = 3
}

export enum EBatchSource {
  sftp = 1
}
