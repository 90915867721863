import { ReactNode, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

export function TopLevelPortal({ children }: { children: ReactNode }) {
  const hostElement = useMemo(() => document.createElement('div'), [])
  useEffect(() => {
    document.body.appendChild(hostElement)
    return () => {
      document.body.removeChild(hostElement)
    }
  }, [hostElement])
  return ReactDOM.createPortal(children, hostElement)
}
