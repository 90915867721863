import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'

import {
  GetLinkedSheets,
  GetLinkedSheetsVariables,
  GetLinkedSheets_getLinkedSheets
} from './types/GetLinkedSheets'

export const SQ_GET_LINKED_SHEETS: SmartQuery<
  GetLinkedSheetsVariables,
  GetLinkedSheets,
  GetLinkedSheets_getLinkedSheets[]
> = {
  query: gql`
    query GetLinkedSheets($rowIds: [Int!], $filter: JSON, $sheetId: UUID!) {
      getLinkedSheets(filter: $filter, rowIds: $rowIds, sheetId: $sheetId) {
        schema {
          name
        }
        countRecords
      }
    }
  `,
  extract({ getLinkedSheets }) {
    return getLinkedSheets
  }
}
