import { ChangeEvent, ReactNode, MouseEvent, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { FlatButton } from './FlatButton'
import { IFlatButtonBaseAnchor } from './IFlatButton'

const UploadInput = styled.input`
  bottom: 0;
  cursor: pointer;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  ::-webkit-file-upload-button {
    cursor: pointer;
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  position: relative;
`
interface IFlatUploadButton extends Omit<IFlatButtonBaseAnchor, 'onChange'> {
  styleAsLink?: boolean
  children: ReactNode
  multiple?: boolean
  onChange?(files: FileList): void
  onFileSelected?({ file }: { file: File }): void
}

const StyleWrap = ({
  styleAsLink,
  children,
  onClick,
  ...props
}: {
  styleAsLink?: boolean
  children: ReactNode
  onClick: () => void
}) => {
  return styleAsLink ? (
    <>
      <StyledLink>{children}</StyledLink>{' '}
    </>
  ) : (
    <FlatButton renderAs='a' {...props}>
      {children}
    </FlatButton>
  )
}

export const FlatUploadButton = ({
  styleAsLink,
  children,
  onChange,
  onFileSelected,
  multiple = false,
  accept = '*',
  ...props
}: IFlatUploadButton) => {
  if (!onChange && !onFileSelected) {
    throw new Error('Must specify onChange or onFileSelected on <UploadButton />')
  }

  const inputRef = useRef<HTMLInputElement>()
  const triggerFileUpload = useCallback(() => {
    inputRef.current.click()
  }, [inputRef])
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files) return
      if (files.length === 1 && onFileSelected) {
        onFileSelected({ file: files[0] })
      } else if (onChange) {
        onChange(files)
      }
      e.target.value = ''
    },
    [onFileSelected, onChange]
  )
  const stopTheProp = (e: MouseEvent) => e.stopPropagation()

  return (
    <StyleWrap styleAsLink={styleAsLink} {...props} onClick={triggerFileUpload}>
      {children}
      <UploadInput
        ref={inputRef}
        type='file'
        multiple={multiple}
        accept={accept}
        onChange={handleChange}
        onClick={stopTheProp}
      />
    </StyleWrap>
  )
}
