import { useRef } from 'react'
import { useGetSchemas } from 'src/applications/Oversight/hooks/useGetSchemas'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup, ButtonWrap } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'
import { SelectWorkspaceSchemas } from './SelectWorkspaceSchemas'

export interface IEditWorkspaceFormData {
  name: string
  organizationId: string
  schemaIds: string[]
}

const InputContainer = styled.div`
  flex: 1;
  padding: 0 ${Spacing.contentPadding};
`
const FormButtonWrap = styled(ButtonWrap)`
  margin-left: ${Spacing.contentPadding};
`

export function EditWorkspaceForm({
  initialValue,
  onSubmit
}: {
  initialValue: IEditWorkspaceFormData
  onSubmit(e: IFormSubmitEvent<IEditWorkspaceFormData>): void
}) {
  const workspaceFormRef = useRef<HTMLFormElement>()

  const schemasQuery = useGetSchemas(
    { includeIds: initialValue.schemaIds },
    { fetchPolicy: 'network-only' }
  )

  if (schemasQuery.alert) {
    return queryAlert(schemasQuery)
  }

  const allSchemas = schemasQuery.result?.data ?? []

  const EditWorkspaceFormElement: TForm<IEditWorkspaceFormData> = Form

  return (
    <EditWorkspaceFormElement
      disableIfUnchanged
      formRef={workspaceFormRef}
      initialValue={initialValue}
      onSubmit={onSubmit}
    >
      <InputContainer>
        <Input label='Workspace name' name='name' placeholder='Workspace Name' />
        <ErrorMessage name='name' />
      </InputContainer>
      <InputContainer>
        <InputGroup>
          <Input
            label='Organization'
            name='organizationId'
            placeholder='organizationId Name'
            disabled={true}
          />
          <ErrorMessage name='organizationId' />
        </InputGroup>
      </InputContainer>

      <InputContainer>
        <SelectWorkspaceSchemas schemas={allSchemas} />
        <ErrorMessage name='schemas' />
      </InputContainer>
      <FormButtonWrap>
        <ButtonGroup left>
          <FormButton secondary reset>
            Revert
          </FormButton>
          <FormButton primary submit>
            Save changes
          </FormButton>
        </ButtonGroup>
      </FormButtonWrap>
    </EditWorkspaceFormElement>
  )
}
