import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'

import { Colors } from 'src/resources/colors'
import { Expand } from 'src/resources/effects/Expand'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { StyledTab, Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { useLocalStorageState } from 'src/resources/hooks/useLocalStorageState'
import { fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const Container = styled.div`
  border-bottom: 1px solid ${Colors.border};
  display: flex;
  flex-direction: row;
  height: 600px;
  position: relative;

  ${Tabstrip} {
    width: 180px;
    ${StyledTab} + ${StyledTab} {
      margin-top: 0;
    }
    ${StyledTab} span {
      color: ${Colors.pigeon600};
    }
    ${StyledTab}.tab--active span {
      color: ${Colors.brandPrimary};
    }
  }

  div.tab--active {
    background: ${Colors.pigeon100};
  }
`

const Description = styled.div`
  color: ${Colors.textAccent};
  font-size: ${fontSizes.type14};
  p {
    margin-bottom: 0;
  }
`

const ToolSurface = styled.div`
  flex-grow: 1;
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export interface ITool {
  name: string
  description?: JSX.Element
  render: () => JSX.Element
}

function Toolbox({
  tools,
  toolsHeader,
  selectedToolName,
  setSelectedToolName,
  visible
}: {
  tools: ITool[]
  toolsHeader: JSX.Element
  selectedToolName: string
  setSelectedToolName: Dispatch<string>
  visible: boolean
}) {
  const selectTool = useMemo(() => {
    const fns: Record<string, () => void> = {}
    for (const tool of tools) {
      fns[tool.name] = () => setSelectedToolName(tool.name)
    }
    return fns
  }, [setSelectedToolName, tools])

  const selectedTool = useMemo(
    () => tools.find((x) => x.name === selectedToolName),
    [tools, selectedToolName]
  )

  return (
    <Expand visible={visible}>
      <Container>
        <Column>
          {toolsHeader}
          <Tabstrip vertical>
            {tools.map((tool) => (
              <Tab
                active={selectedToolName === tool.name}
                key={tool.name}
                onClick={selectTool[tool.name]}
                vertical
              >
                <span>{tool.name}</span>
                <Description>{tool.description}</Description>
              </Tab>
            ))}
          </Tabstrip>
        </Column>
        <ToolSurface>{visible && selectedTool?.render()}</ToolSurface>
      </Container>
    </Expand>
  )
}

export interface ToolboxConfig {
  enabled: boolean
  name: string
  toolButton: (expanded: boolean) => JSX.Element
  tools: ITool[]
  toolsHeader: JSX.Element
}

export function useToolbox({
  enabled,
  name,
  toolButton,
  tools,
  toolsHeader
}: ToolboxConfig): [JSX.Element, JSX.Element] {
  const [selectedToolName, setSelectedToolName] = useState<string | null>(null)
  const visibilityStorageKey = `toolbox:${name}`
  const [toolboxVisible, setToolboxVisible] = useLocalStorageState(visibilityStorageKey, false)

  const toggleToolboxVisibility = useCallback(() => {
    setToolboxVisible(!toolboxVisible)
  }, [setToolboxVisible, toolboxVisible])
  const toolboxButton = useMemo(
    () => (
      <FlatButton
        color='white'
        key={toolboxVisible ? 1 : 0}
        onClick={toggleToolboxVisibility}
        variant='primary-text'
      >
        {toolButton(toolboxVisible)}
      </FlatButton>
    ),
    [toggleToolboxVisibility, toolboxVisible]
  )
  // Select the first available tool when none selected
  useEffect(() => {
    if (selectedToolName === null && tools.length > 0) {
      setSelectedToolName(tools[0].name)
    }
  }, [tools, selectedToolName, setSelectedToolName])
  if (!enabled) {
    return [null, null]
  }
  const toolbox = (
    <Toolbox
      selectedToolName={selectedToolName}
      setSelectedToolName={setSelectedToolName}
      tools={tools}
      toolsHeader={toolsHeader}
      visible={toolboxVisible}
    />
  )
  return [toolbox, toolboxButton]
}
