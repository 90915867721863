import { gql } from '@apollo/client'

export const GET_ACCOUNT_STATUS = gql`
  query GetAccountStatus($teamId: ID!) {
    getAccountStatus(teamId: $teamId) {
      status
      trialEndsAt
      card_status
      plan
    }
  }
`
