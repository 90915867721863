import { GridCell, GridCellHeader } from 'src/resources/elements/GridRow'
import { IBatch } from 'src/types/interfaces/IBatch'
import { renderDate } from 'src/utils/date'

export function BatchTimes({ batch }: { batch: IBatch }) {
  return (
    <>
      <GridCell>
        <GridCellHeader>Started</GridCellHeader>
        {renderDate(batch.createdAt, true)}
      </GridCell>
      <GridCell>
        <GridCellHeader>{batch.failedAt ? 'Failed' : 'Submitted'}</GridCellHeader>
        {batch.failedAt ? (
          renderDate(batch.failedAt)
        ) : batch.submittedAt ? (
          renderDate(batch.submittedAt, true)
        ) : (
          <>&mdash;</>
        )}
      </GridCell>
    </>
  )
}
