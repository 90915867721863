import { ReactNode } from 'react'
import { Toolbar } from 'src/applications/Oversight/controls/Toolbar'
import { SessionMustNotExist } from 'src/controls/SessionMustNotExist'

interface IPreAuthTemplate {
  children: ReactNode
  onAuthenticatedUrl?: string
}

export const PreAuthTemplate = ({ children, onAuthenticatedUrl = '/' }: IPreAuthTemplate) => (
  <SessionMustNotExist onAuthenticatedUrl={onAuthenticatedUrl}>
    <Toolbar />
    {children}
  </SessionMustNotExist>
)
