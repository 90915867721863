import { gql } from '@apollo/client'
import {
  CreateDataHook,
  CreateDataHookVariables,
  CreateDataHook_createDataHook
} from 'src/smart/mutations/types/CreateDataHook'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_CREATE_DATAHOOK: SmartMutation<
  CreateDataHookVariables,
  CreateDataHook,
  CreateDataHook_createDataHook
> = {
  mutation: gql`
    mutation CreateDataHook($schemaId: ID!) {
      createDataHook(schemaId: $schemaId) {
        id
        schemaId
      }
    }
  `,
  extract: (response: any) => response.createDataHook
}
