import { NewSelectStyles, Select } from 'src/resources/elements/form/Select'
import { Filter, useTableFilters } from 'src/resources/hooks/useTableFilters'
import styled from 'styled-components'

const FilterDropdown = styled.div`
  max-width: 180px;
  width: 100%;
  > div {
    margin-bottom: 0;
  }
`
export interface FilterOption {
  value: Filter
  label: string
}

export const availableFilters: FilterOption[] = [
  { value: '', label: 'All records' },
  { value: 'valid', label: 'Valid records' },
  { value: 'invalid', label: 'Invalid records' }
]

export const availableFiltersWithMerge: FilterOption[] = availableFilters.concat([
  { value: 'merged', label: 'Most recently merged records' }
])

export const FilterSelect = ({ showMerged: merged = false }: { showMerged?: boolean }) => {
  const [{ filter }, setTableFilters] = useTableFilters()
  return (
    <>
      <FilterDropdown>
        <Select
          key={filter}
          defaultValue={filter}
          selectedValue={filter}
          onChange={(option) => setTableFilters({ filter: (option as FilterOption).value })}
          options={merged ? availableFiltersWithMerge : availableFilters}
          placeholder='All records'
          styles={NewSelectStyles}
        />
      </FilterDropdown>
    </>
  )
}
