import { ReactNode } from 'react'

import sanitizeHtml from 'sanitize-html-react'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { Colors } from 'src/resources/colors'
import { InputGroup } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

export const Header = styled.header<{ hasMargin?: boolean }>`
  color: ${Colors.text};
  ${({ hasMargin }) =>
    hasMargin
      ? css`
          margin-bottom: ${Spacing.basePadding4x};
        `
      : null}

  > h1,
    > h2 {
    letter-spacing: 0.0125em;
    margin: 0;
    display: flex;
    align-items: center;
  }

  > h3 {
    font-size: ${fontSizes.type18};
    font-weight: 400;
    color: ${Colors.text};
    margin: 0;
  }

  > h2 {
    font-size: ${fontSizes.type21};
    font-weight: 600;
    color: ${themed('titleFontColor')};
  }

  > h1 {
    font-size: ${fontSizes.type24};
    font-weight: 600;
    line-height: 1.675;
    color: ${themed('titleFontColor')};

    > i {
      display: block;
    }
  }

  li,
  p {
    color: ${Colors.pigeon800};
    font-size: ${fontSizes.type16};
    line-height: 1;
    margin-top: ${Spacing.baseAndHalfPadding};
  }

  & + ${InputGroup} {
    margin-top: ${Spacing.basePadding4x};
  }
`

export const ElementHeader = styled.header`
  color: ${themed('textColor')};
  margin-bottom: ${Spacing.basePadding};
  margin-top: ${Spacing.basePadding2x};
  > h1 {
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type20};
    margin: 0;
  }

  > h2 {
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type18};
    font-weight: 600;
    margin: 0;
  }

  > h3 {
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type17};
    margin: 0;
  }

  > p {
    font-size: ${fontSizes.type15};
    line-height: 1;
    margin: ${Spacing.basePadding};
  }

  > h1 + p {
    margin: ${Spacing.basePadding} 0 ${Spacing.basePadding3x};
  }

  > h2 + p {
    margin: ${Spacing.basePadding} 0 ${Spacing.basePadding2x};
  }

  > h3 + p {
    margin: ${Spacing.basePadding} 0 ${Spacing.basePadding};
  }

  > p > small {
    font-size: 90%;
    font-weight: 500;
    opacity: 0.9;
  }
`

const ActionHeaderContainer = styled.div`
  display: flex;
  a {
    margin-right: ${Spacing.basePadding2x};

    &:last-child,
    &[color='primary']:last-of-type:not(:first-child) {
      margin-right: 0;
    }
  }
`

const HeaderTitle = styled.div`
  h1 {
    font-size: ${fontSizes.type24};
    font-weight: 600;
    line-height: 1.675;
    color: ${Colors.text};
  }

  display: flex;
  align-items: center;
`

export const HeaderContainer = styled.div<{
  hasMargin?: boolean
  noBorder?: boolean
  inline?: boolean
  noPadding?: boolean
  pill?: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ hasMargin }) =>
    hasMargin
      ? css`
          margin-bottom: ${Spacing.basePadding2x};
        `
      : null}
  ${({ noBorder }) =>
    noBorder
      ? css`
          border: none;
        `
      : null}
  ${({ inline, noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : inline
      ? css`
          padding: 0 ${Spacing.contentPadding};
        `
      : css`
          padding: calc(${Spacing.contentPadding} - 10px) ${Spacing.contentPadding} 0;
        `}
  h1 {
    margin: 0;
  }

  > ${Header} > div > div {
    ${({ pill }) =>
      pill &&
      css`
        margin-bottom: ${Spacing.basePadding2x};
      `}
  }
`

export const TableHeader = styled.header<{ noMargin?: boolean; noPadding?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fontFamily.fontPrimary};
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '1rem')};
  padding: ${({ noPadding }) => (noPadding ? '0' : `0 ${Spacing.contentPadding}`)};

  h2 {
    color: ${Colors.blackLight};
    font-size: ${fontSizes.type20};
    font-weight: 500;
    margin-top: ${({ noMargin }) => (noMargin ? '0' : '2rem')};
  }

  h3 {
    color: ${Colors.blackLight};
    font-size: ${fontSizes.type18};
    font-weight: 600;
    line-height: 28px;
    margin-left: 1.5rem;
  }
`

export const PageHeaderContainer = ({
  header,
  description,
  hasMargin,
  inline,
  noBorder,
  children,
  content,
  noPadding,
  pill,
  headerMaxWidth = 500
}: {
  header?: string | JSX.Element
  description?: string | JSX.Element
  hasMargin?: boolean
  inline?: boolean
  noPadding?: boolean
  noBorder?: boolean
  children?: ReactNode | string
  content?: ReactNode
  pill?: ReactNode
  headerMaxWidth?: number
}) => {
  return (
    <HeaderContainer
      inline={inline}
      hasMargin={hasMargin}
      noBorder={noBorder}
      noPadding={noPadding}
      pill={!!pill}
    >
      <Header>
        <HeaderTitle>
          {typeof header === 'string' ? (
            <h1>
              <OverflowData
                dataTip={header}
                dataFor={`header_${header}`}
                maxWidth={headerMaxWidth}
                offset={{ top: -8, left: 10 }}
              >
                <span>{header}</span>
              </OverflowData>
            </h1>
          ) : (
            header
          )}
          {pill}
        </HeaderTitle>
        {description ? <p>{description}</p> : null}
        {content}
      </Header>
      <ActionHeaderContainer>{children}</ActionHeaderContainer>
    </HeaderContainer>
  )
}

export const ContentHeader = ({
  header,
  description
}: {
  header: string
  description?: string
}) => {
  return (
    <ElementHeader>
      <h2>{header}</h2>
      {description && <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />}
    </ElementHeader>
  )
}
