import { useEffect } from 'react'

import { format, formatDistanceToNowStrict } from 'date-fns'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { EBatchTimelineFormat } from 'src/applications/Oversight/controls/BatchTimelineFormatSelector'
import { Copyable } from 'src/applications/Oversight/controls/Copyable'
import { TimelineUser } from 'src/applications/Oversight/controls/TimelineUser'
import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import { Shadows } from 'src/resources/shadows'
import { fontMonospace, fontSizes } from 'src/resources/typography'
import { IBatchEvent } from 'src/types/interfaces/IBatchEvent'
import { formatDuration } from 'src/utils/date'
import styled, { css } from 'styled-components'

import { TimelineFile } from './TimelineFile'

const DeveloperInfo = styled.p`
  color: ${Colors.brandPrimary};
  font-family: ${fontMonospace};
  font-size: 12px;
  width: 160px;
  word-break: break-all;
`

const Error = styled.p`
  background: ${Colors.dangerBGColor};
  border-radius: 4px;
  border: 1px solid ${Colors.dangerBorderColor};
  color: ${Colors.redDark};
  padding: 4px;
`

const TimeDelay = styled.p`
  color: ${Colors.grayText};
  font-size: 12px;
  position: relative;
  text-align: center;
  &:before {
    background: ${Colors.grayBG};
    content: '';
    height: 1px;
    left: 80px;
    position: absolute;
    top: 8px;
    width: 120px;
  }
  &:after {
    background: ${Colors.grayBG};
    content: '';
    height: 1px;
    position: absolute;
    right: 80px;
    top: 8px;
    width: 120px;
  }
`

const EventLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${Spacing.basePadding2x} 0;
`

const SmallText = styled.div`
  color: ${Colors.grayText};
  font-size: ${fontSizes.type12};
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 120px;
`

const Column = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${Spacing.basePadding};
  ${({ grow }) =>
    grow
      ? css`
          flex-grow: 1;
        `
      : null}

  p {
    margin: 4px 0;
  }
`

const Duration = styled.div`
  align-content: center;
  background: ${Colors.brandSecondary};
  border-radius: 4px;
  box-shadow: ${Shadows};
  color: white;
  display: grid;
  font-size: 12px;
  margin: 0 0 ${Spacing.basePadding2x} 0;
  min-height: 20px;
  max-height: 500px;
  text-align: center;
  width: 100px;
`

const DURATION_SCALE_FACTOR = 50

const eventLabelOverrides: Record<string, string> = {
  'batch.user.access': 'data accessed',
  'upload.match.rules.generate': 'generate matching rules',
  'upload.prepare': 'upload initiated',
  'upload.transfer.failed': 'upload transfer failed',
  'upload.transfer.uploaded': 'upload transfer successful'
}

export function BatchTimelineEvent({
  delay,
  developerMode = false,
  event,
  timelineFormat,
  updateEvent
}: {
  delay: number
  developerMode?: boolean
  event: IBatchEvent
  timelineFormat: EBatchTimelineFormat
  updateEvent(id: string, patch: Partial<IBatchEvent>): void
}) {
  useEffect(() => {
    if (typeof event.startEventId === 'string') {
      const { eventDuration, countColumns, countRows } = event
      updateEvent(event.startEventId, { eventDuration, countColumns, countRows })
    }
  }, [updateEvent])

  if (typeof event.startEventId === 'string') {
    return null
  }

  const timeTooltip = format(event.timestamp, 'E PPpp O')

  return (
    <>
      {delay > 0 ? <TimeDelay>{formatDuration(delay / 1000).join(', ')} later</TimeDelay> : null}
      <EventLineItem>
        {timelineFormat === EBatchTimelineFormat.simple ? (
          <Column>
            <p data-for={timeTooltip} data-tip={timeTooltip}>
              {formatDistanceToNowStrict(event.timestamp, { addSuffix: true })}
            </p>
            <Tooltip id={timeTooltip} />
          </Column>
        ) : (
          <Column>
            <SmallText>{format(event.timestamp, 'E PP')}</SmallText>
            <SmallText>{format(event.timestamp, 'pp O')}</SmallText>
          </Column>
        )}
        <Column grow>
          <p>
            {event.eventName in eventLabelOverrides
              ? eventLabelOverrides[event.eventName]
              : event.eventLabel}
            {event.eventDuration > 0 && timelineFormat === EBatchTimelineFormat.simple ? (
              <> (took {formatDuration(event.eventDuration / 1000).join(', ')})</>
            ) : null}
          </p>
          {typeof event.countColumns === 'number' || typeof event.countRows === 'number' ? (
            <p>
              {event.countColumns > 0
                ? `${event.countColumns} columns${event.countRows > 0 ? ', ' : ''}`
                : null}
              {event.countRows > 0 ? `${event.countRows} rows` : null}
            </p>
          ) : null}
          {event.eventDuration > 0 && timelineFormat === EBatchTimelineFormat.detailed ? (
            <Duration style={{ height: `${event.eventDuration / DURATION_SCALE_FACTOR}px` }}>
              {formatDuration(event.eventDuration / 1000).join(', ')}
            </Duration>
          ) : null}
          {event.error ? <Error>{event.error}</Error> : null}
        </Column>
        <Column>
          {event.fileSize > 0 ? (
            <SmallText>
              <TimelineFile fileName={event.fileName} fileSize={event.fileSize} />
            </SmallText>
          ) : null}
          {event.user ? (
            <SmallText>
              <TimelineUser user={event.user} />
            </SmallText>
          ) : null}
          {timelineFormat === EBatchTimelineFormat.detailed && developerMode ? (
            <>
              <p>
                <Copyable value={event.eventName} />
              </p>
              <DeveloperInfo>
                {event.systemWorkerType ? `${event.systemWorkerType}: ` : null}
                {event.systemComponent}
              </DeveloperInfo>
            </>
          ) : null}
        </Column>
      </EventLineItem>
    </>
  )
}
