import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

export const HaloButton = styled.button`
  height: 40px;
  width: 40px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
  &:hover {
    background-color: ${Colors.gray60};
  }
  transition: background-color 0.5s;
  display: inline-block;
  padding: ${Spacing.basePadding};

  i.icon {
    line-height: 40px;
    position: relative;
    text-align: center;
    width: 100%;
  }
`
