import { useCallback } from 'react'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import styled from 'styled-components'
import { Colors, sidebarStatusColors } from '../colors'
import { hasLocalStorageItem } from '../hooks/useLocalStorageState'
import { Spacing } from '../layout'
import { ActionContainer, StyledP } from './UpdatedResourceBrowser'

const ItemContainer = styled(ActionContainer)<{ selected: boolean }>`
  position: relative;
  display: flex;
  background-color: ${({ selected }) => (selected ? Colors.selectHighlight : 'initial')};
  padding: ${Spacing.basePadding} ${Spacing.basePadding2x};
  align-items: center;
  border-bottom: none;
  border-right: ${({ selected }) =>
    selected ? `4px solid ${Colors.brandPrimary}` : '4px solid transparent'};
`

const SelectItemButton = styled.button`
  display: block;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
`

const StatusCircle = styled.div<{ color?: string }>`
  background: ${({ color }) => sidebarStatusColors[color] ?? Colors.white};
  border-radius: ${Spacing.basePadding};
  height: ${Spacing.baseAndHalfPadding};
  width: ${Spacing.baseAndHalfPadding};
  padding: 3px;
  margin-right: ${Spacing.baseAndHalfPadding};
`

export const ResourceItemComponents = {
  ItemContainer,
  SelectItemButton,
  StatusCircle,
  StyledP
}

export const ResourceSidebarItem = ({
  components,
  description,
  enabled,
  id,
  name,
  selected,
  status,
  onClick
}: {
  components?: typeof ResourceItemComponents
  description?: string
  enabled?: boolean
  id: string | number
  name: string
  selected?: boolean
  status?: string
  onClick(id: string | number): void
}) => {
  const {
    ItemContainer: XItemContainer,
    SelectItemButton: XSelectItemButton,
    StatusCircle: XStatusCircle,
    StyledP: XStyledP
  } = components ?? ResourceItemComponents
  const handleSelect = useCallback(() => onClick(id), [onClick, id])
  const code = hasLocalStorageItem(`dataHook-${id}-code`)
  const dependencies = hasLocalStorageItem(`dataHook-${id}-dependencies`)
  return (
    <XSelectItemButton key={id} onClick={handleSelect}>
      <XItemContainer selected={selected}>
        {enabled != null && (
          <XStatusCircle
            data-testid='status-circle'
            color={
              code || dependencies
                ? 'changesPending'
                : status === 'Inactive'
                ? 'inactive'
                : enabled
                ? 'active'
                : 'failure'
            }
          />
        )}
        <div>
          <OverflowData dataTip={name} dataFor={name} maxWidth={250} maxLines={0}>
            <XStyledP nowrap bold>
              {name || 'Untitled'}
            </XStyledP>
          </OverflowData>
          {status && (
            <XStyledP small>{code || dependencies ? 'Changes pending' : status}</XStyledP>
          )}
          {description && <StyledP>{description}</StyledP>}
        </div>
      </XItemContainer>
    </XSelectItemButton>
  )
}
