import { MutationTuple, useMutation } from '@apollo/client'
import {
  UpdateUploadDataSourceField,
  UpdateUploadDataSourceFieldVariables
} from 'src/queries/types/UpdateUploadDataSourceField'
import { UPDATE_UPLOAD_DATA_SOURCE_FIELD } from 'src/queries/UPDATE_UPLOAD_DATA_SOURCE_FIELD'

export const useUpdateUploadDataSourceFieldMutation = (): MutationTuple<
  UpdateUploadDataSourceField,
  UpdateUploadDataSourceFieldVariables
> => {
  const [updateUploadDataSourceField, updateUploadDataSourceFieldVariables] = useMutation<
    UpdateUploadDataSourceField,
    UpdateUploadDataSourceFieldVariables
  >(UPDATE_UPLOAD_DATA_SOURCE_FIELD)

  return [updateUploadDataSourceField, updateUploadDataSourceFieldVariables]
}
