import { Link } from 'react-router-dom'
import { ImportMetadata } from 'src/applications/Oversight/components/ImportMetadata'
import { ImportsTable } from 'src/applications/Oversight/components/ImportsTable'
import { SchemaCell } from 'src/applications/Oversight/components/SchemaCell'
import { GlobalSearch } from 'src/applications/Oversight/controls/GlobalSearch'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { GetBatches_getBatches } from 'src/queries/types/GetBatches'
import { GridRow } from 'src/resources/elements/GridRow'
import { PaginatedResultsHeader } from 'src/resources/elements/PaginatedResultsHeader'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { useSearch } from 'src/resources/hooks/useSearch'
import { Spacing } from 'src/resources/layout'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_END_USER } from 'src/smart/queries/SQ_END_USER'
import { SQ_SCHEMA } from 'src/smart/queries/SQ_SCHEMA'
import { IBatchData } from 'src/types/interfaces/IBatch'
import { ISchemaFull } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

export const TableOverflow = styled.div<{ fullHeight?: boolean }>`
  ${(props) =>
    props.fullHeight
      ? `
      overflow: hidden;
      overflow-x: auto;
    `
      : `height: calc(100vh - 319px);`}
`

export const canNavigateTo = (batch: IBatchData) => {
  if (batch.embedId) {
    return true
  }
  if (batch.status) {
    return !batch.endUser
  }

  if (batch.failedAt) {
    return false
  }

  return true
}

const AppliedFilters = styled.div`
  padding: 0 ${Spacing.basePadding4x};

  ${GridRow} {
    margin-bottom: ${Spacing.basePadding2x};
  }
`

const ClearLink = styled(Link)`
  font-size: ${fontSizes.type13};
  position: absolute;
  right: ${Spacing.basePadding2x};
  top: ${Spacing.basePadding2x};
`

export const SchemaFilter = ({ schema }: { schema: ISchemaFull }) => {
  const teamRoot = useTeamRootUrl()
  return (
    <GridRow>
      <SchemaCell schema={schema} />
      <ClearLink to={`${teamRoot}/imports`}>Clear filter</ClearLink>
    </GridRow>
  )
}

export const ImportListDataPanel = ({ batches }: { batches: GetBatches_getBatches }) => {
  const teamRoot = useTeamRootUrl()
  const { location } = useReactRouter()
  const query = useSearch<{ u: string; s: string }, { endUserId?: string; schemaId?: string }>(
    location.search,
    ({ u, s }) => ({
      endUserId: u,
      schemaId: s
    })
  )

  const schemaQuery = useSmartQuery(SQ_SCHEMA, {
    fetchPolicy: 'network-only',
    variables: {
      schemaId: query?.schemaId
    },
    skip: !query?.schemaId
  })

  const endUserQuery = useSmartQuery(SQ_END_USER, {
    variables: {
      id: query.endUserId
    },
    skip: !query.endUserId
  })

  if (endUserQuery.alert) {
    return queryAlert(endUserQuery)
  }

  const links = [
    {
      to: `${teamRoot}/imports`,
      label: 'All imports'
    },
    ...(endUserQuery.result?.name
      ? [
          {
            current: true,
            label: endUserQuery.result?.name
          }
        ]
      : []),
    ...(schemaQuery.result?.name
      ? [
          {
            current: true,
            label: schemaQuery.result?.name
          }
        ]
      : [])
  ]

  return (
    <BreadcrumbsFullTemplate crumbs={(query?.endUserId || query?.schemaId) && links}>
      <PaginatedResultsHeader
        pagination={batches.pagination}
        hasMargin
        header={!query?.endUserId && !query?.schemaId && 'All imports'}
      />
      <AppliedFilters>
        {endUserQuery.result && (
          <ImportMetadata endUser={endUserQuery.result}>
            <ClearLink to={`${teamRoot}/imports`}>Clear filter</ClearLink>
          </ImportMetadata>
        )}
        {schemaQuery.result && <SchemaFilter schema={schemaQuery.result} />}
      </AppliedFilters>
      {batches.pagination.totalCount > 0 && (
        <>
          <GlobalSearch />
          <ImportsTable batches={batches} />
          <ListPagination pagination={batches.pagination} />
        </>
      )}
    </BreadcrumbsFullTemplate>
  )
}
