import { gql } from '@apollo/client'

import { SmartMutation } from '../SmartMutation'
import {
  SmartTestDataHookRows,
  SmartTestDataHookRowsVariables,
  SmartTestDataHookRows_runTestDataHook
} from './types/SmartTestDataHookRows'

export const SQ_TEST_DATAHOOK_ROWS: SmartMutation<
  SmartTestDataHookRowsVariables,
  SmartTestDataHookRows,
  SmartTestDataHookRows_runTestDataHook
> = {
  mutation: gql`
    mutation SmartTestDataHookRows($id: UUID!, $rows: [JSON!]!, $environmentId: UUID!) {
      runTestDataHook(id: $id, rows: $rows, environmentId: $environmentId) {
        rows {
          _id
          validations {
            error
            key
            message
          }
          cells
        }
        requestId
        logGroupName
        logStreamName
        logTail
        error
      }
    }
  `,
  extract({ runTestDataHook }) {
    return runTestDataHook
  }
}
