import { useQuery } from '@apollo/client'
import { gql } from 'apollo-boost'
import {
  GetImportCredentials,
  GetImportCredentialsVariables
} from 'src/queries/types/GetImportCredentials'

export const GET_IMPORT_CREDENTIALS = gql`
  query GetImportCredentials($teamId: ID!, $workspaceId: UUID!) {
    getImportCredentials(teamId: $teamId, workspaceId: $workspaceId) {
      teamId
      workspaceId
      active
      updatedAt
      credentials
    }
  }
`

export const getImportCredentials = (teamId: string, workspaceId: string) => {
  return useQuery<GetImportCredentials, GetImportCredentialsVariables>(GET_IMPORT_CREDENTIALS, {
    variables: {
      teamId,
      workspaceId
    }
  })
}
