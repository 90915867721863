import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { useParams } from 'src/resources/hooks/useParams'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { EFieldAction } from 'src/types/enums/EFieldAction'
import { enumFromString } from 'src/utils/enumFromString'
import useReactRouter from 'use-react-router'

import { SchemaEditPanel } from '../panels/SchemaEditPanel'

export const SchemaDetailScene = () => {
  const { history } = useReactRouter()
  const { id, fieldAction, fieldId: fieldIdEncoded } = useParams()
  const teamRoot = useTeamRootUrl()
  const fieldId = fieldIdEncoded ? decodeURIComponent(fieldIdEncoded) : undefined
  const links = [
    {
      to: `${teamRoot}/templates`,
      label: 'Data templates'
    },
    {
      current: true,
      label: 'Edit data template'
    }
  ]

  return (
    <BreadcrumbsFullTemplate crumbs={links}>
      <SchemaEditPanel
        fieldAction={enumFromString<EFieldAction>(EFieldAction, fieldAction)}
        fieldId={fieldId}
        schemaId={id}
        onSchemaIdUpdate={(updatedSchemaId, tab, dataHook) => {
          history.push(
            `${teamRoot}/templates/${updatedSchemaId}${tab ? `?tab=${tab}` : ''}${
              dataHook ? `&dataHookId=${dataHook}` : ''
            }`
          )
        }}
      />
    </BreadcrumbsFullTemplate>
  )
}
