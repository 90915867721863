import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { InfoIcon } from 'src/resources/elements/Icons'
import { useModal } from 'src/resources/elements/Modal'
import { Spacing } from 'src/resources/layout'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'

import styled from 'styled-components'
import { TeamContext } from 'src/contexts/TeamContext'

const Header = styled.span`
  position: relative;
`

const ButtonWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: ${Spacing.basePadding2x};
  button {
    margin: 0px ${Spacing.basePadding};
  }
`
const TextBody = styled.span`
  color: ${Colors.brandSecondaryText};
  font-size: 16px;
  line-height: 140%;
  margin: ${Spacing.basePadding3x} 0;
  display: block;
`

const StyledButton = styled(FlatButton)`
  span {
    color: ${Colors.brandPrimary} !important;
  }
`

const TooltipAnchor = styled.span`
  position: absolute;
  right: -${Spacing.basePadding3x};
`

const TooltipMessage = styled.div`
  max-width: 250px;
  p {
    color: ${Colors.white};
    margin: ${Spacing.halfBasePadding} 0;
    line-height: 23px;
    &:nth-of-type(2) {
      margin-bottom: ${Spacing.basePadding2x};
    }
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: inherit;
  transition: color 200ms;
  :hover {
    color: ${Colors.brandPrimary};
  }
`
const TooltipInfoHeader = styled.p`
  font-weight: 700;
`
const TooltipParagraph = styled.p`
  font-weight: 400;
`

export function useSelectMergeFieldModal(
  onSelectMergeField: (mergeField?: string) => void,
  uniqueFields: SelectOption[],
  embeds: boolean
) {
  const team = useContext(TeamContext)
  const [selectedField, setSelectedField] = useState<string>('')
  const canMergeDuplicates = team.featureFlags?.WORKSPACES_MERGE_DUPLICATES

  useEffect(() => {
    if (uniqueFields?.length && !selectedField) {
      setSelectedField(uniqueFields[0].value)
    }
  }, [uniqueFields?.length, selectedField])

  const modal = useModal({
    onClose: () => {
      setSelectedField('')
      return true
    },
    header: (
      <Header>
        Do you want to merge duplicates?
        <TooltipAnchor data-tip data-for='merge-info'>
          <InfoIcon fill={Colors.text} />
        </TooltipAnchor>
        <Tooltip
          id='merge-info'
          offset={{ top: 0, left: 0 }}
          backgroundColor={Colors.textLogo}
          content={
            <TooltipMessage>
              <TooltipInfoHeader>Merge</TooltipInfoHeader>
              <TooltipParagraph>
                Replace existing data with updates from your new records.
              </TooltipParagraph>
              <TooltipInfoHeader>Do not merge</TooltipInfoHeader>
              <TooltipParagraph>
                All records will be perserved. Manually correct duplicates.
              </TooltipParagraph>
            </TooltipMessage>
          }
        ></Tooltip>
      </Header>
    ),
    zIndex: 1600,
    contents: () => (
      <>
        <TextBody>
          Select a shared unique field to join the old data to the new data and update any
          duplicate records in the existing data
          <br />
          <StyledLink
            href='https://support.flatfile.com/hc/en-us/articles/4758853328404'
            target='_blank'
            rel='noopener'
          >
            Learn more
          </StyledLink>
        </TextBody>
        <Select
          options={uniqueFields}
          required={true}
          selectedValue={selectedField}
          onChange={(option) => {
            setSelectedField(option.value)
          }}
        ></Select>
        <ButtonWrap>
          <StyledButton color='white' onClick={cancel}>
            <span>Cancel</span>
          </StyledButton>
          <StyledButton color='white' onClick={() => onSelectMergeField()}>
            <span>Do not merge</span>
          </StyledButton>
          <FlatButton
            color='primary'
            disabled={!selectedField}
            onClick={() => onSelectMergeField(selectedField)}
          >
            Merge
          </FlatButton>
        </ButtonWrap>
      </>
    )
  })

  const cancel = useCallback(() => modal.close(), [modal])

  return useMemo(
    () => ({
      ...modal,
      async open() {
        if (embeds || !uniqueFields.length || !canMergeDuplicates) {
          onSelectMergeField()
        } else {
          modal.open()
        }
      }
    }),
    [uniqueFields, modal, onSelectMergeField, embeds]
  )
}
