import { MutationTuple, useMutation } from '@apollo/client'
import { SEND_WORKSPACE_INVITATION } from 'src/queries/SEND_WORKSPACE_INVITATION'
import {
  SendWorkspaceInvitation,
  SendWorkspaceInvitationVariables
} from 'src/queries/types/SendWorkspaceInvitation'

export const useSendWorkspaceInvitationMutation = (): MutationTuple<
  SendWorkspaceInvitation,
  SendWorkspaceInvitationVariables
> => {
  return useMutation<SendWorkspaceInvitation, SendWorkspaceInvitationVariables>(
    SEND_WORKSPACE_INVITATION
  )
}
