import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_ACCOUNT_STATUS } from 'src/queries/GET_ACCOUNT_STATUS'
import { GetAccountStatus, GetAccountStatusVariables } from 'src/queries/types/GetAccountStatus'
import { Colors } from 'src/resources/colors'
import { LogoIcon } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { keyframes } from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const TrialNoticeContainer = styled.div`
  animation: ${fadeIn} 0.25s 0.15s ease both;
  display: flex;
  justify-content: space-between;
  height: ${Spacing.basePadding10x};
  background: ${Colors.purpleAlert};
  border-radius: ${Spacing.halfBasePadding};
  border: 0.5px solid ${Colors.brandPrimaryRGBABorder};
  padding: ${Spacing.basePadding} ${Spacing.basePadding3_5x};
  margin-bottom: ${Spacing.basePadding2x};
  transition: ${Transitions.baseEase};
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div > h5 {
    margin-top: ${Spacing.basePadding};
    margin-bottom: ${Spacing.basePadding};
    font-weight: 600;
    font-size: ${fontSizes.type13};
    font-family: ${fontFamily.fontPrimary};
  }

  > div > p {
    text-transform: capitalize;
    font-size: ${fontSizes.type12};
  }

  &:hover {
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.05), 0 2px 16px 0 rgba(0, 0, 0, 0.05),
      0 24px 16px -16px rgba(0, 0, 0, 0.08);
  }
`

const TimeLeft = styled.div<{ color?: string }>`
  display: flex;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type18};
  align-items: center;
  line-height: calc(${Spacing.basePadding2_5x} + 2px);
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : Colors.gray80)};

  > svg {
    height: 18px;
    width: 18px;
    margin-right: ${Spacing.basePadding};
  }
`

const ContactLink = styled.div`
  display: flex;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type12};
  align-items: center;
  color: ${Colors.text};

  > svg {
    height: 18px;
    width: 18px;
    margin-right: ${Spacing.basePadding};
  }

  a,
  div > span {
    color: ${Colors.brandPrimary};
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type16};
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ${Transitions.baseEase};

    svg {
      height: 14px;
      transition: ${Transitions.baseEase};
      path {
        transition: ${Transitions.baseEase};
      }
    }
  }

  &:hover {
    > svg {
      path,
      rect {
        fill: ${Colors.brandPrimary};
      }
    }
    a {
      color: ${Colors.brandPrimary};

      svg {
        transform: translateX(2px);
        path {
          fill: ${Colors.brandPrimary};
        }
      }
    }
  }
`

const MeetingDetails = styled.div`
  h2,
  p {
    color: ${Colors.textLogo};
  }
  h2 {
    font-family: ${fontFamily.fontSerif};
    font-size: ${fontSizes.type48};
    font-weight: bold;
    margin-bottom: calc(${Spacing.basePadding5x} + 2px);
    margin-top: calc(${Spacing.basePadding10x} + 2px);
  }
  p {
    font-family: ${fontFamily.fontSecondary};
    font-size: ${fontSizes.type21};
    line-height: 150%;
    margin-bottom: ${Spacing.basePadding6x};
  }
`

const ImageAndTitleContainer = styled.span`
  display: flex;
  img {
    max-width: 150px;
    height: auto;
    margin-right: ${Spacing.basePadding5x};
  }
  svg {
    margin-right: ${Spacing.basePadding3x};
    align-self: flex-end;
  }
  h3,
  p {
    font-family: ${fontFamily.fontSecondary};
    letter-spacing: 0.02em;
  }
  h3 {
    font-size: ${fontSizes.type21};
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    font-size: ${fontSizes.type16};
    margin-top: 0;
  }
`

export const TrialExpired = () => {
  return (
    <MeetingDetails>
      <h2>Your trial has expired.</h2>
      <p>
        Had a chance to test out the product? Ready to get into production? Let's have a quick chat
        so we can turn on the full set of features to get your users going with a smooth data
        onboarding process.
      </p>
      <ImageAndTitleContainer>
        <LogoIcon height='45px' />
        <span>
          <h3>The Flatfile Team</h3>
        </span>
      </ImageAndTitleContainer>
    </MeetingDetails>
  )
}

export const TrialNoticeCard = (): JSX.Element => {
  const team = useContext(TeamContext)
  const concierge = team.featureFlags?.PRODUCT_ACCESS_WORKSPACES

  const { data } = useQuery<GetAccountStatus, GetAccountStatusVariables>(GET_ACCOUNT_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { teamId: team.id }
  })

  const githubUser = data?.getAccountStatus.plan?.startsWith('portal-developer-trial')

  if (
    !data?.getAccountStatus ||
    (data?.getAccountStatus?.status.toLowerCase() === 'active' && !githubUser) ||
    concierge ||
    data?.getAccountStatus?.plan === null
  ) {
    return null
  }

  return (
    <TrialNoticeContainer>
      <TimeLeft color={Colors.brandPrimary}>
        You are using a previous version of Flatfile. To sign up for the latest version of
        Flatfile,&nbsp;<a href='https://platform.flatfile.com/account/sign-up'>click here</a>.
      </TimeLeft>
      <div>
        <ContactLink>
          <a href='https://flatfile.com/contact-us/'>Get in touch with us</a>
        </ContactLink>
      </div>
    </TrialNoticeContainer>
  )
}
