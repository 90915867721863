import { useCallback, useContext, useEffect, useState } from 'react'

import { makeVar, useSubscription } from '@apollo/client'
import { Span } from '@sentry/types'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { EXPORT_UPDATED_SUBSCRIPTION } from 'src/queries/EXPORT_UPDATED_SUBSCRIPTION'
import { useInitializeSheetExportMutation } from 'src/queries/hooks/useInitializeSheetExportMutation'
import { ExportUpdated, ExportUpdatedVariables } from 'src/queries/types/ExportUpdated'
import { ButtonBase } from 'src/resources/elements/buttons/FlatButton'
import { Validation } from 'src/resources/hooks/useTableFilters'
import { EExportStatus } from 'src/types/enums/EExportStatus'
import { EExportFormat } from 'src/types/enums/EExportFormat'

import { ExportFailed } from './ExportFailed'
import { ExportInProgress } from './ExportInProgress'
import { ExportSuccessful } from './ExportSuccessful'
import { FileDownloadDropdown } from './FileDownloadDropdown'
import { FeatureFlagContext } from 'src/applications/Embed/fragments/FeatureFlagContext'

// Sentry performance tracking
const ExportInitialized = 'export-initialized'
const ExportSuccess = 'export-success'
const exportSpans: Partial<Span>[] = [{ op: ExportInitialized }, { op: ExportSuccess }]

const exportId = makeVar('')

export const ExportSheetButton = ({
  sheetId,
  validationState
}: {
  sheetId: string
  validationState?: Validation
}) => {
  const [exportStatus, setExportStatus] = useState<string | undefined>()
  const [initializeSheetExport] = useInitializeSheetExportMutation()
  const [exportFileFormat, setExportFileFormat] = useState(null)
  const features = useContext(FeatureFlagContext)

  const downloadLabelsXlsxFlag = features?.includes('DOWNLOAD_LABELS_XLSX')

  const sentryTransaction = useSentryTransaction({
    name: `sheet/export`,
    spans: exportSpans
  })
  const { data: { exportUpdated } = {}, error: exportError } = useSubscription<
    ExportUpdated,
    ExportUpdatedVariables
  >(EXPORT_UPDATED_SUBSCRIPTION, {
    skip: !exportId(),
    variables: { exportId: exportId() }
  })

  useEffect(() => {
    if (exportUpdated?.status) {
      setExportStatus(exportUpdated.status)
    }
  }, [exportUpdated?.status])

  useEffect(() => {
    if (exportUpdated?.status === EExportStatus.UPLOADED) {
      window.open(`${FLATFILE_REFINERY_URL}/export/${exportUpdated.id}?format=${exportFileFormat}`)
      setExportFileFormat(null)
      exportId('')
      submitSentrySpan(sentryTransaction, ExportSuccess)
      sentryTransaction.transaction.finish()
    }
  }, [exportUpdated?.status])

  const initializeExport = async (extension: 'csv' | 'xlsx') => {
    const exportResponse = await initializeSheetExport({
      variables: {
        sheetId,
        validationState,
        ...(downloadLabelsXlsxFlag && extension === 'xlsx' ? { format: EExportFormat.human } : {})
      }
    })
    const { id } = exportResponse.data?.initializeSheetExport ?? {}
    if (id) {
      exportId(id)
      setExportStatus(EExportStatus.IN_PROGRESS)
    }
  }

  const exportFile = useCallback(
    (extension: 'csv' | 'xlsx') => {
      initializeExport(extension)
      setExportFileFormat(extension)
      submitSentrySpan(sentryTransaction, ExportInitialized)
    },
    [initializeExport, setExportFileFormat, submitSentrySpan, exportId]
  )

  const handleDownload = useCallback(
    (type: 'processed', extension) => {
      if (type === 'processed') {
        exportFile(extension)
      }
    },
    [exportFile, exportId]
  )

  if (exportError) {
    return ExportFailed
  } else if (exportId()) {
    switch (exportStatus) {
      case EExportStatus.UPLOAD_FAILED:
        return ExportFailed
      case EExportStatus.UPLOADED:
        return ExportSuccessful
      default:
        return ExportInProgress
    }
  }

  return (
    <FileDownloadDropdown offset={[0, 10]} onDownloadFile={handleDownload}>
      <ButtonBase color='success'>Download</ButtonBase>
    </FileDownloadDropdown>
  )
}
