import { RandomCarousel } from 'src/applications/Oversight/components/Carousel'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Spinner, StyledSpinner } from 'src/resources/elements/Spinner'
import { UnderConstruction } from 'src/resources/elements/UnderConstruction'
import warning from 'src/resources/icons/database-warning.svg'
import { Spacing } from 'src/resources/layout'
import { FindResult } from 'src/smart/hooks/useSmartQuery'
import styled from 'styled-components'

export const StyledLoadStatus = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  padding: ${Spacing.basePadding2x};
`

export const StyledStatusMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: ${Spacing.basePadding6x};
    height: ${Spacing.basePadding6x};
  }
  ${StyledSpinner} {
    width: ${Spacing.basePadding4x};
    height: ${Spacing.basePadding4x};
  }
  img,
  ${StyledSpinner} {
    margin-bottom: ${Spacing.basePadding};
  }
`

const StyledErrorDetails = styled.div`
  background-color: ${Colors.gray20};
  border-radius: ${Spacing.halfBasePadding};
  border: 1px solid ${Colors.gray60};
  color: ${Colors.text};
  margin: ${Spacing.basePadding3x} 0;
  max-width: 360px;
  min-height: 240px;
  padding: ${Spacing.basePadding3x};

  h4 {
    margin-top: 0;
  }
`

export function queryAlert(result: { state: { error?: any; loading: boolean } }) {
  return QueryAlert(result.state)
}

export function QueryLoader<A>(props: {
  query: FindResult<A>
  children: (result: A) => JSX.Element
}): JSX.Element {
  if (!props.query.ready) {
    return QueryAlert(props.query)
  }
  return props.children(props.query.data)
}

export const QueryAlert = (
  { error, loading, carousel }: { error?: any; loading?: boolean; carousel?: boolean },
  errorTitle: JSX.Element = <>Error</>,
  loadingTitle: JSX.Element = <>Loading</>
) => {
  const errorWillRedirect =
    error === 'Error: Unauthorized' || error?.message?.includes('Unauthorized')
  if (error && !errorWillRedirect) {
    return (
      <StyledLoadStatus>
        <StyledStatusMessage>
          <img src={warning} alt='company icon' /> {errorTitle}
          <StyledErrorDetails>
            <h4>Error details:</h4>
            <code>{error?.message ?? error}</code>
          </StyledErrorDetails>
          <p>
            <FlatButton
              onClick={() => {
                location.reload()
              }}
            >
              Try again
            </FlatButton>
          </p>
        </StyledStatusMessage>
      </StyledLoadStatus>
    )
  }
  if (loading || errorWillRedirect) {
    if (carousel) {
      return (
        <div>
          <StyledLoadStatus>
            <StyledStatusMessage>
              <Spinner /> {loadingTitle}
            </StyledStatusMessage>
          </StyledLoadStatus>
          <RandomCarousel inverted oneLogoAtATime />
        </div>
      )
    }
    return (
      <StyledLoadStatus>
        <StyledStatusMessage>
          <Spinner /> {loadingTitle}
        </StyledStatusMessage>
      </StyledLoadStatus>
    )
  }
  return (
    <UnderConstruction
      render={() => (
        <>
          You&apos;re rendering a <code>QueryAlert</code>, but there&apos;s no reason to render it
          if the query is not alerting (loading or error). How to use:{' '}
          <code>{'if (query.alert) { return QueryAlert(query) }'}</code>
        </>
      )}
    />
  )
}
