import { MidLayer2 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import { Opacities } from 'src/resources/opacities'
import styled from 'styled-components'

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.defaultText};
  opacity: ${Opacities.medium};
  z-index: ${MidLayer2};
`
