import { gql } from '@apollo/client'
import {
  UpdateOrganization,
  UpdateOrganizationVariables
} from 'src/smart/mutations/types/UpdateOrganization'
import { SmartMutation } from 'src/smart/SmartMutation'
import { IOrganization } from 'src/types/interfaces/IOrganization'

export const SM_UPDATE_ORGANIZATION: SmartMutation<
  UpdateOrganizationVariables,
  UpdateOrganization,
  IOrganization
> = {
  mutation: gql`
    mutation UpdateOrganization($id: String!, $name: String!) {
      updateOrganization(id: $id, name: $name) {
        id
        name
      }
    }
  `,
  extract: (response) => response.updateOrganization
}
