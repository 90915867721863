import { useContext, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { LowLayer2, LowLayer3 } from 'src/applications/Oversight/layers'
import { TeamContext } from 'src/contexts/TeamContext'
import { GLOBAL_SEARCH } from 'src/queries/GLOBAL_SEARCH'
import {
  GlobalSearch as GlobalSearchResponseType,
  GlobalSearchVariables,
  GlobalSearch_getBatches_data,
  GlobalSearch_getEndUsers_data,
  GlobalSearch_getSchemas_data
} from 'src/queries/types/GlobalSearch'
import { Colors } from 'src/resources/colors'
import { SearchIcon, SearchInput } from 'src/resources/elements/SearchInput'
import { useDelayedState } from 'src/resources/hooks/useDelayedState'
import fileSearchIcon from 'src/resources/icons/search-results/file.svg'
import infoIcon from 'src/resources/icons/search-results/info.svg'
import userSearchIcon from 'src/resources/icons/search-results/user.svg'
import searchIcon from 'src/resources/icons/search.svg'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

interface ISearchResultItem {
  href: string
  element: JSX.Element
}

const FloatingInput = styled.input`
  font-weight: 600;
  max-width: unset;
  position: relative;
  width: 36%;
  z-index: ${LowLayer2};
`

const Icon = styled(SearchIcon)`
  z-index: ${LowLayer3};
`

const SearchDropdown = styled.div`
  box-shadow: 0px 0px 0px 1px #0000000d, 0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a;
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 0;
  right: 0;
  padding: ${Spacing.halfBasePadding};
  background-color: ${Colors.white};
  border-radius: 5px;
  font-size: ${fontSizes.type14};
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 440px;
  max-width: 440px;
`

const SearchMessage = styled.div`
  position: relative;
  padding: 12px 12px 12px 48px;
  font-weight: 600;
  height: 40px;
`

const SearchResult = styled.div<{ selected: boolean }>`
  display: block;
  color: inherit;
  position: relative;
  padding: 12px 12px 12px 48px;
  font-weight: 600;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          background-color: ${Colors.grayBG};
        `
      : null}
`

const SearchResultIcon = styled.div`
  position: absolute;
  left: 22px;
  top: 12px;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const SearchResultIconInfo = styled(SearchResultIcon)`
  background-image: url(${infoIcon});
  background-size: 15px;
`

const SearchResultIconEndUser = styled(SearchResultIcon)`
  background-image: url(${userSearchIcon});
`

const SearchResultIconFile = styled(SearchResultIcon)`
  background-image: url(${fileSearchIcon});
`

const SecondaryText = styled.span`
  padding-left: 10px;
  color: ${Colors.brandSecondaryText};
  font-weight: normal;
`

const SearchResultEndUser = ({
  endUser,
  selected,
  onClick,
  onSelect
}: {
  endUser: GlobalSearch_getEndUsers_data
  selected: boolean
  onClick(): void
  onSelect(): void
}) => (
  <SearchResult
    onClick={onClick}
    onMouseEnter={onSelect}
    selected={selected}
    onMouseDown={(e) => e.preventDefault()}
  >
    <SearchResultIconEndUser />
    {endUser.name}
  </SearchResult>
)

const SearchResultSchema = ({
  schema,
  selected,
  onClick,
  onSelect
}: {
  schema: GlobalSearch_getSchemas_data
  selected: boolean
  onClick(): void
  onSelect(): void
}) => (
  <SearchResult
    onClick={onClick}
    onMouseEnter={onSelect}
    selected={selected}
    onMouseDown={(e) => e.preventDefault()}
  >
    <SearchResultIconInfo />
    {schema.name}
  </SearchResult>
)

const SearchResultBatch = ({
  batch,
  selected,
  onClick,
  onSelect
}: {
  batch: GlobalSearch_getBatches_data
  selected: boolean
  onClick(): void
  onSelect(): void
}) => {
  return (
    <SearchResult
      onClick={onClick}
      onMouseEnter={onSelect}
      selected={selected}
      onMouseDown={(e) => e.preventDefault()}
    >
      <SearchResultIconFile />
      {batch.originalFile && batch.originalFile.length > 0 ? (
        batch.originalFile
      ) : (
        <em>(no file name)</em>
      )}
      {batch.memo ? <SecondaryText>{batch.memo}</SecondaryText> : null}
    </SearchResult>
  )
}

export const GlobalSearch = withRouter(
  ({
    history,
    secondaryStyling
  }: {
    secondaryStyling?: boolean
  } & RouteComponentProps) => {
    const teamRoot = useTeamRootUrl()
    const team = useContext(TeamContext)
    const licenseKey = team.licenses[0].key

    const searchInputRef = useRef<HTMLInputElement>()
    const [isOpen, setIsOpen] = useDelayedState(false)
    const [search, setSearch] = useState('')
    const [rawSelectedIndex, setSelectedIndex] = useState(0)

    const globalSearch = useQuery<GlobalSearchResponseType, GlobalSearchVariables>(GLOBAL_SEARCH, {
      skip: search.length === 0,
      variables: {
        licenseKey,
        teamId: team.id,
        search
      }
    })

    const endUserSearchResults =
      globalSearch.data &&
      globalSearch.data.getEndUsers &&
      globalSearch.data.getEndUsers.data &&
      globalSearch.data.getEndUsers.data.length > 0
        ? globalSearch.data.getEndUsers.data
        : []

    const batchSearchResults =
      globalSearch.data &&
      globalSearch.data.getBatches &&
      globalSearch.data.getBatches.data &&
      globalSearch.data.getBatches.data.length > 0
        ? globalSearch.data.getBatches.data
        : []

    const schemaSearchResults =
      globalSearch.data &&
      globalSearch.data.getSchemas &&
      globalSearch.data.getSchemas.data &&
      globalSearch.data.getSchemas.data.length > 0
        ? globalSearch.data.getSchemas.data
        : []

    const hasSearchTerm = search && search.length > 0

    const totalResultCount =
      endUserSearchResults.length + batchSearchResults.length + schemaSearchResults.length
    const noResults = totalResultCount === 0

    const selectedIndex = Math.min(rawSelectedIndex, totalResultCount - 1)

    let optionIndex = -1

    const nextIndex = <T,>(callback: (index: number) => T) => {
      optionIndex++
      return callback(optionIndex)
    }

    const navigateToIndex = (index: number) => {
      history.push(searchResults[index].href)
      if (searchInputRef.current) {
        searchInputRef.current.blur()
      }
    }

    const searchResults: ISearchResultItem[] = endUserSearchResults
      .map((endUser) => ({
        element: nextIndex((index) => (
          <SearchResultEndUser
            selected={index === selectedIndex}
            onClick={() => navigateToIndex(index)}
            onSelect={() => setSelectedIndex(index)}
            key={endUser.id}
            endUser={endUser}
          />
        )),
        href: `${teamRoot}/imports?u=${endUser.id}`
      }))
      .concat(
        batchSearchResults.map((batch) => ({
          element: nextIndex((index) => (
            <SearchResultBatch
              selected={index === selectedIndex}
              onClick={() => navigateToIndex(index)}
              onSelect={() => setSelectedIndex(index)}
              key={batch.id}
              batch={batch}
            />
          )),
          href: `${teamRoot}/imports/${batch.id}`
        }))
      )
      .concat(
        schemaSearchResults.map((schema) => ({
          element: nextIndex((index) => (
            <SearchResultSchema
              selected={index === selectedIndex}
              onClick={() => navigateToIndex(index)}
              onSelect={() => setSelectedIndex(index)}
              key={schema.id}
              schema={schema}
            />
          )),
          href: `${teamRoot}/imports?s=${schema.id}`
        }))
      )

    const handleFocusSearch = () => {
      setIsOpen(true, 0)
      setSelectedIndex(0)
    }

    const handleBlurSearch = () => {
      setSearch('')
      setIsOpen(false, 250)
    }

    const getSelectedListItem = (): HTMLDivElement | null => {
      if (!searchInputRef.current) {
        return null
      }

      return searchInputRef.current.nextElementSibling.children[selectedIndex] as HTMLDivElement
    }

    const keyboardActions = (keyCode: number) => {
      const listItem = getSelectedListItem()

      if (keyCode === 27) {
        // ESC
        setIsOpen(false, 0)
        if (searchInputRef.current) {
          searchInputRef.current.blur()
        }
      } else if (keyCode === 38) {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1)
        }

        if (listItem) {
          listItem.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      } else if (keyCode === 40) {
        if (selectedIndex < optionIndex) {
          setSelectedIndex(selectedIndex + 1)
        }

        if (listItem) {
          listItem.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      } else if (keyCode === 13) {
        if (listItem) {
          listItem.click()
          searchInputRef.current.blur()
        }
      }
    }

    return (
      <SearchInput secondary={secondaryStyling}>
        <Icon src={searchIcon} alt='search' />
        <FloatingInput
          ref={searchInputRef}
          onFocus={handleFocusSearch}
          onBlur={handleBlurSearch}
          onKeyDown={(e) => keyboardActions(e.keyCode)}
          type='search'
          placeholder='Search by file name, user, company, or data template…'
          onChange={(e) => setSearch(e.currentTarget.value)}
          value={search}
        />
        {isOpen ? (
          <SearchDropdown>
            {noResults && hasSearchTerm ? (
              <SearchMessage>
                <SearchResultIconInfo />
                No results
              </SearchMessage>
            ) : null}
            {noResults && !hasSearchTerm ? (
              <SearchMessage>
                <SearchResultIconInfo />
                Enter a search term
              </SearchMessage>
            ) : null}
            {searchResults.map((result) => result.element)}
          </SearchDropdown>
        ) : null}
      </SearchInput>
    )
  }
)
