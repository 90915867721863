import { useCallback, useState } from 'react'

import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { useSearchParam, useUpdateSearchParam } from 'src/resources/hooks/useSearchParam'
import styled from 'styled-components'

const SelectContainer = styled.div`
  min-width: 186px;
  position: relative;
  right: 32px;
`

export function useActiveArchivedTabs({
  activeLabel = 'Active',
  archivedLabel = 'Archived',
  center = false,
  urlParam = 'archived',
  useUrl = true,
  removeAllParams = true
}: {
  activeLabel?: string
  center?: boolean
  compact?: boolean
  urlParam?: string
  useUrl?: boolean
  archivedLabel?: string
  removeAllParams?: boolean
} = {}) {
  const archivedParam = useUpdateSearchParam(urlParam, removeAllParams)
  function config() {
    if (useUrl) {
      return {
        showActive: !useSearchParam.boolean(urlParam, false),
        setShowActive: useCallback(
          (active: boolean) => (active ? archivedParam.remove() : archivedParam.set('true')),
          [archivedParam]
        )
      }
    }
    const [isActive, setIsActive] = useState<boolean>(true)
    return {
      showActive: isActive,
      setShowActive: setIsActive
    }
  }
  const { showActive, setShowActive } = config()
  const [setShowActiveTrue, setShowActiveFalse] = [true, false].map((bool) =>
    useCallback(() => setShowActive(bool), [setShowActive])
  )

  const selectOptions: SelectOption[] = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' }
  ]
  const [queryParamShown, setQueryParamShown] = useState(!useSearchParam.boolean(urlParam, false))
  const filterArchived = (option: SelectOption) => {
    if (option.value === 'active') {
      setShowActive(true)
      setQueryParamShown(true)
    } else if (option.value === 'archived') {
      setShowActive(false)
      setQueryParamShown(false)
    }
  }
  const tabs = useCallback(() => {
    return (
      <Tabstrip center={center}>
        <Tab active={showActive} onClick={setShowActiveTrue}>
          {activeLabel}
        </Tab>
        {archivedLabel && (
          <Tab active={!showActive} onClick={setShowActiveFalse} data-testid='tab-archived'>
            {archivedLabel}
          </Tab>
        )}
      </Tabstrip>
    )
  }, [showActive, setShowActive])

  const dropdown = useCallback(() => {
    return (
      <SelectContainer>
        <Select
          selectedValue={queryParamShown ? 'active' : 'archived'}
          options={selectOptions}
          onChange={(option) => {
            filterArchived(option)
          }}
        />
      </SelectContainer>
    )
  }, [filterArchived])

  return {
    setShowActive,
    showActive,
    showArchived: !showActive,
    tabs,
    dropdown
  }
}
