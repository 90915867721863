import { useMemo } from 'react'

import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { ISchemaStub } from 'src/types/interfaces/ISchema'
import { sortAndMapSchemas } from 'src/utils/sortAndMapSchemas'

export interface ICreateEmbedFormData {
  name: string
  schema: string
}

const CreateEmbedFormElement: TForm<ICreateEmbedFormData> = Form

export const CreateEmbedForm = ({
  onSubmit,
  onCancel,
  schemas
}: {
  onSubmit: (event: IFormSubmitEvent<ICreateEmbedFormData>) => Promise<void>
  onCancel: () => void
  schemas: ISchemaStub[]
}) => {
  const schemaOptions: SelectOption[] = useMemo(
    () =>
      sortAndMapSchemas(
        schemas,
        (schema) => !Object.keys(schema.jsonSchema?.schema?.properties ?? {}).length,
        'no fields'
      ),
    [schemas]
  )

  return (
    <CreateEmbedFormElement onSubmit={onSubmit}>
      <p>Give your Portal a meaningful name and assign a data template to the Portal.</p>
      <Input
        autoFocus
        data-testid='create-portal-name'
        id='create-portal-name'
        label='Portal name'
        name='name'
        onKeys={{ Escape: onCancel }}
        placeholder='Type Portal name'
        required
      />
      <ErrorMessage name='name' />
      <InputGroup>
        <Select
          name='schema'
          label='Data Template'
          required
          options={schemaOptions}
          selectLabel='Select a data template'
          id='create-portal-schema'
        />
      </InputGroup>
      <ErrorMessage name='schema' />
      <ButtonGroup left>
        <FormButton primary submit data-testid='create-portal-create'>
          Create
        </FormButton>
        <FormButton secondary onClick={onCancel} data-testid='create-portal-cancel'>
          Cancel
        </FormButton>
      </ButtonGroup>
    </CreateEmbedFormElement>
  )
}
