import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { SelectDropdownLayer } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import {
  FieldLabel as SelectLabel,
  HelperText,
  RequiredMark
} from 'src/resources/elements/form/FieldLabel'
import { FlatInputContainer, Input } from 'src/resources/elements/form/Input'
import { RadioInput } from 'src/resources/elements/form/RadioInput'
import arrowDownIcon from 'src/resources/icons/arrow-down.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css, useTheme } from 'styled-components'

import { RadioGroup, RadioLabel } from '../RadioGroup'
import { getFormContext } from './Form'

const SelectInput = styled(FlatInputContainer)<{ revealDelete?: boolean }>`
  div[class$='-multiValue'] {
    & > div + div {
      padding: 0 0 0 3px;
      position: relative;
      width: 24px;
      ${({ revealDelete }) =>
        revealDelete
          ? css`
              width: 0;
              overflow: hidden;
            `
          : null};

      svg {
        display: none;
      }

      &:after {
        color: ${Colors.pigeon600};
        content: '×';
        cursor: pointer;
        display: block;
        font-size: 125%;
        left: 0;
        line-height: 24px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
      }
    }
    &:hover {
      & > div + div {
        ${({ revealDelete }) =>
          revealDelete
            ? css`
                width: 24px;
              `
            : null};
        &:after,
        &:hover {
          background: none;
          color: ${Colors.brandPrimary};
        }
      }
    }
  }
`

const FlatSelect = styled.select`
  background-color: ${Colors.white};
  background-image: url(${arrowDownIcon});
  background-size: 10px;
  background-position: 96% center;
  background-repeat: no-repeat;
  padding: 0;
  border-radius: 3px;
  border: 1px solid ${Colors.border};
  width: 100%;
  max-width: 260px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  outline: transparent dashed 1px;
  appearance: none;

  &:disabled {
    background-color: ${Colors.grayBG};
    border-color: transparent;
    color: ${Colors.grayDark};
  }

  & + label {
    font-weight: bold;
    pointer-events: none;
    color: ${Colors.grayText};
  }

  &:hover {
    border: 1px solid ${Colors.brandSecondaryText};
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 2px 7px rgba(84, 115, 238, 0.2), inset 0 1px 5px rgba(80, 80, 80, 0.1);
    & + label {
      color: ${Colors.brandBlue};
    }
  }
`

const FlatSelectContainer = styled.div`
  position: relative;
  font-weight: 400;

  label {
    color: ${Colors.textAccent};
    display: block;
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type14};
    font-weight: 600;
    margin-bottom: ${Spacing.basePadding};
  }

  select {
    padding: 10px ${Spacing.basePadding2x};
    max-width: 100%;
  }
`

export const NewSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    zIndex: '999',
    borderWidth: '0',
    display: 'flex'
  }),
  control: (provided: any, _state: any) => ({
    ...provided,
    background: Colors.gray20,
    borderWidth: 0,
    boxShadow: null,
    color: Colors.grayInputText,
    fontSize: '15px',
    fontWeight: '500',
    padding: '2px 0px',
    width: '190px',
    '&:hover': {
      cursor: 'pointer',
      borderColor: Colors.brandPrimary
    }
  }),
  placeholder: () => ({
    color: Colors.grayText,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    marginRight: '4px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: () => ({
    opacity: '0'
  }),
  singleValue: () => ({
    color: Colors.grayInputText
  }),
  valueContainer: () => ({
    color: Colors.grayInputText,
    padding: '2px 8px',
    overflow: 'visible',
    display: 'flex'
  })
}

export interface SelectOption {
  __isNew__?: boolean
  disabled?: boolean
  hide?: boolean
  iconCodepoints?: number[]
  label: string
  requires?: string[]
  value: string
}

const optionValue = (option: SelectOption | string | SelectOption[]) => {
  if (option && typeof option === 'object' && 'value' in option) {
    return option.value
  }
  if (Array.isArray(option)) {
    return option.map((opt) => opt.value)
  }
  if (typeof option === 'string') {
    return option
  }
  return undefined
}

const normalizeOption = (
  option: SelectOption | string | SelectOption[]
): SelectOption | SelectOption[] => {
  if (typeof option === 'string') {
    return {
      value: option,
      label: option,
      __isNew__: true
    }
  }
  return option
}

export const TestableFunctions = {
  optionValue,
  normalizeOption
}

export const Select = ({
  autoSelectSingleOption = true,
  changeOptionsData,
  components,
  creatable,
  createLabel,
  defaultValue,
  disabled,
  formatGroupLabel,
  formatOptionLabel,
  helperText,
  id,
  isMulti,
  label,
  menuIsOpen,
  menuPlacement = 'bottom',
  menuPortalTarget,
  name,
  options,
  placeholder,
  required,
  revealDelete,
  isClearable,
  selectedValue,
  selectLabel,
  styles,
  onBlur,
  onChange,
  onMultiChange,
  allOptionsSelectedValue
}: {
  autoSelectSingleOption?: boolean
  changeOptionsData?: any
  components?: any
  creatable?: boolean
  createLabel?: string
  defaultValue?: any
  disabled?: boolean
  formatGroupLabel?: any
  formatOptionLabel?: any
  helperText?: string
  id?: string
  isMulti?: boolean
  label?: string | JSX.Element
  menuIsOpen?: boolean | undefined
  menuPlacement?: 'top' | 'bottom'
  menuPortalTarget?: HTMLElement
  name?: string
  options: SelectOption[]
  placeholder?: string
  required?: boolean
  revealDelete?: boolean
  isClearable?: boolean
  selectedValue?: string
  selectLabel?: string
  styles?: any
  onBlur?: () => void
  onChange?: (option: SelectOption) => void
  onMultiChange?: (options: SelectOption[]) => void
  allOptionsSelectedValue?: string
}) => {
  const formContext = getFormContext()
  const [mode, setMode] = useState(
    options.length > 0 || !creatable || !selectLabel || !createLabel ? 'select' : 'create'
  )
  const theme: any = useTheme()

  const selectedOptionOrOptions = useMemo(() => {
    if (isMulti) {
      if (Array.isArray(formContext.value.data[name])) {
        return options.filter((opt) => formContext.value.data[name].includes(opt.value))
      }
      return []
    }

    return (
      options.find(
        (option) => option.value === (selectedValue || (name && formContext.value.data[name]))
      ) ?? ''
    )
  }, [formContext, isMulti, options])

  const selectOption = useCallback(
    (option: string | SelectOption | SelectOption[]) => {
      let normalizedOption = normalizeOption(option)
      let value = optionValue(normalizedOption)
      if (Array.isArray(normalizedOption)) {
        if (
          Array.isArray(selectedOptionOrOptions) &&
          selectedOptionOrOptions.length === 1 &&
          selectedOptionOrOptions.some((anOption) => anOption.value === allOptionsSelectedValue)
        ) {
          normalizedOption = normalizedOption.filter(
            (opt) => opt.value !== allOptionsSelectedValue
          )
          value = optionValue(normalizedOption)
        } else if (
          (normalizedOption.length >= options.length - 1 ||
            normalizedOption.some((anOption) => anOption.value === allOptionsSelectedValue)) &&
          allOptionsSelectedValue
        ) {
          normalizedOption = [
            options.find((anOption) => anOption.value === allOptionsSelectedValue)
          ]
          value = [allOptionsSelectedValue]
        }
        onMultiChange?.(normalizedOption as SelectOption[])
      } else {
        onChange?.(normalizedOption as SelectOption)
      }

      if (name && formContext) {
        const { data } = formContext.value
        formContext.setValue({ data: { ...data, [name]: value } })
      }
    },
    [normalizeOption, optionValue, onChange, onMultiChange, formContext]
  )

  useEffect(() => {
    if (autoSelectSingleOption) {
      if (options?.length === 1) {
        if (
          Array.isArray(selectedOptionOrOptions)
            ? selectedOptionOrOptions.length === 0
            : !selectedOptionOrOptions
        ) {
          selectOption(options[0])
        }
      }
    }
  }, [autoSelectSingleOption])

  const selectOptions = useMemo(() => options.filter((option) => !option.hide), [options])

  const DynamicSelect: any =
    creatable && (!selectLabel || !createLabel) ? CreatableSelect : ReactSelect

  const defaultStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: SelectDropdownLayer }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? Colors.brandPrimary : Colors.pigeon400,
      borderStyle: `solid`,
      borderRadius: theme.borderRadius,
      borderWidth: `1px`,
      boxShadow: state.isFocused
        ? `0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8)`
        : `0 1px 2px rgba(0, 0, 0, 0.05)`,
      cursor: `text`,
      fontSize: fontSizes.type15,
      marginBottom: '6px',
      marginTop: Spacing.basePadding,
      padding: `0 2px`,
      '&:hover': {
        borderColor: state.isFocused ? Colors.brandPrimary : Colors.pigeon400,
        boxShadow: state.isFocused
          ? `0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8)`
          : `0 1px 2px rgba(0, 0, 0, 0.05)`
      },
      '&:focus': {
        borderColor: Colors.brandPrimary,
        boxShadow: state.isFocused
          ? `0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8)`
          : `0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(59, 47, 201, 0.8)`
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: Colors.pigeon600
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    menu: (styles: any) => ({
      ...styles,
      border: 'none',
      boxShadow: `0px 0px 0px 1px #0000000d, 0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a`
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      background: state.isSelected ? `#6673FF` : Colors.white,
      borderRadius: `4px`,
      fontSize: `15px`,
      margin: `0 4px`,
      padding: `8px 10px`,
      maxWidth: `calc(100% - 10px)`,
      '&:hover': {
        background: state.isSelected ? `#6673FF` : Colors.pigeon200,
        cursor: `pointer`
      }
    })
  }
  const combinedStyles = { ...defaultStyles, ...styles }

  const createValue = useMemo(() => {
    return name ? formContext.value.data[name] : ''
  }, [formContext, name])

  return (
    <SelectInput revealDelete={revealDelete}>
      {label ? (
        <SelectLabel htmlFor={id}>
          {label}
          {required ? <RequiredMark>*</RequiredMark> : null}
        </SelectLabel>
      ) : null}
      {creatable && selectLabel && createLabel ? (
        <RadioGroup>
          <RadioLabel>
            <RadioInput
              checked={mode === 'select'}
              onChange={() => {
                selectOption(defaultValue)
                setMode('select')
              }}
            />
            {selectLabel}
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              checked={mode === 'create'}
              onChange={() => {
                selectOption(defaultValue)
                setMode('create')
              }}
            />
            {createLabel}
          </RadioLabel>
        </RadioGroup>
      ) : null}
      {mode === 'select' ? (
        <DynamicSelect
          changeOptionsData={changeOptionsData}
          components={components}
          defaultValue={defaultValue}
          formatGroupLabel={formatGroupLabel}
          formatOptionLabel={formatOptionLabel}
          id={id}
          isOptionDisabled={(option: any) => option.disabled}
          isDisabled={disabled}
          isMulti={isMulti}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          menuPortalTarget={menuPortalTarget}
          onBlur={onBlur}
          onChange={selectOption}
          options={selectOptions}
          placeholder={placeholder}
          styles={combinedStyles}
          value={selectedOptionOrOptions}
          {...(isClearable ? { isClearable } : {})}
        />
      ) : (
        <Input
          formValue={createValue}
          fullWidth
          id={id}
          onChange={selectOption}
          placeholder={createLabel || placeholder}
        />
      )}
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </SelectInput>
  )
}

export const OldSelect = ({
  children,
  label,
  name,
  placeholder,
  required
}: {
  children: ReactNode
  label: string
  name: string
  placeholder?: string
  required?: boolean
}) => {
  const formContext = getFormContext()

  const update = (value: string) => {
    const { data } = formContext.value
    formContext.setValue({ data: { ...data, [name]: value } })
  }

  return (
    <FlatSelectContainer>
      <label>{label}</label>
      <FlatSelect
        placeholder={placeholder}
        required={required}
        disabled={formContext.value.submitting}
        onChange={(e) => update(e.target.value)}
        value={name in formContext.value.data ? formContext.value.data[name] : ''}
      >
        {children}
      </FlatSelect>
    </FlatSelectContainer>
  )
}
