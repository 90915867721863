import { useCallback, useMemo, useState } from 'react'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { a11yLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { fontMonospace } from 'src/resources/typography'
import styled from 'styled-components'

export type ApiMethod = 'get' | 'post'

const Container = styled.div`
  flex-grow: 1;
`

const CodeSnippet = styled(SyntaxHighlighter)``

export function ApiDetail({ body, method, uri }: { body: string; method: string; uri: string }) {
  const [apiFormat, setApiFormat] = useState('fetch')
  const handleSelectChange = useCallback(
    (option: SelectOption) => {
      setApiFormat(option.value)
    },
    [setApiFormat]
  )
  const fetchBody = useMemo(
    () =>
      method === 'post' ? `body: JSON.stringify(${body.replace(/\n/g, '\n    ')}),\n    ` : '',
    [body, method]
  )
  const curlBody = useMemo(
    () => (method === 'post' ? `--data ${JSON.stringify(body)} \\\n  ` : ''),
    [body, method]
  )
  const [language, snippet] = useMemo(() => {
    switch (apiFormat) {
      case 'fetch':
        return [
          'javascript',
          `const flatfileApiKey = 'change-me'
const response = await fetch(
  '${uri}',
  {
    ${fetchBody}headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': flatfileApiKey
    },
    method: '${method.toUpperCase()}'
  }
)
if (response.ok) {
  console.log(await response.json())
}
else {
  console.error(response.status)
}`
        ]
      case 'curl':
        return [
          'shell',
          `curl --header "Content-Type: application/json" \\
  --header "X-Api-Key: change-me" \\
  --request ${method.toUpperCase()} \\
  ${curlBody}${uri}`
        ]
    }
  }, [body, method, uri, apiFormat])
  return (
    <Container>
      <Select
        options={[
          { label: 'Fetch (Node)', value: 'fetch' },
          { label: 'cURL', value: 'curl' }
        ]}
        selectedValue={apiFormat}
        onChange={handleSelectChange}
        defaultValue='fetch'
      />
      <CodeSnippet
        language={language}
        style={a11yLight}
        customStyle={{
          borderRadius: '4px',
          fontFamily: fontMonospace,
          fontSize: '14px',
          padding: '0'
        }}
      >
        {snippet}
      </CodeSnippet>
    </Container>
  )
}
