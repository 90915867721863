import { gql } from '@apollo/client'

export const GET_INVITATION = gql`
  query GetInvitation($token: String!) {
    getInvitation(token: $token) {
      id
      token
      email
      role
      team {
        id
        name
      }
      user {
        id
      }
    }
  }
`
