import { gql } from 'apollo-boost'
import {
  GetWorkspaceGraphQLAuth,
  GetWorkspaceGraphQLAuthVariables,
  GetWorkspaceGraphQLAuth_getWorkspaceGraphQLAuth
} from 'src/smart/queries/types/GetWorkspaceGraphQLAuth'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_WORKSPACE_GRAPHQL_AUTH: SmartQuery<
  GetWorkspaceGraphQLAuthVariables,
  GetWorkspaceGraphQLAuth,
  GetWorkspaceGraphQLAuth_getWorkspaceGraphQLAuth
> = {
  query: gql`
    query GetWorkspaceGraphQLAuth($workspaceId: UUID!, $graphQLEndpoint: String!) {
      getWorkspaceGraphQLAuth(workspaceId: $workspaceId, graphQLEndpoint: $graphQLEndpoint) {
        createdAt
        createdBy {
          name
        }
        expiresAt
        headersSanitized
        id
      }
    }
  `,
  extract({ getWorkspaceGraphQLAuth }) {
    return getWorkspaceGraphQLAuth
  }
}
