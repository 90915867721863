import { CellChangeDetail, CellDataProps } from '@turntable/core'

import { SchemaProperty } from '../properties/SchemaProperty'
import { Primitive } from '../VirtualRecord'

export abstract class Atom<T extends any = any> {
  constructor(
    protected property: SchemaProperty,
    public value: Primitive,
    protected meta: IAtomMeta
  ) {}

  /**
   * Turntable-ready cell props
   *
   * @returns
   */
  public toCellProps(): CellDataPropsWithColumn<T> {
    const status = this.toCellStatus()
    // TODO: improve on this hack and make sure we have first class hook support for DISABLED
    const disabledIndex = status.warning?.findIndex((info) => info === 'DISABLED')
    if (disabledIndex >= 0) {
      status.warning[disabledIndex] = 'This field is disabled'
    }
    return {
      value: this.toCellValue(),
      column: this.meta.column,
      mutated: status.info?.length > 0,
      disabled: disabledIndex >= 0,
      status
    }
  }

  /**
   * Hydrate this cell from a Turntable cell change.
   *
   * @param change
   */
  public patch(change: CellChangeDetail<T>): this {
    this.value = this.valueFromCellChangeDetail(change)
    return this
  }

  /**
   * Hydrate this cell from a cell change
   * @param change
   */
  public abstract valueFromCellChangeDetail(change: CellChangeDetail<T>): Primitive

  /**
   * Extract any error, warning, and/or info status's
   *
   * Example Output:
   *  {
   *     info: ['An info message', 'Another info message'],
   *     warning: ['A warning message', 'Another warning message'],
   *     error: ['An error message', 'Another error message'],
   *  }
   * @param validations
   * @private
   * @returns
   */
  protected toCellStatus(): ITurntableMessages {
    const msgs = this.meta.messages

    const error = msgs.filter((m) => m.level === IMessageLevel.ERROR).map((m) => m.message)
    const warning = msgs.filter((m) => m.level === IMessageLevel.WARN).map((m) => m.message)
    const info = msgs.filter((m) => m.level === IMessageLevel.INFO).map((m) => m.message)

    // todo: an empty error array handed to the table will result in error visuals. Fix this
    return {
      ...(error.length ? { error } : {}),
      ...(warning.length ? { warning } : {}),
      ...(info.length ? { info } : {})
    }
  }

  protected abstract toCellValue(): T
}

export type CellDataPropsWithColumn<T> = CellDataProps<T> & { column: number }

export type ITurntableMessages = {
  info?: string[]
  warning?: string[]
  error?: string[]
}

export type IAtomMessage = {
  message: string
  level: IMessageLevel
}

export interface IAtomMeta {
  key: string
  column: number
  messages: { level: IMessageLevel; message: string }[]
}

export enum IMessageLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}
