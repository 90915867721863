import { gql } from '@apollo/client'
import {
  SmartEditUser,
  SmartEditUserVariables,
  SmartEditUser_editUser
} from 'src/smart/mutations/types/SmartEditUser'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_EDIT_USER: SmartMutation<
  SmartEditUserVariables,
  SmartEditUser,
  SmartEditUser_editUser
> = {
  mutation: gql`
    mutation SmartEditUser($id: ID!, $name: String) {
      editUser(id: $id, name: $name) {
        id
        email
        name
      }
    }
  `,
  extract(response) {
    return response.editUser
  }
}
