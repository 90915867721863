import { gql } from '@apollo/client'
import {
  SmartGetDataHookCloudWatchLogs,
  SmartGetDataHookCloudWatchLogsVariables,
  SmartGetDataHookCloudWatchLogs_getDataHookCloudWatchLogs
} from 'src/smart/queries/types/SmartGetDataHookCloudWatchLogs'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_DATAHOOK_CLOUDWATCH_LOGS: SmartQuery<
  SmartGetDataHookCloudWatchLogsVariables,
  SmartGetDataHookCloudWatchLogs,
  SmartGetDataHookCloudWatchLogs_getDataHookCloudWatchLogs
> = {
  query: gql`
    query SmartGetDataHookCloudWatchLogs(
      $logGroupName: ID!
      $logStreamName: ID!
      $dataHookId: ID!
      $requestId: String
      $nextToken: String
    ) {
      getDataHookCloudWatchLogs(
        logGroupName: $logGroupName
        logStreamName: $logStreamName
        requestId: $requestId
        dataHookId: $dataHookId
        nextToken: $nextToken
      ) {
        success
        logs
        nextForwardToken
        nextBackwardToken
      }
    }
  `,
  extract({ getDataHookCloudWatchLogs }) {
    return getDataHookCloudWatchLogs
  }
}
