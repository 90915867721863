import {
  SmartFetchWorkbookRows_fetchRowsWithSchema_rows,
  SmartFetchWorkbookRows_fetchRowsWithSchema_rows_validations
} from 'src/smart/queries/types/SmartFetchWorkbookRows'
import { SmartTestDataHookRows_runTestDataHook_rows } from 'src/smart/queries/types/SmartTestDataHookRows'

import { IMessageLevel } from '../atoms/Atom'
import { Schema } from '../Schema'
import {
  ERecordState,
  IRecordMessage,
  IRecordMeta,
  PrimitiveRecord,
  VirtualRecord
} from '../VirtualRecord'
import { Transport } from './Transport'

/**
 * This transport converts data coming from DAL endpoints to the normalized models
 */
export class DALTransport extends Transport {
  /**
   * Cast a standard pre-DAL mongo response to a VirtualRecord
   *
   * @param schema
   * @param data
   * @param pointer
   * @returns
   */
  public static fromDAL(
    schema: Schema,
    data: Omit<
      SmartFetchWorkbookRows_fetchRowsWithSchema_rows | SmartTestDataHookRows_runTestDataHook_rows,
      '__typeName'
    >,
    pointer: number
  ): VirtualRecord {
    const meta = this.prepareMeta(data, pointer)
    return new VirtualRecord(schema, this.normalizeCells(data.cells), meta)
  }

  /**
   * Convert the DAL nested value structure to a simple nested structure (for now)
   *
   * @param cells
   * @returns
   */
  private static normalizeCells(cells: Record<string, { value: any }>): PrimitiveRecord {
    const keys = Object.keys(cells)
    return keys.reduce((acc, k) => {
      return { ...acc, [k]: cells[k].value }
    }, {} as PrimitiveRecord)
  }

  /**
   * Generate a normalized meta object for the virtual record
   *
   * @param data
   * @param pointer
   * @returns
   */
  private static prepareMeta(
    data:
      | SmartFetchWorkbookRows_fetchRowsWithSchema_rows
      | SmartTestDataHookRows_runTestDataHook_rows,
    pointer: number
  ): IRecordMeta {
    const messages = this.prepareMessages(data.validations)

    return {
      id: String(data._id),
      state: ERecordState.REVIEW,
      messages,
      pointer,

      // todo: this needs to use server side logic, not just message count
      valid: messages.filter((m) => m.level === IMessageLevel.ERROR).length === 0,

      // todo: this needs to come from the server (!!)
      ordinal: pointer + 1
    }
  }

  /**
   * Normalize the messages based on the lastEdit from Mongo
   *
   * @param lastEdit
   * @returns
   */
  private static prepareMessages(
    validations: SmartFetchWorkbookRows_fetchRowsWithSchema_rows_validations[]
  ): IRecordMessage[] {
    return validations.map(({ message, error, key }) => {
      const level = this.normalizeMessageType(error)
      return {
        message,
        key,
        level
      }
    })
  }
}
