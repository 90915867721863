import { FocusEvent, useCallback } from 'react'

import styled from 'styled-components'

const CopyableInput = styled.input`
  width: auto;
`

export function Copyable({ value }: { value: string }) {
  const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => e.target.select(), [])
  return <CopyableInput readOnly value={value} onFocus={handleFocus} />
}
