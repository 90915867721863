import { ReactNode } from 'react'

import { Colors } from 'src/resources/colors'
import { AvatarCircle } from 'src/resources/elements/Avatar'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { Transitions } from '../animations/transitions'

import { HaloButton } from './HaloButton'

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
`

export const StyledTable = styled.table`
  border-spacing: 0;
  margin-bottom: ${Spacing.basePadding3x};
  overflow: auto;
  width: 100%;

  td {
    max-width: 260px;
    font-weight: 700;
    line-height: 1.35;
    overflow: hidden;
    padding: ${Spacing.basePadding2x};
    text-overflow: ellipsis;
    white-space: normal;
  }

  td:first-of-type,
  th:first-of-type {
    padding-left: ${Spacing.contentPadding};
  }

  td:last-of-type,
  th:first-of-type {
    padding-right: ${Spacing.contentPadding};
  }

  a {
    color: ${Colors.brandSecondary};
    font-family: ${fontFamily.fontSecondary};
    font-size: ${fontSizes.type14};
    font-weight: 500;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  thead {
    font-family: ${fontFamily.fontPrimary};

    td,
    th {
      color: ${Colors.pigeon700};
      font-family: ${fontFamily.fontPrimary};
      font-size: ${fontSizes.type13};
      font-weight: 600;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }

    p {
      font-family: ${fontFamily.fontPrimary};
      font-weight: 600;
    }
  }

  tbody tr td {
    border-top: 1px solid ${Colors.gray40};
  }
`

export const NewStyledTable = styled(StyledTable)`
  max-width: 100%;
  margin-top: ${Spacing.basePadding};

  th {
    color: ${Colors.pigeon700};
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type13};
    font-weight: 600;
    letter-spacing: 0.1f5em;
    padding: ${Spacing.basePadding2x} ${Spacing.contentPadding};
    text-align: left;
    text-transform: uppercase;
    white-space: pre;
  }

  td {
    max-width: 260px;
    color: ${Colors.text};
    font-size: ${fontSizes.type15};
    font-weight: 400;
    padding: ${Spacing.basePadding2x} ${Spacing.basePadding3x};
  }
`

export const NoClickTd = styled.td`
  cursor: default;
`

export const StyledTR = styled.tr`
  transition: background 0.2s ease-in;
  height: 70px;

  ${HaloButton} {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover ${HaloButton} {
    opacity: 1;
  }
`

export const ClickableTR = styled(StyledTR)<{
  disableClick?: boolean
  cellWrap?: boolean
  changeBorderOnHover?: boolean
}>`
  ${({ disableClick }) =>
    disableClick
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
        `}

  transition: ${Transitions.baseEaseWithProperty('background')};
  height: 70px;

  &:hover {
    background-color: ${Colors.rowHover};

    ${({ changeBorderOnHover }) =>
      changeBorderOnHover
        ? css`
            ${AvatarCircle} {
              border-color: ${Colors.actionHover};
              transition: ${Transitions.baseEase};
            }
          `
        : null}
  }

  td,
  a,
  td > div {
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ cellWrap }) => (cellWrap ? 'unset' : 'nowrap')};
  }
`

export const NewTable = ({ children }: { children: ReactNode }) => (
  <Wrapper>
    <NewStyledTable>{children}</NewStyledTable>
  </Wrapper>
)
