import { FC } from 'react'

import { useMutation } from '@apollo/client'
import { Link, withRouter } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Carousel } from 'src/applications/Oversight/components/Carousel'
import { SplitScreenTemplate } from 'src/applications/Oversight/templates/SplitScreenTemplate'
import { FLATFILE_PRODUCT_HOMEPAGE } from 'src/config'
import { getUserContext } from 'src/contexts/UserContext'
import { GET_INVITATION } from 'src/queries/GET_INVITATION'
import { REGISTER_USER } from 'src/queries/REGISTER_USER'
import {
  GetInvitation,
  GetInvitationVariables,
  GetInvitation_getInvitation
} from 'src/queries/types/GetInvitation'
import { RegisterUser, RegisterUserVariables } from 'src/queries/types/RegisterUser'
import { updateClientLink } from 'src/resources/clients/graphClient'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { FormCheckBox } from 'src/resources/elements/form/CheckBox'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Header } from 'src/resources/elements/Header'
import { MyLogo } from 'src/resources/elements/Logo'
import { QueryLoader } from 'src/resources/elements/QueryAlert'
import { useFindOne } from 'src/smart/hooks/useSmartQuery'
import { IUser } from 'src/types/interfaces/IUser'

export const InviteScene = () => <InvitePanel />

export const InvitePanel = withRouter(({ history, match: { params } }) => {
  const { value } = getUserContext()

  const getInvitation = useFindInvitation(params.inviteToken)

  return (
    <QueryLoader query={getInvitation}>
      {(invitation) =>
        invitation.user ? (
          <InviteAcceptedNotice inviteData={invitation} history={history} user={value.user} />
        ) : (
          <InviteSignUpForm inviteData={invitation} history={history} />
        )
      }
    </QueryLoader>
  )
})

export const InviteSignUpForm: FC<{
  inviteData: GetInvitation_getInvitation
  history: any
}> = ({ inviteData, history }) => {
  const { setValue } = getUserContext()
  const { addToast } = useToasts()

  const SignUpForm: TForm<{
    fullName: string
    email: string
    password: string
    accept: boolean
  }> = Form

  const [registerUser] = useMutation<RegisterUser, RegisterUserVariables>(REGISTER_USER)

  return (
    <SplitScreenTemplate rightSide={<Carousel />}>
      <div>
        <Link to={FLATFILE_PRODUCT_HOMEPAGE}>
          <MyLogo />
        </Link>
        <Header>
          <h1>
            <div>
              You have been invited by <i>{inviteData.team.name}</i>
            </div>
          </h1>
        </Header>
        <SignUpForm
          initialValue={{ email: inviteData.email }}
          onSubmit={async (event) => {
            const { fullName, password, accept } = event.data

            let formErrors = {}

            if (!fullName) {
              formErrors = {
                ...formErrors,
                fullName: 'Please enter a valid name.'
              }
            }

            if (!password) {
              formErrors = {
                ...formErrors,
                password: 'Please enter a password.'
              }
            } else if (password.length < 8) {
              formErrors = {
                ...formErrors,
                password: 'Password must be at least 8 characters long.'
              }
            }

            if (Object.keys(formErrors).length > 0) {
              event.formContext.setValue({
                errors: formErrors
              })
              return
            }

            if (!accept) {
              addToast('Please accept the terms of service', {
                appearance: 'error',
                autoDismiss: true
              })
              return
            }

            const { data } = await registerUser({
              variables: {
                email: inviteData.email,
                fullName,
                inviteToken: inviteData.token,
                password,
                role: 'member',
                urlSource: 'team-invite'
              }
            })

            // @todo no handling of errors here

            if (data) {
              const {
                registerUser: { user, accessToken }
              } = data
              setValue({ user })
              updateClientLink(accessToken)
            }

            history.replace(`/a/${inviteData.team.id}/team`)
          }}
        >
          <InputGroup>
            <Input
              label='Full name'
              name='fullName'
              placeholder='Jane Smith'
              autoComplete='name'
            />
            <ErrorMessage name='fullName' />
          </InputGroup>

          <InputGroup>
            <Input label='Email address' name='email' disabled autoComplete='email' />
            <ErrorMessage name='email' />
          </InputGroup>

          <InputGroup>
            <Input
              autoComplete='new-password'
              label='Password'
              name='password'
              type='password'
              placeholder='• • • • • • • •'
            />
            <ErrorMessage name='password' />
          </InputGroup>

          <InputGroup>
            <FormCheckBox type='primary' name='accept'>
              Accept the platform{' '}
              <a target='_blank' href='/terms'>
                terms of service
              </a>
            </FormCheckBox>
          </InputGroup>

          <ButtonGroup>
            <FormButton primary submit>
              Continue
            </FormButton>
            <FormButton
              secondary
              onClick={() => {
                history.replace('/')
              }}
            >
              Cancel
            </FormButton>
          </ButtonGroup>
        </SignUpForm>
      </div>
    </SplitScreenTemplate>
  )
}

export const InviteAcceptedNotice = ({
  inviteData,
  history,
  user
}: {
  inviteData: GetInvitation_getInvitation
  history: any
  user?: IUser
}) => {
  const teamPageUrl = `/a/${inviteData.team.id}/team`
  const formButton =
    user && inviteData.user && user.id === inviteData.user.id ? (
      <FormButton
        primary
        onClick={() => {
          history.replace(teamPageUrl)
        }}
      >
        Continue
      </FormButton>
    ) : (
      <FormButton
        primary
        onClick={() => {
          history.replace(`/login?redir=${teamPageUrl}`)
        }}
      >
        Log in to Continue
      </FormButton>
    )

  return (
    <SplitScreenTemplate rightSide={<Carousel />}>
      <div>
        <Header>
          <h1>
            <div>
              You have been invited by <i>{inviteData.team.name}</i>
            </div>
          </h1>
          <p>The invitation has been accepted</p>
          <ButtonGroup>{formButton}</ButtonGroup>
        </Header>
      </div>
    </SplitScreenTemplate>
  )
}

const useFindInvitation = (token: string) => {
  return useFindOne<GetInvitation, GetInvitationVariables>(
    GET_INVITATION,
    {
      token
    },
    'getInvitation',
    !token?.length
  )
}
