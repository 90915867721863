import { useCallback } from 'react'

import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { useTableFilters } from 'src/resources/hooks/useTableFilters'
import DoubleCheckIcon from 'src/resources/icons/doublecheck'
import InReviewIcon from 'src/resources/icons/inreview'
import TrashIcon from 'src/resources/icons/trash'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const FilterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 20px;
    margin-right: ${Spacing.basePadding};
    transition: 0.3s ease;
  }
`

export interface ICount {
  review: number
  dismissed: number
  accepted: number
  filtered: number

  [k: string]: number
}

type ValidationStateType = 'review' | 'dismissed' | 'accepted'
const ValidationStateTypes: ['review', 'accepted', 'dismissed'] = [
  'review',
  'accepted',
  'dismissed'
]

export const DataFilters = () => {
  const [{ validationState }, setTableFilters] = useTableFilters()
  const handleFilterClick = useCallback(
    (stateLabel: ValidationStateType) => {
      setTableFilters({ validationState: stateLabel })
    },
    [setTableFilters]
  )

  const [filterToReview, filterToAccepted, filterToDismissed] = ValidationStateTypes.map((state) =>
    useCallback(() => handleFilterClick(state), [handleFilterClick])
  )

  return (
    <Tabstrip bottomBorder>
      <Tab active={validationState === 'review'} onClick={filterToReview} data-testid='in-review'>
        <FilterButtonWrap>
          <InReviewIcon />
          In Review
        </FilterButtonWrap>
      </Tab>
      <Tab
        active={validationState === 'accepted'}
        onClick={filterToAccepted}
        data-testid='approved'
      >
        <FilterButtonWrap>
          <DoubleCheckIcon />
          Approved
        </FilterButtonWrap>
      </Tab>
      <Tab
        active={validationState === 'dismissed'}
        onClick={filterToDismissed}
        right
        data-testid='dismissed'
      >
        <FilterButtonWrap>
          <TrashIcon />
          Dismissed
        </FilterButtonWrap>
      </Tab>
    </Tabstrip>
  )
}
