import { css } from 'styled-components'

import { Colors, ColorUtils } from '../../../../../src/resources/colors'
import { IFlatButtonStyle } from '../IFlatButton'

export const AlternatePrimaryFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return css`
          background-color: transparent !important;
          border: 1px solid ${ColorUtils.hexToRgba(Colors.brandLightBlue, 0.7)} !important;
          color: ${Colors.brandLightBlue} !important;
          &:hover {
            border-color: ${Colors.brandLightBlue} !important;
          }
        `
      case 'hover-outlined':
        return css`
          background-color: transparent !important;
          border: 1px solid #c9d3df !important;
          color: ${Colors.brandLightBlue} !important;
          &:hover {
            border-color: ${Colors.brandLightBlue} !important;
            background-color: ${Colors.brandLightBlue} !important;
            color: ${Colors.white} !important;
          }
        `
      case 'grayscale':
        return css`
          background-color: transparent !important;
          border: 1px solid ${Colors.pigeon400} !important;
          color: ${Colors.pigeon800} !important;
          &:hover {
            background-color: ${Colors.pigeon200} !important;
          }
        `
      default:
        return css`
          background-color: ${Colors.brandLightBlue} !important;
          color: ${Colors.white} !important;
          box-shadow: 0 1px 8px -2px ${ColorUtils.hexToRgba(Colors.brandLightBlue, 0.3)},
            0 2px 12px -3px ${ColorUtils.hexToRgba(Colors.brandLightBlue, 0.2)};

          &:hover {
            background-color: ${Colors.brandDarkBlue} !important;
          }
        `
    }
  }}
`
