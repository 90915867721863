import { gql } from '@apollo/client'
import { EditTeam, EditTeamVariables, EditTeam_editTeam } from 'src/smart/mutations/types/EditTeam'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_EDIT_TEAM: SmartMutation<EditTeamVariables, EditTeam, EditTeam_editTeam> = {
  mutation: gql`
    mutation EditTeam($input: EditTeamDto!) {
      editTeam(input: $input) {
        id
      }
    }
  `,
  extract: (response: any) => response.editTeam
}
