import { useEffect } from 'react'

import { withRouter } from 'react-router-dom'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'

export const LogoutScene = withRouter(() => {
  useEffect(() => {
    UserTransceiver.logout()
    window.location.pathname = '/login' // reload the page to unload JS memory
  }, [])

  return null
})
