import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import { Span, Transaction } from '@sentry/types'

/* 
See: https://docs.sentry.io/product/performance/distributed-tracing/

Tracing hierarchy - used for tracking product reliability
1. Trace (entire operation)
2. Transaction (single instance of a service) (parent-level spans)
3. Span (single unit of work)
*/

interface ISentryTag {
  key: string
  value: string
}

interface ISentryTransaction {
  name?: string
  level?: Severity
  spans?: Partial<Span>[]
  // this can be set with scope
  tags?: ISentryTag[]
}
interface ISentryNamedSpans {
  [key: string]: Span
}

const appendChildSpans = (
  transaction: Transaction,
  spans: Partial<Span>[],
  namedSpans: ISentryNamedSpans
) => {
  spans?.forEach((span: Span) => {
    namedSpans[span.op] = transaction.startChild({
      data: span.data,
      op: span.op,
      description: span.description
    })
  })
}

export const submitSentrySpan = (transaction: any, name: string) => {
  if (transaction.namedSpans[name]) {
    transaction.namedSpans[name].finish()
  }
}

export const useSentryTransaction = ({ name, spans }: ISentryTransaction) => {
  const activeTransaction = Sentry.getCurrentHub().getScope().getTransaction()
  const transaction = Sentry.startTransaction({ name })
  const namedSpans: ISentryNamedSpans = {}

  const setTag = (key: string, value: string) => {
    transaction.setTag(key, value)
  }

  // pass a name to useSentryTransaction to initialize transaction
  if (name) {
    Sentry.configureScope((scope) => {
      scope.setSpan(transaction)
      appendChildSpans(transaction, spans, namedSpans)
    })
  }

  // if an activeTransaction exists - append new spans to existing transaction
  if (activeTransaction && !name) {
    appendChildSpans(activeTransaction, spans, namedSpans)
  }

  return { namedSpans, activeTransaction, transaction, setTag }
}
