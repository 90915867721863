import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import useRouter from 'use-react-router'

function useParseQuerySearch(parseNumbers: boolean = false) {
  const { location } = useRouter()
  return queryString.parse(location.search, { parseNumbers })
}

export const useSearchParam = {
  boolean(name: string, defaultValue: boolean): boolean {
    const query = useParseQuerySearch()
    return name in query ? query[name] === 'true' : defaultValue
  },
  string(name: string, defaultValue?: string): string | undefined {
    const query = useParseQuerySearch(false)
    return name in query
      ? Array.isArray(query[name])
        ? query[name]?.[0]
        : (query[name] as string)
      : defaultValue
  }
}

// all param removes/sets the designated search param and removes all others
export function useUpdateSearchParam(key: string, all?: boolean) {
  const query = useParseQuerySearch()
  const history = useHistory()
  return {
    remove() {
      const location = Object.assign({}, history.location)
      if (!all) {
        delete query[key]
        location.search = queryString.stringify(query)
      } else {
        location.search = ''
      }
      history.push(location)
    },
    set(value: string) {
      const location = Object.assign({}, history.location)
      if (all) {
        location.search = `${key}=${value}`
      } else {
        query[key] = value
        location.search = queryString.stringify(query)
      }
      history.push(location)
    }
  }
}
