import { VariableDepthArray } from 'src/types/structures/VariableDepthArray'

export const flattenVariableDepthArray = <T,>(input: VariableDepthArray<T>): T[] =>
  !Array.isArray(input)
    ? [input]
    : input.reduce<T[]>(
        (final, next) =>
          final.concat(Array.isArray(next) ? flattenVariableDepthArray(next) : next),
        []
      )

export function mergeIdArrays<T>(...arrays: [string, T][][]): [string, T][] {
  const firstIdIndex: Record<string, number> = {}
  const finalArray: [string, T][] = []
  arrays.forEach((arr) => {
    arr.forEach(([id, value]) => {
      if (id in firstIdIndex) {
        const [, finalObject] = finalArray[firstIdIndex[id]]
        Object.assign(finalObject, value)
      } else {
        firstIdIndex[id] = finalArray.length
        finalArray.push([id, value])
      }
    })
  })
  return finalArray
}
