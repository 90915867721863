import { ReactNode } from 'react'

import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'

export interface ITestAccessKeyFormData {
  accessKeyId: string
  secretAccessKey: string
}

const TestAccessKeyFormElement: TForm<ITestAccessKeyFormData> = Form

export const TestAccessKeyForm = ({
  accessKeyId,
  children,
  onSubmit,
  onCancel
}: {
  accessKeyId: string
  children?: ReactNode
  onSubmit: (event: IFormSubmitEvent<ITestAccessKeyFormData>) => Promise<void>
  onCancel: () => void
}) => (
  <TestAccessKeyFormElement onSubmit={onSubmit} initialValue={{ accessKeyId }}>
    <Input autoSelect fullWidth label='Access key id' name='accessKeyId' />
    <Input autoSelect fullWidth label='Secret access key' name='secretAccessKey' />
    {children}
    <ButtonGroup right>
      <FormButton secondary onClick={onCancel}>
        Done
      </FormButton>
      <FormButton primary submit>
        Test
      </FormButton>
    </ButtonGroup>
  </TestAccessKeyFormElement>
)
