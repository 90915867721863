import { ReactNode, useCallback } from 'react'

import { TapArea } from 'src/resources/elements/TapArea'

import { DeveloperMenuRouteControl } from './DeveloperMenuRoutes'

export const DeveloperMenuLink = ({
  to,
  children,
  control
}: {
  to: string[]
  children: ReactNode
  control: DeveloperMenuRouteControl
}) => (
  <TapArea grow onClick={useCallback(() => control.setRoute(to), [to])}>
    {children}
  </TapArea>
)
