import Skeleton from 'react-loading-skeleton'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { Spacing } from 'src/resources/layout'
import { named } from 'src/resources/utils/named'
import styled from 'styled-components'

const SkeletonWorkspaceSettingsFormWrapper = styled.div`
  padding-left: ${Spacing.basePadding4x};
  padding-top: ${Spacing.basePadding2x};
  padding-bottom: ${Spacing.basePadding2x};

  .mt-16 {
    margin-top: 16px;
    display: block;
  }

  .mt-20 {
    margin-top: 20px;
  }
`

export const basicInfoSkeleton = (
  /* eslint-disable react/forbid-component-props */
  <SkeletonWorkspaceSettingsFormWrapper>
    <Skeleton width={160} height={20} />
    <Skeleton width={260} height={30} className='mt-16' />
    <Skeleton width={130} height={20} className='mt-16' />
    <Skeleton width={260} height={30} className='mt-16' />
    <Skeleton width={165} height={20} className='mt-16' />
    <Skeleton width={560} height={30} className='mt-16' />
  </SkeletonWorkspaceSettingsFormWrapper>
)

export const WorkspaceSettingsBasicInfoSkeleton = named(
  'WorkspaceSettingsBasicInfoSkeleton',
  () => {
    return (
      <>
        <Card>
          <PageHeaderContainer
            header='Workspace settings'
            description='Collaborate on customer data by inviting your team or uploading data.'
            hasMargin={true}
          />
          <Tabstrip>
            <Tab active={true}>Basic Info</Tab>
          </Tabstrip>
          {basicInfoSkeleton}
        </Card>
      </>
    )
  }
)
