import { ElementType } from 'react'

import { animated, useSpring } from 'react-spring'
import { StickyLayer } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { CloseIcon, RightChevron, SparklesIcon, SuccessCheck } from 'src/resources/elements/Icons'
import { CloseModal } from 'src/resources/elements/Modal'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { spring } from 'src/resources/utils/spring'
import styled, { css } from 'styled-components'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

export const ExternalSchemaName = styled.div`
  color: ${Colors.pigeon600};
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  svg {
    margin: 0 ${Spacing.basePadding};
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    height: 100%;
    text-overflow: ellipsis;
    max-width: 90px;
  }
`

export const GroupLabel = styled.span`
  color: ${Colors.textLogo};
  font-family: ${(props) => props.theme.fontFamily || fontFamily.fontSecondary};
  font-size: 14px;
  font-weight: 600;
  padding: ${Spacing.basePadding} ${Spacing.basePadding2x} ${Spacing.halfBasePadding};
  display: block;
`

export const GroupWrapper = styled.div<{ suggested?: boolean }>`
  > div {
    padding: ${({ suggested }) =>
      suggested
        ? `${Spacing.basePadding} ${Spacing.basePadding2x}`
        : `${Spacing.basePadding} ${Spacing.basePadding4x}`};
  }
  padding: ${Spacing.basePadding} 0;
  padding-bottom: 0px;
`

export const LinkedGroupWrapper = styled.div`
  padding: ${Spacing.basePadding} 0;
`

export const DropdownHeading = styled.span`
  font-size: ${fontSizes.type16};
  color: ${Colors.pigeon800};
  margin-left: ${Spacing.basePadding2x};
  font-weight: 600;
`

export const DropdownLabel = styled.span`
  font-size: ${fontSizes.type16};
  color: ${Colors.pigeon800};
`

export const SuggestedPill = styled.span`
  background: ${Colors.textLogo};
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  padding: ${Spacing.halfBasePadding} ${Spacing.basePadding};
  width: auto;
  span {
    color: #7e8598;
    margin-right: ${Spacing.basePadding};
  }
`

export const HeadingWrapper = styled.span`
  ${({ theme }) =>
    theme?.sage
      ? css`
          text-transform: capitalize;
          font-size: ${themed('contentFontSize')};
          font-weight: 500;
          line-height: 1;
          color: ${themed('textColor')};
          margin-left: -16px;
        `
      : css`
          text-transform: uppercase;
          font-size: ${fontSizes.type12};
          font-weight: 700;
          line-height: ${fontSizes.type24};
          letter-spacing: 0.1em;
          color: ${Colors.pigeon600};
          margin-left: -16px;
        `}
  text-transform: ${themed('dropdownHeader_textTransform')};
  font-size: ${themed('dropdownHeader_fontSize')};
  font-weight: ${themed('dropdownHeader_fontWeight')};
  letter-spacing: ${themed('dropdownHeader_letterSpacing')};
  color: ${themed('dropdownHeader_textColor')};
  line-height: 1.5;
  margin-left: -16px;
`

export const SparkleWrapper = styled.span`
  padding-left: ${Spacing.basePadding};
  & > svg {
    width: 13px;
    height: 13px;
  }
`

export const DropdownOption = styled.span`
  weight: 400;
`

export const StyledRightChevron = styled(RightChevron)<{ expanded?: boolean }>`
  margin-right: 10px;
  transform: ${({ expanded }) => (expanded ? 'rotate(90deg)' : '')};
  transition: transform 0.25s;
`

export const TypeLabel = styled.span<{ required?: boolean }>`
  color: ${({ required }) => (required ? `${Colors.alertText}` : `${Colors.pigeon600}`)};
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  padding-left: ${Spacing.basePadding};
`

export const DataSourceStatus = styled.div<{ active?: boolean; small?: boolean }>`
  width: ${({ small }) => (small ? '14px' : '24px')};
  height: ${({ small }) => (small ? '14px' : '24px')};
  border: 1px solid ${themed('borderColor')};
  background: white;
  border-radius: ${({ theme }) => (theme.sage || theme.flexport ? '0' : '20px')};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    flex-shrink: 0;
    height: ${({ small }) => (small ? '20px' : '28px')};
    width: ${({ small }) => (small ? '20px' : '28px')};

    ${({ theme }) =>
      theme.sage &&
      css`
        path {
          fill: black;
        }
      `}
  }

  ${({ active }) =>
    !!active &&
    css`
      background-color: ${({ theme }) =>
        theme.sage ? 'white' : theme.secondary || theme.primary || '#377e62'};
      border-color: ${(props) => props.theme.secondary || props.theme.primary || '#377e62'};
    `}
`

export function MatchIndicator({ active }: { active?: boolean }) {
  return (
    <DataSourceStatus active={active}>
      {active && <SuccessCheck circle={'transparent'} />}
    </DataSourceStatus>
  )
}

export const RightChevronWrapper = styled.span`
  padding: ${Spacing.basePadding};
`

export const DataPreviewContent = styled.div`
  background: ${Colors.white};
  border: ${({ theme }) => theme?.cardsHaveBorder ?? `1px solid ${themed('borderColor')}`};
  box-shadow: ${themed('largeBoxShadow')};
  border-radius: ${themed('borderRadius')};
  overflow: hidden;
  position: relative;
`

export const DataPreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${fontSizes.type16};
  font-weight: 500;
  color: ${themed('textColor')};
  margin: 0;
  padding: ${Spacing.basePadding3x};

  > *:first-child {
    margin-right: 10px;
  }
`

export const TableRow = styled.tr`
  font-family: ${(props) => props.theme.fontFamily || fontFamily.fontSecondary};
`

export const TableWrapper = styled.div`
  height: calc(100vh - 104px - 120px);
  flex: 1;

  ::-webkit-scrollbar {
    width: 0;
  }

  font-family: ${(props) => props.theme.fontFamily || fontFamily.fontSecondary};
`

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  table-layout: fixed;

  thead {
    background-color: ${themed('modalBackgroundColor')};
    position: sticky;
    top: 0;
    z-index: ${StickyLayer};

    & > ${TableRow} > th {
      font-size: ${themed('tableListHeader_FontSize')};
      font-weight: ${themed('tableListHeader_FontWeight')};
      line-height: ${themed('tableListHeader_LineHeight')};
      color: ${themed('tableListHeader_Color')};
      padding: 32px 16px 24px;
      border-bottom: 1px solid ${themed('borderColor')};

      @media (max-height: 600px) {
        padding: 16px;
      }

      &:nth-child(1) {
        width: 60px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(4) {
        padding-left: 24px;
      }
      &:nth-child(5) {
        width: 60px;
      }
    }
  }

  tbody > ${TableRow} {
    &:hover {
      background: ${themed('rowHoverColor')};
    }

    & > td {
      font-size: ${themed('tableListItem_FontSize')};
      font-weight: ${themed('tableListItem_FontWeight')};
      line-height: ${themed('tableListItem_LineHeight')};
      color: ${themed('tableListItem_Color')};
      padding: 16px;

      @media (max-height: 600px) {
        padding: 12px;
      }

      &:last-child {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
`

const SubmittedLoaderWrapper = styled.div`
  position: fixed;
  z-index: 17000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(44, 45, 48, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;

  & > svg {
    width: 34px;
    height: 34px;
    margin-bottom: 8px;
  }

  & > button {
    margin-top: 16px;
  }
`

const StyledCloseModal = styled(CloseModal)`
  right: ${Spacing.basePadding3x};
  top: ${Spacing.basePadding3x};
`

export function SubmittedLoader({ status }: { status?: string }) {
  return (
    <SubmittedLoaderWrapper>
      <Spinner color='white' />
      {status || 'Getting you ready..'}
    </SubmittedLoaderWrapper>
  )
}

export function SubmittedLoaderTryAgain({
  message = 'Something went wrong',
  onTryAgain,
  onClose
}: {
  message: string
  onTryAgain(): void
  onClose(): void
}) {
  return (
    <SubmittedLoaderWrapper>
      <StyledCloseModal onClick={onClose}>
        <CloseIcon />
      </StyledCloseModal>
      {message}
      <FlatButton color='white' onClick={() => onTryAgain()}>
        Try Again
      </FlatButton>
    </SubmittedLoaderWrapper>
  )
}

export const Sparkle = () => (
  <>
    <SparkleWrapper>
      <SparklesIcon />
    </SparkleWrapper>
  </>
)

export const FadeUpMessage = ({
  message,
  component
}: {
  message: JSX.Element | string
  component?: ElementType
}) => {
  const { opacity, y } = useSpring({
    config: spring,
    from: { opacity: 0, y: 10 },
    opacity: 1,
    y: 0
  })

  const Element = animated(component ?? 'p')

  return (
    <Element
      style={{
        opacity,
        transform: y.interpolate((a) => `translateY(${a}px)`)
      }}
    >
      {message}
    </Element>
  )
}

export const ErrorWrapper = styled.div`
  background-color: ${Colors.dangerBGColor};
  border-radius: 6px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: ${Spacing.baseAndHalfPadding};
  margin: ${Spacing.basePadding2x} 0 0;
  padding: ${Spacing.basePadding2x};
`

export const ErrorPill = styled.span`
  background: ${Colors.dangerStrong};
  border-radius: 12px;
  color: ${Colors.white};
  display: inline-block;
  margin: ${Spacing.baseAndHalfPadding} ${Spacing.baseAndHalfPadding} 0 0;
  padding: ${Spacing.halfBasePadding} ${Spacing.baseAndHalfPadding};
`
