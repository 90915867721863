import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { TeamHotLinkScene } from 'src/applications/Oversight/scenes/TeamHotLinkScene'
import { TestHotLinkScene } from 'src/applications/Oversight/scenes/TestHotLinkScene'

export const HotLinkRoutes = ({ match: { path } }: RouteComponentProps) => (
  <Switch>
    <Route component={TeamHotLinkScene} path={`${path}/a`} />
    <Route component={TestHotLinkScene} path={`${path}/t`} />
    <Redirect to='/' />
  </Switch>
)
