import { PAGE_QUERY_PARAM } from 'src/config'
import {
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  PaginationWrapper
} from 'src/resources/elements/Pagination'
import { updateQueryString } from 'src/resources/utils/queryString'
import { IPartialPagination } from 'src/types/interfaces/IPagination'
import useReactRouter from 'use-react-router'

export const getPaginationItems = (pagination: IPartialPagination): (string | number)[] => {
  /* eslint-disable max-len */
  // rule 1)
  //    currentPage === 3 then []
  //    currentPage === 4 then [1]
  //    currentPage === 5 then [1, 2]
  //    currentPage >= 6 then [1, 2, 3]
  // rule 2) if currentPage > 6, display "..."
  // rule 3) always displaying current page and two to each side
  // rule 4) if there's a gap, display "..."
  // rule 5)
  //    assuming pageCount = 99
  //    currentPage > 96 then []                [99, 98, 97].slice(0, 0)   Math.max(0, 99 - 97 - 2) = 0
  //                                                                       Math.max(0, 99 - 98 - 2) = 0
  //                                                                       Math.max(0, 99 - 99 - 2) = 0
  //    currentPage === 96 then [99]            [99, 98, 97].slice(0, 1)   Math.max(0, 99 - 96 - 2) = 1
  //    currentPage === 95 then [98, 99]        [99, 98, 97].slice(0, 2)   Math.max(0, 99 - 95 - 2) = 2
  //    currentPage <== 94 > then [97, 98, 99]  [99, 98, 97].slice(0, 3+)  Math.max(0, 99 - 94 - 2) = 3+
  //                                                                       Math.max(0, 99 - 93 - 2) = 3+
  /* eslint-enable max-len */
  const { currentPage, pageCount } = pagination
  const SPACER = ['...']
  const FIRST_PAGES = [1, 2, 3]
  const MIDDLE_PAGES = [-2, -1, 0, 1, 2].map((x) => currentPage + x)
  const LAST_PAGES = [0, -1, -2].map((x) => pageCount + x)

  // how many of the FIRST_PAGES to show
  const firstPagesCount = Math.max(0, currentPage - 3)
  // how many of the LAST_PAGES to show
  const lastPagesCount = Math.max(0, pageCount - currentPage - 2)

  const FIRST_SPACER = currentPage === 7 ? [4] : currentPage > 6 ? SPACER : []
  const LAST_SPACER =
    currentPage === pageCount - 6 ? [pageCount - 3] : currentPage < pageCount - 5 ? SPACER : []

  const isValidPage = (page: number) => page >= 1 && page <= pageCount

  return [
    ...FIRST_PAGES.slice(0, firstPagesCount),
    ...FIRST_SPACER,
    ...MIDDLE_PAGES.filter(isValidPage),
    ...LAST_SPACER,
    ...LAST_PAGES.slice(0, lastPagesCount).reverse() // 99, 98, 97 --> 97, 98, 99
  ]
}

const renderPaginationItems = (pagination: IPartialPagination, qs: string) => {
  return getPaginationItems(pagination).map((page, index) => (
    <PaginationItem
      data-testid='pagination-page'
      key={index}
      to={updateQueryString(qs, { [PAGE_QUERY_PARAM]: page })}
      selected={pagination.currentPage === page}
      disabled={typeof page === 'string'}
    >
      {page}
    </PaginationItem>
  ))
}

const renderNextPageControl = (pagination: IPartialPagination, qs: string) => {
  const { currentPage, pageCount } = pagination
  const isNextPageUnavailable = currentPage >= pageCount
  const nextPageValue = currentPage + 1

  return (
    <PaginationItem
      data-testid='pagination-next-page'
      to={updateQueryString(qs, { [PAGE_QUERY_PARAM]: nextPageValue })}
      disabled={isNextPageUnavailable}
      hidden={isNextPageUnavailable}
    >
      <PaginationNext />
    </PaginationItem>
  )
}

const renderPrevPageControl = (pagination: IPartialPagination, qs: string) => {
  const { currentPage } = pagination
  const isPrevPageUnavailable = currentPage === 1
  const prevPageValue = currentPage - 1

  return (
    <PaginationItem
      data-testid='pagination-prev-page'
      to={updateQueryString(qs, { [PAGE_QUERY_PARAM]: prevPageValue })}
      disabled={isPrevPageUnavailable}
      hidden={isPrevPageUnavailable}
    >
      <PaginationPrevious />
    </PaginationItem>
  )
}

export const ListPagination = ({ pagination }: { pagination: IPartialPagination }) => {
  const { location } = useReactRouter()
  if (pagination.pageCount <= 1) {
    return null
  }
  return (
    <PaginationWrapper data-testid='pagination-wrapper'>
      {renderPrevPageControl(pagination, location.search)}
      {renderPaginationItems(pagination, location.search)}
      {renderNextPageControl(pagination, location.search)}
    </PaginationWrapper>
  )
}
