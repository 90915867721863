import { gql } from '@apollo/client'
import {
  SmartGetUpload,
  SmartGetUpload_getUpload,
  SmartGetUploadVariables
} from 'src/smart/queries/types/SmartGetUpload'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_UPLOAD: SmartQuery<
  SmartGetUploadVariables,
  SmartGetUpload,
  SmartGetUpload_getUpload
> = {
  query: gql`
    query SmartGetUpload($uploadId: UUID!) {
      getUpload(uploadId: $uploadId) {
        selectedDataSourceIndex
        batchId
        id
        fileName
        nextAction {
          derivedViewId
          name
          originalViewId
          schemaId
          processingStep
          message
          suggestCSVConversion
        }
        status
        percentComplete
        uploadDataSources {
          index
          label
        }
        createdAt
        updatedAt
      }
    }
  `,
  extract({ getUpload }) {
    return getUpload
  }
}
