import { useMutation } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import { GeographicRegionSelect } from 'src/applications/Oversight/controls/GeographicRegionSelect'
import { GoBackLink } from 'src/applications/Oversight/controls/GoBackLink'
import { PreAuthTemplate } from 'src/applications/Oversight/templates/PreAuthTemplate'
import { REQUEST_PASSWORD_RESET } from 'src/queries/REQUEST_PASSWORD_RESET'
import {
  RequestPasswordReset,
  RequestPasswordResetVariables
} from 'src/queries/types/RequestPasswordReset'
import { Colors } from 'src/resources/colors'
import { AuthSheet } from 'src/resources/elements/AuthSheet'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled from 'styled-components'

const ForgotContentContainer = styled.section`
  max-width: 400px;
  width: 100%;
  padding: ${Spacing.basePadding4x};
  background: ${Colors.white};
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin: 0 auto ${Spacing.basePadding3x};
`

const ForgotContentHeader = styled.div`
  margin-bottom: ${Spacing.basePadding4x} !important;

  p {
    color: ${Colors.text};
    font-size: ${fontSizes.type14};
  }
`

const TitleGroup = styled.div`
  display: flex;
  margin: 0 0 ${Spacing.basePadding2x} 0;

  h2 {
    color: ${Colors.textAccent};
    font-family: ${fontFamily.fontPrimary};
    line-height: ${Spacing.basePadding5x};
    margin: 0 0 0 ${Spacing.basePadding2x};
  }
`

export const ForgotPasswordScene = () => (
  <PreAuthTemplate>
    <ForgotPasswordPanel />
  </PreAuthTemplate>
)

const ForgotPasswordPanel = () => {
  const { addToast } = useToasts()

  const ForgotPasswordForm: TForm<{ email: string }> = Form

  const [requestPasswordReset] = useMutation<RequestPasswordReset, RequestPasswordResetVariables>(
    REQUEST_PASSWORD_RESET
  )

  return (
    <AuthSheet>
      <ForgotContentContainer>
        <ForgotContentHeader>
          <TitleGroup>
            <GoBackLink to='/login' label='' />
            <h2>Forgot password</h2>
          </TitleGroup>
          <p>We’ll send you an email with a link to reset your password</p>
        </ForgotContentHeader>
        <ForgotPasswordForm
          onSubmit={async (event) => {
            const { email = '' } = event.data

            await requestPasswordReset({
              variables: {
                email
              }
            })

            event.formContext.setValue({
              data: { email: '' }
            })

            addToast(
              `If the email address ${email} is a valid account, we'll send an email now with a link to reset your password`,
              {
                appearance: 'success',
                autoDismiss: true
              }
            )
          }}
        >
          <GeographicRegionSelect path='/forgot-password' />
          <Input label='Email address' name='email' fullWidth placeholder='' />
          <ButtonGroup>
            <FormButton primary submit aria-label='Send reset password email'>
              Reset password
            </FormButton>
          </ButtonGroup>
        </ForgotPasswordForm>
      </ForgotContentContainer>
    </AuthSheet>
  )
}
