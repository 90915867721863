import { Span } from '@sentry/types'
import { useHistory } from 'react-router'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { MatchingScene as MatchingV3 } from 'src/applications/Oversight/scenes/MatchingV3'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { isLoading } from 'src/resources/utils/isLoading'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_UPLOAD } from 'src/smart/queries/SQ_GET_UPLOAD'
import { SQ_GET_WORKBOOK_SHEETS } from 'src/smart/queries/SQ_GET_WORKBOOK_SHEETS'
import { SQ_VIEW_SCHEMA } from 'src/smart/queries/SQ_VIEW_SCHEMA'

// Sentry performance tracking
const matchingSpans: Partial<Span>[] = [
  {
    op: 'match-scene-loaded'
  },
  {
    op: 'match-scene-data-source-fields-loaded'
  },
  {
    op: 'match-scene-onsubmit'
  },
  {
    op: 'match-scene-success'
  }
]

export const WorkspaceUploadMatchingScene = () => {
  const {
    uploadId,
    viewId: stringViewId,
    workspaceId
  } = useParams<{
    uploadId: string
    viewId?: string
    workspaceId: string
  }>()
  const history = useHistory()
  const teamRoot = useTeamRootUrl()
  const viewIdInteger = parseInt(stringViewId, 10)
  const viewId = Number.isFinite(viewIdInteger) ? viewIdInteger : undefined
  const sentryTransaction = useSentryTransaction({
    spans: matchingSpans
  })
  const upload = useSmartQuery(SQ_GET_UPLOAD, {
    variables: {
      uploadId
    }
  })
  const schema = useSmartQuery(SQ_VIEW_SCHEMA, {
    skip: typeof viewId !== 'number',
    variables: { viewId }
  })

  const workspaceSheets = useSmartQuery(SQ_GET_WORKBOOK_SHEETS, {
    variables: { workspaceId },
    fetchPolicy: 'network-only'
  })

  const schemaId = schema.result?.id

  const templateSheet = workspaceSheets?.result?.find((sheet) => sheet.schema?.id === schemaId)

  submitSentrySpan(sentryTransaction, 'match-scene-loaded')

  if (isLoading(schema, upload, workspaceSheets)) {
    return QueryAlert({ loading: true })
  }

  if (!templateSheet) {
    return QueryAlert({
      error: 'Unable to locate selected Template in Workspace, please try again'
    })
  }

  return (
    <MatchingV3
      batchId={upload.state?.data?.getUpload?.batchId}
      sheetId={templateSheet.id}
      workspaceId={workspaceId}
      onWorkbookComplete={(workbookId) => {
        history.push(
          `${teamRoot}/workspaces/${workspaceId}/workbooks/${workbookId}/sheets/${templateSheet.id}`
        )
      }}
      embeds={false}
    />
  )
}
