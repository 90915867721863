import { MutationTuple, useMutation } from '@apollo/client'
import { INACTIVATE_ACCESS_KEY } from 'src/queries/INACTIVATE_ACCESS_KEY'
import {
  InactivateAccessKey,
  InactivateAccessKeyVariables
} from 'src/queries/types/InactivateAccessKey'

export const useInactivateAccessKeyMutation = (): MutationTuple<
  InactivateAccessKey,
  InactivateAccessKeyVariables
> => {
  const [inactivateAccessKey, inactivateAccessKeyStatus] = useMutation<
    InactivateAccessKey,
    InactivateAccessKeyVariables
  >(INACTIVATE_ACCESS_KEY)

  return [inactivateAccessKey, inactivateAccessKeyStatus]
}
