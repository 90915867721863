import { useModal } from 'src/resources/elements/Modal'
import { useParams } from 'src/resources/hooks/useParams'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { useToasts } from 'react-toast-notifications'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_UPDATE_WORKSPACE_STATUS } from 'src/smart/mutations/SM_UPDATE_WORKSPACE_STATUS'
import { useCallback } from 'react'

export const useConfirmWorkspaceUseModal = ({ refetch }: { refetch: () => Promise<void> }) => {
  const createWorkspaceUseModal = useModal({
    width: '515px',
    header: 'Use credit?',
    contents: () => {
      return <SubHeaderContainer />
    }
  })
  const { workspaceId } = useParams<{
    workspaceId: string
  }>()
  const { addToast } = useToasts()
  const updateWorkspaceStatus = useSmartMutation(SM_UPDATE_WORKSPACE_STATUS)
  const updateStatus = useCallback(async () => {
    await updateWorkspaceStatus.run({ workspaceId })
    await refetch()
    addToast('Your workspace has been successfully activated.', {
      appearance: 'success',
      autoDismiss: true
    })
  }, [refetch, updateWorkspaceStatus, addToast, createWorkspaceUseModal])
  const SubHeaderContainer = () => {
    return (
      <div>
        <p>
          Reactivating this workspace will resume consuming credits. Ready to start importing data?
        </p>
        <ButtonGroup right>
          <FormButton secondary onClick={createWorkspaceUseModal.close}>
            Cancel
          </FormButton>
          <FormButton primary onClick={updateStatus}>
            Continue
          </FormButton>
        </ButtonGroup>
      </div>
    )
  }

  return {
    open: createWorkspaceUseModal.open,
    render: createWorkspaceUseModal.render
  }
}
