import ReactTooltip from 'react-tooltip'
import { Colors } from 'src/resources/colors'
import TrashIcon from 'src/resources/icons/trash'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

export const TrashCircle = styled.div<{
  color?: string
  borderColor?: string
  onClick?(): void
}>`
  box-sizing: content-box;
  background-color: ${({ color }) => (color ? color : Colors.gray40)};
  border: 2px solid ${({ borderColor }) => (borderColor ? borderColor : Colors.transparent)};
  align-items: center;
  border-radius: 50%;
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type12};
  font-weight: 600;
  height: 40px;
  width: 40px;
  line-height: normal;

  ${({ onClick }) =>
    onClick
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : null}
`

export const TrashCircleButton = ({
  color,
  name,
  showTooltip,
  borderColor,
  onClick
}: {
  color?: string
  name: string
  showTooltip?: boolean
  borderColor?: string
  onClick: () => void
}) => {
  return (
    <TrashCircle
      color={color}
      data-testid='trash-circle'
      data-for={name}
      data-tip={name}
      borderColor={borderColor}
      onClick={onClick}
    >
      <TrashIcon width={16} height={16} />
      {showTooltip && <ReactTooltip id={name} place='top' type='dark' effect='float' />}
    </TrashCircle>
  )
}
