import { Colors } from 'src/resources/colors'
import { FlatInput } from 'src/resources/elements/form/Input'
import { Shadows } from 'src/resources/shadows'
import { fontFamily } from 'src/resources/typography'
import { IConfigValue, IConfigValuesManager } from 'src/utils/localStorageOverride'
import styled, { css } from 'styled-components'

import { DispatchChangeOverride } from './DeveloperMenuCommon'

interface IConfigMenuProps {
  config: IConfigValuesManager
  onChangeIsOverride: DispatchChangeOverride
}

interface IConfigValueProps {
  configValue: IConfigValue
  isNested: boolean
  onChangeIsOverride: DispatchChangeOverride

  onChangeValue(value: string): void
}
const ConfigValueLine = styled.div<{ disabled?: boolean; isNested?: boolean }>`
  margin-left: ${({ isNested }) => (isNested ? '24px' : '0')};
  padding: 5px 10px 5px 25px;
  position: relative;
  transition: 0.2s filter ease, 0.2s background-color ease;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.25);
          filter: blur(0.25px);

          & > * {
            pointer-events: none;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
            filter: blur(0);
          }
        `
      : null}

  ${({ isNested }) =>
    isNested
      ? css`
          border-left: 3px solid ${Colors.brandPrimary};
        `
      : null}

  label {
    color: ${Colors.brandPrimaryHighlight};
    display: block;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 5px;
  }
`

const ConfigValueEdit = styled.div`
  position: relative;
`

const ConfigValueIsOverrideMark = styled.span<{ checked: boolean }>`
  background: ${Colors.blackLight};
  border-radius: 2px;
  cursor: pointer;
  height: 12px;
  left: 10px;
  position: absolute;
  top: 10px;
  transform: scale(0.75);
  transition: transform 0.2s ease, box-shadow 0.5s ease;
  width: 12px;

  ${({ checked }) =>
    checked
      ? css`
          background: ${Colors.brandPrimary};
          box-shadow: ${Shadows.highlightBright};
          transform: scale(1);
        `
      : undefined}
`

const ConfigValueInput = styled(FlatInput)<{ disabled?: boolean }>`
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 13px;
  line-height: 15px;
  margin-top: 0;
  max-width: initial;
  min-height: 32px;
  padding: 0 12px 0 27px;
  width: 100%;

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.5);
  }
`

const StyledOption = styled(ConfigValueLine)<{ active: boolean }>`
  cursor: pointer;
  font-family: ${fontFamily.fontMonospace};
  font-size: 85%;
  padding-left: 42px;
  transition: 0.2s background-color ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:before {
    border-radius: 100%;
    border: 1px solid white;
    content: '';
    display: block;
    height: 6px;
    left: 27px;
    position: absolute;
    top: 8px;
    transition: 0.2s background-color ease;
    width: 6px;
  }

  ${({ active }) =>
    active
      ? css`
          &:before {
            background-color: white;
          }
        `
      : undefined}
`

const Option = ({
  active,
  configValue,
  onSelect,
  value
}: {
  active: boolean
  configValue: IConfigValue
  value: string
  onSelect(): void
}) => (
  <StyledOption active={active} isNested={configValue.nestUnder.length > 0} onClick={onSelect}>
    {value}
  </StyledOption>
)

const ConfigValue = ({
  configValue,
  isNested,
  onChangeIsOverride,
  onChangeValue
}: IConfigValueProps) => (
  <ConfigValueLine
    disabled={!configValue.isOverride}
    isNested={isNested}
    onClick={() => {
      if (!configValue.isOverride) {
        onChangeIsOverride(configValue, true)
      }
    }}
  >
    <label>{configValue.name}</label>
    <ConfigValueEdit>
      <ConfigValueInput
        disabled={!configValue.isOverride}
        onChange={(e) => onChangeValue(e.target.value)}
        value={configValue.isOverride ? configValue.value : configValue.defaultValue}
      />
      <ConfigValueIsOverrideMark
        checked={configValue.isOverride}
        onClick={() => {
          onChangeIsOverride(configValue, !configValue.isOverride)
        }}
      />
    </ConfigValueEdit>
    {configValue.options?.map((option, index) => (
      <Option
        active={configValue.value === option}
        configValue={configValue}
        key={index}
        value={option}
        onSelect={() => {
          onChangeValue(option)
        }}
      />
    ))}
  </ConfigValueLine>
)

export const DeveloperMenuConfig = ({ config, onChangeIsOverride }: IConfigMenuProps) => (
  <>
    {config.values.map((configValue) => (
      <ConfigValue
        configValue={configValue}
        isNested={configValue.nestUnder.length > 0}
        key={configValue.name}
        onChangeIsOverride={onChangeIsOverride}
        onChangeValue={(value) => config.set(configValue, value)}
      />
    ))}
  </>
)
