import { gql } from '@apollo/client'

export const CONFIRM_UPLOAD_DATA_SOURCE_FIELDS = gql`
  mutation ConfirmUploadDataSourceFields($viewId: Int!, $customDataSourceFields: [JSON!]) {
    confirmUploadDataSourceFields(
      viewId: $viewId
      customDataSourceFields: $customDataSourceFields
    ) {
      success
      message
    }
  }
`
