import { gql } from '@apollo/client'

export const INITIALIZE_FILE = gql`
  mutation InitializeFile(
    $fileType: String!
    $fileSize: Int!
    $fileName: String!
    $licenseKey: String!
  ) {
    initializeFile(
      fileType: $fileType
      fileSize: $fileSize
      fileName: $fileName
      licenseKey: $licenseKey
    ) {
      signedUrl
      file {
        id
      }
    }
  }
`
