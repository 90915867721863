import { useQuery } from '@apollo/client'
import { ListPagination } from 'src/applications/Oversight/controls/ListPagination'
import { DEFAULT_PAGE_SIZE } from 'src/config'
import { GET_ACTIVITIES } from 'src/queries/GET_ACTIVITIES'
import { GetActivities, GetActivitiesVariables } from 'src/queries/types/GetActivities'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { paginationFromQuery } from 'src/resources/utils/paginationFromQuery'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

const Activity = styled.div`
  margin: ${Spacing.basePadding2x} ${Spacing.halfBasePadding};

  > p {
    margin: 0;
  }
`

const ActivityMessage = styled.p`
  font-size: ${fontSizes.type16};
`

const renderDate = (dateString: string) => {
  const dateOptions: Record<string, 'numeric' | '2-digit'> = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  const parsed = new Date(dateString)
  return parsed.toLocaleTimeString('en-US', dateOptions)
}

export const WorkspaceActivitiesPanel = ({ workspace }: { workspace?: IWorkspace }) => {
  const { location } = useReactRouter()
  const { data, loading, error } = useQuery<GetActivities, GetActivitiesVariables>(
    GET_ACTIVITIES,
    {
      variables: {
        ...paginationFromQuery(location.search, DEFAULT_PAGE_SIZE),
        workspaceId: workspace?.id
      },
      skip: !workspace
    }
  )

  if (loading || error || !data || !workspace) {
    return QueryAlert({ error, loading: loading || !data || !workspace })
  }

  return (
    <>
      <PageHeaderContainer hasMargin header='Activities' />
      <ContentContainer>
        {data.getActivities.data.map((activity, index) => {
          const message =
            activity.message ||
            `${activity.resource}(${activity.activityInfo?.name}) is ${activity.action}`
          return (
            <Activity key={index}>
              <ActivityMessage>{message}</ActivityMessage>
              <p>at {renderDate(activity.createdAt)}</p>
            </Activity>
          )
        })}
        <ListPagination pagination={data.getActivities.pagination} />
      </ContentContainer>
    </>
  )
}
