import { ComponentType } from 'react'

import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import NotificationBadge from 'src/applications/Oversight/components/NotificationBadge'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { Colors } from 'src/resources/colors'
import { LinkIcon } from 'src/resources/elements/Icons'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes, kernGilroy } from 'src/resources/typography'
import { ESidebarItemHeadingType } from 'src/types/enums/ESidebarItemHeadingType'
import { ESidebarItemLinkType } from 'src/types/enums/ESidebarItemLinkType'
import { ESidebarItemType } from 'src/types/enums/ESidebarItemType'
import { ILinkedSchema } from 'src/types/interfaces/ISchema'
import styled, { css } from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

export const BaseLink = styled(NavLink)`
  color: ${Colors.blackLight};
  display: flex;
  text-decoration: none;
  font-family: ${fontFamily.fontPrimary};
  padding-left: ${Spacing.basePadding};
  position: relative;
  transition: opacity 0.1s linear, color 0.15s linear;
  white-space: nowrap;

  &:hover {
    opacity: 1;
    color: ${Colors.brandPrimary};
  }

  &.active {
    color: ${Colors.brandPrimary};
    opacity: 1;
  }
`
export const NavLinkWithIcon = styled(NavLink)`
  div {
    opacity: 0.8;
    margin-top: ${Spacing.basePadding2x};
    transition: opacity 0.1s ease-out;
    &:hover {
      opacity: 1;
    }
  }

  p {
    color: ${Colors.text};
    display: block;
    font-size: ${fontSizes.type15};
    font-family: ${fontFamily.fontSecondary};
    font-weight: 500;
    letter-spacing: 0;
    text-transform: lowercase;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`

export const PrimaryLink = styled(BaseLink)<{ hideMargin?: boolean }>`
  color: ${Colors.text};
  display: block;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type15};
  font-weight: 500;
  opacity: 0.8;
  padding: 0;

  ${({ hideMargin }) =>
    hideMargin
      ? css`
          margin: ${Spacing.basePadding2x} 0;
        `
      : css`
          margin: 12px 0 ${Spacing.basePadding2x} 32px;
        `};
`

export const HelpLaunch = styled.div`
  color: ${Colors.text};
  display: block;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type15};
  font-weight: 500;
  opacity: 0.8;
  padding: 0;
  margin: 12px 0 ${Spacing.basePadding2x} 32px;

  &:hover {
    cursor: pointer;
    opacity: 1;
    color: ${Colors.brandPrimary};
  }
`

export const LinkFlex = styled.div`
  display: flex;
  align-items: center;

  > div:nth-of-type(1) {
    position: relative;
    margin-left: 1rem;
    height: 10px;
    width: 10px;
  }
`

export const LinkClipped = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ExternalLink = styled.a`
  display: flex;
  margin: ${Spacing.basePadding2x} 0 ${Spacing.basePadding2x} 32px;
  opacity: 0.8;
  color: ${Colors.text};
  font-size: ${fontSizes.type15};
  font-family: ${fontFamily.fontSecondary};
  font-weight: 500;
  letter-spacing: 0.0125em;
  justify-content: space-between;

  div {
    display: inline-flex;
  }

  svg {
    display: none;
  }
  &:hover {
    svg {
      display: inline-flex;
      height: 18px;
      width: 18px;
    }
  }
`

export const SubExternalLink = styled(ExternalLink)`
  margin-left: 54px;
`

export const StyledButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${Colors.text};
  display: flex;
  font-size: ${fontSizes.type13};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 500;
  margin-bottom: ${Spacing.basePadding2x};
  margin-left: 32px;
  padding-left: 0;
  transition: all 0.125s ease-out;
  width: 100%;

  span {
    display: inline-block;
    margin-left: ${Spacing.basePadding};
  }

  &:hover {
    color: ${Colors.brandPrimary};
    cursor: pointer;
    opacity: 1;
  }
`

export const LinkGroup = styled.section<{
  hasMargin?: boolean
  deactivated?: boolean
  inset?: boolean
}>`
  h1,
  h2 {
    margin: 0;
    color: ${Colors.brandSecondaryText};
    font-weight: 600;
    letter-spacing: ${kernGilroy};
  }

  h1 {
    font-size: ${fontSizes.type14};
    padding: ${Spacing.basePadding2x} 0px;

    &:first-child {
      margin-top: ${Spacing.halfBasePadding};
    }
  }

  h2 {
    font-size: ${fontSizes.type11};
    text-transform: uppercase;
    padding: ${Spacing.basePadding} 0px;
  }

  a:hover {
    opacity: 1;
  }

  a:hover ~ span,
  a.active ~ span {
    opacity: 1;
  }

  ${({ hasMargin }) =>
    hasMargin
      ? css`
          color: white;
          margin: ${Spacing.basePadding2x};

          > div,
          a {
            opacity: 1;
          }
        `
      : null}
  ${({ deactivated }) =>
    deactivated
      ? css`
          pointer-events: none;

          a,
          a.active {
            color: ${Colors.text80};
          }
        `
      : null}
  
  ${({ inset }) =>
    inset &&
    css`
      margin-left: ${Spacing.basePadding};
    `}
`

export const EndUserLinkStyle = styled(BaseLink)`
  display: flex;
  align-items: center;
  color: ${Colors.textLogo};
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type15};
  font-weight: 500;
  opacity: 0.8;
  padding: 0;
  transition: ${Transitions.baseEase};

  & > span {
    position: relative;
    top: 2px;
  }

  svg {
    transition: ${Transitions.baseEase};
    g,
    path {
      transition: ${Transitions.baseEase};
    }
  }

  &.active,
  &:hover {
    color: ${Colors.blueSelected};

    svg path {
      fill: ${Colors.blueSelected};
    }
  }
`

export const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${Spacing.baseAndHalfPadding};
`

const IconWrapper = styled.div`
  flex-shrink: 0;
  height: ${Spacing.basePadding3x};
  margin-right: ${Spacing.basePadding};
  text-align: center;
  width: ${Spacing.basePadding3x};
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`

const LinkIconWrapper = styled(IconWrapper)`
  margin: 0 0 0 ${Spacing.basePadding};
  padding-top: 2px;
`

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 200px;
`

const LinkedSheet = ({ linkedSchemas }: { linkedSchemas: ILinkedSchema[] }) => {
  const schemaNames = linkedSchemas.map((item) => item.name).join(', ')
  return (
    <LinkIconWrapper>
      <LinkIcon data-tip={`Has link to ${schemaNames}`} data-for='LinkedItem' />
      <StyledReactTooltip id='LinkedItem' place='top' type='dark' effect='float' />
    </LinkIconWrapper>
  )
}

export const EndUserLink = ({ link }: { link: SidebarLink }) => {
  if (link.type === ESidebarItemLinkType.EndUser) {
    const pathMatchesExactly = location.pathname === link.url
    const pathMatchesRegEx = link.regex?.test(location.pathname)
    return (
      <LinkWrap>
        <EndUserLinkStyle
          // eslint-disable-next-line react/forbid-component-props
          className={pathMatchesExactly || pathMatchesRegEx ? 'active' : undefined}
          to={link.url}
          exact
        >
          <IconWrapper>
            <link.icon />
          </IconWrapper>
          <OverflowData dataTip={link.value} dataFor={link.value} maxWidth={200}>
            <span>{link.value}</span>
          </OverflowData>
          {link.hasNotification && <NotificationBadge secondaryBorder />}
        </EndUserLinkStyle>
        {!!link.linkedSchemas?.length && <LinkedSheet linkedSchemas={link.linkedSchemas} />}
      </LinkWrap>
    )
  }
}

export type SidebarLink =
  | {
      hasNotification?: boolean
      type: ESidebarItemLinkType
      value: string
      url: string
      icon?: ComponentType<any>
      newBadgeLabel?: string
      linkedSchemas?: ILinkedSchema[]
      regex?: RegExp
    }
  | {
      hasNotification?: boolean
      type: ESidebarItemType.Avatar
      value: string
      url: string
      id: string
    }
  | {
      type: ESidebarItemHeadingType
      value: string
    }
  | {
      type: ESidebarItemType.Button
      value: string
      url?: string
      className?: string
    }
  | {
      type: ESidebarItemType.HelpLaunch
      value: string
      className?: string
    }
