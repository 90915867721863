import { ReactElement, ReactNode, useEffect } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getUserContext } from 'src/contexts/UserContext'
import { useIsMounted } from 'src/resources/hooks/useIsMounted'

type TSessionMustNotExistProps = RouteComponentProps & {
  children: ReactNode
  onAuthenticatedUrl: string
}

export const SessionMustNotExist = withRouter<
  TSessionMustNotExistProps,
  (props: TSessionMustNotExistProps) => ReactElement
>(({ children, history, onAuthenticatedUrl }: TSessionMustNotExistProps) => {
  const {
    value: { user }
  } = getUserContext()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (user && isMounted.current) {
      history.push(onAuthenticatedUrl)
    }
  }, [user])

  return !user ? <>{children}</> : null
})
