import { ReactElement } from 'react'

import { UploadPanelLayer } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import closeIcon from 'src/resources/icons/close.svg'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const FloatingPanel = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: ${Spacing.basePadding2x};
  min-width: 320px;
  border-radius: 8px;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.1), 0px 41.8px 33.4px 1px rgba(0, 0, 0, 0.06),
    0px 22.3px 17.89px 0px rgba(0, 0, 0, 0.05), 0px 12.5px 10px 0px rgba(0, 0, 0, 0.04),
    0px 6.7px 5.3px 0px rgba(0, 0, 0, 0.03), 0px 2.8px 2.2px 0px rgba(0, 0, 0, 0.02);
  z-index: ${UploadPanelLayer};

  img {
    position: absolute;
    top: 0;
    right: 0;
    margin: ${Spacing.basePadding};

    &:hover {
      background-color: ${Colors.gray20};
      border-radius: 4px;
      cursor: pointer;
    }
  }
`

const FlexContent = styled.div`
  display: flex;
  position: relative;
`

export const FloatingContentPanel = ({
  children,
  onClose
}: {
  children: ReactElement
  onClose?(): void
}) => (
  <FloatingPanel>
    <FlexContent>
      {children}
      <img onClick={onClose} src={closeIcon} alt='Close panel' />
    </FlexContent>
  </FloatingPanel>
)
