import { Link } from 'react-router-dom'
import {
  EmptyAction,
  EmptyDescription,
  EmptyStateBlock,
  EmptyStateContent,
  EmptyTitle
} from 'src/applications/Oversight/components/EmptyState'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { Card } from 'src/resources/elements/Card'
import styled from 'styled-components'

const PanelContents = styled.div`
  position: relative;

  > h1 {
    margin-top: 0;
  }

  > hr {
    margin-left: -44px;
    opacity: 0.5;
  }
`

export const ImportListNoDataPanel = () => {
  const teamRoot = useTeamRootUrl()

  return (
    <Card>
      <PanelContents>
        <>
          <EmptyStateBlock>
            <EmptyStateContent>
              <EmptyTitle>We haven't received any imports yet</EmptyTitle>
              <EmptyDescription>
                <p>
                  If you believe this is an error, you can try refreshing the page or contacting us
                  for additional help troubleshooting.
                </p>
              </EmptyDescription>
              <Link to={`${teamRoot}/setup`}>
                <EmptyAction label='Go to setup' />
              </Link>
            </EmptyStateContent>
          </EmptyStateBlock>
        </>
      </PanelContents>
    </Card>
  )
}
