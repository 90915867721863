function MatchingExcludedFieldDown() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none' viewBox='0 0 10 10'>
      <path
        stroke='#587A9D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M4 6.5L6.5 9 9 6.5'
      />
      <path
        stroke='#587A9D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M1 1h3.5a2 2 0 012 2v6'
      />
    </svg>
  )
}

export default MatchingExcludedFieldDown
