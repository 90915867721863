import { Colors } from 'src/resources/colors'
import { Spacing } from 'src/resources/layout'
import styled from 'styled-components'

const VersionStyled = styled.span`
  color: ${Colors.grayText};
  padding-left: ${Spacing.basePadding};
`

export const Version = (props: { v: string }) => {
  return (
    <VersionStyled {...props}>
      ({props.v?.length > 0 ? `#${props.v}` : 'unversioned'})
    </VersionStyled>
  )
}
