import { ReactElement } from 'react'
import {
  EmptyAction,
  EmptyDescription,
  EmptyStateBlock,
  EmptyStateContent,
  EmptyStateGraphic,
  EmptyTitle
} from 'src/applications/Oversight/components/EmptyState'

interface IEmptyPanel {
  header: string
  description: string
  subDescription?: string
  image?: string
  // height, width
  imgSize?: [number, number]
  href?: string
  onClick?: () => void
  buttonLabel?: string
  children?: ReactElement
}

export const EmptyPanel = ({
  header,
  description,
  subDescription,
  image,
  imgSize = [460, 490],
  href,
  onClick,
  buttonLabel,
  children
}: IEmptyPanel) => (
  <>
    <EmptyStateBlock>
      <EmptyStateContent>
        <EmptyTitle data-testid='empty-state-title'>{header}</EmptyTitle>
        <EmptyDescription data-testid='empty-state-description'>
          <p>{description}</p>
          {subDescription && <p>{subDescription}</p>}
        </EmptyDescription>
        {buttonLabel && (
          <>
            {onClick && (
              <div data-testid='empty-action' onClick={onClick}>
                <EmptyAction label={buttonLabel} />
              </div>
            )}
            {href && (
              <a href={href} target='_blank' rel='noopener noreferrer' data-testid='is-anchor-tag'>
                <EmptyAction label={buttonLabel} />
              </a>
            )}
          </>
        )}
        {children}
      </EmptyStateContent>
      {image && (
        <EmptyStateGraphic data-testid='empty-graphic'>
          <img src={image} height={imgSize[0]} width={imgSize[1]} alt={header} />
        </EmptyStateGraphic>
      )}
    </EmptyStateBlock>
  </>
)
