import { useCallback, useEffect, useMemo, useState } from 'react'

import { SchemaMutationDetail } from 'src/applications/Oversight/components/SchemaMutationDetail'
import { ResourceSidebarItem } from 'src/resources/elements/ResourceSidebarItem'
import { UpdatedResourceBrowser } from 'src/resources/elements/UpdatedResourceBrowser'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_CREATE_SCHEMA_MUTATION } from 'src/smart/mutations/SM_CREATE_SCHEMA_MUTATION'
import { SQ_GET_SCHEMA_MUTATIONS } from 'src/smart/queries/SQ_GET_SCHEMA_MUTATIONS'
import { ISchema } from 'src/types/interfaces/ISchema'

export const SchemaMutations = ({
  schema,
  onSchemaIdUpdate
}: {
  schema: ISchema
  onSchemaIdUpdate(id: number | string, tab: string): void
}) => {
  const [selectedResourceId, setSelectedResourceId] = useState<string | number | undefined>()

  const activeMutations = useSmartQuery(SQ_GET_SCHEMA_MUTATIONS, {
    variables: {
      schemaId: schema.id,
      archived: false
    }
  })
  const archivedMutations = useSmartQuery(SQ_GET_SCHEMA_MUTATIONS, {
    variables: {
      schemaId: schema.id,
      archived: true
    }
  })
  const [allMutations, setAllMutations] = useState([])

  useEffect(() => {
    if (activeMutations?.result && archivedMutations?.result) {
      setAllMutations([...activeMutations?.result, ...archivedMutations?.result])
    }
  }, [activeMutations?.result, archivedMutations?.result])

  const selectedResource = useMemo(() => {
    const resource = selectedResourceId && allMutations.find((x) => x.id === selectedResourceId)
    if (!resource && allMutations.length) {
      setSelectedResourceId(allMutations[0]?.id)
      return allMutations[0]
    }
    return resource
  }, [selectedResourceId, allMutations])

  const createSchemaMutation = useSmartMutation(SM_CREATE_SCHEMA_MUTATION)

  const handleAdd = useCallback(async () => {
    const resource = await createSchemaMutation.run({ schemaId: schema.id })
    onSchemaIdUpdate(resource.schemaId, 'graphql')
  }, [createSchemaMutation, onSchemaIdUpdate, schema])

  const schemaIdUpdate = useCallback(
    async (newSchemaId: string | number, tab: string) => {
      if (newSchemaId.toString() !== schema.id) {
        onSchemaIdUpdate(newSchemaId, tab)
      } else {
        await activeMutations.state.refetch()
        await archivedMutations.state.refetch()
      }
    },
    [onSchemaIdUpdate, activeMutations, archivedMutations]
  )

  return (
    <UpdatedResourceBrowser
      sidebarConfig={{
        title: 'Library',
        description: 'Create GraphQL schema mutations.',
        activeCount: true
      }}
      addItem={handleAdd}
      items={allMutations?.map((mutation) => {
        const { id, mutationType, graphQLURI, enabled, archived } = mutation
        return (
          <ResourceSidebarItem
            key={id}
            id={id}
            onClick={setSelectedResourceId}
            selected={id === selectedResourceId}
            name={`TYPE ${mutationType.toUpperCase()}`}
            description={graphQLURI}
            enabled={enabled}
            status={archived ? 'Archived' : 'Active'}
          />
        )
      })}
      active={activeMutations?.result?.length}
      inactive={archivedMutations?.result?.length}
      selectedResourceId={selectedResource?.id}
      docsLink='https://support.flatfile.com/hc/en-us/articles/4408727707796'
    >
      <SchemaMutationDetail
        schema={schema}
        mutation={selectedResource}
        onSchemaIdUpdate={schemaIdUpdate}
      />
    </UpdatedResourceBrowser>
  )
}
