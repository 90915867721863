import { format } from 'date-fns'
import queryString from 'query-string'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { IFlatButtonBase } from 'src/resources/elements/buttons/IFlatButton'
import { SchemaModel } from 'src/resources/elements/form/SchemaModelInput'
import { Select } from 'src/resources/elements/form/Select'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import {
  ArrowRightUpDiagonal,
  BlockedIcon,
  ClipboardIcon,
  DangerIcon,
  DashedCircle,
  ExclamationCircleIcon,
  InfoIcon,
  RightCheckIcon,
  TableIcon
} from 'src/resources/elements/Icons'
import { PaginationItem, PaginationWrapper } from 'src/resources/elements/Pagination'
import Pill from 'src/resources/elements/Pill'
import { Spinner, SpinnerWrapper } from 'src/resources/elements/Spinner'
import { NewStyledTable } from 'src/resources/elements/Table'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { updateQueryString } from 'src/resources/utils/queryString'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_DATAHOOK_CLOUDWATCH_LOGS } from 'src/smart/queries/SQ_DATAHOOK_CLOUDWATCH_LOGS'
import { SmartGetDataHookCloudWatchLogs } from 'src/smart/queries/types/SmartGetDataHookCloudWatchLogs'
import {
  DataHookExecution,
  DataHookExecutionDataHook,
  DataHookExecutionEvent
} from 'src/types/aliases/DataHookExecutions'
import { EDataHookStatusType } from 'src/types/enums/EDataHookStatusType'
import { handleScrollWithCallback } from 'src/utils/handleScrollWithCallback'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import {
  ConsoleLine,
  ConsoleMessage,
  ConsoleWrapper,
  ErrorMessageLog,
  LineNumber
} from 'src/applications/Oversight/components/DataHookTester'
import { FormSubHeading } from 'src/applications/Oversight/components/SchemaSettingsForm'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { useTabs } from 'src/applications/Oversight/hooks/useTabs'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { TableOverflow } from './ImportListDataPanel'
import { buildPillContent, EmptyLogList, IconData } from './LogsListPanel'
import { EDataHookDeploymentState } from 'src/types/enums/EDataHookDeploymentState'
import { EDeploymentState } from 'src/types/enums/EDeploymentState'

const XLogsDetailPanel = styled.div`
  padding: calc(${Spacing.basePadding3x} - 10px) ${Spacing.basePadding3x} 0;

  h1 {
    margin-bottom: ${Spacing.baseAndHalfPadding};

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  table {
    border: 1px solid ${Colors.pigeon200};
    border-radius: ${Spacing.threeQuarterBasePadding};

    thead {
      tr {
        th {
          background: ${Colors.pigeon100};
          text-transform: initial;
          letter-spacing: unset;
          border-radius: ${Spacing.threeQuarterBasePadding};
        }
      }
    }

    tbody {
      background: transparent;
    }
  }

  ${SpinnerWrapper} {
    margin-top: ${Spacing.basePadding3x};
    display: flex;

    svg {
      width: ${Spacing.basePadding3x};
      height: ${Spacing.basePadding3x};
    }
  }

  ${ConsoleWrapper} {
    background-color: ${Colors.purpleTestResult};
    max-height: 380px;

    * {
      font-family: monospace;
      white-space: pre;
    }
  }
`

const SubHeaderItem = styled.div`
  display: flex;
  font-size: ${fontSizes.type14};
  line-height: ${fontSizes.type21};

  label {
    font-weight: 600;
    color: ${Colors.pigeon600};
    width: 167px;
  }

  span {
    color: ${Colors.textLogo};
  }

  div {
    margin: 0;
  }
`

const CheckLogsButton = styled(FlatButton)<IFlatButtonBase>`
  margin: 0 ${Spacing.baseAndHalfPadding};
  line-height: ${fontSizes.type14};
  padding: ${Spacing.halfBasePadding} ${Spacing.basePadding};

  svg {
    display: inline;
  }
`

const StyledTableRow = styled.tr`
  transition: ${Transitions.baseEaseWithProperty('background')};
  height: 40px;

  &:hover {
    background-color: ${Colors.rowHover};
  }

  td {
    padding: ${Spacing.basePadding} 0;
    justify-content: right;
  }

  td:first-of-type,
  th:first-of-type,
  td:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  td:first-child {
    width: 100%;
  }

  td:nth-child(2) {
    &,
    div {
      display: flex;
    }
  }
  ${CheckLogsButton} {
    font-size: ${fontSizes.type12};
  }

  th div label {
    color: ${Colors.textLogo};
  }
`

const DateHeader = styled.span`
  background-image: linear-gradient(to right, ${Colors.text} 25%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
`

export const logEventName = (dhExecutionEvent: DataHookExecutionEvent) => {
  let logName
  if (dhExecutionEvent.payload.fileUpload) {
    logName = `"${dhExecutionEvent.upload?.fileName}" uploaded`
  } else if (dhExecutionEvent.payload.workbookUpdated) {
    logName = `"${dhExecutionEvent.template.name}" updated`
  } else if (dhExecutionEvent.payload.dataHookTest) {
    const dataHook = dhExecutionEvent.dataHooks.find(
      (dh) => dh.id === dhExecutionEvent.payload.dataHookTest
    )
    logName = `"${dataHook?.name ?? dhExecutionEvent.template.name}" tested`
  } else {
    logName = ''
  }
  return logName
}

export const LogsDetailPanel = memo(
  ({ dhExecutionEvent }: { dhExecutionEvent: DataHookExecutionEvent }) => {
    const teamRoot = useTeamRootUrl()

    const logsTabs = useTabs({
      urlParam: 'section',
      tabs: [
        {
          id: 'summary',
          label: 'Summary',
          showBetaBadge: false,
          contents: SummaryLogs
        },
        {
          id: 'logs',
          label: 'Logs',
          showBetaBadge: false,
          contents: LogsList
        }
      ]
    })

    const hooksCount = useMemo(
      () => buildPillContent(dhExecutionEvent.dataHooksCount, 'Hook'),
      [dhExecutionEvent.dataHooksCount]
    )

    const rowsCount = useMemo(
      () =>
        buildPillContent(dhExecutionEvent.rowCount / dhExecutionEvent.dataHooksCount, 'Record'),
      [dhExecutionEvent.rowCount]
    )

    const executionDateTime = new Date(dhExecutionEvent.createdAt)
    const executionUTCFormat = executionDateTime.toUTCString()

    return (
      dhExecutionEvent && (
        <XLogsDetailPanel>
          <PageHeaderContainer
            header={logEventName(dhExecutionEvent)}
            noPadding
            pill={
              <Pill customStyle={{ background: Colors.pigeon200, color: Colors.pigeon800 }}>
                <>
                  {hooksCount}, {rowsCount}
                </>
              </Pill>
            }
            headerMaxWidth={450}
          />

          <SubHeaderItem>
            <label>Time</label>
            <DateHeader data-for='header-date' data-tip={executionUTCFormat}>
              {format(executionDateTime, 'P, pp')}
            </DateHeader>
            <Tooltip
              id='header-date'
              content={executionUTCFormat}
              place='right'
              offset={{ top: 0, left: 0 }}
            />
          </SubHeaderItem>

          <SubHeaderItem>
            <label>Source</label>
            {dhExecutionEvent.workspace ? (
              <Link to={`${teamRoot}/workspaces/${dhExecutionEvent.workspace.id}`}>
                {dhExecutionEvent.workspace.name}
              </Link>
            ) : (
              <>Test</>
            )}
          </SubHeaderItem>
          <SubHeaderItem>
            <label>Template</label>
            <IconData Icon={() => <TableIcon fill={Colors.sky400} />} fontSize={fontSizes.type14}>
              <Link to={`${teamRoot}/templates/${dhExecutionEvent.template.id}`}>
                {dhExecutionEvent.template.name}
              </Link>
            </IconData>
          </SubHeaderItem>

          {logsTabs.render({ dhExecutionEvent })}
        </XLogsDetailPanel>
      )
    )
  }
)

const FieldInput = memo(
  ({
    dataHook,
    executions
  }: {
    dataHook: DataHookExecutionDataHook
    executions: DataHookExecution[]
  }) => {
    const teamRoot = useTeamRootUrl()
    const { history } = useReactRouter()
    const executionId = useSearchParam.string('executionId', null)
    const page = useSearchParam.string('page', '1')

    const anyFailures = executions.filter(
      (execution) => execution.status === EDataHookStatusType.FAILURE
    )
    const wasDeployed = useMemo(
      () => dataHook.deploymentState === EDataHookDeploymentState.DEPLOY_SUCCESS,
      [dataHook.deploymentState]
    )
    const StatusIcon = !wasDeployed
      ? DashedCircle
      : anyFailures.length > 0
      ? BlockedIcon
      : RightCheckIcon

    const setQueryParams = useCallback(
      (params: { [key: string]: string }) => {
        history.push({
          pathname: history.location.pathname,
          search: queryString.stringify({
            page,
            ...params
          })
        })
      },
      [executionId, page]
    )

    const initialLog = executions[0]
    const totalError = executions.reduce((acc, obj) => acc + obj.errorCount, 0)
    const totalInfo = executions.reduce((acc, obj) => acc + obj.infoCount, 0)
    const totalWarning = executions.reduce((acc, obj) => acc + obj.warningCount, 0)

    const handleLinkClick = useCallback(() => {
      if (!!initialLog) {
        setQueryParams({
          section: 'logs',
          eventId: initialLog.id,
          requestId: initialLog.requestId,
          dataHookId: initialLog.dataHookId
        })
      }
    }, [initialLog?.id, initialLog?.requestId, initialLog?.dataHookId])

    return (
      <StyledTableRow>
        <td>
          <IconData fontSize={fontSizes.type13} Icon={StatusIcon}>
            <a onClick={handleLinkClick}>Logs for {dataHook.name}</a>
          </IconData>
        </td>
        <td>
          {wasDeployed ? (
            <>
              <IconData
                Icon={DangerIcon}
                iconColor={!totalError ? Colors.pigeon600 : Colors.brandAccent}
              >
                {totalError}
              </IconData>
              <IconData Icon={InfoIcon}>{totalInfo}</IconData>
              <IconData Icon={ExclamationCircleIcon}>{totalWarning}</IconData>
            </>
          ) : (
            <Pill customStyle={{ background: Colors.pigeon100, color: Colors.pigeon800 }}>
              Not deployed
            </Pill>
          )}

          <CheckLogsButton
            color='accent'
            variant='outlined'
            renderAs='link'
            to={`${teamRoot}/templates/${dataHook.schemaId}?tab=data-hooks&dataHookId=${dataHook.id}`}
          >
            Config <ArrowRightUpDiagonal />
          </CheckLogsButton>
        </td>
      </StyledTableRow>
    )
  }
)

export const SummaryLogs = memo(
  ({ dhExecutionEvent }: { dhExecutionEvent: DataHookExecutionEvent }) => {
    const dataHooks = dhExecutionEvent.payload.dataHookTest
      ? dhExecutionEvent.dataHooks.filter((dh) => dh.id === dhExecutionEvent.payload.dataHookTest)
      : dhExecutionEvent.dataHooks

    const getDHCountByStatusType = useCallback(
      (statusType: EDataHookStatusType) =>
        dataHooks
          .map((dataHook) =>
            dhExecutionEvent.executions.some(
              (execution) =>
                execution.dataHookId === dataHook.id && execution.status === statusType
            )
          )
          .reduce((acc, containsErrors) => (containsErrors ? acc + 1 : acc), 0),
      [dataHooks, dhExecutionEvent.executions]
    )

    const notDeployedCount = useMemo(
      () =>
        dataHooks.filter(
          ({ deploymentState }) => deploymentState !== EDeploymentState.DEPLOY_SUCCESS
        ).length,
      [dataHooks]
    )
    const errorCount = useMemo(() => getDHCountByStatusType(EDataHookStatusType.FAILURE), [])
    const successCount = useMemo(() => getDHCountByStatusType(EDataHookStatusType.SUCCESS), [])

    const summaryTitle =
      errorCount === dataHooks.length
        ? 'All hooks failed to run'
        : errorCount
        ? 'Not all hooks ran successfully'
        : 'All hooks ran successfully'

    const summarySubTitle = [
      ...(successCount ? [`${successCount} sucessful`] : []),
      ...(errorCount ? [`${errorCount} failed`] : []),
      ...(notDeployedCount ? [`${notDeployedCount} not deployed`] : [])
    ].join(', ')

    return (
      <TableOverflow fullHeight>
        <NewStyledTable>
          <thead>
            <StyledTableRow>
              <th colSpan={2}>
                <IconData Icon={ClipboardIcon} fontSize={fontSizes.type13}>
                  <>
                    <label>{summaryTitle}</label>
                    <span>{summarySubTitle}</span>
                  </>
                </IconData>
              </th>
            </StyledTableRow>
          </thead>

          <SchemaModel>
            {dataHooks.map((item, idx) => {
              const executionsFilteredByDataHook = dhExecutionEvent.executions.filter(
                (execution) => execution.dataHookId === item.id
              )
              return (
                <FieldInput
                  dataHook={item}
                  executions={executionsFilteredByDataHook}
                  key={item.id + idx}
                />
              )
            })}
          </SchemaModel>
        </NewStyledTable>
      </TableOverflow>
    )
  }
)

const NewPaginationWrapper = styled(PaginationWrapper)`
  margin-top: ${Spacing.basePadding2x};
  flex-wrap: wrap;

  ${PaginationItem} {
    min-width: 32px;
  }
`

const LogsPagination = ({
  currentPage,
  totalPages
}: {
  currentPage: number
  totalPages: number
}) => {
  const { location } = useReactRouter()
  return (
    <NewPaginationWrapper>
      {[...Array(totalPages)].map((_, idx) => (
        <PaginationItem
          key={idx}
          to={updateQueryString(location.search, { ['logPage']: idx })}
          selected={currentPage === idx}
        >
          {idx}
        </PaginationItem>
      ))}
    </NewPaginationWrapper>
  )
}

const LogsList = memo(({ dhExecutionEvent }: { dhExecutionEvent: DataHookExecutionEvent }) => {
  const { history } = useReactRouter()
  const eventId = useSearchParam.string('eventId', null)
  const dataHookId = useSearchParam.string('dataHookId', dhExecutionEvent.dataHooks[0]?.id)
  const page = useSearchParam.string('page', '1')
  const logPage = useSearchParam.string('logPage', '0')
  const setQueryParams = useCallback(
    (params: { [key: string]: string }) => {
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify({
          eventId,
          page,
          logPage,
          dataHookId,
          ...params
        })
      })
    },
    [eventId, page]
  )

  const dataHooks = dhExecutionEvent.payload.dataHookTest
    ? dhExecutionEvent.dataHooks.filter((dh) => dh.id === dhExecutionEvent.payload.dataHookTest)
    : dhExecutionEvent.dataHooks

  const executionsFilteredByDataHook = dhExecutionEvent.executions.filter(
    (execution) => execution.dataHookId === dataHookId
  )

  const initialDataHook = dataHooks.find((dataHook) => dataHook.id === dataHookId)
  const [selectedDataHook, setSelectedDataHook] = useState<DataHookExecutionDataHook>(
    initialDataHook ?? dataHooks[0]
  )

  const ExecutionEventLogOptions = dataHooks.map((dataHook) => {
    return {
      label: dataHook.name,
      value: dataHook.id
    }
  })

  const SetNewDataHook = (newDataHookId: string) => {
    const newDataHook = dataHooks.find((execution) => execution.id === newDataHookId)
    if (newDataHook) {
      setSelectedDataHook(newDataHook)
      setQueryParams({
        section: 'logs',
        eventId,
        logPage,
        dataHookId: newDataHook.id
      })
    }
  }

  const selectedExecution = executionsFilteredByDataHook[parseInt(logPage)]

  return (
    <>
      <div>
        <FormSubHeading>Data Hook:</FormSubHeading>
        <Select
          defaultValue={ExecutionEventLogOptions[0]}
          selectedValue={selectedDataHook?.id}
          onChange={(option) => SetNewDataHook(option.value)}
          options={ExecutionEventLogOptions}
          placeholder='All Data Hooks'
        />
      </div>

      {selectedExecution && (
        <SelectedExecutionLogs
          key={selectedDataHook?.id + '_' + logPage}
          execution={selectedExecution}
        />
      )}
      {executionsFilteredByDataHook.length > 0 && (
        <LogsPagination
          currentPage={parseInt(logPage)}
          totalPages={Math.ceil(executionsFilteredByDataHook.length)}
        />
      )}
    </>
  )
})

const SelectedExecutionLogs = memo(({ execution }: { execution: DataHookExecution }) => {
  if (execution.logGroupName && execution.logStreamName) {
    return <LogData execution={execution} />
  } else if (execution.functionError && execution.errorType && execution.errorMessage) {
    const messages = [execution.functionError, execution.errorType, execution.errorMessage]
    return <ErrorMessageLog messages={messages} />
  }
  return <>No Logs to show :(</>
})

const LogData = memo(({ execution }: { execution: DataHookExecution }) => {
  const [nextToken, setNextToken] = useState<string>()
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [logs, setLogs] = useState<{ level: string; message: string }[]>([])
  const { result: dhlogs, state } = useSmartQuery(SQ_DATAHOOK_CLOUDWATCH_LOGS, {
    fetchPolicy: 'network-only',
    variables: {
      logGroupName: execution.logGroupName,
      logStreamName: execution.logStreamName,
      dataHookId: execution.dataHookId
    }
  })

  const loadMoreRef = useRef(null)
  const consoleWrapperRef = useRef(null)

  useEffect(() => {
    if (dhlogs) {
      const { logs: initialLogs, nextForwardToken } = dhlogs
      setLogs(initialLogs)
      setNextToken(nextForwardToken)
    }
  }, [dhlogs])

  const handleLoadMore = async () => {
    setLoadingMore(true)
    const { data: moreLogs } = await state.fetchMore({
      variables: {
        logGroupName: execution.logGroupName,
        logStreamName: execution.logStreamName,
        dataHookId: execution.dataHookId,
        nextToken
      },
      updateQuery: (existing: SmartGetDataHookCloudWatchLogs, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return existing
        }

        return Object.assign({}, existing, {
          getDataHookCloudwatchLogs: {
            logs: [
              ...existing.getDataHookCloudWatchLogs.logs,
              ...fetchMoreResult.getDataHookCloudWatchLogs.logs
            ]
          }
        })
      }
    })
    setLogs([...logs, ...moreLogs.getDataHookCloudWatchLogs.logs])
    setNextToken(moreLogs.getDataHookCloudWatchLogs.nextForwardToken)
    setLoadingMore(false)
  }

  if (state.loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  if (!logs?.length) {
    return <EmptyLogList />
  }

  return (
    <ConsoleWrapper
      onScroll={() =>
        handleScrollWithCallback(loadMoreRef, consoleWrapperRef, 150, loadingMore, handleLoadMore)
      }
      ref={consoleWrapperRef}
    >
      {logs.map((line: { level: string; message: string }, index: number) => {
        if (line.message.startsWith('REPORT')) {
          return <p key={index}>{line.message}</p>
        }
        const metaMessage = line.message.startsWith('START') || line.message.startsWith('END')
        if (metaMessage) {
          return <p key={index}>{line.message.split(' ')[0]} LOGS</p>
        }
        try {
          const parsedLog = JSON.parse(line.message)
          return (
            <ConsoleLine key={index}>
              <LineNumber type={parsedLog.level}>[{parsedLog.level}]</LineNumber>
              <ConsoleMessage>
                {typeof parsedLog.message === 'object'
                  ? JSON.stringify(parsedLog.message)
                  : parsedLog.message}
              </ConsoleMessage>
            </ConsoleLine>
          )
        } catch (e) {}
      })}
      {nextToken && (
        <div ref={loadMoreRef}>
          {loadingMore && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </div>
      )}
    </ConsoleWrapper>
  )
})
