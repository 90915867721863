import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'

interface ICreateOrganizationFormData {
  name: string
}

const CreateOrganizationFormElement: TForm<ICreateOrganizationFormData> = Form

export const CreateOrganizationForm = ({
  onSubmit,
  onCancel
}: {
  onSubmit: (event: IFormSubmitEvent<ICreateOrganizationFormData>) => Promise<void>
  onCancel: () => void
}) => (
  <CreateOrganizationFormElement onSubmit={onSubmit}>
    <Input
      label='Organization name'
      placeholder='Coconut Labs'
      helperText='Enter a name for the group you are collaborating with.'
      name='name'
    />
    <ErrorMessage name='name' />
    <ButtonGroup right>
      <FormButton secondary onClick={onCancel}>
        Cancel
      </FormButton>
      <FormButton primary submit>
        Create
      </FormButton>
    </ButtonGroup>
  </CreateOrganizationFormElement>
)
