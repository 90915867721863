import { gql } from '@apollo/client'

export const GET_USERS_FOR_WORKSPACE = gql`
  query GetUsersForWorkspace($workspaceId: UUID!) {
    getDefaultOrganizationForWorkspace(workspaceId: $workspaceId) {
      name
    }
    getUsersForWorkspace(workspaceId: $workspaceId) {
      id
      name
      type
      email
      createdAt
    }
  }
`
