import { gql } from '@apollo/client'
import {
  CreateOrganization,
  CreateOrganizationVariables
} from 'src/smart/mutations/types/CreateOrganization'
import { SmartMutation } from 'src/smart/SmartMutation'
import { IOrganization } from 'src/types/interfaces/IOrganization'

export const SM_CREATE_ORGANIZATION: SmartMutation<
  CreateOrganizationVariables,
  CreateOrganization,
  IOrganization
> = {
  mutation: gql`
    mutation CreateOrganization($name: String!, $teamId: ID!, $environmentId: UUID) {
      createOrganization(teamId: $teamId, name: $name, environmentId: $environmentId) {
        id
        name
      }
    }
  `,
  extract: (response) => response.createOrganization
}
