import { gql } from '@apollo/client'

export const REMOVE_BATCH = gql`
  mutation RemoveBatch($batchId: UUID!) {
    removeBatch(batchId: $batchId) {
      countColumns
      countColumnsMatched
      countRows
      countRowsAccepted
      countRowsInvalid
      createdAt
      failedAt
      failureReason
      filename
      handledAt
      headerHash
      headersMatched {
        index
        matched_key
        value
      }
      headersRaw {
        index
        value
      }
      id
      importedFromUrl
      managed
      manual
      matchedAt
      memo
      originalFile
      parsingConfig
      status
      submittedAt
      type
      updatedAt
      validatedIn
      schema {
        id
        name
        jsonSchema {
          schema
        }
      }
      teamId
      uploads {
        id
        fileName
        fileSize
        fileType
        signedUrl
      }
      views {
        id
        status
        type
      }
    }
  }
`
