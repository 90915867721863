import { ApolloError, useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useEffect, useState } from 'react'
import { Pagination, SmartQuery, SmartResult } from 'src/smart/SmartQuery'
export interface SmartQueryOptions<Variables, Result> {
  defaultResult?: Result
  fetchPolicy?: WatchQueryFetchPolicy
  pollInterval?: number
  skip?: boolean
  variables: Variables
}
export function useSmartQuery<Variables, RawResult, Result>(
  { extract, query }: SmartQuery<Variables, RawResult, Result>,
  options: SmartQueryOptions<Variables, Result>
): SmartResult<Variables, RawResult, Result> {
  const state = useQuery<RawResult, Variables>(query, {
    fetchPolicy: options.fetchPolicy,
    pollInterval: options.pollInterval,
    skip: options.skip,
    variables: options.variables
  })

  const alert = state.loading || typeof state.error !== 'undefined'

  if (state.data) {
    const result = extract(state.data) ?? options.defaultResult

    const data = (result as any)?.data
    const pagination = (result as any)?.pagination

    return { result, state, alert, data, pagination }
  }

  return { result: options.defaultResult, state, alert }
}

export function useFindOne<R, V, N extends keyof R = keyof R, A extends R[N] = R[N]>(
  query: DocumentNode,
  variables: V,
  node: N,
  skip?: boolean
): LoadingResult<A> | ErrorResult<A> | DataResult<A> {
  const queryStatus = useQuery<R, V>(query, { variables, skip })

  const [results, setResults] = useState<LoadingResult<A> | ErrorResult<A> | DataResult<A>>({
    loading: true,
    ready: false
  })

  useEffect(() => {
    const { loading, error, data } = queryStatus
    if (loading) {
      return setResults({ loading: true, ready: false })
    }

    if (error) {
      return setResults({ loading: false, data: undefined, error, ready: false })
    }

    if (!data) {
      return setResults({
        loading: false,
        data: undefined,
        error: new ApolloError({ errorMessage: 'No data in result' }),
        ready: false
      })
    }

    return setResults({ loading: false, data: data[node] as A, ready: true })
  }, [queryStatus])

  return results
}

interface LoadingResult<_A> {
  ready: false
  loading: true
}
interface ErrorResult<_A> {
  data: undefined
  ready: false
  loading: false
  error: ApolloError
}
interface DataResult<A> {
  data: A
  ready: true
  loading: false
}
interface DataResultWithPagination<A> {
  data: A
  pagination: Pagination
  ready: true
  loading: false
}
export type FindResult<A> =
  | LoadingResult<A>
  | ErrorResult<A>
  | DataResult<A>
  | DataResultWithPagination<A>
