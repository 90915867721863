import { EmojiIcon, Vertical } from 'src/applications/Oversight/developer/DeveloperMenuCommon'
import { DeveloperMenuLink } from 'src/applications/Oversight/developer/DeveloperMenuLink'
import {
  DeveloperMenuRoute,
  DeveloperMenuRouteControl,
  GoUpLevel
} from 'src/applications/Oversight/developer/DeveloperMenuRoutes'
import { DeveloperMenuToolApiModules } from 'src/applications/Oversight/developer/tools/DeveloperMenuToolApiModules'

import { DeveloperMenuToolMatching } from './DeveloperMenuToolMatching'

export const developerMenuToolsRoutes: DeveloperMenuRoute[] = [
  {
    prefix: ['tools', 'matching'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>Tools / Matching</GoUpLevel>
    },
    contents() {
      return <DeveloperMenuToolMatching />
    }
  },
  {
    prefix: ['tools', 'api-modules'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>Tools / API Modules</GoUpLevel>
    },
    contents() {
      return <DeveloperMenuToolApiModules />
    }
  },
  {
    prefix: ['tools'],
    toolbar(control): JSX.Element {
      return <GoUpLevel control={control}>Tools</GoUpLevel>
    },
    contents(control) {
      return <DeveloperTools control={control} />
    }
  }
]

const DeveloperTools = ({ control }: { control: DeveloperMenuRouteControl }) => (
  <Vertical>
    <DeveloperMenuLink to={['matching']} control={control}>
      <EmojiIcon>🧲</EmojiIcon>
      <div>Matching Utility</div>
    </DeveloperMenuLink>
    <DeveloperMenuLink to={['api-modules']} control={control}>
      <EmojiIcon>🕸</EmojiIcon>
      <div>API Modules</div>
    </DeveloperMenuLink>
  </Vertical>
)
