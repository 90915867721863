import { gql } from 'apollo-boost'
import {
  GetSchemaMutations,
  GetSchemaMutationsVariables,
  GetSchemaMutations_getSchema_mutations
} from 'src/smart/queries/types/GetSchemaMutations'
import { SmartQuery } from 'src/smart/SmartQuery'

export const SQ_GET_SCHEMA_MUTATIONS: SmartQuery<
  GetSchemaMutationsVariables,
  GetSchemaMutations,
  GetSchemaMutations_getSchema_mutations[]
> = {
  query: gql`
    query GetSchemaMutations($schemaId: ID!, $archived: Boolean) {
      getSchema(schemaId: $schemaId) {
        id
        mutations(archived: $archived) {
          archived
          createdAt
          enabled
          includeUserHeaders
          graphQLURI
          id
          mutationBody
          mutationType
          resultPrimaryKey
          schemaId
          updatedAt
        }
      }
    }
  `,
  extract({ getSchema }) {
    return getSchema.mutations
  }
}
