import { gql } from '@apollo/client'
import { SmartQuery } from 'src/smart/SmartQuery'
import { GetLatestMerge, GetLatestMergeVariables } from './types/GetLatestMerge'

export const SQ_GET_LATEST_MERGE: SmartQuery<
  GetLatestMergeVariables,
  GetLatestMerge,
  string | undefined
> = {
  query: gql`
    query GetLatestMerge($sheetId: UUID!) {
      getLatestMerge(sheetId: $sheetId) {
        merge {
          id
        }
      }
    }
  `,
  extract({ getLatestMerge }) {
    return getLatestMerge?.merge?.id
  }
}
