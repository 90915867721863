import { createContextState } from 'src/resources/hooks/createContextState'

export interface IOnboardingContext {
  signup: boolean
  dataModel: boolean
  workspace: boolean
  upload: boolean

  [signUp: string]: boolean
}

export const [OnboardingContext, getOnboardingContext] = createContextState<IOnboardingContext>(
  'Onboarding',
  {
    signup: true,
    dataModel: false,
    workspace: false,
    upload: false
  }
)
