import { gql } from '@apollo/client'
import {
  UpdateFeature,
  UpdateFeatureVariables,
  UpdateFeature_updateFeature
} from 'src/smart/mutations/types/UpdateFeature'
import { SmartMutation } from 'src/smart/SmartMutation'

export const SM_UPDATE_FEATURE: SmartMutation<
  UpdateFeatureVariables,
  UpdateFeature,
  UpdateFeature_updateFeature
> = {
  mutation: gql`
    mutation UpdateFeature(
      $archived: Boolean!
      $enabled: Boolean!
      $id: Int!
      $key: String!
      $name: String!
    ) {
      updateFeature(archived: $archived, enabled: $enabled, id: $id, key: $key, name: $name) {
        archived
        enabled
        id
        key
        name
      }
    }
  `,
  extract: (response) => response.updateFeature
}
