import { useContext } from 'react'

import { LIVE_ENVIRONMENT_SLUG } from 'src/config'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'
import { TeamContext } from 'src/contexts/TeamContext'

export function useTeamRootUrl() {
  const team = useContext(TeamContext)
  const environment = useContext(EnvironmentContext)
  return `/a/${team.id}/env/${environment?.slug ?? LIVE_ENVIRONMENT_SLUG}`
}
