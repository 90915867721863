import { ReactElement } from 'react'

import { Colors } from 'src/resources/colors'
import datasetIcon from 'src/resources/icons/dataset.svg'
import workspaceIcon from 'src/resources/icons/workspace.svg'
import insightsIcon from 'src/resources/images/insights-section.svg'
import knowledgeBaseIcon from 'src/resources/images/knowledge-base-inactive.svg'
import resourcesIcon from 'src/resources/images/resources-section.svg'
import settingsIcon from 'src/resources/images/settings-section.svg'
import workspacesIcon from 'src/resources/images/workspace-section.svg'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'

const IconMap: any = {
  platform: workspaceIcon,
  datasets: datasetIcon,
  portal: insightsIcon,
  knowledgeBase: knowledgeBaseIcon,
  help: resourcesIcon,
  settings: settingsIcon,
  configuration: workspacesIcon,
  workspace: workspaceIcon
}

export const Wrap = styled.div<{ hideMargin?: boolean; name?: string; endUserHeading?: boolean }>`
  display: flex;
  align-items: center;

  ${({ name }) =>
    name
      ? css`
          background-image: url(${IconMap[name.toString()]});
        `
      : null}

  height: 24px;
  background-repeat: no-repeat;
  background-position: -2px center;
  background-size: 24px;
  color: ${Colors.text};
  font-size: ${fontSizes.type13};
  font-family: ${fontFamily.fontPrimary};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  opacity: 0.6;
  margin-bottom: ${Spacing.basePadding2x};

  > p {
    margin-left: ${({ hideMargin }) => (hideMargin ? '0' : '32px')};
  }
  &:nth-child(n + 2) {
    margin-top: ${Spacing.basePadding4x};
  }
  ${({ endUserHeading }) =>
    endUserHeading &&
    css`
      &:nth-child(n + 2) {
        margin-top: ${Spacing.basePadding2x};
      }
    `}
`
interface ISectionHeader {
  children?: ReactElement
  hideMargin?: boolean
  endUserHeading?: boolean
  name: string
}

const SectionHeader = ({ hideMargin, name, endUserHeading }: ISectionHeader) => {
  return (
    <Wrap name={name} hideMargin={hideMargin} endUserHeading={endUserHeading}>
      <p>{name === 'knowledgeBase' ? 'knowledge base' : name}</p>
    </Wrap>
  )
}

export default SectionHeader
