import { gql } from '@apollo/client'

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $fullName: String!
    $email: LowercaseString!
    $password: String!
    $inviteToken: String
    $role: String
    $urlSource: String
  ) {
    registerUser(
      fullName: $fullName
      email: $email
      password: $password
      inviteToken: $inviteToken
      role: $role
      urlSource: $urlSource
    ) {
      accessToken
      user {
        id
        name
        email
      }
    }
  }
`
