import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'

import { EPropertyType, Schema } from '../Schema'

export class SchemaProperty {
  public readonly type: EPropertyType = EPropertyType.DEFAULT

  constructor(
    protected schema: Schema,
    public readonly key: string,
    public definition: JsonSchemaProperty
  ) {
    if (typeof this.definition.$schemaId === 'string') {
      this.type = EPropertyType.SCHEMA_REF
    }
  }
}
