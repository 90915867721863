import { createContextState } from 'src/resources/hooks/createContextState'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'

interface WorkspaceContext {
  workspace?: IWorkspace
}

export const [WorkspaceContext, useWorkspaceContext] = createContextState<WorkspaceContext>(
  'WorkspaceContext',
  {}
)
