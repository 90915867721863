import { gql } from '@apollo/client'

export const ACCEPT_WORKSPACE_INVITATION = gql`
  mutation AcceptWorkspaceInvitation(
    $token: String!
    $name: String
    $password: String
    $passwordConfirm: String
  ) {
    acceptWorkspaceInvitation(
      token: $token
      name: $name
      password: $password
      passwordConfirm: $passwordConfirm
    ) {
      workspaceTeam {
        team {
          id
        }
        workspace {
          id
        }
      }
      user {
        id
        name
        email
        jobTitle
        intercomHash
        superAdmin
      }
      environment {
        slug
      }
      token
    }
  }
`
