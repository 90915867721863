import { gql } from '@apollo/client'

export const UPDATE_SCHEMA_FUNCTION = gql`
  mutation UpdateSchemaFunction(
    $archived: Boolean
    $functionMeta: String
    $functionType: String
    $id: UUID!
    $name: String
    $order: Int
    $status: String
  ) {
    updateSchemaFunction(
      archived: $archived
      functionMeta: $functionMeta
      functionType: $functionType
      id: $id
      name: $name
      order: $order
      status: $status
    ) {
      id
      schema {
        id
      }
    }
  }
`
