import { LowLayer1 } from 'src/applications/Oversight/layers'
import { Colors } from 'src/resources/colors'
import { Icon } from 'src/resources/elements/Icon'
import { fontMonospace, fontSizes } from 'src/resources/typography'
import { IAccessKey } from 'src/types/interfaces/IAccessKey'
import styled from 'styled-components'

export const AccessKeyId = styled.span`
  display: inline-block;
  font-family: ${fontMonospace};
  padding: 2px;
  font-size: ${fontSizes.type12};
  background: ${Colors.white};
  border: 1px solid ${Colors.border};
  border-radius: 2px;
`

const AccessKeyTagContainer = styled.span`
  vertical-align: middle;
  position: relative;
  display: inline-block;
  padding: 2px;
  font-size: ${fontSizes.type12};
  background: ${Colors.gray20};
  border: 1px solid ${Colors.border};
  border-radius: 4px;
`

const AccessKeyMemo = styled.span`
  display: block;
  margin: 4px 2px 2px;
  overflow: hidden;
  text-overflow: ellipsis;

  i {
    color: ${Colors.blackLight};
    vertical-align: -1px;
    margin-right: 2px;
  }
`

const AccessKeyContainer = styled.span`
  white-space: nowrap;
  display: inline-block;
`

const MainIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: -1px;
  color: ${Colors.brandPrimary};
  display: inline-block;
  padding: 4px;
  z-index: ${LowLayer1};
  position: relative;
  font-size: ${fontSizes.type12};
  background: ${Colors.gray20};
  border: 1px solid ${Colors.border};
  border-right: 1px solid ${Colors.gray20};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

export const AccessKeyTag = ({ accessKey }: { accessKey: Partial<IAccessKey> }) => (
  <AccessKeyContainer>
    <MainIcon name='key' />
    <AccessKeyTagContainer>
      <AccessKeyId>{accessKey.accessKeyId}</AccessKeyId>
      {accessKey.memo ? (
        <AccessKeyMemo>
          <Icon name='comment-alt' /> {accessKey.memo}
        </AccessKeyMemo>
      ) : null}
    </AccessKeyTagContainer>
  </AccessKeyContainer>
)
